import React, { useEffect, useState } from "react";
import { Panel } from "primereact/panel";
import { ISelectOption, IStore, IUser } from "../../../../index.dts";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Util from "../../../util/Util";
import {
  assignUsers,
  getAssignedUsers,
  getMyDealerships,
  removeDealershipUsers,
} from "../../../actions/dealershipActions";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { PERMISSIONS, SEARCH_OPERATIONS } from "../../../util/Enums";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import { updateDropdownKey } from "../../../actions/authActions";
import CloseButton from "../../close_button/CloseButton";
import { Sidebar } from "primereact/sidebar";
import { getUserList } from "../../../actions/userManagementActions";
import {
  AsyncPaginate,
  reduceGroupedOptions,
} from "react-select-async-paginate";

const uuid = require("uniqid");

const DealershipUsers = () => {
  const dispatch = useDispatch();

  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  const [pending, setPending] = useState<boolean>(false);
  const [dealership, setDealership] = useState<ISelectOption>({
    value: dealershipContext.id,
    label: dealershipContext.name,
  });

  const [users, setUsers] = useState<IUser[]>([]);
  const [newUsers, setNewUsers] = useState<ISelectOption[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);

  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);

  useEffect(() => {
    getUsers(dealership.value).finally(() => {
      setSelectedUsers([]);
    });
  }, [dealership.value]);

  const promiseDealerships = async (
    inputValue: string,
    loadedOptions: any,
    { page }: any
  ): Promise<any> => {
    const response = await getMyDealerships({
      pageNumber: page,
      pageLimit: 100,
      dealershipName: inputValue,
      sorting: [],
    });

    return {
      options: [
        {
          options: response.data.content.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          }),
        },
      ],
      additional: { page: page + 1 },
      hasMore: page < response.data.totalPages,
    };
  };

  const promiseUsers = async (
    inputValue: string,
    loadedOptions: any,
    { page }: any
  ): Promise<any> => {
    const filter: any = [];
    if (!Util.isEmpty(inputValue)) {
      filter.push({
        field: "email",
        operation: SEARCH_OPERATIONS.LIKE,
        value: inputValue,
      });
    }

    if (users.length > 0) {
      filter.push({
        field: "id",
        operation: SEARCH_OPERATIONS.NOT_IN,
        value: users.map((user) => user.id),
      });
    }

    const response = await getUserList({
      filter: filter,
      sorting: [{ field: "email", direction: "asc" }],
      paging: { page: page, pageLimit: 100 },
    });

    return {
      options: [
        {
          options: response.data.content.map((item: any) => {
            return {
              value: item.id,
              label: item.email,
            };
          }),
        },
      ],
      additional: { page: page + 1 },
      hasMore: page < response.data.totalPages,
    };
  };

  const getUsers = (dealershipId: number) =>
    new Promise((resolve, reject) => {
      setPending(true);
      getAssignedUsers(dealershipId)
        .then((response) => {
          setUsers(response.data.data);

          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);

          reject(error);
        })
        .finally(() => {
          setPending(false);
        });
    });

  const onDealershipChange = (value: any): void => {
    setDealership(value);
  };

  const showAlertWithNotRemovedUsers = (users: {
    [key: number]: string;
  }): void => {
    const message = Object.values(users).map((item, index) => (
      <p>
        {index + 1}: {item}
      </p>
    ));
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className={"react-confirm-alert-body"}>
              <h1>Some users are not removed!</h1>
              <div style={{ maxHeight: 350, overflow: "auto" }}>{message}</div>
              <div className={"react-confirm-alert-button-group"}>
                <button onClick={onClose} className={"confirm-save-btn"}>
                  Ok
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const onRemove = (): void => {
    if (selectedUsers.length === 0) {
      return;
    }

    confirmAlert({
      title: `You are about to remove ${selectedUsers.length} user${
        selectedUsers.length > 1 ? "s" : ""
      } from selected dealership!`,
      message: "Are you sure you want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setPending(true);
            removeDealershipUsers(
              dealership.value,
              selectedUsers.map((item) => item.id)
            )
              .then((response) => {
                Util.success("The user(s) are removed successfully.");

                if (!Util._.isEmpty(response.data)) {
                  showAlertWithNotRemovedUsers(response.data);
                }

                dispatch(updateDropdownKey(uuid()));
              })
              .catch((error) => {
                Util.showError(error);
              })
              .finally(() => {
                getUsers(dealership.value).finally(() => {
                  setSelectedUsers([]);
                });
              });
          },
          className: "confirm-save-btn",
        },
        {
          label: "Cancel",
          className: "confirm-cancel-btn",
          onClick: () => {},
        },
      ],
    });
  };

  const onAssign = (): void => {
    if (newUsers.length === 0) {
      return;
    }

    onHideSidebar();
    setPending(true);
    assignUsers(
      dealership.value,
      newUsers.map((item) => item.value)
    )
      .then((response) => {
        Util.success("The user(s) are assigned successfully.");
        dispatch(updateDropdownKey(uuid()));
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => {
        getUsers(dealership.value).finally(() => {
          setNewUsers([]);
        });
      });
  };

  const onShowSidebar = (): void => setSidebarVisible(true);

  const onHideSidebar = (): void => setSidebarVisible(false);

  const onNewUsersChange = (users: any): void =>
    setNewUsers(Util.getDefaultIfNull(users, []));

  const renderer = (): React.ReactElement => {
    return (
      <Panel
        header={
          <div style={{ maxWidth: 300 }}>
            <AsyncPaginate
              menuPosition={"fixed"}
              //cacheOptions
              defaultOptions
              value={dealership}
              classNamePrefix={"assign"}
              placeholder={"Select Dealership"}
              loadOptions={promiseDealerships}
              reduceOptions={reduceGroupedOptions}
              onChange={(data: any) => onDealershipChange(data)}
              additional={{ page: 0 }}
            />
          </div>
        }
      >
        <div className={"p-grid"}>
          <div className="p-col-12">
            <div className="card card-w-title datatable-demo">
              <div
                className={
                  limit > 10
                    ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows dealership-users"
                    : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows dealership-users-ten"
                }
              >
                <DataTable
                  value={users}
                  scrollable
                  autoLayout={true}
                  loading={pending}
                  filterDisplay="row"
                  paginator={true}
                  resizableColumns={true}
                  columnResizeMode={"expand"}
                  rowsPerPageOptions={Util.rowsPerPageOptions}
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                  rows={limit}
                  selection={selectedUsers}
                  onSelectionChange={(e) => setSelectedUsers(e.value)}
                  header={
                    <div style={{ display: "flex" }}>
                      <div className={"p-grid-header-components"}>
                        {Util.hasAnyAuthority(PERMISSIONS.USERS_VIEW) ? (
                          <Button
                            style={{ marginRight: 5 }}
                            onClick={onShowSidebar}
                            icon="pi pi-user-plus"
                            label={"Assign"}
                          />
                        ) : null}

                        <Button
                          disabled={selectedUsers.length === 0}
                          onClick={onRemove}
                          icon="pi pi-user-minus"
                          className="p-button-danger"
                          label={"Remove"}
                        />
                      </div>
                    </div>
                  }
                >
                  <Column
                    selectionMode="multiple"
                    style={{ width: "3em", flex: "0.05" }}
                  />
                  <Column
                    filter={true}
                    sortable={true}
                    field="firstName"
                    header="First Name"
                    filterMatchMode={"contains"}
                  />
                  <Column
                    filter={true}
                    sortable={true}
                    field="lastName"
                    header="Last Name"
                    filterMatchMode={"contains"}
                  />
                  <Column
                    filter={true}
                    sortable={true}
                    field="email"
                    header="Email"
                    filterMatchMode={"contains"}
                  />
                </DataTable>
              </div>
            </div>
          </div>
        </div>
        <Sidebar
          style={{ width: 350 }}
          position={"right"}
          visible={sidebarVisible}
          onHide={onHideSidebar}
          showCloseIcon={false}
        >
          {sidebarVisible ? (
            <div style={{ width: "100%", height: "100%" }}>
              <CloseButton onHide={onHideSidebar} />
              <div
                style={{
                  marginTop: 25,
                  marginLeft: -20,
                  marginRight: -15,
                  borderBottom: "1px solid #afa6a6",
                }}
              >
                <label style={{ fontSize: 20, marginLeft: 20 }}>
                  Assign Users
                </label>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: 5,
                  height: "calc(100% - 90px)",
                  overflowY: "auto",
                }}
              >
                <AsyncPaginate
                  isMulti={true}
                  closeMenuOnSelect={false}
                  //cacheOptions
                  defaultOptions
                  value={newUsers}
                  classNamePrefix={"assign"}
                  className={"multi-select"}
                  placeholder={"Select User(s)"}
                  loadOptions={promiseUsers}
                  reduceOptions={reduceGroupedOptions}
                  onChange={onNewUsersChange}
                  additional={{ page: 0 }}
                />
                <Button
                  label={"Save"}
                  onClick={onAssign}
                  disabled={newUsers.length === 0}
                  style={{ float: "right", marginTop: 10, marginBottom: 10 }}
                />
              </div>
            </div>
          ) : null}
        </Sidebar>
      </Panel>
    );
  };

  return renderer();
};

export default DealershipUsers;
