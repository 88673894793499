import React, { useState } from "react";
import { createContext, useContext } from "react";
import {
  ISmartSpendFilterData,
  defaultSmartSpendFilter,
} from "../../components/smart_spend_filter/SmartSpendFilter";
import {
  IVdpPerformanceFilterData,
  defaultVdpPerformanceFilter,
} from "../../components/my_traffic/vdp_performance_view/VdpPerformanceView";

const AppContext = createContext<any>({} as any);

const AppContextProvider = ({ children }: any) => {
  const [filter, setFilter] = useState<ISmartSpendFilterData>(
    defaultSmartSpendFilter
  );
  const [vdpPerformanceFilter, setVdpPerformanceFilter] =
    useState<IVdpPerformanceFilterData>(defaultVdpPerformanceFilter);
  return (
    <AppContext.Provider
      value={{
        filter,
        setFilter,
        vdpPerformanceFilter,
        setVdpPerformanceFilter,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { useAppContext, AppContext, AppContextProvider };
