import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "./App.css";
import "./i18n/config";

import { CookiesProvider, withCookies } from "react-cookie";
import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Assets from "./views/assets/Assets";
import VehicleExchangeDealershipProfile from "./views/vehicle_exchange_dealership_profile/VehicleExchangeDealershipProfile";
import AutoAcquire from "./views/wish_list/AutoAcquire";
import AuthorizedViewsWrapper from "./config/AuthorizedViewsWrapper";
import Brands from "./views/brands/Brands";
import CampaignForm from "./views/my_traffic/campaigns/CampaignForm";
import ChatsView from "./views/chats/ChatsView";
import CrmSources from "./views/crm_sources/CrmSources";
import Dashboard from "./views/dashboard/Dashboard";
import DealershipForm from "./components/dealership_form/DealershipForm";
import Dealerships from "./views/dealerships/Dealerships";
import EnterpriseGroups from "./views/enterprise_groups/EnterpriseGroups";
import EventAlertsEditForm from "./components/my_traffic/stats/event_alerts/EventAlertsEditForm";
import EventLogs from "./views/event_logs/EventLogs";
import GlobalSettings from "./views/global_settings/GlobalSettings";
import { IStore } from "../index.dts";
import IpBlocking from "./views/public/ip_blocking/IpBlocking";
import LaunchRequest from "./views/launch_request/LaunchRequest";
import Login from "./views/public/login/Login";
import Main from "./config/Main";
import MyTraffic from "./views/my_traffic/MyTraffic";
import NotificationForm from "./components/my_traffic/notifications/NotificationForm";
import Offers from "./views/offers/Offers";
import OffersForm from "./views/offers/OffersForm";
import { Password } from "primereact/password";
import Profile from "./views/profile/Profile";
import PublicViewWrapper from "./config/PublicViewWrapper";
import Reports from "./views/reports/Reports";
import ResetPassword from "./views/public/reset_password/ResetPassword";
import CustomerSubscription from "./views/public/customer_subscription/CustomerSubscription";
import Roles from "./views/roles/Roles";
import { RouteAccess } from "./config/RouteAccess";
import Spinner from "./components/spinner/Spinner";
import SupportPortal from "./views/support_portal/SupportPortal";
import Templates from "./views/templates/Templates";
import TemplatesForm from "./views/templates/TemplatesForm";
import UnauthorizedViewsWrapper from "./config/UnauthorizedViewsWrapper";
import UserActivate from "./views/public/user_activate/UserActivate";
import UserViewsWrapper from "./config/UserViewsWrapper";
import Users from "./views/users/Users";
import Util from "./util/Util";
import DealershipsAndUsers from "./views/dealerships_and_users/DealershipsAndUsers";
import EnterpriseGroupForm from "./components/group/enterprise_group/EnterpriseGroupForm";
import NotificationService from "./services/notification/NotificationService";
import { setNotificationService } from "./constants/actions";
import DealershipGroupForm from "./components/group/dealership_group/DealershipGroupForm";
import { STORAGE_EVENTS } from "./util/Enums";
import MyCampaigns from "./views/my_campaigns/MyCampaigns";
import MyCampaignsForm from "./views/my_campaigns/MyCampaignsForm";
import { AppContextProvider } from "./services/contexts/app-context";
import Mag from "./views/mag/Mag";
import IOfferPortal from "./views/iOffer/iOfferPortal";
import CdkROAssist from "./views/cdk_ro_assist/CdkROAssist";
import CdkROAssistForm from "./views/cdk_ro_assist/CdkROAssistForm";
import IOfferCertificate from "./views/public/iOffer/iOfferCertificate";
import IOfferFormNew from "./views/public/iOffer/iOfferFormNew";
import PerformanceReport from "./views/performance_report/PerformanceReport";
import AAServiceAndSales from "./views/vehicle_exchange_dealership_profile/AAServiceAndSales";

const queryString = require("query-string");

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const pending = useSelector(
    (store: IStore) => store.auth.pending,
    shallowEqual
  );
  const isLoggedIn = useSelector(
    (store: IStore) => store.auth.isLoggedIn,
    shallowEqual
  );
  const notificationService = useSelector(
    (store: IStore) => store.myTraffic.notificationService,
    shallowEqual
  );

  useEffect(() => {
    window.addEventListener("storage", onStorageEvent);

    return () => {
      window.removeEventListener("storage", onStorageEvent);
    };
  }, []);

  useEffect(() => {
    // @ts-ignore
    // Password.prototype.testStrength = function (str: string) {
    //   if (
    //     str.length < 8 ||
    //     !Util.hasSpecialCharacters(str) ||
    //     !Util.hasUpperCase(str) ||
    //     !Util.hasNumber(str)
    //   ) {
    //     return 0;
    //   }
    //   if (str.length <= 12) {
    //     return 50;
    //   }
    //   return 100;
    // };

    dispatch(setNotificationService(new NotificationService(history)));
  }, [dispatch, history]);

  useEffect(() => {
    if (isLoggedIn && !pending) {
      handleNotification(history.location.search);
    }
    // eslint-disable-next-line
  }, [pending, isLoggedIn, history.location.search]);

  const onStorageEvent = (event: StorageEvent): void => {
    switch (event.key) {
      case STORAGE_EVENTS.loginEvent:
      case STORAGE_EVENTS.logoutEvent:
        window.location.reload();
        break;
    }
  };

  const handleNotification = (search: string): void => {
    const queryObj = queryString.parse(search);
    if (Object.keys(queryObj).length === 0) {
      return;
    }

    const notificationId = queryObj.notificationId;
    if (Util.isEmpty(notificationId)) {
      return;
    }

    notificationService?.handleNotification(Number(notificationId));
  };

  const authRenderer = (isLoggedIn: boolean): React.ReactNode => {
    if (isLoggedIn) {
      if (pending) {
        return <Spinner visible={true} />;
      }

      return (
        <AuthorizedViewsWrapper>
          <RouteAccess
            path={Util.PATH_NAMES.CHATS_VIEW}
            exact={false}
            Component={ChatsView}
          />
          <RouteAccess
            path={Util.PATH_NAMES.DASHBOARD}
            exact={true}
            Component={Dashboard}
          />
          <RouteAccess
            path={Util.PATH_NAMES.DEALERSHIPS}
            exact={true}
            Component={Dealerships}
          />
          <RouteAccess
            path={Util.PATH_NAMES.DEALERSHIPS_EDIT}
            exact={false}
            Component={DealershipForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.DEALERSHIPS_CREATE}
            exact={false}
            Component={DealershipForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.CDK_RO_ASSIST_EDIT}
            exact={false}
            Component={CdkROAssistForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.CDK_RO_ASSIST_CREATE}
            exact={false}
            Component={CdkROAssistForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.OFFERS}
            exact={true}
            Component={Offers}
          />
          <RouteAccess
            path={Util.PATH_NAMES.OFFERS_EDIT}
            exact={false}
            Component={OffersForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.OFFERS_CREATE}
            exact={false}
            Component={OffersForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.TEMPLATES}
            exact={true}
            Component={Templates}
          />
          <RouteAccess
            path={Util.PATH_NAMES.TEMPLATES_EDIT}
            exact={false}
            Component={TemplatesForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.TEMPLATES_CREATE}
            exact={false}
            Component={TemplatesForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.ASSETS}
            exact={false}
            Component={Assets}
          />
          <RouteAccess
            path={Util.PATH_NAMES.VE2_DEALERSHIP_PROFILE}
            exact={false}
            Component={AAServiceAndSales}
          />
          <RouteAccess
            path={Util.PATH_NAMES.MY_TRAFFIC_WISH_LIST}
            exact={false}
            Component={AutoAcquire}
          />
          <RouteAccess
            path={Util.PATH_NAMES.MY_TRAFFIC_PERFORMANCE_REPORT}
            exact={false}
            Component={PerformanceReport}
          />
          <RouteAccess
            path={Util.PATH_NAMES.REPORTS}
            exact={false}
            Component={Reports}
          />
          <RouteAccess
            path={Util.PATH_NAMES.SUPPORT_PORTAL}
            exact={false}
            Component={SupportPortal}
          />
          <RouteAccess
            path={Util.PATH_NAMES.IOFFER_PORTAL}
            exact={false}
            Component={IOfferPortal}
          />
          <RouteAccess
            path={Util.PATH_NAMES.MY_TRAFFIC}
            exact={true}
            Component={MyTraffic}
          />
          <RouteAccess
            path={Util.PATH_NAMES.MY_TRAFFIC_EDIT_CAMPAIGN}
            exact={false}
            Component={CampaignForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.MY_TRAFFIC_CREATE_CAMPAIGN}
            exact={false}
            Component={CampaignForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.MY_CAMPAIGNS}
            exact={true}
            Component={MyCampaigns}
          />
          <RouteAccess
            path={Util.PATH_NAMES.MY_CAMPAIGNS_EDIT}
            exact={true}
            Component={MyCampaignsForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.MY_CAMPAIGNS_DUPLICATE}
            exact={true}
            Component={MyCampaignsForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.MY_CAMPAIGNS_CREATE}
            exact={true}
            Component={MyCampaignsForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.GLOBAL_SETTINGS}
            exact={false}
            Component={GlobalSettings}
          />
          <RouteAccess
            path={Util.PATH_NAMES.USERS}
            exact={false}
            Component={Users}
          />
          <RouteAccess
            path={Util.PATH_NAMES.ROLES}
            exact={false}
            Component={Roles}
          />
          <RouteAccess
            path={Util.PATH_NAMES.EVENT_LOGS}
            exact={false}
            Component={EventLogs}
          />
          <RouteAccess
            path={Util.PATH_NAMES.CRM_SOURCES}
            exact={false}
            Component={CrmSources}
          />
          <RouteAccess
            path={Util.PATH_NAMES.PROFILE}
            exact={false}
            Component={Profile}
          />
          <RouteAccess
            path={Util.PATH_NAMES.ENTERPRISE_GROUP}
            exact={true}
            Component={EnterpriseGroups}
          />
          <RouteAccess
            path={Util.PATH_NAMES.ENTERPRISE_GROUP_EDIT}
            exact={false}
            Component={EnterpriseGroupForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.ENTERPRISE_GROUP_CREATE}
            exact={false}
            Component={EnterpriseGroupForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.BRANDS}
            exact={false}
            Component={Brands}
          />
          <RouteAccess
            path={Util.PATH_NAMES.LAUNCH_REQUEST}
            exact={false}
            Component={LaunchRequest}
          />
          <RouteAccess
            path={Util.PATH_NAMES.MY_TRAFFIC_CREATE_NOTIFICATION}
            exact={false}
            Component={NotificationForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.MY_TRAFFIC_EDIT_NOTIFICATION}
            exact={false}
            Component={NotificationForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.MY_TRAFFIC_EDIT_EVENT_ALERT}
            exact={false}
            Component={EventAlertsEditForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.MY_TRAFFIC_CREATE_EVENT_ALERT}
            exact={false}
            Component={EventAlertsEditForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.DEALERSHIPS_AND_USERS}
            exact={false}
            Component={DealershipsAndUsers}
          />
          <RouteAccess
            path={Util.PATH_NAMES.MY_TRAFFIC_GROUP_CREATE}
            exact={false}
            Component={DealershipGroupForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.MY_TRAFFIC_GROUP_EDIT}
            exact={false}
            Component={DealershipGroupForm}
          />
          <RouteAccess
            path={Util.PATH_NAMES.MAG_EXTERNAL_REP}
            exact={false}
            Component={Mag}
          />
          <RouteAccess
            path={Util.PATH_NAMES.CDK_RO_ASSIST}
            exact={true}
            Component={CdkROAssist}
          />
        </AuthorizedViewsWrapper>
      );
    }

    return (
      <UnauthorizedViewsWrapper>
        <Route path={"/login"} component={Login} />
        <Route path={"/user/activate"} component={UserActivate} />
        <Route path={"/user/reset-password"} component={ResetPassword} />
      </UnauthorizedViewsWrapper>
    );
  };

  const routeRenderer = (): React.ReactNode => {
    if (history.location.pathname.includes("/public")) {
      return (
        <PublicViewWrapper>
          <Route path={"/public/verify"} component={IpBlocking} />
          <Route
            path={"/public/customer/subscription/:hash/:email/:dealershipId"}
            component={CustomerSubscription}
          />
        </PublicViewWrapper>
      );
    } else if (history.location.pathname.includes("/offer-")) {
      return (
        <>
          <Route
            path={"/offer-certificate/:dealerId/:guid"}
            component={IOfferCertificate}
          />
          <Route
            path={"/offer-form/:iOfferDataId/:acquisitionSource"}
            component={IOfferFormNew}
          />
        </>
      );
    } else {
      return <UserViewsWrapper>{authRenderer(isLoggedIn)}</UserViewsWrapper>;
    }
  };

  return (
    <CookiesProvider>
      <AppContextProvider>
        <Main>{routeRenderer()}</Main>
      </AppContextProvider>
    </CookiesProvider>
  );
}

export default withCookies(App);
