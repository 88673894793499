import { Panel } from "primereact/panel";
import React, { useEffect, useRef, useState } from "react";
import { SmartSpendChartService } from "../../smart_spend_chart/smart_spend_chart_service";
import { DateRangePickerWidget } from "../../data_range_picker/DateRangePickerWidget";
import { DateRangePeriods } from "../../data_range_picker/date_range_periods";
import moment from "moment";
import { useAppContext } from "../../../services/contexts/app-context";
import { ViewTypeRadio } from "./ViewTypeRadio";
import { ViewTypes } from "../../../util/enum/view_types";
import { IDS } from "../../../views/constants";
import { VIEW_BY_SELECTOR } from "../../../util/Enums";
import { Dropdown } from "primereact/dropdown";
import { shallowEqual, useSelector } from "react-redux";
import { BrandTypeMap, IStore, VinTypeMap } from "../../../../index.dts";
import HorizontalBarVDP from "./HorizontalBarVDP";
import RealTimeHorizontalBar from "./RealTimeHorizontalBar";
import { loadVdpStats } from "../../../actions/kaninActions";
import Util from "../../../util/Util";
import BrandGroupings from "./BrandGroupings";

interface IProps {
  dealershipId: number;
  dealershipTimezone: string;
  externalRefId: string;
  dealershipName: string;
  dealershipCreated: string;
  persistentChatEnabled: boolean;
}

interface IPeriod {
  readonly start?: Date;
  readonly end?: Date;
}

interface IViewType {
  readonly type?: ViewTypes;
}

export interface IVdpPerformanceFilterData {
  readonly period?: IPeriod;
  readonly viewType?: IViewType;
}

export const defaultVdpPerformanceFilter: IVdpPerformanceFilterData = {
  period: (() => {
    const period = DateRangePeriods.get(DateRangePeriods.keys.last30Days);
    return {
      start: period.start,
      end: period.end,
    };
  })(),
  viewType: {
    type: ViewTypes.uniqueVisitors,
  },
};

const VdpPerformanceView = (props: IProps) => {
  const service = new SmartSpendChartService();
  const [viewByFilter, setViewByFilter] = useState<string>(
    VIEW_BY_SELECTOR.NONE
  );
  const [total, setTotal] = useState<any>(0);
  const [vdpArray, setVdpArray] = useState<VinTypeMap | undefined>(undefined);
  const [onlyVdps, setOnlyVdps] = useState<boolean>(false);
  const [applyArray, setApplyArray] = useState<any>(null);
  const [applyDropdowns, setApplyDropdowns] = useState<BrandTypeMap>({});
  const ctx = useAppContext();
  const [filter, setFilter] = useState<IVdpPerformanceFilterData>(
    ctx.vdpPerformanceFilter
      ? ctx.vdpPerformanceFilter
      : defaultVdpPerformanceFilter
  );
  let isMountedRef = useRef(false);
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  const onClickApply = (data: any) => {
    if (
      data &&
      data.brand !== "" &&
      data.brand !== undefined &&
      data.year !== "" &&
      data.year !== undefined &&
      data.model !== "" &&
      data.model !== undefined &&
      data.trim !== "" &&
      data.trim !== undefined &&
      data.vin !== "" &&
      data.vin !== undefined
    ) {
      //case for the third graph
    } else if (
      data &&
      data.brand !== "" &&
      data.brand !== undefined &&
      data.year !== "" &&
      data.year !== undefined &&
      data.model !== "" &&
      data.model !== undefined &&
      data.trim !== "" &&
      data.trim !== undefined
    ) {
      // case for vins
      const years = Object.values(applyDropdowns)?.filter(
        (brand) => brand && brand.brandName == data.brand
      )[0]?.years;
      const models =
        years &&
        Object.values(years)?.filter(
          (year) => year && year.year == data.year
        )[0]?.models;
      const trims =
        models &&
        Object.values(models)?.filter(
          (model) => model && model.modelName == data.model
        )[0]?.trims;
      const vins =
        trims &&
        Object.values(trims)?.filter(
          (trim) => trim && trim.trimName == data.trim
        )[0]?.vins;
      setApplyArray(vins);
    } else if (
      data &&
      data.brand !== "" &&
      data.brand !== undefined &&
      data.year !== "" &&
      data.year !== undefined &&
      data.model !== "" &&
      data.model !== undefined
    ) {
      // case for trims
      const years = Object.values(applyDropdowns)?.filter(
        (brand) => brand && brand.brandName == data.brand
      )[0]?.years;
      const models =
        years &&
        Object.values(years)?.filter(
          (year) => year && year.year == data.year
        )[0]?.models;
      const trims =
        models &&
        Object.values(models)?.filter(
          (model) => model && model.modelName == data.model
        )[0]?.trims;
      setApplyArray(trims);
    } else if (
      data &&
      data.brand !== "" &&
      data.brand !== undefined &&
      data.year !== "" &&
      data.year !== undefined
    ) {
      // case for models
      const years = Object.values(applyDropdowns)?.filter(
        (brand) => brand && brand.brandName == data.brand
      )[0]?.years;
      const models =
        years &&
        Object.values(years)?.filter(
          (year) => year && year.year == data.year
        )[0]?.models;
      setApplyArray(models);
    } else if (data && data.brand !== "" && data.brand !== undefined) {
      //case for years
      const years = Object.values(applyDropdowns)?.filter(
        (brand) => brand && brand.brandName == data.brand
      )[0]?.years;
      setApplyArray(years);
    } else {
      // case for brands
      setApplyArray(applyDropdowns);
    }
  };

  function getQueryStart(): string {
    return moment(filter?.period?.start).format(Util.localDateFormat);
  }

  function getQueryEnd(): string {
    return moment(filter?.period?.end).format(Util.localDateFormat);
  }

  useEffect(() => {
    isMountedRef.current = true;
    loadVdpStats(props.dealershipId, getQueryStart(), getQueryEnd())
      .then((response) => {
        setApplyDropdowns(
          filter?.viewType?.type == ViewTypes.uniqueVisitors
            ? response?.visits
            : response?.pageViews
        );
        if (onlyVdps) {
          setApplyArray(
            filter?.viewType?.type == ViewTypes.uniqueVisitors
              ? response?.vinsInVisits
              : response?.vinsInPageViews
          );
        } else {
          setApplyArray(
            filter?.viewType?.type == ViewTypes.uniqueVisitors
              ? response?.visits
              : response?.pageViews
          );
        }
        setVdpArray(
          filter?.viewType?.type == ViewTypes.uniqueVisitors
            ? response?.vinsInVisits
            : response?.vinsInPageViews
        );
        if (filter?.viewType?.type == ViewTypes.uniqueVisitors) {
          let totalViews = 0;
          Object.values(response?.visits)?.map((visit: any) => {
            totalViews += visit?.total;
          });
          setTotal(totalViews);
        } else if (filter?.viewType?.type == ViewTypes.pageViews) {
          let totalViews = 0;
          Object.values(response?.pageViews)?.map((pageView: any) => {
            totalViews += pageView?.total;
          });
          setTotal(totalViews);
        }
      })
      .catch((error) => {
        console.error(error);
        Util.showError(error);
      });
    return () => {
      isMountedRef.current = false;
    };
  }, [filter?.viewType?.type, filter?.period]);

  useEffect(() => {
    if (onlyVdps) {
      setApplyArray(vdpArray);
    } else {
      setApplyArray(applyDropdowns);
    }
  }, [onlyVdps]);

  function onViewTypeChange(update: number): void {
    setFilter({ ...filter, viewType: { type: update } });
  }

  function getHeader() {
    return (
      <div className={"smart-spend-filter-container"}>
        <DateRangePickerWidget
          start={filter?.period?.start}
          end={filter?.period?.end}
          ranges={DateRangePeriods.options}
          onChange={onPeriodChange}
        />
        <ViewTypeRadio
          onChange={onViewTypeChange}
          type={filter?.viewType?.type}
        />
        <p>Total: {total}</p>
        <Dropdown
          id={IDS.dropdown.viewBy}
          showClear={true}
          value={viewByFilter}
          placeholder="Views By"
          options={Object.entries(VIEW_BY_SELECTOR).map((entry) => {
            return {
              label: entry[1],
              value: entry[0],
            };
          })}
          onChange={(e: any) => {
            setViewByFilter(e.target.value);
          }}
        />
      </div>
    );
  }

  function onPeriodChange(start?: Date, end?: Date): void {
    setFilter({
      ...filter,
      period: {
        start: moment(start)
          .set({
            hour: 0,
            minute: 0,
            seconds: 0,
          })
          .toDate(),
        end: moment(end)
          .set({
            hour: 23,
            minute: 59,
            seconds: 59,
          })
          .toDate(),
      },
    });
  }

  function getBrands() {
    return (
      <BrandGroupings
        total={total}
        viewByFilter={viewByFilter}
        filter={filter}
        dealershipId={props.dealershipId}
        dealershipTimezone={props.dealershipTimezone}
      />
    );
  }

  function getHorizontalBarVDP() {
    return (
      <HorizontalBarVDP
        vinTypeMap={applyArray}
        onClickApply={onClickApply}
        applyDropdowns={applyDropdowns}
        filter={filter}
        onlyVdps={onlyVdps}
        setOnlyVdps={setOnlyVdps}
      />
    );
  }

  function getRealTimeHorizontalBarVDP() {
    return <RealTimeHorizontalBar />;
  }

  return (
    <div className={"smart-spend-data-view-container"}>
      <Panel className={"smart-spend-data-view-panel"} header={getHeader()}>
        {getBrands()}
        {getHorizontalBarVDP()}
        {/* {getRealTimeHorizontalBarVDP()} */}
      </Panel>
    </div>
  );
};

export default VdpPerformanceView;
