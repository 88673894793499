import React from "react";
import { ResponseT2Data } from "./index";

interface IProps {
    visitorsT2ExternalData: ResponseT2Data | undefined;
}

const VisitorsExternalData = (props: IProps, ref: any) => {
    const vehicleOrderFields = ['year', 'make', 'model', 'series', 'stock', 'plate_number', 'vin', 'vehicle_type'];

    return (
        <div>
            {
                (() => {
                    let pii = props.visitorsT2ExternalData?.PII;
                    let vehicles = props.visitorsT2ExternalData?.Vehicles;
                    if (pii || vehicles) {
                        return (
                            <>
                                <h4>Registered PII to this lead:</h4>
                            </>
                        );
                    } else {
                        return null;
                    }
                })()
            }
            {
                (() => {
                    let pii = props.visitorsT2ExternalData?.PII;
                    if (pii) {
                        return (
                            <>
                                {pii.map((item, index) => (
                                    <div key={index}>
                                        {
                                            // Render specific fields first: address -> city -> state -> zip code
                                            ['address', 'city', 'state', 'zipcode'].map((field) => {
                                                let value = item[field];
                                                let displayKey = field;

                                                if (value !== undefined) {
                                                    if (Array.isArray(value) && (field === 'emails' || field === 'phone')) {
                                                        value = value.join(',');
                                                    }

                                                    displayKey = displayKey.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());

                                                    return (
                                                        <p key={field}>
                                                            <strong>{displayKey}:</strong> {value ?? 'N/A'}
                                                        </p>
                                                    );
                                                }
                                                return null;
                                            })
                                        }

                                        {
                                            // Render other fields in the object
                                            Object.entries(item).map(([key, value]) => {
                                                if (!['address', 'city', 'state', 'zipcode', 'allFieldsNull'].includes(key)) {
                                                    if (key === 'emails' && Array.isArray(value)) {
                                                        value = value.join(', ');
                                                    }
                                                    if (key === 'phone' && Array.isArray(value)) {
                                                        value = value.join(', ');
                                                    }
                                                    key = key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
                                                    return (
                                                        <p key={key}><strong>{key}:</strong> {value ?? 'N/A'}</p>
                                                    );
                                                }
                                                return null;
                                            })
                                        }

                                        <hr />
                                    </div>
                                ))}
                            </>
                        );
                    } else {
                        return null;
                    }
                })()
            }
            {
                (() => {
                    let vehicles = props.visitorsT2ExternalData?.Vehicles;
                    if (vehicles) {
                        return (
                            <>
                                <h4>Registered vehicles to this lead:</h4>
                                {vehicles.map((vehicle, index) => (
                                    <div key={index}>
                                        <h5>Vehicle # {index + 1}</h5>

                                        {
                                            // Render specific fields first
                                            vehicleOrderFields.map((field) => {
                                                let value = vehicle[field];
                                                let displayKey = field;

                                                if (field === "series") {
                                                    displayKey = "Trim";
                                                }

                                                if (value !== undefined) {
                                                    return (
                                                        <p key={field}>
                                                            <strong>{displayKey.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}:</strong> {value ?? 'N/A'}
                                                        </p>
                                                    );
                                                }
                                                return null;
                                            })
                                        }

                                        {
                                            // Render other fields in the object
                                            Object.entries(vehicle).map(([key, value]) => {
                                                if (!vehicleOrderFields.includes(key)) {
                                                    return (
                                                        <p key={key}>
                                                            <strong>{key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}:</strong> {value ?? 'N/A'}
                                                        </p>
                                                    );
                                                }
                                                return null;
                                            })
                                        }

                                        <hr />
                                    </div>
                                ))}
                            </>
                        );
                    } else if (vehicles !== undefined) {
                        return (
                            <>
                                <p>No vehicles registered.</p>
                            </>
                        );
                    }
                })()
            }
        </div>
    );
};

export default VisitorsExternalData;
