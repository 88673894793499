import React, {useEffect, useRef} from "react";
import GlobalSpinner from "../components/global_spinner/GlobalSpinner";
import {useDispatch} from "react-redux";
import {setGlobalSpinner, setGrowl} from "../actions/mainActions";
import GlobalGrowl from "../components/global_growl/GlobalGrowl";

export function Main(props: any) {
    const dispatch = useDispatch();
    const growlRef: any = useRef();
    const spinnerRef: any = useRef();

    useEffect(() => {
        dispatch(setGrowl(growlRef.current));
        dispatch(setGlobalSpinner(spinnerRef.current));
    }, [dispatch]);


    return (
        <div style={{width: "100%", height: "100%"}}>
            {props.children}
            <GlobalGrowl ref={growlRef}/>
            <GlobalSpinner ref={spinnerRef}/>
        </div>
    );
}

export default Main
