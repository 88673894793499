import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IDealership, ISelectOption, IStore } from "../../../../index.dts";
import {
  assignUserDealerships,
  getUserAssignedDealerships,
  removeUserDealerships,
} from "../../../actions/userActions";
import Util from "../../../util/Util";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { SEARCH_OPERATIONS } from "../../../util/Enums";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmAlert } from "react-confirm-alert";
import { updateDropdownKey } from "../../../actions/authActions";
import { getUserList } from "../../../actions/userManagementActions";
import { Sidebar } from "primereact/sidebar";
import CloseButton from "../../close_button/CloseButton";
import { InputText } from "primereact/inputtext";
import { getUnassignedDealerships } from "../../../actions/dealershipActions";
import {
  AsyncPaginate,
  reduceGroupedOptions,
} from "react-select-async-paginate";
import { IDS } from "../../../views/constants";
import { useTranslation } from "react-i18next";

const uuid = require("uniqid");

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

const UserDealerships = () => {
  const dispatch = useDispatch();
  const userContext = useSelector(
    (store: IStore) => store.auth.user,
    shallowEqual
  );
  const { t } = useTranslation();

  const [user, setUser] = useState<ISelectOption>({
    value: userContext.id,
    label: userContext.email,
  });
  const [dealerships, setDealerships] = useState<IDealership[]>([]);
  const [newDealerships, setNewDealerships] = useState<ISelectOption[]>([]);
  const [selectedDealerships, setSelectedDealerships] = useState<IDealership[]>(
    []
  );

  const [pending, setPending] = useState<boolean>(false);
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);

  const [nameFilterValue, setNameFilterValue] = useState<string>("");

  const onClear = () => {
    setNameFilterValue("");
  };

  useEffect(() => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getUserDealerships(user.value, 0, limit).finally(() => {
        setPage(0);
        searchTimeoutInterval = 300;
        setSelectedDealerships([]);
      });
    }, searchTimeoutInterval);
    // eslint-disable-next-line
  }, [user.value, nameFilterValue]);

  const getUserDealerships = (userId: number, page: number, limit: number) =>
    new Promise((resolve, reject) => {
      setPending(true);
      getUserAssignedDealerships(userId, {
        pageNumber: page,
        pageLimit: limit,
        sorting: [],
        dealershipName: nameFilterValue,
      })
        .then((response) => {
          setDealerships(response.data.content);
          setTotalItemsCount(response.data.totalElements);

          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);

          reject(error);
        })
        .finally(() => {
          setPending(false);
        });
    });

  const showAlertWithNotRemovedUsers = (users: {
    [key: number]: string;
  }): void => {
    const message = Object.values(users).map((item, index) => (
      <p>
        {index + 1}: {item}
      </p>
    ));
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className={"react-confirm-alert-body"}>
              <h1>Some dealerships are not removed!</h1>
              <div style={{ maxHeight: 350, overflow: "auto" }}>{message}</div>
              <div className={"react-confirm-alert-button-group"}>
                <button onClick={onClose} className={"confirm-save-btn"}>
                  Ok
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const onRemove = (): void => {
    if (selectedDealerships.length === 0) {
      return;
    }

    confirmAlert({
      title: `You are about to remove ${selectedDealerships.length} dealership${
        selectedDealerships.length > 1 ? "s" : ""
      } from selected user!`,
      message: "Are you sure you want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setPending(true);
            removeUserDealerships(
              user.value,
              selectedDealerships.map((item) => item.id)
            )
              .then((response) => {
                Util.success("The dealership(s) are removed successfully.");

                if (!Util._.isEmpty(response.data)) {
                  showAlertWithNotRemovedUsers(response.data);
                }

                dispatch(updateDropdownKey(uuid()));
              })
              .catch((error) => {
                Util.showError(error);
              })
              .finally(() => {
                getUserDealerships(user.value, 0, limit).finally(() => {
                  setPage(0);
                  setSelectedDealerships([]);
                });
              });
          },
          className: "confirm-save-btn",
        },
        {
          label: "Cancel",
          className: "confirm-cancel-btn",
          onClick: () => {},
        },
      ],
    });
  };

  const onAssign = (): void => {
    if (newDealerships.length === 0) {
      return;
    }

    onHideSidebar();
    setPending(true);
    assignUserDealerships(
      user.value,
      newDealerships.map((item) => item.value)
    )
      .then((response) => {
        Util.success("The dealership(s) are assigned successfully.");
        dispatch(updateDropdownKey(uuid()));
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => {
        getUserDealerships(user.value, page, limit);
      });
  };

  const statusTemplate = (rowData: any) => {
    if (rowData.deleted) {
      return <div>Inactive</div>;
    } else {
      return <div>Active</div>;
    }
  };

  const promiseUsers = async (
    inputValue: string,
    loadedOptions: any,
    { page }: any
  ): Promise<any> => {
    const filter: any = [];
    if (!Util.isEmpty(inputValue)) {
      filter.push({
        field: "email",
        operation: SEARCH_OPERATIONS.LIKE,
        value: inputValue,
      });
    }

    const response = await getUserList({
      filter: filter,
      sorting: [{ field: "email", direction: "asc" }],
      paging: { page: page, pageLimit: 100 },
    });

    return {
      options: [
        {
          options: response.data.content.map((item: any) => {
            return {
              value: item.id,
              label: item.email,
            };
          }),
        },
      ],
      additional: { page: page + 1 },
      hasMore: page < response.data.totalPages,
    };
  };
  const promiseDealerships = async (
    inputValue: string,
    loadedOptions: any,
    { page }: any
  ): Promise<any> => {
    const response = await getUnassignedDealerships(user.value, {
      pageNumber: page,
      pageLimit: 100,
      dealershipName: inputValue,
      sorting: [],
    });

    return {
      options: [
        {
          options: response.data.content.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          }),
        },
      ],
      additional: { page: page + 1 },
      hasMore: page < response.data.totalPages,
    };
  };

  const onUserChange = (value: any): void => setUser(value);

  const onShowSidebar = (): void => setSidebarVisible(true);

  const onHideSidebar = (): void => setSidebarVisible(false);

  const onNewDealershipsChange = (users: any): void =>
    setNewDealerships(Util.getDefaultIfNull(users, []));

  const onPage = (event: any) => {
    getUserDealerships(user.value, event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
    });
  };

  const nameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={nameFilterValue}
      onChange={(e: any) => {
        setNameFilterValue(e.target.value || "");
      }}
    />
  );

  const renderer = (): React.ReactElement => {
    return (
      <Panel
        header={
          <div style={{ maxWidth: 300 }}>
            <AsyncPaginate
              //cacheOptions
              defaultOptions
              value={user}
              classNamePrefix={"assign"}
              className={"multi-select"}
              placeholder={"Select User(s)"}
              loadOptions={promiseUsers}
              reduceOptions={reduceGroupedOptions}
              onChange={onUserChange}
              additional={{ page: 0 }}
            />
          </div>
        }
      >
        <div className={"p-grid"}>
          <div className="p-col-12">
            <div className="card card-w-title datatable-demo">
              <div
                className={
                  limit > 10
                    ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows dealership-users"
                    : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows dealership-users-ten"
                }
              >
                <DataTable
                  value={dealerships}
                  filterDisplay="row"
                  scrollable
                  autoLayout={true}
                  loading={pending}
                  paginator={true}
                  resizableColumns={true}
                  columnResizeMode={"expand"}
                  rowsPerPageOptions={Util.rowsPerPageOptions}
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                  totalRecords={totalItemsCount}
                  rows={limit}
                  lazy={true}
                  first={first}
                  onPage={onPage}
                  selection={selectedDealerships}
                  onSelectionChange={(e) => setSelectedDealerships(e.value)}
                  header={
                    <div style={{ display: "flex" }}>
                      <div className={"p-grid-header-components"}>
                        <Button
                          id={IDS.button.clear}
                          style={{ position: "absolute", left: "10px" }}
                          icon={"pi pi-filter"}
                          label={t("clear")}
                          onClick={onClear}
                        />
                        <Button
                          style={{ marginRight: 5 }}
                          onClick={onShowSidebar}
                          icon="pi pi-user-plus"
                          label={"Assign"}
                        />

                        <Button
                          disabled={selectedDealerships.length === 0}
                          onClick={onRemove}
                          icon="pi pi-user-minus"
                          className="p-button-danger"
                          label={"Remove"}
                        />
                      </div>
                    </div>
                  }
                >
                  <Column selectionMode="multiple" style={{ width: "3em" }} />
                  <Column
                    filter={true}
                    field="name"
                    header="Name"
                    filterElement={nameFilter}
                  />
                  <Column field="address" header="Address" />
                  <Column
                    field="deleted"
                    style={{ overflow: "visible" }}
                    header="Status"
                    body={statusTemplate}
                  />
                </DataTable>
              </div>
            </div>
          </div>
        </div>
        <Sidebar
          style={{ width: 350 }}
          position={"right"}
          visible={sidebarVisible}
          onHide={onHideSidebar}
          showCloseIcon={false}
        >
          {sidebarVisible ? (
            <div style={{ width: "100%", height: "100%" }}>
              <CloseButton onHide={onHideSidebar} />
              <div
                style={{
                  marginTop: 25,
                  marginLeft: -20,
                  marginRight: -15,
                  borderBottom: "1px solid #afa6a6",
                }}
              >
                <label style={{ fontSize: 20, marginLeft: 20 }}>
                  Assign Dealerships
                </label>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: 5,
                  height: "calc(100% - 90px)",
                  overflowY: "auto",
                }}
              >
                <AsyncPaginate
                  isMulti={true}
                  closeMenuOnSelect={false}
                  //cacheOptions
                  defaultOptions
                  classNamePrefix={"assign"}
                  className={"multi-select"}
                  placeholder={"Select Dealership(s)"}
                  loadOptions={promiseDealerships}
                  reduceOptions={reduceGroupedOptions}
                  onChange={onNewDealershipsChange}
                  additional={{ page: 0 }}
                />
                <Button
                  label={"Save"}
                  onClick={onAssign}
                  disabled={newDealerships.length === 0}
                  style={{ float: "right", marginTop: 10, marginBottom: 10 }}
                />
              </div>
            </div>
          ) : null}
        </Sidebar>
      </Panel>
    );
  };

  return renderer();
};

export default UserDealerships;
