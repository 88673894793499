import {
  getNotificationById,
  joinChatBotChannel, markAsReviewed,
  updateMyTrafficStatsTabIndex,
  updateMyTrafficTabIndex, updateUserNotifications
} from "../../actions/userActions";
import Util from "../../util/Util";
import {
  CHAT_HISTORY_TYPE,
  MyTrafficTabKeys,
  NOTIFICATION_METADATA_KEYS, NotificationStatus,
  NotificationType,
  StatsTabKeys
} from "../../util/Enums";
import {IMyTrafficNotificationLog} from "../../../index.dts";
import {findMyTrafficTabIndex, findStatsTabIndex} from "../firebase/firebase";
import store from "../../config/store";
import ChatModes from "../../constants/chat_modes";
import {startChat} from "../../actions/chatActions";
import {BrowserHistory} from "history";
import {updateDevice} from "../../actions/deviceActions";
import {setDealershipContext} from "../../actions/authActions";
import {INotificationService} from "./INotificationService";

export default class NotificationService implements INotificationService {

  private _history: BrowserHistory;

  constructor(history: BrowserHistory) {
    this._history = history;
  }

  get history() {
    return this._history;
  }

  set history(value) {
    this._history = value;
  }

  public handleNotification = (id: number): void => {
    Util.globalSpinner().show();
    getNotificationById(id)
      .then(response => {
        const dealershipContext = store.getState().auth.dealershipContext;
        const dealershipId: number = Util._.get(response, "data.dealershipId", null);

        if (!dealershipId || dealershipId === dealershipContext.id) {
          this.processNotification(response.data);
        } else {
          this.changeDealershipContext(dealershipId).then(() => this.processNotification(response.data));
        }
      })
      .catch(error => {
        Util.showError(error);
      })
      .finally(() => Util.globalSpinner().hide())
  }

  private changeDealershipContext = (dealershipId: number) => new Promise((resolve) => {
    Util.globalSpinner().show();
    const device = store.getState().auth.device;
    updateDevice(device.id, {dealershipId: dealershipId})
      .then(response => {
        resolve(void 0);
        store.dispatch(setDealershipContext(Util.getDealershipContextFromResponseData(response.data.dealership)));
      }).catch(error => {
      Util.showError(error);
    }).finally(() => {
      Util.globalSpinner().hide();
    })
  })

  private processNotification = (notification: IMyTrafficNotificationLog): void => {
    switch (notification.type) {
      case NotificationType.TRAFFIC_ALERT:
        this.handleAlertNotification(notification);
        break;
      case NotificationType.TRAFFIC_MARKER_REMINDER:
        this.handleMarkerNotification(notification);
        break
      case NotificationType.BEBACK:
      case NotificationType.CAMPAIGN:
      case NotificationType.NOTIFICATION:
      case NotificationType.CAMPAIGN_CHAT_DISABLED:
      case NotificationType.NOTIFICATION_CHAT_DISABLED:
        this.handleEngagementNotification(notification);
        break;
      case NotificationType.ENGAGEMENT_OFFER_LEAD:
        this.handleOfferLeadNotification(notification);
        break;
      case NotificationType.CHATBOT:
        this.handleChatBotNotification(notification);
        break;
      case NotificationType.PERSISTENT_CHAT:
        this.handlePersistentChatNotification(notification);
        break
      default:
        Util.warning(`This action is not supported yet.`);
    }

    this.markNotificationAsReviewed(notification);
  }

  private handleAlertNotification = (notification: IMyTrafficNotificationLog): void => {
    //TODO custom logic for alerts
    this.navigateToVisits();
  }

  private handleMarkerNotification = (notification: IMyTrafficNotificationLog): void => {
    //TODO custom logic for markers
    this.navigateToVisits();
  }

  private handleEngagementNotification = (notification: IMyTrafficNotificationLog): void => {
    const visitorExtRefId = Util._.get(notification, "visitorId");

    //TODO custom logic for beback
    this.navigateToVisualizer(visitorExtRefId);
  }

  private handleOfferLeadNotification = (notification: IMyTrafficNotificationLog): void => {
    //TODO custom logic for eng leads
    this.navigateToEngLeads();
  }

  private handleChatBotNotification = (notification: IMyTrafficNotificationLog): void => {
    const chatHistoryId = Util._.get(notification, `metadata.${NOTIFICATION_METADATA_KEYS.chatHistoryId}`);

    if (!chatHistoryId) {
      return;
    }

    Util.globalSpinner().show();
    joinChatBotChannel(chatHistoryId)
      .then(response => {
        const auth = store.getState().auth;
        const myTraffic = store.getState().myTraffic;

        const chatWidget = myTraffic.chatWidget;
        const visitorChatMap = myTraffic.visitorChatMap;
        const dealershipContext = auth.dealershipContext;
        const channelId = Util._.get(response, "data.channelId", null);
        const visitorId = Util._.get(response, "data.visitorId", "");
        const chatHistoryId = Util._.get(response, "data.id", null);

        chatWidget.start({
          channelId: channelId,
          isCallsEnabled: dealershipContext.chatMode === ChatModes.Calls,
          label: dealershipContext.name,
        });
        visitorChatMap.set(visitorId.toString(), chatHistoryId);
      })
      .catch(error => {
        if (error.response.status === 403) {
          Util.warning(Util._.get(error, "response.data.message"));
          return
        }
        Util.showError(error);
      })
      .finally(() => Util.globalSpinner().hide());
  }

  private handlePersistentChatNotification = (notification: IMyTrafficNotificationLog): void => {
    const chatHistoryId = Util._.get(notification, `metadata.${NOTIFICATION_METADATA_KEYS.chatHistoryId}`);

    if (!chatHistoryId) {
      return;
    }

    Util.globalSpinner().show();
    startChat(chatHistoryId)
      .then(response => {
        const auth = store.getState().auth;
        const myTraffic = store.getState().myTraffic;

        const chatWidget = myTraffic.chatWidget;
        const visitorChatMap = myTraffic.visitorChatMap;
        const dealershipContext = auth.dealershipContext;

        const channelId = Util._.get(response, "data.channelId", null);
        const visitorId = Util._.get(response, "data.visitorId", "");
        const chatHistoryId = Util._.get(response, "data.id", null);

        chatWidget.start({
          channelId: channelId,
          isCallsEnabled: dealershipContext.chatMode === ChatModes.Calls,
          label: dealershipContext.name,
        });

        visitorChatMap.set(visitorId.toString(), {
          chatHistoryId: chatHistoryId,
          type: CHAT_HISTORY_TYPE.persistent
        });
      })
      .catch(error => {
        const status = Util._.get(error, "response.status");
        const message: string = Util._.get(error, "response.data.message", "");

        if (status === 403) {
          Util.warning(message);
          return;
        } else if (status === 400) {
          if (message === `The visitor is no longer online.`) {
            Util.warning(message);
            return;
          }
        }
        Util.showError(error);
      })
      .finally(() => Util.globalSpinner().hide());
  }

  private navigateToVisits = (): void => {
    const visitsKey = findStatsTabIndex(StatsTabKeys.VISITS)
    const myTrafficStatsTabIndex = findMyTrafficTabIndex(MyTrafficTabKeys.STATS);

    store.dispatch(updateMyTrafficStatsTabIndex(visitsKey));
    store.dispatch(updateMyTrafficTabIndex(myTrafficStatsTabIndex));

    this.history.push(`${Util.PATH_NAMES.MY_TRAFFIC}`);
  }


  private navigateToVisualizer = (visitorExtRefId: string): void => {
    const visualizerTabIndex = findMyTrafficTabIndex(MyTrafficTabKeys.VISUALIZER);

    store.dispatch(updateMyTrafficTabIndex(visualizerTabIndex));

    this.history.push(`${Util.PATH_NAMES.MY_TRAFFIC}?visitorExtRefId=${visitorExtRefId}`);
  }

  private navigateToEngLeads = (): void => {
    const engLeadsTab = findMyTrafficTabIndex(MyTrafficTabKeys.ENG_LEADS);

    store.dispatch(updateMyTrafficTabIndex(engLeadsTab));

    this.history.push(Util.PATH_NAMES.MY_TRAFFIC);
  }

  private markNotificationAsReviewed = (notification: IMyTrafficNotificationLog): void => {
    markAsReviewed(notification.id)
      .finally(() => {
        store.dispatch(updateUserNotifications());
      });
  }

  public static isUnread = (notification: IMyTrafficNotificationLog): boolean => {
    switch (notification.status) {
      case NotificationStatus.SENT:
      case NotificationStatus.RECEIVED:
      case NotificationStatus.NON_RESPONDED:
        return true;
      default:
        return false;
    }
  }
}