import React, {useState} from "react";
import './reset_password.css';
import {useHistory} from "react-router-dom";
import {Password} from 'primereact/password';
import {Button} from "primereact/button";
import {authSuccess, getUserInfo, login, resetPassword} from "../../../actions/authActions";
import Util from "../../../util/Util";
import {useDispatch} from "react-redux";

const queryString = require('query-string');

export function ResetPassword() {
    const history = useHistory();
    const dispatch = useDispatch();

    const [key] = useState(queryString.parse(history.location.search).key);
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");


    const onSubmit = () => {
        if (key && Util.validatePassword(password) && password === confirmPassword) {
            Util.globalSpinner().show();
            resetPassword(key, window.btoa(password))
                .then(resetResponse => {
                    login(resetResponse.data.email, password)
                        .then(response => {
                            getUserInfo(response.data.access_token)
                                .then(res => {
                                    dispatch(authSuccess({auth: response.data, user: res.data}));
                                    history.push(Util.PATH_NAMES.DASHBOARD);
                                })
                                .catch(error => {
                                    Util.showError(error);
                                })
                                .finally(() => {
                                    Util.globalSpinner().hide();
                                });

                            Util.success("Password reset successfully.");
                        });
                })
                .catch(error => {
                    if (error?.response?.data?.message?.toLowerCase().includes("invalid token")) {
                        Util.error("Reset password link has expired or is invalid.");
                    } else {
                        Util.showError(error);
                    }
                    Util.globalSpinner().hide();
                })
        } else {
            if (!key) {
                Util.warning("Invalid token");
            } else if (!Util.validatePassword(password)) {
                Util.warning("Passwords must:\n" +
                    "Be a minimum of eight (8) characters in length\n" +
                    "Be a maximum of sixty four (64) characters in length\n" +
                    "Contain at least one (1) character from each four (4) of the following categories:\n" +
                    "Uppercase letter (A-Z)\n" +
                    "Lowercase letter (a-z)\n" +
                    "Digit (0-9)\n" +
                    "Special character (~`!@#$%^&*()+=_-{}[]\\|:;”’?/<>,.)");
            } else {
                Util.warning("Passwords does not match");
            }
        }
    };


    return (
        <div id={"user-reset-password"}>
            <div style={{padding: 20}} className={"p-grid"}>
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className={"p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"}>
                            <h1>Reset Password</h1>
                            <div className={"p-grid"}>
                                <div className={"p-col-12"}>
                                    <label>Password</label>
                                </div>
                                <div className={"p-col-12"}>
                                    <Password
                                        value={password}
                                        style={{width: "100%"}}
                                        onChange={(e: any) => setPassword(e.target.value)}
                                    />
                                </div>

                                <div className={"p-col-12"}>
                                    <label>Confirm Password</label>
                                </div>
                                <div className={"p-col-12"}>
                                    <Password
                                        feedback={false}
                                        value={confirmPassword}
                                        style={{width: "100%"}}
                                        onChange={(e: any) => setConfirmPassword(e.target.value)}
                                    />
                                </div>

                                <div className={"p-col-12"}>
                                    <Button onClick={() => history.push(Util.PATH_NAMES.LOGIN)}
                                            className={"p-button-secondary"}
                                            style={{float: "left"}} label={"Back to login"}/>
                                    <Button onClick={onSubmit} style={{float: "right"}} label={"Reset"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword
