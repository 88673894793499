import Axios from "../config/Axios";

export const getProfileConfiguration = (dealershipId: number) =>
  Axios.instance.get(
    `dealerships/${dealershipId}/vehicle-exchange-profile-configuration`
  );
export const createProfileConfiguration = (
  dealershipId: number,
  formData: FormData
) =>
  Axios.instance.post(
    `dealerships/${dealershipId}/vehicle-exchange-profile-configuration`,
    formData
  );
export const updateProfileConfiguration = (
  dealershipId: number,
  formData: FormData
) =>
  Axios.instance.put(
    `dealerships/${dealershipId}/vehicle-exchange-profile-configuration`,
    formData
  );
interface ServicePerformanceReportParams {
  dealershipId: number;
  dateRange?: string; // Optional property
}

export const getMyAcquisitionsServicePerformanceReport = (
  dealershipId: number,
  dateRange: string | undefined
) => {
  const params: ServicePerformanceReportParams = {dealershipId: dealershipId};

  if (dateRange) {
    params.dateRange = dateRange;
  }

  return Axios.instance.get(`my-acquisitions/services-performance-report`, {
    params,
  });
};

export const exportMyAcquisitionsServicePerformanceReport = (
  dealershipId: number,
  dateRange: string | undefined
) => {
  const params: ServicePerformanceReportParams = {dealershipId: dealershipId};

  if (dateRange) {
    params.dateRange = dateRange;
  }

  return Axios.instance.get(`my-acquisitions/export-services-performance-report`, {
    params,
  });
};
