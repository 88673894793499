import "./sem_vendors.css";

import {
  ISEMVendorsData,
  IVendor,
  IVendorDataRow,
} from "../../../../index.dts";
import {
  ISmartSpendFilterData,
  SmartSpendFilter,
  defaultSmartSpendFilter,
} from "../../smart_spend_filter/SmartSpendFilter";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  createSemVendor,
  deleteSemVendors,
  getSemVendorList,
  updateSemVendor,
} from "../../../actions/semVendorActions";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import LeadModal from "../lead_modal/LeadModal";
import { ProductTypes } from "../../../util/enum/product_types";
import { SmartSpendFilterOptions } from "../../smart_spend_filter/smart_spend_filter_options";
import Util from "../../../util/Util";
import { confirmAlert } from "react-confirm-alert";
import { get } from "lodash";
import { getSEMVendorsData } from "../../../actions/kaninActions";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IDS } from "../../../views/constants";

interface IProps {
  dealershipId: number;
  dealershipRefId: string;
  dealershipTimezone: string;
}

interface ISelectedSemVendor {
  id: number;
  segment: string;
  name: string;
  leadsPaid: number;
  visitsPaid: number;
}

const SemVendors = forwardRef((props: IProps) => {
  const leadModalRef = useRef<any>();

  const [filter, setFilter] = useState<ISmartSpendFilterData>(
    defaultSmartSpendFilter
  );

  const [paidValue, setPaidValue] = useState<number>(0);
  const [paidPerVisit, setPaidPerVisit] = useState<number>(0);
  const [paidPerLead, setPaidPerLead] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState<number>(0);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [vendors, setVendors] = useState<IVendor[]>([]);
  const [selectedVendors, setSelectedVendors] = useState<ISelectedSemVendor[]>(
    []
  );

  const [newVendor, setNewVendor] = useState<IVendor>();
  const [addSemVendorEnabled, setAddSemVendorEnabled] = useState<boolean>(true);

  const [sortMap] = useState<Map<string, { field: string; order: 1 | 0 | -1 }>>(
    new Map()
  );
  const [nameFilterValue, setNameFilterValue] = useState<string>("");
  const [segmentFilterValue, setSegmentFilterValue] = useState<string>("");

  const [data, setData] = useState<ISEMVendorsData>();

  const [needsReload, setNeedsReload] = useState<boolean>(false);

  const { t } = useTranslation();
  let isMountedRef = useRef(false);

  const onClear = () => {
    sortMap.clear();
    setNameFilterValue("");
    setSegmentFilterValue("");
  };

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  /**
   * Get the SEM vendors data
   */
  useEffect(() => {
    /**
     * Only fetch the data if the periods have changed, otherwise we have all
     * the necessary data and just need to filter it.
     */
    if (isMountedRef.current) {
      checkIfNeedsReload();

      if (needsReload) {
        loadSEMVendorsData();
      } else {
        setLoading(true);
        setTimeout(() => setLoading(false), 250);
      }
    }
    // eslint-disable-next-line
  }, [filter, props.dealershipId]);

  /**
   * Calculation of the amounts
   */
  useEffect(() => {
    if (isMountedRef.current) {
      calculatePaid(selectedVendors, paidValue);
    }
    // eslint-disable-next-line
  }, [selectedVendors, paidValue]);

  /**
   * Gets the SEM vendors
   */
  useEffect(() => {
    if (isMountedRef.current) {
      getData(props.dealershipId, 0, limit).finally(() => {
        setPage(0);
        setSelectedVendors([]);
      });
    }
    // eslint-disable-next-line
  }, [props.dealershipId, nameFilterValue, segmentFilterValue]);

  function checkIfNeedsReload(): void {
    const hasChangeStart = data?.startDate !== getQueryStart();
    const hasChangeEnd = data?.endDate !== getQueryEnd();
    const hasChangeDealership = data?.dealershipId !== props.dealershipId;
    const hasChange = hasChangeStart || hasChangeEnd || hasChangeDealership;
    setNeedsReload(hasChange);
  }

  function getQueryStart(): string {
    return moment(filter.period?.start).format(Util.localDateFormat);
  }

  function getQueryEnd(): string {
    return moment(filter.period?.end).format(Util.localDateFormat);
  }

  function loadSEMVendorsData(): void {
    setLoading(true);
    getSEMVendorsData({
      dealershipId: props.dealershipId,
      startDate: getQueryStart(),
      endDate: getQueryEnd(),
    })
      .then((response) => {
        setData(response);
      })
      .catch((error) => {
        console.error(error);
        Util.showError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const getData = (dealershipId: number, page: number, limit: number) =>
    new Promise((resolve) => {
      setLoading(true);
      getSemVendorList({
        pageNumber: page,
        pageLimit: limit,
        name: nameFilterValue,
        segment: segmentFilterValue,
        dealershipIds: [dealershipId],
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
      })
        .then((response) => {
          setVendors(response.data.content);
          setTotalItemsCount(response.data.totalElements);
          resolve(response);
        })
        .catch((error) => Util.showError(error))
        .finally(() => setLoading(false));
    });

  const calculatePaid = (
    selectedSemVendors: ISelectedSemVendor[],
    paidValue: number
  ): void => {
    let totalLeadsPaid = 0;
    let totalVisitsPaid = 0;

    selectedSemVendors.forEach((item) => {
      totalLeadsPaid += item.leadsPaid;
      totalVisitsPaid += item.visitsPaid;
    });

    if (totalVisitsPaid > 0) {
      setPaidPerVisit(parseFloat((paidValue / totalVisitsPaid).toString()));
    } else {
      setPaidPerVisit(0);
    }

    if (totalLeadsPaid > 0) {
      setPaidPerLead(parseFloat((paidValue / totalLeadsPaid).toString()));
    } else {
      setPaidPerLead(0);
    }
  };

  const rowValidator = (data: any): boolean => {
    return !(Util.isEmpty(data.name) || Util.isEmpty(data.segment));
  };

  const onPage = (event: any): void => {
    getData(props.dealershipId, event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
      setSelectedVendors([]);
    });
  };

  const onSort = (data: any): void => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getData(props.dealershipId, page, limit);
    }
  };

  const onAdd = (): void => {
    setNewVendor({ id: 0, name: "", segment: "" });
    setAddSemVendorEnabled(false);

    setTimeout(() => {
      Util.$("#sem-vendors .p-row-editor-init-icon")[0]?.click();
      Util.$("#sem-vendors .sem-vendor-editor")[0]?.focus();
    });
    setTimeout(() => {
      document.getElementById("sem-vendor-editor")?.focus();
    }, 130);
  };

  const onDelete = (): void => {
    if (selectedVendors.length > 0) {
      confirmAlert({
        title: `You are about to delete ${selectedVendors.length} vendor${
          selectedVendors.length > 1 ? "s" : ""
        }!`,
        message: "Are you sure you want to do this?",
        buttons: [
          {
            label: "Ok",
            className: "confirm-save-btn",
            onClick: () => {
              setLoading(true);
              deleteSemVendors(selectedVendors.map((item) => item.id))
                .then(() => {
                  getData(props.dealershipId, 0, limit).finally(() => {
                    setPage(0);
                    setSelectedVendors([]);
                  });
                  Util.success("Sem Vendor(s) deleted successfully.");
                })
                .catch((error) => Util.showError(error))
                .finally(() => setLoading(false));
            },
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    } else {
      Util.warning("Please choose at least one sem vendor.");
    }
  };

  const onRowEditSave = (props: any): void => {
    props.originalEvent.preventDefault();
    const semVendor: IVendor = get(props, "newData", null);
    setAddSemVendorEnabled(true);
    if (semVendor.id) {
      onUpdate(semVendor);
    } else {
      onCreate(semVendor);
    }
  };

  const onRowEditCancel = (event: { data: IVendor }): void => {
    if (!event.data.id) {
      setNewVendor(undefined);
      setAddSemVendorEnabled(true);
    }
  };

  const semVendorTextEditor = (props: any, field: "name" | "segment") => {
    switch (field) {
      case "name":
        return (
          <InputText
            autoFocus
            id="sem-vendor-editor"
            className={"sem-vendor-editor"}
            value={props.value}
            onChange={(e: any) => props.editorCallback(e.target.value)}
            placeholder="Enter name"
          />
        );
      case "segment":
        return (
          <InputText
            className={"sem-vendor-editor"}
            value={props.value}
            onChange={(e: any) => props.editorCallback(e.target.value)}
            placeholder="Enter segment"
          />
        );
    }
  };

  const nameEditor = (props: any) => {
    return semVendorTextEditor(props, "name");
  };

  const segmentEditor = (props: any) => {
    return semVendorTextEditor(props, "segment");
  };

  const onCreate = (vendor: IVendor): void => {
    setLoading(true);
    createSemVendor({
      name: vendor.name,
      segment: vendor.segment,
      dealershipId: props.dealershipId,
    })
      .then((response) => {
        vendors.unshift(response.data);
        setTotalItemsCount(totalItemsCount + 1);
        Util.success("Sem Vendor created successfully.");
      })
      .catch((error) => Util.showError(error))
      .finally(() => {
        setLoading(false);
        setNewVendor(undefined);
      });
  };

  const onUpdate = (vendor: IVendor): void => {
    setLoading(true);
    updateSemVendor(vendor.id, {
      name: vendor.name,
      segment: vendor.segment,
      dealershipId: props.dealershipId,
    })
      .then((response) => {
        const index = vendors.findIndex(
          (item) => item.id === Util._.get(response, "data.id", 0)
        );

        if (index !== -1) {
          const vendor = vendors[index];

          vendor["name"] = Util._.get(response, "data.name");
          vendor["segment"] = Util._.get(response, "data.segment");
        }

        Util.success("Sem Vendor updated successfully.");
      })
      .catch((error) => Util.showError(error))
      .finally(() => setLoading(false));
  };

  const nameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={nameFilterValue}
      onChange={(e: any) => {
        setNameFilterValue(e.target.value || "");
      }}
    />
  );

  const segmentFilter = (
    <InputText
      style={{ width: "100%" }}
      value={segmentFilterValue}
      onChange={(e: any) => {
        setSegmentFilterValue(e.target.value || "");
      }}
    />
  );

  const getSemVendors = (): IVendorDataRow[] => {
    const semVendors: IVendor[] = newVendor
      ? [newVendor].concat(vendors)
      : vendors;
    return semVendors.map((item: IVendor): IVendorDataRow => {
      const key = `paid_${item.segment}`;
      return {
        id: item.id,
        name: item.name,
        segment: item.segment,
        leadsPaid: getCounts(key, "leads", filter.product?.type),
        visitsPaid: getCounts(key, "visits", filter.product?.type),
      };
    });
  };

  function getDimensionsCounts(
    key: string,
    dimension: "leads" | "visits"
  ): {
    new: number;
    used: number;
    certified: number;
  } {
    return {
      new: getCounts(key, dimension, ProductTypes.new),
      used: getCounts(key, dimension, ProductTypes.used),
      certified: getCounts(key, dimension, ProductTypes.certified),
    };
  }

  const leadsTemplate = (item: any) => {
    const count = get(item, "leadsPaid", 0) ?? 0;
    const visits = get(item, "visitsPaid") ?? 0;
    const key = `paid_${item.segment}`;
    const dimension = "leads";
    const counts = getDimensionsCounts(key, dimension);
    const percentage =
      visits !== 0 && count !== 0 ? Number(count / visits).toFixed(2) : "0.0";

    return (
      <label className={"anchor"}>
        <span
          className={"sem-vendor-breakdown-count"}
          onClick={() => onLeadsPaidClick(key, ProductTypes.all)}
        >
          {count}
        </span>
        <span className={"sem-vendor-count-cell-extension"}>
          (<span className={"sem-vendor-breakdown-count"}>{percentage} %</span>)
        </span>
        <span className={"sem-vendor-count-cell-extension"}>
          (
          <span
            className={"sem-vendor-breakdown-count"}
            onClick={() => onLeadsPaidClick(key, ProductTypes.new)}
          >
            {counts.new} New
          </span>
          |
          <span
            className={"sem-vendor-breakdown-count"}
            onClick={() => onLeadsPaidClick(key, ProductTypes.used)}
          >
            {counts.used} Used
          </span>
          |
          <span
            className={"sem-vendor-breakdown-count"}
            onClick={() => onLeadsPaidClick(key, ProductTypes.certified)}
          >
            {counts.certified} Certified
          </span>
          )
        </span>
      </label>
    );
  };

  const onLeadsPaidClick = (key: string, type: ProductTypes) => {
    const startDate = moment(filter.period?.start).format(
      Util.localDateTimeFormat
    );
    const endDate = moment(filter.period?.end).format(Util.localDateTimeFormat);
    leadModalRef.current.show(key, startDate, endDate, type);
  };

  function onFilterChange(update: ISmartSpendFilterData): void {
    setFilter(update);
  }

  function getCounts(
    key: string,
    dimension: "leads" | "visits",
    type?: ProductTypes
  ): number {
    let count;
    const keyData = data?.[dimension]?.channels?.[key];

    if (type === ProductTypes.all) {
      count = keyData?.total;
    } else {
      const statusData = keyData?.byStatus;

      if (type === ProductTypes.certified) {
        count = statusData?.certified;
      } else if (type === ProductTypes.new) {
        count = statusData?.new;
      } else if (type === ProductTypes.used) {
        count = statusData?.used;
      }
    }

    return count ?? 0;
  }

  function visitsTemplate(item: any) {
    const count = get(item, "visitsPaid") ?? 0;
    const key = `paid_${item.segment}`;
    const dimension = "visits";
    const counts = getDimensionsCounts(key, dimension);

    return (
      <div>
        <span>
          <span>{count}</span>
          <span className={"sem-vendor-count-cell-extension"}>
            ( {counts.new} New | {counts.used} Used | {counts.certified}{" "}
            Certified )
          </span>
        </span>
      </div>
    );
  }

  return (
    <div
      id={"sem-vendors"}
      className={
        limit > 10
          ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting"
          : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
      }
    >
      <DataTable
        rowEditValidator={rowValidator}
        rowsPerPageOptions={Util.rowsPerPageOptions}
        scrollable
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
        value={getSemVendors()}
        paginator={true}
        filterDisplay="row"
        lazy={true}
        editMode={"row"}
        totalRecords={totalItemsCount}
        rows={limit}
        first={first}
        loading={loading}
        onPage={onPage}
        onSort={onSort}
        selection={selectedVendors}
        onSelectionChange={(e) => setSelectedVendors(e.value)}
        onRowEditComplete={onRowEditSave}
        onRowEditCancel={onRowEditCancel}
        sortMode={"multiple"}
        multiSortMeta={Array.from(sortMap.values())}
        header={
          <div style={{ display: "flex" }}>
            <SmartSpendFilter
              initial={filter}
              onChange={onFilterChange}
              options={[SmartSpendFilterOptions.dateRange]}
            />
            <div
              style={{
                justifyContent: "end",
                width: "100%",
                display: "flex",
                position: "relative",
              }}
            >
              <Button
                id={IDS.button.clear}
                style={{ position: "absolute", left: "10px" }}
                icon={"pi pi-filter"}
                label={t("clear")}
                onClick={onClear}
              />
              <Button
                label={t("refresh")}
                icon={"pi pi-refresh"}
                onClick={() => loadSEMVendorsData()}
              />
              <Button
                disabled={
                  addSemVendorEnabled == undefined ||
                  addSemVendorEnabled == true
                    ? false
                    : true
                }
                label={t("add")}
                icon="pi pi-plus"
                onClick={onAdd}
                // style={{ marginRight: 5 }}
              />
              <Button
                label={t("delete")}
                icon="pi pi-trash"
                onClick={onDelete}
                className="p-button-danger"
              />
            </div>
          </div>
        }
        footer={
          <div style={{ height: 30 }}>
            <InputNumber
              value={paidValue}
              max={9000000000}
              style={{ float: "left" }}
              placeholder={"SEM spend $"}
              onChange={(e: any) => setPaidValue(e.value)}
            />
            <div
              style={{
                display: "grid",
                float: "left",
                marginLeft: 5,
                fontSize: 12,
                textAlign: "left",
              }}
            >
              <label style={{ color: "red" }}>
                Paid per visit $
                {parseFloat(paidPerVisit.toString()).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </label>
              <label style={{ color: "red" }}>
                Paid per lead $
                {parseFloat(paidPerLead.toString()).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </label>
            </div>
          </div>
        }
      >
        <Column selectionMode="multiple" style={{ width: "3em" }} />
        <Column
          filter={true}
          sortable={true}
          field={"name"}
          header={"Name"}
          editor={nameEditor}
          filterElement={nameFilter}
        />
        <Column
          filter={true}
          sortable={true}
          field={"segment"}
          header={"Segment"}
          editor={segmentEditor}
          filterElement={segmentFilter}
        />
        <Column field={"leadsPaid"} header={"Leads"} body={leadsTemplate} />
        <Column body={visitsTemplate} field={"visitsPaid"} header={"Visits"} />
        <Column
          rowEditor
          headerStyle={{ width: "5rem" }}
          bodyStyle={{ textAlign: "center" }}
        />
      </DataTable>
      <LeadModal
        ref={leadModalRef}
        dealershipId={props.dealershipId}
        dealershipTimezone={props.dealershipTimezone}
      />
    </div>
  );
});

export default SemVendors;
