import React, {useState} from "react";
import "./profile.css"
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {IStore, IUser, IUserDto} from "../../../index.dts";
import Util from "../../util/Util";
import {
    disableDesktopNotifications,
    enableDesktopNotifications,
    updateUser,
    updateUserPassword
} from "../../actions/userActions";
import {userUpdate} from "../../actions/authActions";
import Axios from "../../config/Axios";
import {useHistory} from "react-router-dom";
import {useForceUpdate} from "../../hooks/useForceUpdate";
import SelectableImage from "../../components/selectable_image/SelectableImage";
import {InputSwitch} from "primereact/inputswitch";
import {Panel} from "primereact/panel";

const WARNINGS = {
    firstName: "",
    lastName: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
};

export function Profile() {
    const history = useHistory();
    const dispatch = useDispatch();
    const forceUpdate = useForceUpdate();
    const user: IUser = useSelector((store: IStore) => store.auth.user, shallowEqual);

    const [firstName, setFirstName] = useState<string>(user.firstName);
    const [lastName, setLastName] = useState<string>(user.lastName);
    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [profilePicture, setProfilePicture] = useState<File>();
    const avatarURL = user.avatarObjectId ? Util.getAssetUrl(user.avatarObjectId) : undefined;

    const resetWarnings = () => {
        WARNINGS.firstName = "";
        WARNINGS.lastName = "";
        WARNINGS.oldPassword = "";
        WARNINGS.newPassword = "";
        WARNINGS.confirmPassword = ""
    };

    const isValid = (): boolean => {
        resetWarnings();

        if (Util.isEmpty(firstName)) {
            WARNINGS.firstName = "Invalid First Name.";
        }


        if (Util.isEmpty(lastName)) {
            WARNINGS.lastName = "Invalid Last Name";
        }

        if (!Util.isEmpty(newPassword) || !Util.isEmpty(confirmPassword) || !Util.isEmpty(oldPassword)) {
            if (Util.isEmpty(newPassword) || !Util.validatePassword(newPassword))
                WARNINGS.newPassword = "Invalid New Password";

            if (Util.isEmpty(confirmPassword) || newPassword !== confirmPassword)
                WARNINGS.confirmPassword = "Confirmation password do not match";

            if (Util.isEmpty(oldPassword))
                WARNINGS.oldPassword = "Old password can't be empty string";
        }

        return !(WARNINGS.firstName || WARNINGS.lastName || WARNINGS.newPassword || WARNINGS.confirmPassword || WARNINGS.oldPassword);
    };

    const onSubmit = () => {
        if (!isValid()) {
            forceUpdate();
            return;
        }


        const isProfileChanged: boolean = firstName !== user.firstName ||
            lastName !== user.lastName ||
            profilePicture instanceof File;
        const isPasswordChanged: boolean = !Util.isEmpty(newPassword);
        const futures: Promise<any>[] = [];
        const userDTO: IUserDto = {
            firstName: firstName,
            lastName: lastName,
            avatarObjectId: user.avatarObjectId
        };

        if (isProfileChanged && isPasswordChanged) {
            const userModifier = updateUser(userDTO, profilePicture);
            const passwordModifier = updateUserPassword(oldPassword, newPassword);

            futures.push(userModifier, passwordModifier);
        } else if (isProfileChanged) {
            const userModifier = updateUser(userDTO, profilePicture);

            futures.push(userModifier);
        } else if (isPasswordChanged) {
            const passwordModifier = updateUserPassword(oldPassword, newPassword);

            futures.push(passwordModifier);
        } else {
            Util.warning("There are no changes");
            return;
        }

        Util.globalSpinner().show()
        Axios.all(futures)
            .then(results => {
                history.push("/");
                dispatch(userUpdate(results[0].data));
                Util.success("User profile successfully updated");
            })
            .catch(error => Util.showError(error))
            .finally(() => Util.globalSpinner().hide());
    };

    const onSelectProfilePicture = (file: File) => setProfilePicture(file);

    const onEnableDesktopNotificationsChange = (value: boolean): void => {
        Util.globalSpinner().show();
        if (value) {
            enableDesktopNotifications()
                .then(() => {
                    user.desktopNotificationsEnabled = true;
                    Util.success("Desktop notifications enabled successfully.");

                    forceUpdate();

                    if (window.confirm("To apply updated settings to site, reload this page.")) {
                        window.location.reload()
                    }
                })
                .catch(error => Util.showError(error))
                .finally(() => Util.globalSpinner().hide())
        } else {
            disableDesktopNotifications()
                .then(() => {
                    user.desktopNotificationsEnabled = false;
                    Util.success("Desktop notifications disabled successfully.");

                    forceUpdate();
                })
                .catch(error => Util.showError(error))
                .finally(() => Util.globalSpinner().hide())
        }
    }

    return (
        <div id={"profile-view"}>
            <Panel header={(
                <div style={{display: "flex", height: 18}}>
                    <label>Allow Desktop Notifications</label>
                    <div style={{marginTop: 2, marginLeft: 5}}>
                        <InputSwitch
                            checked={user.desktopNotificationsEnabled}
                            onChange={(e: any) => onEnableDesktopNotificationsChange(e.target.value)}/>
                    </div>
                </div>
            )}>
                <div className={"p-grid"}>
                    <div className="p-col-12">

                        <div className={"p-grid"}>
                            <div className={"p-col-12 p-lg-3 p-label-col"}>
                                <div className={"label-container"}>
                                    <label className={"input-label"}>Profile image</label>
                                </div>
                            </div>
                            <div style={{margin: 8, maxWidth: 200}}>
                                <SelectableImage
                                    url={avatarURL}
                                    inputId="profile_picture_select_input"
                                    onChange={onSelectProfilePicture}
                                />
                            </div>
                        </div>

                        <div className={"p-grid"}>
                            <div className={"p-col-12 p-lg-3 p-label-col"}>
                                <div className={"label-container"}>
                                    <label className={"input-label"}>First Name</label>
                                </div>
                            </div>
                            <div className={"p-col-12 p-lg-9"}>
                                <InputText
                                    value={firstName}
                                    style={{width: "100%"}}
                                    onChange={(e: any) => setFirstName(e.target.value)}
                                />
                                <span className={"warning-label"}>{WARNINGS.firstName}</span>
                            </div>
                        </div>

                        <div className={"p-grid"}>
                            <div className={"p-col-12 p-lg-3 p-label-col"}>
                                <div className={"label-container"}>
                                    <label className={"input-label"}>Last Name</label>
                                </div>
                            </div>
                            <div className={"p-col-12 p-lg-9"}>
                                <InputText
                                    value={lastName}
                                    style={{width: "100%"}}
                                    onChange={(e: any) => setLastName(e.target.value)}
                                />
                                <span className={"warning-label"}>{WARNINGS.lastName}</span>
                            </div>
                        </div>

                        <div className={"p-grid"}>
                            <div className={"p-col-12 p-lg-3 p-label-col"}>
                                <div className={"label-container"}>
                                    <label className={"input-label"}>Password</label>
                                </div>
                            </div>
                            <div className={"p-col-12 p-lg-9"}>
                                <Password
                                    feedback={false}
                                    value={oldPassword}
                                    style={{width: "100%"}}
                                    autoComplete={"new-password"}
                                    onChange={(e: any) => setOldPassword(e.target.value)}
                                />
                                <span className={"warning-label"}>{WARNINGS.oldPassword}</span>
                            </div>
                        </div>

                        <div className={"p-grid"}>
                            <div className={"p-col-12 p-lg-3 p-label-col"}>
                                <div className={"label-container"}>
                                    <label className={"input-label"}>New Password</label>
                                </div>
                            </div>
                            <div className={"p-col-12 p-lg-9"}>
                                <Password
                                    value={newPassword}
                                    style={{width: "100%"}}
                                    autoComplete={"new-password"}
                                    onChange={(e: any) => setNewPassword(e.target.value)}
                                />
                                <span className={"warning-label"}>{WARNINGS.newPassword}</span>
                            </div>
                        </div>

                        <div className={"p-grid"}>
                            <div className={"p-col-12 p-lg-3 p-label-col"}>
                                <div className={"label-container"}>
                                    <label className={"input-label"}>Confirm Password</label>
                                </div>
                            </div>
                            <div className={"p-col-12 p-lg-9"}>
                                <Password
                                    feedback={false}
                                    value={confirmPassword}
                                    style={{width: "100%"}}
                                    autoComplete={"new-password"}
                                    onChange={(e: any) => setConfirmPassword(e.target.value)}
                                />
                                <span className={"warning-label"}>{WARNINGS.confirmPassword}</span>
                            </div>
                        </div>

                        <div className={"p-grid"}>
                            <div className={"p-col-12 p-lg-3 p-label-col"}>
                                <div className={"label-container"}>
                                    <label className={"input-label"}>Role</label>
                                </div>
                            </div>
                            <div className={"p-col-12 p-lg-9"}>
                                <InputText
                                    readOnly={true}
                                    disabled={true}
                                    defaultValue={user.role.name}
                                    style={{width: "100%"}}
                                />
                            </div>
                        </div>

                        <div className={"p-grid"}>
                            <div className={"p-col-12 p-lg-3 p-label-col"}>
                                <div className={"label-container"}>
                                    <label className={"input-label"}>Dealership</label>
                                </div>
                            </div>
                            <div className={"p-col-12 p-lg-9"}>
                                <InputText
                                    readOnly={true}
                                    disabled={true}
                                    placeholder={"e.g John Doe's Make"}
                                    style={{width: "100%"}}
                                />
                            </div>
                        </div>

                        <div className={"p-grid"}>
                            <div className={"p-col-12 p-lg-3 p-label-col"}>
                                <div className={"label-container"}>
                                    <label className={"input-label"}>Email Address</label>
                                </div>
                            </div>
                            <div className={"p-col-12 p-lg-9"}>
                                <InputText
                                    readOnly={true}
                                    disabled={true}
                                    defaultValue={user.email}
                                    style={{width: "100%"}}
                                />
                            </div>
                        </div>

                        <Button onClick={onSubmit} label={"Save"} icon={"pi pi-save"}/>
                    </div>
                </div>
            </Panel>
        </div>
    )
}

export default Profile
