import "./select-actions.css";
import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import {
  ICampaignInfo,
  IChatGreeting,
  IMyTrafficNotificationDto,
  IMyTrafficNotificationFull,
  IOffer,
  IStore,
} from "../../../../index.dts";
import { getChatGreetings } from "../../../actions/chatGreetingActions";
import { getMyCampaignsNotification } from "../../../actions/MyTrafficNotificationActions";
import {
  getMyCampaignsOffer,
  getMyCampaignsOfferList,
} from "../../../actions/offerActions";
import Util from "../../../util/Util";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Panel } from "primereact/panel";
import InfiniteScroll from "react-infinite-scroll-component";
import { Alert, useMediaQuery } from "@mui/material";
import LoadMorePagination from "./LoadMorePagination";
import { Checkbox } from "primereact/checkbox";
import { MODE } from "../../../util/Enums";
import { get, isEqual } from "lodash";
import {
  getActiveNotificationsList,
  updateMyCampaign,
} from "../../../actions/campaignsActions";
import { useHistory } from "react-router-dom";
import moment from "moment";

type Props = {
  setCampaignInfo: React.Dispatch<React.SetStateAction<ICampaignInfo>>;
  campaignInfo: ICampaignInfo;
  setStep: React.Dispatch<
    React.SetStateAction<{
      step: number | string;
      payload?: any;
    }>
  >;
  mode: MODE.EDIT | MODE.NEW | MODE.DUPLICATE;
  initialData: ICampaignInfo;
  setInitialData: React.Dispatch<React.SetStateAction<ICampaignInfo>>;
};

const SelectActions = (props: Props) => {
  const history = useHistory();
  const {
    setCampaignInfo,
    campaignInfo,
    setStep,
    mode,
    initialData,
    setInitialData,
  } = props;
  const [offersPage, setOffersPage] = useState<number>(0);
  const [greetingsPage, setGreetingsPage] = useState<number>(0);
  const [hasMoreOffers, setHasMoreOffers] = useState<boolean>(true);
  const [hasNoNotifications, setHasNoNotifications] = useState(false);
  // const [notificationsPage, setNotificationsPage] = useState<number>(0);
  // const [hasMoreNotifications, setHasMoreNotifications] =
  //   useState<boolean>(true);
  const [hasMoreGreetings, setHasMoreGreetings] = useState<boolean>(true);
  const phone = useMediaQuery("(max-width:700px)");

  const [offerList, setOfferList] = useState<{
    data: any;
    totalElements?: number;
  }>({ data: [] });
  const [chatGreetings, setChatGreetings] = useState<{
    data: any;
    totalElements?: number;
  }>({ data: [] });
  const [notifications, setNotifications] = useState<any[]>([]);
  const [selectedOffer, setSelectedOffer] = useState<IOffer>();
  const [selectedGreeting, setSelectedGreeting] = useState<IChatGreeting>();
  const [selectedNotifications, setSelectedNotifications] = useState<any>([]);
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  useEffect(() => {
    getMyCampaignsOfferList({
      pageNumber: 0,
      pageLimit: 5,
      sorting: [],
      dealershipName: dealershipContext?.name,
      dealershipIds: [dealershipContext?.id],
    })
      .then((offerRes) => {
        setOfferList({
          data: offerRes?.data?.content,
          totalElements: offerRes?.data?.totalElements,
        });
        setOffersPage(0);
      })
      .finally(() => window.scrollTo(0, 0))
      .catch((error) => {
        Util.showError(error);
      });
    getChatGreetings(dealershipContext.id, {
      filter: [],
      sorting: [],
      paging: {
        page: 0,
        pageLimit: 10,
      },
    })
      .then((greetingsResponse) => {
        setChatGreetings({
          data: greetingsResponse?.data?.content,
          totalElements: greetingsResponse?.data?.totalElements,
        });
        setGreetingsPage(0);
      })
      .finally(() => window.scrollTo(0, 0))
      .catch((error) => {
        Util.showError(error);
      });
    getActiveNotificationsList({
      dealershipId: dealershipContext?.id,
      myCampaignId: campaignInfo?.id || null,
    })
      .then((notificationResponse) => {
        if (notificationResponse?.data?.length <= 0) {
          setHasNoNotifications(true);
        }
        setNotifications(notificationResponse?.data);
      })
      .catch((error) => {
        setHasNoNotifications(true);
        Util.showError(error);
      });
    if (campaignInfo?.offer) {
      setSelectedOffer(campaignInfo?.offer);
    }
    if (campaignInfo?.greeting) {
      setSelectedGreeting(campaignInfo?.greeting);
    }
    if (campaignInfo?.notifications) {
      setSelectedNotifications(campaignInfo?.notifications);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previewBody = (props: IChatGreeting): React.ReactNode => {
    return (
      <div className={"rts-template-item"}>
        <div
          id={"rts-template-chat-permission-container"}
          style={{ backgroundColor: `#${props.backgroundColor}` }}
        >
          <img
            id={"rts-template-chat-permission-close-svg"}
            src={"assets/icons/close.svg"}
            alt={""}
          />
          <div id={"rts-template-chat-permission-header"}>{props.name}</div>
          <div id={"rts-template-chat-permission-question-container"}>
            <div id={"rts-template-chat-permission-question-header"}>
              {props.message}
            </div>
            <div
              id={"rts-template-chat-permission-question-button"}
              style={{ backgroundColor: `#${props.buttonBackgroundColor}` }}
            >
              <div id={"rts-template-chat-permission-question-yes"}>
                <span>
                  {props.buttonLabel}{" "}
                  <img
                    id={"rts-template-chat-permission-question-arrow"}
                    src={"assets/icons/arrow.svg"}
                    alt={""}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getEditOfferPreview = (offer: any): React.ReactElement | null => {
    return (
      <img
        alt=""
        width={phone ? 250 : 200}
        src={Util.getAssetUrl(
          offer?.asset?.objectId
            ? offer?.asset?.objectId
            : offer?.template?.assetObjectId
        )}
      />
    );
  };

  const transformNotificationsToDto = (
    notifications: IMyTrafficNotificationFull[]
  ): IMyTrafficNotificationDto[] => {
    const formattedDtos: IMyTrafficNotificationDto[] = notifications.map(
      (notification) => {
        return {
          ...((mode === MODE.EDIT || mode === MODE.DUPLICATE) && {
            id: notification?.id || null,
          }),
          name: notification?.name,
          dealershipId: dealershipContext.id,
          segment: notification?.segment,
          message: notification?.message,
          engagementScore: notification?.engagementScore,
          delaySend: notification?.delaySend,
          limitByZipCodes: notification?.limitByZipCodes,
          enabled: notification?.enabled,
          zipCodes: notification?.zipCodes,
          useChatBot: notification?.useChatBot,
          useSharkTank: notification?.useSharkTank,
          chatbotId: notification?.chatbotId,
          chatGreetingId: notification?.chatGreetingId,
          users: notification?.users?.map((user, index) => {
            return {
              userId: user?.userId,
              order: notification?.users?.length - index,
            };
          }),
          chatBotWorkflows: notification?.chatBotWorkflows?.map((item) => {
            return {
              chatBotId: item.chatBot.id,
              chatGreetingId: item.chatGreeting.id,
              daysOfWeek: item.daysOfWeek,
              endTime: item.endTime,
              startTime: item.startTime,
            };
          }),
        };
      }
    );
    return formattedDtos;
  };

  const onNext = () => {
    if (selectedGreeting || selectedOffer) {
      setCampaignInfo((prev) => {
        return {
          ...prev,
          greeting: selectedGreeting ? selectedGreeting : undefined,
          offer: selectedOffer ? selectedOffer : undefined,
          notifications:
            selectedNotifications?.length > 0
              ? selectedNotifications
              : undefined,
        };
      });
      setStep((prev: any) => {
        return {
          ...prev,
          step: (prev?.step as number) + 1,
        };
      });
    }
  };

  const onBack = () => {
    setStep((prev: any) => {
      return {
        ...prev,
        step: (prev?.step as number) - 1,
      };
    });
  };

  const onPageOffers = () => {
    getMyCampaignsOfferList({
      pageNumber: offersPage + 1,
      pageLimit: 5,
      sorting: [],
      dealershipName: dealershipContext?.name,
    })
      .then((offerRes) => {
        setOfferList((prev) => {
          return {
            data:
              prev?.data?.length > 0
                ? [...prev?.data, ...offerRes?.data?.content]
                : [...offerRes?.data?.content],
            totalElements: prev?.totalElements as number,
          };
        });
        setOffersPage((prev) => prev + 1);
        setHasMoreOffers(offerRes.data.totalPages !== offerRes.data.number);
      })
      .catch((error) => {
        Util.showError(error);
      });
  };

  // const onPageNotifications = () => {
  //   getMyTrafficNotificationList({
  //     pageNumber: notificationsPage + 1,
  //     pageLimit: 30,
  //     sorting: [],
  //     dealershipIds: [dealershipContext?.id],
  //   })
  //     .then((notifRes) => {
  //       setNotifications((prev) => {
  //         return {
  //           data:
  //             prev?.data?.length > 0
  //               ? [...prev?.data, ...notifRes?.data?.content]
  //               : [...notifRes?.data?.content],
  //           totalElements: prev?.totalElements as number,
  //         };
  //       });
  //       setNotificationsPage((prev) => prev + 1);
  //       setHasMoreNotifications(
  //         notifRes.data.totalPages !== notifRes.data.number
  //       );
  //     })
  //     .catch((error) => {
  //       Util.showError(error);
  //     });
  // };

  const onPageGreetings = () => {
    getChatGreetings(dealershipContext.id, {
      filter: [],
      sorting: [],
      paging: {
        page: greetingsPage + 1,
        pageLimit: 10,
      },
    })
      .then((greetingsResponse) => {
        setChatGreetings((prev) => {
          return {
            data:
              prev?.data?.length > 0
                ? [...prev?.data, ...greetingsResponse?.data?.content]
                : [...greetingsResponse?.data?.content],
            totalElements: prev?.totalElements as number,
          };
        });
        setGreetingsPage((prev) => prev + 1);
        setHasMoreGreetings(
          greetingsResponse.data.totalPages !== greetingsResponse.data.number
        );
      })
      .catch((error) => {
        Util.showError(error);
      });
  };

  return (
    <Panel
      style={{ width: "100%", marginBottom: 10 }}
      header={mode === MODE.NEW ? "Select Actions" : "Edit Notifications"}
    >
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div className="select-actions-main-container p-col-12">
            {(mode === MODE.EDIT || mode === MODE.DUPLICATE) &&
              campaignInfo?.greeting && (
                <div
                  className={"p-grid p-col-6"}
                  style={{
                    marginBottom: "20px",
                    pointerEvents: "none",
                    opacity: 0.6,
                    alignContent: "baseline",
                  }}
                >
                  <div
                    className={"p-col-12 p-label-col"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "2rem",
                    }}
                  >
                    <div className={"label-container"}>
                      <label className="select-actions-label">Greeting</label>
                    </div>
                  </div>
                  <div
                    className={"p-col-12 p-label-col"}
                    style={{ display: "flex" }}
                  >
                    {previewBody(campaignInfo?.greeting)}
                  </div>
                </div>
              )}
            {(mode === MODE.EDIT || mode === MODE.DUPLICATE) &&
              campaignInfo?.offer && (
                <div
                  className={"p-grid p-col-6"}
                  style={{
                    marginBottom: "20px",
                    pointerEvents: "none",
                    opacity: 0.6,
                    alignContent: "baseline",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className={"p-col-12 p-label-col"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "2rem",
                    }}
                  >
                    <div className={"label-container"}>
                      <label className="select-actions-label">Offer</label>
                    </div>
                  </div>
                  <div>
                    <span
                      style={{
                        fontWeight: "bold",
                        maxWidth: "200px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {campaignInfo?.offer?.name}
                    </span>
                    <div
                      className={"p-col-12 p-label-col"}
                      style={{ display: "flex" }}
                    >
                      {getEditOfferPreview(campaignInfo?.offer)}
                    </div>
                  </div>
                </div>
              )}
            {mode === MODE.NEW && (
              <div
                className="select-actions-section"
                // style={{
                //   ...((mode === MODE.EDIT || mode === MODE.DUPLICATE) && {
                //     pointerEvents: "none",
                //     opacity: 0.6,
                //   }),
                // }}
              >
                <label className="select-actions-label">Offers</label>
                <div className="select-actions-offers">
                  {phone ? (
                    <LoadMorePagination
                      apiRequest={(page, limit) => {
                        return getMyCampaignsOfferList({
                          pageNumber: page,
                          pageLimit: limit,
                          sorting: [],
                          dealershipName: dealershipContext?.name,
                        });
                      }}
                      limit={3}
                      style={{ marginBottom: "20px" }}
                      renderBody={(data: any) => {
                        return (
                          <div className="select-actions-load-container">
                            {data?.map((offer: any, index: number) => {
                              return (
                                <div key={index}>
                                  <div
                                    className="select-actions-mini-container-offers"
                                    key={offer?.id}
                                  >
                                    <RadioButton
                                      checked={selectedOffer?.id === offer?.id}
                                      onChange={(e) => {
                                        if (e?.checked) {
                                          if (selectedGreeting) {
                                            setSelectedGreeting(undefined);
                                          }
                                          Util.globalSpinner().show();
                                          getMyCampaignsOffer(offer?.id, {
                                            dealershipId: dealershipContext?.id,
                                            filters:
                                              props?.campaignInfo?.filters,
                                          })
                                            .then((res) => {
                                              setSelectedOffer(res?.data);
                                            })
                                            .catch((error) => {
                                              Util.showError(error);
                                            })
                                            .finally(() => {
                                              Util.globalSpinner().hide();
                                              window.scrollTo(0, 0);
                                            });
                                        } else {
                                          setSelectedOffer(undefined);
                                        }
                                      }}
                                    />
                                    <div>
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          maxWidth: "200px",
                                        }}
                                      >
                                        {offer?.name}
                                      </span>
                                      {getEditOfferPreview(offer)}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }}
                    />
                  ) : (
                    <InfiniteScroll
                      dataLength={offerList?.data?.length}
                      next={onPageOffers}
                      hasMore={hasMoreOffers}
                      loader={<h4>Loading...</h4>}
                      height={400}
                    >
                      {offerList?.data?.map((offer: any, index: number) => (
                        <div key={index}>
                          <div
                            className="select-actions-mini-container-offers"
                            key={offer?.id}
                          >
                            <RadioButton
                              checked={selectedOffer?.id === offer?.id}
                              onChange={(e) => {
                                if (e?.checked) {
                                  if (selectedGreeting) {
                                    setSelectedGreeting(undefined);
                                  }
                                  Util.globalSpinner().show();
                                  getMyCampaignsOffer(offer?.id, {
                                    dealershipId: dealershipContext?.id,
                                    filters: props?.campaignInfo?.filters,
                                  })
                                    .then((res) => setSelectedOffer(res?.data))
                                    .catch((error) => {
                                      Util.showError(error);
                                    })
                                    .finally(() => {
                                      Util.globalSpinner().hide();
                                      window.scrollTo(0, 0);
                                    });
                                } else {
                                  setSelectedOffer(undefined);
                                }
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  maxWidth: "200px",
                                }}
                              >
                                {offer?.name}
                              </span>
                              {getEditOfferPreview(offer)}
                            </div>
                          </div>
                        </div>
                      ))}
                    </InfiniteScroll>
                  )}
                </div>
              </div>
            )}
            {mode === MODE.NEW && (
              <div
                className="select-actions-section"
                // style={{
                //   ...((mode === MODE.EDIT || mode === MODE.DUPLICATE) && {
                //     pointerEvents: "none",
                //     opacity: 0.6,
                //   }),
                // }}
              >
                <label className="select-actions-label">Greetings</label>
                {hasNoNotifications ? (
                  <Alert severity="warning" sx={{ margin: "0 10px" }}>
                    Greetings are not displayed because they require
                    notifications and no notifications exist.
                  </Alert>
                ) : (
                  <div className="select-actions-greetings">
                    {phone ? (
                      <LoadMorePagination
                        apiRequest={(page, limit) => {
                          return getChatGreetings(dealershipContext.id, {
                            filter: [],
                            sorting: [],
                            paging: {
                              page: page,
                              pageLimit: limit,
                            },
                          });
                        }}
                        limit={3}
                        style={{ marginBottom: "20px" }}
                        renderBody={(data: any) => {
                          return (
                            <div className="select-actions-load-container">
                              {chatGreetings?.data?.map((greeting: any) => {
                                return (
                                  <div
                                    className="select-actions-mini-container-greetings"
                                    key={greeting?.id}
                                  >
                                    <RadioButton
                                      checked={
                                        selectedGreeting?.id === greeting?.id
                                      }
                                      onChange={(e) => {
                                        if (e?.checked) {
                                          if (selectedOffer) {
                                            setSelectedOffer(undefined);
                                          }
                                          setSelectedGreeting(greeting);
                                        } else {
                                          setSelectedGreeting(undefined);
                                        }
                                      }}
                                    />
                                    {previewBody(greeting)}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        }}
                      />
                    ) : (
                      <InfiniteScroll
                        dataLength={chatGreetings?.data?.length}
                        next={onPageGreetings}
                        hasMore={hasMoreGreetings}
                        loader={<h4>Loading...</h4>}
                        height={400}
                      >
                        {chatGreetings?.data?.map((greeting: any) => {
                          return (
                            <div
                              className="select-actions-mini-container-greetings"
                              key={greeting?.id}
                            >
                              <RadioButton
                                checked={selectedGreeting?.id === greeting?.id}
                                onChange={(e) => {
                                  if (e?.checked) {
                                    if (selectedOffer) {
                                      setSelectedOffer(undefined);
                                    }
                                    setSelectedGreeting(greeting);
                                  } else {
                                    setSelectedGreeting(undefined);
                                  }
                                }}
                              />
                              {previewBody(greeting)}
                            </div>
                          );
                        })}
                      </InfiniteScroll>
                    )}
                  </div>
                )}
              </div>
            )}
            <div
              className={`select-actions-section ${
                (mode === MODE.EDIT || mode === MODE.DUPLICATE) && "p-col-6"
              }`}
            >
              <label className="select-actions-label">Notifications</label>
              <div className="select-actions-notifications">
                <div
                  className="select-actions-load-container"
                  style={{ overflow: "auto" }}
                >
                  {!hasNoNotifications ? (
                    notifications?.map((notification: any) => {
                      return (
                        <div
                          className="select-actions-mini-container-notifications"
                          key={notification?.id}
                        >
                          <Checkbox
                            checked={selectedNotifications.some(
                              (m: any) => m.id === notification?.id
                            )}
                            onChange={(e) => {
                              if (e?.checked) {
                                Util.globalSpinner().show();
                                getMyCampaignsNotification(notification?.id, {
                                  dealershipId: dealershipContext?.id,
                                  filters: props?.campaignInfo?.filters,
                                })
                                  .then((res) =>
                                    setSelectedNotifications((prev: any) => [
                                      ...prev,
                                      res?.data,
                                    ])
                                  )
                                  .catch((error: any) => {
                                    Util.showError(error);
                                  })
                                  .finally(() => {
                                    Util.globalSpinner().hide();
                                  });
                              } else {
                                const filteredArray =
                                  selectedNotifications?.filter(
                                    (selectedNotification: any) =>
                                      selectedNotification?.id !==
                                      notification?.id
                                  );
                                setSelectedNotifications(filteredArray);
                              }
                            }}
                          />
                          <span>{notification?.name}</span>
                        </div>
                      );
                    })
                  ) : (
                    <Alert severity="warning" sx={{ margin: "0 10px" }}>
                      No notifications exist. Go to My Traffic to create
                      notifications.
                    </Alert>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              onClick={onBack}
              className={"p-button-warning"}
              icon={"pi pi-arrow-left"}
              label={"Back"}
            />
            <Button
              style={{ marginRight: 5 }}
              label={"Next"}
              icon={"pi pi-save"}
              disabled={
                (!selectedGreeting && !selectedOffer) ||
                (selectedGreeting && selectedNotifications?.length <= 0)
              }
              onClick={() => onNext()}
            />
            {(mode === MODE.EDIT) && (
              <Button
                style={{ marginRight: 15 }}
                label={"Update and Exit"}
                icon={"pi pi-save"}
                disabled={isEqual(campaignInfo, initialData)}
                onClick={() => {
                  Util.globalSpinner().show();
                  updateMyCampaign(campaignInfo?.id as number, {
                    active: campaignInfo?.active,
                    knownVisitor: campaignInfo?.knownVisitor,
                    name: campaignInfo?.name,
                    vehicleFilters: campaignInfo?.filters,
                    expirable: get(campaignInfo, "expirable"),
                    startDate: campaignInfo?.startDate
                      ? moment(campaignInfo?.startDate).format(
                          Util.localDateFormat
                        )
                      : (undefined as any),
                    endDate: campaignInfo?.endDate
                      ? moment(campaignInfo?.endDate).format(
                          Util.localDateFormat
                        )
                      : (undefined as any),
                    automaticallyDisabled: campaignInfo?.automaticallyDisabled,
                    ...(campaignInfo?.notifications &&
                      campaignInfo?.notifications?.length > 0 && {
                        rtsNotifications: transformNotificationsToDto(
                          campaignInfo?.notifications
                        ),
                      }),
                    ...(campaignInfo?.sources &&
                      campaignInfo?.sources?.length > 0 && {
                        semVendorIds: campaignInfo?.sources,
                      }),
                  })
                    .then(() => {
                      setInitialData(campaignInfo);
                      setTimeout(() => {
                        history?.push("/my-campaigns");
                      }, 1000);
                    })
                    .catch((error: any) => {
                      Util.showError(error);
                    })
                    .finally(() => {
                      Util.globalSpinner().hide();
                    });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default SelectActions;
