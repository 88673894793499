import { Button } from "primereact/button";
import React, { useEffect } from "react";
import { useState } from "react";

type Props = {
  apiRequest: (page: number, limit: number) => Promise<any>;
  limit: number;
  renderBody?: any;
  style?: any;
};

const LoadMorePagination = (props: Props) => {
  const { apiRequest, limit, renderBody, style } = props;
  const [data, setData] = useState<{ data: any; totalElements: number }>();
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const getDataFromApi = (page: number) => {
    if (apiRequest) {
      apiRequest(page, limit).then((res: any) => {
        setData((prev: any) => {
          return {
            data:
              prev?.data?.length > 0
                ? [...prev?.data, ...res?.data?.content]
                : [...res?.data?.content],
            totalElements: prev?.totalElements as number,
          };
        });
        setPageNumber((prev) => prev + 1);
        setHasMore(res.data.totalPages !== res.data.number);
      });
    }
  };

  useEffect(() => {
    getDataFromApi(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNextPage = () => {
    getDataFromApi(pageNumber);
  };

  const renderData = () => {
    if (renderBody) {
      return renderBody(data?.data);
    }
    return null;
  };

  return (
    <div style={{ overflow: "auto", ...(style ? style : {}) }}>
      {renderData()}
      {hasMore && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            className={"p-button-warning"}
            label={"Load More"}
            onClick={onNextPage}
          />
        </div>
      )}
    </div>
  );
};

export default LoadMorePagination;
