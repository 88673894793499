import React, { useEffect, useRef, useState } from "react";
import { Panel } from "primereact/panel";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import GroupUserDealerships from "../GroupUserDealerships";
import { get, map, forEach, isEqual } from "lodash";
import { MODE } from "../../../util/Enums";
import {
  createEnterpriseGroup,
  getEnterpriseGroupDealerships,
  getEnterpriseGroup,
  updateEnterpriseGroup,
} from "../../../actions/enterpriseGroupActions";
import Util from "../../../util/Util";
import { IGroupDealership, ISelectOption } from "../../../../index.dts";
import GroupDealerships from "../GroupDealerships";
import Tags from "../../tags/Tags";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import "./style.css";
import { confirmAlert } from "react-confirm-alert";
import EnterpriseUsers from "./EnterpriseUsers";

const hash: any = require("object-hash");

interface IProps {
  readonly location: {
    readonly state: { [key: string]: any };
  };
}

const EnterpriseGroupForm = (props: IProps) => {
  const history = useHistory();
  const unblockHandle = useRef<any>();
  const forceUpdate = useForceUpdate();

  const [mode] = useState<MODE>(get(props, "location.state.mode", MODE.NEW));
  const [id, setId] = useState<number>(get(props, "location.state.id", null));

  const [name, setName] = useState<string>("");
  const [users, setUsers] = useState<ISelectOption[]>([]);
  const [description, setDescription] = useState<string>("");
  const [publicGroup, setPublicGroup] = useState<boolean>(true);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [groupDealerships, setGroupDealerships] = useState<IGroupDealership[]>(
    []
  );

  const [groupHash, setGroupHash] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (mode === MODE.EDIT) {
      getEnterpriseGroupById(get(props, "location.state.id", null));
    } else {
      setGroupHash(generateHash(getHashArg()));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    unblockHandle.current = history.block((targetLocation: any) => {
      if (isSaving) {
        return true; // Allow navigation if saving
      }
      return onLeave(targetLocation);
    });
    return () => {
      unblockHandle.current.current && unblockHandle.current.current();
    };
    // eslint-disable-next-line
  }, [
    history.location,
    groupHash,
    name,
    description,
    publicGroup,
    groupDealerships,
    users,
    isSaving,
  ]);

  const getHashArg = (args?: any) => {
    return {
      name: Util.getDefaultIfNull(args?.name, name),
      description: Util.getDefaultIfNull(args?.description, description),
      publicGroup: Util.getDefaultIfNull(args?.publicGroup, publicGroup),
      users: Util.getDefaultIfNull(args?.users, users),
      groupDealerships: Util.getDefaultIfNull(
        args?.groupDealerships,
        groupDealerships
      ),
    };
  };

  const generateHash = (args: any): string => {
    return hash(args);
  };

  const onLeave = (targetLocation: any): boolean => {
    const currentHash = generateHash(getHashArg());

    if (isEqual(groupHash, currentHash)) {
      return true;
    }

    confirmAlert({
      title: "There are unsaved changes!",
      message: "Discard local changes, or save to continue!",
      buttons: [
        {
          label: "Save",
          className: "confirm-save-btn",
          onClick: () => {
            onSave(targetLocation);
          },
        },
        {
          label: "Discard",
          onClick: () => {
            setGroupHash(currentHash);
            setTimeout(() => {
              history.push(targetLocation);
            }, 0);
          },
          className: "confirm-discard-btn",
        },
        {
          label: "Cancel",
          className: "confirm-cancel-btn",
          onClick: () => {},
        },
      ],
    });

    return false;
  };

  const getEnterpriseGroupById = (id: number): void => {
    Util.globalSpinner().show();
    getEnterpriseGroup(id)
      .then((response) => {
        const name = response.data.name;
        const description = response.data.description;
        const publicGroup = response.data.publicGroup;
        const groupDealerships = response.data.dealerships;
        const users = response.data.users.map((item) => {
          return {
            value: item.id,
            label: item.email,
          };
        });

        setName(name);
        setUsers(users);
        setId(response.data.id);
        setDescription(description);
        setPublicGroup(publicGroup);
        setGroupDealerships(groupDealerships);

        setGroupHash(
          generateHash(
            getHashArg({
              name: name,
              users: users,
              description: description,
              publicGroup: publicGroup,
              groupDealerships: groupDealerships,
            })
          )
        );
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => {
        Util.globalSpinner().hide();
      });
  };

  const onAddDealerships = (value: IGroupDealership[]): void => {
    forEach(value, (item) => {
      const index = groupDealerships.findIndex((val) => val.id === item.id);

      if (index === -1) {
        groupDealerships.push(item);
      }
    });

    forceUpdate();
  };

  const onRemoveDealerships = (value: IGroupDealership[]): void => {
    forEach(value, (item) => {
      const index = groupDealerships.findIndex((val) => val.id === item.id);

      if (index !== -1) {
        groupDealerships.splice(index, 1);
      }
    });

    forceUpdate();
  };

  const validate = (): { [key: string]: string } => {
    const result: { [key: string]: string } = {};

    if (Util.isEmpty(name)) {
      result["name"] = "Please enter enterprise group name.";
    }

    if (Util.isEmpty(description)) {
      result["description"] = "Please enter enterprise group description.";
    }

    return result;
  };

  const onSave = (targetLocation?: any): void => {
    setIsSaving(true);
    const result = validate();

    setErrors(result);
    if (Object.keys(result).length > 0) {
      return;
    }

    if (id) {
      onUpdate(targetLocation);
    } else {
      onCreate(targetLocation);
    }
  };

  const onCreate = (targetLocation?: any): void => {
    Util.globalSpinner().show();
    createEnterpriseGroup({
      name: name,
      description: description,
      publicGroup: publicGroup,
      userIds: users.map((item) => item.value),
      dealershipIds: groupDealerships.map((item) => item.id),
    })
      .then(() => {
        setGroupHash(generateHash(getHashArg()));
        goBack(targetLocation);
        setIsSaving(false);
      })
      .catch((error) => {
        Util.showError(error);
        setIsSaving(false);
      })
      .finally(() => {
        Util.globalSpinner().hide();
      });
  };

  const onUpdate = (targetLocation?: any): void => {
    Util.globalSpinner().show();
    updateEnterpriseGroup(id, {
      name: name,
      description: description,
      publicGroup: publicGroup,
      userIds: users.map((item) => item.value),
      dealershipIds: groupDealerships.map((item) => item.id),
    })
      .then(() => {
        setGroupHash(generateHash(getHashArg()));
        goBack(targetLocation);
        setIsSaving(false);
      })
      .catch((error) => {
        Util.showError(error);
        setIsSaving(false);
      })
      .finally(() => {
        Util.globalSpinner().hide();
      });
  };

  const goBack = (targetLocation?: any): void => {
    setTimeout(() => {
      if (targetLocation) {
        history.push(targetLocation);
      } else {
        history.push(Util.PATH_NAMES.ENTERPRISE_GROUP);
      }
    });
  };

  const render = (): React.ReactElement => {
    return (
      <Panel
        header={
          <div>
            <label>Public</label>
            <InputSwitch
              className="public-inputswitch"
              checked={publicGroup}
              style={{ marginLeft: 5 }}
              onChange={(e) => setPublicGroup(e.value)}
            />
          </div>
        }
      >
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-6"}>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-2 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Name</label>
                  <span className={"red"}>*</span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-10"}>
                <InputText
                  value={name}
                  placeholder={"Name"}
                  style={{ width: "100%" }}
                  onChange={(e: any) => setName(e.target.value)}
                />
                <label className={"warning-label"}>{errors.name}</label>
              </div>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-2 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Description</label>
                  <span className={"red"}>*</span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-10"}>
                <InputText
                  value={description}
                  placeholder={"Description"}
                  style={{ width: "100%" }}
                  onChange={(e: any) => setDescription(e.target.value)}
                />
                <label className={"warning-label"}>{errors.description}</label>
              </div>
            </div>
          </div>
        </div>

        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-1 p-label-col"}>
            <div className={"label-container"}>
              <label className={"input-label"}>Users</label>
            </div>
          </div>
          <div className={"p-col-12 p-lg-11"}>
            <EnterpriseUsers users={users} onChange={setUsers} />
          </div>
        </div>

        <Panel id={"dealerships_panel"} header={"Dealerships"}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <GroupUserDealerships
                value={groupDealerships}
                onAdd={onAddDealerships}
                loadingOptions={getEnterpriseGroupDealerships}
              />
            </div>

            <div className={"p-col-12 p-lg-6"}>
              <GroupDealerships
                value={groupDealerships}
                onRemove={onRemoveDealerships}
              />
            </div>
          </div>
        </Panel>

        <div style={{ marginTop: 5 }}>
          <Button
            className="no-icon-buttons"
            label={"Save"}
            onClick={() => onSave()}
          />
          <Button
            label={"Back"}
            className={"p-button p-button-warning no-icon-buttons"}
            onClick={() => history.push(Util.PATH_NAMES.ENTERPRISE_GROUP)}
          />
        </div>
      </Panel>
    );
  };

  return render();
};

export const brandsBody = (data: IGroupDealership): React.ReactElement => {
  const brands: any = map(data.brands, (item) => item.name);
  return <Tags values={brands} />;
};

export const enterpriseGroupsBody = (
  data: IGroupDealership
): React.ReactElement => {
  const enterpriseGroups: any = map(data.enterpriseGroups, (item) => item.name);
  return <Tags values={enterpriseGroups} />;
};

export default EnterpriseGroupForm;
