import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {entries, get} from "lodash";
import Util from "../util/Util";
import {IVisitorDetailsKeys} from "../util/Enums";
import {IVisitorDetails} from "../../index.dts";

interface IProps {
  visitorRefId?: string;
  visitorDetails?: IVisitorDetails
}

const Calculation = (props: IProps) => {

  const attrMap: { [key: string]: string } = {
    [IVisitorDetailsKeys.sessionCountScore]: "Session count score",
    [IVisitorDetailsKeys.sessionTimeScore]: "Session time score",
    [IVisitorDetailsKeys.timeSincePrevSessionScore]: "Time since previous session score",
    [IVisitorDetailsKeys.daysAwayScore]: "Days away score",
    [IVisitorDetailsKeys.vdpViewsScore]: "VDP views score",
    [IVisitorDetailsKeys.vdpTimeScore]: "VDP time score",
    [IVisitorDetailsKeys.vdpScrollScore]: "VDP scroll score",
    [IVisitorDetailsKeys.srpTimeScore]: "SRP time score",
    [IVisitorDetailsKeys.srpScrollScore]: "SRP scroll score",
    [IVisitorDetailsKeys.totalScore]: "Total"
  }


  const tableRow = (key: string, value: string): React.ReactElement => {
    const reason = get(props.visitorDetails, `${key}.reason`, "");
    const result = get(props.visitorDetails, `${key}.result`, 0);
    return (
      <TableRow key={props.visitorRefId + key}>
        <TableCell component="th" scope="row">
          {value}
        </TableCell>
        <TableCell align="right">
          {Util.fmt_num(result)}
        </TableCell>
        <TableCell align="right">
          {reason}
        </TableCell>
      </TableRow>
    )
  }


  return (
    <React.Fragment>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell align="right">Value</TableCell>
            <TableCell align="right">Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries(attrMap).map(([key, value]) => tableRow(key, value))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}

export default Calculation