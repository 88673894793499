import React, { useEffect, useState } from "react";
import "./dealerships.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import {
  activateDealerships,
  deactivateDealerships,
  getAllDealerships,
} from "../../actions/dealershipActions";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import moment from "moment-timezone";
import Util from "../../util/Util";
import { MODE, PERMISSIONS, SEARCH_OPERATIONS } from "../../util/Enums";
import { useTranslation } from "react-i18next";
import { IDS } from "../constants";
import { DateRangePickerWidget } from "../../components/data_range_picker/DateRangePickerWidget";
import { DateRangePeriods } from "../../components/data_range_picker/date_range_periods";

let searchTimeout: any;
let searchTimeoutInterval: number = 0;
type status = "all" | "active" | "inactive" | "paused";

const timezones: { label: string; value: any }[] = Util.getTimezones();
timezones.unshift({ label: "All", value: null });

export function Dealerships() {
  const { t }: any = useTranslation();
  const history = useHistory();

  const [sortMap] = useState<Map<string, { field: string; order: 1 | 0 | -1 }>>(
    new Map()
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState<number>(0);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [selectedDealerships, setSelectedDealerships] = useState<any[]>([]);
  const [dealerships, setDealerships] = useState<any[]>([]);

  const [nameFilterValue, setNameFilterValue] = useState<string>("");
  const [addressFilterValue, setAddressFilterValue] = useState<string>("");
  //const period = DateRangePeriods.get(DateRangePeriods.keys.last30Days);
  const period = {
    start: undefined,
    end: undefined
  }
  const [createdPeriod, setCreatedPeriod] = useState<{
    start?: Date;
    end?: Date;
  }>({
    start: period.start,
    end: period.end,
  });
  const [updatedPeriod, setUpdatedPeriod] = useState<{
    start?: Date;
    end?: Date;
  }>({
    start: period.start,
    end: period.end,
  });
  const [dealershipStatusValue, setDealershipStatusValue] =
    useState<status>("all");
  const [crmSourceNameFilterValue, setCrmSourceNameFilterValue] =
    useState<string>("");

  useEffect(() => {
    sortMap.set("created", { field: "created", order: -1 });
  }, []);

  useEffect(() => {
    searchTimeout = setTimeout(() => {
      getDealership(0, limit).finally(() => {
        setPage(0);
        setFirst(0);
        searchTimeoutInterval = 300;
        setSelectedDealerships([]);
      });
    }, searchTimeoutInterval);

    return () => {
      clearTimeout(searchTimeout);
    };
    // eslint-disable-next-line
  }, [
    nameFilterValue,
    addressFilterValue,
    crmSourceNameFilterValue,
    dealershipStatusValue,
    createdPeriod,
    updatedPeriod,
  ]);

  const onClear = () => {
    sortMap.clear();
    setNameFilterValue("");
    setAddressFilterValue("");
    setDealershipStatusValue("all");
    setCrmSourceNameFilterValue("");
    setCreatedPeriod({
      start: undefined,
      end: undefined,
    });
    setUpdatedPeriod({
      start: undefined,
      end: undefined,
    });
  };

  const onNew = () => {
    history.push(Util.PATH_NAMES.DEALERSHIPS_CREATE, { mode: MODE.NEW });
  };

  const onEdit = () => {
    if (selectedDealerships.length > 0) {
      history.push(Util.PATH_NAMES.DEALERSHIPS_EDIT, {
        mode: MODE.EDIT,
        dealership: selectedDealerships[0],
      });
    } else {
      Util.warning(t("dealerships.chooseDealership"));
    }
  };

  const getStatusFilter = (dealershipStatusValue: status): any => {
    switch (dealershipStatusValue) {
      case "active":
        return {
          field: "deleted",
          value: false,
          operation: SEARCH_OPERATIONS.EQUAL,
        };
      case "inactive":
        return {
          field: "deleted",
          value: true,
          operation: SEARCH_OPERATIONS.EQUAL,
        };
      case "paused":
        return {
          field: "paused",
          value: true,
          operation: SEARCH_OPERATIONS.EQUAL,
        };
      default:
        return null;
    }
  };

  const getDealership = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      const filter: any[] = [];
      const statusFilter = getStatusFilter(dealershipStatusValue);

      if (statusFilter) {
        filter.push(statusFilter);
      }

      if (nameFilterValue) {
        filter.push({
          field: "name",
          value: nameFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (addressFilterValue) {
        filter.push({
          field: "address",
          value: addressFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (crmSourceNameFilterValue) {
        filter.push({
          field: "crmSource.name",
          value: crmSourceNameFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (createdPeriod.start) {
        filter.push({
          field: "created",
          operation: SEARCH_OPERATIONS.GREATER_THAN,
          value: `${moment(createdPeriod.start).format("YYYY-MM-DDT")}00:00:00`,
        });
      }

      if (createdPeriod.end) {
        filter.push({
          field: "created",
          operation: SEARCH_OPERATIONS.LESS_THAN,
          value: `${moment(createdPeriod.end).format("YYYY-MM-DDT")}23:59:59`,
        });
      }

      if (updatedPeriod.start) {
        filter.push({
          field: "updated",
          operation: SEARCH_OPERATIONS.GREATER_THAN,
          value: `${moment(updatedPeriod.start).format("YYYY-MM-DDT")}00:00:00`,
        });
      }

      if (updatedPeriod.end) {
        filter.push({
          field: "updated",
          operation: SEARCH_OPERATIONS.LESS_THAN,
          value: `${moment(updatedPeriod.end).format("YYYY-MM-DDT")}23:59:59`,
        });
      }

      setLoading(true);
      getAllDealerships({
        filter: filter,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
        paging: { page: page, pageLimit: limit },
      })
        .then((response) => {
          setLoading(false);
          setDealerships(response.data.content);
          setTotalItemsCount(response.data.totalElements);

          resolve(response);
        })
        .catch((error) => {
          setLoading(false);
          Util.showError(error);

          reject(error);
        });
    });

  const onPage = (event: any) => {
    getDealership(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
      setSelectedDealerships([]);
    });
  };

  const onSort = (data: any) => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getDealership(page, limit);
    }
  };

  const statusTemplate = (rowData: any) => {
    if (rowData.deleted) {
      return (
        <div>
          {t("inactive")} {pausedTemplate(rowData)}
        </div>
      );
    }

    return (
      <div>
        {t("active")} {pausedTemplate(rowData)}
      </div>
    );
  };

  const pausedTemplate = (rowData: any): string | null => {
    if (rowData.paused) {
      return `(${t("paused")})`;
    }

    return null;
  };

  const stateEditor = (props: any) => {
    return (
      <Dropdown
        value={props.rowData.deleted}
        options={[
          { label: t("active"), value: false },
          { label: t("inactive"), value: true },
        ]}
        onChange={(e) => onStateEditorChange(e, props.rowData)}
        style={{ width: "100%" }}
        placeholder={t("dealerships.selectState")}
      />
    );
  };

  const onStateEditorChange = (e: any, rowData: any) => {
    if (!e.value) {
      rowData.deleted = false;
      onActivate(rowData.id);
    } else {
      rowData.deleted = true;
      onDeactivate(rowData.id);
    }
  };

  const onDeactivate = (id: number) => {
    setLoading(true);
    deactivateDealerships([id])
      .then(() => {
        setLoading(false);
        Util.success(t("dealerships.deactivatedSuccessfully"));
      })
      .catch((error) => {
        setLoading(false);
        Util.showError(error);
      });
  };

  const onActivate = (id: number) => {
    setLoading(true);
    activateDealerships([id])
      .then(() => {
        setLoading(false);
        Util.success(t("dealerships.activatedSuccessfully"));
      })
      .catch((error) => {
        setLoading(false);
        Util.showError(error);
      });
  };

  const nameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={nameFilterValue}
      onChange={(e: any) => {
        setNameFilterValue(e.target.value || "");
      }}
    />
  );

  const addressFilter = (
    <InputText
      style={{ width: "100%" }}
      value={addressFilterValue}
      onChange={(e: any) => {
        setAddressFilterValue(e.target.value || "");
      }}
    />
  );

  const statusFilter = (
    <Dropdown
      value={dealershipStatusValue}
      options={[
        { label: t("all"), value: "all" },
        { label: t("active"), value: "active" },
        { label: t("inactive"), value: "inactive" },
        { label: t("paused"), value: "paused" },
      ]}
      style={{ width: "100%", boxShadow: "none" }}
      onChange={(e: any) => {
        setDealershipStatusValue(e.target.value);
      }}
    />
  );

  const crmSourceNameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={crmSourceNameFilterValue}
      onChange={(e: any) => {
        setCrmSourceNameFilterValue(e.target.value || "");
      }}
    />
  );

  function onCreatedPeriodChange(start?: Date, end?: Date): void {
    setCreatedPeriod({
      start: start ? moment(start).set({ hour: 0, minute: 0, second: 0 }).toDate() : undefined,
      end: end ? moment(end).set({ hour: 23, minute: 59, second: 59 }).toDate(): undefined,
    });
  }

  function onUpdatedPeriodChange(start?: Date, end?: Date): void {
    setUpdatedPeriod({
      start: start ? moment(start).set({ hour: 0, minute: 0, second: 0 }).toDate() : undefined,
      end: end ? moment(end).set({ hour: 23, minute: 59, second: 59 }).toDate() : undefined,
    });
  }

  const createdFilter = (
    <DateRangePickerWidget
      start={createdPeriod.start}
      end={createdPeriod.end}
      ranges={DateRangePeriods.options} // Replace 'Options' with the range definitions
      onChange={onCreatedPeriodChange}
    />
  );

  const updatedFilter = (
    <DateRangePickerWidget
      start={updatedPeriod.start}
      end={updatedPeriod.end}
      ranges={DateRangePeriods.options} // Replace 'Options' with the range definitions
      onChange={onUpdatedPeriodChange}
    />
  );

  const createdTemplate = (data: any) => {
    return (
      <div>
        {moment(Util.utcToLocal(data.created)).format(Util.usDateTimeFormat)}
      </div>
    );
  };

  const updatedTemplate = (data: any) => {
    return (
      <div>
        {moment(Util.utcToLocal(data.updated)).format(Util.usDateTimeFormat)}
      </div>
    );
  };

  const onDealershipsAndUsers = () => {
    history.push(Util.PATH_NAMES.DEALERSHIPS_AND_USERS);
  };

  const onProfileConfiguration = () => {
    if (selectedDealerships.length > 0) {
      history.push(Util.PATH_NAMES.VE2_DEALERSHIP_PROFILE, {
        mode: MODE.EDIT,
        dealership: selectedDealerships[0],
      });
    } else {
      Util.warning(t("dealerships.chooseDealership"));
    }
  };

  const resendWeeklyReport = () => {
    if (selectedDealerships.length === 1) {
      const dealership = selectedDealerships[0];

      const dealershipId = Util._.get(dealership, "id", 0);
      const dealershipName = Util._.get(dealership, "name", "");

      Util.resendWeeklyReport({
        id: dealershipId,
        name: dealershipName,
      });
    }
  };

  return (
    <div id={IDS.dealerships.wrapper}>
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div className="card card-w-title datatable-demo">
            <div
              className={
                limit > 10
                  ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows dealerships"
                  : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows dealerships-ten"
              }
            >
              <DataTable
                resizableColumns={true}
                scrollable
                scrollHeight="600"
                columnResizeMode={"expand"}
                rowsPerPageOptions={Util.rowsPerPageOptions}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                value={dealerships}
                paginator={true}
                totalRecords={totalItemsCount}
                rows={limit}
                lazy={true}
                first={first}
                loading={loading}
                onPage={onPage}
                filterDisplay="row"
                selection={selectedDealerships}
                onSelectionChange={(e) => setSelectedDealerships(e.value)}
                onSort={onSort}
                sortMode={"multiple"}
                multiSortMeta={Array.from(sortMap.values())}
                header={
                  Util.hasAnyAuthority(PERMISSIONS.DEALERSHIP_MANAGEMENT) ? (
                    <div className={"p-grid-header-components"}>
                      <Button
                        id={IDS.button.clear}
                        style={{ position: "absolute", left: "10px" }}
                        icon={"pi pi-filter"}
                        label={t("clear")}
                        onClick={onClear}
                      />
                      <Button
                        id={IDS.button.new}
                        style={{ marginRight: 5 }}
                        icon={"pi pi-plus"}
                        label={t("new")}
                        onClick={onNew}
                      />
                      <Button
                        id={IDS.button.edit}
                        icon={"pi pi-pencil"}
                        label={t("edit")}
                        onClick={onEdit}
                        disabled={selectedDealerships.length !== 1}
                      />
                      <Button
                        id={IDS.button.resendWR}
                        label={" Resend WR"}
                        icon={"pi pi-send"}
                        title={t("dealerships.resendWeeklyReport")}
                        onClick={resendWeeklyReport}
                        disabled={selectedDealerships.length !== 1}
                      />

                      <Button
                        id={IDS.dealerships.button.assignUsers}
                        label={t("dealerships.assignUsers")}
                        onClick={onDealershipsAndUsers}
                        icon={"pi pi-users"}
                      />

                      <Button
                        id={IDS.dealerships.button.vexProfileConfiguration}
                        label={t("dealerships.profileConfiguration")}
                        onClick={onProfileConfiguration}
                        icon={"pi pi-briefcase"}
                        disabled={selectedDealerships.length !== 1}
                      />
                    </div>
                  ) : null
                }
              >
                <Column
                  selectionMode="multiple"
                  style={{ width: "3em", flex: "0.1" }}
                />
                <Column
                  filter={true}
                  sortable={true}
                  field={"name"}
                  sortField={"name"}
                  header={t("dealerships.dealershipName")}
                  filterElement={nameFilter}
                />
                <Column
                  field="address"
                  header={t("address")}
                  filter={true}
                  filterElement={addressFilter}
                  sortable={true}
                />
                <Column
                  field="deleted"
                  header={t("status")}
                  body={statusTemplate}
                  editor={
                    Util.hasAnyAuthority(
                      PERMISSIONS.DEALERSHIP_STATUS_MANAGEMENT
                    )
                      ? stateEditor
                      : undefined
                  }
                  filter={true}
                  filterElement={statusFilter}
                  sortable={true}
                  style={{ overflow: "visible" }}
                />
                <Column
                  filter={true}
                  sortable={true}
                  field={"crmSource.name"}
                  sortField={"crmSource.name"}
                  header={t("crmSourceName")}
                  filterElement={crmSourceNameFilter}
                />
                <Column
                  field="created"
                  header={t("created")}
                  filter={true}
                  filterElement={createdFilter}
                  sortable={true}
                  style={{ overflow: "visible" }}
                  body={createdTemplate}
                />
                <Column
                  field="updated"
                  header={t("updated")}
                  filter={true}
                  filterElement={updatedFilter}
                  sortable={true}
                  style={{ overflow: "visible" }}
                  body={updatedTemplate}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dealerships;
