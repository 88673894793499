import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import Util from "../../../util/Util";
import {
  IChat,
  IChatGreeting,
  IDealershipData,
  IDealershipVisitor,
  IStore,
} from "../../../../index.dts";
import { shallowEqual, useSelector } from "react-redux";
import { getDealershipData } from "../../../actions/kaninActions";
import { saveEngagementLeadHistory } from "../../../actions/engLeadsAction";
import { CHAT_HISTORY_TYPE, LEAD_ACTIONS } from "../../../util/Enums";
import { Button } from "primereact/button";
import { openChat, viewChat } from "../../../actions/engagementActions";
import {
  isRestrictedIP,
  showEngagementError,
} from "../../../views/my_traffic/visualizer/Visualizer";
import ChatModes from "../../../constants/chat_modes";
import { InputText } from "primereact/inputtext";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import { Column } from "primereact/column";
import { get, isEmpty } from "lodash";
import ChatGreetingsModal from "../../chat_greetings_modal/ChatGreetingsModal";
import { getMyChats } from "../../../actions/chatHistoryActions";
import { IDS } from "../../../views/constants";
import { useTranslation } from "react-i18next";

export enum CHAT_HISTORY_ACTIONS {
  VIEW_HISTORY = "VIEW_HISTORY",
  OPEN_CHAT = "OPEN_CHAT",
}

interface IProps {
  readonly chatMode: ChatModes;
  readonly dealershipId: number;
  readonly dealershipRefId: string;
  readonly chatType: CHAT_HISTORY_TYPE;
  readonly actionStyle?: CSSProperties;

  readonly actions: CHAT_HISTORY_ACTIONS[];
}

let interval: any = 0;
let timeout: any = 0;

const ChatHistory = (props: IProps) => {
  const forceUpdate = useForceUpdate();
  const chatGreetingModalRef = useRef<any>();
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [first, setFirst] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);

  const [chats, setChats] = useState<IChat[]>([]);
  const [visitorsMap] = useState<Map<string, IDealershipVisitor>>(new Map());
  const chatWidget = useSelector(
    (store: IStore) => store.myTraffic.chatWidget,
    shallowEqual
  );
  const visitorChatMap = useSelector(
    (store: IStore) => store.myTraffic.visitorChatMap,
    shallowEqual
  );

  const [lastNameFilterValue, setLastNameFilterValue] = useState<string>("");
  const [firstNameFilterValue, setFirstNameFilterValue] = useState<string>("");
  const [emailFilterValue, setEmailFilterValue] = useState<string>("");
  const [phoneFilterValue, setPhoneFilterValue] = useState<string>("");
  const [makeFilterValue, setMakeFilterValue] = useState<string>("");
  const [modelFilterValue, setModelFilterValue] = useState<string>("");
  const [visitorIdFilterValue, setVisitorIdFilterValue] = useState<string>("");
  const [sortMap] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(new Map());

  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  const onClear = () => {
    sortMap.clear();
    setVisitorIdFilterValue("");
    setFirstNameFilterValue("");
    setLastNameFilterValue("");
    setEmailFilterValue("");
    setPhoneFilterValue("");
    setMakeFilterValue("");
    setModelFilterValue("");
  };

  useEffect(() => {
    getData(props.dealershipRefId).catch((error) => {
      Util.showError(error);
    });

    interval = setInterval(() => {
      getData(props.dealershipRefId);
    }, parseInt(process.env.REACT_APP_MY_TRAFFIC_DATA_TIMEOUT_INTERVAL || "3000"));

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [props.dealershipRefId]);

  useEffect(() => {
    timeout = setTimeout(() => {
      getChats(0, limit).finally(() => {
        setPage(0);
      });
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [
    props.dealershipId,
    firstNameFilterValue,
    lastNameFilterValue,
    emailFilterValue,
    phoneFilterValue,
    makeFilterValue,
    modelFilterValue,
    visitorIdFilterValue,
  ]);

  const getData = (dealershipRefId: string): Promise<IDealershipData> =>
    new Promise((resolve, reject) => {
      getDealershipData(dealershipRefId)
        .then((response) => {
          const visitors: IDealershipVisitor[] = Util._.get(
            response,
            "data.visitors",
            []
          );

          visitorsMap.clear();

          visitors.forEach((item) => {
            visitorsMap.set(item.visitorID, item);
          });

          forceUpdate();
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getChats = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      getMyChats({
        paging: {
          page: page,
          pageLimit: limit,
        },
        type: !isEmpty(props.chatType) ? props.chatType : undefined,
        dealershipId: props.dealershipId,
        firstName: !isEmpty(firstNameFilterValue)
          ? firstNameFilterValue
          : undefined,
        lastName: !isEmpty(lastNameFilterValue)
          ? lastNameFilterValue
          : undefined,
        email: !isEmpty(emailFilterValue) ? emailFilterValue : undefined,
        phone: !isEmpty(phoneFilterValue) ? phoneFilterValue : undefined,
        make: !isEmpty(makeFilterValue) ? makeFilterValue : undefined,
        model: !isEmpty(modelFilterValue) ? modelFilterValue : undefined,
        visitorExtRefId: !isEmpty(visitorIdFilterValue)
          ? visitorIdFilterValue
          : undefined,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
      })
        .then((response) => {
          setChats(response.data.content);
          setTotalItemsCount(response.data.totalElements);

          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);
          reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const onPage = (event: any) => {
    getChats(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
    });
  };

  const onSort = (data: any) => {
    sortMap.clear();
    if (Array.isArray(data?.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getChats(page, limit);
    }
  };

  const isChatEnabled = (data: IChat): boolean => {
    const visitor = visitorsMap.get(data?.visitorExtRefId);

    return !(!visitor || visitor.isBlocked);
  };

  const getActionTemplate = (
    action: CHAT_HISTORY_ACTIONS,
    data: IChat
  ): React.ReactElement => {
    switch (action) {
      case CHAT_HISTORY_ACTIONS.OPEN_CHAT:
        return openChatAction(data);
      case CHAT_HISTORY_ACTIONS.VIEW_HISTORY:
        return viewChatHistoryAction(data);
      default:
        return <div key={action} />;
    }
  };

  const getActions = (data: IChat): React.ReactElement[] => {
    return props.actions.map((action) => {
      return getActionTemplate(action, data);
    });
  };

  const actionsTemplate = (data: IChat) => {
    return (
      <div style={{ width: "max-content" }} className={"action-buttons"}>
        {getActions(data)}
      </div>
    );
  };

  const viewChatHistoryAction = (data: IChat): React.ReactElement => {
    return Util.isChatEnabled(props.chatMode) ? (
      <Button
        key={CHAT_HISTORY_ACTIONS.VIEW_HISTORY}
        icon={"pi pi-comments"}
        label={"History"}
        className={"p-button-primary"}
        onClick={() => onViewChatHistory(data)}
      />
    ) : (
      <div key={data.id} />
    );
  };

  const openChatAction = (data: IChat): React.ReactElement => {
    return Util.isChatEnabled(props.chatMode) ? (
      <Button
        key={CHAT_HISTORY_ACTIONS.OPEN_CHAT}
        icon={"pi pi-comments"}
        label={"Chat"}
        className={"p-button-success"}
        onClick={() => onOpenChat(data)}
        disabled={!isChatEnabled(data)}
      />
    ) : (
      <div key={data.id} />
    );
  };

  const onOpenChat = (data: IChat): void => {
    const visitor = visitorsMap.get(data?.visitorExtRefId);

    if (!visitor || isRestrictedIP(visitor)) {
      return;
    }

    chatGreetingModalRef.current.show(visitor);
  };

  const onViewChatHistory = (data: IChat): void => {
    Util.globalSpinner().show();
    viewChat(data.id)
      .then((response) => {
        const channelId = Util._.get(response, "data.channelId", null);

        if (!channelId) {
          return;
        }

        chatWidget.start({
          channelId: channelId,
          isCallsEnabled: false,

          label: dealershipContext.name,
          isMessagesDisabled: true,
        });
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => Util.globalSpinner().hide());
  };

  const onSendChatGreeting = (
    visitor?: IDealershipVisitor,
    chatGreeting?: IChatGreeting
  ): void => {
    if (!visitor) {
      return;
    }

    Util.globalSpinner().show();
    openChat({
      visitorExtRefId: visitor.visitorID,
      chatGreetingId: Util._.get(chatGreeting, "id", null),
      dealershipId: props.dealershipId,
      visitorSession: visitor.sessionID,
      startedInUrl: visitor.currentUrl,
      make: visitor.make,
      model: visitor.model,
      year: visitor.year,
      pageType: visitor.pageClass,
      engagementScore: Math.round(visitor.eScore),
      sessionTime: visitor.sessionTime,
    })
      .then((response) => {
        const channelId = Util._.get(response, "data.channelId", null);
        const visitorId = Util._.get(response, "data.visitorId", "");
        const chatHistoryId = Util._.get(response, "data.id", null);

        chatWidget.start({
          channelId: channelId,
          isCallsEnabled: dealershipContext.chatMode === ChatModes.Calls,
          label: dealershipContext.name,
        });

        visitorChatMap.set(visitorId.toString(), {
          chatHistoryId: chatHistoryId,
          type: CHAT_HISTORY_TYPE.my_traffic,
        });

        if (visitor.leads) {
          saveEngagementLeadHistory({
            action: LEAD_ACTIONS.CHAT,
            dealershipRefId: props.dealershipRefId,
            visitorRefId: Util._.get(visitor, "visitorID"),
          });
        }
      })
      .catch((error) => {
        showEngagementError(error);
      })
      .finally(() => Util.globalSpinner().hide());
  };

  const visitorIdFilter = (
    <InputText
      style={{ width: "100%" }}
      value={visitorIdFilterValue}
      onChange={(e: any) => {
        setVisitorIdFilterValue(
          Util.getIfNullOrEmpty(get(e, "target.value"), "")
        );
      }}
    />
  );

  const firstNameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={firstNameFilterValue}
      onChange={(e: any) => {
        setFirstNameFilterValue(
          Util.getIfNullOrEmpty(get(e, "target.value"), "")
        );
      }}
    />
  );

  const lastNameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={lastNameFilterValue}
      onChange={(e: any) => {
        setLastNameFilterValue(
          Util.getIfNullOrEmpty(get(e, "target.value"), "")
        );
      }}
    />
  );

  const emailFilter = (
    <InputText
      style={{ width: "100%" }}
      value={emailFilterValue}
      onChange={(e: any) => {
        setEmailFilterValue(Util.getIfNullOrEmpty(get(e, "target.value"), ""));
      }}
    />
  );

  const phoneFilter = (
    <InputText
      style={{ width: "100%" }}
      value={phoneFilterValue}
      onChange={(e: any) => {
        setPhoneFilterValue(Util.getIfNullOrEmpty(get(e, "target.value"), ""));
      }}
    />
  );

  const makeFilter = (
    <InputText
      style={{ width: "100%" }}
      value={makeFilterValue}
      onChange={(e: any) => {
        setMakeFilterValue(Util.getIfNullOrEmpty(get(e, "target.value"), ""));
      }}
    />
  );

  const modelFilter = (
    <InputText
      style={{ width: "100%" }}
      value={modelFilterValue}
      onChange={(e: any) => {
        setModelFilterValue(Util.getIfNullOrEmpty(get(e, "target.value"), ""));
      }}
    />
  );

  const render = (): React.ReactElement => {
    return (
      <div className={"card card-w-title"}>
        <div
          className={
            limit > 10
              ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows dealership-users"
              : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows dealership-users-ten"
          }
        >
          <DataTable
            resizableColumns={true}
            filterDisplay="row"
            scrollable
            autoLayout={true}
            columnResizeMode={"expand"}
            rowsPerPageOptions={Util.rowsPerPageOptions}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
            paginator={true}
            totalRecords={totalItemsCount}
            rows={limit}
            lazy={true}
            first={first}
            loading={loading}
            value={chats}
            onPage={onPage}
            onSort={onSort}
            sortMode={"multiple"}
            multiSortMeta={Array.from(sortMap.values())}
            header={
              <div
                style={{ minHeight: "30px" }}
                className={"p-grid-header-components"}
              >
                <Button
                  id={IDS.button.clear}
                  style={{ position: "absolute", left: "10px" }}
                  icon={"pi pi-filter"}
                  label={t("clear")}
                  onClick={onClear}
                />
              </div>
            }
          >
            <Column
              filter={true}
              sortable={true}
              header={"Visitor ID"}
              field={"visitorExtRefId"}
              sortField={"visitorExtRefId"}
              filterElement={visitorIdFilter}
            />
            <Column
              filter={true}
              sortable={true}
              header={"First Name"}
              sortField={"firstName"}
              field={"visitorMetadata.first_name"}
              filterElement={firstNameFilter}
            />
            <Column
              filter={true}
              sortable={true}
              header={"Last Name"}
              sortField={"lastName"}
              field={"visitorMetadata.last_name"}
              filterElement={lastNameFilter}
            />
            <Column
              filter={true}
              sortable={true}
              header={"Email"}
              sortField={"email"}
              field={"visitorMetadata.email"}
              filterElement={emailFilter}
            />
            <Column
              filter={true}
              sortable={true}
              header={"Phone"}
              sortField={"phone"}
              field={"visitorMetadata.phone"}
              filterElement={phoneFilter}
            />
            <Column
              filter={true}
              sortable={true}
              field={"make"}
              header={"Make"}
              sortField={"make"}
              filterElement={makeFilter}
            />
            <Column
              filter={true}
              sortable={true}
              field={"model"}
              header={"Model"}
              sortField={"model"}
              filterElement={modelFilter}
            />
            <Column
              header={"Actions"}
              style={props.actionStyle}
              body={actionsTemplate}
            />
          </DataTable>
        </div>

        <ChatGreetingsModal
          ref={chatGreetingModalRef}
          onSelect={onSendChatGreeting}
        />
      </div>
    );
  };

  return render();
};

export default ChatHistory;
