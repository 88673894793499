import Axios from "../config/Axios";
import {
  AxiosResponse,
  IMyTrafficNotificationLog,
  IPageable,
  ISearchCriteria,
  ISearchRequestDto,
  IUserDto
} from "../../index.dts";
import actions from "../constants/actions";

export const updateUserNotifications = () => ({
  type: actions.UPDATE_USER_NOTIFICATIONS
});

export const updateMyTrafficTabIndex = (payload: number) => ({
  type: actions.UPDATE_MY_TRAFFIC_TAB_INDEX,
  payload: payload
});

export const updateMyTrafficStatsTabIndex = (payload: number) => ({
  type: actions.UPDATE_MY_TRAFFIC_STATS_TAB_INDEX,
  payload: payload
});

export const updateUser = (dto: IUserDto, file: File | undefined) => {
  const formData: FormData = new FormData();
  const encoded: string = JSON.stringify(dto);

  if (file) {
    formData.append("file", file);
  }

  formData.append("data", encoded);

  return Axios.instance.put("user", formData);
}

export const updateUserPassword = (oldPassword: string, newPassword: string) =>
  Axios.instance.put("user/password", {
    oldPassword: window.btoa(oldPassword),
    newPassword: window.btoa(newPassword)
  });

export const bulkImport = (files: File[]) => {
  const formData = new FormData();
  formData.append('file', files[0]);
  return Axios.instance.post(`admin/users/invite/bulk`, formData)
}

export const getNotificationById = (id: number): Promise<AxiosResponse<IMyTrafficNotificationLog>> => Axios.instance.get(`notification-log/${id}`);
export const markAsReviewed = (id: number) => Axios.instance.put(`notification-log/${id}/review`);
export const markAllAsRead = () => Axios.instance.put(`notification-log/user/mark-all-as-read`);
export const clearNotifications = () => Axios.instance.delete(`notification-log/delete-notification-logs`);
export const getMyNotificationsCount = () => Axios.instance.get(`notification-log/user/count`);
export const getMyNotifications = (dto: ISearchCriteria): Promise<IPageable> => Axios.instance.post('notification-log/user/list', dto);
export const joinChatBotChannel = (chatHistoryId: number) => Axios.instance.get(`rts/chat/join/${chatHistoryId}`);
export const disableDesktopNotifications = () => Axios.instance.put("user/disable-desktop-notifications");
export const enableDesktopNotifications = () => Axios.instance.put("user/enable-desktop-notifications");
export const getUserAssignedDealerships = (userId: number, searchCriteria: ISearchRequestDto) => Axios.instance.post(`user/${userId}/dealerships`, searchCriteria);
export const removeUserDealerships = (userId: number, dealershipIds: number[]) => Axios.instance.post(`user/${userId}/dealerships/remove`, dealershipIds);
export const assignUserDealerships = (userId: number, dealershipIds: number[]) => Axios.instance.post(`user/${userId}/dealerships/assign`, dealershipIds);