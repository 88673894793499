import { find, isUndefined } from "lodash";

import moment from "moment";

export interface Period {
  label: string;
  value: number,
  start: Date;
  end: Date;
}

enum Keys {
  today,
  yesterday,
  thisWeek,
  lastWeek,
  last30Days,
  thisMonth,
  lastMonth,
  thisYear,
  lastYear,
  custom,
}

const Options: Period[] = [
  {
    label: "today",
    value: Keys.today,
    start: moment().toDate(),
    end: moment().toDate(),
  },
  {
    label: "yesterday",
    value: Keys.yesterday,
    start: moment().subtract(1, 'days').toDate(),
    end: moment().subtract(1, 'days').toDate(),
  },
  {
    label: "thisWeek",
    value: Keys.thisWeek,
    start: moment().startOf("isoWeek").toDate(),
    end: moment().endOf("isoWeek").toDate(),
  },
  {
    label: "lastWeek",
    value: Keys.lastWeek,
    start: moment().subtract(1, 'week').startOf("isoWeek").toDate(),
    end: moment().subtract(1, 'week').endOf("isoWeek").toDate(),
  },
  {
    label: "last30days",
    value: Keys.last30Days,
    start: moment().subtract(30, "days").toDate(),
    end: moment().toDate(),
  },
  {
    label: "thisMonth",
    value: Keys.thisMonth,
    start: moment().startOf("month").toDate(),
    end: moment().endOf("month").toDate()
  },
  {
    label: "lastMonth",
    value: Keys.lastMonth,
    start: moment().subtract(1, "month").startOf("month").toDate(),
    end: moment().subtract(1, "month").endOf("month").toDate(),
  },
  {
    label: "thisYear",
    value: Keys.thisYear,
    start: moment().startOf("year").toDate(),
    end: moment().endOf("year").toDate()
  },
  {
    label: "lastYear",
    value: Keys.lastYear,
    start: moment().subtract(1, "year").startOf("year").toDate(),
    end: moment().subtract(1, "year").endOf("year").toDate()
  },
];

export class DateRangePeriods {
  public static keys = Keys;
  public static options = Options;

  public static get(key: Keys): Period {
    const period = find(this.options, o => o.value === key);

    if (isUndefined(period)) {
      throw new Error(`Invalid date range period key: ${key}`);
    }

    return period;
  }
}
