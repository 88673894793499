import { IAuth } from "../../index.dts";
import actions from "../constants/actions";
import Util from "../util/Util";
import { COOKIES } from "../util/Enums";
import Axios from "../config/Axios";
import ChatModes from "../constants/chat_modes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state: IAuth = {
    pending: true,
    isLoggedIn: false,
    device: {
      id: 0,
      deviceId: "",
      token: "",
    },
    auth: {
      access_token: "",
      expires_in: 0,
      refresh_token: "",
      scope: "",
      token_type: "",
    },
    user: {
      activated: false,
      avatarObjectId: "",
      deleted: false,
      enabled: false,
      id: 0,
      username: "",
      firstName: "",
      lastName: "",
      name: "",
      email: "",
      externalRefId: "",
      role: {
        id: 0,
        name: "",
        permissions: [],
      },
      desktopNotificationsEnabled: false,
    },
    dealershipContext: {
      id: 0,
      name: "",
      created: "",
      logoPath: "",
      timezone: "",
      externalRefId: "",
      chatMode: ChatModes.Disabled,

      deleted: true,
      rtsEnabled: false,
      myCampaignsEnabled: false,
      persistentChatEnabled: false,
      serviceRecordsEnabled: false,
      autoAcquireNoEngagementEnabled: false,
      m1Enabled: false,
      fraudGuardEnabled: false,
      blockOffersEnabled: false,
      engagementScoreThreshold: 0,
      gaPropertyId: 0,
      useGaEnabled: false,
      crmSource: null,
      iOfferEnabled: false,
    },
    dealershipDropdownKey: "",
  },
  action: { type: string; payload?: any }
) => {
  state = { ...state };

  switch (action.type) {
    case actions.AUTH_PENDING:
      state.pending = true;
      return state;
    case actions.AUTH_SUCCESS:
      state.isLoggedIn = true;
      state.auth = action.payload.auth;
      state.user = action.payload.user;
      Util.Cookie.set(COOKIES.AUTH, action.payload.auth, {
        expires: new Date(Date.now() + action.payload.auth.expires_in * 1000),
      });
      Util.Cookie.set(
        COOKIES.REFRESH_TOKEN,
        action.payload.auth.refresh_token,
        {
          expires: new Date(
            new Date().setFullYear(new Date().getFullYear() + 1)
          ),
        }
      );
      return state;
    case actions.AUTH_ERROR:
      state.pending = false;
      state.isLoggedIn = false;
      return state;
    case actions.LOGOUT_SUCCESS:
      state.pending = false;
      state.isLoggedIn = false;
      state.auth = {
        access_token: "",
        expires_in: 0,
        refresh_token: "",
        scope: "",
        token_type: "",
      };
      Axios.removeCookies();
      return state;
    case actions.USER_UPDATE:
      state.user = action.payload;
      return state;
    case actions.SET_DEALERSHIP_CONTEXT:
      state.pending = false;
      state.dealershipContext = action.payload;
      return state;
    case actions.UPDATE_DROPDOWN_KEY:
      state.dealershipDropdownKey = action.payload;
      return state;
    case actions.SET_DEVICE:
      state.device = action.payload;
      return state;
    default:
      return state;
  }
};
