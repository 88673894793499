import React, { useImperativeHandle, useState } from "react";
import { IAsset, IDealershipVisitor, IStore } from "../../../index.dts";
import { Dialog } from "primereact/dialog";
import { DataView } from "primereact/dataview";
import { getAssetList } from "../../actions/assetActions";
import Util from "../../util/Util";
import "./send-offer-modal.css";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ColorPicker } from "primereact/colorpicker";
import { COOKIES, PERMISSIONS } from "../../util/Enums";
import { FileUpload } from "primereact/fileupload";
import { shallowEqual, useSelector } from "react-redux";

interface IProps {
  onStaticOffer(asset: IAsset, visitor: IDealershipVisitor | undefined): void;

  onDynamicOffer(body: string, visitor: IDealershipVisitor | undefined): void;

  onHide(): void;
}

const dynamicOfferContainerId = "dynamic-offer-container";

const SendOfferModal = React.forwardRef((props: IProps, ref: any) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [values, setValues] = useState<IAsset[]>([]);
  const [pending, setPending] = useState<boolean>(false);
  const [visitor, setVisitor] = useState<IDealershipVisitor>();

  const [dynamicOfferTitle, setDynamicOfferTitle] =
    useState<string>("GET YOUR");
  const [dynamicOfferBody, setDynamicOfferBody] =
    useState<string>("$1,000 OFF");
  const [dynamicOfferDescription, setDynamicOfferDescription] =
    useState<string>("ON ANY VEHICLE");
  const [dynamicOfferBackgroundColor, setDynamicOfferBackgroundColor] =
    useState<string>("42cb83");

  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }));

  const show = (dealershipId: number, visitor: IDealershipVisitor) => {
    setVisitor(visitor);
    setVisible(true);
    getData(dealershipId);
  };

  const hide = () => {
    setValues([]);
    setVisible(false);

    props.onHide();
  };

  const getData = (dealershipId: number) =>
    new Promise((resolve, reject) => {
      setPending(true);
      getAssetList(dealershipId)
        .then((response) => {
          setValues(response.data.data);
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => setPending(false));
    });

  const onStaticOffer = (
    asset: IAsset,
    visitor: IDealershipVisitor | undefined
  ): void => {
    props.onStaticOffer(asset, visitor);

    hide();
  };

  const onDynamicOffer = (visitor: IDealershipVisitor | undefined): void => {
    const htmlBody: any = document.getElementById(dynamicOfferContainerId);
    const outerHTML = Util._.get(htmlBody, "outerHTML", "");

    props.onDynamicOffer(outerHTML, visitor);

    hide();
  };

  const itemTemplate = (asset: IAsset) => {
    return (
      <div
        onClick={() => onStaticOffer(asset, visitor)}
        style={{ margin: 5, cursor: "pointer" }}
        className="product-grid-item card"
      >
        <img
          width={300}
          height={200}
          src={Util.getAssetUrl(asset.objectId)}
          alt={"..."}
        />
      </div>
    );
  };

  const onUpload = (e: { xhr: XMLHttpRequest; files: any }): void => {
    Util.success("File uploaded successfully.");

    const responseStr = Util._.get(e, "xhr.response", "");
    const data = JSON.parse(responseStr);

    values.push(data);
    setPending(false);
  };

  return (
    <Dialog
      id={"chat_greetings_modal"}
      visible={visible}
      onHide={hide}
      header={`Select Your Offer`}
    >
      <TabView>
        <TabPanel contentStyle={{ width: "70vw" }} header="Static Offer">
          <DataView
            lazy={true}
            loading={pending}
            value={values}
            layout={"grid"}
            header={
              <div style={{ display: "flex" }}>
                <label style={{ fontWeight: "bold" }}>Assets</label>
                {Util.hasAnyAuthority(PERMISSIONS.ASSETS_MANAGEMENT) ? (
                  <div className={"p-grid-header-components"}>
                    <FileUpload
                      mode="basic"
                      name={"file"}
                      auto={true}
                      withCredentials={true}
                      onBeforeSend={(event) => {
                        const auth: any = JSON.parse(
                          Util.Cookie.get(COOKIES.AUTH) || "{}"
                        );
                        const access_token = Util._.get(
                          auth,
                          "access_token",
                          null
                        );

                        event.xhr.setRequestHeader(
                          "Authorization",
                          `Bearer ${access_token}`
                        );
                      }}
                      onError={() => setPending(false)}
                      onBeforeUpload={() => setPending(true)}
                      url={`${process.env.REACT_APP_BASE_URL}/dealerships/${dealershipContext.id}/assets`}
                      accept={".gif, .jpg, .png, .svg"}
                      maxFileSize={2000000}
                      onUpload={onUpload}
                      chooseLabel={"Browse"}
                    />
                  </div>
                ) : undefined}
              </div>
            }
            itemTemplate={itemTemplate}
          />
        </TabPanel>
        <TabPanel header="Dynamic Offer">
          <div style={{ width: 300 }}>
            <div
              id={dynamicOfferContainerId}
              style={{
                backgroundColor: `#${dynamicOfferBackgroundColor}`,
                height: "100%",
                minHeight: 350,
                borderTopRightRadius: 10,
                borderTopLeftRadius: 10,
                position: "relative",
              }}
            >
              <div
                id={"text-wrapper"}
                style={{
                  marginTop: "auto",
                  margin: 0,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  msTransform: "translate(-50%, -50%)",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  padding: 15,
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <label
                    style={{
                      color: "#FFFFFF",
                      fontSize: 22,
                      fontWeight: "bold",
                    }}
                  >
                    {dynamicOfferTitle}
                  </label>
                </div>
                <div style={{ textAlign: "center" }}>
                  <label
                    style={{
                      color: "#FFFFFF",
                      fontSize: 22,
                      fontWeight: "bold",
                    }}
                  >
                    {dynamicOfferBody}
                  </label>
                </div>
                <div style={{ textAlign: "center" }}>
                  <label
                    style={{
                      color: "#FFFFFF",
                      fontSize: 22,
                      fontWeight: "bold",
                    }}
                  >
                    {dynamicOfferDescription}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div id={"input-wrapper"}>
            <div className={"input-container"}>
              <ColorPicker
                value={`#${dynamicOfferBackgroundColor}`}
                onChange={(e: any) => {
                  setDynamicOfferBackgroundColor(e.value);
                }}
              />
            </div>
            <div className={"input-container"}>
              <InputText
                maxLength={20}
                className={"input-field"}
                value={dynamicOfferTitle}
                onChange={(e: any) => setDynamicOfferTitle(e.target.value)}
              />
            </div>
            <div className={"input-container"}>
              <InputText
                maxLength={20}
                className={"input-field"}
                value={dynamicOfferBody}
                onChange={(e: any) => setDynamicOfferBody(e.target.value)}
              />
            </div>
            <div className={"input-container"}>
              <InputText
                maxLength={20}
                className={"input-field"}
                value={dynamicOfferDescription}
                onChange={(e: any) =>
                  setDynamicOfferDescription(e.target.value)
                }
              />
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              className="no-icon-buttons"
              label={"Send"}
              onClick={() => {
                onDynamicOffer(visitor);
              }}
            />
          </div>
        </TabPanel>
      </TabView>
    </Dialog>
  );
});

export default SendOfferModal;
