import React from "react";

interface IProps {
    onHide: any
}

export function CloseButton({onHide}: IProps) {

    return (
        <button onClick={onHide} className={"layout-config-close"}>
            <i className={"pi pi-times"}/>
        </button>
    );
}

export default CloseButton
