import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tooltip,
} from "@mui/material";
import { startCase } from "lodash";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CarsListTable,
  ICampaignInfo,
  IMyTrafficNotificationDto,
  IMyTrafficNotificationFull,
  IStore,
} from "../../../../index.dts";
import Util from "../../../util/Util";
import { IDS } from "../../constants";
import InfoIcon from "@mui/icons-material/Info";
import {
  CAMPAIGNS_DIS_TYPE,
  CAMPAIGN_FILTER_TYPE,
  MODE,
} from "../../../util/Enums";
import { get, isEqual } from "lodash";
import {
  getCarsListRequest,
  updateMyCampaign,
} from "../../../actions/campaignsActions";
import { useSelector, shallowEqual } from "react-redux";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useTranslation } from "react-i18next";

type Props = {
  setCampaignInfo: React.Dispatch<React.SetStateAction<ICampaignInfo>>;
  getVehiclesList: (newFilters: any) => void;
  noVehicleMessage: string;
  setMatchingVehicles: React.Dispatch<React.SetStateAction<any>>;
  setStep: React.Dispatch<
    React.SetStateAction<{
      step: number | string;
      payload?: any;
    }>
  >;
  campaignInfo: ICampaignInfo;
  mode: MODE.EDIT | MODE.NEW | MODE.DUPLICATE;
  initialData: ICampaignInfo;
  setInitialData: React.Dispatch<React.SetStateAction<ICampaignInfo>>;
};

let searchTimeout: any;
let searchTimeoutInterval: number = 0;
const NO_IMAGE_URL: string = "assets/images/no_image_placeholder.png";

const ChooseCar = (props: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    campaignInfo,
    setCampaignInfo,
    setMatchingVehicles,
    setStep,
    mode,
    initialData,
    getVehiclesList,
    noVehicleMessage,
    setInitialData,
  } = props;
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [page, setPage] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [carsList, setCarsList] = useState<any>([]);
  const [initialCarsList, setInitialCarsList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCars, setSelectedCars] = useState<any[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [isDisFilterVisible, setIsDisFilterVisible] = useState(false);
  const [isPriceFilterVisible, setIsPriceFilterVisible] = useState(false);
  const [columnFilters, setColumnFilters] = useState<{
    year?: string;
    make?: string;
    model?: string;
    trim?: string;
    body?: string;
    vin?: string;
    stockNumber?: string;
    price?: number;
    priceType?: CAMPAIGNS_DIS_TYPE;
    daysInStock?: string;
    daysInStockType?: CAMPAIGNS_DIS_TYPE;
    type?:
      | CAMPAIGN_FILTER_TYPE.NEW
      | CAMPAIGN_FILTER_TYPE.USED
      | CAMPAIGN_FILTER_TYPE.CERTIFIED;
  }>({
    priceType: CAMPAIGNS_DIS_TYPE.EQUALS,
    daysInStockType: CAMPAIGNS_DIS_TYPE.EQUALS,
    year: "",
    make: "",
    model: "",
    trim: "",
    body: "",
    vin: "",
    stockNumber: "",
    price: 0,
    daysInStock: "",
  });
  const [sortMap] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(new Map());
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  const initialColumnFilters = {
    priceType: CAMPAIGNS_DIS_TYPE.EQUALS,
    daysInStockType: CAMPAIGNS_DIS_TYPE.EQUALS,
  };

  const onClear = () => {
    sortMap.clear();
    setColumnFilters({
      year: "",
      make: "",
      model: "",
      trim: "",
      body: "",
      vin: "",
      stockNumber: "",
      price: 0,
      priceType: CAMPAIGNS_DIS_TYPE.EQUALS,
      daysInStockType: CAMPAIGNS_DIS_TYPE.EQUALS,
      daysInStock: "",
    });
  };

  useEffect(() => {
    setColumnFilters({
      priceType: CAMPAIGNS_DIS_TYPE.EQUALS,
      daysInStockType: CAMPAIGNS_DIS_TYPE.EQUALS,
    });
  }, [dealershipContext?.id]);

  useEffect(() => {
    searchTimeout = setTimeout(() => {
      getCarsList(0, 10).finally(() => {
        setPage(0);
        setFirst(0);
        setSelectedCars([]);
        searchTimeoutInterval = 500;
      });
    }, searchTimeoutInterval);

    return () => {
      clearTimeout(searchTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealershipContext?.id, columnFilters]);

  const getCarsList = (page: number, limit: number) =>
    getCarsListRequest({
      pageNumber: page,
      pageLimit: limit,
      dealershipId: dealershipContext?.id,
      body: columnFilters?.body ? columnFilters?.body : undefined,
      daysInStock: columnFilters?.daysInStock
        ? (columnFilters?.daysInStock as any)
        : undefined,
      ...(columnFilters?.daysInStock
        ? {
            daysInStockType: columnFilters?.daysInStockType,
          }
        : undefined),
      ...(columnFilters?.price
        ? {
            priceType: columnFilters?.priceType,
          }
        : undefined),
      make: columnFilters?.make ? columnFilters?.make : undefined,
      model: columnFilters?.model ? columnFilters?.model : undefined,
      trim: columnFilters?.trim ? columnFilters?.trim : undefined,
      vin: columnFilters?.vin ? columnFilters?.vin : undefined,
      stockNumber: columnFilters?.stockNumber
        ? columnFilters?.stockNumber
        : undefined,
      year: columnFilters?.year ? columnFilters?.year : undefined,
      price: columnFilters?.price
        ? columnFilters?.price?.toString()
        : undefined,
      vehicleType: columnFilters?.type
        ? columnFilters?.type
        : CAMPAIGN_FILTER_TYPE.ALL,
      sorting: Array.from(sortMap.values()).map((item) => {
        return {
          field: item.field,
          direction: item.order === 1 ? "asc" : "desc",
        };
      }),
    })
      .then((response: any) => {
        setLoading(false);
        const reformattedContent: CarsListTable[] =
          response?.data?.content?.map((carInfo) => {
            return {
              imageUrl: carInfo?.imageUrl || NO_IMAGE_URL,
              year: carInfo?.year,
              make: carInfo?.make,
              model: carInfo?.model,
              trim: carInfo?.trim,
              body: carInfo?.body,
              vin: carInfo?.vin,
              stockNumber: carInfo?.stockNumber,
              price: carInfo?.price,
              daysInStock: carInfo?.daysInStock,
              miles: carInfo?.miles,
              extColor: carInfo?.extColor,
              type: carInfo?.type,
            };
          });
        setCarsList(reformattedContent);
        setMatchingVehicles(reformattedContent);
        Util._.isEqual(initialColumnFilters, columnFilters) &&
          setInitialCarsList(reformattedContent);
        setTotalItemsCount(response.data.totalElements);
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => {
        setLoading(false);
      });

  const onPage = (event: any) => {
    getCarsList(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
      setSelectedCars([]);
    });
  };

  const onSort = (data: any) => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getCarsList(page, limit);
    }
  };

  const onNext = () => {
    setStep((prev: any) => {
      return {
        ...prev,
        step: (prev?.step as number) + 1,
      };
    });
  };

  const onBack = () => {
    history.push(Util.PATH_NAMES.MY_CAMPAIGNS);
  };

  const transformNotificationsToDto = (
    notifications: IMyTrafficNotificationFull[]
  ): IMyTrafficNotificationDto[] => {
    const formattedDtos: IMyTrafficNotificationDto[] = notifications.map(
      (notification) => {
        return {
          ...((mode === MODE.EDIT) && {
            id: notification?.id || null,
          }),
          name: notification?.name,
          dealershipId: dealershipContext.id,
          segment: notification?.segment,
          message: notification?.message,
          engagementScore: notification?.engagementScore,
          delaySend: notification?.delaySend,
          limitByZipCodes: notification?.limitByZipCodes,
          enabled: notification?.enabled,
          zipCodes: notification?.zipCodes,
          useChatBot: notification?.useChatBot,
          useSharkTank: notification?.useSharkTank,
          chatbotId: notification?.chatbotId,
          chatGreetingId: notification?.chatGreetingId,
          users: notification?.users?.map((user, index) => {
            return {
              userId: user?.userId,
              order: notification?.users?.length - index,
            };
          }),
          chatBotWorkflows: notification?.chatBotWorkflows?.map((item) => {
            return {
              chatBotId: item.chatBot.id,
              chatGreetingId: item.chatGreeting.id,
              daysOfWeek: item.daysOfWeek,
              endTime: item.endTime,
              startTime: item.startTime,
            };
          }),
        };
      }
    );
    return formattedDtos;
  };

  const addUniqueFilters = useCallback(
    (filtersToAdd, rowSelection?: boolean) => {
      // A CHECK FOR UNIQUE selectedFilters array
      setCampaignInfo((prev) => {
        let filters = Array.isArray(filtersToAdd)
          ? filtersToAdd
          : [filtersToAdd];
        let formattedUniqueFilterToAdd = filters?.map((filter) => {
          return {
            id: null,
            year: filter?.year || null,
            make: filter?.make || "",
            model: filter?.model || "",
            trim: filter?.trim || "",
            body: filter?.body || "",
            vin: filter?.vin || "",
            stockNumber: filter?.stockNumber || "",
            price: rowSelection ? null : filter?.price,
            priceType: rowSelection
              ? CAMPAIGNS_DIS_TYPE.EQUALS
              : filter?.priceType,
            daysInStock: rowSelection ? null : filter?.daysInStock,
            daysInStockType: rowSelection
              ? CAMPAIGNS_DIS_TYPE.EQUALS
              : filter?.daysInStockType,
            vehicleType: filter?.type?.toUpperCase() || "ALL",
          };
        });
        if (prev?.filters && prev?.filters?.length > 0) {
          formattedUniqueFilterToAdd = formattedUniqueFilterToAdd.filter(
            (filter: any) => {
              if (
                !prev?.filters?.some((selectedFilter: any) =>
                  Util._.isEqual(selectedFilter, filter)
                )
              ) {
                return true;
              }
              return false;
            }
          );
        }
        const newFilters =
          prev?.filters && prev?.filters?.length > 0
            ? [...prev?.filters, ...formattedUniqueFilterToAdd]
            : [...formattedUniqueFilterToAdd];

        getVehiclesList(newFilters);
        return {
          ...prev,
          filters:
            prev?.filters && prev?.filters?.length > 0
              ? [...prev?.filters, ...formattedUniqueFilterToAdd]
              : [...formattedUniqueFilterToAdd],
        };
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const typeFilterTemplate = (
    <Dropdown
      showClear={true}
      value={columnFilters?.type}
      options={Object.entries(CAMPAIGN_FILTER_TYPE).map((entry) => {
        return {
          label: entry[0],
          value: entry[1],
        };
      })}
      style={{
        width: "100%",
        boxShadow: "none",
        backgroundColor: "#FFFFFF",
        // overflow: "visible",
      }}
      onChange={(e: any) => {
        const { value } = e?.target;
        setColumnFilters((prev) => {
          return { ...prev, type: value };
        });
      }}
    />
  );

  const priceField = (rowData) => {
    return <p>${rowData.price.toLocaleString()}</p>;
  };

  return (
    <Panel style={{ width: "100%", marginBottom: 10 }} header={"Choose Cars"}>
      <div id={IDS.offers.wrapper}>
        <div
          className={"p-grid"}
          style={{ alignItems: "center", margin: "10px 0 15px 0" }}
        >
          <div className={"p-col-12 p-lg-2 p-label-col"}>
            <div className={"label-container"}>
              <label className={"input-label"}>Campaign Name</label>
              <span className={"red"}>*</span>
            </div>
          </div>
          <div className={"p-col-8 p-lg-4"}>
            <InputText
              id={IDS.inputs.name}
              style={{ width: "100%" }}
              value={campaignInfo?.name || ""}
              placeholder={"Campaign Name"}
              autoFocus={mode === MODE.DUPLICATE}
              onChange={(e: any) => {
                const { value } = e.target;
                setCampaignInfo((prev) => {
                  return {
                    ...prev,
                    name: value,
                  };
                });
              }}
            />
          </div>
        </div>
        <Panel style={{ width: "100%", marginBottom: 10 }} header={"Filters"}>
          <div className="card card-w-title datatable-demo">
            <div
              className={
                "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"
              }
            >
              <DataTable
                tableStyle={{ overflow: "visible" }}
                resizableColumns={true}
                columnResizeMode={"expand"}
                value={campaignInfo?.filters || []}
              >
                <Column
                  style={{ background: "white", cursor: "default" }}
                  field="year"
                  header="Year"
                />
                <Column
                  style={{ background: "white", cursor: "default" }}
                  field="make"
                  header="Make"
                />
                <Column
                  style={{ background: "white", cursor: "default" }}
                  field="model"
                  header="Model"
                />
                <Column
                  style={{ background: "white", cursor: "default" }}
                  field="trim"
                  header="Trim"
                />
                <Column
                  style={{ background: "white", cursor: "default" }}
                  field="body"
                  header="Body"
                />
                <Column
                  style={{ background: "white", cursor: "default" }}
                  field="vin"
                  header="VIN"
                />
                <Column
                  style={{ background: "white", cursor: "default" }}
                  field="stockNumber"
                  header="Stock Number"
                />
                <Column
                  style={{ background: "white", cursor: "default" }}
                  field="price"
                  header="Price"
                  body={(rowData: any) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {startCase(rowData?.priceType)?.toUpperCase()}:{" "}
                          {rowData?.price}
                        </span>
                      </div>
                    );
                  }}
                />
                <Column
                  style={{ background: "white", cursor: "default" }}
                  field="daysInStock"
                  header="DIS"
                  body={(rowData: any) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {startCase(rowData?.daysInStockType)?.toUpperCase()}:{" "}
                          {rowData?.daysInStock}
                        </span>
                      </div>
                    );
                  }}
                />
                <Column
                  style={{ background: "white", cursor: "default" }}
                  field="vehicleType"
                  header="Type"
                  body={(rowData: any) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {rowData?.vehicleType?.toUpperCase() !== "ALL"
                          ? rowData?.vehicleType
                          : ""}
                      </span>
                    </div>
                  )}
                />
                <Column
                  style={{ background: "white", cursor: "default" }}
                  field="delete"
                  header=""
                  body={(rowData: any) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setCampaignInfo((prev) => {
                            const filterToCompare = {
                              id: rowData?.id || null,
                              body: rowData?.body,
                              daysInStock: rowData?.daysInStock,
                              daysInStockType: rowData?.daysInStockType,
                              make: rowData?.make,
                              model: rowData?.model,
                              trim: rowData?.trim,
                              price: rowData?.price,
                              priceType: rowData?.priceType,
                              vin: rowData?.vin,
                              stockNumber: rowData?.stockNumber,
                              year: rowData?.year,
                              vehicleType:
                                rowData?.vehicleType?.toUpperCase() || "ALL",
                            };
                            const newFilters = prev?.filters?.filter(
                              (filter) => {
                                const prevFilterToCompare = {
                                  id: filter?.id || null,
                                  body: filter?.body,
                                  daysInStock: filter?.daysInStock,
                                  daysInStockType: filter?.daysInStockType,
                                  make: filter?.make,
                                  model: filter?.model,
                                  trim: filter?.trim,
                                  price: filter?.price,
                                  priceType: filter?.priceType,
                                  vin: filter?.vin,
                                  stockNumber: filter?.stockNumber,
                                  year: filter?.year,
                                  vehicleType:
                                    filter?.vehicleType?.toUpperCase() || "ALL",
                                };
                                return !isEqual(
                                  prevFilterToCompare,
                                  filterToCompare
                                );
                              }
                            );
                            getVehiclesList(newFilters);
                            return {
                              ...prev,
                              filters: prev?.filters?.filter((filter) => {
                                const prevFilterToCompare = {
                                  id: filter?.id || null,
                                  body: filter?.body,
                                  daysInStock: filter?.daysInStock,
                                  daysInStockType: filter?.daysInStockType,
                                  make: filter?.make,
                                  model: filter?.model,
                                  trim: filter?.trim,
                                  price: filter?.price,
                                  priceType: filter?.priceType,
                                  vin: filter?.vin,
                                  stockNumber: filter?.stockNumber,
                                  year: filter?.year,
                                  vehicleType:
                                    filter?.vehicleType?.toUpperCase() || "ALL",
                                };
                                return !isEqual(
                                  prevFilterToCompare,
                                  filterToCompare
                                );
                              }),
                            };
                          });
                        }}
                      >
                        <DeleteOutlineIcon fontSize="medium" />
                      </IconButton>
                    </div>
                  )}
                />
              </DataTable>
            </div>
          </div>
          <p
            style={{
              color: "red",
              fontWeight: "bolder",
            }}
          >
            {noVehicleMessage}
          </p>
        </Panel>
        <div className={"p-grid"}>
          <div className="p-col-12">
            <div style={{ marginTop: "10px" }}>
              <Button
                id={IDS.button.back}
                onClick={() => addUniqueFilters(selectedCars, true)}
                className={"p-button-warning no-icon-buttons"}
                disabled={selectedCars?.length === 0 || !selectedCars}
                label={`Add Selected${
                  selectedCars?.length > 0 ? `(${selectedCars?.length})` : ""
                }`}
              />
              <Button
                style={{ marginRight: 5 }}
                className="no-icon-buttons"
                label={"Add as Filter"}
                disabled={
                  !Object.values(columnFilters)?.some((value) => {
                    return (
                      value !== undefined &&
                      value !== null &&
                      value !== "" &&
                      !Object?.values(CAMPAIGNS_DIS_TYPE).includes(value as any)
                    );
                  })
                }
                onClick={() => addUniqueFilters(columnFilters)}
              />
            </div>
            <div className={"p-grid"}>
              <div className="p-col-12">
                <div className="card card-w-title datatable-demo">
                  <div
                    className={
                      limit > 10
                        ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting"
                        : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
                    }
                  >
                    <DataTable
                      tableStyle={{ overflow: "visible" }}
                      resizableColumns={true}
                      filterDisplay="row"
                      scrollable
                      columnResizeMode={"expand"}
                      rowsPerPageOptions={Util.rowsPerPageOptions}
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                      lazy={true}
                      rows={limit}
                      first={first}
                      value={carsList}
                      paginator={initialCarsList?.length === 0 ? false : true}
                      loading={loading}
                      sortMode={"multiple"}
                      onPage={onPage}
                      onSort={onSort}
                      multiSortMeta={Array.from(sortMap.values())}
                      totalRecords={totalItemsCount}
                      selection={selectedCars}
                      onSelectionChange={(e) => setSelectedCars(e.value)}
                      header={
                        <div
                          style={{ minHeight: "30px" }}
                          className={"p-grid-header-components"}
                        >
                          <Button
                            id={IDS.button.clear}
                            style={{ position: "absolute", left: "10px" }}
                            icon={"pi pi-filter"}
                            label={t("clear")}
                            onClick={onClear}
                          />
                        </div>
                      }
                    >
                      {!(initialCarsList?.length === 0) && (
                        <Column
                          selectionMode="multiple"
                          style={{ width: "3em", flex: "0.2" }}
                        />
                      )}
                      {!(initialCarsList?.length === 0) && (
                        <Column
                          field="imageUrl"
                          header=""
                          body={(rowData: any) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img alt="" width={100} src={rowData?.imageUrl} />
                            </div>
                          )}
                        />
                      )}
                      <Column
                        field="year"
                        header="Year"
                        filter={true}
                        sortable={true}
                        filterElement={
                          <InputText
                            style={{ width: "100%" }}
                            value={columnFilters?.year}
                            onChange={(e: any) => {
                              const { value } = e?.target;
                              setColumnFilters((prev) => {
                                return { ...prev, year: value };
                              });
                            }}
                          />
                        }
                      />
                      <Column
                        field="make"
                        header="Make"
                        filter={true}
                        sortable={true}
                        filterElement={
                          <InputText
                            style={{ width: "100%" }}
                            value={columnFilters?.make}
                            onChange={(e: any) => {
                              const { value } = e?.target;
                              setColumnFilters((prev) => {
                                return { ...prev, make: value };
                              });
                            }}
                          />
                        }
                      />
                      <Column
                        field="model"
                        header="Model"
                        filter={true}
                        sortable={true}
                        filterElement={
                          <InputText
                            style={{ width: "100%" }}
                            value={columnFilters?.model}
                            onChange={(e: any) => {
                              const { value } = e?.target;
                              setColumnFilters((prev) => {
                                return { ...prev, model: value };
                              });
                            }}
                          />
                        }
                      />
                      <Column
                        field="trim"
                        header="Trim"
                        filter={true}
                        sortable={true}
                        filterElement={
                          <InputText
                            style={{ width: "100%" }}
                            value={columnFilters?.trim}
                            onChange={(e: any) => {
                              const { value } = e?.target;
                              setColumnFilters((prev) => {
                                return { ...prev, trim: value };
                              });
                            }}
                          />
                        }
                      />
                      <Column
                        field="body"
                        header="Body"
                        filter={true}
                        sortable={true}
                        filterElement={
                          <InputText
                            style={{ width: "100%" }}
                            value={columnFilters?.body}
                            onChange={(e: any) => {
                              const { value } = e?.target;
                              setColumnFilters((prev) => {
                                return { ...prev, body: value };
                              });
                            }}
                          />
                        }
                      />
                      <Column
                        field="vin"
                        header="VIN"
                        filter={true}
                        sortable={true}
                        filterElement={
                          <InputText
                            style={{ width: "100%" }}
                            value={columnFilters?.vin}
                            onChange={(e: any) => {
                              const { value } = e?.target;
                              setColumnFilters((prev) => {
                                return { ...prev, vin: value };
                              });
                            }}
                          />
                        }
                      />
                      <Column
                        field="stockNumber"
                        header="Stock Number"
                        filter={true}
                        sortable={true}
                        filterElement={
                          <InputText
                            style={{ width: "100%" }}
                            value={columnFilters?.stockNumber}
                            onChange={(e: any) => {
                              const { value } = e?.target;
                              setColumnFilters((prev) => {
                                return { ...prev, stockNumber: value };
                              });
                            }}
                          />
                        }
                      />
                      <Column
                        field="price"
                        header="Price"
                        filter={true}
                        sortable={true}
                        style={{ width: "130px", overflow: "visible" }}
                        body={priceField}
                        filterElement={
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              overflow: "visible",
                            }}
                          >
                            <Grid
                              container
                              alignItems="center"
                              sx={{ position: "relative", overflow: "visible" }}
                            >
                              <Grid item xs={8}>
                                <InputText
                                  placeholder="PRICE"
                                  style={{ width: "100%" }}
                                  value={columnFilters?.price}
                                  onChange={(e: any) => {
                                    const { value } = e?.target;
                                    setColumnFilters((prev) => {
                                      return {
                                        ...prev,
                                        price: parseInt(value),
                                      };
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <FilterAltIcon
                                  onClick={() =>
                                    setIsPriceFilterVisible(
                                      !isPriceFilterVisible
                                    )
                                  }
                                />
                              </Grid>
                              {isPriceFilterVisible && (
                                <Paper
                                  sx={{
                                    width: "100%",
                                    position: "absolute",
                                    marginTop: "90px",
                                    zIndex: 99999999,
                                  }}
                                >
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Type
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={columnFilters?.priceType}
                                      label="TYPE"
                                      onChange={(event) =>
                                        setColumnFilters((prev) => {
                                          return {
                                            ...prev,
                                            priceType: event.target
                                              .value as any,
                                          };
                                        })
                                      }
                                    >
                                      <MenuItem
                                        onClick={() =>
                                          setIsPriceFilterVisible(false)
                                        }
                                        value={"equals"}
                                      >
                                        Equals
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          setIsPriceFilterVisible(false)
                                        }
                                        value={"greaterThan"}
                                      >
                                        Greater Than
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          setIsPriceFilterVisible(false)
                                        }
                                        value={"lessThan"}
                                      >
                                        Less Than
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Paper>
                              )}
                            </Grid>
                          </div>
                        }
                      />
                      <Column
                        field="type"
                        header="Type"
                        style={{ overflow: "visible" }}
                        filter={true}
                        sortable={true}
                        filterElement={typeFilterTemplate}
                      />
                      <Column
                        field="daysInStock"
                        header={
                          <>
                            <span>DIS</span>
                            <Tooltip title="This field represents FROM Days in stock">
                              <IconButton>
                                <InfoIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                        filter={true}
                        style={{ width: "130px", overflow: "visible" }}
                        sortable={true}
                        filterElement={
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              overflow: "visible",
                            }}
                          >
                            <Grid
                              container
                              alignItems="center"
                              sx={{ position: "relative", overflow: "visible" }}
                            >
                              <Grid item xs={8}>
                                <InputText
                                  placeholder="DIS"
                                  style={{ width: "100%" }}
                                  value={columnFilters?.daysInStock}
                                  type="number"
                                  onChange={(e: any) => {
                                    const { value } = e?.target;
                                    setColumnFilters((prev: any) => {
                                      return {
                                        ...prev,
                                        daysInStock: parseInt(value),
                                      };
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <FilterAltIcon
                                  onClick={() =>
                                    setIsDisFilterVisible(!isDisFilterVisible)
                                  }
                                />
                              </Grid>
                              {isDisFilterVisible && (
                                <Paper
                                  sx={{
                                    width: "100%",
                                    position: "absolute",
                                    marginTop: "90px",
                                    zIndex: 9999999,
                                  }}
                                >
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Type
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={columnFilters?.daysInStockType}
                                      label="TYPE"
                                      onChange={(event) =>
                                        setColumnFilters((prev) => {
                                          return {
                                            ...prev,
                                            daysInStockType: event.target
                                              .value as any,
                                          };
                                        })
                                      }
                                    >
                                      <MenuItem
                                        onClick={() =>
                                          setIsDisFilterVisible(false)
                                        }
                                        value={"equals"}
                                      >
                                        Equals
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          setIsDisFilterVisible(false)
                                        }
                                        value={"greaterThan"}
                                      >
                                        Greater Than
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          setIsDisFilterVisible(false)
                                        }
                                        value={"lessThan"}
                                      >
                                        Less Than
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Paper>
                              )}
                            </Grid>
                          </div>
                        }
                      />
                    </DataTable>
                    {initialCarsList?.length === 0 && (
                      <p
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          fontSize: "large",
                          padding: "20px 40px",
                          flexWrap: "wrap",
                          fontWeight: "bold",
                          color: "#b75f5f",
                        }}
                      >
                        Use the fields above to create filters for your
                        campaign. If you obtain a vAuto subscription, you will
                        also be able to create filters based on specific
                        vehicles in your inventory.
                      </p>
                    )}
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Button
                    id={IDS.button.back}
                    onClick={onBack}
                    className={"p-button-warning"}
                    icon={"pi pi-arrow-left"}
                    label={"Back"}
                  />
                  <Button
                    style={{ marginRight: 5 }}
                    label={"Next"}
                    icon={"pi pi-save"}
                    disabled={
                      campaignInfo?.filters?.length === 0 ||
                      !campaignInfo?.filters ||
                      !campaignInfo?.name
                    }
                    onClick={() => onNext()}
                  />
                  {(mode === MODE.EDIT) && (
                    <Button
                      style={{ marginRight: 15 }}
                      label={"Update and Exit"}
                      icon={"pi pi-save"}
                      disabled={isEqual(campaignInfo, initialData)}
                      onClick={() => {
                        Util.globalSpinner().show();
                        updateMyCampaign(campaignInfo?.id as number, {
                          active: campaignInfo?.active,
                          knownVisitor: campaignInfo?.knownVisitor,
                          name: campaignInfo?.name,
                          vehicleFilters: campaignInfo?.filters,
                          expirable: get(campaignInfo, "expirable"),
                          startDate: campaignInfo?.startDate
                            ? moment(campaignInfo?.startDate).format(
                                Util.localDateFormat
                              )
                            : (undefined as any),
                          endDate: campaignInfo?.endDate
                            ? moment(campaignInfo?.endDate).format(
                                Util.localDateFormat
                              )
                            : (undefined as any),
                          automaticallyDisabled:
                            campaignInfo?.automaticallyDisabled,
                          ...(campaignInfo?.notifications &&
                            campaignInfo?.notifications?.length > 0 && {
                              rtsNotifications: transformNotificationsToDto(
                                campaignInfo?.notifications
                              ),
                            }),
                          ...(campaignInfo?.sources &&
                            campaignInfo?.sources?.length > 0 && {
                              semVendorIds: campaignInfo?.sources,
                            }),
                        })
                          .then(() => {
                            setInitialData(campaignInfo);
                            setTimeout(() => {
                              history?.push("/my-campaigns");
                            }, 1000);
                          })
                          .catch((error: any) => {
                            Util.showError(error);
                          })
                          .finally(() => {
                            Util.globalSpinner().hide();
                          });
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default ChooseCar;
