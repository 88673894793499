import React from "react";

interface IProps {
  title: string;
  description: string;
}

const ColumnHeaderWithDescription = (props: IProps) => {
  return (
    <>
      <span className="p-column-title" title={props.description}>
        {props.title}
      </span>
    </>
  );
};

export default ColumnHeaderWithDescription;
