import React, { useEffect, useState } from "react";
import Util from "../../../../util/Util";
import { AAServicePerformanceReportDto } from "../../../../../index.dts";
import {
  getMyAcquisitionsSalesPerformanceReport,
  exportMyAcquisitionsSalesPerformanceReport,
} from "../../../../actions/wishListActions";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { IDS } from "../../../constants";
import { DateRangePickerWidget } from "../../../../components/data_range_picker/DateRangePickerWidget";
import { DateRangePeriods } from "../../../../components/data_range_picker/date_range_periods";
import moment from "moment-timezone";
import { shallowEqual, useSelector } from "react-redux";
import { IStore } from "../../../../../index.dts";
import "./style.css";

export function MyAcquisitionSalePerformanceReport() {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<AAServicePerformanceReportDto[]>([]);

  const [calendarFilter, setCalendarFilter] = useState<any>({
    period: (() => {
      const period = DateRangePeriods.get(DateRangePeriods.keys.last30Days);
      return {
        start: period.start,
        end: period.end,
      };
    })(),
  });

  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  function onPeriodChange(start?: Date, end?: Date): void {
    setCalendarFilter({
      period: {
        start: start ? moment(start)
          .set({
            hour: 0,
            minute: 0,
            seconds: 0,
          })
          .toDate() : undefined,
        end: end ? moment(end)
          .set({
            hour: 23,
            minute: 59,
            seconds: 59,
          })
          .toDate() : undefined,
      },
    });
  }

  const getPerformanceReport = (
    dealershipId: number,
    endDate: string,
    startDate: string
  ) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      let dateToSend;
      if (endDate !== undefined && startDate !== undefined) {
        dateToSend = `${moment(calendarFilter?.period?.start)
          .set({ hour: 0, minute: 0, seconds: 0 })
          .format(Util.localDateTimeFormat)},${moment(
          calendarFilter?.period?.end
        )
          .set({ hour: 23, minute: 59, seconds: 59 })
          .format(Util.localDateTimeFormat)}`;
      } else {
        dateToSend = undefined;
      }
      getMyAcquisitionsSalesPerformanceReport(dealershipId, dateToSend)
        .then((response) => {
          resolve(response);
          setData(response.data);
        })
        .catch((error) => {
          reject(error);
          Util.showError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });

  useEffect(() => {
    getPerformanceReport(
      dealershipContext.id,
      calendarFilter?.period?.end,
      calendarFilter?.period?.start
    );
    // eslint-disable-next-line
  }, [
    dealershipContext,
    calendarFilter?.period?.end,
    calendarFilter?.period?.start,
  ]);

  const onExport = (): void => {
    Util.globalSpinner().show();
    let dateToSend;
    if (
      calendarFilter?.period?.end !== undefined &&
      calendarFilter?.period?.start !== undefined
    ) {
      dateToSend = `${moment(calendarFilter?.period?.start)
        .set({ hour: 0, minute: 0, seconds: 0 })
        .format(Util.localDateTimeFormat)},${moment(calendarFilter?.period?.end)
        .set({ hour: 23, minute: 59, seconds: 59 })
        .format(Util.localDateTimeFormat)}`;
    } else {
      dateToSend = undefined;
    }
    exportMyAcquisitionsSalesPerformanceReport(dealershipContext.id, dateToSend)
      .then((response) => {
        Util.success("The requested report will be sent to your email soon!");
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => Util.globalSpinner().hide());
  };

  return (
    <div id={IDS.performanceReport.services} style={{ marginBottom: "-10px" }}>
      <div className={"p-grid"}>
        <div className="p-col-12" style={{ paddingBottom: 0 }}>
          <div className="card card-w-title datatable-demo">
            <div
              className={
                "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows offers"
              }
            >
              <DataTable
                loading={loading}
                dataKey="id"
                value={data}
                showGridlines
                header={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", columnGap: 40 }}>
                      <DateRangePickerWidget
                        start={calendarFilter?.period?.start}
                        end={calendarFilter?.period?.end}
                        ranges={DateRangePeriods.options}
                        onChange={onPeriodChange}
                      />
                    </div>
                    <div className={"p-grid-header-components"}>
                      <Button
                        icon={"pi pi-send"}
                        onClick={onExport}
                        label={"Get Report"}
                      />
                    </div>
                  </div>
                }
              >
                <Column
                  field={"dealershipName"}
                  header="Name"
                  filter={false}
                  sortable={false}
                  style={{ textAlign: "center" }}
                />
                <Column
                  header="Total Emails Sent To Dealership"
                  field={"totalEmailsSentToDealership"}
                  filter={false}
                  sortable={false}
                  style={{ textAlign: "center" }}
                />
                <Column
                  header="Total First Notification Emails Sent"
                  field={"totalFirstNotificationEmailsSent"}
                  filter={false}
                  sortable={false}
                  style={{ textAlign: "center" }}
                />
                <Column
                  header="Total Acquisitions Email Sent"
                  field={"totalAcquisitionsEmailSent"}
                  filter={false}
                  sortable={false}
                  style={{ textAlign: "center" }}
                />
                <Column
                  header="Emails Open By Customers"
                  field={"aaSalesEmailsOpen"}
                  filter={false}
                  sortable={false}
                  style={{ textAlign: "center" }}
                />
                <Column
                  header="Total Acquisition Opportunities"
                  field={"totalAcquisitionOpportunities"}
                  filter={false}
                  sortable={false}
                  style={{ textAlign: "center" }}
                />
                <Column
                  header="Vehicles Acquired (From Inventory)"
                  field={"vehiclesAcquired"}
                  filter={false}
                  sortable={false}
                  style={{ textAlign: "center" }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyAcquisitionSalePerformanceReport;
