export class ChartConfig {
  public static readonly averageScore = {
    borderWidth: 4,
    color: "#ff9900",
    id: "averageEnagementScore",
  };
  public static readonly border = {
    width: 1.5,
  };
  public static readonly leads = {
    id: "leads",
    labelKey: "leads",
  };
  public static readonly organic = {
    color: "#006400",
    id: "organic",
  };
  public static readonly vdp = {
    color: "#ff9900",
    id: "vdp",
  };
  public static readonly srp = {
    color: "#226622",
    id: "srp",
  };
  public static readonly pageViews = {
    id: "pageViews",
    labelKey: "pageViews",
  };
  public static readonly paid = {
    color: "#00008b",
  };
  public static readonly total = {
    color: "#000000",
    id: "total",
  };
  public static readonly trafficMarker = {
    backgroundColor: "rgba(0,0,0,0.8)",
    color: "red",
    width: 3,
    yAdjust: 10,
  };
  public static readonly visits = {
    id: "visits",
    labelKey: "visits",
  };
  public static readonly botOrganic = {
    color: "rgba(237,130,127,1)",
  };
  public static readonly botPaid = {
    color: "rgba(113,57,55,1)",
  };
  public static readonly botTotal = {
    color: "red",
  };
  public static readonly native = {
    color: "rgba(9,203,47,1)",
  };
  public static readonly botNative = {
    color: "rgba(97,165,220,1)",
  };
  public static readonly nonNative = {
    color: "rgba(208,97,220,1)",
  };
}
