export const DEFAULT_NEUTRAL_BTN_SOURCE = `
<div class="neutral-btn-template" style="
    text-align: center; 
    color: white; 
    display: flex;
    justify-content: center;
    width: 100%;
">
    <div style="
        width: 300px;
        background: #007CC2;
        padding: 5px 10px;
        border-radius: 20px;
    ">
        <span style="
            color: red;
            font-size: 20px;
            min-height: 30px;
            font-weight: bold;
            color: white;
            cursor: pointer;
            ">
            I'm interested!
        </span>
    </div>
</div>`;
