export const DEFAULT_PULL_UP_LEFT_DIV = `<div class="pi-pullup-template" style="text-align: center; color: white; background: red;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid black;
    -webkit-animation: bg-color-change 2s infinite;
    -moz-animation: bg-color-change 2s infinite;
    -o-animation: bg-color-change 2s infinite;
    -ms-animation: bg-color-change 2s infinite;
    animation: bg-color-change 2s infinite;">

    <div class="pi-pullup-line1">
        <span style="
            color: white;
            font-size: 20px;
            font-weight: bold;
            -webkit-animation: pi-color-change 2s infinite;
            -moz-animation: pi-color-change 2s infinite;
            -o-animation: pi-color-change 2s infinite;
            -ms-animation: pi-color-change 2s infinite;
            animation: pi-color-change 2s infinite;
            cursor: pointer;
            ">Get 500 Off
        </span>
    </div>
    <div class="pi-pullup-line2">
        <span style="
            color: red;
            font-size: 18px;
            font-weight: normal;
            -webkit-animation: pi-color-change 2s infinite;
            -moz-animation: pi-color-change 2s infinite;
            -o-animation: pi-color-change 2s infinite;
            -ms-animation: pi-color-change 2s infinite;
            animation: pi-color-change 2s infinite;
            cursor: pointer;
            ">
        
        </span>
    </div>
</div>`;
