import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Util from "../../../util/Util";
import "./ip_blocking.css";
import {getDealershipInfo} from "../../../actions/ipBlockingActions";
import {Button} from "primereact/button";
import Spinner from "../../../components/spinner/Spinner";

export function IpBlocking(props: any) {
    const history = useHistory();
    const url = Util.url.parse(history.location.search, true);
    const [siteId] = useState(Util._.get(url, "query.siteid", ""));
    const [pending, setPending] = useState<boolean>(false);

    const [assetObjectId, setAssetObjectId] = useState<string>(Util.defaultLogoPath);
    const [dealershipSiteUrl, setDealershipSiteUrl] = useState<string>("");
    const [dealershipAddress, setDealershipAddress] = useState<string>("");

    useEffect(() => {
        if (!Util.isEmpty(siteId)) {
            getData(siteId);
        }
    }, [siteId])

    const getData = (siteId: string): void => {
        setPending(true);
        getDealershipInfo(siteId)
            .then(response => {
                if (response.data.assetObjectId) {
                    setAssetObjectId(Util.getAssetUrl(response.data.assetObjectId))
                }
                setDealershipSiteUrl(response.data.siteURL);
                setDealershipAddress(response.data.dealershipAddress);
            })
            .finally(() => setPending(false))
    }

    const onClick = () => {
        alert("Thank you for your feedback!");
    }

    return (
        <div id={"ip_blocking"}>
            {pending ? (
                <Spinner visible={pending}/>
            ) : (
                <div id={"container"}>
                    <div id={"img-container"}>
                        <img src={assetObjectId} alt={"..."}/>
                    </div>
                    <div>
                        <h3>Can you see the image? Is this your address and website URL?</h3>
                        <p>{dealershipAddress}</p>
                        <a href={dealershipSiteUrl} rel={"noopener noreferrer"}
                           target={"_blank"}>{dealershipSiteUrl}</a>
                    </div>
                    <div id={"button-container"}>
                        <div style={{marginLeft: "auto", marginRight: "auto"}}>
                            <Button onClick={onClick} label={"Yes"}/>
                            <Button onClick={onClick} label={"No"}/>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default IpBlocking