import Axios from "../config/Axios";
import {
  AxiosResponse,
  IEnterpriseListGroup,
  IEnterpriseGroupDto,
  IEnterpriseGroupSearchRequestDto,
  IPageable, IEnterpriseGroup, IGroupDealership, IGroupDealershipSearchRequest, IUser
} from "../../index.dts";

export const getMyEnterpriseGroups = () => Axios.instance.get(`rts/enterprise`);
export const getEnterpriseGroupsList = (searchRequestDto: IEnterpriseGroupSearchRequestDto): Promise<IPageable<IEnterpriseListGroup>> => Axios.instance.post(`rts/enterprise/list`, searchRequestDto);
export const createEnterpriseGroup = (dto: IEnterpriseGroupDto): Promise<AxiosResponse<IEnterpriseListGroup>> => Axios.instance.post(`rts/enterprise`, dto);
export const getEnterpriseGroup = (id: number): Promise<AxiosResponse<IEnterpriseGroup>> => Axios.instance.get(`rts/enterprise/${id}`);
export const updateEnterpriseGroup = (id: number, dto: IEnterpriseGroupDto) => Axios.instance.put(`rts/enterprise/${id}`, dto);
export const getEnterpriseUsers = (): Promise<AxiosResponse<IUser[]>> => Axios.instance.get(`rts/enterprise/users`);
export const deleteEnterpriseGroups = (ids: number[]) => Axios.all(ids.map(id => Axios.instance.delete(`rts/enterprise/${id}`)));
export const getEnterpriseGroupDealerships = (searchRequest: IGroupDealershipSearchRequest): Promise<IPageable<IGroupDealership>> => Axios.instance.post("rts/enterprise/dealerships/page", searchRequest);
