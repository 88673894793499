import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "./role_and_permisiions.css";
import { Checkbox } from "primereact/checkbox";
import { IPermission, IRole, IStore } from "../../../index.dts";
import { updateRole } from "../../actions/roleManagementActions";
import Util from "../../util/Util";
import { shallowEqual, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataView } from "primereact/dataview";
import { useForceUpdate } from "../../hooks/useForceUpdate";

const RolePermissions = forwardRef(
  (
    props: {
      role: IRole;
      permissions: IPermission[];
      onDelete: any;
      onAfterSave: any;
    },
    ref: any
  ) => {
    const forceUpdate = useForceUpdate();

    const [editNameEnabled, setEditNameEnabled] = useState<boolean>(false);

    const [categoryPermissionMap] = useState<Map<string, IPermission[]>>(
      new Map()
    );
    const globalSpinner = useSelector(
      (store: IStore) => store.myTraffic.globalSpinner,
      shallowEqual
    );

    useEffect(() => {
      if (props.permissions.length > 0) {
        props.permissions.forEach((permission: IPermission) => {
          if (categoryPermissionMap.has(permission.category)) {
            const permissions = categoryPermissionMap.get(
              permission.category
            ) as IPermission[];
            permissions.push(permission);
          } else {
            categoryPermissionMap.set(permission.category, [permission]);
          }
        });
        forceUpdate();
      }
      // eslint-disable-next-line
    }, []);

    useImperativeHandle(ref, () => ({
      save: (role: IRole) => onSave(role),
    }));

    const onCategoryChange = (checked: boolean, permissions: IPermission[]) => {
      if (checked) {
        permissions.forEach((permission) => {
          if (!props.role.permissions.some((per) => per.id === permission.id)) {
            props.role.permissions.push(permission);
          }
        });
      } else {
        permissions.forEach((permission) => {
          const index = props.role.permissions.findIndex(
            (p) => p.id === permission.id
          );
          if (index > -1) props.role?.permissions.splice(index, 1);
        });
      }
      forceUpdate();
    };

    const onPermissionChange = (checked: boolean, permission: IPermission) => {
      if (checked) {
        props.role.permissions.push(permission);
      } else {
        const index = props.role.permissions.findIndex(
          (p) => p.id === permission.id
        );
        if (index > -1) {
          props.role.permissions.splice(index, 1);
        }
      }
      forceUpdate();
    };

    const onSave = (role: IRole) =>
      new Promise((resolve, reject) => {
        globalSpinner.show();
        updateRole({
          id: role.id,
          name: role.name,
          permissions: role.permissions.map((permission) => permission.id),
        })
          .then((response) => {
            props.onAfterSave(props.role);
            Util.success("Changes saved successfully.");

            resolve(response);
          })
          .catch((error) => {
            forceUpdate();

            Util.showError(error);

            reject(error);
          })
          .finally(() => {
            globalSpinner.hide();
          });
      });

    const onRoleNameChange = (value: string) => {
      props.role.name = value;

      forceUpdate();
    };

    const renderGridItem = (entry: any) => {
      return (
        <div className="p-col-12 p-lg-4 p-md-6 p-sm-12 role-category">
          <div className={"category-container p-panel"}>
            <div
              style={{
                backgroundColor: "#f4f4f4",
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
              }}
              className={"category-row"}
            >
              <Checkbox
                className={"role-checkbox"}
                onChange={(e) => onCategoryChange(e.checked, entry[1])}
                checked={entry[1].every((permission: any) =>
                  props.role.permissions.some((per) => per.id === permission.id)
                )}
              />
              <label style={{ fontSize: 18 }}>{entry[0]}</label>
            </div>
            {entry[1].map((permission: any, index: number) => {
              const checked = props.role.permissions.some(
                (p) => p.id === permission.id
              );
              return (
                <div
                  style={{
                    backgroundColor: checked ? "rgb(181 221 238)" : "white",
                    borderBottomLeftRadius:
                      index === entry[1].length - 1 ? 4 : undefined,
                    borderBottomRightRadius:
                      index === entry[1].length - 1 ? 4 : undefined,
                    borderBottom:
                      index === entry[1].length - 1
                        ? "unset"
                        : "1px solid #dddddd",
                  }}
                  className={"category-row"}
                  key={permission.id}
                >
                  <Checkbox
                    className={"role-checkbox"}
                    onChange={(e) => onPermissionChange(e.checked, permission)}
                    checked={checked}
                  />
                  <span>{permission.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    return (
      <div className={"role-permissions-container"}>
        <DataView
          value={Array.from(categoryPermissionMap)}
          layout={"grid"}
          emptyMessage={"No assets found"}
          itemTemplate={renderGridItem}
          header={
            <div style={{ display: "flex" }}>
              <div>
                {editNameEnabled ? (
                  <div>
                    <InputText
                      value={props.role.name}
                      onChange={(e: any) => onRoleNameChange(e.target.value)}
                    />
                    <Button
                      onClick={() => setEditNameEnabled(!editNameEnabled)}
                      icon="pi pi-check"
                      style={{ minWidth: 25, width: 25 }}
                      className="p-button p-component p-button-icon-only p-role-edit-name-button"
                    />
                  </div>
                ) : (
                  <div style={{ display: "flex" }}>
                    <div style={{ fontSize: 18, marginTop: 5 }}>
                      {props.role.name}
                    </div>
                    <Button
                      onClick={() => setEditNameEnabled(!editNameEnabled)}
                      icon="pi pi-pencil"
                      style={{ minWidth: 25, width: 25 }}
                      className="p-button p-role-edit-name-button"
                    />
                  </div>
                )}
              </div>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  id="apply-btn"
                  style={{ marginRight: 5 }}
                  onClick={() => onSave(props.role)}
                  className="p-button no-icon-buttons"
                  label={"Save"}
                />
                <Button
                  id="apply-btn"
                  onClick={props.onDelete}
                  className="p-button p-button-danger no-icon-buttons"
                  label={"Delete"}
                />
              </div>
            </div>
          }
        />
      </div>
    );
  }
);

export default RolePermissions;
