import { BaseChartDataset } from "./base_chart_dataset";
import { ChartConfig } from "../chart_config";

export class BotTotalDataset extends BaseChartDataset {
  constructor(args: { label: string; data?: number[]; yAxisID: string }) {
    super({
      backgroundColor: ChartConfig.botTotal.color,
      borderColor: ChartConfig.botTotal.color,
      borderWidth: ChartConfig.border.width,
      color: ChartConfig.botTotal.color,
      data: args.data ?? [],
      label: args.label,
      yAxisID: args.yAxisID,
    });
  }
}
