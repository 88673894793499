import {useEffect, useState} from "react";

export default function useWindowSize() {
    const isClient = typeof window === 'object';

    const [windowSize, setWindowSize] = useState(getSize);

    function getSize() {
        return {
            width: isClient ? window.innerWidth : 0,
            height: isClient ? window.innerHeight : 0
        };
    }


    useEffect(() => {
        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}
