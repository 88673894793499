import React, {CSSProperties} from "react";
import "./refresh_button.css"
import {Button} from "primereact/button";

interface IProps {
    onClick(): void;

    style?: CSSProperties
}

export function RefreshButton(props: IProps) {

    return (
        <Button
            id={"refresh-button"}
            icon={"pi pi-refresh"}
            style={props.style}
            onClick={props.onClick}
        />
    );
}

export default RefreshButton
