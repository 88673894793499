import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import Util from "../../util/Util";
import { Column } from "primereact/column";
import {
  ICheckboxEvent,
  IGroupDealership,
  IGroupDealershipSearchRequest,
  IPageable,
} from "../../../index.dts";
import {
  brandsBody,
  enterpriseGroupsBody,
} from "./enterprise_group/EnterpriseGroupForm";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import $ from "jquery";
import { InputText } from "primereact/inputtext";

interface IProps {
  readonly value: IGroupDealership[];

  onAdd(value: IGroupDealership[]): void;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadingOptions<T>(
    args: IGroupDealershipSearchRequest
  ): Promise<IPageable<IGroupDealership>>;
}

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

const GroupUserDealerships = (props: IProps) => {
  const forceUpdate = useForceUpdate();

  const [highlightClassName] = useState("p-highlight");
  const [datatableId] = useState("enterprise-user-dealerships");

  const [total, setTotal] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [value, setValue] = useState<IGroupDealership[]>([]);
  const [selected, setSelected] = useState<
    Map<number, { data: IGroupDealership; element: any }>
  >(new Map());

  const [nameFilterValue, setNameFilterValue] = useState<string>();
  const [addressFilterValue, setAddressFilterValue] = useState<string>();

  useEffect(() => {
    searchTimeout = setTimeout(() => {
      getData(0, limit).finally(() => {
        searchTimeoutInterval = 300;
      });
    }, searchTimeoutInterval);

    return () => {
      clearTimeout(searchTimeout);
    };
    // eslint-disable-next-line
  }, [nameFilterValue, addressFilterValue]);

  const getData = (page: number, limit: number): Promise<any> =>
    new Promise((resolve) => {
      setLoading(true);
      props
        .loadingOptions({
          name: nameFilterValue,
          address: addressFilterValue,

          pageLimit: limit,
          pageNumber: page,
          sorting: [],
        })
        .then((response) => {
          removeAllHighlighted();
          setValue(response.data.content);
          setTotal(response.data.totalElements);

          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const onPage = (event: any) => {
    getData(event.page, event.rows).finally(() => {
      setLimit(event.rows);
      setFirst(event.first);
    });
  };

  const onAdd = (): void => {
    if (selected.size === 0) {
      return;
    }

    removeAllHighlighted();

    props.onAdd(Array.from(selected.values()).map((item) => item.data));
    selected.clear();
  };

  const checkboxBody = (data: IGroupDealership, e: any): React.ReactElement => {
    const isChecked = selected.has(data.id);
    const disabledIndex = props.value.findIndex((item) => item.id === data.id);

    const isDisabled: boolean = disabledIndex !== -1;

    if (isChecked) {
      const item = selected.get(data.id);

      addHighlight(item?.element?.parentNode?.parentNode?.parentNode);
    }

    return (
      <Checkbox
        checked={isChecked}
        disabled={isDisabled}
        onChange={(e: CheckboxChangeParams) => onChange(e, data)}
      />
    );
  };

  const onChange = (e: any, data: IGroupDealership): void => {
    const element = e.originalEvent.target;
    if (!selected.has(data.id)) {
      selected.set(data.id, {
        data: data,
        element: element,
      });
    } else {
      selected.delete(data.id);

      removeHighlight(element?.parentNode?.parentNode?.parentNode?.parentNode);
    }

    forceUpdate();
  };

  const addHighlight = (value: any): void => {
    $(value).addClass(highlightClassName);
  };

  const removeHighlight = (value: any): void => {
    $(value).removeClass(highlightClassName);
  };

  const removeAllHighlighted = (): void => {
    $(`#${datatableId} tr`).removeClass(highlightClassName);
  };

  const nameFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) =>
        setNameFilterValue(Util.getDefaultIfNull(e.target.value, undefined))
      }
    />
  );

  const addressFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) =>
        setAddressFilterValue(Util.getDefaultIfNull(e.target.value, undefined))
      }
    />
  );

  const render = (): React.ReactElement => {
    return (
      <div
        className={
          limit > 10
            ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows dealership-users"
            : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows dealership-users-ten"
        }
      >
        <DataTable
          id={datatableId}
          filterDisplay="row"
          value={value}
          autoLayout={true}
          resizableColumns={true}
          columnResizeMode={"expand"}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
          lazy={true}
          loading={loading}
          paginator={true}
          sortMode={"multiple"}
          rows={limit}
          first={first}
          totalRecords={total}
          onPage={onPage}
          selection={Array.from(selected.values()).map((item) => item.data)}
          scrollable={true}
          scrollHeight={"570px"}
          onSelectionChange={(e) => setSelected(e.value)}
          header={
            <div style={{ height: 35 }}>
              <label>All Dealerships</label>
              <div style={{ float: "right" }}>
                <IconButton
                  size={"small"}
                  onClick={onAdd}
                  disabled={selected.size === 0}
                  style={{
                    backgroundColor: "#39a3f4",
                    color: "#FFFFFF",
                    borderRadius: 4,
                    opacity: selected.size === 0 ? 0.3 : 1,
                  }}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </div>
          }
        >
          <Column body={checkboxBody} style={{ width: "3em" }} />
          <Column
            filter={true}
            sortable={false}
            field={"name"}
            header={"Name"}
            filterElement={nameFilter}
          />
          <Column
            filter={true}
            sortable={false}
            field={"address"}
            header={"Address"}
            filterElement={addressFilter}
          />
          <Column
            filter={false}
            sortable={false}
            field={"brands"}
            header={"Brands"}
            body={brandsBody}
          />
          <Column
            filter={false}
            sortable={false}
            field={"rtsEnterpriseGroups"}
            header={"Enterprise Groups"}
            body={enterpriseGroupsBody}
          />
        </DataTable>
      </div>
    );
  };

  return render();
};

export default GroupUserDealerships;
