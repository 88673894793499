import Axios from "../config/Axios";
import { IAsset, IOffer } from "../../index.dts";
import { SupportRequestCategory } from "../util/Enums";

export const pauseDealership = (id: number) =>
  Axios.instance.put(`support/dealership/${id}/pause`);
export const restartDealership = (id: number) =>
  Axios.instance.put(`support/dealership/${id}/restart`);
export const activateDealership = (id: number) =>
  Axios.instance.put(`support/dealership/${id}/activate`);
export const deactivateDealership = (id: number) =>
  Axios.instance.put(`support/dealership/${id}/deactivate`);
export const updateDealershipReportRecipients = (
  id: number,
  args: {
    dailyLeadGroupSet: string[];
    leadSummaryRecipientsSet: string[];
    performanceReportsRecipientsSet: string[];
  }
) => Axios.instance.put(`support/dealership/${id}/recipients`, args);
export const updateDealershipRespectedIpAddresses = (
  id: number,
  restrictedIps: string[]
) =>
  Axios.instance.put(`support/dealership/${id}/restrict`, {
    restrictedIpsSet: restrictedIps,
  });
export const updateDealershipOfferState = (
  dealershipId: number,
  offerId: number,
  state: boolean
) =>
  Axios.instance.put(
    `support/dealership/${dealershipId}/offer/${offerId}/status`,
    { active: state }
  );
export const updateDealershipOfferTerms = (
  dealershipId: number,
  offerId: number,
  termsAndConditions: string
) =>
  Axios.instance.put(
    `support/dealership/${dealershipId}/offer/${offerId}/terms`,
    { termsAndConditions: termsAndConditions }
  );
export const updateDealershipOfferLimit = (
  dealershipId: number,
  offer: IOffer
) =>
  Axios.instance.put(
    `support/dealership/${dealershipId}/offer/${offer.id}/limit`,
    { offerType: offer.offerType }
  );
export const updateDealershipVendor = (
  dealershipId: number,
  websiteVendor: any
) =>
  Axios.instance.put(`support/dealership/${dealershipId}/vendor`, {
    websiteVendor: websiteVendor,
  });
export const addMyTrafficUser = (dealershipId: number, userEmail: string) =>
  Axios.instance.post(`support/mytraffic/users/invite`, {
    dealershipIds: [dealershipId],
    userEmail: userEmail,
  });
export const resendMyTrafficUsersInvitation = (ids: number[]) =>
  Axios.all(
    ids.map((id) => Axios.instance.post(`support/mytraffic/users/${id}/invite`))
  );
export const updateDealershipOfferExpiration = (
  dealershipId: number,
  offerId: number,
  startDate: string,
  endDate: string | null,
  expirable: boolean,
  notificationEmail: string
) =>
  Axios.instance.put(
    `support/dealership/${dealershipId}/offer/${offerId}/expire`,
    {
      endDate: endDate,
      startDate: startDate,
      expirable: expirable,
      notificationEmail: notificationEmail,
    }
  );
export const updateDealershipOfferName = (
  dealershipId: number,
  offerId: number,
  name: string
) =>
  Axios.instance.put(
    `support/dealership/${dealershipId}/offer/${offerId}/name`,
    {
      name: name,
    }
  );
export const getInactiveDealershipUsers = (dealershipId: number) =>
  Axios.instance.get(`support/dealership/${dealershipId}/users/inactive`);
export const updateDealershipOfferAsset = (
  dealershipId: number,
  offerId: number,
  asset: IAsset
) =>
  Axios.instance.put(
    `support/dealership/${dealershipId}/offer/${offerId}/asset`,
    { assetId: asset.id }
  );
export const sendDealershipSupportRequest = (
  dealershipId: number,
  category: SupportRequestCategory,
  message: string
) =>
  Axios.instance.post(`support/dealership/${dealershipId}/supportRequest`, {
    category: category,
    message: message,
  });
export const sendDealershipTags = (dealershipId: number, tags: string[]) =>
  Axios.instance.post(`support/dealership/${dealershipId}/sendTags`, tags);
export const resendDealershipWeeklyReport = (dealershipId: number) =>
  Axios.instance.post(`support/dealership/${dealershipId}/weeklyReport`);
export const updateDealershipUaGaIds = (id: number, uaGaIds: string) =>
  Axios.instance.put(`support/dealership/${id}/analytics`, {
    googleAnalyticsTrackingId: uaGaIds,
  });
