import "../../../App.css";

import { shallowEqual, useSelector } from "react-redux";

import { Button } from "primereact/button";
import { IActiveChat } from "..";
import { IStore } from "../../../../index.dts";
import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

interface StartChatButtonProps {
  chat: IActiveChat;
  onStartChat: VoidFunction;
}

export default function StartChatButton(props: StartChatButtonProps) {
  const user = useSelector((store: IStore) => store.auth.user, shallowEqual);
  const { t } = useTranslation();

  function isEnabled(): boolean {
    const isBot = props.chat.user?.isBot === true;
    const isEmpty = _.isNull(props.chat.user?.name) || _.isUndefined(props.chat.user?.name)
    const isMe = props.chat.user?.id === user.id;
    return isBot || isEmpty || isMe;
  }

  return (
    <Button
      aria-disabled={!isEnabled()}
      disabled={!isEnabled()}
      label={t("chatsView.grid.activeChats.buttons.joinChat")}
      onClick={props.onStartChat}
    />
  );
}