import React, { useEffect, useState } from "react";
import Util from "../../../../util/Util";
import moment from "moment-timezone";
import { Chart } from "primereact/chart";
import { Button } from "primereact/button";
import Spinner from "../../../spinner/Spinner";
import { Panel } from "primereact/panel";
import useWindowSize from "../../../../hooks/useWindowSize";
import DateRangePickerModal from "../../../data_range_picker/DateRangePickerModal";
import RefreshButton from "../../../refresh_button/RefreshButton";
import { getVisitorsAndLeadsReport } from "../../../../actions/myTrafficActions";

interface IProps {
  dealershipId: number;
}

interface IStatistics {
  [key: string]: {
    leads: number[];
    sessions: number[];
  };
}

const PeakHours = (props: IProps) => {
  const size = useWindowSize();

  const [startDate, setStartDate] = useState<string>(
    moment().subtract(6, "d").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(moment().format("YYYY-MM-DD"));

  const [pending, setPending] = useState<boolean>(false);

  const [statistics, setStatistics] = useState<IStatistics>({});
  const [day, setDay] = useState<number>(1);
  const [dataset, setDataset] = useState<any[]>([]);

  useEffect(() => {
    getData(props.dealershipId, startDate, endDate);
    // eslint-disable-next-line
  }, [props.dealershipId, startDate, endDate]);

  useEffect(() => {
    generateDataset(statistics, day);
    // eslint-disable-next-line
  }, [statistics, day]);

  const getHeight = (): number => {
    if (size.height < 300) {
      return 300;
    }

    if (size.height > 490) {
      return 490;
    }

    return size.height;
  };

  const getWidth = (): number => {
    if (size.width < 200) {
      return 200;
    }

    if (size.width > 1220) {
      return 1220;
    }

    return size.width;
  };

  const getData = (dealershipId: number, startDate: string, endDate: string) =>
    new Promise((resolve, reject) => {
      setPending(true);
      getVisitorsAndLeadsReport(dealershipId, startDate, endDate)
        .then((response) => {
          resolve(response);
          setStatistics(response.data.statistics);
        })
        .catch((error) => {
          reject(error);
          Util.showError(error);
        })
        .finally(() => setPending(false));
    });

  const getDaysBetweenDates = (start: string, end: string, day: number) => {
    const result: any = [];
    const endDate = new Date(end);
    const current = new Date(start);

    current.setDate(current.getDate() + ((day - current.getDay() + 7) % 7));
    while (current <= endDate) {
      result.push(moment(current).format("YYYY-MM-DD"));
      current.setDate(current.getDate() + 7);
    }
    return result;
  };

  const generateDataset = (statistics: IStatistics, day: number) => {
    const result = getDaysBetweenDates(startDate, endDate, day);
    const dataSets = new Map();

    result.forEach((label) => {
      const deyData = Util._.get(statistics, label, {
        leads: [],
        sessions: [],
      });

      if (dataSets.has("leads")) {
        const leads = dataSets.get("leads");
        leads.data = leads.data.map(
          (num: number, idx: number) => num + deyData.leads[idx]
        );
      } else {
        dataSets.set("leads", {
          label: "Leads",
          borderWidth: 0,
          data: deyData.leads,
          backgroundColor: "rgb(61, 213, 152)",
        });
      }

      if (dataSets.has("visitors")) {
        const visitors = dataSets.get("visitors");
        visitors.data = visitors.data.map(
          (num: number, idx: number) => num + deyData.sessions[idx]
        );
      } else {
        dataSets.set("visitors", {
          label: "Visitors",
          borderWidth: 0,
          data: deyData.sessions,
          backgroundColor: "rgb(0, 98, 255)",
        });
      }
    });

    setDataset(Array.from(dataSets.values()));
  };

  const onRangeChange = (start: string, end: string): void => {
    setEndDate(end);
    setStartDate(start);
  };

  const onRefresh = (): void => {
    getData(props.dealershipId, startDate, endDate);
  };

  return (
    <Panel
      style={{ padding: 2 }}
      header={
        <div style={{ width: "100%", display: "inline-block" }}>
          <div style={{ float: "left" }}>
            <div style={{ float: "left", marginRight: 2 }}>
              <DateRangePickerModal
                onChange={onRangeChange}
                start={startDate}
                end={endDate}
              />
            </div>
            <RefreshButton
              style={{ marginRight: 10, height: 38, width: 38 }}
              onClick={onRefresh}
            />
            <Button
              label={"Mon"}
              onClick={() => setDay(1)}
              className={`${
                day === 1 ? "" : "p-button-secondary"
              } no-icon-buttons`}
            />
            <Button
              label={"Tue"}
              onClick={() => setDay(2)}
              className={`${
                day === 2 ? "" : "p-button-secondary"
              } no-icon-buttons`}
            />
            <Button
              label={"Wed"}
              onClick={() => setDay(3)}
              className={`${
                day === 3 ? "" : "p-button-secondary"
              } no-icon-buttons`}
            />
            <Button
              label={"Thur"}
              onClick={() => setDay(4)}
              className={`${
                day === 4 ? "" : "p-button-secondary"
              } no-icon-buttons`}
            />
            <Button
              label={"Fri"}
              onClick={() => setDay(5)}
              className={`${
                day === 5 ? "" : "p-button-secondary"
              } no-icon-buttons`}
            />
            <Button
              label={"Sat"}
              onClick={() => setDay(6)}
              className={`${
                day === 6 ? "" : "p-button-secondary"
              } no-icon-buttons`}
            />
            <Button
              label={"Sun"}
              onClick={() => setDay(0)}
              className={`${
                day === 0 ? "" : "p-button-secondary"
              } no-icon-buttons`}
            />
          </div>
        </div>
      }
    >
      <Spinner visible={pending} />
      <Chart
        type={"bar"}
        key={size.width + size.height}
        height={getHeight().toString()}
        width={(getWidth() - 100).toString()}
        data={{
          datasets: dataset,
          labels: [
            "12am",
            "1am",
            "2am",
            "3am",
            "4am",
            "5am",
            "6am",
            "7am",
            "8am",
            "9am",
            "10am",
            "11am",
            "12pm",
            "1pm",
            "2pm",
            "3pm",
            "4pm",
            "5pm",
            "6pm",
            "7pm",
            "8pm",
            "9pm",
            "10pm",
            "11pm",
          ],
        }}
        options={{
          response: true,
          animation: {
            duration: 0,
          },
        }}
      />
    </Panel>
  );
};

export default PeakHours;
