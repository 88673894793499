import React from "react";

export function PublicViewWrapper(props: any) {

    return (
        <div id={"main-view"}>
            {props.children}
        </div>
    );
}

export default PublicViewWrapper
