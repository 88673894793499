import { fill, forEach, isArray, isEmpty, slice } from "lodash";

import { ISmartSpendFilterData } from "./SmartSpendFilter";
import { ProductTypes } from "../../util/enum/product_types";
import { SmartSpendFilterResolver } from "./smart_spend_filter_resolver";
import { sumArrays } from "../../util/common";

export class BaseSmartSpendFilterResolver implements SmartSpendFilterResolver {
  private _getEmpty(size?: number): number[] {
    return fill(Array(size), 0);
  }

  private _sumSelection(args: {
    container: number[][];
    filter: ISmartSpendFilterData;
    source: any;
  }): void {
    forEach(args.filter.options?.makes, (models, make) => {
      if (isEmpty(models)) {
        const makeTotal = args.source?.[make]?.daily_total;
        isArray(makeTotal) && args.container.push(makeTotal);
      } else {
        forEach(models, (model) => {
          let modelName = Object.keys(model)[0];
          let years = Object.values(model)[0];
          if (isEmpty(years)) {
            const modelTotal = args.source?.[make]?.[modelName]?.daily_total;
            isArray(modelTotal) && args.container.push(modelTotal);
          } else {
            forEach(years, (year) => {
              const yearTotal =
                args.source?.[make]?.[modelName]?.[year]?.daily_total;
              isArray(yearTotal) && args.container.push(yearTotal);
            });
          }
        });
      }
    });
  }

  public resolve(
    source: any,
    filter: ISmartSpendFilterData,
    size?: number
  ): number[] {
    let result;
    let subResult: any;

    switch (filter.product?.type) {
      case ProductTypes.all:
        subResult = source;
        break;
      case ProductTypes.certified:
        subResult = source?.certified;
        break;
      case ProductTypes.new:
        subResult = source?.new;
        break;
      case ProductTypes.used:
        subResult = source?.used;
        break;
      case ProductTypes.service:
        subResult = source?.service;
        break;
      default:
        throw new Error(`Unknown product type: ${filter.product?.type}`);
    }

    if (isEmpty(filter.options?.makes)) {
      result = subResult?.daily_total;
    } else {
      const arrays: number[][] = [];

      if (filter.product?.type === ProductTypes.all) {
        forEach(subResult, (value) => {
          this._sumSelection({
            container: arrays,
            filter: filter,
            source: value,
          });
        });
      } else {
        this._sumSelection({
          container: arrays,
          filter: filter,
          source: subResult,
        });
      }

      result = sumArrays(...arrays);
    }

    return result ? slice(result) : this._getEmpty(size);
  }
}
