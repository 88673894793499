import React, { useState } from "react";
import { AiCertificate } from "@iofferio/certificate";
import { useParams } from "react-router-dom";
import _ from "lodash";
import "./iOfferCertificate.css";

interface RouteParams {
  dealerId: string;
  guid: string;
}

export default function IOfferCertificate() {
  const params: RouteParams = useParams();

  return (
    <div>
      {!_.isNull(params.dealerId) &&
        !_.isUndefined(params.dealerId) &&
        !_.isNull(params.guid) &&
        !_.isUndefined(params.guid) && (
          <AiCertificate
            dealerId={params.dealerId}
            showCondition={false}
            environment={
              process.env.REACT_APP_ENV_VAR === "prod" ? "prod" : "dev"
            }
            guid={params.guid}
          ></AiCertificate>
        )}
    </div>
  );
}
