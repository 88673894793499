import React, { useState } from "react";
import "./chat_greetings.css";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import {
  ICampaignInfo,
  IChatGreeting,
  IChatGreetingDto,
} from "../../../../index.dts";
import Util from "../../../util/Util";
import { SketchPicker } from "react-color";
import { InputTextarea } from "primereact/inputtextarea";
import { MODE, PERMISSIONS } from "../../../util/Enums";
import { Panel } from "primereact/panel";
import { updateChatGreeting } from "../../../actions/chatGreetingActions";

interface IProps {
  campaignInfo: ICampaignInfo;
  setCampaignInfo: React.Dispatch<React.SetStateAction<ICampaignInfo>>;
  setStep: React.Dispatch<
    React.SetStateAction<{
      step: number | string;
      payload?: any;
    }>
  >;
  mode: MODE.NEW | MODE.EDIT;
  previousPage: string;
}

const styles: any = {
  picker: {
    width: "calc(100% - 20px)",
    padding: "10px 10px 0px",
    boxSizing: "initial",
    background: "rgb(255, 255, 255)",
    borderRadius: 4,
    boxShadow:
      "rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px",
  },
};

const CampaignGreetingEdit = (props: IProps) => {
  const { campaignInfo, setCampaignInfo, setStep, mode, previousPage } = props;
  const forceUpdate = useForceUpdate();
  const [limit] = useState<number>(Util.dataGridDefaultLimit);
  const [first] = useState<number>(0);

  const onRowEditSave = (props: any): void => {
    if (mode === MODE.NEW) {
      setCampaignInfo((prev: any) => {
        return {
          ...prev,
          greeting: {
            ...prev?.greeting,
            name: props.data.name,
            message: props.data.message,
            buttonLabel: props.data.buttonLabel,
            backgroundColor: props.data.backgroundColor,
            buttonBackgroundColor: props.data.buttonBackgroundColor,
          },
        };
      });
    } else {
      updateChatGreeting(props.data.id, {
        name: props.data.name,
        message: props.data.message,
        buttonLabel: props.data.buttonLabel,
        backgroundColor: props.data.backgroundColor,
        buttonBackgroundColor: props.data.buttonBackgroundColor,
      })
        .then(() => {
          Util.success("Chat Greeting updated successfully");
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => Util.globalSpinner().hide());
    }
  };

  const onRowEditCancel = (props: { data: IChatGreetingDto }): void => {};

  const onChange = (data: any, field: string, value: any): void => {
    data[field] = value;
    forceUpdate();
  };

  const chatGreetingEditor = (
    props: any,
    field: string,
    maxLength: number
  ): JSX.Element => {
    return (
      <InputTextarea
        className={"input-editor"}
        style={{
          minWidth: "100%",
          maxWidth: "100%",
          minHeight: 40,
          maxHeight: 200,
        }}
        value={props.rowData[field]}
        maxLength={maxLength}
        onChange={(e: any) => onChange(props.rowData, field, e.target.value)}
      />
    );
  };

  const chatGreetingColorEditor = (props: any, field: string): JSX.Element => {
    return (
      <SketchPicker
        disableAlpha={true}
        color={props.rowData[field]}
        styles={styles}
        onChange={(e) => {
          props.rowData[field] = e.hex.substring(1);
          forceUpdate();
        }}
      />
    );
  };

  const previewBody = (props: IChatGreeting): React.ReactNode => {
    return (
      <div className={"rts-template-item"}>
        <div
          id={"rts-template-chat-permission-container"}
          style={{ backgroundColor: `#${props.backgroundColor}` }}
        >
          <img
            id={"rts-template-chat-permission-close-svg"}
            src={"assets/icons/close.svg"}
            alt={""}
          />
          <div id={"rts-template-chat-permission-header"}>{props.name}</div>
          <div id={"rts-template-chat-permission-question-container"}>
            <div id={"rts-template-chat-permission-question-header"}>
              {props.message}
            </div>
            <div
              id={"rts-template-chat-permission-question-button"}
              style={{ backgroundColor: `#${props.buttonBackgroundColor}` }}
            >
              <div id={"rts-template-chat-permission-question-yes"}>
                <span>
                  {props.buttonLabel}{" "}
                  <img
                    id={"rts-template-chat-permission-question-arrow"}
                    src={"assets/icons/arrow.svg"}
                    alt={""}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const backgroundColorBody = (props: IChatGreeting): React.ReactNode => {
    return (
      <input
        style={{ width: "100%" }}
        type={"color"}
        value={`#${props.backgroundColor}`}
        disabled={true}
      />
    );
  };

  const buttonColorBody = (props: IChatGreeting): React.ReactNode => {
    return (
      <input
        style={{ width: "100%" }}
        type={"color"}
        value={`#${props.buttonBackgroundColor}`}
        disabled={true}
      />
    );
  };

  const onSave = () => {
    setStep((prev: any) => {
      return {
        ...prev,
        step: previousPage === "actionsEditPage" ? 2 : 3,
      };
    });
  };

  const onBack = () => {
    setStep((prev: any) => {
      return {
        ...prev,
        step: previousPage === "actionsEditPage" ? 2 : 3,
      };
    });
  };

  return (
    <Panel style={{ width: "100%", marginBottom: 10 }} header={"Edit Greeting"}>
      <div>
        <div
          className={
            "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"
          }
        >
          <DataTable
            id={"chat-greetings"}
            editMode={"row"}
            first={first}
            rows={limit}
            scrollable={true}
            resizableColumns={true}
            columnResizeMode={"expand"}
            value={[campaignInfo?.greeting]}
            onRowEditSave={onRowEditSave}
            onRowEditCancel={onRowEditCancel}
          >
            <Column
              filter={false}
              field={"name"}
              header={"Title"}
              editor={(props) => chatGreetingEditor(props, "name", 40)}
              style={{ width: 100 }}
            />
            <Column
              filter={false}
              field={"message"}
              header={"Message"}
              editor={(props) => chatGreetingEditor(props, "message", 70)}
              style={{ width: 100 }}
            />
            <Column
              filter={false}
              field={"buttonLabel"}
              header={"Button Label"}
              editor={(props) => chatGreetingEditor(props, "buttonLabel", 18)}
              style={{ width: 100 }}
            />
            <Column
              filter={false}
              field={"backgroundColor"}
              header={"Background Color"}
              body={backgroundColorBody}
              editor={(props) =>
                chatGreetingColorEditor(props, "backgroundColor")
              }
              style={{ width: 180 }}
            />
            <Column
              filter={false}
              field={"buttonBackgroundColor"}
              header={"Button Color"}
              body={buttonColorBody}
              editor={(props) =>
                chatGreetingColorEditor(props, "buttonBackgroundColor")
              }
              style={{ width: 180 }}
            />
            <Column
              field={"preview"}
              header={"Preview"}
              body={previewBody}
              style={{ width: 225 }}
            />
            {Util.hasAnyAuthority(PERMISSIONS.RTS_CHAT_MANAGEMENT) ? (
              <Column
                rowEditor
                headerStyle={{ width: "3rem" }}
                bodyStyle={{ textAlign: "center" }}
              />
            ) : null}
          </DataTable>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <Button
            onClick={onBack}
            className={"p-button-warning"}
            icon={"pi pi-arrow-left"}
            label={"Back"}
          />
          <Button
            style={{ marginRight: 5 }}
            label={"Next"}
            icon={"pi pi-save"}
            onClick={() => onSave()}
          />
        </div>
      </div>
    </Panel>
  );
};

export default CampaignGreetingEdit;
