import actions from "../constants/actions";
import Axios from "../config/Axios";
import axios from "axios";
import {AxiosResponse, IDealershipContext, IDevice, IUser, IUserActivate} from "../../index.dts";
import {STORAGE_EVENTS} from "../util/Enums";

export const logoutSuccess = () => ({
  type: actions.LOGOUT_SUCCESS
});

export const authPending = () => ({
  type: actions.AUTH_PENDING
});

export const authSuccess = (payload: { auth: any, user: IUser }) => ({
  type: actions.AUTH_SUCCESS,
  payload: payload
});

export const authError = () => ({
  type: actions.AUTH_ERROR
});

export const userUpdate = (payload: IUser) => ({
  type: actions.USER_UPDATE,
  payload: payload
});

export const setDealershipContext = (payload: IDealershipContext) => ({
  type: actions.SET_DEALERSHIP_CONTEXT,
  payload: payload
});

export const updateDropdownKey = (payload: string) => ({
  type: actions.UPDATE_DROPDOWN_KEY,
  payload: payload
});

export const setDevice = (payload: IDevice) => ({
  type: actions.SET_DEVICE,
  payload: payload
});


export const login = (username: string, password: string): Promise<any> => new Promise((resolve, rejects) => {
  const fromData = new FormData();
  fromData.set("grant_type", "password");
  fromData.set("username", username);
  fromData.set("password", password);
  fromData.set("client_id", "spring-security-oauth2-read-write-webclient");
  Axios.instance.post("public/oauth/token", fromData, {headers: {"Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION_HEADER}`}})
    .then(response => resolve(response))
    .catch(error => rejects(error))
    .finally(() => localStorage?.setItem(STORAGE_EVENTS.loginEvent, 'logout' + Math.random()))
})

export const logout = (): Promise<any> => new Promise((resolve, rejects) => {
  Axios.instance.post(`user/logout`)
    .then((response) => resolve(response))
    .catch((error) => rejects(error))
    .finally(() => localStorage?.setItem(STORAGE_EVENTS.logoutEvent, 'logout' + Math.random()))
});

export const refreshToken = (refreshToken: string) => {
  const fromData = new FormData();
  fromData.set("grant_type", "refresh_token");
  fromData.set("refresh_token", refreshToken);
  return Axios.instance.post(`public/oauth/token`, fromData, {
    headers: {
      "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION_HEADER}`,
      "Content-Type": "application/json"
    }
  });
};

export const userActivate = (token: string, useActivateDto: IUserActivate) => axios.post(`${process.env.REACT_APP_BASE_URL}/public/user/activate/?key=${token}`, useActivateDto);

export const getUserInfo = (access_token: string): Promise<AxiosResponse> => Axios.instance.get("user/info", {headers: {Authorization: `Bearer ${access_token}`}});
export const forgotPassword = (email: string) => axios.post(`${process.env.REACT_APP_BASE_URL}/public/user/forgot-password`, {email: email});
export const resetPassword = (key: string, password: string) => axios.post(`${process.env.REACT_APP_BASE_URL}/public/user/reset-password/?key=${key}`, {newPassword: password});
export const sendUserInvitation = (userId: number) => Axios.instance.post(`admin/users/${userId}/invite`);
export const validateKey = (key: string) => axios.get(`${process.env.REACT_APP_BASE_URL}/public/user/validate?key=${key}`);
