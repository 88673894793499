import React, { useRef, useState } from "react";
import "./data-picker-css.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";

import { InputText } from "primereact/inputtext";
import moment from "moment-timezone";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import Util from "../../util/Util";

interface IProps {
  start: string;
  end: string;

  onChange(startDate: string, endDate: string): void;
}

export function DateRangePickerModal(props: IProps) {
  const op: any = useRef(null);

  const [startDate, setStartDate] = useState<string>(props.start);
  const [endDate, setEndDate] = useState<string>(props.end);

  const onRangeChange = (start: string, end: string): void => {
    setEndDate(end);
    setStartDate(start);
  };

  const onApply = () => {
    op.current.hide();
    props.onChange(startDate, endDate);
  };

  const onCancel = (): void => {
    setStartDate(props.start);
    setEndDate(props.end);
    op.current.hide();
  };

  return (
    <div id={"date-range-calendar"}>
      <InputText
        style={{ width: 155, height: 38 }}
        value={`${moment(props.start).format(Util.usDateFormat)} - ${moment(
          props.end
        ).format(Util.usDateFormat)})`}
        readOnly={true}
        onClick={(e) => op.current.toggle(e)}
      />
      <OverlayPanel
        ref={op}
        style={{
          position: "absolute",
          display: "block",
          opacity: 1.016,
          zIndex: 1006,
        }}
        dismissable={true}
        id={"data-picker-panel"}
      >
        <DateRangePicker
          //showSelectionPreview={true}
          showPreview
          moveRangeOnFirstSelection={true}
          months={1}
          ranges={[
            {
              startDate: moment(startDate).toDate(),
              endDate: moment(endDate).toDate(),
              key: "selection",
            },
          ]}
          inputRanges={[]}
          minDate={moment().subtract(90, "d").toDate()}
          maxDate={moment().toDate()}
          direction={"horizontal"}
          onChange={(item: any) => {
            onRangeChange(
              moment(item.selection.startDate).format("YYYY-MM-DD"),
              moment(item.selection.endDate).format("YYYY-MM-DD")
            );
          }}
          staticRanges={[
            {
              label: "Today",
              hasCustomRendering: false,
              range: () => ({
                startDate: moment().toDate(),
                endDate: moment().toDate(),
              }),
              isSelected() {
                return (
                  startDate === moment().format("YYYY-MM-DD") &&
                  endDate === moment().format("YYYY-MM-DD")
                );
              },
            },
            {
              label: "Last 7 Days",
              hasCustomRendering: false,
              range: () => ({
                startDate: moment().subtract(6, "d").toDate(),
                endDate: moment().toDate(),
              }),
              isSelected() {
                return (
                  startDate ===
                    moment().subtract(6, "d").format("YYYY-MM-DD") &&
                  endDate === moment().format("YYYY-MM-DD")
                );
              },
            },
            {
              label: "This Week",
              hasCustomRendering: false,
              range: () => ({
                startDate: moment().startOf("isoWeek").toDate(),
                endDate: moment().endOf("isoWeek").toDate(),
              }),
              isSelected() {
                return (
                  startDate ===
                    moment().startOf("isoWeek").format("YYYY-MM-DD") &&
                  endDate === moment().endOf("isoWeek").format("YYYY-MM-DD")
                );
              },
            },
            {
              label: "Last Week",
              hasCustomRendering: false,
              range: () => ({
                startDate: moment()
                  .subtract(1, "weeks")
                  .startOf("isoWeek")
                  .toDate(),
                endDate: moment()
                  .subtract(1, "weeks")
                  .endOf("isoWeek")
                  .toDate(),
              }),
              isSelected() {
                return (
                  startDate ===
                    moment()
                      .subtract(1, "weeks")
                      .startOf("isoWeek")
                      .format("YYYY-MM-DD") &&
                  endDate ===
                    moment()
                      .subtract(1, "weeks")
                      .endOf("isoWeek")
                      .format("YYYY-MM-DD")
                );
              },
            },
            {
              label: "This Month",
              hasCustomRendering: false,
              range: () => ({
                startDate: moment().startOf("month").toDate(),
                endDate: moment().endOf("month").toDate(),
              }),
              isSelected() {
                return (
                  startDate ===
                    moment().startOf("month").format("YYYY-MM-DD") &&
                  endDate === moment().endOf("month").format("YYYY-MM-DD")
                );
              },
            },
            {
              label: "Last Month",
              hasCustomRendering: false,
              range: () => ({
                startDate: moment()
                  .subtract(1, "months")
                  .startOf("month")
                  .toDate(),
                endDate: moment().subtract(1, "months").endOf("month").toDate(),
              }),
              isSelected() {
                return (
                  startDate ===
                    moment()
                      .subtract(1, "months")
                      .startOf("month")
                      .format("YYYY-MM-DD") &&
                  endDate ===
                    moment()
                      .subtract(1, "months")
                      .endOf("month")
                      .format("YYYY-MM-DD")
                );
              },
            },
          ]}
        />
        <div>
          <Button
            label={"Cancel"}
            onClick={onCancel}
            className={"p-button-secondary no-icon-buttons"}
            style={{ float: "right", marginBottom: 10 }}
          />
          <Button
            label={"Apply"}
            className="no-icon-buttons"
            onClick={onApply}
            style={{ float: "right", marginBottom: 10 }}
          />
        </div>
      </OverlayPanel>
    </div>
  );
}

export default DateRangePickerModal;
