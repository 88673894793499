import "./marker_select.css";

import React, { useEffect, useRef, useState } from "react";

import AsyncSelect from "react-select/async";
import { getTrafficMarkers } from "../../actions/trafficMarkerActions";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface IMarker {
  date: Date;
  label: string;
  value: number;
}

interface IProps {
  readonly dealershipId: number;
  readonly onChange: (date?: Date, id?: number) => void;
  readonly value?: any;
  readonly loadOptions: (search: string) => Promise<IMarker[]>;
  readonly marker?: any;
}

export function MarkerSelect(props: IProps) {
  const { t }: any = useTranslation();
  let isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  function onChange(update: IMarker): void {
    props.onChange(update?.date, update?.value);
  }

  return (
    <div className={"marker-select-container"}>
      <AsyncSelect
        cacheOptions
        defaultOptions
        isClearable={true}
        loadOptions={props.loadOptions}
        menuPosition={"fixed"}
        onChange={onChange}
        placeholder={t("selectMarker")}
        value={props.value ? props.value : props.marker}
      />
    </div>
  );
}
