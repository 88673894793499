enum EmailTypes {
  email,
  emailSMTP,
}

export default EmailTypes;

export class EmailTypesUtil {
  public static getName(value?: EmailTypes): string {
    let name;

    switch (value) {
      case EmailTypes.email:
        name = "Email";
        break;
      case EmailTypes.emailSMTP:
        name = "Email SMTP Configuration";
        break;
      default:
        name = "unknown";
        break;
    }

    return name;
  }
}
