import "./active_chats_grid.css";
import "../../../App.css";

import { IActiveChat, StartChatCallback, VisitorDetailsCallback } from "..";
import { formatDateTime, fullname } from "../../../util/common";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import StartChatButton from "./StartChatButton";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { getMyDealerships } from "../../../actions/dealershipActions";
import Util from "../../../util/Util";
import { ISelectValue } from "../../../../index.dts";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import { Button } from "primereact/button";
import { IDS } from "../../constants";
import { InputText } from "primereact/inputtext";

interface IActiveChatsGridProps {
  data: IActiveChat[];
  isLoading: boolean;
  onStartChat: StartChatCallback;
  onVisitorDetails: VisitorDetailsCallback;
}

export function ActiveChatsGrid(props: IActiveChatsGridProps) {
  const { t }: any = useTranslation();
  const forceUpdate = useForceUpdate();
  const [visitorFilterValue, setVisitorFilterValue] = useState<string>("");
  const [campaignFilterValue, setCampaignFilterValue] = useState<string>("");
  const [userFilterValue, setUserFilterValue] = useState<string>("");

  const [selectedDealerships] = useState<
    Map<number, { label: string; value: number }>
  >(new Map());

  function filterVisitorId(value: any, filter: any): boolean {
    const pattern = new RegExp(filter, "i");
    const name = fullname(value?.firstName, value?.lastName) ?? "";
    const isName = pattern.test(name);
    const isEmail = pattern.test(value?.email);
    const isPhone = pattern.test(value?.phone);
    const isRefId = pattern.test(value?.refId);
    return isName || isEmail || isPhone || isRefId;
  }

  function formatUser(rowData: IActiveChat): any {
    let username: any;

    if (_.isNull(rowData?.user?.name) || _.isUndefined(rowData?.user?.name)) {
      username = "";
    } else if (rowData?.user?.isBot) {
      username = (
        <span>
          {rowData.user?.name}
          <span className="chatbot-badge">{t("chatbot")}</span>
        </span>
      );
    } else {
      username = rowData.user.name;
    }

    return username;
  }

  function formatVisitorId(rowData: IActiveChat): any {
    const visitor = rowData?.visitor;
    const dealership = rowData?.dealership;
    const name = fullname(visitor?.firstName, visitor?.lastName);
    const callback = () =>
      props.onVisitorDetails({
        dealershipId: dealership.id,
        dealershipRefId: dealership.refId,
        visitorRefId: visitor.refId,
      });

    return (
      <label className={"anchor"} onClick={callback}>
        <div>{name}</div>
        <div>{visitor?.email}</div>
        <div>{visitor?.phone}</div>
        <div>{visitor?.refId}</div>
      </label>
    );
  }

  const onSelectedDealershipChange = (values: any) => {
    const array = Util.getDefaultIfNull(values, []);

    selectedDealerships.clear();
    array.forEach((item: ISelectValue) => {
      selectedDealerships.set(item.value, item);
    });

    forceUpdate();
  };

  const promiseDealerships = (searchValue: string): any =>
    new Promise((resolve) => {
      const page = 0;
      const limit = 100;

      getMyDealerships({
        pageNumber: page,
        pageLimit: limit,
        dealershipName: searchValue,
        sorting: [],
      }).then((response) => {
        resolve(
          response.data.content.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          })
        );
      });
    });

  const dealershipFilter = (
    <AsyncSelect
      isMulti={true}
      cacheOptions
      defaultOptions
      styles={
        {
          // valueContainer: (provided, state) => ({
          //   ...provided,
          //   height: 100,
          //   maxHeight: 100,
          //   overflowY: "auto"
          // })
        }
      }
      hideSelectedOptions={false}
      isClearable={true}
      placeholder={"Select Dealerships"}
      closeMenuOnSelect={false}
      value={Array.from(selectedDealerships.values())}
      loadOptions={promiseDealerships}
      onChange={onSelectedDealershipChange}
    />
  );

  const onClear = () => {
    setVisitorFilterValue("");
    onSelectedDealershipChange([]);
    setCampaignFilterValue("");
    setUserFilterValue("");
  };

  const visitorFilter = (
    <InputText
      style={{ width: "100%" }}
      value={visitorFilterValue}
      onChange={(e: any) => {
        setVisitorFilterValue(e.target.value || "");
      }}
    />
  );

  const campaignFilter = (
    <InputText
      style={{ width: "100%" }}
      value={campaignFilterValue}
      onChange={(e: any) => {
        setCampaignFilterValue(e.target.value || "");
      }}
    />
  );

  const userFilter = (
    <InputText
      style={{ width: "100%" }}
      value={userFilterValue}
      onChange={(e: any) => {
        setUserFilterValue(e.target.value || "");
      }}
    />
  );

  const getData = (data: IActiveChat[]): any => {
    if (selectedDealerships.size > 0) {
      return data.filter((item) => selectedDealerships.has(item.dealership.id));
    }

    return data;
  };

  return (
    <DataTable
      id={"active-chats"}
      filterDisplay="row"
      columnResizeMode={"expand"}
      loading={props.isLoading}
      resizableColumns={true}
      value={getData(props.data)}
      removableSort
      header={
        <div
          className={"p-grid-header-components"}
          style={{ minHeight: "30px" }}
        >
          <Button
            id={IDS.button.clear}
            style={{ position: "absolute", left: "10px" }}
            icon={"pi pi-filter"}
            label={t("clear")}
            onClick={onClear}
          />
        </div>
      }
    >
      <Column
        body={formatVisitorId}
        bodyClassName={"text-underlined text-center"}
        field={"visitor"}
        filter={true}
        filterFunction={filterVisitorId}
        filterMatchMode={"custom"}
        header={t("visitorId")}
        filterElement={visitorFilter}
      />
      <Column
        field={"dealership.name"}
        filter={true}
        filterElement={dealershipFilter}
        header={t("dealership")}
        style={{ overflow: "visible" }}
        sortable={true}
      />
      <Column
        field={"campaignType"}
        filter={true}
        header={t("campaign")}
        sortable={true}
        filterElement={campaignFilter}
      />
      <Column
        body={formatUser}
        field={"user.name"}
        filter={true}
        header={t("user")}
        sortable={true}
        filterElement={userFilter}
      />
      <Column
        body={(rowData: IActiveChat) => formatDateTime(rowData.created)}
        bodyClassName={"text-center"}
        header={t("chatsView.grid.activeChats.headers.started")}
        sortable={true}
        sortField={"created"}
      />
      <Column
        body={(rowData: IActiveChat) => (
          <StartChatButton
            chat={rowData}
            onStartChat={(): void => props.onStartChat(rowData)}
          />
        )}
        bodyClassName={"text-center"}
        header={t("action")}
      />
    </DataTable>
  );
}
