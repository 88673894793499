import React, {forwardRef, useImperativeHandle, useState} from "react";
import "./global-spinner.css"
import {ProgressSpinner} from "primereact/progressspinner";

enum ACTIONS {
    SHOW = "SHOW",
    HIDE = "HIDE"
}

const GlobalSpinner = forwardRef((props: any, ref: any) => {

    const [visible, setVisible] = useState<boolean>(false);
    const [action, setAction] = useState<ACTIONS>(ACTIONS.HIDE);
    const [spinners] = useState<number[]>([]);

    const show = () => {
        spinners.push(spinners.length);

        if (spinners.length === 1) {
            setAction(ACTIONS.SHOW);
            setVisible(true);
        }
    };


    const hide = (duration?: number) => {
        spinners.pop();

        setTimeout(() => {
            if (spinners.length === 0) {
                setAction(ACTIONS.HIDE);
                setVisible(false);
            }
        }, 500)
    };

    useImperativeHandle(ref, () => ({
        show: show,
        hide: hide,
        isPending: isPending
    }));

    const isPending = (): boolean => {
        return spinners.length > 0;
    }

    const getStyle = (action: ACTIONS): any => {
        if (action === ACTIONS.HIDE) {
            return {
                transition: `${500}ms`,
                top: -70
            }
        } else {
            return {
                transition: `${100}ms`,
                top: "calc(50% - 60px)"
            }
        }
    };

    if (visible) {
        return (
            <div>
                <div id={"global-spinner-container"}/>
                <div id={"global-spinner"} style={getStyle(action)}>
                    <ProgressSpinner style={{width: 40, height: 40}} strokeWidth={"4"}/>
                </div>
            </div>
        )
    }
    return null;
});

export default GlobalSpinner
