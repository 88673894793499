import React, { useState, useEffect } from "react";
import "./customer_subscription.css";
import { useParams } from "react-router-dom";
import Util from "../../../util/Util";
import _ from "lodash";
import { ICustomerSubscription } from "../../../../index.dts";
import {
  getCustomerSubscription,
  updateCustomerSubscription,
} from "../../../actions/customerSubscriptionActions";

import { Button } from "primereact/button";
import { SubscriptionTypeRadio } from "../../../../src/components/subscription_type_radio/SubscriptionTypeRadio";

interface RouteParams {
  hash: string;
  email: string;
  dealershipId: string;
}

export enum SubscriptionTypes {
  subscribe,
  unsubscribe,
}

interface ISubscriptionType {
  readonly type?: SubscriptionTypes;
}

export function CustomerSubscription() {
  const params: RouteParams = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [customerExists, setCustomerExists] = useState<boolean>(false);
  const [subscriptionTypeUser, setSubscriptionTypeUser] =
    useState<ISubscriptionType>({});
  const [customerSubscription, setCustomerSubscription] =
    useState<ICustomerSubscription>({
      email: "",
      subscribed: false,
      dealershipId: 0,
    });

  function isValid(): boolean {
    try {
      const isOriginalHashValid =
        !_.isNull(params.hash) && !_.isUndefined(params.hash);
      const isOriginalEmailValid =
        !_.isNull(params.email) && !_.isUndefined(params.email);
      const isOriginalIdValid =
        !_.isNull(params.dealershipId) && !_.isUndefined(params.dealershipId);
      return isOriginalHashValid && isOriginalEmailValid && isOriginalIdValid;
    } catch (e) {
      setCustomerExists(false);
      return false;
    }
  }

  useEffect(() => {
    if (isValid()) {
      getConfig(params.hash, params.email, params.dealershipId);
    } else {
      setCustomerExists(false);
    }
    // eslint-disable-next-line
  }, [params]);

  const getConfig = (hash: string, email: string, dealershipId: string) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      getCustomerSubscription(hash, email, dealershipId)
        .then((response) => {
          setCustomerSubscription(response.data);
          setCustomerExists(true);
          setSubscriptionTypeUser({
            type: response.data.subscribed
              ? SubscriptionTypes.subscribe
              : SubscriptionTypes.unsubscribe,
          });
        })
        .catch((error) => {
          setCustomerSubscription({
            email: "",
            subscribed: false,
            dealershipId: 0,
          });
          setCustomerExists(false);
          setSubscriptionTypeUser({ type: SubscriptionTypes.unsubscribe });
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const onEdit = () => {
    Util.globalSpinner().show();
    let dto: any = { ...customerSubscription };
    dto.subscribed = subscriptionTypeUser.type === 0 ? true : false;
    updateCustomerSubscription(params.hash, dto)
      .then((response) => {
        Util.globalSpinner().hide();
        Util.success("Subscription updated");
      })
      .catch((error) => {
        Util.globalSpinner().hide();
        Util.error(error.response.data.message);
      });
  };

  function onSubscriptionTypeChange(update: number): void {
    setSubscriptionTypeUser({ type: update });
  }

  // Use the hash and email variables for your component's logic
  return (
    <div id={"subs-view"}>
      {customerExists && (
        <div id={"wrapper"}>
          <div
            id={"form"}
            style={{ boxShadow: "10px 10px 27px rgba(0, 0, 0, 0.5)" }}
          >
            <div style={{ paddingTop: 20 }}>
              <label style={{ fontSize: 18, fontWeight: "bold" }}>
                Manage your email subscriptions.
              </label>
            </div>
            <div style={{ padding: 30, paddingBottom: 10 }}>
              <div style={{ marginBottom: 5, paddingTop: 5 }}>
                <label className={"input-label"}>{atob(params.email)}</label>
                <div style={{ paddingTop: 15 }}>
                  <SubscriptionTypeRadio
                    onChange={onSubscriptionTypeChange}
                    type={subscriptionTypeUser.type}
                  />
                </div>
              </div>
              <div style={{ marginTop: 20, paddingBottom: 20 }}>
                <Button
                  disabled={loading}
                  onClick={() => onEdit()}
                  style={{
                    width: "100%",
                    padding: "2px !important",
                    boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.5)",
                  }}
                  label="Save"
                  className="p-button-raised p-button-rounded"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {!customerExists && (
        <h1 style={{ color: "#fff" }}>User does not exist.</h1>
      )}
    </div>
  );
}

export default CustomerSubscription;
