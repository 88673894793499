import React, { useEffect, useState } from "react";
import "./users.css";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import {
  changeUserAccess,
  changeUserRole,
  getRoleList,
  getUserList,
  inviteUser,
  updateUserById,
} from "../../actions/userManagementActions";
import Util from "../../util/Util";
import { IStore, IUser, IUserDto } from "../../../index.dts";
import AsyncSelect from "react-select/async";
import { getAllDealerships } from "../../actions/dealershipActions";
import { sendUserInvitation } from "../../actions/authActions";
import { SEARCH_OPERATIONS } from "../../util/Enums";
import CloseButton from "../../components/close_button/CloseButton";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { useDropzone } from "react-dropzone";
import { bulkImport } from "../../actions/userActions";
import { ExportToCsv } from "export-to-csv";
import { shallowEqual, useSelector } from "react-redux";
import { IDS } from "../constants";
import { useTranslation } from "react-i18next";

const hash = require("object-hash");
type status = "all" | "active" | "inactive";

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

export function Users() {
  const forceUpdate = useForceUpdate();
  const [loading, setLoading] = useState<boolean>(true);
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  const { t }: any = useTranslation();

  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    accept: ".csv",
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [first, setFirst] = useState<number>(0);
  const [usersList, setUsersList] = useState<IUser[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [sortMap] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(new Map());
  const [firstNameFilterValue, setFirstNameFilterValue] = useState<string>("");
  const [lastNameFilterValue, setLastNameFilterValue] = useState<string>("");
  const [emailFilterValue, setEmailFilterValue] = useState<string>("");
  const [usersStatusValue, setUsersStatusValue] = useState<status>("all");
  const [roleValue, setRoleValue] = useState<any>(null);

  const [showInviteUserSidebar, setShowInviteUserSidebar] =
    useState<boolean>(false);
  const [showBulkImportSidebar, setShowBulkImportSidebar] =
    useState<boolean>(false);

  const [inviteUserRole, setInviteUserRole] = useState<{
    value: number;
    label: string;
  }>({ value: 0, label: "" });
  const [inviteUserDealerships, setInviteUserDealerships] = useState<
    { value: number; label: string }[]
  >([]);

  const [inviteUserEmail, setInviteUserEmail] = useState("");
  const [inviteUserFirstName, setInviteUserFirstName] = useState("");
  const [inviteUserLastName, setInviteUserLastName] = useState("");

  const [emailWarning, setEmailWarning] = useState("");
  const [roleWarning, setRoleWarning] = useState("");
  const [onErrorMessage, setOnErrorMessage] = useState("");
  const [onSuccessMessage, setOnSuccessMessage] = useState("");

  const onClear = () => {
    sortMap.clear();
    setFirstNameFilterValue("");
    setRoleValue("all");
    setLastNameFilterValue("");
    setEmailFilterValue("");
    setUsersStatusValue("all");
  };

  useEffect(() => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getData(0, limit).finally(() => {
        setPage(0);
        setFirst(0);
        searchTimeoutInterval = 500;
      });
    }, searchTimeoutInterval);
    // eslint-disable-next-line
  }, [
    usersStatusValue,
    firstNameFilterValue,
    lastNameFilterValue,
    emailFilterValue,
    roleValue,
  ]);

  const getData = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      setLoading(true);

      const filter: any[] = [];
      const statusFilter = getStatusFilter(usersStatusValue);

      if (statusFilter) {
        filter.push(statusFilter);
      }

      if (firstNameFilterValue) {
        filter.push({
          field: "firstName",
          value: firstNameFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (lastNameFilterValue) {
        filter.push({
          field: "lastName",
          value: lastNameFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (emailFilterValue) {
        filter.push({
          field: "email",
          value: emailFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (roleValue) {
        filter.push({
          field: "role",
          value: roleValue,
          operation: SEARCH_OPERATIONS.EQUAL,
        });
      }

      getUserList({
        filter: filter,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
        paging: { page: page, pageLimit: limit },
      })
        .then((response) => {
          setLoading(false);
          setUsersList(response.data.content);
          setTotalItemsCount(response.data.totalElements);

          resolve(response);
        })
        .catch((error) => {
          setLoading(false);
          Util.showError(error);

          reject(error);
        });
    });

  const promiseRoles = (inputValue: string, includeAll?: boolean) =>
    new Promise((resolve) => {
      getRoleList({
        filter: [{ field: "name", operation: "like", value: inputValue }],
        sorting: [],
        paging: { page: 0, pageLimit: 100 },
      }).then((response) => {
        if (includeAll) {
          response.data.content.unshift({ id: null, name: "All" });
        }
        resolve(
          response.data.content.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
            };
          })
        );
      });
    });

  const promiseDealerships = (inputValue: string): any =>
    new Promise((resolve) => {
      getAllDealerships({
        filter: [
          { field: "name", operation: "like", value: inputValue },
          {
            field: "deleted",
            operation: SEARCH_OPERATIONS.EQUAL,
            value: false,
          },
        ],
        sorting: [],
        paging: { page: 0, pageLimit: 100 },
      }).then((response) => {
        resolve(
          response.data.content.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
            };
          })
        );
      });
    });

  const roleFilter = (
    <AsyncSelect
      menuPosition={"fixed"}
      cacheOptions
      defaultOptions
      defaultValue={{ label: "All", value: null }}
      loadOptions={(inputValue): any => promiseRoles(inputValue, true)}
      onChange={(value: any) => setRoleValue(value.value)}
    />
  );

  const firstNameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={firstNameFilterValue}
      onChange={(e: any) => {
        setFirstNameFilterValue(e.target.value || "");
      }}
    />
  );

  const lastNameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={lastNameFilterValue}
      onChange={(e: any) => {
        setLastNameFilterValue(e.target.value || "");
      }}
    />
  );

  const emailFilter = (
    <InputText
      style={{ width: "100%" }}
      value={emailFilterValue}
      onChange={(e: any) => {
        setEmailFilterValue(e.target.value || "");
      }}
    />
  );

  const statusTemplate = (rowData: any) => {
    if (!rowData.activated) {
      return <div>Invited</div>;
    }
    if (!rowData.enabled) {
      return <div>Inactive</div>;
    }
    return <div>Active</div>;
  };

  const actionsTemplate = (rowData: any) => {
    if (!rowData.activated) {
      return (
        <label
          onClick={() => onResendInvitation(rowData)}
          style={{ cursor: "pointer", color: "#007ad9" }}
        >
          Resend Invitation
        </label>
      );
    }
    return "";
  };

  const onResendInvitation = (rowData: any) => {
    setLoading(true);
    sendUserInvitation(rowData.id)
      .then(() => {
        setLoading(false);
        Util.success("User invited successfully.");
      })
      .catch((error) => {
        setLoading(false);
        Util.showError(error);
      });
  };

  const getStatusFilter = (usersStatusValue: status): any => {
    switch (usersStatusValue) {
      case "active":
        return {
          field: "enabled",
          value: true,
          operation: SEARCH_OPERATIONS.EQUAL,
        };
      case "inactive":
        return {
          field: "enabled",
          value: false,
          operation: SEARCH_OPERATIONS.EQUAL,
        };
      default:
        return null;
    }
  };

  const statusFilter = (
    <Dropdown
      value={usersStatusValue}
      options={[
        { label: t("all"), value: "all" },
        { label: t("active"), value: "active" },
        { label: t("inactive"), value: "inactive" },
      ]}
      style={{ width: "100%", boxShadow: "none" }}
      onChange={(e: any) => {
        setUsersStatusValue(e.target.value);
      }}
    />
  );

  const onPage = (event: any) => {
    getData(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
    });
  };

  const onSort = (data: any) => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      forceUpdate();
      getData(page, limit);
    }
  };

  const stateEditor = (props: any) => {
    if (props.rowData.activated) {
      if (props.rowData.username !== "sysadmin") {
        return (
          <Dropdown
            value={!props.rowData.enabled}
            options={[
              { label: "Active", value: false },
              { label: "Inactive", value: true },
            ]}
            onChange={(e) => onStateEditorChange(e, props.rowData)}
            style={{ width: "100%" }}
            placeholder="Select a State"
          />
        );
      }
      return <div>Active</div>;
    }
    return <div>Invited</div>;
  };

  const roleEditor = (props: any) => {
    if (props.rowData.username !== "sysadmin") {
      return (
        <AsyncSelect
          menuPosition={"fixed"}
          cacheOptions
          defaultOptions
          defaultValue={{
            label: props.rowData.roleName,
            value: props.rowData.roleId,
          }}
          loadOptions={(inputValue): any => promiseRoles(inputValue, false)}
          onChange={(event: any) => onChangeUserRole(event, props.rowData)}
        />
      );
    }
    return <div>{props.rowData.roleName}</div>;
  };

  const handleKeypress = (e, props) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      updateUser(props.rowData.id, props.rowData);
    }
  };

  const firstNameEditor = (props: any) => {
    return (
      <div className="p-inputgroup">
        <InputText
          placeholder="First Name"
          value={props.value}
          onChange={(e: any) => {
            props.editorCallback(e.target.value);
          }}
          onKeyDown={(e: any) => handleKeypress(e, props)}
        />
        <Button
          style={{ width: 30, minWidth: 30 }}
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            document.getElementById("outside-element")?.click();
            updateUser(props.rowData.id, props.rowData);
          }}
        />
      </div>
    );
  };

  const lastNameEditor = (props: any) => {
    return (
      <div className="p-inputgroup">
        <InputText
          placeholder="Last Name"
          value={props.value}
          onChange={(e: any) => {
            props.editorCallback(e.target.value);
          }}
          onKeyDown={(e: any) => handleKeypress(e, props)}
        />
        <Button
          style={{ width: 30, minWidth: 30 }}
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            document.getElementById("outside-element")?.click();
            updateUser(props.rowData.id, props.rowData);
          }}
        />
      </div>
    );
  };

  const updateUser = (id: number, data: IUserDto) => {
    updateUserById(id, {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      username: data.username,
    })
      .then(() => {
        getData(page, limit);
        Util.success("User updated successfully.");
      })
      .catch((error) => Util.showError(error));
  };

  const onStateEditorChange = (e: any, rowData: any) => {
    if (!e.value) {
      rowData.enabled = true;

      setLoading(true);
      changeUserAccess(rowData.id, !e.value)
        .then(() => {
          setLoading(false);
          Util.success("User activated successfully.");
        })
        .catch((error) => {
          setLoading(false);
          Util.showError(error);
        });
    } else {
      rowData.enabled = false;

      setLoading(true);
      changeUserAccess(rowData.id, !e.value)
        .then(() => {
          setLoading(false);
          Util.success("User deactivated successfully.");
        })
        .catch((error) => {
          setLoading(false);
          Util.showError(error);
        });
    }
  };

  const onChangeUserRole = (event: any, rowData: any) => {
    setLoading(true);
    changeUserRole(rowData.id, event.value)
      .then(() => {
        setLoading(false);
        rowData.roleId = event.value;
        rowData.roleName = event.label;
        Util.success("User role changed successfully.");
      })
      .catch((error) => {
        setLoading(false);
        Util.showError(error);
      });
  };

  const onInvite = () => {
    setRoleWarning("");
    setEmailWarning("");

    if (!Util.validateEmail(inviteUserEmail)) {
      setEmailWarning("Invalid email.");
      return;
    }

    if (Util.isEmpty(inviteUserRole.label)) {
      setRoleWarning("Invalid role.");
      return;
    }

    Util.globalSpinner().show();
    inviteUser({
      firstName: inviteUserFirstName.trim(),
      lastName: inviteUserLastName.trim(),
      userEmail: inviteUserEmail.trim(),
      roleName: inviteUserRole.label.trim(),
      dealershipIds: inviteUserDealerships.map((item) => item.value),
    })
      .then(() => {
        setOnSuccessMessage(`User "${inviteUserEmail}" invited successfully.`);
        resetState();
        setTimeout(() => {
          setOnSuccessMessage("");
        }, 3000);
      })
      .catch((error) => {
        const message = Util._.get(error, "response.data.message", null);
        setOnErrorMessage(
          Util.getDefaultIfNull(message, Util.defaultErrorMessage)
        );
      })
      .finally(() => {
        Util.globalSpinner().hide();
      });
  };

  const resetState = (): void => {
    setRoleWarning("");
    setEmailWarning("");
    setInviteUserEmail("");
    setInviteUserFirstName("");
    setInviteUserLastName("");
    setInviteUserRole({ label: "", value: 0 });
    setOnErrorMessage("");
    setInviteUserDealerships([]);
  };

  const onInviteUserSidebarHide = () => {
    resetState();
    getData(page, limit);
    setShowInviteUserSidebar(false);
  };

  const getResponseMessage = (): React.ReactNode => {
    if (onSuccessMessage.trim()) {
      return (
        <div>
          <span style={{ color: "green" }}>{onSuccessMessage}</span>
        </div>
      );
    } else if (onErrorMessage.trim()) {
      return (
        <div>
          <span style={{ color: "red" }}>{onErrorMessage}</span>
        </div>
      );
    }
    return null;
  };

  const onHide = () => {
    acceptedFiles.splice(0, acceptedFiles.length);
    setShowBulkImportSidebar(false);
    getData(page, limit);
  };

  const onOpenInviteDrawer = () => {
    setInviteUserDealerships(
      dealershipContext.id !== 0
        ? [
            {
              value: dealershipContext.id,
              label: dealershipContext.name,
            },
          ]
        : []
    );
    setShowInviteUserSidebar(true);
  };

  const removeFile = (file: File) => () => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    forceUpdate();
  };

  const files = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      <span>
        {file.path} - {file.size} bytes
      </span>
      <i
        style={{ marginLeft: 5, color: "red", cursor: "pointer" }}
        className={"pi pi-trash"}
        onClick={removeFile(file)}
      />
    </li>
  ));

  const onDownloadFailedUsersReport = (data: {
    [key: string]: string;
  }): void => {
    const csvExporter = new ExportToCsv({
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: false,
      useKeysAsHeaders: true,
      filename: "report",
    });

    const reportData = Object.entries(data).map((entry) => {
      return {
        Email: entry[0],
        Error: entry[1],
      };
    });

    csvExporter.generateCsv(reportData);
  };

  const onSendBulkImport = (): void => {
    if (acceptedFiles.length > 0) {
      Util.globalSpinner().show();
      bulkImport(acceptedFiles)
        .then((response) => {
          if (Object.keys(response.data).length === 0) {
            Util.success("Users invited successfully.");
          } else {
            Util.warning(
              <div>
                <label>
                  Some imports are failed. Click{" "}
                  <span
                    onClick={() => onDownloadFailedUsersReport(response.data)}
                    style={{ color: "#39a3f4", cursor: "pointer" }}
                  >
                    here{" "}
                  </span>
                  to download report.
                </label>
              </div>
            );
          }
        })
        .catch((error) => Util.showError(error))
        .finally(() => Util.globalSpinner().hide());
    } else {
      Util.warning("Please import file first.");
    }
  };

  const generateTemplate = (): void => {
    const csvExporter = new ExportToCsv({
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: false,
      useKeysAsHeaders: true,
      filename: "template",
    });

    csvExporter.generateCsv([
      {
        '"First Name"': "John",
        '"Last Name"': "Tiger",
        '"Email"': "john@example.com",
        '"Role"': "admin",
      },
    ]);
  };

  return (
    <div id={"accounts-and-users"}>
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div
            className="card card-w-title datatable-demo"
            id="outside-element"
          >
            <div
              className={
                limit > 10
                  ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting"
                  : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
              }
            >
              <DataTable
                resizableColumns={true}
                columnResizeMode={"expand"}
                rowsPerPageOptions={Util.rowsPerPageOptions}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                paginator={true}
                scrollable
                totalRecords={totalItemsCount}
                rows={limit}
                lazy={true}
                first={first}
                loading={loading}
                filterDisplay="row"
                onPage={onPage}
                onSort={onSort}
                sortMode={"multiple"}
                multiSortMeta={Array.from(sortMap.values())}
                header={
                  <div className={"p-grid-header-components"}>
                    <Button
                      id={IDS.button.clear}
                      style={{ position: "absolute", left: "10px" }}
                      icon={"pi pi-filter"}
                      label={t("clear")}
                      onClick={onClear}
                    />
                    <Button
                      label={"Invite"}
                      icon="pi pi-user-plus"
                      style={{ marginRight: 5 }}
                      className={"pi-"}
                      onClick={() => onOpenInviteDrawer()}
                    />
                    <Button
                      label={"Bulk Import"}
                      icon="pi pi-users"
                      onClick={() => setShowBulkImportSidebar(true)}
                    />
                  </div>
                }
                value={usersList}
              >
                <Column
                  field="firstName"
                  header="First Name"
                  filter={true}
                  filterElement={firstNameFilter}
                  sortable={true}
                  editor={firstNameEditor}
                />
                <Column
                  field="lastName"
                  header="Last Name"
                  filter={true}
                  filterElement={lastNameFilter}
                  sortable={true}
                  editor={lastNameEditor}
                />
                <Column
                  field="email"
                  header="Email"
                  filter={true}
                  filterElement={emailFilter}
                  sortable={true}
                />
                <Column
                  field="roleName"
                  header="Role"
                  filter={true}
                  filterElement={roleFilter}
                  editor={roleEditor}
                  sortable={true}
                />
                <Column
                  field="enabled"
                  header="Status"
                  filter={true}
                  filterElement={statusFilter}
                  sortable={true}
                  body={statusTemplate}
                  editor={stateEditor}
                  style={{ overflow: "visible" }}
                />
                <Column header="Actions" body={actionsTemplate} />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        style={{ width: 350 }}
        position={"right"}
        visible={showInviteUserSidebar}
        onHide={onInviteUserSidebarHide}
        showCloseIcon={false}
      >
        {showInviteUserSidebar ? (
          <div style={{ width: "100%", height: "100%" }}>
            <CloseButton onHide={onInviteUserSidebarHide} />
            <div
              style={{
                marginTop: 25,
                marginLeft: -15,
                marginRight: -15,
                borderBottom: "1px solid #afa6a6",
              }}
            >
              <label style={{ fontSize: 20, marginLeft: 20 }}>
                Invite User
              </label>
            </div>
            <div style={{ padding: 5, height: "100%", width: "100%" }}>
              <div style={{ width: "100%", marginTop: 20 }}>
                <p>
                  Note: The dealership at the first position will be initialy
                  the primary dealership, but could be changed later.
                </p>
                <label>Dealership</label>
                <div style={{ width: "100%" }}>
                  <AsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions
                    defaultValue={inviteUserDealerships}
                    loadOptions={promiseDealerships}
                    className={"multi-select"}
                    onChange={(value: any) =>
                      setInviteUserDealerships(value || [])
                    }
                  />
                </div>
              </div>
              <div style={{ width: "100%", marginTop: 20 }}>
                <label>First Name</label>
                <div style={{ width: "100%" }}>
                  <InputText
                    style={{ width: "100%" }}
                    value={inviteUserFirstName}
                    onChange={(e: any) =>
                      setInviteUserFirstName(e.target.value)
                    }
                  />
                </div>
              </div>
              <div style={{ width: "100%", marginTop: 20 }}>
                <label>Last Name</label>
                <div style={{ width: "100%" }}>
                  <InputText
                    style={{ width: "100%" }}
                    value={inviteUserLastName}
                    onChange={(e: any) => setInviteUserLastName(e.target.value)}
                  />
                </div>
              </div>
              <div style={{ width: "100%", marginTop: 20 }}>
                <label>Email</label>
                <div style={{ width: "100%" }}>
                  <InputText
                    style={{ width: "100%" }}
                    value={inviteUserEmail}
                    onChange={(e: any) => setInviteUserEmail(e.target.value)}
                  />
                  {emailWarning ? (
                    <label className={"warning-text"}>{emailWarning}</label>
                  ) : null}
                </div>
              </div>
              <div style={{ width: "100%", marginTop: 20 }}>
                <label>Role</label>
                <div style={{ width: "100%" }}>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={inviteUserRole}
                    loadOptions={(inputValue): any => promiseRoles(inputValue)}
                    onChange={(value: any) => setInviteUserRole(value)}
                  />
                  {roleWarning ? (
                    <label className={"warning-text"}>{roleWarning}</label>
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: 20,
                  display: "inline-block",
                }}
              >
                <div style={{ float: "right" }}>
                  <div style={{ display: "flex" }}>
                    <Button
                      style={{ marginRight: 5 }}
                      className={"p-button-secondary no-icon-buttons"}
                      label={"Cancel"}
                      onClick={() => setShowInviteUserSidebar(false)}
                    />
                    <Button
                      className="no-icon-buttons"
                      onClick={onInvite}
                      label={"Invite"}
                    />
                  </div>
                </div>
              </div>
              {getResponseMessage()}
            </div>
          </div>
        ) : null}
      </Sidebar>
      <Sidebar
        style={{ width: 350 }}
        position={"right"}
        visible={showBulkImportSidebar}
        onHide={onHide}
        showCloseIcon={false}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <CloseButton onHide={onHide} />
          <div
            style={{
              marginTop: 25,
              marginLeft: -15,
              marginRight: -15,
              borderBottom: "1px solid #afa6a6",
            }}
          >
            <label style={{ fontSize: 20, marginLeft: 20 }}>Bulk Import</label>
          </div>
          <div style={{ padding: 5, height: "100%", width: "100%" }}>
            <p>
              To import a list of people, create a spreadsheet(.csv) with the
              following columns:
            </p>
            <p>First Name, Last Name, Email, Role</p>
            <p>Please note that Email and Role are mandatory.</p>

            <img
              style={{ width: "100%" }}
              alt={"..."}
              src={"assets/images/users_template.png"}
            />

            <div style={{ marginTop: 5, marginBottom: 10 }}>
              <label
                style={{ cursor: "pointer", color: "#39a3f4" }}
                onClick={generateTemplate}
              >
                <i style={{ marginRight: 5 }} className={"pi pi-download"} />
                <span> Download Template</span>
              </label>
            </div>

            <section className="container">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                {files.length === 0 ? (
                  <p>Drag 'n' drop '.csv' file here.</p>
                ) : (
                  <aside>
                    <ul style={{ listStyleType: "none" }}>{files}</ul>
                  </aside>
                )}
                <Button
                  onClick={open}
                  label={"Upload File"}
                  icon={"pi pi-plus"}
                />
              </div>
            </section>

            <div style={{ marginTop: 10 }}>
              <Button
                className="no-icon-buttons"
                disabled={files.length === 0}
                onClick={onSendBulkImport}
                label={"Send"}
              />
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default Users;
