import React, {CSSProperties, useEffect, useState} from "react";
import "./spinner.css";
import {ProgressSpinner} from "primereact/progressspinner";

enum ACTIONS {
    SHOW = "SHOW",
    HIDE = "HIDE"
}

interface IProps {
    visible: boolean
    width?: number;
    height?: number;
    style?: CSSProperties
}

const Spinner = (props: IProps) => {

    const [action, setAction] = useState<ACTIONS>(ACTIONS.HIDE);

    useEffect(() => {
        if (props.visible) {
            setAction(ACTIONS.SHOW);
        } else {
            setAction(ACTIONS.HIDE);
        }
    }, [props.visible])

    const getStyle = (action: ACTIONS): any => {
        if (action === ACTIONS.HIDE) {
            return {
                transition: `${500}ms`,
                top: -70
            }
        } else {
            return {
                transition: `${100}ms`,
                top: "calc(50% - 60px)"
            }
        }
    };

    if (props.visible) {
        return (
            <div>
                <div id={"spinner"} style={{...getStyle(action), ...props.style}}>
                    <ProgressSpinner
                        style={{width: props.width ? props.width : 40, height: props.height ? props.height : 40}}
                        strokeWidth={"4"}/>
                </div>
            </div>
        )
    }
    return null;
}

export default Spinner
