import React from "react";
import { VehicleResponse } from "./index";

interface IProps {
    vehicleData: VehicleResponse | undefined;
}

const VehicleData = (props: IProps) => {
    const vehicleOrderFields = ['year', 'make', 'model', 'series', 'plate_number', 'vin', 'vehicle_type'];

    const { vehicleData } = props;

    return (
        <div>
            {
                vehicleData && (
                    <>
                        <h4>Registered PII to this lead:</h4>
                        {['address', 'city', 'state', 'zipcode', 'first_name', 'last_name', 'full_name', 'business_name'].map((field) => {
                            let value = vehicleData[field];
                            if (value) {
                                return (
                                    <p key={field}>
                                        <strong>{field.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}:</strong> {value}
                                    </p>
                                );
                            }
                            return null;
                        })}
                        <hr />
                        
                        <h4>Registered vehicles to this lead:</h4>
                        {
                            // Render specific fields first
                            vehicleOrderFields.map((field) => {
                                let value = vehicleData[field];
                                let displayKey = field === "series" ? "Trim" : field;

                                if (value !== undefined) {
                                    return (
                                        <p key={field}>
                                            <strong>{displayKey.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}:</strong> {value}
                                        </p>
                                    );
                                }
                                return null;
                            })
                        }
                        
                        {
                            // Render other fields in the object
                            Object.entries(vehicleData).map(([key, value]) => {
                                if (!vehicleOrderFields.includes(key) && !['address', 'city', 'state', 'zipcode', 'first_name', 'last_name', 'full_name', 'business_name'].includes(key)) {
                                    return (
                                        <p key={key}>
                                            <strong>{key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}:</strong> {value ?? 'N/A'}
                                        </p>
                                    );
                                }
                                return null;
                            })
                        }
                    </>
                )
            }
        </div>
    );
};

export default VehicleData;
