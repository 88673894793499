import React, { useEffect, useState } from "react";
import { Panel } from "primereact/panel";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import Util from "../../../../util/Util";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import {
  IStore,
  ITrafficAlert,
  ITrafficAlertRequestDto,
} from "../../../../../index.dts";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { useForceUpdate } from "../../../../hooks/useForceUpdate";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { Checkbox } from "primereact/checkbox";
import {
  deleteTrafficAlerts,
  getTrafficAlerts,
} from "../../../../actions/trafficAlertActions";
import { useHistory } from "react-router-dom";
import { MODE } from "../../../../util/Enums";
import { IDS } from "../../../../views/constants";
import { useTranslation } from "react-i18next";

interface IProps {
  dealershipId: number;
}

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

const EventAlerts = (props: IProps) => {
  const history = useHistory();
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(0);

  const [sortField, setSortField] = useState<string>("created");
  const [sortOrder, setSortOrder] = useState<DataTableSortOrderType>(-1);
  const [loading, setLoading] = useState<boolean>(false);
  const [first, setFirst] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [sortMap] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(new Map([["created", { field: "created", order: -1 }]]));

  const [selectedRows, setSelectedRows] = useState<ITrafficAlert[]>([]);

  const [alerts, setAlerts] = useState<ITrafficAlert[]>([]);

  const user = useSelector((store: IStore) => store.auth.user, shallowEqual);

  const [nameFilterValue, setNameFilterValue] = useState<string>("");
  const [ownerFilterValue, setOwnerFilterValue] = useState<string>("");
  const [startDateFilterValue, setStartDateFilterValue] = useState<string>("");
  const [endDateFilterValue, setEndDateFilterValue] = useState<string>("");

  const onClear = () => {
    sortMap.clear();
    setNameFilterValue("");
    setOwnerFilterValue("");
    setStartDateFilterValue("");
    setEndDateFilterValue("");
  };

  useEffect(() => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getTrafficAlertsList(props.dealershipId, 0, limit).finally(() => {
        setPage(0);
        setSelectedRows([]);
        searchTimeoutInterval = 300;
      });
    }, searchTimeoutInterval);
    // eslint-disable-next-line
  }, [
    props.dealershipId,
    nameFilterValue,
    ownerFilterValue,
    startDateFilterValue,
    endDateFilterValue,
  ]);

  const onPage = (event: any) => {
    setLimit(event.rows);
    setFirst(event.first);

    getTrafficAlertsList(props.dealershipId, event.page, event.rows).finally(
      () => {
        setPage(event.page);
      }
    );
  };

  const onSort = (event: any) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);

    sortMap.clear();
    if (event && Array.isArray(event.multiSortMeta)) {
      event.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getTrafficAlertsList(props.dealershipId, page, limit);
    }
  };

  const onAdd = (): void => {
    history.push(Util.PATH_NAMES.MY_TRAFFIC_CREATE_EVENT_ALERT, {
      mode: MODE.NEW,
      dealershipId: props.dealershipId,
    });
  };

  const onEdit = (): void => {
    if (selectedRows.length === 0) {
      return;
    }

    const alert = selectedRows[0];
    const alertId = Util._.get(alert, "id", null);

    history.push(Util.PATH_NAMES.MY_TRAFFIC_EDIT_EVENT_ALERT, {
      id: alertId,
      mode: MODE.EDIT,
      dealershipId: props.dealershipId,
    });
  };

  const onDelete = (): void => {
    const ids = selectedRows
      .filter((alert) => alert.owner.id === user.id)
      .map((alert) => alert.id);

    if (ids.length === 0) {
      return;
    }

    confirmAlert({
      title: `You are about to delete ${ids.length} event alert${
        ids.length > 1 ? "s" : ""
      }!`,
      message: "Are you sure you want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setLoading(true);
            deleteTrafficAlerts(ids)
              .then(() => {
                Util.success("Traffic alerts deleted successfully");
              })
              .catch((error) => Util.showError(error))
              .finally(() => {
                getTrafficAlertsList(props.dealershipId, 0, limit)
                  .catch((error) => Util.showError(error))
                  .finally(() => {
                    setPage(0);
                    setSelectedRows([]);
                  });
              });
          },
          className: "confirm-save-btn",
        },
        {
          label: "Cancel",
          className: "confirm-cancel-btn",
          onClick: () => {},
        },
      ],
    });
  };

  const getTrafficAlertsList = (
    dealershipId: number,
    pageNumber: number,
    pageLimit: number
  ) =>
    new Promise((resolve, reject) => {
      setLoading(true);

      const dto: ITrafficAlertRequestDto = {
        dealershipId: dealershipId,
        pageNumber: pageNumber,
        pageLimit: pageLimit,
        name: nameFilterValue,
        owner: ownerFilterValue,
        startDate: startDateFilterValue,
        endDate: endDateFilterValue,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
      };

      getTrafficAlerts(dto)
        .then((response) => {
          resolve(response);

          setAlerts(response.data.content);
          setTotalRecords(response.data.totalElements);
        })
        .catch((error) => {
          reject(error);
          Util.showError(error);
        })
        .finally(() => setLoading(false));
    });

  const nameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={nameFilterValue}
      onChange={(e: any) => {
        setNameFilterValue(
          Util.getDefaultIfNull(Util._.get(e, "target.value", null), "")
        );
      }}
    />
  );

  const ownerFilter = (
    <InputText
      style={{ width: "100%" }}
      value={ownerFilterValue}
      onChange={(e: any) => {
        setOwnerFilterValue(
          Util.getDefaultIfNull(Util._.get(e, "target.value", null), "")
        );
      }}
    />
  );

  const startDateFilter = (
    <Calendar
      style={{ width: "100%" }}
      inputStyle={{ width: "100%" }}
      value={
        !Util.isEmpty(startDateFilterValue)
          ? moment(startDateFilterValue).toDate()
          : ""
      }
      onChange={(e): any => {
        const value = Util._.get(e, "value", null);
        if (value) {
          setStartDateFilterValue(moment(value).format(Util.localDateFormat));
          return;
        }

        setStartDateFilterValue("");
      }}
    />
  );

  const endDateFilter = (
    <Calendar
      style={{ width: "100%" }}
      inputStyle={{ width: "100%" }}
      value={
        !Util.isEmpty(endDateFilterValue)
          ? moment(endDateFilterValue).toDate()
          : ""
      }
      onChange={(e): any => {
        const value = Util._.get(e, "value", null);
        if (value) {
          setEndDateFilterValue(moment(value).format(Util.localDateFormat));
          return;
        }

        setEndDateFilterValue("");
      }}
    />
  );

  const startDateTemplate = (data: any) => {
    return <div>{moment(data.startDate).format(Util.usDateFormat)}</div>;
  };

  const endDateDateTemplate = (data: any) => {
    if (!data.endDate) {
      return null;
    }
    return <div>{moment(data.endDate).format(Util.usDateFormat)}</div>;
  };

  const nameBody = (data: any) => {
    return <div style={{ wordBreak: "break-word" }}>{data.name}</div>;
  };

  const checkboxTemplate = (data: ITrafficAlert) => {
    const index = selectedRows.findIndex((m) => m.id === data.id);
    return (
      <Checkbox
        checked={index !== -1}
        onChange={(e) => onSelectRow(e.checked, data)}
      />
    );
  };

  const onSelectRow = (checked: boolean, alert: ITrafficAlert): void => {
    const index = selectedRows.findIndex((m) => m.id === alert.id);

    if (index !== -1) {
      selectedRows.splice(index, 1);
    } else {
      selectedRows.push(alert);
    }

    forceUpdate();
  };

  const checkAlertsForDelete = (): boolean => {
    if (selectedRows.length === 0) {
      return false;
    }

    const ownerAlerts = selectedRows.filter(
      (alert) => alert.owner.id === user.id
    );

    return ownerAlerts.length > 0;
  };

  return (
    <Panel
      id={"event_alerts"}
      style={{ padding: 2 }}
      header={
        <div style={{ display: "flex" }}>
          <span>Alerts</span>
          <div className={"p-grid-header-components"}>
            <Button
              id={IDS.button.clear}
              icon={"pi pi-filter"}
              label={t("clear")}
              onClick={onClear}
            />
            <Button
              style={{ marginRight: 5 }}
              onClick={onAdd}
              icon="pi pi-plus"
              label={"Add"}
            />
            <Button
              disabled={selectedRows.length !== 1}
              style={{ marginRight: 5 }}
              onClick={onEdit}
              icon="pi pi-pencil"
              label={"Edit"}
            />
            <Button
              disabled={!checkAlertsForDelete()}
              style={{ marginRight: 5 }}
              onClick={onDelete}
              icon="pi pi-trash"
              className={"p-button-danger"}
              label={"Delete"}
            />
          </div>
        </div>
      }
    >
      <div
        className={
          limit > 10
            ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting"
            : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
        }
      >
        <DataTable
          rowsPerPageOptions={Util.rowsPerPageOptions}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
          value={alerts}
          lazy={true}
          scrollable
          paginator={true}
          filterDisplay="row"
          editMode={"row"}
          totalRecords={totalRecords}
          rows={limit}
          first={first}
          loading={loading}
          onPage={onPage}
          onSort={onSort}
          sortOrder={sortOrder}
          sortField={sortField}
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          sortMode={"multiple"}
          multiSortMeta={Array.from(sortMap.values())}
        >
          <Column
            sortable={false}
            body={checkboxTemplate}
            style={{ width: "3em" }}
          />
          <Column
            filter={true}
            sortable={true}
            field={"name"}
            header={"Name"}
            body={nameBody}
            filterElement={nameFilter}
          />
          <Column
            filter={true}
            sortable={true}
            field={"startDate"}
            header={"Start Date"}
            body={startDateTemplate}
            filterElement={startDateFilter}
          />
          <Column
            filter={true}
            sortable={true}
            field={"endDate"}
            header={"End Date"}
            body={endDateDateTemplate}
            filterElement={endDateFilter}
          />
          <Column
            filter={true}
            sortable={true}
            field={"owner.email"}
            header={"Owner"}
            filterElement={ownerFilter}
          />
        </DataTable>
      </div>
    </Panel>
  );
};

export default EventAlerts;
