import React, { useEffect, useState } from "react";
import "./reports.css";
import { Panel } from "primereact/panel";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import Util from "../../util/Util";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import {
  exportLeads,
  getAutoAcquireReport,
  getPerformanceReport,
} from "../../actions/reportActions";
import AsyncSelect from "react-select/async";

export enum ServiceName {
  IOFFER = "IOFFER",
  T2 = "T2",
  CLICKINS = "CLICKINS",
  DMS_FORTELLIS_APPOINTMENTS = "DMS_FORTELLIS_APPOINTMENTS",
  DMS_FORTELLIS_FI_SALES = "DMS_FORTELLIS_FI_SALES",
  DMS_FORTELLIS_CUSTOMERS = "DMS_FORTELLIS_CUSTOMERS",
}

export const PERFORMANCE_REPORT_TAB_KEY = "my_traffic_active_tab";

export function Reports() {
  const [startDate, setStartDate] = useState(
    moment().subtract(30, "day").format(Util.localDateFormat)
  );
  const [endDate, setEndDate] = useState(moment().format(Util.localDateFormat));
  const [startDateT2, setStartDateT2] = useState(
    moment().subtract(30, "day").format(Util.localDateFormat)
  );
  const [endDateT2, setEndDateT2] = useState(
    moment().format(Util.localDateFormat)
  );
  const [startDateIOffer, setStartDateIOffer] = useState(
    moment().subtract(30, "day").format(Util.localDateFormat)
  );
  const [endDateIOffer, setEndDateIOffer] = useState(
    moment().format(Util.localDateFormat)
  );
  const [startDateClickIns, setStartDateClickIns] = useState(
    moment().subtract(30, "day").format(Util.localDateFormat)
  );
  const [endDateClickIns, setEndDateClickIns] = useState(
    moment().format(Util.localDateFormat)
  );
  const [startDateAutoAcquire, setStartDateAutoAcquire] = useState(
    moment().subtract(30, "day").format(Util.localDateFormat)
  );
  const [endDateAutoAcquire, setEndDateAutoAcquire] = useState(
    moment().format(Util.localDateFormat)
  );
  const [startDateServiceRecords, setStartDateServiceRecords] = useState(
    moment().subtract(30, "day").format(Util.localDateFormat)
  );
  const [endDateServiceRecords, setEndDateServiceRecords] = useState(
    moment().format(Util.localDateFormat)
  );
  const [startDateSalesRecords, setStartDateSalesRecords] = useState(
    moment().subtract(30, "day").format(Util.localDateFormat)
  );
  const [endDateSalesRecords, setEndDateSalesRecords] = useState(
    moment().format(Util.localDateFormat)
  );
  const [startDateCustomerRecords, setStartDateCustomerRecords] = useState(
    moment().subtract(30, "day").format(Util.localDateFormat)
  );
  const [endDateCustomerRecords, setEndDateCustomerRecords] = useState(
    moment().format(Util.localDateFormat)
  );
  const [type, setType] = useState("all");
  const [dealershipsT2, setDealershipsT2] = useState<
    { label: string; value: number; externalRefId?: string }[]
  >([]);
  const [dealershipsIOffer, setDealershipsIOffer] = useState<
    { label: string; value: number; externalRefId?: string }[]
  >([]);
  const [dealershipsClickIns, setDealershipsClickIns] = useState<
    { label: string; value: number; externalRefId?: string }[]
  >([]);
  const [dealershipsAutoAcquire, setDealershipsAutoAcquire] = useState<
    { label: string; value: number; externalRefId?: string }[]
  >([]);
  const [dealershipsServiceRecords, setDealershipsServiceRecords] = useState<
    { label: string; value: number; externalRefId?: string }[]
  >([]);
  const [dealershipsSalesRecords, setDealershipsSalesRecords] = useState<
    { label: string; value: number; externalRefId?: string }[]
  >([]);
  const [dealershipsCustomerRecords, setDealershipsCustomerRecords] = useState<
    { label: string; value: number; externalRefId?: string }[]
  >([]);

  const [types] = useState([
    { name: "All", value: "all" },
    { name: "Sales", value: "sales" },
    { name: "Service", value: "service" },
  ]);

  const onExport = (): void => {
    Util.globalSpinner().show();
    exportLeads(startDate, endDate, type)
      .then((response) => {
        Util.success("The requested report will be sent to your email soon!");
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => Util.globalSpinner().hide());
  };

  const onExportReport = (
    serviceName: ServiceName,
    startDate: any,
    endDate: any,
    dealerships: any[]
  ): void => {
    Util.globalSpinner().show();
    let dealershipIDs = dealerships.map((d) => d.externalRefId);

    getPerformanceReport({
      serviceName: serviceName,
      startDate: startDate,
      endDate: endDate,
      dealershipExtRefIds: dealershipIDs,
    })
      .then((response) => {
        Util.success("The requested report will be sent to your email soon!");
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => Util.globalSpinner().hide());
  };

  const onExportAutoAcquireReport = (
    startDate: any,
    endDate: any,
    dealerships: any[]
  ): void => {
    Util.globalSpinner().show();
    let dealershipIDs = dealerships.map((d) => d.externalRefId);

    getAutoAcquireReport({
      startDate: startDate,
      endDate: endDate,
      dealershipExtRefIds: dealershipIDs,
    })
      .then((response) => {
        Util.success("The requested report will be sent to your email soon!");
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => Util.globalSpinner().hide());
  };

  return (
    <div id={"reports-view"}>
      <Panel
        style={{ marginBottom: "15px" }}
        header={
          <div style={{ display: "flex" }}>
            <label>Dealer lead performance</label>
            <div className={"p-grid-header-components"}>
              <Button
                icon={"pi pi-send"}
                onClick={onExport}
                label={"Get Report"}
              />
            </div>
          </div>
        }
      >
        <div style={{ marginTop: 5 }} className={"p-grid"}>
          <div style={{ float: "left", marginLeft: 5 }}>
            <label style={{ marginRight: 2 }}>Start Date</label>
            <Calendar
              value={moment(startDate).toDate()}
              yearNavigator={true}
              monthNavigator={true}
              maxDate={moment().toDate()}
              yearRange={`2010:${moment().format("YYYY")}`}
              onChange={(e) =>
                setStartDate(
                  moment(Util._.get(e, "value")).format(Util.localDateFormat)
                )
              }
            />
          </div>
          <div style={{ float: "left", marginLeft: 10 }}>
            <label style={{ marginRight: 2 }}>End Date</label>
            <Calendar
              value={moment(endDate).toDate()}
              yearNavigator={true}
              monthNavigator={true}
              maxDate={moment().toDate()}
              yearRange={`2010:${moment().format("YYYY")}`}
              onChange={(e) =>
                setEndDate(
                  moment(Util._.get(e, "value")).format(Util.localDateFormat)
                )
              }
            />
          </div>
          <div
            style={{
              float: "left",
              marginLeft: 10,
              width: "50%",
              display: "flex",
            }}
          >
            <label style={{ marginRight: 2, marginTop: 5 }}>Type</label>
            <Dropdown
              value={type}
              options={types}
              onChange={(e: any) => setType(e.target.value)}
              optionLabel={"name"}
              optionValue={"value"}
              placeholder={"Select a type"}
              style={{ width: "70%" }}
            />
          </div>
        </div>
      </Panel>
      <Panel
        style={{ marginBottom: "15px" }}
        header={
          <div style={{ display: "flex" }}>
            <label>T2</label>
            <div className={"p-grid-header-components"}>
              <Button
                icon={"pi pi-send"}
                onClick={() =>
                  onExportReport(
                    ServiceName.T2,
                    startDateT2,
                    endDateT2,
                    dealershipsT2
                  )
                }
                label={"Get Report"}
              />
            </div>
          </div>
        }
      >
        <div style={{ marginTop: 5 }} className={"p-grid"}>
          <div style={{ float: "left", marginLeft: 5 }}>
            <label style={{ marginRight: 2 }}>Start Date</label>
            <Calendar
              value={moment(startDateT2).toDate()}
              yearNavigator={true}
              monthNavigator={true}
              maxDate={moment().toDate()}
              yearRange={`2010:${moment().format("YYYY")}`}
              onChange={(e) =>
                setStartDateT2(
                  moment(Util._.get(e, "value")).format(Util.localDateFormat)
                )
              }
            />
          </div>
          <div style={{ float: "left", marginLeft: 10 }}>
            <label style={{ marginRight: 2 }}>End Date</label>
            <Calendar
              value={moment(endDateT2).toDate()}
              yearNavigator={true}
              monthNavigator={true}
              maxDate={moment().toDate()}
              yearRange={`2010:${moment().format("YYYY")}`}
              onChange={(e) =>
                setEndDateT2(
                  moment(Util._.get(e, "value")).format(Util.localDateFormat)
                )
              }
            />
          </div>
          <div
            style={{
              float: "left",
              marginLeft: 10,
              width: "50%",
              display: "flex",
            }}
          >
            <label style={{ marginRight: 2, marginTop: 5 }}>Dealership</label>
            <AsyncSelect
              isMulti={true}
              defaultOptions={true}
              isClearable={true}
              cacheOptions
              closeMenuOnSelect={false}
              menuPosition={"fixed"}
              className={"multi-select reports-select"}
              value={dealershipsT2}
              placeholder={"Select Dealerships"}
              loadOptions={Util.promiseMyDealershipsForReports}
              onChange={(e: any) => {
                const allOption = e.find(
                  (option: any) => option.externalRefId === "ALL"
                );
                if (allOption) {
                  setDealershipsT2([allOption]);
                } else {
                  setDealershipsT2(e);
                }
              }}
            />
          </div>
        </div>
      </Panel>
      <Panel
        style={{ marginBottom: "15px" }}
        header={
          <div style={{ display: "flex" }}>
            <label>iOffer</label>
            <div className={"p-grid-header-components"}>
              <Button
                icon={"pi pi-send"}
                onClick={() =>
                  onExportReport(
                    ServiceName.IOFFER,
                    startDateIOffer,
                    endDateIOffer,
                    dealershipsIOffer
                  )
                }
                label={"Get Report"}
              />
            </div>
          </div>
        }
      >
        <div style={{ marginTop: 5 }} className={"p-grid"}>
          <div style={{ float: "left", marginLeft: 5 }}>
            <label style={{ marginRight: 2 }}>Start Date</label>
            <Calendar
              value={moment(startDateIOffer).toDate()}
              yearNavigator={true}
              monthNavigator={true}
              maxDate={moment().toDate()}
              yearRange={`2010:${moment().format("YYYY")}`}
              onChange={(e) =>
                setStartDateIOffer(
                  moment(Util._.get(e, "value")).format(Util.localDateFormat)
                )
              }
            />
          </div>
          <div style={{ float: "left", marginLeft: 10 }}>
            <label style={{ marginRight: 2 }}>End Date</label>
            <Calendar
              value={moment(endDateIOffer).toDate()}
              yearNavigator={true}
              monthNavigator={true}
              maxDate={moment().toDate()}
              yearRange={`2010:${moment().format("YYYY")}`}
              onChange={(e) =>
                setEndDateIOffer(
                  moment(Util._.get(e, "value")).format(Util.localDateFormat)
                )
              }
            />
          </div>
          <div
            style={{
              float: "left",
              marginLeft: 10,
              width: "50%",
              display: "flex",
            }}
          >
            <label style={{ marginRight: 2, marginTop: 5 }}>Dealership</label>
            <AsyncSelect
              isMulti={true}
              defaultOptions={true}
              isClearable={true}
              cacheOptions
              closeMenuOnSelect={false}
              menuPosition={"fixed"}
              className={"multi-select reports-select"}
              value={dealershipsIOffer}
              placeholder={"Select Dealerships"}
              loadOptions={Util.promiseMyDealershipsForReports}
              onChange={(e: any) => {
                const allOption = e.find(
                  (option: any) => option.externalRefId === "ALL"
                );
                if (allOption) {
                  setDealershipsIOffer([allOption]);
                } else {
                  setDealershipsIOffer(e);
                }
              }}
            />
          </div>
        </div>
      </Panel>
      <Panel
        style={{ marginBottom: "15px" }}
        header={
          <div style={{ display: "flex" }}>
            <label>Click-Ins</label>
            <div className={"p-grid-header-components"}>
              <Button
                icon={"pi pi-send"}
                onClick={() =>
                  onExportReport(
                    ServiceName.CLICKINS,
                    startDateClickIns,
                    endDateClickIns,
                    dealershipsClickIns
                  )
                }
                label={"Get Report"}
              />
            </div>
          </div>
        }
      >
        <div style={{ marginTop: 5 }} className={"p-grid"}>
          <div style={{ float: "left", marginLeft: 5 }}>
            <label style={{ marginRight: 2 }}>Start Date</label>
            <Calendar
              value={moment(startDateClickIns).toDate()}
              yearNavigator={true}
              monthNavigator={true}
              maxDate={moment().toDate()}
              yearRange={`2010:${moment().format("YYYY")}`}
              onChange={(e) =>
                setStartDateClickIns(
                  moment(Util._.get(e, "value")).format(Util.localDateFormat)
                )
              }
            />
          </div>
          <div style={{ float: "left", marginLeft: 10 }}>
            <label style={{ marginRight: 2 }}>End Date</label>
            <Calendar
              value={moment(endDateClickIns).toDate()}
              yearNavigator={true}
              monthNavigator={true}
              maxDate={moment().toDate()}
              yearRange={`2010:${moment().format("YYYY")}`}
              onChange={(e) =>
                setEndDateClickIns(
                  moment(Util._.get(e, "value")).format(Util.localDateFormat)
                )
              }
            />
          </div>
          <div
            style={{
              float: "left",
              marginLeft: 10,
              width: "50%",
              display: "flex",
            }}
          >
            <label style={{ marginRight: 2, marginTop: 5 }}>Dealership</label>
            <AsyncSelect
              isMulti={true}
              defaultOptions={true}
              isClearable={true}
              cacheOptions
              closeMenuOnSelect={false}
              menuPosition={"fixed"}
              className={"multi-select reports-select"}
              value={dealershipsClickIns}
              placeholder={"Select Dealerships"}
              loadOptions={Util.promiseMyDealershipsForReports}
              onChange={(e: any) => {
                const allOption = e.find(
                  (option: any) => option.externalRefId === "ALL"
                );
                if (allOption) {
                  setDealershipsClickIns([allOption]);
                } else {
                  setDealershipsClickIns(e);
                }
              }}
            />
          </div>
        </div>
      </Panel>
      <Panel
        style={{ marginBottom: "15px" }}
        header={
          <div style={{ display: "flex" }}>
            <label>My Acquisitions</label>
            <div className={"p-grid-header-components"}>
              <Button
                icon={"pi pi-send"}
                onClick={() =>
                  onExportAutoAcquireReport(
                    startDateAutoAcquire,
                    endDateAutoAcquire,
                    dealershipsAutoAcquire
                  )
                }
                label={"Get Report"}
              />
            </div>
          </div>
        }
      >
        <div style={{ marginTop: 5 }} className={"p-grid"}>
          <div style={{ float: "left", marginLeft: 5 }}>
            <label style={{ marginRight: 2 }}>Start Date</label>
            <Calendar
              value={moment(startDateAutoAcquire).toDate()}
              yearNavigator={true}
              monthNavigator={true}
              maxDate={moment().toDate()}
              yearRange={`2010:${moment().format("YYYY")}`}
              onChange={(e) =>
                setStartDateAutoAcquire(
                  moment(Util._.get(e, "value")).format(Util.localDateFormat)
                )
              }
            />
          </div>
          <div style={{ float: "left", marginLeft: 5 }}>
            <label style={{ marginRight: 2 }}>End Date</label>
            <Calendar
              value={moment(endDateAutoAcquire).toDate()}
              yearNavigator={true}
              monthNavigator={true}
              maxDate={moment().toDate()}
              yearRange={`2010:${moment().format("YYYY")}`}
              onChange={(e) =>
                setEndDateAutoAcquire(
                  moment(Util._.get(e, "value")).format(Util.localDateFormat)
                )
              }
            />
          </div>
          <div
            style={{
              float: "left",
              marginLeft: 5,
              width: "50%",
              display: "flex",
            }}
          >
            <label style={{ marginRight: 2, marginTop: 5 }}>Dealership</label>
            <AsyncSelect
              isMulti={true}
              defaultOptions={true}
              isClearable={true}
              cacheOptions
              closeMenuOnSelect={false}
              menuPosition={"fixed"}
              className={"multi-select reports-select"}
              value={dealershipsAutoAcquire}
              placeholder={"Select Dealerships"}
              loadOptions={Util.promiseMyDealershipsForReports}
              onChange={(e: any) => {
                const allOption = e.find(
                  (option: any) => option.externalRefId === "ALL"
                );
                if (allOption) {
                  setDealershipsAutoAcquire([allOption]);
                } else {
                  setDealershipsAutoAcquire(e);
                }
              }}
            />
          </div>
        </div>
      </Panel>
      <Panel
        style={{ marginBottom: "15px" }}
        header={
          <div style={{ display: "flex" }}>
            <label>Fortellis</label>
          </div>
        }
      >
        <div style={{ marginTop: 5, marginBottom: 20 }} className={"p-grid"}>
          <div style={{ float: "left", marginLeft: 5, minWidth: 170 }}>
            <label style={{ marginRight: "30px", fontSize: "1.1rem" }}>
              Service Records:{" "}
            </label>
          </div>
          <div style={{ float: "left", marginLeft: 5 }}>
            <label style={{ marginRight: 2 }}>Start Date</label>
            <Calendar
              value={moment(startDateServiceRecords).toDate()}
              yearNavigator={true}
              monthNavigator={true}
              maxDate={moment().toDate()}
              yearRange={`2010:${moment().format("YYYY")}`}
              onChange={(e) =>
                setStartDateServiceRecords(
                  moment(Util._.get(e, "value")).format(Util.localDateFormat)
                )
              }
            />
          </div>
          <div style={{ float: "left", marginLeft: 10 }}>
            <label style={{ marginRight: 2 }}>End Date</label>
            <Calendar
              value={moment(endDateServiceRecords).toDate()}
              yearNavigator={true}
              monthNavigator={true}
              maxDate={moment().toDate()}
              yearRange={`2010:${moment().format("YYYY")}`}
              onChange={(e) =>
                setEndDateServiceRecords(
                  moment(Util._.get(e, "value")).format(Util.localDateFormat)
                )
              }
            />
          </div>
          <div
            style={{
              float: "left",
              marginLeft: 10,
              width: "30%",
              display: "flex",
            }}
          >
            <label style={{ marginRight: 2, marginTop: 5 }}>Dealership</label>
            <AsyncSelect
              isMulti={true}
              defaultOptions={true}
              isClearable={true}
              cacheOptions
              closeMenuOnSelect={false}
              menuPosition={"fixed"}
              className={"multi-select reports-select"}
              value={dealershipsServiceRecords}
              placeholder={"Select Dealerships"}
              loadOptions={Util.promiseMyDealershipsForReports}
              onChange={(e: any) => {
                const allOption = e.find(
                  (option: any) => option.externalRefId === "ALL"
                );
                if (allOption) {
                  setDealershipsServiceRecords([allOption]);
                } else {
                  setDealershipsServiceRecords(e);
                }
              }}
            />
          </div>
          <Button
            icon={"pi pi-send"}
            onClick={() =>
              onExportReport(
                ServiceName.DMS_FORTELLIS_APPOINTMENTS,
                startDateServiceRecords,
                endDateServiceRecords,
                dealershipsServiceRecords
              )
            }
            label={"Get Report"}
          />
        </div>
        <div style={{ marginTop: 5, marginBottom: 20 }} className={"p-grid"}>
          <div style={{ float: "left", marginLeft: 5, minWidth: 170 }}>
            <label style={{ marginRight: "30px", fontSize: "1.1rem" }}>
              Sales Records:{" "}
            </label>
          </div>
          <div style={{ float: "left", marginLeft: 5 }}>
            <label style={{ marginRight: 2 }}>Start Date</label>
            <Calendar
              value={moment(startDateSalesRecords).toDate()}
              yearNavigator={true}
              monthNavigator={true}
              maxDate={moment().toDate()}
              yearRange={`2010:${moment().format("YYYY")}`}
              onChange={(e) =>
                setStartDateSalesRecords(
                  moment(Util._.get(e, "value")).format(Util.localDateFormat)
                )
              }
            />
          </div>
          <div style={{ float: "left", marginLeft: 10 }}>
            <label style={{ marginRight: 2 }}>End Date</label>
            <Calendar
              value={moment(endDateSalesRecords).toDate()}
              yearNavigator={true}
              monthNavigator={true}
              maxDate={moment().toDate()}
              yearRange={`2010:${moment().format("YYYY")}`}
              onChange={(e) =>
                setEndDateSalesRecords(
                  moment(Util._.get(e, "value")).format(Util.localDateFormat)
                )
              }
            />
          </div>
          <div
            style={{
              float: "left",
              marginLeft: 10,
              width: "30%",
              display: "flex",
            }}
          >
            <label style={{ marginRight: 2, marginTop: 5 }}>Dealership</label>
            <AsyncSelect
              isMulti={true}
              defaultOptions={true}
              isClearable={true}
              cacheOptions
              closeMenuOnSelect={false}
              menuPosition={"fixed"}
              className={"multi-select reports-select"}
              value={dealershipsSalesRecords}
              placeholder={"Select Dealerships"}
              loadOptions={Util.promiseMyDealershipsForReports}
              onChange={(e: any) => {
                const allOption = e.find(
                  (option: any) => option.externalRefId === "ALL"
                );
                if (allOption) {
                  setDealershipsSalesRecords([allOption]);
                } else {
                  setDealershipsSalesRecords(e);
                }
              }}
            />
          </div>
          <Button
            icon={"pi pi-send"}
            onClick={() =>
              onExportReport(
                ServiceName.DMS_FORTELLIS_FI_SALES,
                startDateSalesRecords,
                endDateSalesRecords,
                dealershipsSalesRecords
              )
            }
            label={"Get Report"}
          />
        </div>
        <div style={{ marginTop: 5, marginBottom: 20 }} className={"p-grid"}>
          <div style={{ float: "left", marginLeft: 5, minWidth: 170 }}>
            <label style={{ marginRight: "30px", fontSize: "1.1rem" }}>
              Customer Records:{" "}
            </label>
          </div>
          <div style={{ float: "left", marginLeft: 5 }}>
            <label style={{ marginRight: 2 }}>Start Date</label>
            <Calendar
              value={moment(startDateCustomerRecords).toDate()}
              yearNavigator={true}
              monthNavigator={true}
              maxDate={moment().toDate()}
              yearRange={`2010:${moment().format("YYYY")}`}
              onChange={(e) =>
                setStartDateCustomerRecords(
                  moment(Util._.get(e, "value")).format(Util.localDateFormat)
                )
              }
            />
          </div>
          <div style={{ float: "left", marginLeft: 10 }}>
            <label style={{ marginRight: 2 }}>End Date</label>
            <Calendar
              value={moment(endDateCustomerRecords).toDate()}
              yearNavigator={true}
              monthNavigator={true}
              maxDate={moment().toDate()}
              yearRange={`2010:${moment().format("YYYY")}`}
              onChange={(e) =>
                setEndDateCustomerRecords(
                  moment(Util._.get(e, "value")).format(Util.localDateFormat)
                )
              }
            />
          </div>
          <div
            style={{
              float: "left",
              marginLeft: 10,
              width: "30%",
              display: "flex",
            }}
          >
            <label style={{ marginRight: 2, marginTop: 5 }}>Dealership</label>
            <AsyncSelect
              isMulti={true}
              defaultOptions={true}
              isClearable={true}
              cacheOptions
              closeMenuOnSelect={false}
              menuPosition={"fixed"}
              className={"multi-select reports-select"}
              value={dealershipsCustomerRecords}
              placeholder={"Select Dealerships"}
              loadOptions={Util.promiseMyDealershipsForReports}
              onChange={(e: any) => {
                const allOption = e.find(
                  (option: any) => option.externalRefId === "ALL"
                );
                if (allOption) {
                  setDealershipsCustomerRecords([allOption]);
                } else {
                  setDealershipsCustomerRecords(e);
                }
              }}
            />
          </div>
          <Button
            icon={"pi pi-send"}
            onClick={() =>
              onExportReport(
                ServiceName.DMS_FORTELLIS_CUSTOMERS,
                startDateCustomerRecords,
                endDateCustomerRecords,
                dealershipsCustomerRecords
              )
            }
            label={"Get Report"}
          />
        </div>
      </Panel>
    </div>
  );
}
export default Reports;
