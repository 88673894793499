import actions from "../constants/actions";
import {IProgress} from "../../index.dts";

export const setGrowl = (payload: any) => ({
  type: actions.SET_GROWL,
  payload: payload
});

export const setGlobalSpinner = (payload: any) => ({
  type: actions.SET_GLOBAL_SPINNER_REF,
  payload: payload
});


export const setProgress = (payload: IProgress) => ({
  type: actions.SET_PROGRESS_BAR,
  payload: payload
});


export const removeProgress = (payload: string) => ({
  type: actions.REMOVE_PROGRESS_BAR,
  payload: payload
});