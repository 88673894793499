import React, { useRef, useState } from "react";
import "./file_uploaded_custom.css";
import {
  FileUpload,
  FileUploadHeaderTemplateOptions,
  ItemTemplateOptions,
} from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import Util from "../../util/Util";
import { COOKIES } from "../../util/Enums";

interface IProps {
  url: string;
  multiple: boolean;
  name: string;
  accept: string;
  maxFileSize: number;
  withCredentials: boolean;
}

export default function FileUploadCustom(props: IProps) {
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef<FileUpload>(null);

  const onTemplateSelect = (e: any) => {
    let validFiles: any = []; // Store only valid files
    let latestFileSize = 0; // Store the size of the latest valid file

    for (let i = 0; i < e.files.length; i++) {
      const file = e.files[i];
      if (file.size > props.maxFileSize) {
        // Display error for files exceeding max size
        Util.error(
          `File ${file.name} exceeds the maximum size of ${
            props.maxFileSize / 1000000
          } MB.`
        );
      } else {
        validFiles.push(file); // Add valid files to the list
        latestFileSize = file.size; // Update with the latest valid file's size
      }
    }

    e.files = validFiles; // Replace the files with only valid files
    setTotalSize(latestFileSize); // Display only the latest valid file's size
  };

  const onTemplateUpload = (e: any) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    Util.success("File uploaded successfully.");
  };

  const onTemplateRemove = (file: File, callback: Function) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const onError = (e: any) => {
    let response: any = JSON.parse(e.xhr.response);
    Util.error(response.message);
  };

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = (totalSize / props.maxFileSize) * 100;
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          padding: 15,
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex" style={{ marginLeft: 15 }}>
          <span>
            {formatedValue} / {props.maxFileSize / 1000000} MB
          </span>
          <ProgressBar
            value={value}
            showValue={false}
            style={{ width: "10rem", height: "12px" }}
          ></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
    const file = inFile as File;
    return (
      <div>
        <div className="p-grid" style={{ padding: 10 }}>
          <div className={"p-col-12 p-lg-3 p-label-col"}>
            <div>
              <b>File Name: </b> {file.name}
            </div>
          </div>
          <div className={"p-col-12 p-lg-3"}>
            <b>File Date: </b> {new Date().toLocaleDateString()}
          </div>
          <div className={"p-col-12 p-lg-3"}>
            <b>File Size: </b>{" "}
            <Tag
              value={props.formatSize}
              severity="warning"
              className="px-3 py-2"
            />
          </div>
          <div className={"p-col-12 p-lg-3"}>
            <Button
              type="button"
              icon="pi pi-times"
              className="p-button-outlined p-button-rounded p-button-danger ml-auto"
              onClick={() => onTemplateRemove(file, props.onRemove)}
            />
          </div>
        </div>
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div
        className="flex align-items-center flex-column"
        style={{ padding: 20 }}
      >
        <i
          className="pi pi-file mt-3 p-5"
          style={{
            fontSize: "1em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop File(s) Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-plus",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  return (
    <div>
      <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
      <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
      <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

      <FileUpload
        ref={fileUploadRef}
        name={props.name}
        url={props.url}
        multiple={props.multiple}
        accept={props.accept}
        maxFileSize={props.maxFileSize}
        withCredentials={props.withCredentials}
        onUpload={onTemplateUpload}
        onSelect={onTemplateSelect}
        onError={onError}
        onClear={onTemplateClear}
        headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
        onBeforeSend={(event) => {
          const auth: any = JSON.parse(Util.Cookie.get(COOKIES.AUTH) || "{}");
          const access_token = Util._.get(auth, "access_token", null);

          event.xhr.setRequestHeader("Authorization", `Bearer ${access_token}`);
        }}
      />
    </div>
  );
}
