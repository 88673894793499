import actions from "../constants/actions";
import {IMyTrafficReducer} from "../../index.dts";
import {v4 as uuid} from 'uuid';
import Util from "../util/Util";
import {MY_TRAFFIC_TAB_KEY} from "../views/my_traffic/MyTraffic";
import {STATS_TAB_KEY} from "../components/my_traffic/stats/Stats";
import ChatWidget from "../services/chat_widget/ChatWidget";
import moment from "moment-timezone";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: IMyTrafficReducer = {
  globalSpinner: {
    hide(): void {
    },
    show(): void {
    },
    isPending(): boolean {
      return false
    }
  },
  growl: {
    error(message: string): void {
    },
    info(message: string): void {
    },
    success(message: string): void {
    },
    warning(message: string): void {
    }
  },
  visitsAndLeadsConfig: {
    endDate: moment().format('YYYY-MM-DD'),
    startDate: moment().subtract(6, 'd').format('YYYY-MM-DD')
  },
  swimLanesConfig: {},
  visitorChatMap: new Map(),
  chatWidget: new ChatWidget(),
  notificationsRefreshKey: uuid(),
  statsActiveTabIndex: Number(Util.getDefaultIfNull(window.sessionStorage.getItem(STATS_TAB_KEY), 0)),
  myTrafficActiveTabIndex: Number(Util.getDefaultIfNull(window.sessionStorage.getItem(MY_TRAFFIC_TAB_KEY), 0)),
  progressBars: new Map(),
  notificationService: null
}, action: { type: string, payload: any }) => {

  state = {...state};

  switch (action.type) {
    case actions.SET_GROWL:
      state.growl = action.payload;
      return state;
    case actions.SET_GLOBAL_SPINNER_REF:
      state.globalSpinner = action.payload;
      return state;
    case actions.SET_SWIM_LANES_CONFIG:
      state.swimLanesConfig = action.payload;
      return state
    case actions.UPDATE_USER_NOTIFICATIONS:
      state.notificationsRefreshKey = uuid();
      return state
    case actions.UPDATE_MY_TRAFFIC_TAB_INDEX:
      state.myTrafficActiveTabIndex = action.payload;
      window.sessionStorage.setItem(MY_TRAFFIC_TAB_KEY, action.payload.toString())
      return state;
    case actions.UPDATE_MY_TRAFFIC_STATS_TAB_INDEX:
      state.statsActiveTabIndex = action.payload;
      window.sessionStorage.setItem(STATS_TAB_KEY, action.payload.toString())
      return state;
    case actions.AUTH_SUCCESS:
      state.chatWidget.init(action.payload.user);
      return state;
    case actions.SET_PROGRESS_BAR:
      state.progressBars.set(action.payload.id, action.payload);
      state.progressBars = new Map(state.progressBars);
      return state;
    case actions.REMOVE_PROGRESS_BAR:
      state.progressBars.delete(action.payload);
      state.progressBars = new Map(state.progressBars);
      return state;

    case actions.SET_NOTIFICATION_SERVICE:
      state.notificationService = action.payload;
      return state
    default:
      return state;
  }
};
