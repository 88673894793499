import {
  IChannelBuckets,
  IChartDataset,
  ISmartSpendData,
  IStore,
} from "../../../../index.dts";

import { IChartAnnotation } from "../../../util/chart/annotation/chart_annotation";
import { ISmartSpendFilterData } from "../../smart_spend_filter/SmartSpendFilter";
import { OrganicDataset } from "../../../util/chart/dataset/organic_dataset";
import { PaidDataset } from "../../../util/chart/dataset/paid_dataset";
import React, { useEffect, useState } from "react";
import { SmartSpendChart } from "../SmartSpendChart";
import { SmartSpendChartService } from "../smart_spend_chart_service";
import { TotalDataset } from "../../../util/chart/dataset/total_dataset";
import { useTranslation } from "react-i18next";
import { ProductTypes } from "../../../util/enum/product_types";
import { ColoredDataset } from "../../../util/chart/dataset/colored_dataset";
import { BotOrganicDataset } from "../../../util/chart/dataset/bot_organic_dataset";
import { BotTotalDataset } from "../../../util/chart/dataset/bot_total_dataset";
import { BotPaidDataset } from "../../../util/chart/dataset/bot_paid_dataset";
import { Button } from "primereact/button";
import { shallowEqual, useSelector } from "react-redux";
import { ChartConfig } from "../../../util/chart/chart_config";
import Util from "../../../util/Util";
import { NativeDataset } from "../../../util/chart/dataset/native_dataset";
import { BotNativeDataset } from "../../../util/chart/dataset/bot_native_dataset";
import { NonNativeDataset } from "../../../util/chart/dataset/non_native_dataset";

interface IProps {
  readonly annotations?: IChartAnnotation[];
  readonly data?: ISmartSpendData;
  readonly filter: ISmartSpendFilterData;
  readonly scale: {
    readonly id: string;
    readonly label: string;
  };
}

export function SmartSpendChannelsChart(props: IProps) {
  const service = new SmartSpendChartService();
  const [selectedAll, setSelectedAll] = useState<boolean>(true);
  const [isDisplayedGA4, setIsDisplayedGA4] = useState<boolean>(true);
  const dealership = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  /**
   * HOOKS
   */
  const { t } = useTranslation();
  /**
   * FUNCTIONS
   */

  useEffect(() => {
    if (
      props.data &&
      props.data.gaErrorMessage !== "" &&
      props.data.gaErrorMessage !== null
    ) {
      Util.error(props.data.gaErrorMessage);
    }
  }, [props.data]);

  function getGaDatasets(): IChartDataset[] {
    const datasets: IChartDataset[] = [];
    if (props && props.data && props.data.channels) {
      Object.keys(props?.data.channels).map((channelLabel) => {
        if (channelLabel.includes("GA"))
          datasets.push(
            new ColoredDataset({
              label: channelLabel.replaceAll("_", " "),
              data: props.data
                ? getFilteredData(props.data.channels[channelLabel])
                : undefined,
              yAxisID: props.scale.id,
            })
          );
      });
    }

    return datasets;
  }

  function getChannelGMBDataset(): IChartDataset {
    return new ColoredDataset({
      label: t("googleMyBusiness"),
      data: getFilteredData(props.data?.channels["Google My Business"]),
      yAxisID: props.scale.id,
    });
  }

  function getChannelNativeDataset(): IChartDataset {
    return new NativeDataset({
      label: t("native"),
      data: getFilteredData(props.data?.channels?.native),
      yAxisID: props.scale.id,
    });
  }

  function getChannelBotNativeDataset(): IChartDataset {
    return new BotNativeDataset({
      label: t("bot_native"),
      data: getFilteredData(props.data?.channels?.bot_native),
      yAxisID: props.scale.id,
    });
  }

  function getChannelNonNativeDataset(): IChartDataset {
    return new NonNativeDataset({
      label: dealership?.crmSource?.name ?? t("non_native"),
      data: getFilteredData(props.data?.channels?.non_native),
      yAxisID: props.scale.id,
    });
  }

  function getChannelOrganicDataset(): IChartDataset {
    return new OrganicDataset({
      label: t("organic"),
      data: getFilteredData(props.data?.channels?.organic),
      yAxisID: props.scale.id,
    });
  }

  function getChannelPaidDataset(): IChartDataset {
    return new PaidDataset({
      label: t("paid"),
      data: getFilteredData(props.data?.channels?.paid),
      yAxisID: props.scale.id,
    });
  }

  function getChannelTotalDataset(): IChartDataset {
    return new TotalDataset({
      label: t("total"),
      data: getFilteredData(props.data?.channels?.total),
      yAxisID: props.scale.id,
    });
  }

  function getFilteredData(source: any): number[] {
    return service.getFilteredData(
      source,
      props.filter,
      props.data?.dates?.length
    );
  }

  function getChannelBotOrganicDataset(): IChartDataset {
    return new BotOrganicDataset({
      label: t("botOrganic"),
      data: getFilteredData(props.data?.channels?.bot_organic),
      yAxisID: props.scale.id,
    });
  }

  function getChannelBotPaidDataset(): IChartDataset {
    return new BotPaidDataset({
      label: t("botPaid"),
      data: getFilteredData(props.data?.channels?.bot_paid),
      yAxisID: props.scale.id,
    });
  }

  function getChannelBotTotalDataset(): IChartDataset {
    return new BotTotalDataset({
      label: t("botTotal"),
      data: getFilteredData(props.data?.channels?.bot_total),
      yAxisID: props.scale.id,
    });
  }

  function getChannelBuckets(props: IProps): IChannelBuckets | undefined {
    if (props.filter.product?.type !== ProductTypes.all) {
      return undefined;
    }
    if (
      props.filter.options?.makes &&
      Object.keys(props.filter.options.makes).length !== 0
    ) {
      return undefined;
    }

    return props.data?.channelBuckets;
  }

  /**
   * VIEW
   */

  return (
    <>
      <div
        style={{
          marginBottom: "0.5rem",
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        {dealership?.useGaEnabled &&
          props.scale.label !== t(ChartConfig.pageViews.labelKey) && (
            <label
              style={{
                padding: "5px",
                fontSize: "1rem",
                fontWeight: "bold",
                marginRight: "10px",
              }}
            >
              GA Account ID: {dealership?.gaPropertyId}
            </label>
          )}
        {dealership?.useGaEnabled &&
          props.scale.label !== t(ChartConfig.pageViews.labelKey) && (
            <Button
              id="apply-btn"
              onClick={() => setIsDisplayedGA4(!isDisplayedGA4)}
              className="no-icon-buttons"
              label={isDisplayedGA4 ? t("hideGA4") : t("displayGA4")}
            />
          )}
        <Button
          id="apply-btn"
          onClick={() => setSelectedAll(!selectedAll)}
          className="no-icon-buttons"
          label={selectedAll ? t("unselectAll") : t("selectAll")}
        />
      </div>
      <SmartSpendChart
        selectedAll={selectedAll}
        annotations={props.annotations}
        data={props.data}
        channelBuckets={getChannelBuckets(props)}
        datasets={
          isDisplayedGA4 &&
          dealership?.useGaEnabled &&
          props.scale.label !== t(ChartConfig.pageViews.labelKey)
            ? props.scale.label == t(ChartConfig.leads.labelKey)
              ? [
                  getChannelNonNativeDataset(),
                  getChannelNativeDataset(),
                  getChannelBotNativeDataset(),
                  getChannelTotalDataset(),
                  getChannelOrganicDataset(),
                  getChannelGMBDataset(),
                  getChannelPaidDataset(),
                  getChannelBotOrganicDataset(),
                  getChannelBotPaidDataset(),
                  getChannelBotTotalDataset(),
                  ...getGaDatasets(),
                ]
              : [
                  getChannelTotalDataset(),
                  getChannelOrganicDataset(),
                  getChannelGMBDataset(),
                  getChannelPaidDataset(),
                  getChannelBotOrganicDataset(),
                  getChannelBotPaidDataset(),
                  getChannelBotTotalDataset(),
                  ...getGaDatasets(),
                ]
            : props.scale.label == t(ChartConfig.leads.labelKey)
            ? [
                getChannelNonNativeDataset(),
                getChannelNativeDataset(),
                getChannelBotNativeDataset(),
                getChannelTotalDataset(),
                getChannelOrganicDataset(),
                getChannelGMBDataset(),
                getChannelPaidDataset(),
                getChannelBotOrganicDataset(),
                getChannelBotPaidDataset(),
                getChannelBotTotalDataset(),
              ]
            : [
                getChannelTotalDataset(),
                getChannelOrganicDataset(),
                getChannelGMBDataset(),
                getChannelPaidDataset(),
                getChannelBotOrganicDataset(),
                getChannelBotPaidDataset(),
                getChannelBotTotalDataset(),
              ]
        }
        scale={props.scale}
      />
    </>
  );
}
