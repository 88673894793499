import {
  AxiosResponse,
  IDealershipData,
  IDealershipVisitor,
  IMakeModelData,
  ISEMVendorsData,
  ISmartSpendData,
  ISmartSpendDataMakeModelParams,
  ISmartSpendDataParams as ISmartSpendDataArgs,
} from "../../index.dts";
import i18n from "../i18n/config";

import Axios from "../config/Axios";
import Util from "../util/Util";
import actions from "../constants/actions";
import moment from "moment";

export const updateVisitorsAndLeadsConfig = (payload: {
  startDate: string;
  endDate: string;
}) => ({
  type: actions.UPDATE_VISITORS_AND_LEADS_CONFIG,
  payload: payload,
});

export const getDealershipData = (
  dealershipId: string,
  detailed?: boolean
): Promise<AxiosResponse<IDealershipData>> =>
  new Promise((resolve, reject) => {
    Axios.instance
      .get(`kanin/dataQuery`, {
        params: { siteid: dealershipId, detailed: detailed },
      })
      .then((response) => resolve(response))
      .catch((error) => {
        const status = error?.response?.status;
        if (status === 400) {
          const message = error.response?.data?.message;
          if (message?.includes("Dealership data not found")) {
            error.response.data.message = i18n.t("dealershipIsDisabled");
          }
        }

        reject(error);
      });
  });

export const getVisitorDetails = (
  dealershipRefId: string,
  visitorRefId: string,
  detailed?: boolean
): Promise<AxiosResponse<IDealershipVisitor>> =>
  Axios.instance.get("kanin/visitorDetails", {
    params: {
      siteid: dealershipRefId,
      visitorId: visitorRefId,
      detailed: detailed,
    },
  });

export const getVisitorHistory = (dealershipRefId: string, visitorId: string) =>
  Axios.instance.post(`kanin/visitorHistory?siteid=${dealershipRefId}`, {
    visitorId: visitorId,
    historyPeriod: 5,
  });

export const getScoreConfig = (dealershipRefId: string) =>
  Axios.instance.get(`kanin/scoreConfig?siteid=${dealershipRefId}`);
export const saveScoreConfig = (dealershipRefId: string, dto: any) =>
  Axios.instance.post(`kanin/scoreConfig?siteid=${dealershipRefId}`, dto);

export const getVisitsAndLeadsStats = (dto: {
  siteid: string;
  startDate: string;
  endDate: string;
}) => Axios.instance.post(`kanin/visitsAndLeads`, dto);

export const getChatbotStats = (
  dealershipRefId: string,
  notificationRefId: string
) =>
  Axios.instance.post("kanin/chatStats", {
    siteid: dealershipRefId,
    campaignId: notificationRefId,
  });

export function getSEMVendorsData(args: {
  dealershipId: number;
  startDate: string;
  endDate: string;
}): Promise<ISEMVendorsData> {
  return new Promise((resolve, reject) => {
    Axios.instance
      .get("rts/vendors", { params: args })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getSmartSpendData(
  args: ISmartSpendDataArgs
): Promise<ISmartSpendData> {
  return new Promise((resolve, reject) => {
    Axios.instance
      .get("rts/loadStats", {
        params: {
          dealershipId: args.dealershipId,
          startDate: moment(args.date.start).format(Util.localDateFormat),
          endDate: moment(args.date.end).format(Util.localDateFormat),
          type: args.type,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getSmartSpendMakeModelData(
  args: ISmartSpendDataMakeModelParams
): Promise<IMakeModelData> {
  return new Promise((resolve, reject) => {
    Axios.instance
      .get("rts/loadMakeModelStats", {
        params: {
          dealershipId: args.dealershipId,
          startDate: moment(args.date.start).format(Util.localDateFormat),
          endDate: moment(args.date.end).format(Util.localDateFormat),
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function loadVdpStats(
  dealershipId: number,
  startDate: string,
  endDate: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    Axios.instance
      .get("rts/loadVdpStats", {
        params: {
          dealershipId: dealershipId,
          startDate: moment(startDate).format(Util.localDateFormat),
          endDate: moment(endDate).format(Util.localDateFormat),
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
