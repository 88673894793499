import Axios from "../config/Axios";
import { ITemplateDto, ITemplateSearchRequestDto } from "../../index.dts";

export const createTemplate = (templateDTO: ITemplateDto) =>
  Axios.instance.post("templates", templateDTO);
export const deleteTemplate = (templateId: number) =>
  Axios.instance.delete(`templates/${templateId}`);
export const deleteTemplates = (templatesIds: number[]) =>
  Axios.all(
    templatesIds.map((templateId) =>
      Axios.instance.delete(`templates/${templateId}`)
    )
  );
export const updateTemplate = (templateId: number, dto: ITemplateDto) =>
  Axios.instance.put(`templates/${templateId}`, dto);
export const getTemplate = (templateId: number) =>
  Axios.instance.get(`templates/${templateId}`);
export const getTemplateList = (searchRequestDto: ITemplateSearchRequestDto) =>
  Axios.instance.post(`templates/list`, searchRequestDto);
export const getOfferTemplate = () => Axios.instance.get("templates/offer");
export const getOfferMultiphaseTemplate = (
  multiphaseEnabled: boolean,
  singleCTAEnabled: boolean
) =>
  Axios.instance.get(
    `templates/default?isMultiphase=${multiphaseEnabled}&isSingleCTA=${singleCTAEnabled}`
  );
