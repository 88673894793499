import React, { useEffect, useState } from "react";
import "./campaigns.css";
import {
  deleteCampaigns,
  enabledCampaignNotifications,
  getCampaignList,
} from "../../../actions/campaignActions";
import Util from "../../../util/Util";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { IGroupCampaign, IPageable } from "../../../../index.dts";
import moment from "moment-timezone";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { useHistory } from "react-router-dom";
import { MODE } from "../../../util/Enums";
import { confirmAlert } from "react-confirm-alert";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import { IDS } from "../../constants";
import { useTranslation } from "react-i18next";
import { DateRangePickerWidget } from "../../../components/data_range_picker/DateRangePickerWidget";
import { DateRangePeriods } from "../../../components/data_range_picker/date_range_periods";

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

interface IProps {
  activeIndex: number;
}

export function Campaigns({ activeIndex }: IProps) {
  const history = useHistory();
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [groupCampaignList, setGroupCampaignList] = useState<IGroupCampaign[]>(
    []
  );
  const [selectedCampaigns, setSelectedCampaigns] = useState<IGroupCampaign[]>(
    []
  );
  const [sortMap] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(new Map());

  const [nameFilterValue, setNameFilterValue] = useState<string>("");
  const [groupFilterValue, setGroupFilterValue] = useState<string>("");
  const [ownerFilterValue, setOwnerFilterValue] = useState<string>("");
  const [createdPeriod, setCreatedPeriod] = useState<{
    start?: Date;
    end?: Date;
  }>({
    start: undefined,
    end: undefined,
  });

  const onClear = () => {
    sortMap.clear();
    setNameFilterValue("");
    setGroupFilterValue("");
    setOwnerFilterValue("");
    setCreatedPeriod({
      start: undefined,
      end: undefined,
    });
  };

  useEffect(() => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getData(0, limit).finally(() => {
        setPage(0);
        setFirst(0);
        searchTimeoutInterval = 500;
        setSelectedCampaigns([]);
      });
    }, searchTimeoutInterval);
    // eslint-disable-next-line
  }, [nameFilterValue, groupFilterValue, ownerFilterValue, createdPeriod]);

  const getData = (page: number, limit: number): Promise<IPageable> =>
    new Promise((resolve, reject) => {
      setLoading(true);
      getCampaignList({
        pageLimit: limit,
        pageNumber: page,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),

        name: nameFilterValue,
        rtsGroupName: groupFilterValue,
        userEmail: ownerFilterValue,

        toDate: createdPeriod.end
          ? moment(createdPeriod.end).format(Util.localDateTimeFormat)
          : undefined,
        fromDate: createdPeriod.start
          ? moment(createdPeriod.start).format(Util.localDateTimeFormat)
          : undefined,
      })
        .then((response) => {
          resolve(response);
          setGroupCampaignList(response.data.content);
          setTotalItemsCount(response.data.totalElements);
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => setLoading(false));
    });

  const onSort = (data: any): void => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getData(page, limit);
    }
  };

  const onPage = (event: any): void => {
    getData(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
      setSelectedCampaigns([]);
    });
  };

  const onNew = (): void => {
    history.push(Util.PATH_NAMES.MY_TRAFFIC_CREATE_CAMPAIGN, {
      activeIndex,
      mode: MODE.NEW,
    });
  };

  const onEdit = (): void => {
    if (selectedCampaigns.length === 1) {
      history.push(Util.PATH_NAMES.MY_TRAFFIC_EDIT_CAMPAIGN, {
        activeIndex,
        mode: MODE.EDIT,
        id: selectedCampaigns[0].id,
      });
    } else {
      Util.warning("Please select Group Campaign.");
    }
  };

  const onDelete = (): void => {
    if (selectedCampaigns.length > 0) {
      confirmAlert({
        title: `You are about to delete ${selectedCampaigns.length} campaign${
          selectedCampaigns.length > 1 ? "s" : ""
        }!`,
        message: "Are you sure you want to do this?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              Util.globalSpinner().show();
              deleteCampaigns(selectedCampaigns.map((campaign) => campaign.id))
                .then(() => Util.success("Campaign deleted successfully."))
                .catch((error) => Util.showError(error))
                .finally(() => {
                  Util.globalSpinner().hide();
                  getData(0, limit).finally(() => {
                    setPage(0);
                    setFirst(0);
                    setSelectedCampaigns([]);
                  });
                });
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    } else {
      Util.warning("Please choose at least one campaign.");
    }
  };

  const onChangeNotificationEnabled = (data: IGroupCampaign) => {
    data.enabled = !data.enabled;
    enabledCampaignNotifications(data.id, data.enabled)
      .then(() => {
        if (data.enabled) {
          Util.success("Campaign notifications enabled successfully.");
        } else {
          Util.success("Campaign notifications disabled successfully.");
        }
      })
      .catch((error) => {
        Util.showError(error);
        data.enabled = !data.enabled;
        forceUpdate();
      });

    forceUpdate();
  };

  const enableNotificationTemplate = (data: IGroupCampaign) => {
    return (
      <InputSwitch
        onChange={() => onChangeNotificationEnabled(data)}
        checked={data.enabled}
      />
    );
  };

  const createdTemplate = (data: IGroupCampaign) => {
    return (
      <div>
        {moment(Util.utcToLocal(data.created)).format(Util.usDateTimeFormat)}
      </div>
    );
  };

  const startDateTemplate = (data: IGroupCampaign) => {
    return (
      <div>
        {moment(Util.utcToLocal(data.startDate)).format(Util.usDateFormat)}
      </div>
    );
  };

  const endDateTemplate = (data: IGroupCampaign) => {
    return (
      <div>
        {moment(Util.utcToLocal(data.endDate)).format(Util.usDateFormat)}
      </div>
    );
  };

  const nameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={nameFilterValue}
      onChange={(e: any) => {
        setNameFilterValue(e.target.value || "");
      }}
    />
  );

  const groupFilter = (
    <InputText
      style={{ width: "100%" }}
      value={groupFilterValue}
      onChange={(e: any) => {
        setGroupFilterValue(e.target.value || "");
      }}
    />
  );

  const ownerFilter = (
    <InputText
      style={{ width: "100%" }}
      value={ownerFilterValue}
      onChange={(e: any) => {
        setOwnerFilterValue(e.target.value || "");
      }}
    />
  );

  function onCreatedPeriodChange(start?: Date, end?: Date): void {
    setCreatedPeriod({
      start: start ? moment(start).set({ hour: 0, minute: 0, second: 0 }).toDate() : undefined,
      end: end ? moment(end).set({ hour: 23, minute: 59, second: 59 }).toDate() : undefined,
    });
  }

  const createdFilter = (
    <DateRangePickerWidget
      start={createdPeriod.start}
      end={createdPeriod.end}
      ranges={DateRangePeriods.options} // Replace 'Options' with the range definitions
      onChange={onCreatedPeriodChange}
    />
  );

  return (
    <div id={"campaigns-view"}>
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div
            className={
              limit > 10
                ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting"
                : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
            }
          >
            <DataTable
              resizableColumns={true}
              scrollable
              columnResizeMode={"expand"}
              rowsPerPageOptions={Util.rowsPerPageOptions}
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
              value={groupCampaignList}
              paginator={true}
              totalRecords={totalItemsCount}
              rows={limit}
              lazy={true}
              first={first}
              filterDisplay="row"
              loading={loading}
              onPage={onPage}
              onSort={onSort}
              sortMode={"multiple"}
              multiSortMeta={Array.from(sortMap.values())}
              selection={selectedCampaigns}
              onSelectionChange={(e) => setSelectedCampaigns(e.value)}
              header={
                <div className={"p-grid-header-components"}>
                  <Button
                    id={IDS.button.clear}
                    style={{ position: "absolute", left: "10px" }}
                    icon={"pi pi-filter"}
                    label={t("clear")}
                    onClick={onClear}
                  />
                  <Button
                    style={{ marginRight: 5 }}
                    onClick={onNew}
                    icon="pi pi-plus"
                    label={"New"}
                  />
                  <Button
                    label={"Edit"}
                    style={{ marginRight: 5 }}
                    onClick={onEdit}
                    icon="pi pi-pencil"
                    disabled={selectedCampaigns.length !== 1}
                  />
                  <Button
                    onClick={onDelete}
                    icon="pi pi-trash"
                    className="p-button-danger"
                    label={"Delete"}
                    disabled={selectedCampaigns.length === 0}
                  />
                </div>
              }
            >
              <Column
                selectionMode="multiple"
                style={{ width: "3em", flex: "0.1" }}
              />
              <Column
                filter={true}
                sortable={true}
                field={"name"}
                sortField={"name"}
                header={"Name"}
                filterElement={nameFilter}
              />
              <Column
                filter={true}
                sortable={false}
                field={"rtsGroupName"}
                header={"Dealership Group"}
                filterElement={groupFilter}
              />
              <Column
                filter={false}
                sortable={true}
                field={"enabled"}
                header={"Enable Notifications"}
                body={enableNotificationTemplate}
              />
              <Column
                filter={true}
                sortable={false}
                field={"ownerEmail"}
                header={"Owner"}
                filterElement={ownerFilter}
              />
              <Column
                filter={false}
                sortable={true}
                field={"startDate"}
                header={"Start Date"}
                body={startDateTemplate}
                style={{ overflow: "visible" }}
              />
              <Column
                filter={false}
                sortable={true}
                field={"endDate"}
                header={"End Date"}
                body={endDateTemplate}
                style={{ overflow: "visible" }}
              />
              <Column
                filter={true}
                sortable={true}
                field={"created"}
                header={"Created"}
                body={createdTemplate}
                filterElement={createdFilter}
                style={{ overflow: "visible" }}
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Campaigns;
