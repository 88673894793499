import { IPageable, ISearchCRMSourcesDto } from "../../index.dts";
import Axios from "../config/Axios";

export const getCrmSourceList = () => Axios.instance.get(`crm`);
export const getCrmSourcesList = (searchCriteria: ISearchCRMSourcesDto): Promise<IPageable<any>> => Axios.instance.post(`crm/list`, searchCriteria);
export const getCrmSource = (crmSourceId: number) => Axios.instance.get(`crm/${crmSourceId}`);
export const createCrmSource = (formData: FormData) => Axios.instance.post(`crm`, formData);
export const updateCrmSource = (crmSourceId: number, dto: { name: any; adfTemplate?: any; rtsName?: any; subdomain?: any }) => Axios.instance.put(`crm/${crmSourceId}`, dto);
export const deleteCrmSource = (crmSourceId: number) => Axios.instance.delete(`crm/${crmSourceId}`);
export const deleteCrmSources = (crmSourceIds: number[]) => Axios.all(crmSourceIds.map(crmSourceId => Axios.instance.delete(`crm/${crmSourceId}`)));
