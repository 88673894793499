import React, { useEffect, useState } from "react";
import Util from "../../../util/Util";
import { getVisitorExternalData } from "../../../actions/myTrafficActions";
import { shallowEqual, useSelector } from "react-redux";
import { IStore } from "../../../../index.dts";

interface IProps {
    visitorId: string;
    dealershipId: number;
}

interface PiiInfo {
    piiAddress: String;
    piiCity: string;
    piiPhone: string;
    piiState: string;
    piiZipcode: String;
    piiEmails: string;
    piiFullName: string;
    piiLastName: string;
    piiFirstName: string;
}

interface VehicleInfo {
    vehicleMake: string;
    vehicleYear: number;
    vehicleModel: string | null;
    vehicleDatePurchased: string;
    vehicleSeries: string;
    vehiclePlateNumber: string;
    vehicleVin: String;
}

interface ResponseData extends PiiInfo, VehicleInfo { }

interface ApiResponse {
    content: ResponseData[];
}

export function LeadDetails({ visitorId, dealershipId }: IProps) {
    const [pending, setPending] = useState<boolean>(false);
    const [hasContent, setHasContent] = useState<boolean>(false);
    const dealershipContext = useSelector(
        (store: IStore) => store.auth.dealershipContext,
        shallowEqual
    );
    const [visitorExternalData, setVisitorExternalData] =
        useState<ApiResponse>({
            content: [],
        });

    useEffect(() => {
        if (Util.isEmpty(visitorId)) {
            return;
        }

        getData(dealershipId, visitorId);
    }, [visitorId, dealershipId]);

    const getData = (dealershipId: number, visitorRefId: string) =>
        new Promise((resolve, reject) => {
            setPending(true);
            getVisitorExternalData(dealershipId, visitorRefId)
                .then((response) => {
                    resolve(response);
                    setVisitorExternalData(response.data);
                    if (response.data.content.length > 0) {
                        setHasContent(true);
                    } else {
                        setHasContent(false);
                    }
                })
                .catch((error) => {
                    reject(error);
                    Util.showError(error);
                })
                .finally(() => setPending(false));
        });

    // Define the keys you want to render
    const allowedKeys = ["vehicleYear", "vehicleMake", "vehicleModel", "vehicleSeries", "vehiclePlateNumber", 
    "vehicleVin", "vehicleDatePurchased", "piiAddress", "piiCity", "piiState", "piiZipcode", "piiFullName", 
    "piiPhone", "piiEmails"];

    // Function to truncate email if too long
    const truncateEmail = (value: any) => {
        if (value.includes(",")) {
            return value.split(",").join(",\n");
        } else {
            return value;
        }
    }

    const formatLabel = (key: string) => {
        let newKey = key.replace("pii", "").replace("vehicle", "");
        newKey = newKey.replace(/([a-z])([A-Z])/g, '$1 $2'); // converts camelCase to space separated
        newKey = newKey.charAt(0).toUpperCase() + newKey.slice(1); // capitalizes the first letter
        if (newKey === "Series") {
            newKey = "Trim";
        }
        return newKey;
    };

    return (
        <div className={"lead-details-tab"}>
            {!hasContent
                ? <p>No data to display</p>
                : visitorExternalData.content.map((data: ResponseData, index: number) => (
                    <>
                        <h3>Vehicle # {index + 1}</h3>
                        <div key={index}>
                            {allowedKeys.map(key => {
                                const value = data[key];
                                if (value !== undefined) {
                                    return (
                                        <div className={"conv-history-item"} key={key}>
                                            <label><b>{formatLabel(key)}: </b></label>
                                            <span>
                                                {key === "piiEmails" ? truncateEmail(value) : value || ""}
                                            </span>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </>
                ))
            }
        </div>
    );
}
