import "./smart_spend_data_view.css";

import {
  IDateArgs,
  ISmartSpendData,
  ITrafficMakeModel,
  ITrafficMarker,
} from "../../../index.dts";
import {
  defaultSmartSpendFilter,
  ISmartSpendFilterData,
  SmartSpendFilter,
} from "../smart_spend_filter/SmartSpendFilter";
import React, { useEffect, useRef, useState } from "react";

import { Fieldset } from "primereact/fieldset";
import { IChartAnnotation } from "../../util/chart/annotation/chart_annotation";
import { Panel } from "primereact/panel";
import { SmartSpendChannelsChart } from "../smart_spend_chart/channels/SmartSpendChannelsChart";
import { SmartSpendChartService } from "../smart_spend_chart/smart_spend_chart_service";
import { SmartSpendSEMVendorsChart } from "../smart_spend_chart/sem_vendors/SmartSpendSEMVendorsChart";
import Spinner from "../spinner/Spinner";
import { TrafficMarker } from "../../util/chart/annotation/traffic_marker";
import Util from "../../util/Util";
import { listSEMVendors } from "../../actions/semVendorActions";
import {
  listTrafficMakes,
  listTrafficMarkers,
} from "../../actions/trafficMarkerActions";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { SmartSpendFilterOptions } from "../smart_spend_filter/smart_spend_filter_options";
import { useAppContext } from "../../services/contexts/app-context";

export type ISmartSpendDataAction = (
  args: IDateArgs
) => Promise<ISmartSpendData>;

interface IProps {
  readonly dataAction: ISmartSpendDataAction;
  readonly dealershipId: number;
  readonly dealershipRefId: string;
  readonly scale: {
    readonly id: string;
    readonly label: string;
  };
}

export function SmartSpendDataView(props: IProps) {
  const service = new SmartSpendChartService();
  let isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  /**
   * HOOKS
   */
  const { t }: any = useTranslation();

  /**
   * STATE
   */

  const ctx = useAppContext();
  const [markers, setMarkers] = useState<ITrafficMarker[]>();
  const [makeModels, setMakeModels] = useState<ITrafficMakeModel>({});
  const [data, setData] = useState<ISmartSpendData>();
  const [filter, setFilter] = useState<ISmartSpendFilterData>(
    ctx.filter ? ctx.filter : defaultSmartSpendFilter
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [needsRefresh, setNeedsRefresh] = useState<boolean>(false);
  const [semVendors, setSemVendors] = useState<any[]>([]);
  const [displayAnnotationTitle, setDisplayAnnotationTitle] =
    useState<boolean>(false);

  /**
   * EFFECTS
   */

  /**
   * On dealership change
   */
  useEffect(() => {
    if (isMountedRef.current) load();
    // eslint-disable-next-line
  }, [props.dealershipId]);

  /**
   * On filter change
   */
  useEffect(() => {
    if (isMountedRef.current) {
      if (needsRefresh) {
        load();
      }
    }
    // eslint-disable-next-line
  }, [filter]);

  /**
   * CALLBACKS
   */

  function onFilterChange(update: ISmartSpendFilterData): void {
    ctx.setFilter(update);
    setFilter(update);
    checkIfNeedsRefresh(update);
  }

  /**
   * FUNCTIONS
   */

  function checkIfNeedsRefresh(update: ISmartSpendFilterData): void {
    const hasChangeMarkerId = update.marker?.id !== filter.marker?.id;
    const hasChangeMarkerDate = update.marker?.date !== filter.marker?.date;
    const hasChangeDateStart = update.period?.start !== filter.period?.start;
    const hasChangeDateEnd = update.period?.end !== filter.period?.end;
    const hasChange =
      hasChangeMarkerId ||
      hasChangeMarkerDate ||
      hasChangeDateStart ||
      hasChangeDateEnd;
    setNeedsRefresh(hasChange);
  }

  function getAnnotations(): IChartAnnotation[] {
    const annotations = markers?.map(
      (marker) =>
        new TrafficMarker({
          id: marker.id,
          label: marker.title,
          scaleId: "days",
          value: marker.axisValue,
          endValue: marker.axisValue,
          display: displayAnnotationTitle,
          setDisplay: setDisplayAnnotationTitle,
        })
    );

    return annotations ?? [];
  }

  function getSmartSpendData(): Promise<ISmartSpendData> {
    return props.dataAction({
      start: filter.period?.start,
      end: filter.period?.end,
    });
  }

  function getTrafficMarkers(): Promise<ITrafficMarker[]> {
    return listTrafficMarkers({
      dealershipId: props.dealershipId,
      startDate: moment(filter.period?.start).format(Util.localDateFormat),
      endDate: moment(filter.period?.end).format(Util.localDateFormat),
    });
  }

  function getTrafficMakes(
    dealershipRefId: string
  ): Promise<ITrafficMakeModel> {
    return listTrafficMakes(dealershipRefId);
  }

  function load(): Promise<void> {
    return new Promise((resolve) => {
      setIsLoading(true);
      loadData().finally(() => {
        setIsLoading(false);
        resolve();
      });
    });
  }

  function loadData(): Promise<void> {
    return new Promise((resolve) => {
      Promise.all([
        getSmartSpendData(),
        getTrafficMarkers(),
        getTrafficMakes(props.dealershipRefId),
        listSEMVendors({ dealershipId: props.dealershipId }),
        // getCampaignMarkers(props.dealershipRefId)
      ])
        .then((response) => {
          if (isMountedRef.current) {
            setData(response[0]);
            setMarkers(response[1]);
            setMakeModels(response[2]);
            setSemVendors(response[3]);
          }
        })
        .catch((error) => Util.showError(error))
        .finally(() => resolve());
    });
  }

  /**
   * VIEW
   */

  function getChannelsChart() {
    return (
      <Fieldset legend={t("trafficChannels")} toggleable={false}>
        <SmartSpendChannelsChart
          annotations={getAnnotations()}
          data={data}
          filter={filter}
          scale={{
            id: props.scale.id,
            label: props.scale.label,
          }}
        />
      </Fieldset>
    );
  }

  function getCharts() {
    return (
      <div className={"smart-spend-data-view-charts-container"}>
        {getChannelsChart()}
        {getSEMVendorsChart()}
      </div>
    );
  }

  function getHeader() {
    return (
      <SmartSpendFilter
        dealershipId={props.dealershipId}
        initial={filter}
        makesModels={service.getMakeModels(makeModels)}
        makeObjects={makeModels}
        onChange={onFilterChange}
        options={[
          SmartSpendFilterOptions.makes,
          SmartSpendFilterOptions.models,
          SmartSpendFilterOptions.marker,
          SmartSpendFilterOptions.dateRange,
          SmartSpendFilterOptions.productType,
          SmartSpendFilterOptions.years,
        ]}
      />
    );
  }

  function getSEMVendorsChart() {
    return (
      <Fieldset legend={t("semVendors")} toggleable={false}>
        <SmartSpendSEMVendorsChart
          annotations={getAnnotations()}
          data={data}
          filter={filter}
          scale={{
            id: props.scale.id,
            label: props.scale.label,
          }}
          semVendors={semVendors}
        />
      </Fieldset>
    );
  }

  function getSpinner() {
    return isLoading ? (
      <Spinner
        visible={isLoading}
        width={20}
        height={20}
        style={{
          position: "absolute",
          width: 40,
          height: 40,
          top: "unset",
        }}
      />
    ) : null;
  }

  return (
    <div className={"smart-spend-data-view-container"}>
      <Panel
        className={"smart-spend-data-view-panel"}
        header={isMountedRef.current && getHeader()}
      >
        {isMountedRef.current && getSpinner()}
        {isMountedRef.current && getCharts()}
      </Panel>
    </div>
  );
}
