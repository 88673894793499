import {IKeyOptions, IMakeModelData} from "../../../../index.dts";
import {forEach, includes, isArray} from "lodash";
import {ISmartSpendFilterData} from "../../smart_spend_filter/SmartSpendFilter";
import {MakeModelFilterResolver} from "../filter/MakeModelFilterResolver";

export class MakeModelDataService {


  public getFilteredData(source: any, filter: ISmartSpendFilterData, make?: string, model?: string, size?: number): number[] {
    const resolver = new MakeModelFilterResolver();
    return resolver.resolve(source, filter, make, model, size);
  }

  public getMakeModelOptions(source?: IMakeModelData): IKeyOptions {
    let result: any = {};
    const dailyTotal = "daily_total";

    function pluck(target: any): void {
      forEach(target, (value: any) => {
        forEach(value, (_value: any, _key: string) => {
          if (_key !== dailyTotal) {
            forEach(_value, (__value: any, __key: string) => {
              if (__key !== dailyTotal) {
                if (!isArray(result[__key])) {
                  result[__key] = [];
                }

                forEach(__value, (___value: any, ___key: string) => {
                  if (___key !== dailyTotal) {
                    if (!includes(result[__key], ___key)) {
                      result[__key].push(___key);
                    }
                  }
                });
              }
            });
          }
        });
      });
    }

    pluck(source?.leads);
    pluck(source?.pageViews);

    return result;
  }
}