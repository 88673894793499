import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Dialog } from "primereact/dialog";
import { getMyTrafficNotificationLogList } from "../../../actions/MyTrafficNotificationActions";
import Util from "../../../util/Util";
import { Column } from "primereact/column";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import { IMyTrafficNotificationLog } from "../../../../index.dts";
import moment from "moment-timezone";
import { SEARCH_OPERATIONS } from "../../../util/Enums";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { isArray } from "lodash";
import { IDS } from "../../../views/constants";
import { useTranslation } from "react-i18next";
import { DateRangePeriods } from "../../data_range_picker/date_range_periods";
import { DateRangePickerWidget } from "../../data_range_picker/DateRangePickerWidget";

interface IProps {}

const STATUS_MAP = {
  SENT: "SENT",
  RECEIVED: "Received",
  IGNORED: "Ignored",
  REVIEWED: "Reviewed",
  SENT_OFFER: "Sent Offer",
  OPENED_CHAT: "Opened Chat",
  NON_RESPONDED: "Non Responded",
};

const ReportModal = forwardRef((props: IProps, ref: any) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [campaignId, setCampaignId] = useState<number>(0);
  const [campaignName, setCampaignName] = useState<string>("");
  const [value, setValue] = useState<IMyTrafficNotificationLog[]>([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(Util.dataGridDefaultLimit);
  const [first, setFirst] = useState<number>(0);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);

  const [sortMap] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(
    new Map([
      [
        "created",
        {
          field: "created",
          order: -1,
        },
      ],
    ])
  );

  const [userFilterValue, setUserFilterValue] = useState<string>("");
  const [statusFilterValue, setStatusFilterValue] = useState<string>("");
  const [visitorFilterValue, setVisitorFilterValue] = useState<string>("");
  const period = DateRangePeriods.get(DateRangePeriods.keys.last30Days);
  const [createdPeriod, setCreatedPeriod] = useState<{
    start?: Date;
    end?: Date;
  }>({
    start: period.start,
    end: period.end,
  });
  const [responsePeriod, setResponsePeriod] = useState<{
    start?: Date;
    end?: Date;
  }>({
    start: period.start,
    end: period.end,
  });

  const onClear = () => {
    sortMap.clear();
    setCreatedPeriod({
      start: undefined,
      end: undefined,
    });
    setResponsePeriod({
      start: undefined,
      end: undefined,
    });
    setVisitorFilterValue("");
    setStatusFilterValue("");
  };

  useEffect(() => {
    if (visible) {
      getData(campaignId, page, limit);
    }
    // eslint-disable-next-line
  }, [
    visible,
    userFilterValue,
    statusFilterValue,
    visitorFilterValue,
    createdPeriod,
    responsePeriod,
  ]);

  useImperativeHandle(ref, () => ({
    show: onShow,
    hide: onHide,
  }));

  const onHide = (): void => {
    setPage(0);
    setCampaignId(0);
    setCampaignName("");
    setValue([]);
    setLimit(Util.dataGridDefaultLimit);
    setVisible(false);
    sortMap.clear();
    sortMap.set("created", {
      field: "created",
      order: -1,
    });
  };

  const onShow = (campaignId: number, campaignName: string): void => {
    setVisible(true);
    setCampaignId(campaignId);
    setCampaignName(campaignName);
  };

  const getData = (campaignId: number, page: number, limit: number) =>
    new Promise((resolve, reject) => {
      const filter: any[] = [
        {
          field: "rtsNotificationId",
          value: campaignId,
          operation: SEARCH_OPERATIONS.EQUAL,
        },
      ];

      if (userFilterValue) {
        filter.push({
          field: "user.email",
          value: userFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (statusFilterValue) {
        filter.push({
          field: "status",
          value: statusFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (visitorFilterValue) {
        filter.push({
          field: "visitorId",
          value: visitorFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (createdPeriod.start) {
        filter.push({
          field: "created",
          operation: SEARCH_OPERATIONS.GREATER_THAN,
          value: `${moment(createdPeriod.start).format("YYYY-MM-DDT")}00:00:00`,
        });
      }

      if (createdPeriod.end) {
        filter.push({
          field: "created",
          operation: SEARCH_OPERATIONS.LESS_THAN,
          value: `${moment(createdPeriod.end).format("YYYY-MM-DDT")}23:59:59`,
        });
      }

      if (responsePeriod.start) {
        filter.push({
          field: "responseTs",
          operation: SEARCH_OPERATIONS.GREATER_THAN,
          value: `${moment(responsePeriod.start).format(
            "YYYY-MM-DDT"
          )}00:00:00`,
        });
      }

      if (responsePeriod.end) {
        filter.push({
          field: "responseTs",
          operation: SEARCH_OPERATIONS.LESS_THAN,
          value: `${moment(responsePeriod.end).format("YYYY-MM-DDT")}23:59:59`,
        });
      }

      setLoading(true);
      getMyTrafficNotificationLogList({
        paging: {
          page: page,
          pageLimit: limit,
        },
        filter: filter,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
      })
        .then((response) => {
          setValue(response.data.content);
          setTotalItemsCount(response.data.totalElements);

          resolve(response);
        })
        .catch((error) => Util.showError(error))
        .finally(() => setLoading(false));
    });

  const onPage = (event: any) => {
    getData(campaignId, event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
    });
  };

  const onSort = (data: any) => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getData(campaignId, page, limit);
    }
  };

  const createdTemplate = (data: any) => {
    return (
      <div>
        {moment(Util.utcToLocal(data.created)).format(Util.usDateTimeFormat)}
      </div>
    );
  };

  const responseTsTemplate = (data: IMyTrafficNotificationLog) => {
    if (data.resolved) {
      return (
        <div>
          {moment(Util.utcToTimezone(data.resolved)).format(
            Util.usDateTimeFormat
          )}
        </div>
      );
    }
    return null;
  };

  const statusTemplate = (data: IMyTrafficNotificationLog) => {
    return <div>{STATUS_MAP[data.status]}</div>;
  };

  const onRefresh = (): void => {
    getData(campaignId, page, limit);
  };

  const statusFilter = (
    <InputText
      style={{ width: "100%" }}
      value={statusFilterValue}
      onChange={(e: any) => {
        setStatusFilterValue(e.target.value || "");
      }}
    />
  );

  const userFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setUserFilterValue(e.target.value || "");
      }}
    />
  );

  const visitorFilter = (
    <InputText
      style={{ width: "100%" }}
      value={visitorFilterValue}
      onChange={(e: any) => {
        setVisitorFilterValue(e.target.value || "");
      }}
    />
  );

  function onCreatedPeriodChange(start?: Date, end?: Date): void {
    setCreatedPeriod({
      start: moment(start).set({ hour: 0, minute: 0, second: 0 }).toDate(),
      end: moment(end).set({ hour: 23, minute: 59, second: 59 }).toDate(),
    });
  }

  const createdFilter = (
    <DateRangePickerWidget
      start={createdPeriod.start}
      end={createdPeriod.end}
      ranges={DateRangePeriods.options} // Replace 'Options' with the range definitions
      onChange={onCreatedPeriodChange}
    />
  );

  function onResponsePeriodChange(start?: Date, end?: Date): void {
    setResponsePeriod({
      start: moment(start).set({ hour: 0, minute: 0, second: 0 }).toDate(),
      end: moment(end).set({ hour: 23, minute: 59, second: 59 }).toDate(),
    });
  }

  const responseFilter = (
    <DateRangePickerWidget
      start={responsePeriod.start}
      end={responsePeriod.end}
      ranges={DateRangePeriods.options} // Replace 'Options' with the range definitions
      onChange={onResponsePeriodChange}
    />
  );

  return (
    <Dialog
      id={"report-modal"}
      visible={visible}
      onHide={onHide}
      style={{ width: "80vw" }}
      header={`Notifications History for ${campaignName}`}
    >
      <DataTable
        lazy={true}
        loading={loading}
        filterDisplay="row"
        sortMode={"multiple"}
        resizableColumns={true}
        columnResizeMode={"expand"}
        rowsPerPageOptions={Util.rowsPerPageOptions}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
        paginator={true}
        rows={limit}
        first={first}
        onPage={onPage}
        onSort={onSort}
        totalRecords={totalItemsCount}
        value={value}
        multiSortMeta={Array.from(sortMap.values())}
        header={
          <div className={"p-grid-header-components"}>
            <Button
              id={IDS.button.clear}
              icon={"pi pi-filter"}
              label={t("clear")}
              onClick={onClear}
            />
            <Button label={"Refresh"} onClick={onRefresh} />
          </div>
        }
      >
        <Column
          filter={true}
          sortable={true}
          field={"created"}
          header={"Send Time"}
          body={createdTemplate}
          style={{ overflow: "visible" }}
          filterElement={createdFilter}
        />
        <Column
          filter={true}
          sortable={true}
          field={"resolved"}
          header={"Response"}
          body={responseTsTemplate}
          style={{ overflow: "visible" }}
          filterElement={responseFilter}
        />
        <Column
          filter={true}
          sortable={true}
          field={"visitorId"}
          header={"Visitor"}
          filterElement={visitorFilter}
        />
        <Column
          filter={true}
          sortable={true}
          field={"status"}
          header={"Status"}
          body={statusTemplate}
          filterElement={statusFilter}
        />
        <Column
          filter={false}
          sortable={true}
          field={"user.email"}
          header={"User"}
          filterElement={userFilter}
        />
      </DataTable>
    </Dialog>
  );
});

export default ReportModal;
