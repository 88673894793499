import React, { useEffect, useRef } from "react";
import GlobalSpinner from "../components/global_spinner/GlobalSpinner";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setGlobalSpinner, setGrowl } from "../actions/mainActions";
import {
  authError,
  authPending,
  authSuccess,
  getUserInfo,
  logout,
  logoutSuccess,
  refreshToken,
  setDealershipContext,
  setDevice,
} from "../actions/authActions";
import { IDeviceDto, IStore } from "../../index.dts";
import Util from "../util/Util";
import { useHistory } from "react-router-dom";
import GlobalGrowl from "../components/global_growl/GlobalGrowl";
import { COOKIES } from "../util/Enums";
import Firebase from "../services/firebase/firebase";
import {
  createDevice,
  getDevice,
  revokeDeviceToken,
  updateDevice,
} from "../actions/deviceActions";
import moment from "moment-timezone";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const { detect } = require("detect-browser");

export function UserViewsWrapper(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const growlRef: any = useRef();
  const spinnerRef: any = useRef();

  const auth = useSelector((store: IStore) => store.auth.auth, shallowEqual);
  const isLoggedIn = useSelector(
    (store: IStore) => store.auth.isLoggedIn,
    shallowEqual
  );

  useEffect(() => {
    dispatch(setGrowl(growlRef.current));
    dispatch(setGlobalSpinner(spinnerRef.current));
  }, [dispatch]);

  useEffect(() => {
    if (
      history.location.pathname.startsWith("/user/activate") ||
      history.location.pathname.startsWith("/user/reset-password") ||
      history.location.pathname.startsWith("/offer-certificate/") ||
      history.location.pathname.startsWith("/offer-form/")
    ) {
      logout().finally(() => dispatch(logoutSuccess()));
      return;
    }

    authorize()
      .then((response: any) => {
        getUserDetails(response);
      })
      .catch(() => {
        dispatch(authError());
        history.push(Util.PATH_NAMES.LOGIN);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      pushNotificationsSubscribe();
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const pushNotificationsSubscribe = (): void => {
    Firebase.subscribe()
      .then((token: any) => {
        registerUserDevice(token);
      })
      .catch((error) => {
        registerUserDevice(null);
      });
  };

  const registerUserDevice = async (token: string | null) => {
    const deviceId: string = await getDeviceId();

    getDevice(deviceId, token)
      .then((response) => {
        const data = Util._.get(response, "data", {});

        if (checkDeviceForUpdate(data, token)) {
          updateUserDevice(data.id, token);
        } else if (Util.isEmpty(token)) {
          revokeDeviceToken(data.id);
        }

        if (deviceId !== data.deviceId) {
          Util.Cookie.set(COOKIES.UUID, data.deviceId, {
            expires: moment().add(10, "years").toDate(),
          });
        }

        setContext(response.data);
      })
      .catch((error) => {
        const message = Util.getDefaultIfNull(
          Util._.get(error, "response.data.message"),
          ""
        ).toLowerCase();

        if (
          message === `user device with id: ${deviceId} not found.` ||
          error === undefined
        ) {
          createUserDevice(deviceId, token);
        } else {
          Util.showError(error);
        }
      });
  };

  const checkDeviceForUpdate = (
    data: IDeviceDto,
    token: string | null
  ): boolean => {
    const browser = detect();

    if (!Util.isEmpty(token) && token !== data.token) {
      return true;
    }

    return browser.version !== data.osVersion;
  };

  const updateUserDevice = (id: number, token: string | null): void => {
    const browser = detect();

    updateDevice(id, {
      token: token,
      tz: moment.tz.guess(),
      os: `${browser.os} ${browser.name} (${browser.version})`,
      osVersion: browser.version,
    });
  };

  const createUserDevice = (deviceId: string, token: string | null): void => {
    const browser = detect();

    createDevice({
      deviceId: deviceId,
      token: token,
      tz: moment.tz.guess(),
      os: `${browser.os} ${browser.name} (${browser.version})`,
      osVersion: browser.version,
    })
      .then((response) => {
        setContext(response.data);
      })
      .catch((error) => {
        Util.showError(error);
      });
  };

  const setContext = (data: IDeviceDto): void => {
    const dealership = data.dealership;

    const id = Util._.get(data, "id", 0);
    const token = Util._.get(data, "token", 0);
    const deviceId = Util._.get(data, "deviceId", 0);

    dispatch(
      setDealershipContext(
        Util.getDealershipContextFromResponseData(dealership)
      )
    );

    dispatch(
      setDevice({
        id: id,
        deviceId: deviceId,
        token: token,
      })
    );
  };

  const getDeviceId = (): Promise<string> =>
    new Promise(async (resolve) => {
      let uniqueId: string = Util.getDefaultIfNull(
        Util.Cookie.get(COOKIES.UUID),
        ""
      );

      if (!Util.isEmpty(uniqueId)) {
        resolve(uniqueId);
        return;
      }

      const fpPromise = FingerprintJS.load();

      const fp = await fpPromise;
      const result = await fp.get();

      uniqueId = result.visitorId;
      Util.Cookie.set(COOKIES.UUID, uniqueId, {
        expires: moment().add(10, "years").toDate(),
      });

      resolve(uniqueId);
    });

  const getUserDetails = (auth: any) => {
    Util.globalSpinner().show();
    getUserInfo(auth.access_token)
      .then((res) => {
        onAuthSuccess(auth, res.data);
      })
      .catch((error) => {
        Util.showError(error);
        dispatch(authError());
        history.push(Util.PATH_NAMES.LOGIN);
      })
      .finally(() => {
        Util.globalSpinner().hide();
      });
  };

  const onAuthSuccess = (auth: any, user: any): void => {
    dispatch(authSuccess({ auth: auth, user: user }));
    if (history.location.pathname.startsWith(Util.PATH_NAMES.LOGIN)) {
      history.push(Util.PATH_NAMES.DASHBOARD);
    }
  };

  const authorize = () =>
    new Promise((resolve, reject) => {
      if (isLoggedIn) {
        resolve(auth);
        return;
      }

      const cookieAuth = Util.Cookie.get(COOKIES.AUTH);

      if (cookieAuth) {
        resolve(JSON.parse(cookieAuth));
        return;
      }

      const refresh_token = Util.Cookie.get(COOKIES.REFRESH_TOKEN);

      if (refresh_token) {
        getRefreshToken(refresh_token)
          .then((response) => {
            resolve(response);
          })
          .catch(() => {
            reject();
          });

        return;
      }

      reject();
    });

  const getRefreshToken = (refresh_token: string) =>
    new Promise((resolve, reject) => {
      dispatch(authPending());
      spinnerRef.current.show(600);
      refreshToken(refresh_token)
        .then((response) => {
          spinnerRef.current.hide(600);

          response.data.expires_in = new Date(
            Date.now() + response.data.expires_in
          );

          resolve({ ...response.data });
        })
        .catch(() => {
          spinnerRef.current.hide(600);

          reject();
        });
    });

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {props.children}
      <GlobalGrowl ref={growlRef} />
      <GlobalSpinner ref={spinnerRef} />
    </div>
  );
}

export default UserViewsWrapper;
