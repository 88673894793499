import React, { useEffect, useState } from "react";
import { IGroupDealership, IIdNameDto } from "../../../index.dts";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  brandsBody,
  enterpriseGroupsBody,
} from "./enterprise_group/EnterpriseGroupForm";
import Util from "../../util/Util";
import { IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import { confirmAlert } from "react-confirm-alert";
import { InputText } from "primereact/inputtext";
import { getSelectedGroupDealerships } from "../../actions/rtsGroupActions";

interface IProps {
  value: IGroupDealership[];

  onRemove(value: IGroupDealership[]): void;
}

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

const GroupDealerships = (props: IProps) => {
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [sortMap] = useState<Map<string, { field: string; order: 1 | 0 | -1 }>>(
    new Map()
  );
  const [selected, setSelected] = useState<IGroupDealership[]>([]);
  const [nameFilterValue, setNameFilterValue] = useState<string>("");
  const [addressFilterValue, setAddressFilterValue] = useState<string>("");
  const [brandsFilterValue, setBrandsFilterValue] = useState<string>("");
  const [enterpriseGroupsFilterValue, setEnterpriseGroupsValue] =
    useState<string>("");
  // const [total, setTotal] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<IGroupDealership[]>(props.value);

  useEffect(() => {
    sortMap.set("created", { field: "created", order: -1 });
  }, []);

  const getData = (page: number, limit: number): Promise<any> =>
    new Promise((resolve) => {
      getSelectedGroupDealerships({
        name: nameFilterValue,
        address: addressFilterValue,
        brandName: brandsFilterValue,
        egName: enterpriseGroupsFilterValue,
        dealerships: props.value,
        pageLimit: limit,
        pageNumber: page,
        sorting: [],
        // Array.from(sortMap.values()).map((item) => {
        //   return {
        //     field: item.field,
        //     direction: item.order === 1 ? "asc" : "desc",
        //   };
        // }),
      })
        .then((response) => {
          setValue(response.data.content);

          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const onPage = (event: any) => {
    getData(event.page, event.rows);
    setLimit(event.rows);
    setFirst(event.first);
  };

  const onSort = (event: any) => {
    sortMap.clear();
    if (event && Array.isArray(event.multiSortMeta)) {
      event.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getData(event.page, limit);
    }
  };

  useEffect(() => {
    if (props.value?.length !== 0) {
      getData(0, limit);
    }
  }, []);

  useEffect(() => {
    searchTimeout = setTimeout(() => {
      if (props.value?.length !== 0) {
        getData(0, limit);
      }
    }, searchTimeoutInterval);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [
    nameFilterValue,
    addressFilterValue,
    brandsFilterValue,
    enterpriseGroupsFilterValue,
  ]);

  const onRemove = (): void => {
    if (selected.length === 0) {
      return;
    }

    confirmAlert({
      title: `You are about to remove ${selected.length} dealership${
        selected.length > 1 ? "s" : ""
      }!`,
      message: "Are you sure you want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            props.onRemove(selected);
            setSelected([]);
          },
          className: "confirm-save-btn",
        },
        {
          label: "Cancel",
          className: "confirm-cancel-btn",
          onClick: () => {},
        },
      ],
    });
  };

  const multipleFilterFunction = (data: IIdNameDto[], filter: any): boolean => {
    return data.some((item) =>
      item?.name?.toLowerCase()?.includes(filter?.toLowerCase())
    );
  };

  const nameFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) =>
        setNameFilterValue(Util.getDefaultIfNull(e.target.value, undefined))
      }
    />
  );

  const addressFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) =>
        setAddressFilterValue(Util.getDefaultIfNull(e.target.value, undefined))
      }
    />
  );

  const brandsFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) =>
        setBrandsFilterValue(Util.getDefaultIfNull(e.target.value, undefined))
      }
    />
  );

  const enterpriseGroupsFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) =>
        setEnterpriseGroupsValue(
          Util.getDefaultIfNull(e.target.value, undefined)
        )
      }
    />
  );

  const render = (): React.ReactElement => {
    return (
      <div
        className={
          limit > 10
            ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows dealership-users"
            : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows dealership-users-ten"
        }
      >
        <DataTable
          value={value.length > 0 ? value : props?.value.slice(0, limit)}
          lazy={true}
          filterDisplay="row"
          autoLayout={true}
          resizableColumns={true}
          columnResizeMode={"expand"}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
          paginator={true}
          sortMode={"multiple"}
          rows={limit}
          first={first}
          loading={loading}
          totalRecords={props?.value?.length}
          onPage={onPage}
          selection={selected}
          scrollable={true}
          onSelectionChange={(e) => setSelected(e.value)}
          // onSort={onSort}
          // multiSortMeta={Array.from(sortMap.values())}
          header={
            <div style={{ height: 35 }}>
              <label>Group Dealerships</label>
              <div style={{ float: "right" }}>
                <IconButton
                  size={"small"}
                  onClick={onRemove}
                  disabled={selected.length === 0}
                  style={{
                    backgroundColor: "#FF0000FF",
                    color: "#FFFFFF",
                    borderRadius: 4,
                    opacity: selected.length === 0 ? 0.3 : 1,
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            </div>
          }
        >
          <Column selectionMode="multiple" style={{ width: "3em" }} />
          <Column
            filter={true}
            sortable={false}
            field={"name"}
            header={"Name"}
            sortField={"name"}
            filterElement={nameFilter}
          />
          <Column
            filter={true}
            sortable={false}
            field={"address"}
            header={"Address"}
            sortField={"address"}
            filterElement={addressFilter}
          />
          <Column
            filter={true}
            sortable={false}
            field={"brands"}
            sortField={"brands"}
            header={"Brands"}
            body={brandsBody}
            filterElement={brandsFilter}
          />
          <Column
            filter={true}
            sortable={false}
            field={"rtsEnterpriseGroups"}
            sortField={"rtsEnterpriseGroups"}
            header={"Enterprise Groups"}
            body={enterpriseGroupsBody}
            filterElement={enterpriseGroupsFilter}
          />
        </DataTable>
      </div>
    );
  };

  return render();
};

export default GroupDealerships;
