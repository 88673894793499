import "./smart_spend_chart.css";

import {
  IChannelBuckets,
  IChartDataset,
  ISmartSpendData,
} from "../../../index.dts";

import { AverageEngagementScoreDataset } from "../../util/chart/dataset/average_engagement_score_dataset";
import { Line } from "react-chartjs-2";
import { ChartConfig } from "../../util/chart/chart_config";
import { IChartAnnotation } from "../../util/chart/annotation/chart_annotation";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Util from "../../util/Util";
import { CategoryScale, Chart, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
Chart.register(CategoryScale);
Chart.register(...registerables);
Chart.register(annotationPlugin);

require("chartjs-plugin-annotation");

interface IProps {
  readonly selectedAll?: boolean;
  readonly annotations?: IChartAnnotation[];
  readonly data?: ISmartSpendData;
  readonly datasets: IChartDataset[];
  readonly channelBuckets?: IChannelBuckets;
  readonly scale: {
    id: string;
    label: string;
  };
}

export function SmartSpendChart(props: IProps) {
  const lineElement = useRef<any>();
  const { t } = useTranslation();

  const [total, setTotal] = useState<number>(0);

  function getAverageEngagementScoreDataset(): IChartDataset {
    return new AverageEngagementScoreDataset({
      label: t("averageEngagementScore"),
      data: props.data?.avgEngScore,
    });
  }

  useEffect(() => {
    if (props.selectedAll === false) {
      let ci = lineElement.current.legend.chart;
      props.datasets.map((element, index) => {
        [ci.getDatasetMeta(0), ci.getDatasetMeta(index + 1)].forEach(function (
          meta
        ) {
          if (meta) {
            meta.hidden =
              meta.hidden === null || meta.hidden === false
                ? !ci.data.datasets[index].hidden
                : meta.hidden === true
                ? true
                : null;
          }
        });
      });
      ci.update();
    } else if (props.selectedAll === true) {
      let ci = lineElement.current.legend.chart;
      props.datasets.map((element, index) => {
        [ci.getDatasetMeta(0), ci.getDatasetMeta(index + 1)].forEach(function (
          meta
        ) {
          if (meta) {
            meta.hidden = false;
          }
        });
      });
      ci.update();
    }
  }, [props.selectedAll]);

  const generateLabels = (chart: any) => {
    let total = 0;

    const datasets: any[] = Util._.get(chart, "data.datasets", []);

    const array = datasets.map(function (dataset: any, i: number) {
      let sum = Util._.sum(dataset.data);

      if (chart.isDatasetVisible(i)) {
        if (
          [t("averageEngagementScore"), t("total")].findIndex(
            (item) => item === dataset.label
          ) === -1
        ) {
          total += sum;
        }
      }

      return {
        text:
          dataset.label === t("averageEngagementScore")
            ? dataset.label
            : `${dataset.label} (${sum})`,
        fillStyle: dataset.backgroundColor,
        hidden: !chart.isDatasetVisible(i),
        lineCap: dataset.borderCapStyle,
        lineDash: dataset.borderDash,
        lineDashOffset: dataset.borderDashOffset,
        lineJoin: dataset.borderJoinStyle,
        lineWidth: dataset.borderWidth,
        strokeStyle: dataset.borderColor,
        pointStyle: dataset.pointStyle,

        // Below is extra data used for toggling the datasets
        datasetIndex: i,
      };
    });

    setTotal(total);

    return array;
  };

  const padTooltipLabel = (label: string): any => {
    return Util._.padStart(label, 0);
  };

  const padTooltipValue = (value?: number) => {
    return Util._.padStart(value, 0);
  };

  const findDataIndex = (xAxis: string, labels: string[]): number => {
    return labels?.findIndex((item) => item === xAxis);
  };

  const tooltipCallbacks = (buckets?: IChannelBuckets): any => {
    return {
      beforeFooter: function (tooltipItem: any, object: any) {
        if (!buckets) {
          return;
        }

        const item = Util._.first(tooltipItem);
        const dataIndex = item?.dataIndex; //findDataIndex(item?.xLabel, object?.labels);

        const label = "Total Above A.E.S.: ";
        const paddedLabel = padTooltipLabel(label);
        const paddedValue = padTooltipValue(buckets.total?.high[dataIndex]);

        return !Number.isNaN(paddedValue) &&
          props?.scale?.id !== ChartConfig.pageViews.id
          ? `${paddedLabel}${paddedValue}`
          : "";
      },
      footer: function (tooltipItem: any, object: any) {
        if (!buckets) {
          return;
        }

        const item = Util._.first(tooltipItem);
        const dataIndex = item?.dataIndex; //findDataIndex(item?.xLabel, object?.labels);

        const label = "Total Below A.E.S.: ";
        const paddedLabel = padTooltipLabel(label);
        const paddedValue = padTooltipValue(buckets.total?.low[dataIndex]);

        return !Number.isNaN(paddedValue) &&
          props?.scale?.id !== ChartConfig.pageViews.id
          ? `${paddedLabel}${paddedValue}`
          : "";
      },
    };
  };

  return (
    <div className={"smart-spend-chart"}>
      <label style={{ float: "right" }}>Total: {total}</label>
      <Line
        ref={lineElement}
        data={{
          datasets: [getAverageEngagementScoreDataset(), ...props.datasets],
          labels: props.data?.dates,
        }}
        height={80}
        options={{
          // events: ["click", "mousemove", "mouseout"],
          plugins: {
            annotation: {
              annotations: props.annotations ?? [],
            },
            legend: {
              position: "top",
              labels: {
                generateLabels,
              },
            },
            tooltip: {
              mode: "index",
              intersect: false,
              callbacks: tooltipCallbacks(props.channelBuckets),
            },
          },
          // responsive: true,
          scales: {
            days: {
              title: {
                display: true,
              },
              position: "bottom",
              // id: "days",
            },
            [ChartConfig.averageScore.id]: {
              position: "left",
              title: {
                display: true,
                text: t("averageEngagementScore"),
              },
              // id: ChartConfig.averageScore.id,
              suggestedMin: 0,
              suggestedMax: 100,
              type: "linear",
            },
            [props.scale.id]: {
              // id: props.scale.id,
              position: "right",
              title: {
                display: true,
                text: props.scale.label,
              },
              suggestedMin: 0,
              type: "linear",
            },
          },
        }}
        // type="line"
      />
    </div>
  );
}
