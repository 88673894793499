import Axios from "../config/Axios";
import {
  AxiosResponse,
  ConfigurationProvider,
  ConfigurationProviderDto,
  IBaseDealershipDto,
  IDealership,
  IDealershipDto,
  IPageable,
  ISearchCriteria,
  ISearchRequestDto,
  ITagImplementationDto,
} from "../../index.dts";

export const createDealership = (dealership: IDealershipDto) =>
  Axios.instance.post("dealerships", dealership);

export const updateDealership = (id: number, dealership: IDealershipDto) =>
  Axios.instance.put(`dealerships/${id}`, dealership);

export const createDealershipFbImageFile = (
  dealershipId: number,
  file: FormData
) => Axios.instance.post(`dealerships/${dealershipId}/fb-image-file`, file);

export const createDealershipLogoImageFile = (
  dealershipId: number,
  file: FormData
) =>
  Axios.instance.post(
    `dealerships/${dealershipId}/dealership-logo-image-file`,
    file
  );

export const deactivateDealerships = (ids: number[]) =>
  Axios.instance.put(`dealerships/deactivate`, ids);

export const activateDealerships = (ids: number[]) =>
  Axios.instance.put(`dealerships/activate`, ids);

export const getAllDealerships = (searchCriteria: ISearchCriteria) =>
  Axios.instance.post(`dealerships/list`, searchCriteria);

export const getMyDealerships = (
  searchCriteria: ISearchRequestDto
): Promise<IPageable<IBaseDealershipDto>> =>
  Axios.instance.post("dealerships/list/page", searchCriteria);

export const getAssignedUsers = (dealershipId: number) =>
  Axios.instance.get(`dealerships/${dealershipId}/users`);

export const assignUsers = (dealershipId: number, userIds: number[]) =>
  Axios.instance.post(`dealerships/${dealershipId}/users/assign`, { userIds });

export const removeDealershipUsers = (
  dealershipId: number,
  userIds: number[]
) =>
  Axios.instance.post(`dealerships/${dealershipId}/users/remove`, { userIds });

export const getDealership = (
  dealershipId: number
): Promise<AxiosResponse<IDealership>> =>
  Axios.instance.get(`dealerships/${dealershipId}`);

export const getDealershipAndEnterpriseGroups = (dealershipId: number) =>
  Axios.all([
    Axios.instance.get(`dealerships/${dealershipId}`),
    Axios.instance.get(`rts/enterprise`),
  ]);

export const sendTestLeadReceipt = (dealershipId: number, emails: string) =>
  Axios.instance.post(`dealerships/${dealershipId}/test-lead-receipt`, {
    emails: emails,
  });

export const sendTagImplementationEmail = (
  dealershipId: number,
  dto: ITagImplementationDto
) => Axios.instance.post(`dealerships/${dealershipId}/tag-implementation`, dto);

export const addPersistentChatAvatar = (dealershipId: number, file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  return Axios.instance.put(
    `dealerships/${dealershipId}/persistent-chat/avatar`,
    formData
  );
};

export const getUnassignedDealerships = (
  userId: number,
  searchRequest: ISearchRequestDto
): Promise<IPageable<IBaseDealershipDto>> =>
  Axios.instance.post(
    `dealerships/users/${userId}/unassigned/list`,
    searchRequest
  );

export const getAllConfigurationProviders = (): Promise<
  AxiosResponse<ConfigurationProvider[]>
> => Axios.instance.get(`provider/all`);

export const saveConfigurationProvider = (
  dto: ConfigurationProviderDto,
  dealershipId
): any =>
  Axios.instance.post(`dealerships/${dealershipId}/provider-config`, dto);

export const updateConfigurationProvider = (
  dto: ConfigurationProviderDto,
  dealershipId
): any =>
  Axios.instance.put(`dealerships/${dealershipId}/provider-config`, dto);

export const getConfigurationProviderInfo = (
  providerType: string,
  dealershipId
): any =>
  Axios.instance.get(
    `dealerships/${dealershipId}/provider-config?type=${providerType}`
  );
