import { INPUT_TAGS_TYPES } from "./index";
import Util from "../Util";
import { join, map, head } from "lodash";

export default class TagsInputUtil {
  public static onValidationReject = (
    tags: string[],
    type: INPUT_TAGS_TYPES
  ) => {
    switch (type) {
      case INPUT_TAGS_TYPES.URL:
        Util.warning(`"${tags[0]}" is not a valid URL address.`);
        break;
      case INPUT_TAGS_TYPES.EMAIL:
        Util.warning(`"${tags[0]}" is not a valid e-mail address.`);
        break;
      case INPUT_TAGS_TYPES.IP_ADDRESS:
        Util.warning(`"${tags[0]}" is not a valid IP address.`);
        break;
      case INPUT_TAGS_TYPES.YEAR:
        Util.warning(`"${tags[0]}" is not a valid year.`);
        break;
      case INPUT_TAGS_TYPES.NUMBER:
        Util.warning(`"${tags[0]}" is not a valid number.`);
        break;
      case INPUT_TAGS_TYPES.USER_IDS:
        Util.warning(`"${tags[0]}" is not a valid user ID.`);
        break;
      case INPUT_TAGS_TYPES.HOUR_24_FORMAT:
        Util.warning(`"${tags[0]}" is not a valid hour in 24H format.`);
        break;
    }
  };

  public static join = (value?: string[]): string => {
    return join(
      value?.map((item) => item?.trim()),
      ","
    );
  };

  public static joinWithUpperCase = (value?: string[]): string => {
    return join(
      value?.map((item) => item?.trim().toUpperCase()),
      ","
    );
  };

  public static trimValues = (value?: string[]) => {
    return map(value, (item) => item?.trim());
  };

  public static setUniqueValue = (value?: string[]): string => {
    return value?.pop() ?? "";
  };
}
