import "./makes_select.css";

import { chain, map } from "lodash";

import React from "react";
import Select from "react-select";
import { StringSelectOption } from "../../../util/string_select_option";
import { useTranslation } from "react-i18next";
import Util from "../../../util/Util";

interface IProps {
  onChange: (update: string[]) => void;
  options: string[];
  selected: string[];
  maxMakes?: number;
}

export function MakesSelect(props: IProps) {
  const { t }: any = useTranslation();

  function getOptions(): StringSelectOption[] {
    return chain(props.options)
      .map((option) => new StringSelectOption(option))
      .orderBy("label")
      .value();
  }

  function onChange(values: any): void {
    if (props.maxMakes && values?.length > props.maxMakes) {
      Util.warning("You can select a maximum of 3 Makes");
      return;
    }

    props.onChange(map(values, (item: any) => item.value));
  }

  return (
    <div className={"makes-select-container"}>
      <Select
        backspaceRemovesValue={false}
        //cacheOptions={true}
        className={"makes-select"}
        closeMenuOnSelect={false}
        //defaultOptions={true}
        escapeClearsValue={false}
        //filter={true}
        isClearable={true}
        isMulti={true}
        onChange={onChange}
        options={getOptions()}
        placeholder={t("selectYears")}
        value={props.selected.map((select) => new StringSelectOption(select))}
      />
    </div>
  );
}
