import React, { useEffect, useState } from "react";
import "./my-campaigns.css";
import Util from "../../util/Util";
import { CAMPAIGN_TYPES, MODE, PERMISSIONS } from "../../util/Enums";
import { Button } from "primereact/button";
import {
  CampaignDto,
  ICampaignInfo,
  IOfferCompact,
  IStore,
} from "../../../index.dts";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { confirmAlert } from "react-confirm-alert";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import { IDS } from "../constants";
import {
  deleteMyCampaign,
  getMyCampaignsList,
  updateMyCampaign,
  getAllActiveCampaigns,
  editRanksOfMyCampaign,
} from "../../actions/campaignsActions";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { get } from "lodash";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import { getOfferList } from "../../actions/offerActions";

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

export function MyCampaigns() {
  const location = useLocation();
  const { t }: any = useTranslation();
  const history = useHistory();
  const forceUpdate = useForceUpdate();
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [page, setPage] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [campaignList, setCampaignList] = useState<ICampaignInfo[]>([]);
  const [nameFilter, setNameFilter] = useState<string>("");
  const [typeFilter, setTypeFilter] = useState<string>();
  const [offerFilter, setOfferFilter] = useState<string>("");
  const [greetingFilter, setGreetingFilter] = useState<string>("");
  const [notificationFilter, setNotificationFilter] = useState<string>("");
  const [semVendorFilter, setSemVendorFilter] = useState<string>("");
  const [createdDateFilter, setCreatedDateFilter] = useState<string>("");
  const [startDateFilterValue, setStartDateFilterValue] = useState<string>("");
  const [endDateFilterValue, setEndDateFilterValue] = useState<string>("");
  const [rankClicked, setRankClicked] = useState<boolean>(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState<ICampaignInfo[]>(
    []
  );
  const [sortMap] = useState<Map<string, { field: string; order: 1 | 0 | -1 }>>(
    new Map()
  );
  const [activeOfferList, setActiveOfferList] = useState<IOfferCompact[]>([]);
  const [activeCampaignList, setActiveCampaignList] = useState<CampaignDto[]>(
    []
  );
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  const [filterDealership, setFilterDealership] = useState<string>(
    dealershipContext.id !== 0 ? dealershipContext.name : ""
  );
  const globalSpinner = useSelector(
    (store: IStore) => store.myTraffic.globalSpinner,
    shallowEqual
  );

  const onClear = () => {
    setNameFilter("");
    setTypeFilter(undefined);
    setOfferFilter("");
    setGreetingFilter("");
    setNotificationFilter("");
    setSemVendorFilter("");
    setStartDateFilterValue("");
    setEndDateFilterValue("");
    setCreatedDateFilter("");
    sortMap.clear();
    forceUpdate();
  };

  const getOffers = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      getOfferList({
        pageLimit: limit,
        pageNumber: page,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
        name: undefined,
        templateName: undefined,
        dealershipName: dealershipContext.name,
        crmSourceName: undefined,
        offerType: undefined,
        toDate: undefined,
        fromDate: undefined,
        startDate: undefined,
        endDate: undefined,
      })
        .then((response) => {
          resolve(response);
          setActiveOfferList(
            response.data.content.filter((offer) => offer?.active == true)
          );
        })
        .catch((error) => {
          reject(error);
          Util.showError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });

  useEffect(() => {
    sortMap.set("created", { field: "created", order: -1 });
    getOffers(0, limit);

    getAllActiveCampaigns(dealershipContext?.id).then((response) => {
      setActiveCampaignList(response.data);
    });
  }, []);

  useEffect(() => {
    if (location?.state?.newCampaign == true) {
      getAllActiveCampaigns(dealershipContext?.id).then((response) => {
        if (response && response?.data?.length > 1) {
          setRankClicked(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (filterDealership !== dealershipContext.name)
      setFilterDealership(
        dealershipContext.id !== 0 ? dealershipContext.name : ""
      );
    // eslint-disable-next-line
  }, [dealershipContext]);

  useEffect(() => {
    searchTimeout = setTimeout(() => {
      getCampaigns(0, limit).finally(() => {
        setPage(0);
        setFirst(0);
        setSelectedCampaigns([]);
        searchTimeoutInterval = 500;
      });
    }, searchTimeoutInterval);

    return () => {
      clearTimeout(searchTimeout);
    };
    // eslint-disable-next-line
  }, [
    nameFilter,
    typeFilter,
    offerFilter,
    greetingFilter,
    notificationFilter,
    createdDateFilter,
    semVendorFilter,
    startDateFilterValue,
    endDateFilterValue,
    dealershipContext?.id,
  ]);

  const onStartDateChange = (e: any): void => {
    if (e.value) {
      setStartDateFilterValue(
        moment(get(e, "value")).format(Util.localDateFormat)
      );
    } else {
      setStartDateFilterValue("");
    }
  };

  const onEndDateChange = (e: any): void => {
    if (e.value) {
      setEndDateFilterValue(
        moment(get(e, "value")).format(Util.localDateFormat)
      );
    } else {
      setEndDateFilterValue("");
    }
  };

  const getCalendarValue = (value?: string): Date | undefined => {
    if (!value) {
      return;
    }

    return moment(value).toDate();
  };

  const onCreatedDateChange = (e: any): void => {
    if (e.value) {
      setCreatedDateFilter(
        moment(get(e, "value")).format(Util.localDateFormat)
      );
    } else {
      setCreatedDateFilter("");
    }
  };

  const typeFilterTemplate = (
    <Dropdown
      showClear={true}
      value={typeFilter}
      options={Object.entries(CAMPAIGN_TYPES).map((entry) => {
        return {
          label: entry[0],
          value: entry[1],
        };
      })}
      style={{ width: "100%", boxShadow: "none", backgroundColor: "#FFFFFF" }}
      onChange={(e: any) => {
        setTypeFilter(e.target.value);
      }}
    />
  );

  const createdFilterTemplate = (
    <Calendar
      style={{ width: "100%" }}
      inputId={"startDateInput"}
      inputStyle={{ width: "100%" }}
      value={getCalendarValue(createdDateFilter)}
      onChange={onCreatedDateChange}
    />
  );

  const startDateFilter = (
    <Calendar
      style={{ width: "100%" }}
      inputId={"startDateInput"}
      inputStyle={{ width: "100%" }}
      value={getCalendarValue(startDateFilterValue)}
      onChange={onStartDateChange}
    />
  );

  const endDateFilter = (
    <Calendar
      style={{ width: "100%" }}
      inputId={"endDateInput"}
      inputStyle={{ width: "100%" }}
      value={getCalendarValue(endDateFilterValue)}
      onChange={onEndDateChange}
    />
  );

  const getCampaigns = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      getMyCampaignsList({
        pageLimit: limit,
        pageNumber: page,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
        name: nameFilter ? nameFilter : undefined,
        type: typeFilter ? typeFilter : undefined,
        offerName: offerFilter ? offerFilter : undefined,
        greetingName: greetingFilter ? greetingFilter : undefined,
        notificationName: notificationFilter ? notificationFilter : undefined,
        semVendorName: semVendorFilter ? semVendorFilter : undefined,
        dealershipId: dealershipContext?.id,
        startDate: startDateFilterValue
          ? moment(startDateFilterValue).format(Util.localDateTimeFormat)
          : undefined,
        endDate: endDateFilterValue
          ? moment(endDateFilterValue).format(Util.localDateTimeFormat)
          : undefined,
        createdDate: createdDateFilter
          ? moment(createdDateFilter).format(Util.localDateTimeFormat)
          : undefined,
      })
        .then((response) => {
          resolve(response);
          const reformattedContent: ICampaignInfo[] =
            response?.data?.content?.map((campaign) => {
              return {
                active: campaign?.active,
                filters: campaign?.vehicleFilters,
                knownVisitor: campaign?.knownVisitor,
                name: campaign?.name,
                sources: campaign?.semVendors,
                greeting: campaign?.chatGreeting,
                id: campaign?.id,
                notifications: campaign?.rtsNotifications,
                offer: campaign?.offer,
                created: campaign?.created,
                startDate: campaign?.startDate,
                endDate: campaign?.endDate,
                expirable: campaign?.expirable,
                rank: campaign?.rank,
                automaticallyDisabled: campaign?.automaticallyDisabled,
              };
            });
          setCampaignList(reformattedContent);
          setTotalItemsCount(response.data.totalElements);
        })
        .catch((error) => {
          reject(error);
          Util.showError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const onSaveRankMenu = async () => {
    await editRanksOfMyCampaign(activeCampaignList).then((response) => {
      Util.success("Campaigns ranked successfully.");
    });
    setRankClicked(false);
    if (location?.state?.newCampaign) {
      history?.push("/my-campaigns", { newCampaign: false });
    }
    sortMap.clear();
    sortMap.set("rank", { field: "rank", order: 1 });
    getCampaigns(page, limit);
  };
  const onShowRankMenu = () => {
    setRankClicked(true);
  };

  const onHideRankMenu = () => {
    setRankClicked(false);
    if (location?.state?.newCampaign) {
      history?.push("/my-campaigns", { newCampaign: false });
    }
  };

  const onRowReorder = (e) => {
    let reorderedRows: CampaignDto[] = [];
    e?.value?.map((activeCampaign, index) => {
      if (activeCampaign) {
        let reorderdCampaign = {
          ...activeCampaign,
          rank: index + 1,
        };
        reorderedRows.push(reorderdCampaign);
      }
    });
    setActiveCampaignList(reorderedRows);
  };

  const onNew = () => {
    history.push(Util.PATH_NAMES.MY_CAMPAIGNS_CREATE, { mode: MODE.NEW });
  };

  const onEdit = () => {
    if (selectedCampaigns.length === 1) {
      history.push(Util.PATH_NAMES.MY_CAMPAIGNS_EDIT, {
        mode: MODE.EDIT,
        campaignId: selectedCampaigns[0]?.id,
      });
    } else {
      Util.warning("Please choose only one campaign.");
    }
  };

  const onDuplicate = () => {
    if (selectedCampaigns.length === 1) {
      history.push(Util.PATH_NAMES.MY_CAMPAIGNS_DUPLICATE, {
        mode: MODE.DUPLICATE,
        campaignId: selectedCampaigns[0]?.id,
      });
    } else {
      Util.warning("Please choose only one campaign.");
    }
  };

  const onDelete = () => {
    if (selectedCampaigns.length > 0) {
      confirmAlert({
        title: `You are about to delete ${selectedCampaigns.length} campaign${
          selectedCampaigns.length > 1 ? "s" : ""
        }!`,
        message: "Are you sure you want to do this?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              globalSpinner.show();
              deleteMyCampaign(selectedCampaigns[0]?.id as number)
                .then(() => {
                  selectedCampaigns.forEach((campaign) => {
                    const index = campaignList.findIndex(
                      (o) => o.id === campaign.id
                    );
                    if (index > -1) {
                      campaignList.splice(index, 1);
                    }
                  });
                  setSelectedCampaigns([]);
                  Util.success("Campaign deleted successfully.");
                })
                .catch((error: any) => {
                  Util.showError(error);
                })
                .finally(() => {
                  globalSpinner.hide();
                });
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    } else {
      Util.warning("Please choose at least one campaign.");
    }
  };

  const onPage = (event: any) => {
    getCampaigns(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
      setSelectedCampaigns([]);
    });
  };

  const onSort = (data: any) => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getCampaigns(page, limit);
    }
  };

  const dateBody = (data: any, field: string, formatter: string) => {
    const date = moment(data[field]);
    if (date.isValid()) {
      return date.format(formatter);
    }
    return null;
  };

  const dateBodyCreated = (data: any, field: string, formatter: string) => {
    const date = moment(Util.utcToLocal(data[field]));
    if (date.isValid()) {
      return date.format(formatter);
    }
    return null;
  };

  const activeTemplate = (data: ICampaignInfo) => {
    return (
      <InputSwitch
        onChange={() => onActiveChange(data)}
        checked={data.active}
      />
    );
  };

  const knownVisitorTemplate = (data: ICampaignInfo) => {
    return (
      <InputSwitch
        tooltip={
          data.knownVisitor
            ? "'Returning' visitor excluded"
            : "All visitors included"
        }
        tooltipOptions={{ className: "yellow-tooltip", position: "top" }}
        onChange={() => onActiveKnownVisitorChange(data)}
        checked={data.knownVisitor}
      />
    );
  };

  const onActiveChange = async (data: ICampaignInfo) => {
    data.active = !data.active;
    await updateMyCampaign(data?.id as number, { active: data?.active })
      .then(() => {
        getAllActiveCampaigns(dealershipContext?.id).then((response) => {
          data.active && setRankClicked(true);
          setActiveCampaignList(response.data);
        });

        Util.success(
          `Campaign ${data.active ? "activated" : "deactivated"} successfully.`
        );
      })
      .catch((error: any) => {
        Util.showError(error);
        data.active = !data.active;
        forceUpdate();
      });
    forceUpdate();
    sortMap.clear();
    sortMap.set("rank", { field: "rank", order: 1 });
    getCampaigns(page, limit);
  };

  const onActiveKnownVisitorChange = async (data: ICampaignInfo) => {
    data.knownVisitor = !data.knownVisitor;
    await updateMyCampaign(data?.id as number, {
      knownVisitor: data?.knownVisitor,
    })
      .then(() => {
        Util.success(
          `Campaign ${
            data.knownVisitor
              ? "known visitor activated"
              : " known visitor deactivated"
          } successfully.`
        );
      })
      .catch((error: any) => {
        Util.showError(error);
        data.knownVisitor = !data.knownVisitor;
        forceUpdate();
      });
    forceUpdate();
  };

  const content = (): React.ReactElement => {
    if (!dealershipContext.myCampaignsEnabled) {
      return <div style={{ color: "red" }}>{t("myCampaignsIsDisabled")}</div>;
    }

    return (
      <div id={IDS.offers.wrapper}>
        <div className={"p-grid"}>
          <div className="p-col-12">
            <div className="card card-w-title datatable-demo">
              <div
                className={
                  "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows my-campaigns"
                }
              >
                <DataTable
                  // tableStyle={{ overflow: "visible" }}
                  scrollable
                  scrollHeight="flex"
                  autoLayout={true}
                  resizableColumns={true}
                  columnResizeMode={"fit"}
                  rowsPerPageOptions={Util.rowsPerPageOptions}
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                  lazy={true}
                  rows={limit}
                  first={first}
                  value={campaignList}
                  paginator={true}
                  loading={loading}
                  filterDisplay="row"
                  sortMode={"multiple"}
                  onPage={onPage}
                  onSort={onSort}
                  multiSortMeta={Array.from(sortMap.values())}
                  totalRecords={totalItemsCount}
                  selection={selectedCampaigns}
                  header={
                    Util.hasAnyAuthority(
                      PERMISSIONS.MY_CAMPAIGNS_MANAGEMENT
                    ) ? (
                      <div
                        className={"p-grid-header-components"}
                        style={{
                          position: "relative",
                          width: "100%",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          id={IDS.button.new}
                          disabled={!(activeCampaignList.length > 1)}
                          style={{ position: "absolute", left: "0" }}
                          onClick={onShowRankMenu}
                          icon="pi pi-sort-alt"
                          label={"Rank"}
                        />
                        <Button
                          id={IDS.button.clear}
                          style={{ position: "absolute", left: "85px" }}
                          icon={"pi pi-filter"}
                          label={t("clear")}
                          onClick={onClear}
                        />
                        <Button
                          id={IDS.button.new}
                          style={{ marginRight: 5 }}
                          onClick={onNew}
                          icon="pi pi-plus"
                          label={"New"}
                        />
                        <Button
                          id={IDS.button.edit}
                          label={"Edit"}
                          style={{ marginRight: 5 }}
                          icon={"pi pi-pencil"}
                          onClick={onEdit}
                          disabled={selectedCampaigns.length !== 1}
                        />
                        <Button
                          id={IDS.button.duplicate}
                          label={"Duplicate"}
                          style={{ marginRight: 5 }}
                          icon={"pi pi-clone"}
                          onClick={onDuplicate}
                          disabled={selectedCampaigns.length !== 1}
                        />
                        <Button
                          id={IDS.button.delete}
                          onClick={onDelete}
                          icon="pi pi-trash"
                          className={"p-button-danger"}
                          label={"Delete"}
                          disabled={selectedCampaigns.length === 0}
                        />
                      </div>
                    ) : null
                  }
                  onSelectionChange={(e) => setSelectedCampaigns(e.value)}
                >
                  <Column
                    selectionMode="multiple"
                    style={{ width: "3em", flex: 0.3 }}
                  />
                  <Column
                    style={{ width: "4em" }}
                    field="rank"
                    header="Rank"
                    sortable={true}
                    body={(rowData) => (
                      <p style={{ textAlign: "center" }}>{rowData.rank}</p>
                    )}
                  />
                  <Column
                    field="name"
                    header="Name"
                    sortable={true}
                    filter={true}
                    filterElement={
                      <InputText
                        style={{ width: "100%" }}
                        value={nameFilter}
                        onChange={(e: any) => setNameFilter(e.target.value)}
                      />
                    }
                    body={(rowData) => {
                      return (
                        <p
                          style={
                            rowData?.automaticallyDisabled === true
                              ? {
                                  color: "red",
                                  fontWeight: "bolder",
                                  width: "100%",
                                  height: "100%",
                                  alignItems: "center",
                                  display: "flex",
                                }
                              : {
                                  width: "100%",
                                  height: "100%",
                                  alignItems: "center",
                                  display: "flex",
                                }
                          }
                          title={
                            rowData?.automaticallyDisabled === true
                              ? "This campaign was automatically disabled. There are no vehicles, matching the filter criteria."
                              : ""
                          }
                        >
                          {rowData?.name}
                        </p>
                      );
                    }}
                  />
                  <Column
                    field="type"
                    header="Type"
                    sortable={true}
                    filter={true}
                    filterElement={typeFilterTemplate}
                    body={(rowData: any) => {
                      let types: any = [];
                      if (rowData?.greeting?.id) {
                        types.push("Greeting");
                      }
                      if (rowData?.offer?.id) {
                        types.push("Offer");
                      }
                      if (rowData?.notifications?.length > 0) {
                        types.push("Notification");
                      }
                      return <span>{types?.join(", ")}</span>;
                    }}
                  />
                  <Column
                    field="offer"
                    header="Offer"
                    sortable={true}
                    filter={true}
                    filterElement={
                      <InputText
                        style={{ width: "100%" }}
                        value={offerFilter}
                        onChange={(e: any) => setOfferFilter(e.target.value)}
                      />
                    }
                    body={(rowData: any) => {
                      return rowData?.offer?.name ? (
                        <span>{rowData?.offer?.name}</span>
                      ) : null;
                    }}
                  />
                  <Column
                    field="chatGreeting"
                    header="Greeting"
                    sortable={true}
                    filter={true}
                    filterElement={
                      <InputText
                        style={{ width: "100%" }}
                        value={greetingFilter}
                        onChange={(e: any) => setGreetingFilter(e.target.value)}
                      />
                    }
                    body={(rowData: any) => {
                      return rowData?.greeting?.name ? (
                        <span>{rowData?.greeting?.name}</span>
                      ) : null;
                    }}
                  />
                  <Column
                    field="notificationNames"
                    header="Notifications"
                    sortable={true}
                    filter={true}
                    filterElement={
                      <InputText
                        style={{ width: "100%" }}
                        value={notificationFilter}
                        onChange={(e: any) =>
                          setNotificationFilter(e.target.value)
                        }
                      />
                    }
                    body={(rowData: any) => {
                      return rowData?.notifications?.length > 0 ? (
                        <span>
                          {rowData?.notifications
                            ?.map((notif: any) => notif?.name)
                            ?.join(", ")}
                        </span>
                      ) : null;
                    }}
                  />
                  <Column
                    field="semVendorNames"
                    header="Sources"
                    sortable={true}
                    filter={true}
                    filterElement={
                      <InputText
                        style={{ width: "100%" }}
                        value={semVendorFilter}
                        onChange={(e: any) =>
                          setSemVendorFilter(e.target.value)
                        }
                      />
                    }
                    body={(rowData: any) => {
                      const sourceNames = rowData?.sources?.map(
                        (source: any) => source?.name
                      );
                      return sourceNames?.length > 0 ? (
                        <span>{sourceNames?.join(", ")}</span>
                      ) : null;
                    }}
                  />
                  <Column
                    field="startDate"
                    header="Start Date"
                    filter={true}
                    sortable={true}
                    body={(data: any) =>
                      dateBody(data, "startDate", Util.usDateFormat)
                    }
                    filterElement={startDateFilter}
                  />
                  <Column
                    field="endDate"
                    header="End Date"
                    filter={true}
                    sortable={true}
                    body={(data: any) =>
                      dateBody(data, "endDate", Util.usDateFormat)
                    }
                    filterElement={endDateFilter}
                  />
                  <Column
                    field="created"
                    header="Created"
                    sortable={true}
                    filter={true}
                    filterElement={createdFilterTemplate}
                    body={(data: any) =>
                      dateBodyCreated(data, "created", Util.usDateTimeFormat)
                    }
                  />
                  <Column
                    field="knownVisitor"
                    header="Known Visitor"
                    sortable={true}
                    filter={false}
                    style={{ overflow: "visible" }}
                    body={knownVisitorTemplate}
                  />
                  <Column
                    field="isActive"
                    header="Active"
                    sortable={true}
                    body={activeTemplate}
                  />
                </DataTable>
              </div>
            </div>
            <Dialog
              modal={true}
              visible={!!rankClicked}
              onHide={onHideRankMenu}
              style={{ maxWidth: "60%" }}
              header={
                <p style={{ maxWidth: "90rem" }}>
                  {"Please rank your ACTIVE CAMPAIGNS in the order in which you’d like them to take precedent during a shopper's website visit. Your PRIMARY offer of '" +
                    activeOfferList[0]?.name +
                    "' will NOT be shown to shoppers visiting any pages that meet any of your CAMPAIGN(s) settings."}
                </p>
              }
            >
              <div>
                <DataTable
                  tableStyle={{ overflow: "visible", maxWidth: "90rem" }}
                  value={activeCampaignList}
                  reorderableRows
                  onRowReorder={onRowReorder}
                  responsiveLayout="scroll"
                  footer={
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        onClick={() => onSaveRankMenu()}
                        style={{ marginRight: 5 }}
                        label={"Save"}
                        icon={"pi pi-save"}
                      />
                      <Button
                        onClick={onHideRankMenu}
                        className={"confirm-discard-btn"}
                        label={"Cancel"}
                        icon={"pi pi-times"}
                      />
                    </div>
                  }
                >
                  <Column rowReorder style={{ width: "3em" }} />
                  <Column field="rank" header="Rank" style={{ width: "4em" }} />
                  <Column
                    field="name"
                    header="Name"
                    sortable={true}
                    filter={true}
                  />
                </DataTable>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    );
  };
  return <div id={"my-traffic-view"}>{content()}</div>;
}

export default MyCampaigns;
