import { Card } from "@mui/material";
import React from "react";

export function Legend() {
  return (
    <Card sx={{ padding: 3 }}>
      <div style={{ marginTop: 5 }} className={"p-grid"}>
        <div className={"legend-item"}>
          <div className={"legend-bubble legend-selected-visitor"}>
            <div className={"bubble-text"}>9</div>
          </div>
          <div className={"description"}>
            <p>Selected Visitor</p>
          </div>
        </div>
        <div className={"legend-item"}>
          <div className={"legend-bubble legend-organic-direct"}>
            <div className={"bubble-text"}>9</div>
          </div>
          <div className={"description"}>
            <p>Organic/Direct Visitor.</p>
            <p>Engagement score 9</p>
            <p>Bubble size relates to</p>
            <p>"session time"</p>
          </div>
        </div>
        <div className={"legend-item"}>
          <div className={"legend-bubble legend-organic-direct-paid"}>
            <div className={"bubble-text"}>9</div>
          </div>
          <div className={"description"}>
            <p>Organic/Direct Visitor with</p>
            <p>previous "paid search visit"</p>
          </div>
        </div>
        <div className={"legend-item"}>
          <div className={"legend-bubble legend-paid-visitor"}>
            <div className={"bubble-text"}>9</div>
          </div>
          <div className={"description"}>
            <p>Paid Visitor</p>
          </div>
        </div>
        <div className={"legend-item"}>
          <div className={"legend-bubble legend-paid-search-visitor"}>
            <div className={"bubble-text"}>9</div>
          </div>
          <div className={"description"}>
            <p>Paid Search Visitor with</p>
            <p>previous "organic/direct</p>
            <p>visit"</p>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 5 }} className={"p-grid"}>
        <div className={"legend-item"}>
          <div
            className={"legend-bubble legend-previous-engagement-not-converted"}
          >
            <div className={"bubble-text"}>9</div>
          </div>
          <div className={"description"}>
            <p>Previous engagement</p>
            <p>attempt, not converted</p>
          </div>
        </div>
        <div className={"legend-item"}>
          <div className={"legend-bubble legend-converted-via-my-traffic"}>
            <div style={{ left: "calc(50% - 6px)" }} className={"bubble-text"}>
              C
            </div>
          </div>
          <div className={"description"}>
            <p>converted via myTraffic</p>
          </div>
        </div>
        <div className={"legend-item"}>
          <div className={"legend-bubble legend-converted-natively"}>
            <div style={{ left: "calc(50% - 8px)" }} className={"bubble-text"}>
              W
            </div>
          </div>
          <div className={"description"}>
            <p>Converted natively via</p>
            <p>website or via myOffer</p>
            <p>(auto offer)</p>
          </div>
        </div>
        <div className={"legend-item"}>
          <div className={"legend-bubble legend-orphan-visitor"}>
            <div style={{ left: "calc(50% - 7px)" }} className={"bubble-text"}>
              O
            </div>
          </div>
          <div className={"description"}>
            <p>Orphaned Visitor</p>
          </div>
        </div>
        <div className={"legend-item"}>
          <div className={"legend-bubble legend-restricted-visitor"}>
            <div style={{ left: "calc(50% - 6px)" }} className={"bubble-text"}>
              R
            </div>
          </div>
          <div className={"description"}>
            <p>Visitor with restricted</p>
            <p>Ip Address</p>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 5 }} className={"p-grid"}>
        <div className={"legend-item"}>
          <div className={"legend-bubble legend-organic-direct"}>
            <div className={"bubble-text"}>D</div>
          </div>
          <div className={"description"}>
            <p>Has demographics data</p>
          </div>
        </div>
        <div className={"legend-item"}>
          <div className={"legend-bubble legend-paid-visitor"}>
            <div className={"bubble-text"}>B</div>
          </div>
          <div className={"description"}>
            <p>Blocked</p>
          </div>
        </div>
        <div className={"legend-item"}>
          <div className={"legend-bubble legend-converted-via-my-traffic"}>
            <div className={"bubble-text"}>S</div>
          </div>
          <div className={"description"}>
            <p>Has sold a vehicle</p>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default Legend;
