import React, { useEffect, useState } from "react";
import "./groups.css";
import {
  createRtsGroup,
  deleteRtsGroups,
  getRtsGroup,
  getRtsGroupsBySearchCriteria,
} from "../../../actions/rtsGroupActions";
import Util from "../../../util/Util";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import { Column } from "primereact/column";
import { MODE } from "../../../util/Enums";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { IRtsGroup } from "../../../../index.dts";
import moment from "moment-timezone";
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "react-router-dom";
import { IDS } from "../../constants";
import { useTranslation } from "react-i18next";
import { DateRangePickerWidget } from "../../../components/data_range_picker/DateRangePickerWidget";
import { DateRangePeriods } from "../../../components/data_range_picker/date_range_periods";

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

export function Groups() {
  const history = useHistory();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [rtsGroupList, setRtsGroupList] = useState<IRtsGroup[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [sortMap] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(new Map());
  const [selectedRtsGroups, setSelectedRtsGroups] = useState<IRtsGroup[]>([]);

  const [nameFilterValue, setNameFilterValue] = useState<string>("");
  const [ownerFilterValue, setOwnerFilterValue] = useState<string>("");
  const [createdPeriod, setCreatedPeriod] = useState<{
    start?: Date;
    end?: Date;
  }>({
    start: undefined,
    end: undefined,
  });

  const onClear = () => {
    sortMap.clear();
    setNameFilterValue("");
    setOwnerFilterValue("");
    setCreatedPeriod({
      start: undefined,
      end: undefined,
    });
  };

  useEffect(() => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getData(0, limit).finally(() => {
        setPage(0);
        setFirst(0);
        searchTimeoutInterval = 500;
        setSelectedRtsGroups([]);
      });
    }, searchTimeoutInterval);
    // eslint-disable-next-line
  }, [nameFilterValue, ownerFilterValue, createdPeriod]);

  const getData = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      getRtsGroupsBySearchCriteria({
        name: nameFilterValue,
        userEmail: ownerFilterValue,
        pageLimit: limit,
        pageNumber: page,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
        toDate: createdPeriod.end
          ? moment(createdPeriod.end).format(Util.localDateTimeFormat)
          : undefined,
        fromDate: createdPeriod.start
          ? moment(createdPeriod.start).format(Util.localDateTimeFormat)
          : undefined,
      })
        .then((response) => {
          setRtsGroupList(response.data.content);
          setTotalItemsCount(response.data.totalElements);

          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);

          reject(error);
        })
        .finally(() => setLoading(false));
    });

  const onSort = (data: any) => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getData(page, limit);
    }
  };

  const onPage = (event: any) => {
    getData(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
      setSelectedRtsGroups([]);
    });
  };

  const onNew = () => {
    history.push(Util.PATH_NAMES.MY_TRAFFIC_GROUP_CREATE, {
      mode: MODE.NEW,
    });
  };

  const onEdit = () => {
    history.push(Util.PATH_NAMES.MY_TRAFFIC_GROUP_EDIT, {
      mode: MODE.EDIT,
      id: selectedRtsGroups?.[0]?.id,
    });
  };

  const onCopy = (): void => {
    if (selectedRtsGroups.length === 1) {
      Util.globalSpinner().show();
      getRtsGroup(selectedRtsGroups[0].id)
        .then((response) => {
          const name = `Copy of ${Util._.get(
            response,
            "data.name",
            ""
          )}_(${Util.uniqId()})`;
          const description = Util._.get(response, "data.description", "");
          const dealershipIds = response.data.dealerships.map(
            (item) => item.id
          );

          createRtsGroup({
            name: name,
            description: description,
            dealershipIds: dealershipIds,
          })
            .then(() => {
              Util.success("Dealership group copied successfully.");
            })
            .catch((error) => {
              Util.showError(error);
            })
            .finally(() => {
              Util.globalSpinner().hide();
              getData(page, limit).finally(() => {
                setSelectedRtsGroups([]);
              });
            });
        })
        .catch((error) => {
          Util.showError(error);
          Util.globalSpinner().hide();
        });
    } else {
      Util.warning("Please select only one group.");
    }
  };

  const onDelete = () => {
    if (selectedRtsGroups.length > 0) {
      confirmAlert({
        title: `You are about to delete ${
          selectedRtsGroups.length
        } myTraffic group${selectedRtsGroups.length > 1 ? "s" : ""}!`,
        message: "Are you sure you want to do this?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              Util.globalSpinner().show();
              deleteRtsGroups(selectedRtsGroups.map((group) => group.id))
                .then(() =>
                  Util.success("myTraffic group(s) deleted successfully.")
                )
                .catch((error) => Util.showError(error))
                .finally(() => {
                  Util.globalSpinner().hide();
                  getData(0, limit).finally(() => {
                    setPage(0);
                    setFirst(0);
                    setSelectedRtsGroups([]);
                  });
                });
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    } else {
      Util.warning("Please choose at least one myTraffic group.");
    }
  };

  const createdTemplate = (data: any) => {
    return (
      <div>
        {moment(Util.utcToLocal(data.created)).format(Util.usDateTimeFormat)}
      </div>
    );
  };

  const nameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={nameFilterValue}
      onChange={(e: any) => {
        setNameFilterValue(e.target.value || "");
      }}
    />
  );

  const ownerFilter = (
    <InputText
      style={{ width: "100%" }}
      value={ownerFilterValue}
      onChange={(e: any) => {
        setOwnerFilterValue(e.target.value || "");
      }}
    />
  );

  function onCreatedPeriodChange(start?: Date, end?: Date): void {
    setCreatedPeriod({
      start: start ? moment(start).set({ hour: 0, minute: 0, second: 0 }).toDate() : undefined,
      end: start ? moment(end).set({ hour: 23, minute: 59, second: 59 }).toDate() : undefined,
    });
  }

  const createdFilter = (
    <DateRangePickerWidget
      start={createdPeriod.start}
      end={createdPeriod.end}
      ranges={DateRangePeriods.options} // Replace 'Options' with the range definitions
      onChange={onCreatedPeriodChange}
    />
  );

  return (
    <div id={"my-traffic-groups"}>
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div
            className={
              limit > 10
                ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting"
                : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
            }
          >
            <DataTable
              resizableColumns={true}
              columnResizeMode={"expand"}
              rowsPerPageOptions={Util.rowsPerPageOptions}
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
              value={rtsGroupList}
              scrollable
              paginator={true}
              totalRecords={totalItemsCount}
              rows={limit}
              lazy={true}
              first={first}
              filterDisplay="row"
              loading={loading}
              onPage={onPage}
              onSort={onSort}
              sortMode={"multiple"}
              multiSortMeta={Array.from(sortMap.values())}
              selection={selectedRtsGroups}
              onSelectionChange={(e) => setSelectedRtsGroups(e.value)}
              header={
                <div className={"p-grid-header-components"}>
                  <Button
                    id={IDS.button.clear}
                    style={{ position: "absolute", left: "10px" }}
                    icon={"pi pi-filter"}
                    label={t("clear")}
                    onClick={onClear}
                  />
                  <Button
                    style={{ marginRight: 5 }}
                    onClick={onNew}
                    icon="pi pi-plus"
                    label={"New"}
                  />
                  <Button
                    label={"Edit"}
                    disabled={selectedRtsGroups.length !== 1}
                    style={{ marginRight: 5 }}
                    onClick={onEdit}
                    icon="pi pi-pencil"
                  />
                  <Button
                    disabled={selectedRtsGroups.length !== 1}
                    style={{ marginRight: 5 }}
                    onClick={onCopy}
                    icon="pi pi-copy"
                    className="p-button-warning"
                    label={"Copy"}
                  />
                  <Button
                    onClick={onDelete}
                    icon="pi pi-trash"
                    className="p-button-danger"
                    label={"Delete"}
                    disabled={selectedRtsGroups.length === 0}
                  />
                </div>
              }
            >
              <Column
                selectionMode="multiple"
                style={{ width: "3em", flex: "0.05" }}
              />
              <Column
                filter={true}
                sortable={true}
                field={"name"}
                sortField={"name"}
                header={"Name"}
                filterElement={nameFilter}
              />
              <Column
                field="dealershipsCount"
                header="Dealerships"
                filter={false}
                sortable={false}
              />
              <Column
                field="owner.email"
                header="Owner"
                filter={true}
                filterElement={ownerFilter}
                sortable={false}
              />
              <Column
                filter={true}
                sortable={true}
                field={"created"}
                sortField={"created"}
                header="Created"
                style={{ overflow: "visible" }}
                body={createdTemplate}
                filterElement={createdFilter}
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Groups;
