import { IDateArgs, SmartSpendDataType } from "../../../index.dts";

import { ISmartSpendDataAction } from "./SmartSpendDataView";
import { getSmartSpendData } from "../../actions/kaninActions";

export class SmartSpendDataViewService {
  getDataAction(args: {
    dealershipId: any,
    type: SmartSpendDataType
  }): ISmartSpendDataAction {
    return (params: IDateArgs) => getSmartSpendData({
      date: params,
      dealershipId: args.dealershipId,
      type: args.type,
    });
  }
}