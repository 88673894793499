import React, { useImperativeHandle, useState } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IPersistentChatUserStats } from "./index";
import { getPersistentChatUsersReport } from "../../actions/persistentChatActions";
import Util from "../../util/Util";

interface IProps {}

const PersistentChatUserStatistics = React.forwardRef(
  (props: IProps, ref: any) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [data, setData] = useState<IPersistentChatUserStats[]>([]);

    const [endDate, setEndDate] = useState<string>();
    const [startDate, setStartDate] = useState<string>();

    useImperativeHandle(ref, () => ({
      show: show,
      hide: hide,
    }));

    const show = (
      dealershipId: number,
      startDate: string,
      endDate: string
    ): void => {
      setVisible(true);
      setPending(true);

      setEndDate(endDate);
      setStartDate(startDate);

      getPersistentChatUsersReport({
        dealershipId: dealershipId,
        startDate: startDate,
        endDate: endDate,
      })
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => {
          setPending(false);
        });
    };

    const hide = (): void => {
      setVisible(false);

      setEndDate(undefined);
      setStartDate(undefined);
    };

    return (
      <Dialog
        onHide={hide}
        visible={visible}
        style={{ width: "80vw" }}
        header={`Users Breakdown (${startDate} - ${endDate})`}
      >
        <div
          className={
            "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"
          }
        >
          <DataTable
            resizableColumns={true}
            columnResizeMode={"expand"}
            loading={pending}
            filterDisplay="row"
            sortMode={"single"}
            value={data}
          >
            <Column
              filter={true}
              sortable={true}
              field={"email"}
              header={"User Email"}
            />
            <Column
              filter={true}
              sortable={true}
              field={"totalAccepted"}
              header={"Total Accepted"}
            />
            <Column
              filter={true}
              sortable={true}
              field={"totalLeads"}
              header={"Total Leads"}
            />
          </DataTable>
        </div>
      </Dialog>
    );
  }
);

export default PersistentChatUserStatistics;
