import React, { useEffect, useRef, useState } from "react";
import "./crm_sources.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Util from "../../util/Util";
import { ICrmSource, ISelectValue } from "../../../index.dts";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { PERMISSIONS, SettingsName } from "../../util/Enums";
import {
  createCrmSource,
  deleteCrmSources,
  getCrmSource,
  getCrmSourcesList,
  updateCrmSource,
} from "../../actions/crmSourceActions";
import { confirmAlert } from "react-confirm-alert";
import { InputText } from "primereact/inputtext";
import ImageUploading from "react-images-uploading";
import { ErrorsType, ImageListType } from "react-images-uploading/dist/typings";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Popup from "reactjs-popup";
import CloseButton from "../../components/close_button/CloseButton";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import Select from "react-select";
import { getSettingsByName } from "../../actions/globalSettingsActions";
import moment from "moment";
import AceEditor from "react-ace";
import ImageIcon from "@mui/icons-material/Image";

import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-xml";
import { Calendar } from "primereact/calendar";
import { get } from "lodash";
import { IDS } from "../constants";
import { useTranslation } from "react-i18next";

enum MODE {
  NEW = "NEW",
  EDIT = "EDIT",
}
let searchTimeout: any;
let searchTimeoutInterval: number = 0;

export function CrmSources() {
  const imageUploaderRef: any = useRef();
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();

  const [first, setFirst] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);

  const [pending, setPending] = useState<boolean>(false);
  const [newCrmSourceFile, setNewCrmSourceFile] = useState<ImageListType>([]);
  const [newCrmSourceName, setNewCrmSourceName] = useState<string | null>("");
  const [newCrmSourceRTSName, setNewCrmSourceRTSName] = useState<string | null>(
    ""
  );
  const [newCrmSourceAdfTemplate, setNewCrmSourceAdfTemplate] = useState<
    string | null
  >("");
  const [newCrmSourceSubdomain, setNewCrmSourceSubdomain] = useState<
    string | null
  >("");

  const [subdomains, setSubdomains] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [mode, setMode] = useState<MODE>(MODE.NEW);
  const [crmSourceList, setCrmSourceList] = useState<ICrmSource[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [selectedCrmSources, setSelectedCrmSources] = useState<ICrmSource[]>(
    []
  );
  const [sidebarEnabled, setSidebarEnabled] = useState<boolean>(false);
  const [nameFilter, setNameFilter] = useState<string>("");
  const [subdomainFilter, setSubdomainFilter] = useState<string>("");
  const [createdDateFilter, setCreatedDateFilter] = useState<string>("");
  const [sortMap] = useState<Map<string, { field: string; order: 1 | 0 | -1 }>>(
    new Map()
  );

  useEffect(() => {
    sortMap.set("created", { field: "created", order: -1 });
  }, []);

  const onClear = () => {
    sortMap.clear();
    setNameFilter("");
    setSubdomainFilter("");
    setCreatedDateFilter("");
  };

  useEffect(() => {
    searchTimeout = setTimeout(() => {
      getCrmSources(0, limit).finally(() => {
        setPage(0);
        setFirst(0);
        setSelectedCrmSources([]);
        searchTimeoutInterval = 500;
      });
    }, searchTimeoutInterval);

    return () => {
      clearTimeout(searchTimeout);
      Util.removeAlert();
    };
    // eslint-disable-next-line
  }, [nameFilter, createdDateFilter, subdomainFilter]);

  const getCalendarValue = (value?: string): Date | undefined => {
    if (!value) {
      return;
    }

    return moment(value).toDate();
  };

  const onCreatedDateChange = (e: any): void => {
    if (e.value) {
      setCreatedDateFilter(
        moment(get(e, "value")).format(Util.localDateFormat)
      );
    } else {
      setCreatedDateFilter("");
    }
  };

  const createdFilterTemplate = (
    <Calendar
      style={{ width: "100%" }}
      inputId={"startDateInput"}
      inputStyle={{ width: "100%" }}
      value={getCalendarValue(createdDateFilter)}
      onChange={onCreatedDateChange}
    />
  );

  const getCrmSources = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      getCrmSourcesList({
        pageLimit: limit,
        pageNumber: page,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
        name: nameFilter ? nameFilter : undefined,
        subdomain: subdomainFilter ? subdomainFilter : undefined,
        createdDate: createdDateFilter
          ? moment(createdDateFilter).format(Util.localDateTimeFormat)
          : undefined,
      })
        .then((response) => {
          setCrmSourceList(
            response.data.content.map((crmSource: ICrmSource) => {
              return {
                id: crmSource.id,
                name: crmSource.name,
                logoObjectId: crmSource.logoObjectId,
                rtsName: crmSource.rtsName,
                system: crmSource.system,
                subdomain: crmSource.subdomain,
                created: moment(crmSource.created).format(
                  Util.usDateTimeFormat
                ),
              };
            })
          );
          setLoading(false);
          setTotalItemsCount(response.data.totalElements);
          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);
          setLoading(false);

          reject(error);
        });
    });

  const onError = (errors: ErrorsType, files?: ImageListType): void => {
    if (errors?.maxFileSize) {
      Util.warning("File size exceeds the maximum allowed size.");
    }
  };

  const getSubdomains = () => {
    setPending(true);
    getSettingsByName(SettingsName.CRM_SUBDOMAIN)
      .then((response) => {
        setSubdomains(
          response.data.map((item: any) => {
            return {
              label: item.value,
              value: item.value,
            };
          })
        );
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => {
        setPending(false);
      });
  };

  const onDeleteCrmSource = () => {
    if (selectedCrmSources.length > 0) {
      confirmAlert({
        title: `You are about to delete ${
          selectedCrmSources.length
        } channel partner source${selectedCrmSources.length > 1 ? "s" : ""}!`,
        message: "Are you sure you want to do this?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              Util.globalSpinner().show();
              deleteCrmSources(
                selectedCrmSources.map((crmSource) => crmSource.id)
              )
                .then(() => {
                  selectedCrmSources.forEach((crmSource) => {
                    const index = crmSourceList.findIndex(
                      (c) => c.id === crmSource.id
                    );
                    if (index > -1) {
                      crmSourceList.splice(index, 1);
                    }
                  });
                  setSelectedCrmSources([]);

                  Util.success("Channel Partner source deleted successfully.");
                })
                .catch((error) => {
                  Util.showError(error);

                  getCrmSources(page, limit);
                })
                .finally(() => {
                  Util.globalSpinner().hide();
                });
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    } else {
      Util.warning("Please choose at least one Channel Partner Source.");
    }
  };

  const onSave = () => {
    if (!Util.isEmpty(newCrmSourceName)) {
      if (mode === MODE.NEW) {
        if (newCrmSourceFile) {
          const formData = new FormData();
          //@ts-ignore
          formData.append("file", newCrmSourceFile[0]?.file);
          formData.append(
            "data",
            JSON.stringify({
              name: newCrmSourceName,
              rtsName: newCrmSourceRTSName,
              adfTemplate: newCrmSourceAdfTemplate,
              subdomain: newCrmSourceSubdomain,
            })
          );
          Util.globalSpinner().show();
          createCrmSource(formData)
            .then((response) => {
              crmSourceList.push(response.data);
              forceUpdate();
              onHideSidebar();

              Util.success("Channel Partner Source created successfully");
            })
            .catch((error) => {
              Util.showError(error);
            })
            .finally(() => {
              Util.globalSpinner().hide();
            });
        } else {
          Util.warning("Please attach an image.");
        }
      } else if (selectedCrmSources.length > 0) {
        Util.globalSpinner().show();
        updateCrmSource(selectedCrmSources[0].id, {
          name: newCrmSourceName,
          rtsName: newCrmSourceRTSName,
          adfTemplate: newCrmSourceAdfTemplate,
          subdomain: newCrmSourceSubdomain,
        })
          .then((response) => {
            const index = crmSourceList.findIndex(
              (crmSource) => crmSource.id === selectedCrmSources[0].id
            );

            if (index > -1) {
              crmSourceList[index].name = response.data.name;
              crmSourceList[index].rtsName = response.data.rtsName;
              crmSourceList[index].subdomain = response.data.subdomain;
            }

            forceUpdate();
            onHideSidebar();

            Util.success("Channel Partner Source updated successfully");
          })
          .catch((error) => {
            Util.showError(error);
          })
          .finally(() => {
            Util.globalSpinner().hide();
          });
      }
    } else {
      Util.warning("Invalid Channel Partner Source Name");
    }
  };

  const onHideSidebar = () => {
    setNewCrmSourceName("");
    setNewCrmSourceRTSName("");
    setNewCrmSourceAdfTemplate("");
    setSidebarEnabled(false);

    if (mode === MODE.NEW)
      imageUploaderRef?.current?.removeImage(
        imageUploaderRef.current.state.pictures[0]
      );
  };

  const onNew = () => {
    if (subdomains.length === 0) {
      getSubdomains();
    }

    setMode(MODE.NEW);
    setSidebarEnabled(true);
  };

  const onEdit = () => {
    if (selectedCrmSources.length === 1) {
      Util.globalSpinner().show();
      getCrmSource(selectedCrmSources[0].id)
        .then((response) => {
          setMode(MODE.EDIT);
          setNewCrmSourceName(Util._.get(response, "data.name", ""));
          setNewCrmSourceRTSName(Util._.get(response, "data.rtsName", ""));
          setNewCrmSourceSubdomain(Util._.get(response, "data.subdomain", ""));

          setSidebarEnabled(true);
          setNewCrmSourceAdfTemplate(response.data.adfTemplate);
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => {
          Util.globalSpinner().hide();
        });
      if (subdomains.length === 0) {
        getSubdomains();
      }
    } else {
      Util.warning("Please choose only one Channel Partner source.");
    }
  };

  const nameTemplate = (rowData: ICrmSource) => {
    return (
      <div style={{ display: "flex" }}>
        <Popup
          trigger={
            <img
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={Util.getAssetUrl(rowData.logoObjectId)}
              alt={""}
            />
          }
          modal
          nested
        >
          <>
            {(close: any) => (
              <div id={`${rowData.id}_modal`} className={"crm-img-modal"}>
                <button onClick={close} className="close">
                  &times;
                </button>
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={Util.getAssetUrl(rowData.logoObjectId)}
                  alt={""}
                  onError={close}
                  onLoad={() => {
                    const button = document.getElementById(
                      `${rowData.id}_modal`
                    );
                    if (button) {
                      button.style.display = "block";
                    }
                  }}
                />
              </div>
            )}
          </>
        </Popup>
        <div style={{ marginTop: 6 }}>
          <label>{rowData.name}</label>
        </div>
      </div>
    );
  };

  const createdTemplate = (rowData: ICrmSource) => {
    return moment(rowData.created).format(Util.customDateTimeFormat);
  };

  const onSort = (data: any) => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getCrmSources(page, limit);
    }
  };

  const onPage = (event: any) => {
    getCrmSources(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
      setSelectedCrmSources([]);
    });
  };

  return (
    <div id={"crm-source-view"}>
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div className="card card-w-title datatable-demo">
            <div
              className={
                limit > 10
                  ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting"
                  : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
              }
            >
              <DataTable
                resizableColumns={true}
                filterDisplay="row"
                columnResizeMode={"expand"}
                rowsPerPageOptions={Util.rowsPerPageOptions}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                paginator={true}
                lazy={true}
                scrollable
                rows={limit}
                first={first}
                totalRecords={totalItemsCount}
                loading={loading}
                onPage={onPage}
                sortMode={"multiple"}
                onSort={onSort}
                multiSortMeta={Array.from(sortMap.values())}
                value={crmSourceList}
                selection={selectedCrmSources}
                onSelectionChange={(e) => setSelectedCrmSources(e.value)}
                header={
                  Util.hasAnyAuthority(PERMISSIONS.DEALERSHIP_MANAGEMENT) ? (
                    <div className={"p-grid-header-components"}>
                      <Button
                        id={IDS.button.clear}
                        style={{ position: "absolute", left: "10px" }}
                        icon={"pi pi-filter"}
                        label={t("clear")}
                        onClick={onClear}
                      />
                      <Button
                        style={{ marginRight: 5 }}
                        onClick={onNew}
                        icon="pi pi-plus"
                        label={"New"}
                      />
                      <Button
                        label={"Edit"}
                        style={{ marginRight: 5 }}
                        onClick={onEdit}
                        icon="pi pi-pencil"
                        disabled={selectedCrmSources.length !== 1}
                      />
                      <Button
                        onClick={onDeleteCrmSource}
                        icon="pi pi-trash"
                        className="p-button-danger"
                        label={"Delete"}
                        disabled={selectedCrmSources.length === 0}
                      />
                    </div>
                  ) : null
                }
              >
                <Column
                  selectionMode="multiple"
                  style={{ width: "3em", flex: "0.04" }}
                />
                <Column
                  field="name"
                  header="Name"
                  filter={true}
                  sortable={true}
                  body={nameTemplate}
                  filterElement={
                    <InputText
                      style={{ width: "100%" }}
                      value={nameFilter}
                      onChange={(e: any) => setNameFilter(e.target.value)}
                    />
                  }
                />
                <Column
                  field="subdomain"
                  header="Subdomain"
                  filter={true}
                  sortable={true}
                  filterElement={
                    <InputText
                      style={{ width: "100%" }}
                      value={subdomainFilter}
                      onChange={(e: any) => setSubdomainFilter(e.target.value)}
                    />
                  }
                />
                <Column
                  field="created"
                  header="Created"
                  body={createdTemplate}
                  filter={true}
                  filterElement={createdFilterTemplate}
                  sortable={true}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>

      <Sidebar
        style={{ width: 500 }}
        position={"right"}
        visible={sidebarEnabled}
        onHide={onHideSidebar}
        showCloseIcon={false}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <CloseButton onHide={onHideSidebar} />
          <div
            style={{
              marginTop: 25,
              marginLeft: -15,
              marginRight: -15,
              borderBottom: "1px solid #afa6a6",
            }}
          >
            <label
              style={{
                fontSize: 20,
                marginLeft: 20,
              }}
            >
              {mode === MODE.NEW
                ? "Create New Channel Partner Source"
                : "Update Channel Partner Source"}
            </label>
          </div>
          <div
            style={{
              padding: 5,
              height: "calc(100% - 50px)",
              width: "100%",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {mode === MODE.NEW ? (
              <ImageUploading
                value={newCrmSourceFile}
                onChange={(e: ImageListType) => setNewCrmSourceFile([e[0]])}
                dataURLKey="data_url"
                maxFileSize={500000}
                onError={onError}
                acceptType={["jpg", "gif", "png"]}
              >
                {({ imageList, onImageUpload }) => (
                  <div
                    style={{
                      borderRadius: "4px",
                      borderBottom: "1px solid #c8c8c8",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <ImageIcon fontSize={"large"} />
                    <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                      Max file size: 0.5mb, accepted: jpg|gif|png
                    </span>
                    <Button
                      className="no-icon-buttons"
                      label={"Choose Image"}
                      onClick={() => onImageUpload()}
                    />
                    {imageList?.length > 0 &&
                      imageList?.map((image, index) => (
                        <div key={index} className="uploadPictureContainer">
                          <img
                            src={image["data_url"]}
                            alt=""
                            style={{
                              width: "320px",
                              height: "220px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      ))}
                  </div>
                )}
              </ImageUploading>
            ) : null}
            <span>Channel Partner Source Name</span>
            <InputText
              style={{ width: "100%", marginBottom: 10 }}
              placeholder={"Channel Partner Source Name"}
              defaultValue={newCrmSourceName || ""}
              onChange={(e: any) => setNewCrmSourceName(e.target.value)}
            />
            <span>Channel Partner MyTraffic Name</span>
            <InputText
              style={{ width: "100%", marginBottom: 10 }}
              placeholder={"Channel Partner MyTraffic Name"}
              defaultValue={newCrmSourceRTSName || ""}
              onChange={(e: any) => setNewCrmSourceRTSName(e.target.value)}
            />
            <span>Channel Partner Subdomain</span>
            <div style={{ width: "100%", marginBottom: 10 }}>
              <Select
                isLoading={pending}
                options={subdomains}
                // @ts-ignore
                value={
                  !Util.isEmpty(newCrmSourceSubdomain)
                    ? {
                        value: newCrmSourceSubdomain,
                        label: newCrmSourceSubdomain,
                      }
                    : null
                }
                placeholder={"Channel Partner Subdomain"}
                onChange={(item: any) => setNewCrmSourceSubdomain(item.value)}
              />
            </div>
            <span>ADF Template</span>
            <AceEditor
              mode={"xml"}
              fontSize={16}
              name="ADF_TEMPLATE"
              placeholder={"ADF Template"}
              highlightActiveLine={true}
              enableLiveAutocompletion={true}
              enableBasicAutocompletion={true}
              onChange={(code) => setNewCrmSourceAdfTemplate(code)}
              style={{
                width: "100%",
                zIndex: 0,
                marginBottom: 10,
                height: 300,
                border: "1px",
              }}
              value={Util.getDefaultIfNull(newCrmSourceAdfTemplate, "")}
            />
            <Button
              label={"Save"}
              style={{ float: "right" }}
              onClick={onSave}
              icon={"pi pi-save"}
            />
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default CrmSources;
