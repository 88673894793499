import React, { useEffect, useState } from "react";
import "./assets.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  createAsset,
  deleteAsset,
  getAssetList,
} from "../../actions/assetActions";
import Util from "../../util/Util";
import { IAsset, IStore } from "../../../index.dts";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { confirmAlert } from "react-confirm-alert";
import { PERMISSIONS } from "../../util/Enums";
import Axios from "../../config/Axios";
import { DataView } from "primereact/dataview";
import CloseButton from "../../components/close_button/CloseButton";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { shallowEqual, useSelector } from "react-redux";
import ImageUploading from "react-images-uploading";
import ImageIcon from "@mui/icons-material/Image";
import {
  ErrorsType,
  ImageListType,
  ImageType,
} from "react-images-uploading/dist/typings";
import { get } from "lodash";

export function Assets() {
  const forceUpdate = useForceUpdate();

  const [images, setImages] = useState<ImageType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [assetList, setAssetList] = useState<IAsset[]>([]);
  const [sidebarEnabled, setSidebarEnabled] = useState<boolean>(false);

  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  useEffect(() => {
    return () => {
      Util.removeAlert();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dealershipContext.id !== 0) {
      getAssets(dealershipContext.id);
    } else {
      setAssetList([]);
    }
    // eslint-disable-next-line
  }, [dealershipContext]);

  const getAssets = (dealershipId: number) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      getAssetList(dealershipId)
        .then((response) => {
          setAssetList(response.data.data);
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const onDeleteAsset = (asset: IAsset) => {
    confirmAlert({
      title: "You are about to delete an asset!",
      message: "Are you sure you want to delete this asset?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            Util.globalSpinner().show();
            deleteAsset(asset.id)
              .then(() => {
                const index = assetList.findIndex((a) => a.id === asset.id);
                if (index > -1) {
                  assetList.splice(index, 1);
                  forceUpdate();
                }
                Util.success("Asset deleted successfully.");
              })
              .catch((error) => {
                Util.showError(error);
              })
              .finally(() => {
                Util.globalSpinner().hide();
              });
          },
          className: "confirm-save-btn",
        },
        {
          label: "Cancel",
          className: "confirm-cancel-btn",
          onClick: () => {},
        },
      ],
    });
  };

  const onSave = () => {
    if (images.length > 0) {
      if (dealershipContext.id !== 0) {
        Util.globalSpinner().show();
        Axios.all(
          images.map((image) => {
            const formData = new FormData();
            formData.append("file", get(image, "file") as File);
            return createAsset(dealershipContext.id, formData);
          })
        )
          .then((responses) => {
            responses.forEach((response: any) => {
              assetList.push(response.data);
            });
            forceUpdate();
            Util.success(
              `Asset${images.length > 1 ? "s" : ""} uploaded successfully.`
            );

            onHideSidebar();
          })
          .catch((error) => {
            Util.showError(error);
          })
          .finally(() => {
            Util.globalSpinner().hide();
          });
      }
    } else {
      Util.warning("Please attach an image.");
    }
  };

  const onHideSidebar = () => {
    setImages([]);
    setSidebarEnabled(false);
  };

  const itemTemplate = (product: any, layout: string) => {
    if (!product) {
      return;
    }

    return renderGridItem(product);
  };

  const renderGridItem = (asset: IAsset) => {
    return (
      <div style={{ margin: 5 }} className="product-grid-item card">
        <img
          width={300}
          height={200}
          src={Util.getAssetUrl(asset.objectId)}
          alt={"..."}
        />
        {Util.hasAnyAuthority(PERMISSIONS.ASSETS_MANAGEMENT) ? (
          <div className="product-grid-item-bottom">
            <Button
              icon="pi pi-trash"
              onClick={() => onDeleteAsset(asset)}
              className="p-button p-button-danger p-component p-button-icon-only"
            />
          </div>
        ) : null}
      </div>
    );
  };

  const onChange = (imageList: any) => {
    setImages(imageList);
  };

  const onError = (errors: ErrorsType, files?: ImageListType): void => {
    if (errors?.maxFileSize) {
      Util.warning("File size exceeds the maximum allowed size.");
    }
  };

  return (
    <div id={"assets-view"}>
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div className="card card-w-title datatable-demo">
            <DataView
              value={assetList}
              layout={"grid"}
              loading={loading}
              emptyMessage={"No assets found"}
              header={
                Util.hasAnyAuthority(PERMISSIONS.ASSETS_MANAGEMENT) ? (
                  <div className={"p-grid-header-components"}>
                    <Button
                      disabled={dealershipContext.id === 0}
                      onClick={() => setSidebarEnabled(true)}
                      icon="pi pi-plus"
                      label={"New"}
                    />
                  </div>
                ) : undefined
              }
              itemTemplate={itemTemplate}
            />
          </div>
        </div>
      </div>

      <Sidebar
        style={{ width: 350 }}
        position={"right"}
        visible={sidebarEnabled}
        onHide={onHideSidebar}
        showCloseIcon={false}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <CloseButton onHide={onHideSidebar} />
          <div
            style={{
              marginTop: 25,
              marginLeft: -15,
              marginRight: -15,
              borderBottom: "1px solid #afa6a6",
            }}
          >
            <label style={{ fontSize: 20, marginLeft: 20 }}>Import Asset</label>
          </div>
          <div style={{ height: "100%", width: "100%" }}>
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={20}
              dataURLKey="data_url"
              maxFileSize={2000000}
              onError={onError}
              acceptType={["gif", "jpg", "png", "svg", "jpeg"]}
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                <div className="fileContainer">
                  <ImageIcon fontSize={"large"} />
                  <span style={{ marginRight: "20px" }}>Max file size 2mb</span>
                  <Button
                    className="no-icon-buttons"
                    label={"Choose Image"}
                    onClick={() => onImageUpload()}
                  />

                  <div className={"uploadPicturesWrapper"}>
                    {imageList.map((image, index) => (
                      <div key={index} className="uploadPictureContainer">
                        <div
                          onClick={() => onImageRemove(index)}
                          className={"deleteImage"}
                        >
                          X
                        </div>
                        <img src={image["data_url"]} alt="" width="100" />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </ImageUploading>
            <Button
              label={"Upload"}
              style={{ float: "right" }}
              onClick={onSave}
              icon={"pi pi-upload"}
            />
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default Assets;
