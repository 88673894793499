import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/messaging';
import Util from "../../util/Util";
import store from "../../config/store";
import {updateUserNotifications} from "../../actions/userActions";
import {MY_TRAFFIC_TABS} from "../../views/my_traffic/MyTraffic";
import {NotificationType, StatsTabKeys} from "../../util/Enums";
import {get} from "lodash";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const self: Window &
  typeof globalThis & {
  registration: any
}

export const FIREBASE_ERROR_MESSAGES = {
  PERMISSION_DENIED: "Please allow your notifications from site settings."
}

const FIREBASE_ERROR_CODE = {
  PERMISSION_DENIED: "messaging/permission-blocked",
  PERMISSION_DEFAULT: "messaging/permission-default"
}

export default class Firebase {
  private static messaging: any;

  private static init() {
    firebase.initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
      databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
      messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    });

    Firebase.addEventListeners();
    Firebase.messaging = firebase.messaging();
  }

  private static addEventListeners() {
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (!event.data) {
        return;
      }

      switch (event.data) {
        case process.env.REACT_APP_FIREBASE_SW_WINDOW_RELOAD:
          reloadWindow();
          break;
        case process.env.REACT_APP_FIREBASE_SW_REFREH_NOTIFICATION_BADGE:
          updateNotificationsBar();
          break
        default:
          break;
      }
    });
  }

  private static requestPermission = (registration: ServiceWorkerRegistration) => new Promise(async (resolve, reject) => {
    try {
      await Firebase.messaging.requestPermission();
      resolve(await Firebase.messaging.getToken({
        serviceWorkerRegistration: registration,
        vapidKey: process.env.REACT_APP_FIREBASE_KEY
      }));
    } catch (error) {
      //@ts-ignore
      switch (error.code) {
        case FIREBASE_ERROR_CODE.PERMISSION_DENIED:
        case FIREBASE_ERROR_CODE.PERMISSION_DEFAULT:
          const user = store.getState().auth.user;

          if (user.desktopNotificationsEnabled) {
            reject({message: FIREBASE_ERROR_MESSAGES.PERMISSION_DENIED});
            return;
          }

          reject();
          break;
        default:
          reject(error);
      }
    }
  });

  private static updateToken = () => new Promise((resolve, reject) => {
    if (!Firebase.checkMessaging()) {
      reject({message: "Firebase messaging is null."});
      return;
    }

    if (!('serviceWorker' in navigator)) {
      reject();
      return;
    }

    if (!firebase.messaging.isSupported()) {
      reject({message: "The browser does not support Web Push API."});
      return;
    }

    Firebase.init();
    navigator.serviceWorker.register('./firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Registration successful, scope is:', registration.scope);

        registration.update();
        Firebase.requestPermission(registration)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      })
      .catch(function (error) {
        reject(error);
      });
  })

  public static subscribe = () => new Promise((resolve, reject) => {
    Firebase.updateToken()
      .then((token) => {
        resolve(token);
        Firebase.subscribeForForegroundNotifications();
      })
      .catch(error => {
        reject(error);
        handlerErrorMessage(error);
      });
  });

  private static subscribeForForegroundNotifications() {
    Firebase.messaging.onMessage((payload: any) => {
      console.log('Message received. ', payload);
      const data = get(payload, "data", {});
      const type = get(data, "type", NotificationType.USER_ROLE_UPDATE);

      if (type === NotificationType.USER_ROLE_UPDATE) {
        reloadWindow();
        return;
      }

      updateNotificationsBar();
      const localNotification = new Notification(get(data, "title", "myTraffic"), {
        data: data,
        body: get(data, "body", "Unknown notification")
      });

      localNotification.onclick = Firebase.handleNotification;
    });
  }

  private static checkMessaging(): boolean {
    return Firebase.messaging !== null;
  }

  private static handleNotification = (data: any): void => {
    const notification = get(data, "currentTarget.data");
    const notificationService = store.getState().myTraffic.notificationService;

    notificationService?.handleNotification(Number(notification.notificationId));
  }
}

const handlerErrorMessage = (error: any): void => {
  const message = get(error, "message");

  switch (message) {
    case FIREBASE_ERROR_MESSAGES.PERMISSION_DENIED:
      Util.warning(message);
      break;
    default:
      console.error("Firebase error:", error);
  }

}

const reloadWindow = (): void => {
  window.location.reload();
}

const updateNotificationsBar = (): void => {
  store.dispatch(updateUserNotifications());
}

export const findMyTrafficTabIndex = (key: string): number => {
  return Object.keys(MY_TRAFFIC_TABS).findIndex(item => item === key)
}

export const findStatsTabIndex = (key: string): number => {
  return Object.values(StatsTabKeys).findIndex(item => item === key);
}