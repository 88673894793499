import Axios from "../config/Axios";
import {IDeviceRequestDto} from "../../index.dts";
import Util from "../util/Util";

export const createDevice = (dto: IDeviceRequestDto) => Axios.instance.post("device", dto);
export const getDevice = (uniqueId: string, token: string | null) => {
    if (!Util.isEmpty(token)) {
        return Axios.instance.get(`device/${uniqueId}/v2?token=${token}`);
    }
    return Axios.instance.get(`device/${uniqueId}/v2`);
};
export const updateDevice = (id: number, dto: IDeviceRequestDto) => Axios.instance.put(`device/${id}`, dto);
export const revokeDeviceToken = (id: number) => Axios.instance.put(`device/${id}/revoke`);