import React from "react";
import { PiiT2Response } from "../fraud_guard/index";

interface IProps {
  piiData: PiiT2Response | undefined;
}

const T2PiiData = (props: IProps) => {
  const { piiData } = props;
  return (
    <div>
      {piiData && (
        <>
          <h4>Personal Information</h4>
          <br />
          <b>First Name: </b> {piiData?.name.firstName}
          <br />
          <b>Middle Name: </b> {piiData?.name.middleName}
          <br />
          <b>Last Name: </b> {piiData?.name.lastName}
          <br />
          <b>Name Sufix: </b> {piiData?.name.nameSufix}
          <br />
          <b>Gender: </b> {piiData.gender}
          <br />
          <b>Deceased: </b> {piiData.deceased}
          <br />
          <hr />
          <h4>Address Information</h4>
          <br />
          <b>Address: </b> {piiData?.address.line1}
          <br />
          <b>City: </b> {piiData?.address.city}
          <br />
          <b>State: </b> {piiData?.address.state}
          <br />
          <b>Zip: </b> {piiData?.address.zip}
          <br />
          <b>County: </b> {piiData?.address.county}
          <br />
          <hr />
          <h4>Phone Information</h4>
          <br />
          <b>Phone Number: </b> {piiData?.phone.phone}
          <br />
          <b>Phone Type: </b> {piiData?.phone.phoneType}
          <br />
          <b>Carrier: </b> {piiData?.phone.carrier}
          <br />
          <b>CarrierType: </b> {piiData?.phone.carrierType}
          <br />
          <b>Listing Name: </b> {piiData?.phone.listingName}
          <br />
          <b>City: </b> {piiData?.phone.city}
          <br />
          <b>State: </b> {piiData?.phone.state}
          <br />
        </>
      )}
    </div>
  );
};

export default T2PiiData;
