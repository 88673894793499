import { ChartConfig } from "../chart_config";
import { IChartAnnotation } from "./chart_annotation";

export class TrafficMarker implements IChartAnnotation {
  readonly borderColor: string = ChartConfig.trafficMarker.color;
  readonly borderWidth: number = ChartConfig.trafficMarker.width;
  readonly id: string;
   readonly label: {
    readonly yAdjust: number;
    readonly content: string;
    readonly display: boolean;
    readonly position: string;
    readonly backgroundColor: string;
  };
  readonly mode: string = "vertical";
  readonly scaleID: string;
  readonly type: string = "line";
  readonly value: any;
  readonly endValue: any;
  readonly enter: (context, event) => boolean | void;
  readonly leave: (context, event) => boolean | void;

  constructor(args: {
    id: any,
    label: string,
    scaleId: string,
    value: any,
    endValue: any,
    display: boolean,
    setDisplay: any,
  }) {
    this.id = `vline_${args.id}`;
    this.label = {
      backgroundColor: ChartConfig.trafficMarker.backgroundColor,
      content: args.label,
      display: args.display,
      position: "top",
      yAdjust: ChartConfig.trafficMarker.yAdjust,
    };
    this.enter = function (context, event) {
      args.setDisplay(true);
    }
    this.leave = function (context, event) {
      args.setDisplay(false);
    }
    this.scaleID = args.scaleId;
    this.value = args.value;
    this.endValue = args.endValue;
  }
}