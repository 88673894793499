export enum INPUT_TAGS_TYPES {
  URL = "URL",
  EMAIL = "EMAIL",
  IP_ADDRESS = "IP_ADDRESS",
  YEAR = "YEAR",
  NUMBER = "NUMBER",
  USER_IDS = "USER_IDS",
  HOUR_24_FORMAT = "HOUR_24_FORMAT",
}

export enum TAG_INPUT_PLACEHOLDERS {
  DEFAULT = "Type and press enter!",
}
