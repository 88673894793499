import React, { useEffect, useState } from "react";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  authError,
  authPending,
  authSuccess,
  forgotPassword,
  getUserInfo,
  login,
} from "../../../actions/authActions";

import "./login.css";
import { IStore } from "../../../../index.dts";
import Util from "../../../util/Util";
import { useHistory } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { handleLoginError } from "../../../util/common";

enum MODE {
  LOGIN = "LOGIN",
  FORGOT = "FORGOT",
}

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const enterPress = useKeyPress("Enter");
  const [mode, setMode] = useState<MODE>(MODE.LOGIN);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [emailWarningText, setEmailWarningText] = useState<string>("");
  const [passwordWarningText, setPasswordWarningText] = useState<string>("");

  const pending = useSelector(
    (store: IStore) => store.auth.pending,
    shallowEqual
  );

  useEffect(() => {
    if (enterPress) {
      onSignIn();
    }
    // eslint-disable-next-line
  }, [enterPress]);

  const onChangeMode = () => {
    if (mode === MODE.LOGIN) {
      setMode(MODE.FORGOT);
    } else {
      setMode(MODE.LOGIN);
    }
  };

  const onSignIn = () => {
    if (email.trim() !== "" && password.trim() !== "") {
      dispatch(authPending());
      Util.globalSpinner().show();
      login(email, password)
        .then((response) => {
          getUserInfo(response.data.access_token)
            .then((res) => {
              onAuthSuccess(response.data, res.data);
            })
            .catch((error) => {
              dispatch(authError());
              Util.showError(error);
            })
            .finally(() => {
              Util.globalSpinner().hide();
            });
        })
        .catch((error) => {
          dispatch(authError());
          Util.globalSpinner().hide();

          handleLoginError(error);
        });
    } else {
      if (email.trim() === "") {
        setEmailWarningText("Username is required!");
      }
      if (password.trim() === "") {
        setPasswordWarningText("Password is required!");
      }
    }
  };

  const onAuthSuccess = (auth: any, user: any): void => {
    dispatch(authSuccess({ auth: auth, user: user }));

    history.push(Util.PATH_NAMES.DASHBOARD);
  };

  const onEmailBlur = () => {
    if (email.trim() === "") {
      setEmailWarningText("Username is required!");
    } else {
      setEmailWarningText("");
    }
  };

  const onPasswordBlur = () => {
    if (password.trim() === "") {
      setPasswordWarningText("Password is required!");
    } else {
      setPasswordWarningText("");
    }
  };

  const onSend = () => {
    if (Util.validateEmail(email)) {
      forgotPassword(email)
        .then((response) => {
          Util.success("We have sent you an email with reset instructions.");
        })
        .catch((error) => {
          Util.showError(error);
        });
    } else {
      Util.warning("Invalid email");
    }
  };

  // type PasswordProps = {
  //   disabled?: boolean;
  //   style?: React.CSSProperties;
  //   feedback?: boolean;
  //   placeholder?: string;
  //   value?: string;
  //   onChange?: (e: any) => void;
  //   onBlur?: () => void;
  // };

  // const Password: React.FC<PasswordProps> = ({
  //   disabled = false,
  //   style = { width: "100%" },
  //   feedback = false,
  //   placeholder = "Password",
  //   value = "",
  //   onChange = () => {},
  //   onBlur = () => {},
  // }) => {
  //   return (
  //     <input
  //       type="password"
  //       disabled={disabled}
  //       style={style}
  //       placeholder={placeholder}
  //       value={value}
  //       onChange={onChange}
  //       onBlur={onBlur}
  //     />
  //   );
  // };

  const getForm = (mode: MODE): React.ReactNode => {
    if (mode === MODE.LOGIN) {
      return (
        <div id={"form"}>
          {(window.location.href ===
            "https://dealeralchemist.idrove.it/app/login" ||
            window.location.href ===
              "https://dealeralchemist.stage.idrove.it/app/login") && (
            <img
              style={{ width: "100%", marginTop: "2rem" }}
              src={"assets/images/logo_alchemist.png"}
              alt={"Pureinfluencer"}
            />
          )}
          {(window.location.href ===
            "https://dealers.powerpixel.ai/app/login" ||
            window.location.href ===
              "https://powerpixel.idrove.it/app/login") && (
            <img
              style={{ width: "100%", marginTop: "2rem" }}
              src={"assets/images/logo_powerpixel.png"}
              alt={"Pureinfluencer"}
            />
          )}
          {window.location.href ===
            "https://convert.cuneoadvertising.com/app/login" && (
            <img
              style={{ width: "100%", marginTop: "2rem" }}
              src={"assets/images/logo_cuneo.png"}
              alt={"Pureinfluencer"}
            />
          )}
          {window.location.href ===
            "https://autoacquireai.idrove.it/app/login" && (
            <img
              style={{ width: "100%", marginTop: "2rem" }}
              src={"assets/images/logo_autoacquireai.png"}
              alt={"AutoAcquireAI"}
            />
          )}
          {window.location.href ===
            "https://portal.smartpixlpro.com/app/login" && (
            <img
              style={{ width: "100%", marginTop: "2rem" }}
              src={"assets/images/logo_smartpixl.png"}
              alt={"Pureinfluencer"}
            />
          )}
          <div style={{ paddingTop: 20 }}>
            <label style={{ fontSize: 18, fontWeight: "bold" }}>
              Sign in to your account
            </label>
          </div>
          <div style={{ padding: 30, paddingBottom: 10 }}>
            <div style={{ marginBottom: 20 }}>
              <InputText
                disabled={pending}
                style={{ width: "100%" }}
                placeholder={"Email"}
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                onBlur={() => onEmailBlur()}
              />
              {emailWarningText ? (
                <label className={"warning-text"}>{emailWarningText}</label>
              ) : null}
            </div>
            <div style={{ marginBottom: 5 }}>
              <Password
                disabled={pending}
                style={{ width: "100%" }}
                feedback={false}
                placeholder={"Password"}
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
                onBlur={onPasswordBlur}
              />
              {passwordWarningText ? (
                <label className={"warning-text"}>{passwordWarningText}</label>
              ) : null}
            </div>
            <div>
              <label
                onClick={onChangeMode}
                style={{ fontSize: 12, color: "#007ad9", cursor: "pointer" }}
              >
                Forgot password?
              </label>
            </div>
            <div style={{ marginTop: 20 }}>
              <Button
                disabled={pending}
                onClick={onSignIn}
                style={{ width: "100%" }}
                label="Sign in"
                className="p-button-raised p-button-rounded"
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id={"form"}>
          {(window.location.href ===
            "https://dealeralchemist.idrove.it/app/login" ||
            window.location.href ===
              "https://dealeralchemist.stage.idrove.it/app/login") && (
            <img
              style={{ width: "100%", marginTop: "2rem" }}
              src={"assets/images/logo_alchemist.png"}
              alt={"Pureinfluencer"}
            />
          )}
          {(window.location.href ===
            "https://dealers.powerpixel.ai/app/login" ||
            window.location.href ===
              "https://powerpixel.idrove.it/app/login") && (
            <img
              style={{ width: "100%", marginTop: "2rem" }}
              src={"assets/images/logo_powerpixel.png"}
              alt={"Pureinfluencer"}
            />
          )}
          {window.location.href ===
            "https://convert.cuneoadvertising.com/app/login" && (
            <img
              style={{ width: "100%", marginTop: "2rem" }}
              src={"assets/images/logo_cuneo.png"}
              alt={"Pureinfluencer"}
            />
          )}
          {window.location.href ===
            "https://autoacquireai.idrove.it/app/login" && (
            <img
              style={{ width: "100%", marginTop: "2rem" }}
              src={"assets/images/logo_autoacquireai.png"}
              alt={"AutoAcquireAI"}
            />
          )}
          {window.location.href ===
            "https://portal.smartpixlpro.com/app/login" && (
            <img
              style={{ width: "100%", marginTop: "2rem" }}
              src={"assets/images/logo_smartpixl.png"}
              alt={"Pureinfluencer"}
            />
          )}
          <div style={{ paddingTop: 20 }}>
            <label style={{ fontSize: 18, fontWeight: "bold" }}>
              Forgot your password?
            </label>
          </div>
          <div style={{ padding: 30, paddingBottom: 20 }}>
            <div style={{ marginBottom: 20 }}>
              <InputText
                value={email}
                maxLength={64}
                style={{ width: "100%" }}
                placeholder={"Your Email"}
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <Button
                style={{ width: "100%" }}
                label="Send"
                className="p-button-raised p-button-rounded"
                onClick={onSend}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Button
                style={{ width: "100%" }}
                onClick={onChangeMode}
                label="Back"
                className="p-button-raised p-button-rounded"
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div id={"login-view"}>
      {window.location.href === "https://dealeralchemist.idrove.it/app/login" ||
      window.location.href ===
        "https://dealeralchemist.stage.idrove.it/app/login" ||
      window.location.href === "https://dealers.powerpixel.ai/app/login" ||
      window.location.href === "https://powerpixel.idrove.it/app/login" ||
      window.location.href === "https://autoacquireai.idrove.it/app/login" ||
      window.location.href ===
        "https://convert.cuneoadvertising.com/app/login" ||
      window.location.href === "https://portal.smartpixlpro.com/app/login" ? (
        <div id={"wrapper"}>{getForm(mode)}</div>
      ) : window.location.href ===
        "https://clickheredigital.idrove.it/app/login" ? (
        <div id={"wrapper"}>
          <img
            style={{ width: 300, marginBottom: "-4rem", marginTop: "1rem" }}
            src={"assets/images/click_logo_vertical.png"}
            alt={"Click Here Digital"}
          />
          {getForm(mode)}
        </div>
      ) : (
        <div id={"wrapper"}>
          <img
            style={{ width: 300 }}
            src={"assets/images/logo.png"}
            alt={"Pureinfluencer"}
          />
          {getForm(mode)}
        </div>
      )}
    </div>
  );
}
