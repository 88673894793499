import React, { useEffect, useState } from "react";
import ChartRace from "react-chart-race";

const RealTimeHorizontalBar = () => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    handleChange();
    setInterval(() => {
      handleChange();
    }, 2000);
  }, []);

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function handleChange() {
    const dataNew = [
      { id: 0, title: "Facebook Leads", value: 38, color: "#3fc42d" },
      {
        id: 1,
        title: "Engagement Score",
        value: getRandomInt(10, 90),
        color: "#c33178",
      },
      {
        id: 2,
        title: "Paid Leads",
        value: getRandomInt(10, 90),
        color: "#423bce",
      },
      { id: 3, title: "Total Views", value: 58, color: "#c8303b" },
    ];
    setData(dataNew);
  }

  return (
    <div className="brands-grouping-div">
      <ChartRace
        data={data}
        backgroundColor="rgba(179, 171, 171, 0.5)"
        width={800}
        padding={12}
        itemHeight={58}
        gap={12}
        titleStyle={{ font: "normal 400 13px Arial", color: "#000" }}
        valueStyle={{
          font: "normal 400 11px Arial",
          color: "#000",
        }}
      />
    </div>
  );
};

export default RealTimeHorizontalBar;
