import React, { useEffect, useState } from "react";
import "./configuration.css";
import { ConfigurationFields, ConfigurationKeys } from "../../../util/Enums";
import {
  IBaseDealershipDto,
  IDealershipContext,
  IScoreConfig,
} from "../../../../index.dts";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { getScoreConfig, saveScoreConfig } from "../../../actions/kaninActions";
import Util from "../../../util/Util";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import AsyncSelect from "react-select/async";
import { groupBadgeStyles, groupStyles } from "../../../util/styles";

interface IProps {
  dealershipContext: IDealershipContext;
}

export function Configuration(props: IProps) {
  const forceUpdate = useForceUpdate();

  const [loading, setLoading] = useState<boolean>(true);

  const [dealership, setDealership] = useState<IBaseDealershipDto>(
    props.dealershipContext
  );

  const [dataMap, setDataMap] = useState<Map<ConfigurationKeys, IScoreConfig>>(
    new Map()
  );
  const [watermarkLevel, setWatermarkLevel] = useState<number>();

  useEffect(() => {
    if (props.dealershipContext.id !== dealership.id) {
      setDealership(props.dealershipContext);
    }
    // eslint-disable-next-line
  }, [props.dealershipContext]);

  useEffect(() => {
    getData(dealership.externalRefId);
    // eslint-disable-next-line
  }, [dealership.externalRefId]);

  const getData = (dealershipRefId: string) => {
    setLoading(true);
    getScoreConfig(dealershipRefId)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => Util.showError(error))
      .finally(() => setLoading(false));
  };

  const setData = (data: any): void => {
    setDataMap(
      new Map(
        Object.entries(data)
          .filter((entry: any) =>
            Object.keys(ConfigurationKeys).includes(entry[0])
          )
          .map((entry: any) => {
            // @ts-ignore
            return [
              entry[0],
              { ...entry[1], ...{ property: ConfigurationKeys[entry[0]] } },
            ];
          })
      )
    );
    setWatermarkLevel(data.watermarkLevel);
  };

  const onChange = (
    event: any,
    props: IScoreConfig,
    field: ConfigurationFields
  ): void => {
    if (event.value !== null) {
      (props[field] as number) = event.value;
      forceUpdate();
    }
  };

  const body = (
    props: IScoreConfig,
    field: ConfigurationFields,
    min?: number,
    max?: number,
    title?: string
  ): React.ReactElement => {
    return (
      <InputNumber
        style={{ width: "100%" }}
        disabled={props[field] === null}
        inputStyle={{ width: "calc(100% - 25px)" }}
        value={
          props[field] !== null && !isNaN(props[field] as number)
            ? (props[field] as number)
            : undefined
        }
        showButtons={true}
        mode={"decimal"}
        min={min}
        max={max}
        tooltip={title}
        minFractionDigits={field === ConfigurationFields.MULTIPLAYER ? 1 : 0}
        maxFractionDigits={field === ConfigurationFields.MULTIPLAYER ? 5 : 0}
        onChange={(event) => onChange(event, props, field)}
      />
    );
  };

  const getMin = (
    col: ConfigurationFields,
    property: string
  ): number | undefined => {
    return undefined;
  };

  const getMax = (
    col: ConfigurationFields,
    property: string
  ): number | undefined => {
    switch (col) {
      case ConfigurationFields.MULTIPLAYER:
        switch (property.toLowerCase()) {
          case "previous visit":
          case "total time away":
            return -1;
          default:
            return undefined;
        }
      default:
        return undefined;
    }
  };

  const getTitle = (
    col: ConfigurationFields,
    property: string
  ): string | undefined => {
    switch (col) {
      case ConfigurationFields.MULTIPLAYER:
        switch (property.toLowerCase()) {
          case "previous visit":
          case "total time away":
            return `${property} ${col} should be less than zero!`;
          default:
            return undefined;
        }
      default:
        return undefined;
    }
  };

  const multiplierBody = (props: IScoreConfig): React.ReactElement => {
    return body(
      props,
      ConfigurationFields.MULTIPLAYER,
      getMin(ConfigurationFields.MULTIPLAYER, props.property),
      getMax(ConfigurationFields.MULTIPLAYER, props.property),
      getTitle(ConfigurationFields.MULTIPLAYER, props.property)
    );
  };

  const limitBody = (props: IScoreConfig): React.ReactElement => {
    return body(props, ConfigurationFields.LIMIT);
  };

  const thresholdBody = (props: IScoreConfig): React.ReactElement => {
    return body(props, ConfigurationFields.THRESHOLD);
  };

  const onSave = () => {
    if (dealership) {
      const dto: any = Array.from(dataMap).reduce(
        (
          accumulator: {
            [key: string]: {
              [ConfigurationFields.LIMIT]: number | null;
              [ConfigurationFields.MULTIPLAYER]: number;
              [ConfigurationFields.THRESHOLD]: number;
            };
          },
          entry: any
        ) => {
          accumulator[entry[0]] = {
            limit: entry[1].limit || null,
            multiplier: entry[1].multiplier,
            threshold: entry[1].threshold,
          };
          return accumulator;
        },
        {}
      );

      dto["watermarkLevel"] = watermarkLevel;
      dto["dealershipId"] = dealership.externalRefId;

      Util.globalSpinner().show();
      saveScoreConfig(dealership.externalRefId, dto)
        .then(() => {
          Util.success("Score Config saved successfully.");
        })
        .catch((error) => Util.showError(error))
        .finally(() => Util.globalSpinner().hide());
    } else {
      Util.warning(Util.defaultErrorMessage);
    }
  };

  const onDealershipChange = (dealership: any) => {
    setDealership({
      id: dealership.value,
      name: dealership.label,
      deleted: dealership.deleted,
      externalRefId: dealership.externalRefId,
    });
  };
  const formatGroupLabel = (data: any) => {
    return (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.total}</span>
      </div>
    );
  };

  const promiseDealerships = (inputValue: string): any =>
    new Promise((resolve) => {
      Util.promiseMyDealerships(inputValue)
        .then((response: any) => {
          const options = Util._.get(response, "[0].options", []);

          options.unshift({
            value: 0,
            label: "GLOBAL",
            externalRefId: "GLOBAL",
          });

          Util._.set(response, "[0].options", options);

          resolve(response);
        })
        .catch(() => resolve([]));
    });

  return (
    <div id={"configuration-view"}>
      <DataTable
        //responsive={true}
        columnResizeMode={"expand"}
        loading={loading}
        header={
          <div style={{ maxWidth: 250 }}>
            <AsyncSelect
              menuPosition={"fixed"}
              cacheOptions
              defaultOptions
              value={{
                value: dealership.id,
                label: dealership.name,
              }}
              loadOptions={promiseDealerships}
              formatGroupLabel={formatGroupLabel}
              placeholder={"Select Dealership"}
              onChange={(data: any) => onDealershipChange(data)}
            />
          </div>
        }
        footer={
          <div style={{ display: "flex" }}>
            <Button
              className="no-icon-buttons"
              onClick={onSave}
              label={"Save"}
            />
            <div style={{ marginLeft: "auto" }}>
              <label
                style={{
                  marginRight: 5,
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                Watermark Level
              </label>
              <InputNumber
                style={{ maxWidth: "300px" }}
                inputStyle={{ width: "100%" }}
                showButtons={true}
                value={watermarkLevel}
                onChange={(event: any) => setWatermarkLevel(event.value)}
              />
            </div>
          </div>
        }
        value={Array.from(dataMap.values())}
      >
        <Column field={"property"} header={"Property"} />
        <Column
          field={"multiplier"}
          header={"Multiplier"}
          body={multiplierBody}
        />
        <Column field={"limit"} header={"Limit"} body={limitBody} />
        <Column field={"threshold"} header={"Threshold"} body={thresholdBody} />
      </DataTable>
    </div>
  );
}

export default Configuration;
