import React from "react";
import "./my_traffic.css";
import { TabPanel, TabView, TabViewTabChangeParams } from "primereact/tabview";
import Groups from "./groups/Groups";
import {
  CHAT_HISTORY_TYPE,
  MyTrafficTabKeys,
  PERMISSIONS,
} from "../../util/Enums";
import CampaignReport from "./reports/CampaignReport";
import Campaigns from "./campaigns/Campaigns";
import Visualizer from "./visualizer/Visualizer";
import Configuration from "./configuration/Configuration";
import ChatGreetings from "../../components/my_traffic/chat_greetings/ChatGreetings";
import Notifications from "../../components/my_traffic/notifications/Notifications";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../index.dts";
import Stats from "../../components/my_traffic/stats/Stats";
import EngagementLeads from "../../components/my_traffic/engagement_leads/EngagementLeads";
import SemVendors from "../../components/my_traffic/sem_vendors/SemVendors";
import { updateMyTrafficTabIndex } from "../../actions/userActions";
import ChatHistory, {
  CHAT_HISTORY_ACTIONS,
} from "../../components/my_traffic/chat_history/ChatHistory";
import Util from "../../util/Util";
import { useTranslation } from "react-i18next";
import Acquisitions from "../../components/my_traffic/acquisitions/Acquisitions";
import FraudGuard from "../../components/my_traffic/fraud_guard/FraudGuard";
import VdpPerformanceView from "../../components/my_traffic/vdp_performance_view/VdpPerformanceView";

export const MY_TRAFFIC_TABS: {
  [key: string]: { name: any; permissions: PERMISSIONS[] };
} = {
  [MyTrafficTabKeys.VISUALIZER]: { name: "Visualizer", permissions: [] },
  [MyTrafficTabKeys.SEM_VENDORS]: {
    name: "myProfitRetainer",
    permissions: [PERMISSIONS.RTS_STATISTICS],
  },
  [MyTrafficTabKeys.VDP_PERFORMANCE_VIEW]: {
    name: "VDP Performance View",
    permissions: [PERMISSIONS.MY_CAMPAIGNS_ACCESS],
  },
  [MyTrafficTabKeys.CHAT_GREETING]: {
    name: (
      <div>
        <img src={"assets/icons/chat.png"} alt={""} className={"icon"} />
        Chat Greetings
      </div>
    ),
    permissions: [PERMISSIONS.RTS_CHAT_MANAGEMENT],
  },
  [MyTrafficTabKeys.CHAT_HISTORY]: { name: "Chat History", permissions: [] },
  [MyTrafficTabKeys.STATS]: {
    name: (
      <div>
        <img src={"assets/icons/user_report.jpg"} alt={""} className={"icon"} />
        <span>Analytics</span>
      </div>
    ),
    permissions: [PERMISSIONS.RTS_STATISTICS],
  },
  [MyTrafficTabKeys.ENG_LEADS]: {
    name: (
      <div>
        <img src={"assets/icons/lead.png"} alt={""} className={"icon"} />
        Eng. Leads
      </div>
    ),
    permissions: [PERMISSIONS.RTS_STATISTICS, PERMISSIONS.RTS_USER_STATISTICS],
  },
  [MyTrafficTabKeys.NOTIFICATIONS]: {
    name: (
      <div>
        <img src={"assets/icons/campaign.svg"} alt={""} className={"icon"} />
        Notifications
      </div>
    ),
    permissions: [PERMISSIONS.RTS_NOTIFICATIONS],
  },
  [MyTrafficTabKeys.ACQUISITIONS]: {
    name: (
      <div>
        <img src={"assets/icons/organic.svg"} alt={""} className={"icon"} />
        Acquisitions
      </div>
    ),
    permissions: [PERMISSIONS.RTS_NOTIFICATIONS],
  },
  [MyTrafficTabKeys.GROUPS]: {
    name: "Groups",
    permissions: [PERMISSIONS.RTS_GROUPS_MANAGEMENT],
  },
  [MyTrafficTabKeys.CAMPAIGNS]: {
    name: "Campaigns",
    permissions: [PERMISSIONS.RTS_GROUP_CAMPAIGNS_MANAGEMENT],
  },
  [MyTrafficTabKeys.CAMPAIGN_REPORTS]: {
    name: "Campaign Reports",
    permissions: [PERMISSIONS.RTS_GROUP_CAMPAIGNS_MANAGEMENT],
  },
  [MyTrafficTabKeys.CONFIGURATION]: {
    name: "Configuration",
    permissions: [PERMISSIONS.RTS_SCORING_MODEL_MANAGEMENT],
  },
  [MyTrafficTabKeys.FRAUD_GUARD]: {
    name: (
      <div>
        <img src={"assets/icons/wheel.svg"} alt={""} className={"icon"} />
        Fraud Guard
      </div>
    ),
    permissions: [PERMISSIONS.RTS_NOTIFICATIONS],
  },
};

export const MY_TRAFFIC_TAB_KEY = "my_traffic_active_tab";

export function MyTraffic() {
  const { t }: any = useTranslation();
  const dispatch = useDispatch();
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  const activeIndex = useSelector(
    (store: IStore) => store.myTraffic.myTrafficActiveTabIndex,
    shallowEqual
  );

  const onTabChange = (e: TabViewTabChangeParams) => {
    dispatch(updateMyTrafficTabIndex(e.index));
  };

  if (dealershipContext.id === 0) {
    return null;
  }

  const getMyTrafficMenu = (): any[] => {
    return Object.entries(MY_TRAFFIC_TABS)
      .filter((entry) => Util.hasAnyAuthority(...entry[1].permissions))
      .map((entry) => {
        const key = entry[0];
        const value = entry[1];

        switch (key) {
          case MyTrafficTabKeys.VISUALIZER:
            return (
              <TabPanel key={key} header={value.name} leftIcon="pi pi-eye">
                <Visualizer
                  dealershipId={dealershipContext.id}
                  externalRefId={dealershipContext.externalRefId}
                  dealershipTimezone={dealershipContext.timezone}
                  chatMode={dealershipContext.chatMode}
                />
              </TabPanel>
            );
          case MyTrafficTabKeys.SEM_VENDORS:
            return (
              <TabPanel key={key} header={value.name} leftIcon="pi pi-dollar">
                <SemVendors
                  dealershipId={dealershipContext.id}
                  dealershipRefId={dealershipContext.externalRefId}
                  dealershipTimezone={dealershipContext.timezone}
                />
              </TabPanel>
            );
          case MyTrafficTabKeys.VDP_PERFORMANCE_VIEW:
            return dealershipContext.myCampaignsEnabled ? (
              <TabPanel
                key={key}
                header={value.name}
                contentClassName={"stats-tabview-container"}
                leftIcon="pi pi-chart-bar"
              >
                <VdpPerformanceView
                  dealershipId={dealershipContext.id}
                  dealershipTimezone={dealershipContext.timezone}
                  dealershipName={dealershipContext.name}
                  externalRefId={dealershipContext.externalRefId}
                  dealershipCreated={dealershipContext.created}
                  persistentChatEnabled={
                    dealershipContext.persistentChatEnabled
                  }
                />
              </TabPanel>
            ) : null;
          case MyTrafficTabKeys.CHAT_GREETING:
            return (
              <TabPanel key={key} header={value.name}>
                <ChatGreetings />
              </TabPanel>
            );
          case MyTrafficTabKeys.CHAT_HISTORY:
            return (
              <TabPanel
                key={key}
                header={value.name}
                leftIcon={"pi pi-fw pi-send"}
              >
                <ChatHistory
                  actions={[
                    CHAT_HISTORY_ACTIONS.OPEN_CHAT,
                    CHAT_HISTORY_ACTIONS.VIEW_HISTORY,
                  ]}
                  actionStyle={{ width: 175 }}
                  chatType={CHAT_HISTORY_TYPE.all}
                  chatMode={dealershipContext.chatMode}
                  dealershipId={dealershipContext.id}
                  dealershipRefId={dealershipContext.externalRefId}
                />
              </TabPanel>
            );
          case MyTrafficTabKeys.STATS:
            return (
              <TabPanel
                key={key}
                header={value.name}
                contentClassName={"stats-tabview-container"}
              >
                <Stats
                  dealershipId={dealershipContext.id}
                  dealershipName={dealershipContext.name}
                  externalRefId={dealershipContext.externalRefId}
                  dealershipCreated={dealershipContext.created}
                  persistentChatEnabled={
                    dealershipContext.persistentChatEnabled
                  }
                />
              </TabPanel>
            );
          case MyTrafficTabKeys.ENG_LEADS:
            return (
              <TabPanel key={key} header={value.name}>
                <EngagementLeads
                  dealershipId={dealershipContext.id}
                  dealershipTimezone={dealershipContext.timezone}
                  dealershipRefId={dealershipContext.externalRefId}
                  chatMode={dealershipContext.chatMode}
                />
              </TabPanel>
            );
          case MyTrafficTabKeys.NOTIFICATIONS:
            return (
              <TabPanel key={key} header={value.name}>
                <Notifications
                  dealershipId={dealershipContext.id}
                  dealershipExtRefId={dealershipContext.externalRefId}
                />
              </TabPanel>
            );
          case MyTrafficTabKeys.ACQUISITIONS:
            return dealershipContext.autoAcquireNoEngagementEnabled ||
              dealershipContext.leadRecordsEnabled ||
              dealershipContext.serviceRecordsEnabled ||
              dealershipContext.iOfferEnabled ? (
              <TabPanel key={key} header={value.name}>
                <Acquisitions
                  dealershipId={dealershipContext.id}
                  dealershipName={dealershipContext.name}
                />
              </TabPanel>
            ) : null;

          case MyTrafficTabKeys.GROUPS:
            return (
              <TabPanel key={key} header={value.name} leftIcon="pi pi-users">
                <Groups />
              </TabPanel>
            );
          case MyTrafficTabKeys.CAMPAIGNS:
            return (
              <TabPanel key={key} header={value.name} leftIcon="pi pi-search">
                <Campaigns activeIndex={activeIndex} />
              </TabPanel>
            );
          case MyTrafficTabKeys.CAMPAIGN_REPORTS:
            return (
              <TabPanel
                key={key}
                header={value.name}
                leftIcon="pi pi-chart-line"
              >
                <CampaignReport activeIndex={activeIndex} />
              </TabPanel>
            );
          case MyTrafficTabKeys.CONFIGURATION:
            return (
              <TabPanel key={key} header={value.name} leftIcon="pi pi-cog">
                <Configuration dealershipContext={dealershipContext} />
              </TabPanel>
            );
          case MyTrafficTabKeys.FRAUD_GUARD:
            return dealershipContext.fraudGuardEnabled ? (
              <TabPanel key={key} header={value.name}>
                <FraudGuard dealershipId={dealershipContext.id} />
              </TabPanel>
            ) : null;
          default:
            return null;
        }
      });
  };

  const content = (): React.ReactElement => {
    if (!dealershipContext.rtsEnabled) {
      return <div style={{ color: "red" }}>{t("myTrafficIsDisabled")}</div>;
    }

    return (
      <div style={{ padding: 0 }} className="card">
        <TabView
          onTabChange={onTabChange}
          activeIndex={activeIndex}
          renderActiveOnly={true}
          className="tabview-custom"
        >
          {getMyTrafficMenu()}
        </TabView>
      </div>
    );
  };

  return <div id={"my-traffic-view"}>{content()}</div>;
}

export default MyTraffic;
