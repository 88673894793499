import {fill, isArray, isEmpty, slice, forEach} from "lodash";
import {ISmartSpendFilterData} from "../../smart_spend_filter/SmartSpendFilter";
import {ProductTypes} from "../../../util/enum/product_types";
import {sumArrays} from "../../../util/common";

export class MakeModelFilterResolver {
  private static _getEmpty(size?: number): number[] {
    return fill(Array(size), 0);
  }

  private static _sumSelection(args: {
    make?: string,
    source: any,
    container: number[][],
    model?: string
  }): void {

    const make: any = args.make;
    const model: any = args.model;

    if (isEmpty(args.model)) {
      const makeTotal = args.source?.[make]?.daily_total;

      isArray(makeTotal) && args.container.push(makeTotal);
    } else {
      const modelTotal = args.source?.[make]?.[model]?.daily_total;
      isArray(modelTotal) && args.container.push(modelTotal);
    }
  }

  public resolve(source: any, filter: ISmartSpendFilterData, make?: string, model?: string, size?: number): number[] {
    let result;
    let subResult: any;

    switch (filter.product?.type) {
      case ProductTypes.all:
        subResult = source;
        break;
      case ProductTypes.certified:
        subResult = source?.certified;
        break;
      case ProductTypes.new:
        subResult = source?.new;
        break;
      case ProductTypes.used:
        subResult = source?.used;
        break;
      case ProductTypes.service:
        subResult = source?.service;
        break;
      default:
        throw new Error(`Unknown product type: ${filter.product?.type}`);
    }

    if (isEmpty(filter.options?.makes)) {
      result = subResult?.daily_total;
    } else {
      const arrays: number[][] = [];

      if (filter.product?.type === ProductTypes.all) {
        forEach(subResult, (value) => {
          MakeModelFilterResolver._sumSelection({
            container: arrays,
            make: make,
            model,
            source: value
          });
        });
      } else {
        MakeModelFilterResolver._sumSelection({
          container: arrays,
          make: make,
          model,
          source: subResult,
        });
      }
      //
      result = sumArrays(...arrays);
    }

    return result ? slice(result) : MakeModelFilterResolver._getEmpty(size);
  }
}