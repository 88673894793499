import IActiveVisitorDetails from "../components/visitor_details/active_visitor_details";
import { IDealershipVisitor } from "../../index.dts";

export default class DealershipActiveVisitorDetailsAdapter implements IActiveVisitorDetails {
  readonly engagementScore: number;
  readonly make: string;
  readonly model: string;
  readonly pageClass: string;
  readonly refId: string;
  readonly sessionTime: number;
  readonly url: string;
  readonly visits: {
    readonly organic: number;
    readonly paid: number;
  };

  constructor(data: IDealershipVisitor) {
    this.engagementScore = data.eScore;
    this.make = data.make;
    this.model = data.model;
    this.pageClass = data.pageClass;
    this.sessionTime = data.sessionTime;
    this.url = data.currentUrl;
    this.refId = data.visitorID;
    this.visits = {
      organic: data.numPriorOrganicVisitsInLast90Days,
      paid: data.numPriorPaidVisitsInLast90Days
    };
  }
}