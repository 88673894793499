import React, { useEffect, useRef, useState } from "react";
import "./roles.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getRoleList } from "../../actions/userManagementActions";
import Util from "../../util/Util";
import { IPermission, IRole, IStore } from "../../../index.dts";
import { shallowEqual, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import RoleAndPermissions from "../../components/role_and_permissions/RoleAndPermissions";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import Axios from "../../config/Axios";
import {
  createRole,
  deleteRole,
  getPermissionList,
} from "../../actions/roleManagementActions";
import { confirmAlert } from "react-confirm-alert";
import deepClone from "deep-clone";
import { useHistory } from "react-router-dom";
import CloseButton from "../../components/close_button/CloseButton";
import { useForceUpdate } from "../../hooks/useForceUpdate";

const hash = require("object-hash");

let isMounted: boolean = false;
let searchTimeout: any;
let searchTimeoutInterval: number = 500;

export function Roles() {
  const history = useHistory();
  const forceUpdate = useForceUpdate();
  const unblockHandle = useRef<any>();
  const rolePermissionsRef: any = useRef();

  const [showSidebar, setShowSidebar] = useState<boolean>(false);

  const [limit] = useState<number>(40);
  const [filterMap] = useState<
    Map<string, { field: string; value: any; operation: string }>
  >(new Map());

  const [tempRole, setTempRole] = useState<IRole>();
  const [selectedRole, setSelectedRole] = useState<IRole>();
  const [roleList, setRoleList] = useState<IRole[]>([]);
  const [permissionsList, setPermissionsList] = useState<IPermission[]>([]);

  const [newRoleName, setNewRoleName] = useState<string>("");

  const globalSpinner = useSelector(
    (store: IStore) => store.myTraffic.globalSpinner,
    shallowEqual
  );

  useEffect(() => {
    unblockHandle.current = history.block((targetLocation: any) => {
      return onLeave(null, targetLocation);
    });
    return () => {
      unblockHandle.current.current && unblockHandle.current.current();
    };
    // eslint-disable-next-line
  }, [history.location, tempRole, selectedRole]);

  useEffect(() => {
    Axios.all([getAllRoles(0), getPermissionList()])
      .then((responses: any[]) => {
        isMounted = true;
        setPermissionsList(
          responses[1].data.sort((a: any, b: any) =>
            a.description.localeCompare(b.description)
          )
        );
        setSelectedRole(responses[0].data.content[0]);
      })
      .catch(() => {
        isMounted = true;
      });
    return () => {
      isMounted = false;
      Util.removeAlert();

      history.block(() => {
        return true;
      });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTempRole(deepClone(selectedRole));
  }, [selectedRole]);

  useEffect(() => {
    if (isMounted) {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        getAllRoles(0).then((response: any) => {
          setSelectedRole(response.data.content[0]);
        });
      }, searchTimeoutInterval);
    }
    // eslint-disable-next-line
  }, [hash(filterMap)]);

  const getAllRoles = (page: number) =>
    new Promise((resolve, reject) => {
      globalSpinner.show();
      getRoleList({
        filter: Array.from(filterMap.values()),
        sorting: [],
        paging: { page: page, pageLimit: limit },
      })
        .then((response) => {
          setRoleList(response.data.content);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
          Util.showError(error);
        })
        .finally(() => {
          globalSpinner.hide();
        });
    });

  const equal = (role1: IRole, role2: IRole): boolean => {
    if (role1.name !== role2.name) {
      return false;
    }
    if (role1.permissions.length !== role2.permissions.length) {
      return false;
    }
    return role1?.permissions.every((role1_permissions) =>
      role2?.permissions.some(
        (role2_permissions) => role2_permissions.id === role1_permissions.id
      )
    );
  };

  const onLeave = (newRole: IRole | null, targetLocation?: any) => {
    if (selectedRole && tempRole && !equal(selectedRole, tempRole)) {
      confirmAlert({
        title: "There are unsaved changes!",
        message: "Discard local changes, or save to continue!",
        buttons: [
          {
            label: "Save",
            className: "confirm-save-btn",
            onClick: () => {
              rolePermissionsRef.current.save(selectedRole).then(() => {
                if (targetLocation) {
                  setTempRole(selectedRole);
                  setTimeout(() => {
                    history.push(targetLocation);
                  }, 0);
                } else if (newRole) {
                  setSelectedRole(newRole);
                }
              });
            },
          },
          {
            label: "Discard",
            onClick: () => {
              if (targetLocation) {
                setTempRole(selectedRole);
                setTimeout(() => {
                  history.push(targetLocation);
                }, 0);
              } else if (newRole) {
                selectedRole.name = tempRole.name;
                selectedRole.permissions = [...tempRole.permissions];
                setSelectedRole(newRole);
              }
            },
            className: "confirm-discard-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
      return false;
    }
    if (newRole) {
      setSelectedRole(newRole);
    }
    return true;
  };

  const onFilter = (e: any) => {
    const value = e.target.value;
    if (value.trim()) {
      filterMap.set("name", { field: "name", value: value, operation: "like" });
    } else {
      filterMap.delete("name");
    }
    forceUpdate();
  };

  const getRoleListNodes = (roleList: IRole[]): React.ReactNode => {
    return roleList
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((role) => {
        return (
          <div
            key={role.id}
            style={{ color: selectedRole?.id === role.id ? "#007ad9" : "gray" }}
            className={"role-list-element"}
          >
            <label onClick={() => onLeave(role)} className={"role-list-label"}>
              {role.name}
            </label>
          </div>
        );
      });
  };

  const onNew = () => {
    setNewRoleName("");

    setShowSidebar(true);
  };

  const onCreateNewRole = () => {
    if (!Util.isEmpty(newRoleName)) {
      globalSpinner.show();
      createRole({ name: newRoleName })
        .then((response) => {
          if (!response.data.permissions) {
            response.data.permissions = [];
          }
          setSelectedRole(response.data);
          setTempRole(deepClone(response.data));
          roleList.push(response.data);

          setShowSidebar(false);

          Util.success("Role created successfully.");
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => {
          globalSpinner.hide();
        });
    } else {
      Util.warning("Invalid role name.");
    }
  };

  const onDelete = () => {
    if (selectedRole) {
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure you want to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () =>
              deleteRole(selectedRole.id)
                .then(() => {
                  const index = roleList.findIndex(
                    (role) => role.id === selectedRole.id
                  );
                  if (index > -1) {
                    roleList.splice(index, 1);
                  }
                  setSelectedRole(roleList[0]);
                  Util.success("Role deleted successfully.");
                })
                .catch((error) => {
                  Util.showError(error);
                }),
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    }
  };

  const onAfterSave = (role: IRole) => {
    setTempRole(deepClone(role));
  };

  const onHide = (): void => {
    setShowSidebar(false);
  };

  return (
    <div id={"roles-management"}>
      <div className={"p-grid"}>
        <div className="p-col-12 p-xl-2 p-lg-3">
          <div
            style={{ height: "100%" }}
            className="card card-w-title datatable-demo"
          >
            <div>
              <label id={"title"}>Hiring roles</label>
            </div>
            <span style={{ fontSize: 14 }}>
              Customize different level of access for your team members.
            </span>
            <div id={"filter-and-roles-container"}>
              <div style={{ display: "flex" }}>
                <InputText
                  onChange={onFilter}
                  placeholder={"Search"}
                  style={{ width: "calc(100% - 85px)", marginRight: 5 }}
                />
                <Button
                  className="no-icon-buttons"
                  id="apply-btn"
                  key={1}
                  style={{ marginRight: 5 }}
                  onClick={onNew}
                  label={"New"}
                />
              </div>
              <div id={"roles-container"}>{getRoleListNodes(roleList)}</div>
            </div>
          </div>
        </div>
        <div className="p-col-12 p-xl-10 p-lg-9">
          <div
            style={{ height: "100%" }}
            className="card card-w-title datatable-demo"
          >
            {selectedRole && permissionsList.length > 0 ? (
              <RoleAndPermissions
                role={selectedRole}
                permissions={permissionsList}
                onDelete={onDelete}
                onAfterSave={onAfterSave}
                ref={rolePermissionsRef}
              />
            ) : null}
          </div>
        </div>
      </div>

      <Sidebar
        style={{ width: 350 }}
        position={"right"}
        visible={showSidebar}
        dismissable={false}
        showCloseIcon={false}
        onHide={onHide}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <CloseButton onHide={onHide} />
          <div
            style={{
              marginTop: 25,
              marginLeft: -15,
              marginRight: -15,
              borderBottom: "1px solid #afa6a6",
            }}
          >
            <label style={{ fontSize: 20, marginLeft: 15 }}>
              Create New User Role
            </label>
          </div>
          <div id={"new-role-container"}>
            <InputText
              value={newRoleName}
              onChange={(e: any) => setNewRoleName(e.target.value)}
              style={{ width: "100%", marginBottom: 10 }}
              placeholder={"Name"}
            />
            <Button
              onClick={onCreateNewRole}
              style={{ float: "right" }}
              label={"Save"}
            />
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default Roles;
