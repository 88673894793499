import {IPageable, ITrafficMakeModel, ITrafficMarker, ITrafficMarkerRequestDto} from "../../index.dts";

import Axios from "../config/Axios";

export const getTrafficMarker = (id: number) => Axios.instance.get(`traffic-markers/${id}`);
export const deleteTrafficMarkers = (ids: number[]) => Axios.all(ids.map(id => Axios.instance.delete(`traffic-markers/${id}`)));
export const createTrafficMarker = (dto: ITrafficMarker) => Axios.instance.post(`traffic-markers`, dto);
export const updateTrafficMarker = (id: number, dto: ITrafficMarker) => Axios.instance.put(`traffic-markers/${id}`, dto);
export const getTrafficMarkers = (dto: ITrafficMarkerRequestDto): Promise<IPageable> => Axios.instance.post(`traffic-markers/list`, dto);
export const getTrafficMarkersInRange = (dto: { dealershipId: number, startDate: string, endDate: string }) => Axios.instance.post(`traffic-markers/list/period`, dto);
export const getTrafficMakes = (dealershipExtRefId: string) => Axios.instance.get(`kanin/metas?siteid=${dealershipExtRefId}`);

export function listTrafficMarkers(args: {
  dealershipId: number,
  startDate: string,
  endDate: string,
}): Promise<ITrafficMarker[]> {
  return new Promise((resolve, reject) => {
    getTrafficMarkersInRange(args)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function listTrafficMakes(dealershipExtRefId: string): Promise<ITrafficMakeModel> {
  return new Promise((resolve, reject) => {
    getTrafficMakes(dealershipExtRefId)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}