import React, { forwardRef, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { TOP_BAR_ITEMS } from "./util/Enums";
import Util from "./util/Util";
import { ISelectValue, IStore } from "../index.dts";
import { ProgressSpinner } from "primereact/progressspinner";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getMyDealerships } from "./actions/dealershipActions";
import {
  AsyncPaginate,
  reduceGroupedOptions,
} from "react-select-async-paginate";
import { updateDevice } from "./actions/deviceActions";
import { setDealershipContext } from "./actions/authActions";
import UserNotifications from "./components/user_notifications/UserNotifications";
import { ProgressBar } from "primereact/progressbar";

interface IProps {
  userName: string;
  darkTheme?: boolean;
  activeTopBarItem?: string;
  topBarMenuActive?: boolean;
  onTopBarItemClick: (e: any) => void;
  onMenuButtonClick: (e: any) => void;
  onTopBarMenuButtonClick: (e: any) => void;
}

const AppTopBar = forwardRef((props: IProps, ref: any) => {
  const dispatch = useDispatch();
  const [menu] = useState(Util.getMenu());
  const [firstDealership, setFirstDealership] = useState();
  const [logoLoading, setLogoLoading] = useState<boolean>(true);

  const device = useSelector(
    (store: IStore) => store.auth.device,
    shallowEqual
  );
  const dealership = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  const progressBars = useSelector(
    (store: IStore) => store.myTraffic.progressBars
  );
  const dealershipDropdownKey = useSelector(
    (store: IStore) => store.auth.dealershipDropdownKey,
    shallowEqual
  );
  let isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const onSelectDealership = (dealership: ISelectValue) =>
    new Promise((resolve, reject) => {
      Util.globalSpinner().show();
      if (isMountedRef.current) {
        updateDevice(device.id, {
          dealershipId: dealership.value,
        })
          .then((response) => {
            if (isMountedRef.current) {
              const dealership = response.data.dealership;

              dispatch(
                setDealershipContext(
                  Util.getDealershipContextFromResponseData(dealership)
                )
              );
            }
          })
          .catch((error) => {
            Util.showError(error);
          })
          .finally(() => {
            Util.globalSpinner().hide();
          });
      }
    });

  const onTopBarItemClick = (event: any, item: TOP_BAR_ITEMS) => {
    props.onTopBarItemClick({ originalEvent: event, item: item });
  };

  const promiseDealerships = async (
    inputValue: string,
    loadedOptions: any,
    { page }: any
  ): Promise<any> => {
    const response = await getMyDealerships({
      pageNumber: page,
      pageLimit: 50,
      dealershipName: inputValue,
      sorting: [],
    });

    const options = Util._.get(response, "data.content", []);

    if (!firstDealership && options.length > 0) {
      if (isMountedRef.current) setFirstDealership(options[0]);
    }

    return {
      options: [
        {
          label: "Active",
          options: response.data.content
            .filter((item) => !item.deleted)
            .map((item) => {
              return {
                value: item.id,
                label: item.name,
                externalRefId: item.externalRefId,
              };
            }),
        },
        {
          label: "Inactive",
          options: response.data.content
            .filter((item) => item.deleted)
            .map((item) => {
              return {
                value: item.id,
                label: item.name,
                externalRefId: item.externalRefId,
              };
            }),
        },
      ],
      hasMore: page < response.data.totalPages,
      additional: { page: page + 1 },
    };
  };

  const getDealershipValue = () => {
    if (dealership.id === 0) {
      return {
        label: "Loading...",
        value: 0,
      };
    }
    return {
      value: dealership.id,
      label: dealership.name,
    };
  };

  const getProgressBars = (): React.ReactElement | null => {
    if (progressBars.size === 0) {
      return null;
    }

    return (
      <li style={{ color: "white", padding: 10 }}>
        <label>Downloading...</label>
        <div>
          {Array.from(progressBars.values()).map((item) => {
            return (
              <div key={item.id}>
                <label
                  style={{ textOverflow: "ellipsis", wordBreak: "break-word" }}
                >
                  {item.name}
                </label>
                <ProgressBar value={item.value} />
              </div>
            );
          })}
        </div>
      </li>
    );
  };

  return (
    <div className="topbar clearfix">
      {menu.length > 1 ? (
        <button
          className="p-link p-hamburger-menu"
          id="menu-button"
          onClick={props.onMenuButtonClick}
        >
          <i className="pi pi-bars" />
        </button>
      ) : null}

      <span
        className={"topbar-logo-spinner"}
        style={{
          display:
            logoLoading || Util.isEmpty(dealership.logoPath) ? "unset" : "none",
        }}
      >
        <ProgressSpinner style={{ width: "100%", height: "100%" }} />
      </span>

      <img
        style={{
          float: "left",
          backgroundColor: "#ffffff",
          marginTop: -10,
          marginLeft: -10,
          height: 50,
          maxWidth: 200,
          display:
            logoLoading || Util.isEmpty(dealership.logoPath) ? "none" : "unset",
        }}
        className="header-logo"
        alt={dealership.name}
        src={dealership.logoPath}
        onLoad={() => {
          if (isMountedRef.current) setLogoLoading(false);
        }}
        onError={() => {
          if (isMountedRef.current) setLogoLoading(false);
        }}
      />

      <span
        style={{
          left: logoLoading || Util.isEmpty(dealership.logoPath) ? -10 : 0,
        }}
        className={"myTraffic"}
      >
        myTraffic
      </span>

      <button
        style={{ height: 34 }}
        className="p-link profile"
        onClick={props.onTopBarMenuButtonClick}
      >
        <span className="username">{props.userName}</span>
        <i className="pi pi-angle-down" />
      </button>

      <div
        style={{ marginTop: -8, marginRight: 10 }}
        title={"Notifications"}
        className="p-link profile"
      >
        <UserNotifications />
      </div>

      <span className={"topbar-context-dropdown"}>
        <AsyncPaginate
          key={dealershipDropdownKey}
          menuPosition={"fixed"}
          //cacheOptions
          defaultOptions
          value={getDealershipValue()}
          loadOptions={promiseDealerships}
          placeholder={"Select Environment"}
          reduceOptions={reduceGroupedOptions}
          onChange={(data: any) => onSelectDealership(data)}
          additional={{ page: 0 }}
        />
      </span>

      <ul
        className={classNames("topbar-menu fadeInDown", {
          "topbar-menu-visible": props.topBarMenuActive,
        })}
      >
        <li
          className={classNames({
            "menuitem-active": props.activeTopBarItem === TOP_BAR_ITEMS.PROFILE,
          })}
          onClick={(e) => onTopBarItemClick(e, TOP_BAR_ITEMS.PROFILE)}
        >
          <button className="p-link">
            <i className="topbar-icon pi pi-fw pi-user" />
            <span className="topbar-item-name">Profile</span>
          </button>
        </li>
        <li
          className={classNames({
            "menuitem-active": props.activeTopBarItem === TOP_BAR_ITEMS.LOGOUT,
          })}
          onClick={(e) => onTopBarItemClick(e, TOP_BAR_ITEMS.LOGOUT)}
        >
          <button className="p-link">
            <i className="topbar-icon pi pi-fw pi-sign-out" />
            <span className="topbar-item-name">Logout</span>
          </button>
        </li>
        {getProgressBars()}
      </ul>
    </div>
  );
});

export default AppTopBar;
