import React, { useEffect, useRef, useState } from "react";
import { Column } from "primereact/column";
import {
  DataTable,
  DataTableSelectionChangeParams,
  DataTableSortOrderType,
} from "primereact/datatable";
import { Button } from "primereact/button";
import {
  deleteDealershipCampaigns,
  getMyTrafficNotificationList,
  updateDealershipCampaign,
} from "../../../actions/MyTrafficNotificationActions";
import Util from "../../../util/Util";
import { IMyTrafficNotification } from "../../../../index.dts";
import { InputSwitch } from "primereact/inputswitch";
import { MODE } from "../../../util/Enums";
import { InputText } from "primereact/inputtext";
import "./notifications.css";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import { confirmAlert } from "react-confirm-alert";
import ReportModal from "./ReportModal";
import { useHistory } from "react-router-dom";
import { IDS } from "../../../views/constants";
import { useTranslation } from "react-i18next";

interface IProps {
  dealershipId: number;
  dealershipExtRefId: string;
}

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

const Notifications = (props: IProps, ref: any) => {
  const history = useHistory();
  const reportModalRef = useRef<any>();
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [first, setFirst] = useState<number>(0);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [nameFilterValue, setNameFilterValue] = useState<string>("");
  const [sortMap] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(new Map());

  const [loading, setLoading] = useState<boolean>(true);
  const [myTrafficNotifications, setMyTrafficNotifications] = useState<
    IMyTrafficNotification[]
  >([]);
  const [selectedMyTrafficNotifications, setSelectedMyTrafficNotifications] =
    useState<IMyTrafficNotification[]>([]);

  const onClear = () => {
    sortMap.clear();
    setNameFilterValue("");
  };

  useEffect(() => {
    return () => {
      searchTimeoutInterval = 0;
    };
  }, []);

  useEffect(() => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getData(props.dealershipId, 0, limit).finally(() => {
        setPage(0);
        setFirst(0);
        searchTimeoutInterval = 500;
        setSelectedMyTrafficNotifications([]);
      });
    }, searchTimeoutInterval);
    // eslint-disable-next-line
  }, [props.dealershipId, nameFilterValue]);

  const getData = (dealershipId: number, page: number, limit: number) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      getMyTrafficNotificationList({
        pageNumber: page,
        pageLimit: limit,
        name: nameFilterValue,
        dealershipIds: [dealershipId],
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
      })
        .then((response) => {
          setTotalItemsCount(response.data.totalElements);
          setMyTrafficNotifications(response.data.content);

          resolve(response);
        })
        .catch((error) => Util.showError(error))
        .finally(() => setLoading(false));
    });

  const onNew = (): void => {
    history.push(Util.PATH_NAMES.MY_TRAFFIC_CREATE_NOTIFICATION, {
      mode: MODE.NEW,
      dealershipId: props.dealershipId,
      dealershipExtRefId: props.dealershipExtRefId,
    });
  };

  const onEdit = (): void => {
    if (selectedMyTrafficNotifications.length === 1) {
      history.push(Util.PATH_NAMES.MY_TRAFFIC_EDIT_NOTIFICATION, {
        mode: MODE.EDIT,
        dealershipId: props.dealershipId,
        dealershipExtRefId: props.dealershipExtRefId,
        notificationId: Util._.get(selectedMyTrafficNotifications, "[0].id", 0),
      });
    } else {
      Util.warning("Please select myTraffic notification.");
    }
  };

  const onViewNotificationHistory = (): void => {
    if (selectedMyTrafficNotifications.length === 1) {
      const myTrafficNotification = selectedMyTrafficNotifications[0];
      reportModalRef.current.show(
        myTrafficNotification.id,
        myTrafficNotification.name
      );
    } else {
      Util.warning("Please select myTraffic notification.");
    }
  };

  const onDelete = (): void => {
    if (selectedMyTrafficNotifications.length > 0) {
      confirmAlert({
        title: `You are about to delete ${
          selectedMyTrafficNotifications.length
        } myTraffic notification${
          selectedMyTrafficNotifications.length > 1 ? "s" : ""
        }!`,
        message: "Are you sure you want to do this?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              Util.globalSpinner().show();
              deleteDealershipCampaigns(
                selectedMyTrafficNotifications.map((item) => item.id)
              )
                .then((response) => {
                  getData(props.dealershipId, 0, limit).finally(() => {
                    setPage(0);
                    setSelectedMyTrafficNotifications([]);
                  });

                  Util.success(
                    "MyTraffic Notification(s) deleted successfully."
                  );
                })
                .catch((error) => Util.showError(error))
                .finally(() => Util.globalSpinner().hide());
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    } else {
      Util.warning("Please select at least one myTraffic notification.");
    }
  };

  const enabledBody = (data: IMyTrafficNotification) => {
    return (
      <InputSwitch
        checked={data.enabled}
        onChange={() => onChangeStatus(data)}
      />
    );
  };

  const onChangeStatus = (data: IMyTrafficNotification) => {
    data.enabled = !data.enabled;
    updateDealershipCampaign(data.id, {
      enabled: data.enabled,
    }).catch((error) => {
      Util.showError(error);
      data.enabled = !data.enabled;
      forceUpdate();
    });

    forceUpdate();
  };

  const onPage = (event: any) => {
    getData(props.dealershipId, event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
      setSelectedMyTrafficNotifications([]);
    });
  };

  const onSort = (data: any) => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getData(props.dealershipId, page, limit);
    }
  };

  const nameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={nameFilterValue}
      onChange={(e: any) => {
        setNameFilterValue(e.target.value || "");
      }}
    />
  );

  return (
    <div
      id={"dealership-campaign"}
      className={
        limit > 10
          ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting"
          : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
      }
    >
      <DataTable
        lazy={true}
        filterDisplay="row"
        loading={loading}
        scrollable
        autoLayout={true}
        sortMode={"multiple"}
        resizableColumns={true}
        columnResizeMode={"expand"}
        rowsPerPageOptions={Util.rowsPerPageOptions}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
        paginator={true}
        rows={limit}
        first={first}
        onPage={onPage}
        onSort={onSort}
        totalRecords={totalItemsCount}
        value={myTrafficNotifications}
        selection={selectedMyTrafficNotifications}
        onSelectionChange={(e) => setSelectedMyTrafficNotifications(e.value)}
        multiSortMeta={Array.from(sortMap.values())}
        header={
          <div className={"p-grid-header-components"}>
            <Button
              id={IDS.button.clear}
              style={{ position: "absolute", left: "10px" }}
              icon={"pi pi-filter"}
              label={t("clear")}
              onClick={onClear}
            />
            <Button
              style={{ marginRight: 5 }}
              onClick={onNew}
              icon="pi pi-plus"
              label={"New"}
            />
            <Button
              disabled={selectedMyTrafficNotifications.length !== 1}
              style={{ marginRight: 5 }}
              onClick={onEdit}
              icon="pi pi-pencil"
              label={"Edit"}
            />
            <Button
              disabled={selectedMyTrafficNotifications.length !== 1}
              style={{ marginRight: 5 }}
              onClick={onViewNotificationHistory}
              icon="pi pi-file"
              label={"Notification History"}
            />
            <Button
              disabled={selectedMyTrafficNotifications.length === 0}
              onClick={onDelete}
              icon="pi pi-trash"
              className="p-button-danger"
              label={"Delete"}
            />
          </div>
        }
      >
        <Column selectionMode="multiple" style={{ width: "3em" }} />
        <Column
          filter={true}
          sortable={true}
          field={"name"}
          header={"Name"}
          filterElement={nameFilter}
        />
        <Column
          filter={false}
          sortable={true}
          field={"segment"}
          header={"Segment"}
        />
        <Column
          filter={false}
          sortable={true}
          field={"message"}
          header={"Prompt"}
        />
        <Column
          filter={false}
          sortable={true}
          field={"engagementScore"}
          header={"Eng. Score"}
        />
        <Column
          filter={false}
          sortable={true}
          field={"delaySend"}
          header={"Delay send"}
        />
        <Column
          filter={false}
          sortable={false}
          field={"enabled"}
          header={"Enabled"}
          body={enabledBody}
        />
        <Column
          filter={false}
          sortable={true}
          field={"zipCodes"}
          header={"ZIP Codes"}
        />
        <Column field={"usersCount"} header={"Users Count"} />
      </DataTable>
      <ReportModal ref={reportModalRef} />
    </div>
  );
};

export default Notifications;
