import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { BrandTypeMap, IStore, VinTypeMap } from "../../../../index.dts";
import { loadVdpStats } from "../../../actions/kaninActions";
import Util from "../../../util/Util";
import { IVdpPerformanceFilterData } from "./VdpPerformanceView";
import moment from "moment";
import { ViewTypes } from "../../../util/enum/view_types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { VIEW_BY_SELECTOR } from "../../../util/Enums";
import { ProductTypes } from "../../../util/enum/product_types";
import VdpLeadModal from "./VdpLeadModal";

interface IProps {
  dealershipId: number;
  filter: IVdpPerformanceFilterData;
  dealershipTimezone: string;
  viewByFilter: string;
  total: number;
}

const BrandGroupings = (props: IProps) => {
  let isMountedRef = useRef(false);
  const leadModalRef = useRef<any>();
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  const [vdpStatsNone, setVdpStatsNone] = useState<BrandTypeMap | undefined>(
    undefined
  );
  const [vdpStats, setVdpStats] = useState<VinTypeMap | undefined>(undefined);
  const [expandedRows, setExpandedRows] = useState<any>(null);
  const [expandedRowsYears, setExpandedRowsYears] = useState<any>(null);
  const [expandedRowsModels, setExpandedRowsModels] = useState<any>(null);
  const [expandedRowsTrims, setExpandedRowsTrims] = useState<any>(null);

  function getQueryStart(): string {
    return moment(props?.filter?.period?.start).format(Util.localDateFormat);
  }

  function getQueryEnd(): string {
    return moment(props?.filter?.period?.end).format(Util.localDateFormat);
  }

  useEffect(() => {
    isMountedRef.current = true;
    Util.globalSpinner().show();
    loadVdpStats(props.dealershipId, getQueryStart(), getQueryEnd())
      .then((response) => {
        setVdpStatsNone(
          props?.filter?.viewType?.type == ViewTypes.uniqueVisitors
            ? response?.visits
            : response?.pageViews
        );
        setVdpStats(
          props?.filter?.viewType?.type == ViewTypes.uniqueVisitors
            ? response?.vinsInVisits
            : response?.vinsInPageViews
        );
      })
      .catch((error) => {
        console.error(error);
        Util.showError(error);
      })
      .finally(() => Util.globalSpinner().hide());
    return () => {
      isMountedRef.current = false;
    };
  }, [props.filter, props.viewByFilter]);

  const allowExpansion = (rowData) => {
    return Object.values(rowData?.years).length > 0;
  };

  const allowExpansionYears = (rowData) => {
    return Object.values(rowData?.models).length > 0;
  };

  const allowExpansionModels = (rowData) => {
    return Object.values(rowData?.trims).length > 0;
  };

  const allowExpansionTrims = (rowData) => {
    return Object.values(rowData?.vins).length > 0;
  };

  const leadPercentage = (rowData: any) => {
    return (
      <div>
        {((rowData?.leadStatsDto?.totalLeads / rowData?.total) * 100).toFixed(
          0
        )}
      </div>
    );
  };

  const onLeadsPaidClick = (
    rowData: any,
    campaignSearch: string,
    type: ProductTypes
  ) => {
    const startDate = moment(props?.filter.period?.start).format(
      Util.localDateTimeFormat
    );
    const endDate = moment(props?.filter.period?.end).format(
      Util.localDateTimeFormat
    );
    leadModalRef.current.show(
      rowData,
      campaignSearch,
      startDate,
      endDate,
      type
    );
  };

  const vinTemplate = (rowData: any) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p>{rowData.vin}</p>
        <p style={{ margin: "5px" }}>Brand: {rowData?.brand}</p>
        <p style={{ margin: "5px" }}>Year: {rowData?.year}</p>
        <p style={{ margin: "5px" }}>Model: {rowData?.model}</p>
        <p style={{ margin: "5px" }}>Trim: {rowData?.trim}</p>
      </div>
    );
  };

  const rowExpansionTemplateTrims = (data) => {
    return (
      <div className="orders-subtable">
        <h4>Vins for {data.trimName}</h4>
        <DataTable
          value={data.vins && Object.values(data.vins)}
          responsiveLayout="scroll"
          dataKey="vin"
        >
          <Column
            field="vin"
            header="Vin"
            body={(rowData) => (
              <a
                style={{ cursor: "pointer" }}
                href={rowData?.url}
                target="_blank"
              >
                {rowData?.vin}
              </a>
            )}
            sortable
          ></Column>
          <Column
            field="total"
            header="Views"
            style={{ textAlign: "center" }}
            sortable
          />
          <Column
            field="daysInStock"
            style={{ textAlign: "center" }}
            header="Specific DIS"
            sortable
          />
          <Column
            field="daysOnline"
            style={{ textAlign: "center" }}
            header="Days Online"
            sortable
          />
          <Column
            field="leadStatsDto.nativeLeads"
            header="Native Leads"
            style={{ textAlign: "center" }}
            sortable
          />
          <Column
            field="leadStatsDto.offerLeads"
            header="Offer Leads"
            style={{ textAlign: "center" }}
            sortable
          />
          <Column
            field="leadStatsDto.totalLeads"
            header="Total Leads"
            style={{ textAlign: "center" }}
            sortable
          />
          <Column
            field=""
            header="Lead %"
            style={{ textAlign: "center" }}
            sortable
            body={leadPercentage}
          />
        </DataTable>
      </div>
    );
  };

  const rowExpansionTemplateModels = (data) => {
    return (
      <div className="orders-subtable">
        <h4>Trims for {data.modelName}</h4>
        <DataTable
          value={data.trims && Object.values(data.trims)}
          responsiveLayout="scroll"
          rowExpansionTemplate={rowExpansionTemplateTrims}
          expandedRows={expandedRowsTrims}
          onRowToggle={(e) => setExpandedRowsTrims(e.data)}
          dataKey="trimName"
        >
          <Column expander={allowExpansionTrims} style={{ width: "3em" }} />
          <Column field="trimName" header="Trim" sortable></Column>
          <Column
            field="total"
            header="Views"
            style={{ textAlign: "center" }}
            sortable
          />
          <Column
            field="leadStatsDto.avgDaysInStock"
            header="Average DIS"
            sortable
          />
          <Column
            field="leadStatsDto.nativeLeads"
            header="Native Leads"
            style={{ textAlign: "center" }}
            sortable
          />
          <Column
            field="leadStatsDto.offerLeads"
            header="Offer Leads"
            style={{ textAlign: "center" }}
            sortable
          />
          <Column
            field="leadStatsDto.totalLeads"
            header="Total Leads"
            style={{ textAlign: "center" }}
            sortable
          />
          <Column
            field=""
            header="Lead %"
            style={{ textAlign: "center" }}
            sortable
            body={leadPercentage}
          />
        </DataTable>
      </div>
    );
  };

  const rowExpansionTemplateYears = (data) => {
    return (
      <div className="orders-subtable">
        <h4>Models for {data.year}</h4>
        <DataTable
          value={data.models && Object.values(data.models)}
          responsiveLayout="scroll"
          rowExpansionTemplate={rowExpansionTemplateModels}
          expandedRows={expandedRowsModels}
          onRowToggle={(e) => setExpandedRowsModels(e.data)}
          dataKey="modelName"
        >
          <Column expander={allowExpansionModels} style={{ width: "3em" }} />
          <Column field="modelName" header="Model" sortable></Column>
          <Column
            field="total"
            header="Views"
            style={{ textAlign: "center" }}
            sortable
          />
          <Column
            field="leadStatsDto.avgDaysInStock"
            header="Average DIS"
            sortable
          />
          <Column
            field="leadStatsDto.nativeLeads"
            header="Native Leads"
            style={{ textAlign: "center" }}
            sortable
          />
          <Column
            field="leadStatsDto.offerLeads"
            header="Offer Leads"
            style={{ textAlign: "center" }}
            sortable
          />
          <Column
            field="leadStatsDto.totalLeads"
            header="Total Leads"
            style={{ textAlign: "center" }}
            sortable
          />
          <Column
            field=""
            header="Lead %"
            style={{ textAlign: "center" }}
            sortable
            body={leadPercentage}
          />
        </DataTable>
      </div>
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="orders-subtable">
        <h4>Years for {data.brandName}</h4>
        <DataTable
          value={data.years && Object.values(data.years)}
          responsiveLayout="scroll"
          rowExpansionTemplate={rowExpansionTemplateYears}
          expandedRows={expandedRowsYears}
          onRowToggle={(e) => setExpandedRowsYears(e.data)}
          dataKey="year"
        >
          <Column expander={allowExpansionYears} style={{ width: "3em" }} />
          <Column field="year" header="Year" sortable></Column>
          <Column
            field="total"
            header="Views"
            style={{ textAlign: "center" }}
            sortable
          />
          <Column
            field="leadStatsDto.avgDaysInStock"
            header="Average DIS"
            sortable
          />
          <Column
            field="leadStatsDto.nativeLeads"
            header="Native Leads"
            style={{ textAlign: "center" }}
            sortable
          />
          <Column
            field="leadStatsDto.offerLeads"
            header="Offer Leads"
            style={{ textAlign: "center" }}
            sortable
          />
          <Column
            field="leadStatsDto.totalLeads"
            header="Total Leads"
            style={{ textAlign: "center" }}
            sortable
          />
          <Column
            field=""
            header="Lead %"
            style={{ textAlign: "center" }}
            sortable
            body={leadPercentage}
          />
        </DataTable>
      </div>
    );
  };

  return (
    <div className="brands-grouping-div">
      {props?.viewByFilter && props.viewByFilter == VIEW_BY_SELECTOR.NONE && (
        <DataTable
          value={vdpStatsNone && Object.values(vdpStatsNone)}
          responsiveLayout="scroll"
          rowExpansionTemplate={rowExpansionTemplate}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          dataKey="brandName"
        >
          <Column expander={allowExpansion} style={{ width: "3em" }} />
          <Column field="brandName" header="Brand" sortable />
          <Column
            field="total"
            header="Views"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <p>{rowData?.total}</p>
                <p>
                  {"("}
                  {((rowData?.total / props?.total) * 100).toFixed(2)}
                  {" %)"}
                </p>
              </div>
            )}
          />
          <Column
            field="leadStatsDto.avgDaysInStock"
            header="Average DIS"
            sortable
          />
          <Column
            field="leadStatsDto.nativeLeads"
            header="Native Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => <p>{rowData?.leadStatsDto?.nativeLeads}</p>}
          />
          <Column
            field="leadStatsDto.offerLeads"
            header="Offer Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => <p>{rowData?.leadStatsDto?.offerLeads}</p>}
          />
          <Column
            field="leadStatsDto.totalLeads"
            header="Total Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => <p>{rowData?.leadStatsDto?.totalLeads}</p>}
          />
          <Column
            field=""
            header="Lead %"
            style={{ textAlign: "center" }}
            sortable
            body={leadPercentage}
          />
        </DataTable>
      )}
      {props?.viewByFilter && props.viewByFilter == VIEW_BY_SELECTOR.VISITS && (
        <DataTable
          value={vdpStats && Object.values(vdpStats)}
          responsiveLayout="scroll"
          dataKey="vin"
          sortField={"total"}
          sortOrder={-1}
        >
          <Column field="vin" header="Vin" body={vinTemplate} sortable></Column>
          <Column
            field="total"
            header="Views"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => {
              const organicViewsObj = rowData?.statistic["organic"];
              const paidViews =
                organicViewsObj !== null && organicViewsObj !== undefined
                  ? Number(rowData?.total) - Number(organicViewsObj.count)
                  : Number(rowData?.total);
              return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p>{rowData?.total}</p>
                  <p id="organic-paid-p" title="(Organic|Paid)">
                    {"("}
                    {organicViewsObj !== undefined && organicViewsObj?.count
                      ? organicViewsObj?.count
                      : 0}
                    {"|" + paidViews + ")"}
                  </p>
                </div>
              );
            }}
          />
          <Column
            field="daysInStock"
            style={{ textAlign: "center" }}
            header="Specific DIS"
            sortable
          />
          <Column
            field="daysOnline"
            style={{ textAlign: "center" }}
            header="Days Online"
            sortable
          />
          <Column
            field="leadStatsDto.nativeLeads"
            header="Native Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => (
              <p
                onClick={() =>
                  onLeadsPaidClick(rowData, "Native Leads", ProductTypes.all)
                }
              >
                {rowData?.leadStatsDto?.nativeLeads}
              </p>
            )}
          />
          <Column
            field="leadStatsDto.offerLeads"
            header="Offer Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => (
              <p
                onClick={() =>
                  onLeadsPaidClick(rowData, "Offer Leads", ProductTypes.all)
                }
              >
                {rowData?.leadStatsDto?.offerLeads}
              </p>
            )}
          />
          <Column
            field="leadStatsDto.totalLeads"
            header="Total Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => (
              <p
                onClick={() =>
                  onLeadsPaidClick(rowData, "Total Leads", ProductTypes.all)
                }
              >
                {rowData?.leadStatsDto?.totalLeads}
              </p>
            )}
          />
          <Column
            field=""
            header="Lead %"
            style={{ textAlign: "center" }}
            sortable
            body={leadPercentage}
          />
        </DataTable>
      )}
      {props?.viewByFilter && props.viewByFilter == VIEW_BY_SELECTOR.LEADS && (
        <DataTable
          value={vdpStats && Object.values(vdpStats)}
          responsiveLayout="scroll"
          dataKey="vin"
          sortField={"leadStatsDto.totalLeads"}
          sortOrder={-1}
        >
          <Column field="vin" header="Vin" body={vinTemplate} sortable></Column>
          <Column
            field="total"
            header="Views"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => {
              const organicViewsObj = rowData?.statistic["organic"];
              const paidViews =
                organicViewsObj !== null && organicViewsObj !== undefined
                  ? Number(rowData?.total) - Number(organicViewsObj.count)
                  : Number(rowData?.total);
              return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p>{rowData?.total}</p>
                  <p id="organic-paid-p" title="(Organic|Paid)">
                    {"("}
                    {organicViewsObj !== undefined && organicViewsObj?.count
                      ? organicViewsObj?.count
                      : 0}
                    {"|" + paidViews + ")"}
                  </p>
                </div>
              );
            }}
          />
          <Column
            field="daysInStock"
            style={{ textAlign: "center" }}
            header="Specific DIS"
            sortable
          />
          <Column
            field="daysOnline"
            style={{ textAlign: "center" }}
            header="Days Online"
            sortable
          />
          <Column
            field="leadStatsDto.nativeLeads"
            header="Native Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => (
              <p
                onClick={() =>
                  onLeadsPaidClick(rowData, "Native Leads", ProductTypes.all)
                }
              >
                {rowData?.leadStatsDto?.nativeLeads}
              </p>
            )}
          />
          <Column
            field="leadStatsDto.offerLeads"
            header="Offer Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => (
              <p
                onClick={() =>
                  onLeadsPaidClick(rowData, "Offer Leads", ProductTypes.all)
                }
              >
                {rowData?.leadStatsDto?.offerLeads}
              </p>
            )}
          />
          <Column
            field="leadStatsDto.totalLeads"
            header="Total Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => (
              <p
                onClick={() =>
                  onLeadsPaidClick(rowData, "Total Leads", ProductTypes.all)
                }
              >
                {rowData?.leadStatsDto?.totalLeads}
              </p>
            )}
          />
          <Column
            field=""
            header="Lead %"
            style={{ textAlign: "center" }}
            sortable
            body={leadPercentage}
          />
        </DataTable>
      )}
      {props?.viewByFilter && props.viewByFilter == "ENGAGEMENT_SCORE" && (
        <DataTable
          value={vdpStats && Object.values(vdpStats)}
          responsiveLayout="scroll"
          dataKey="vin"
          sortField={"averageEngScore"}
          sortOrder={-1}
        >
          <Column field="vin" header="Vin" body={vinTemplate} sortable></Column>
          <Column
            field="total"
            header="Views"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => {
              const organicViewsObj = rowData?.statistic["organic"];
              const paidViews =
                organicViewsObj !== null && organicViewsObj !== undefined
                  ? Number(rowData?.total) - Number(organicViewsObj.count)
                  : Number(rowData?.total);
              return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p>{rowData?.total}</p>
                  <p id="organic-paid-p" title="(Organic|Paid)">
                    {"("}
                    {organicViewsObj !== undefined && organicViewsObj?.count
                      ? organicViewsObj?.count
                      : 0}
                    {"|" + paidViews + ")"}
                  </p>
                </div>
              );
            }}
          />
          <Column
            field="daysInStock"
            style={{ textAlign: "center" }}
            header="Specific DIS"
            sortable
          />
          <Column
            field="daysOnline"
            style={{ textAlign: "center" }}
            header="Days Online"
            sortable
          />
          <Column
            field="leadStatsDto.nativeLeads"
            header="Native Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => (
              <p
                onClick={() =>
                  onLeadsPaidClick(rowData, "Native Leads", ProductTypes.all)
                }
              >
                {rowData?.leadStatsDto?.nativeLeads}
              </p>
            )}
          />
          <Column
            field="leadStatsDto.offerLeads"
            header="Offer Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => (
              <p
                onClick={() =>
                  onLeadsPaidClick(rowData, "Offer Leads", ProductTypes.all)
                }
              >
                {rowData?.leadStatsDto?.offerLeads}
              </p>
            )}
          />
          <Column
            field="leadStatsDto.totalLeads"
            header="Total Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => (
              <p
                onClick={() =>
                  onLeadsPaidClick(rowData, "Total Leads", ProductTypes.all)
                }
              >
                {rowData?.leadStatsDto?.totalLeads}
              </p>
            )}
          />
          <Column
            field=""
            header="Lead %"
            style={{ textAlign: "center" }}
            sortable
            body={leadPercentage}
          />
        </DataTable>
      )}
      {props?.viewByFilter && props.viewByFilter == "ZIP_CODE" && (
        <DataTable
          value={vdpStats && Object.values(vdpStats)}
          responsiveLayout="scroll"
          dataKey="vin"
          sortField={"numberOfZips"}
          sortOrder={-1}
        >
          <Column field="vin" header="Vin" body={vinTemplate} sortable></Column>
          <Column
            field="total"
            header="Views"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => {
              const organicViewsObj = rowData?.statistic["organic"];
              const paidViews =
                organicViewsObj !== null && organicViewsObj !== undefined
                  ? Number(rowData?.total) - Number(organicViewsObj.count)
                  : Number(rowData?.total);
              return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p>{rowData?.total}</p>
                  <p id="organic-paid-p" title="(Organic|Paid)">
                    {"("}
                    {organicViewsObj !== undefined && organicViewsObj?.count
                      ? organicViewsObj?.count
                      : 0}
                    {"|" + paidViews + ")"}
                  </p>
                </div>
              );
            }}
          />
          <Column
            field="daysInStock"
            style={{ textAlign: "center" }}
            header="Specific DIS"
            sortable
          />
          <Column
            field="daysOnline"
            style={{ textAlign: "center" }}
            header="Days Online"
            sortable
          />
          <Column
            field="leadStatsDto.nativeLeads"
            header="Native Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => (
              <p
                onClick={() =>
                  onLeadsPaidClick(rowData, "Native Leads", ProductTypes.all)
                }
              >
                {rowData?.leadStatsDto?.nativeLeads}
              </p>
            )}
          />
          <Column
            field="leadStatsDto.offerLeads"
            header="Offer Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => (
              <p
                onClick={() =>
                  onLeadsPaidClick(rowData, "Offer Leads", ProductTypes.all)
                }
              >
                {rowData?.leadStatsDto?.offerLeads}
              </p>
            )}
          />
          <Column
            field="leadStatsDto.totalLeads"
            header="Total Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => (
              <p
                onClick={() =>
                  onLeadsPaidClick(rowData, "Total Leads", ProductTypes.all)
                }
              >
                {rowData?.leadStatsDto?.totalLeads}
              </p>
            )}
          />
          <Column
            field=""
            header="Lead %"
            style={{ textAlign: "center" }}
            sortable
            body={leadPercentage}
          />
        </DataTable>
      )}
      {props?.viewByFilter && props.viewByFilter == "DAYS_IN_STOCK" && (
        <DataTable
          value={vdpStats && Object.values(vdpStats)}
          responsiveLayout="scroll"
          dataKey="vin"
          sortField={"daysInStock"}
          sortOrder={-1}
        >
          <Column field="vin" header="Vin" body={vinTemplate} sortable></Column>
          <Column
            field="total"
            header="Views"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => {
              const organicViewsObj = rowData?.statistic["organic"];
              const paidViews =
                organicViewsObj !== null && organicViewsObj !== undefined
                  ? Number(rowData?.total) - Number(organicViewsObj.count)
                  : Number(rowData?.total);
              return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p>{rowData?.total}</p>
                  <p id="organic-paid-p" title="(Organic|Paid)">
                    {"("}
                    {organicViewsObj !== undefined && organicViewsObj?.count
                      ? organicViewsObj?.count
                      : 0}
                    {"|" + paidViews + ")"}
                  </p>
                </div>
              );
            }}
          />
          <Column
            field="daysInStock"
            style={{ textAlign: "center" }}
            header="Specific DIS"
            sortable
          />
          <Column
            field="daysOnline"
            style={{ textAlign: "center" }}
            header="Days Online"
            sortable
          />
          <Column
            field="leadStatsDto.nativeLeads"
            header="Native Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => (
              <p
                onClick={() =>
                  onLeadsPaidClick(rowData, "Native Leads", ProductTypes.all)
                }
              >
                {rowData?.leadStatsDto?.nativeLeads}
              </p>
            )}
          />
          <Column
            field="leadStatsDto.offerLeads"
            header="Offer Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => (
              <p
                onClick={() =>
                  onLeadsPaidClick(rowData, "Offer Leads", ProductTypes.all)
                }
              >
                {rowData?.leadStatsDto?.offerLeads}
              </p>
            )}
          />
          <Column
            field="leadStatsDto.totalLeads"
            header="Total Leads"
            style={{ textAlign: "center", cursor: "pointer" }}
            sortable
            body={(rowData) => (
              <p
                onClick={() =>
                  onLeadsPaidClick(rowData, "Total Leads", ProductTypes.all)
                }
              >
                {rowData?.leadStatsDto?.totalLeads}
              </p>
            )}
          />
          <Column
            field=""
            header="Lead %"
            style={{ textAlign: "center" }}
            sortable
            body={leadPercentage}
          />
        </DataTable>
      )}
      <VdpLeadModal
        ref={leadModalRef}
        dealershipId={props.dealershipId}
        dealershipTimezone={props.dealershipTimezone}
      />
    </div>
  );
};

export default BrandGroupings;
