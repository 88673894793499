import { BaseChartDataset } from "./base_chart_dataset";
import { ChartConfig } from "../chart_config";

export class OrganicDataset extends BaseChartDataset {
  constructor(args: {
    label: string,
    data?: number[],
    yAxisID: string,
  }) {
    super({
      backgroundColor: ChartConfig.organic.color,
      borderColor: ChartConfig.organic.color,
      borderWidth: ChartConfig.border.width,
      color: ChartConfig.organic.color,
      data: args.data ?? [],
      label: args.label,
      yAxisID: args.yAxisID,
    });
  }
}