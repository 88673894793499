import Util from "./Util";
import _ from "lodash";
import moment from "moment";

export function formatDateTime(input: any): string {
  return moment(Util.utcToLocal(input)).format(Util.usDateTimeFormat);
}

export function fullname(...names: string[]): string | undefined {
  let buffer: string[] = [];

  _.forEach(names, (name) => {
    if (!_.isEmpty(name)) {
      buffer.push(name);
    }
  });

  return _.isEmpty(buffer) ? undefined : _.join(buffer, ' ');
}

export function sumArrays(...arrays: number[][]): number[] {
  let result: number[] = [];

  if (Array.isArray(arrays)) {
    if (arrays.length > 1) {
      const base = arrays[0];
      const rest = arrays.slice(1);

      if (Array.isArray(base) && base.length > 0) {
        base.forEach((value, index) => {
          let sum = value ?? 0;

          rest.forEach(array => {
            sum += array[index] ?? 0;
          });

          result.push(sum);
        });
      }
    } else {
      result = arrays[0];
    }
  }

  return result;
}

export function handleLoginError(error: any) {
  const errorDescription = Util.getDefaultIfNull(Util._.get(error, "response.data.error_description", null), "");
  if (errorDescription.toLowerCase().includes("bad user credentials.")) {
    Util.error("Wrong username or password. Try again or click Forgot password to reset it.");
  } else if (errorDescription.toLowerCase().includes("user is disabled")) {
    Util.error("Your account is disabled. You do not have access.Please contact our support if you have questions.");
  } else {
    Util.showError(error);
  }
}

export const PERIODS: any = {
  TODAY: {
    label: "Today",
    from: moment().set({
      hour: 0,
      minute: 0,
      second: 0,
    }).format(Util.localDateTimeFormat),
    to: moment().set({
      hour: 23,
      minute: 59,
      second: 59,
    }).format(Util.localDateTimeFormat)
  },
  YESTERDAY: {
    label: "Yesterday",
    from: moment().subtract(1, 'days').set({
      hour: 0,
      minute: 0,
      second: 0,
    }).format(Util.localDateTimeFormat),
    to: moment().subtract(1, 'days').set({
      hour: 23,
      minute: 59,
      second: 59,
    }).format(Util.localDateTimeFormat)
  },
  THIS_WEEK: {
    label: "This Week",
    from: moment().startOf("week").set({
      hour: 0,
      minute: 0,
      second: 0,
    }).format(Util.localDateTimeFormat),
    to: moment().endOf("week").set({
      hour: 23,
      minute: 59,
      second: 59,
    }).format(Util.localDateTimeFormat)
  },
  LAST_WEEK: {
    label: "Last Week",
    from: moment().subtract(1, 'week').startOf("week").set({
      hour: 0,
      minute: 0,
      second: 0,
    }).format(Util.localDateTimeFormat),
    to: moment().subtract(1, 'week').endOf("week").set({
      hour: 23,
      minute: 59,
      second: 59,
    }).format(Util.localDateTimeFormat)
  },
  LAST_30_DAYS: {
    label: "Last 30 - days",
    from: moment().subtract(30, "days").set({
      hour: 0,
      minute: 0,
      second: 0,
    }).format(Util.localDateTimeFormat),
    to: moment().set({
      hour: 23,
      minute: 59,
      second: 59,
    }).format(Util.localDateTimeFormat)
  },
  THIS_MONTH: {
    label: "This month",
    from: moment().startOf("month").set({
      hour: 0,
      minute: 0,
      second: 0,
    }).format(Util.localDateTimeFormat),
    to: moment().endOf("month").set({
      hour: 23,
      minute: 59,
      second: 59,
    }).format(Util.localDateTimeFormat)
  },
  LAST_MONTH: {
    label: "Last month",
    from: moment().subtract(1, "month").startOf("month").set({
      hour: 0,
      minute: 0,
      second: 0,
    }).format(Util.localDateTimeFormat),
    to: moment().subtract(1, "month").endOf("month").set({
      hour: 23,
      minute: 59,
      second: 59,
    }).format(Util.localDateTimeFormat)
  },
  THIS_YEAR: {
    label: "This Year",
    from: moment().startOf("year").set({
      hour: 0,
      minute: 0,
      second: 0,
    }).format(Util.localDateTimeFormat),
    to: moment().endOf("year").set({
      hour: 23,
      minute: 59,
      second: 59,
    }).format(Util.localDateTimeFormat)
  },
  LAST_YEAR: {
    label: "Last Year",
    from: moment().subtract(1, "year").startOf("year").set({
      hour: 0,
      minute: 0,
      second: 0,
    }).format(Util.localDateTimeFormat),
    to: moment().subtract(1, "year").endOf("year").set({
      hour: 23,
      minute: 59,
      second: 59,
    }).format(Util.localDateTimeFormat)
  },
  CUSTOM_PERIOD: {
    label: "Custom Period",
    from: null,
    to: null
  }
}