import "./smart_spend_filter.css";

import {
  DateRangePeriods,
  Period,
} from "../data_range_picker/date_range_periods";
import React, { useEffect, useState } from "react";
import {
  forEach,
  includes,
  isDate,
  isEmpty,
  isNumber,
  isUndefined,
  map,
  pick,
} from "lodash";

import { DateRangePickerWidget } from "../data_range_picker/DateRangePickerWidget";
import {
  IKeyOptions,
  IMakeOptions,
  ITrafficMakeModel,
} from "../../../index.dts";
import { MakesSelect } from "./makes/MakesSelect";
import { MarkerSelect } from "../marker_select/MarkerSelect";
import { ModelsSelect } from "./models/ModelsSelect";
import { ProductTypeRadio } from "../product_type_radio/ProductTypeRadio";
import { ProductTypes } from "../../util/enum/product_types";
import { SmartSpendFilterOptions } from "./smart_spend_filter_options";
import moment from "moment";
import { PageTypeRadio } from "../page_type_radio/PageTypeRadio";
import PageTypes from "../../util/enum/page_types";
import { getTrafficMarkers } from "../../actions/trafficMarkerActions";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { YearsSelect } from "./years/YearsSelect";
import Util from "../../util/Util";

interface IMarker {
  readonly date?: Date;
  readonly id?: number;
}

interface IMarker2 {
  date: Date;
  label: string;
  value: number;
}

interface IOptions {
  readonly makes?: IMakeOptions;
}

interface IPeriod {
  readonly start?: Date;
  readonly end?: Date;
}

interface IProduct {
  readonly type?: ProductTypes;
}

interface IPage {
  readonly type?: PageTypes;
}

export interface ISmartSpendFilterData {
  readonly marker?: IMarker;
  readonly options?: IOptions;
  readonly period?: IPeriod;
  readonly product?: IProduct;
  readonly page?: IPage;
}

interface IProps {
  readonly maxMakes?: number;
  readonly maxModels?: number;
  readonly maxYears?: number;
  readonly dateRanges?: Period[];
  readonly dealershipId?: number;
  readonly initial?: ISmartSpendFilterData;
  readonly makesModels?: IKeyOptions;
  readonly makeObjects?: ITrafficMakeModel;
  readonly onChange?: (data: ISmartSpendFilterData) => void;
  readonly options?: SmartSpendFilterOptions[];
}

export const defaultSmartSpendFilter: ISmartSpendFilterData = {
  period: (() => {
    const period = DateRangePeriods.get(DateRangePeriods.keys.last30Days);
    return {
      start: period.start,
      end: period.end,
    };
  })(),
  product: {
    type: ProductTypes.all,
  },
  page: {
    type: PageTypes.all,
  },
};

export function SmartSpendFilter(props: IProps): any {
  const [marker, setMarker] = useState<IMarker>(props.initial?.marker ?? {});
  const [options, setOptions] = useState<IOptions>(
    props.initial?.options ?? {}
  );
  const [period, setPeriod] = useState<IPeriod>(props.initial?.period ?? {});
  const [product, setProduct] = useState<IProduct>(
    props.initial?.product ?? {}
  );
  const [page, setPage] = useState<IPage>(props.initial?.page ?? {});
  const [markerValue, setMarkerValue] = useState<any>();
  const { t } = useTranslation();
  /**
   * EFFECTS
   */

  useEffect(() => {
    loadOptions("").then(function (markers) {
      setMarkerValue(
        markers?.filter((markerObj) => markerObj.value === marker.id)[0]
      );
    });
  }, [marker]);
  /**
   * Call the onChange callback when the filter data changes
   */
  useEffect(() => {
    props.onChange?.({
      marker: marker,
      options: options,
      period: period,
      product: product,
      page: page,
    });
    // eslint-disable-next-line
  }, [marker, options, period, product, page]);

  /**
   * FUNCTIONS
   */

  function getDateRangeControl() {
    return (
      <DateRangePickerWidget
        start={period.start}
        end={period.end}
        ranges={props.dateRanges ?? DateRangePeriods.options}
        onChange={onPeriodChange}
      />
    );
  }

  function getMakeOfModel(model: string): string {
    let result;

    forEach(props.makesModels, (models, make) => {
      if (includes(models, model)) {
        result = make;
        return false;
      }
    });

    if (isUndefined(result)) {
      throw new Error(`Cannot find make of model: ${model}`);
    }

    return result;
  }

  function getModelOfYear(year: string): string {
    let result;
    forEach(getYearsOptions(), (years, model) => {
      if (includes(years, year)) {
        result = model;
        return false;
      }
    });

    if (isUndefined(result)) {
      throw new Error(`Cannot find model of year: ${year}`);
    }

    return result;
  }

  function getYearsControl() {
    return (
      <MakesSelect
        maxMakes={props.maxMakes}
        onChange={onMakesChange}
        options={map(props.makesModels, (_v, k) => k)}
        selected={getSelectedMakes()}
      />
    );
  }

  function getMarkerControl() {
    if (!isNumber(props.dealershipId)) {
      throw new Error("Dealership ID is not defined for SmartSpendFilter!");
    }

    return (
      <MarkerSelect
        marker={isEmpty(marker) ? "" : markerValue}
        dealershipId={props.dealershipId}
        onChange={onMarkerChange}
        loadOptions={loadOptions}
      />
    );
  }

  function getMakesControl() {
    return (
      <ModelsSelect
        maxModels={props.maxModels}
        isDisabled={isEmpty(options?.makes)}
        onChange={onModelsChange}
        options={pick(props.makesModels, getSelectedMakes()) as IKeyOptions}
        selected={getSelectedModels()}
      />
    );
  }

  function getYearsOptions() {
    let years: any = {};
    if (props?.makeObjects)
      Object.entries(props?.makeObjects)?.map((yearObject) => {
        let makes = yearObject[1];

        getSelectedModels()?.map((selectedMake) => {
          map(makes, (_v, k) => {
            if (selectedMake === k) {
              let modelMakeObj = { [k]: Object.keys(_v) };
              Object.assign(years, modelMakeObj);
            }
          });
        });
      });
    return years;
  }

  function getModelsControl() {
    return (
      <YearsSelect
        maxYears={props.maxYears}
        isDisabled={isEmpty(getSelectedModels())}
        onChange={onYearsChange}
        options={getYearsOptions()}
        selected={!isEmpty(getSelectedYears()) ? getSelectedYears() : []}
      />
    );
  }

  function getProductTypeControl() {
    return (
      <ProductTypeRadio onChange={onProductTypeChange} type={product.type} />
    );
  }

  function getPageTypeControl() {
    return <PageTypeRadio onChange={onPageTypeChange} type={page.type} />;
  }

  function getSelectedMakes(): string[] {
    return map(options.makes, (_value, key) => key);
  }

  function getSelectedModels(): string[] {
    let modelsArray: string[] = [];
    forEach(options.makes, (models, make) => {
      forEach(models, (model) => {
        if (!modelsArray.includes(Object.keys(model)[0]))
          modelsArray.push(Object.keys(model)[0]);
      });
    });
    return modelsArray;
  }

  function getSelectedYears(): string[] {
    let yearsArray: string[] = [];
    forEach(options.makes, (models, make) => {
      forEach(models, (model) => {
        let modelName = Object.keys(model)[0];
        forEach(model[modelName], (year) => {
          if (!yearsArray.includes(year)) yearsArray.push(year);
        });
      });
    });
    return yearsArray;
  }

  function hasOption(option: SmartSpendFilterOptions): boolean {
    return props.options?.includes?.(option) ?? true;
  }

  /**
   * CALLBACKS
   */

  function onMakesChange(update: string[]): void {
    let updated: any = { makes: {} };
    forEach(
      update,
      (make) => (updated.makes[make] = options.makes?.[make] ?? [])
    );
    setOptions(updated);
  }

  const loadOptions = (search: string): Promise<IMarker2[]> => {
    return new Promise(async (resolve) => {
      // setIsLoading(true);
      if (props?.dealershipId)
        await getTrafficMarkers({
          dealershipId: props.dealershipId,
          pageLimit: 100,
          pageNumber: 0,
          sorting: [{ field: "created", direction: "desc" }],
          title: search,
        }).then((response) => {
          resolve(
            response.data.content.map((marker) => {
              return {
                value: marker.id,
                label: marker.title,
                date: moment(marker.startDate).toDate(),
              };
            })
          );
        });
      // .finally(() => setIsLoading(false));
    });
  };

  function onMarkerChange(date?: Date, id?: number): void {
    setMarker({ date: date, id: id });

    if (isDate(date)) {
      const period = 30;
      const unit = "days";
      setPeriod({
        start: moment(date)
          .subtract(period, unit)
          .set({
            hour: 0,
            minute: 0,
            seconds: 0,
          })
          .toDate(),
        end: moment(date)
          .add(period, unit)
          .set({
            hour: 23,
            minute: 59,
            seconds: 59,
          })
          .toDate(),
      });
    }
  }

  function onModelsChange(update: string[]): void {
    let updated: any = { makes: {} };
  
    // Initialize makes
    forEach(getSelectedMakes(), (make) => (updated.makes[make] = []));
  
    forEach(update, (model) => {
      if (!isEmpty(options.makes) && !getSelectedModels().includes(model)) {
        let results: any = [];
  
        // Find makes associated with the model
        forEach(props.makesModels, (models, make) => {
          if (includes(models, model)) {
            results.push(make);
          }
        });
  
        let optionalMakeOfModel;
        results.forEach((year) => {
          if (getSelectedMakes().includes(year)) optionalMakeOfModel = year;
        });
  
        const makeKey = getMakeOfModel(model);
  
        if (options.makes?.[makeKey] !== undefined) {
          if (!updated.makes[makeKey]) {
            updated.makes[makeKey] = [];
          }
          return updated.makes[makeKey].push({ [model]: {} });
        } else if (optionalMakeOfModel) {
          if (!updated.makes[optionalMakeOfModel]) {
            updated.makes[optionalMakeOfModel] = [];
          }
          return updated.makes[optionalMakeOfModel].push({ [model]: {} });
        }
      } else if (
        !isEmpty(options.makes) &&
        getSelectedModels().includes(model)
      ) {
        try {
          const makeKey = getMakeOfModel(model);
  
          // Ensure makeKey exists before accessing options.makes
          if (!options.makes[makeKey]) {
            console.warn(`Make key "${makeKey}" not found in options.makes`);
            return;
          }
  
          let makeValues = Object.values(options.makes[makeKey]).map(
            (makeObject) => {
              if (update.includes(Object.keys(makeObject)[0])) {
                return makeObject;
              }
              return null;
            }
          );
  
          updated.makes[makeKey] = makeValues.filter((makeVal) => makeVal != null);
        } catch (e) {
          console.error("Error processing makes:", e);
          Util.error("Element might be undefined, try deleting and selecting again!");
        }
      } else {
        const makeKey = getMakeOfModel(model);
  
        // Ensure the makeKey exists
        if (!updated.makes[makeKey]) {
          updated.makes[makeKey] = [];
        }
        updated.makes[makeKey].push({ [model]: {} });
      }
    });
  
    setOptions(updated);
  }

  function onYearsChange(update: string[]): void {
    try {
      let updated: any = { makes: {} };
  
      // Initialize makes
      forEach(getSelectedMakes(), (make) => {
        updated.makes[make] = [];
      });
  
      // Add selected models to their respective makes
      forEach(getSelectedModels(), (model) => {
        let makeOfModel = getMakeOfModel(model);
  
        // Ensure the makeOfModel key is initialized
        if (!updated.makes[makeOfModel]) {
          updated.makes[makeOfModel] = [];
        }
  
        updated.makes[makeOfModel].push({ [model]: [] });
      });
  
      // Map over the update array
      update?.forEach((model) => {
        let makeOfModel = getModelOfYear(model);
        let yearOfModel = getMakeOfModel(makeOfModel);
  
        // Ensure the yearOfModel key is initialized
        if (!updated.makes[yearOfModel]) {
          updated.makes[yearOfModel] = [];
        }
  
        updated.makes[yearOfModel] = updated.makes[yearOfModel].map(
          (modelObject) => {
            if (!isUndefined(modelObject[makeOfModel])) {
              modelObject[makeOfModel].push(model);
            }
            return modelObject;
          }
        );
      });
  
      setOptions(updated);
    } catch (e) {
      console.error("Caught Error:", e);
      Util.error(
        "Element might be undefined, try deleting and selecting again!"
      );
    }
  }

  function onPeriodChange(start?: Date, end?: Date): void {
    setMarker({});
    setPeriod({
      start: moment(start)
        .set({
          hour: 0,
          minute: 0,
          seconds: 0,
        })
        .toDate(),
      end: moment(end)
        .set({
          hour: 23,
          minute: 59,
          seconds: 59,
        })
        .toDate(),
    });
  }

  function onProductTypeChange(update: number): void {
    setProduct({ type: update });
  }

  function onPageTypeChange(update: number): void {
    setPage({ type: update });
  }

  /**
   * VIEW
   */

  return (
    <div className={"smart-spend-filter-container"}>
      <Button
        id="apply-btn"
        className="no-icon-buttons"
        style={{ position: "absolute", top: "9rem", right: "1.5rem" }}
        label={t("setDefaults")}
        onClick={() => {
          onPeriodChange(
            defaultSmartSpendFilter.period?.start,
            defaultSmartSpendFilter.period?.end
          );
          onPageTypeChange(PageTypes.all);
          onProductTypeChange(ProductTypes.all);
          onMakesChange([]);
          onModelsChange([]);
          props.onChange?.(defaultSmartSpendFilter);
          setOptions({});
          setMarker({});
        }}
      />
      {hasOption(SmartSpendFilterOptions.dateRange) && getDateRangeControl()}
      {hasOption(SmartSpendFilterOptions.marker) && getMarkerControl()}
      {hasOption(SmartSpendFilterOptions.pageType) && getPageTypeControl()}
      {hasOption(SmartSpendFilterOptions.productType) &&
        getProductTypeControl()}
      {hasOption(SmartSpendFilterOptions.years) && getYearsControl()}
      {hasOption(SmartSpendFilterOptions.makes) && getMakesControl()}
      {hasOption(SmartSpendFilterOptions.models) && getModelsControl()}
    </div>
  );
}
