import Axios from "../config/Axios";

export const getCustomerSubscription = (
  hash: string,
  email: string,
  dealershipId: string
) =>
  Axios.instance.get(
    `public/customer-subscriptions/${hash}?email=${email}&dealershipId=${dealershipId}`
  );
export const updateCustomerSubscription = (hash: string, formData: FormData) =>
  Axios.instance.put(`public/customer-subscriptions/${hash}`, formData);
