import "./radio_filter.css";

import { RadioButton } from "primereact/radiobutton";
import React from "react";

interface IProps {
  name: string;
  onChange: (value: number) => void;
  options: { label: string; value: number }[];
  selected?: number;
}

export function EmailRadioFilter(props: IProps) {
  function onChange(checked: boolean, value: number): void {
    if (checked) {
      props.onChange(value);
    }
  }

  return (
    <div className={"email-radio-style"}>
      {props.options.map((o) => (
        <div className={"email-radio-item"} key={o.value}>
          <label className={"radio-filter-item-label"} htmlFor={o.label}>
            {o.label}
          </label>
          <RadioButton
            checked={o.value === props.selected}
            id={o.label}
            name={props.name}
            onChange={(e) => onChange(e.checked, e.value)}
            value={o.value}
          />
        </div>
      ))}
    </div>
  );
}
