import React from "react";
import { useTranslation } from "react-i18next";
import { RadioFilter } from "../../radio_filter/RadioFilter";
import { ViewTypes } from "../../../util/enum/view_types";

interface IProps {
  onChange: (update: number) => void;
  type?: ViewTypes;
}

export function ViewTypeRadio(props: IProps) {
  const { t } = useTranslation();

  function onChange(value: number): void {
    props.onChange(value);
  }

  return (
    <RadioFilter
      name={"product-type-radio"}
      onChange={onChange}
      options={[
        {
          label: t("uniqueVisitors"),
          value: ViewTypes.uniqueVisitors,
        },
        {
          label: t("pageViews"),
          value: ViewTypes.pageViews,
        },
      ]}
      selected={props.type}
    />
  );
}
