import React, { useEffect, useRef, useState } from "react";
import "./launch_request.css";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import Util from "../../util/Util";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { getWebsiteVendors } from "../../actions/globalSettingsActions";
import {
  IAsset,
  ICrmSource,
  IDealershipDto,
  IEnterpriseGroup,
  IWebsiteVendor,
} from "../../../index.dts";
import { InputMask } from "primereact/inputmask";
import { getBrandList } from "../../actions/brandActions";
import { getMyEnterpriseGroups } from "../../actions/enterpriseGroupActions";
import TagsInput from "react-tagsinput";
import { Button } from "primereact/button";
import {
  createDealershipLaunchRequest,
  getLaunchAssets,
} from "../../actions/launchRequestActions";
import { DataView } from "primereact/dataview";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { updateDropdownKey } from "../../actions/authActions";
import { useDispatch } from "react-redux";
import { getCrmSourceList } from "../../actions/crmSourceActions";
import ImageUploading from "react-images-uploading";
import ImageIcon from "@mui/icons-material/Image";
import { ErrorsType, ImageListType } from "react-images-uploading/dist/typings";
import {
  INPUT_TAGS_TYPES,
  TAG_INPUT_PLACEHOLDERS,
} from "../../util/tags_input";
import TagsInputUtil from "../../util/tags_input/TagsInputUtil";

const uniqid = require("uniqid");
const timezones = Util.getTimezones();

export function LaunchRequest() {
  const [newAssetImageFile, setNewAssetImageFile] = useState<ImageListType>([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const unblockHandle = useRef<any>();
  const forceUpdate = useForceUpdate();

  const [loading, setLoading] = useState<boolean>(false);
  const [assetList, setAssetList] = useState<IAsset[]>([]);
  const [activeAsset, setActiveAsset] = useState<IAsset | null>(null);
  const [websiteVendors, setWebsiteVendors] = useState<IWebsiteVendor[]>([]);
  const [enterpriseGroups, setEnterpriseGroups] = useState<IEnterpriseGroup[]>(
    []
  );
  const [weeklyLeadRecipientsInput, setWeeklyLeadRecipientsInput] =
    useState<string>("");
  const [leadRecipientsInput, setLeadRecipientsInput] = useState<string>("");

  const [restrictedIpAddress, setRestrictedIpAddress] = useState<string>("");
  const [dealershipDto, setDealershipDto] = useState<IDealershipDto>({
    timezone: process.env.REACT_APP_DEALERSHIP_TIMEZONE,
  });
  const [dealershipDtoWarnings] = useState<{ [key: string]: string }>({});
  const [crmSourceList, setCrmSourceList] = useState<ICrmSource[]>([]);

  useEffect(() => {
    getAssets();
  }, []);

  useEffect(() => {
    unblockHandle.current = history.block((targetLocation: any) => {
      return onLeave(targetLocation);
    });
    return () => {
      unblockHandle.current.current && unblockHandle.current.current();
    };
    // eslint-disable-next-line
  }, [history.location, dealershipDto]);

  const onLeave = (targetLocation: any): boolean => {
    if (Object.keys(dealershipDto).length > 1) {
      confirmAlert({
        title: "There are unsaved changes!",
        message: "Discard local changes, or save to continue!",
        buttons: [
          {
            label: "Save",
            className: "confirm-save-btn",
            onClick: () => {
              onSubmit(targetLocation);
            },
          },
          {
            label: "Discard",
            onClick: () => {
              setDealershipDto({
                timezone: process.env.REACT_APP_DEALERSHIP_TIMEZONE,
              });
              setTimeout(() => {
                history.push(targetLocation);
              });
            },
            className: "confirm-discard-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
      return false;
    }
    return true;
  };

  const getAssets = (): void => {
    setLoading(true);
    getLaunchAssets()
      .then((response) => {
        setAssetList(response.data.data);
      })
      .catch((error) => Util.showError(error))
      .finally(() => setLoading(false));
  };

  const promiseVendors = (inputValue: string): any =>
    new Promise((resolve) => {
      if (websiteVendors.length === 0) {
        getWebsiteVendors().then((response) => {
          resolve(
            response.data.map((item: any) => {
              return {
                value: item.id,
                label: item.value,
              };
            })
          );
          setWebsiteVendors(response.data);
        });
      } else {
        resolve(
          websiteVendors
            .filter((item: any) =>
              item.value.toLowerCase().includes(inputValue.toLowerCase())
            )
            .map((item: any) => {
              return {
                value: item.id,
                label: item.value,
              };
            })
        );
      }
    });

  const promiseBrands = (inputValue: string): any =>
    new Promise((resolve) => {
      getBrandList({
        name: inputValue,
        pageNumber: 0,
        pageLimit: 40,
        sorting: [],
      }).then((response) => {
        resolve(
          response.data.content.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          })
        );
      });
    });

  const promiseEnterpriseGroups = (inputValue: string): any =>
    new Promise((resolve) => {
      if (enterpriseGroups.length === 0) {
        getMyEnterpriseGroups().then((response) => {
          resolve(
            response.data.map((item: any) => {
              return {
                label: item.name,
                value: item.id,
              };
            })
          );
          setEnterpriseGroups(response.data);
        });
      } else {
        resolve(
          enterpriseGroups
            .filter((item: any) =>
              item.name.toLowerCase().includes(inputValue.toLowerCase())
            )
            .map((item: any) => {
              return {
                value: item.id,
                label: item.name,
              };
            })
        );
      }
    });

  const renderGridItem = (asset: IAsset) => {
    return (
      <div
        onClick={() => onSelectActiveAsset(asset)}
        style={{ margin: 5, cursor: "pointer" }}
        className="product-grid-item card"
      >
        <img
          className={`${
            activeAsset && activeAsset.id === asset.id ? "active" : "inactive"
          }`}
          width={300}
          height={200}
          src={Util.getAssetUrl(asset.objectId)}
          alt={"..."}
        />
      </div>
    );
  };

  const onSelectActiveAsset = (asset: IAsset): void => {
    if (activeAsset && activeAsset.id === asset.id) {
      setActiveAsset(null);
    } else {
      setActiveAsset(asset);
    }
  };

  const getDealershipDtoValue = (field: string, defaultValue?: any): any => {
    return Util._.get(dealershipDto, field, defaultValue);
  };

  const setDealershipDtoValue = (field: string, value: any): void => {
    Util._.set(dealershipDto, field, value);
    forceUpdate();
  };

  const onError = (errors: ErrorsType, files?: ImageListType): void => {
    if (errors?.maxFileSize) {
      Util.warning("File size exceeds the maximum allowed size.");
    }
  };

  const validate = (dealershipDto: IDealershipDto): boolean => {
    Util.emptyObject(dealershipDtoWarnings);

    if (!Util.isEmpty(weeklyLeadRecipientsInput)) {
      const emailArray = weeklyLeadRecipientsInput.split(",");
      emailArray.forEach((email) => {
        if (Util.validateEmail(email)) {
          if (Array.isArray(dealershipDto["performanceReportsRecipientsSet"])) {
            dealershipDto["performanceReportsRecipientsSet"]?.push(email);
          } else {
            dealershipDto["performanceReportsRecipientsSet"] = [email];
          }
        }
      });

      setWeeklyLeadRecipientsInput("");
    }

    if (!Util.isEmpty(leadRecipientsInput)) {
      const emailArray = leadRecipientsInput.split(",");
      emailArray.forEach((email) => {
        if (Util.validateEmail(email)) {
          if (Array.isArray(dealershipDto["leadSummaryRecipientsSet"])) {
            dealershipDto["leadSummaryRecipientsSet"]?.push(email);
          } else {
            dealershipDto["leadSummaryRecipientsSet"] = [email];
          }
        }
      });

      setLeadRecipientsInput("");
    }

    if (!Util.isEmpty(restrictedIpAddress)) {
      const ipAddressArray = restrictedIpAddress.split(",");
      ipAddressArray.forEach((ip) => {
        if (Util.validateIpAddress(ip)) {
          if (Array.isArray(dealershipDto["restrictedIpsSet"])) {
            dealershipDto["restrictedIpsSet"]?.push(ip);
          } else {
            dealershipDto["restrictedIpsSet"] = [ip];
          }
        }
      });
      setRestrictedIpAddress("");
    }

    if (Util.isEmpty(dealershipDto.resellerOrganization)) {
      dealershipDtoWarnings["resellerOrganization"] =
        "Reseller Organization can't be empty.";
    }
    if (Util.isEmpty(dealershipDto.resellerName)) {
      dealershipDtoWarnings["resellerName"] = "Reseller Name can't be empty.";
    }
    if (!Util.validateEmail(dealershipDto.resellerEmail)) {
      dealershipDtoWarnings["resellerEmail"] = "Invalid Reseller Email.";
    }
    if (Util.isEmpty(dealershipDto.name)) {
      dealershipDtoWarnings["name"] = "Dealership Name can't be empty.";
    }
    if (Util.isEmpty(dealershipDto.address)) {
      dealershipDtoWarnings["address"] = "Dealership Address can't be empty.";
    }
    if (Util.isEmpty(dealershipDto.city)) {
      dealershipDtoWarnings["city"] = "Dealership City can't be empty.";
    }
    if (Util.isEmpty(dealershipDto.state)) {
      dealershipDtoWarnings["state"] = "Dealership State can't be empty.";
    }
    if (Util.isEmpty(dealershipDto.zip)) {
      dealershipDtoWarnings["zip"] = "Dealership Zip can't be empty.";
    }
    if (Util.isEmpty(dealershipDto.salesPhoneNumber)) {
      dealershipDtoWarnings["salesPhoneNumber"] =
        "Sales Phone Number can't be empty.";
    }
    if (Util.isEmpty(dealershipDto.homepageURL)) {
      dealershipDtoWarnings["homepageURL"] = "Homepage URL can't be empty.";
    }
    if (!dealershipDto.websiteVendor) {
      dealershipDtoWarnings["websiteVendor"] = "Please select Website Vendor";
    }
    if (!dealershipDto.brandIds || dealershipDto.brandIds.length === 0) {
      dealershipDtoWarnings["brandIds"] = "Please select at least one Brand.";
    }
    if (Util.isEmpty(dealershipDto.timezone)) {
      dealershipDtoWarnings["timezone"] = "Dealership Timezone can't be empty.";
    }
    if (!Util.validateEmail(dealershipDto.mainCrmEmailAddress)) {
      dealershipDtoWarnings["mainCrmEmailAddress"] =
        "Invalid UserViewsWrapper Channel Partner Email.";
    }
    if (Util.isEmpty(dealershipDto.administratorFirstName)) {
      dealershipDtoWarnings["administratorFirstName"] =
        "Administrator First Name can't be empty.";
    }
    if (Util.isEmpty(dealershipDto.administratorLastName)) {
      dealershipDtoWarnings["administratorLastName"] =
        "Administrator Last Name can't be empty.";
    }
    if (!Util.validateEmail(dealershipDto.administratorEmailAddress)) {
      dealershipDtoWarnings["administratorEmailAddress"] =
        "Invalid Administrator Email.";
    }
    if (Util.isEmpty(dealershipDto.administratorPhoneNumber)) {
      dealershipDtoWarnings["administratorPhoneNumber"] =
        "Administrator Phone Number can't be empty.";
    }
    if (Util.isEmpty(dealershipDto.generalManagerFirstName)) {
      dealershipDtoWarnings["generalManagerFirstName"] =
        "General First Name can't be empty.";
    }
    if (Util.isEmpty(dealershipDto.generalManagerLastName)) {
      dealershipDtoWarnings["generalManagerLastName"] =
        "General Last Name can't be empty.";
    }
    if (!Util.validateEmail(dealershipDto.generalManagerEmailAddress)) {
      dealershipDtoWarnings["generalManagerEmailAddress"] =
        "Invalid General Manager Email.";
    }

    return Object.keys(dealershipDtoWarnings).length === 0;
  };

  const onSubmit = (targetLocation?: any): void => {
    if (validate(dealershipDto)) {
      Util.globalSpinner().show();
      const activeOfferAssetId = Util._.get(activeAsset, "id", null);

      const formData = new FormData();
      if (newAssetImageFile?.length > 0) {
        //@ts-ignore
        formData.append("file", newAssetImageFile?.[0]?.file);
      }
      formData.append(
        "data",
        JSON.stringify({
          dealership: dealershipDto,
          activeOfferAssetId: activeOfferAssetId,
        })
      );
      createDealershipLaunchRequest(formData)
        .then((response) => {
          Util.success("Dealership created successfully.");
          setDealershipDto({
            timezone: process.env.REACT_APP_DEALERSHIP_TIMEZONE,
          });

          setTimeout(() => {
            history.push(targetLocation || Util.PATH_NAMES.DEALERSHIPS);
          });
          dispatch(updateDropdownKey(uniqid()));
        })
        .catch((error) => {
          Util.showError(error);
          Util.globalSpinner().hide();
        })
        .finally(() => Util.globalSpinner().hide());
    } else {
      Util.warning("Invalid inputs.");
    }
    forceUpdate();
  };

  const promiseCrmSources = (inputValue: string, crmSourceList: ICrmSource[]) =>
    new Promise((resolve) => {
      if (crmSourceList.length === 0) {
        getCrmSourceList().then((response) => {
          setCrmSourceList(response.data.data);
          resolve(
            response.data.data.map((item: ICrmSource) => {
              return {
                label: item.name,
                value: item.id,
              };
            })
          );
        });
      } else {
        resolve(
          crmSourceList
            .filter((item: ICrmSource) =>
              item.name.toLowerCase().includes(inputValue.toLowerCase())
            )
            .map((item: ICrmSource) => {
              return {
                label: item.name,
                value: item.id,
              };
            })
        );
      }
    });

  return (
    <div id={"launch-request-form"}>
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div className="card card-w-title datatable-demo">
            <Panel header="Reseller Details">
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      Reseller Organization
                    </label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={"Reseller Organization"}
                    defaultValue={getDealershipDtoValue("resellerOrganization")}
                    onChange={(e: any) =>
                      setDealershipDtoValue(
                        "resellerOrganization",
                        e.target.value
                      )
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["resellerOrganization"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Reseller Name</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={"Reseller Name"}
                    defaultValue={getDealershipDtoValue("resellerName")}
                    onChange={(e: any) =>
                      setDealershipDtoValue("resellerName", e.target.value)
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["resellerName"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Reseller Email</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={"Reseller Email"}
                    defaultValue={getDealershipDtoValue("resellerEmail")}
                    onChange={(e: any) =>
                      setDealershipDtoValue(
                        "resellerEmail",
                        Util.getDefaultIfNull(e.target.value, "").trim()
                      )
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["resellerEmail"]}
                  </label>
                </div>
              </div>
            </Panel>
            <Panel style={{ marginTop: 10 }} header="Dealership Details">
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Dealership Name</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={"Dealership Name"}
                    defaultValue={getDealershipDtoValue("name")}
                    onChange={(e: any) =>
                      setDealershipDtoValue("name", e.target.value)
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["name"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Dealership Address</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={"Dealership Address"}
                    defaultValue={getDealershipDtoValue("address")}
                    onChange={(e: any) =>
                      setDealershipDtoValue("address", e.target.value)
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["address"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>City</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={"City"}
                    defaultValue={getDealershipDtoValue("city")}
                    onChange={(e: any) =>
                      setDealershipDtoValue("city", e.target.value)
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["city"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>State</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={"State"}
                    defaultValue={getDealershipDtoValue("state")}
                    onChange={(e: any) =>
                      setDealershipDtoValue("state", e.target.value)
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["state"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Zip</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={"Zip"}
                    defaultValue={getDealershipDtoValue("zip")}
                    onChange={(e: any) =>
                      setDealershipDtoValue("zip", e.target.value)
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["zip"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Sales Phone Number</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputMask
                    style={{ width: "100%" }}
                    mask={Util.phone_mask}
                    placeholder={"Sales Phone Number"}
                    value={getDealershipDtoValue("salesPhoneNumber")}
                    onChange={(e: any) =>
                      setDealershipDtoValue("salesPhoneNumber", e.target.value)
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["salesPhoneNumber"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Homepage URL</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={"Homepage URL"}
                    defaultValue={getDealershipDtoValue("homepageURL")}
                    onChange={(e: any) => {
                      setDealershipDtoValue("siteURL", e.target.value?.trim());
                      setDealershipDtoValue(
                        "homepageURL",
                        e.target.value?.trim()
                      );
                    }}
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["homepageURL"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Website Vendor</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={promiseVendors}
                    placeholder={"Select Website Vendor"}
                    onChange={(item: any) =>
                      setDealershipDtoValue("websiteVendor", {
                        id: item.value,
                        name: item.label,
                      })
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["websiteVendor"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Brands</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <AsyncSelect
                    isMulti={true}
                    cacheOptions
                    defaultOptions
                    closeMenuOnSelect={false}
                    placeholder={"Brands"}
                    className={"multi-select"}
                    loadOptions={promiseBrands}
                    onChange={(value: any) =>
                      setDealershipDtoValue(
                        "brandIds",
                        (value || []).map((item: any) => item.value)
                      )
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["brandIds"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Enterprise Groups</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <AsyncSelect
                    isMulti={true}
                    cacheOptions
                    defaultOptions
                    closeMenuOnSelect={false}
                    placeholder={"Enterprise Groups"}
                    className={"multi-select"}
                    loadOptions={promiseEnterpriseGroups}
                    onChange={(value: any) =>
                      setDealershipDtoValue(
                        "rtsEnterpriseGroupIds",
                        (value || []).map((item: any) => item.value)
                      )
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["rtsEnterpriseGroupIds"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Timezone</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <Select
                    //filter={true}
                    options={timezones}
                    placeholder={"Timezone"}
                    //style={{ width: "100%", boxShadow: "none" }}
                    value={timezones.find(
                      (item) => item.value === dealershipDto.timezone
                    )}
                    onChange={(item: any) =>
                      setDealershipDtoValue("timezone", item.value)
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["timezone"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      Main Channel Partner email address
                    </label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={
                      "UserViewsWrapper Channel Partner email address"
                    }
                    defaultValue={getDealershipDtoValue("mainCrmEmailAddress")}
                    onChange={(e: any) =>
                      setDealershipDtoValue(
                        "mainCrmEmailAddress",
                        Util.getDefaultIfNull(e.target.value, "").trim()
                      )
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["mainCrmEmailAddress"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>IP Addresses</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <TagsInput
                    onlyUnique={true}
                    validationRegex={Util.ipAddressRegex}
                    onValidationReject={(tags) =>
                      TagsInputUtil.onValidationReject(
                        tags,
                        INPUT_TAGS_TYPES.IP_ADDRESS
                      )
                    }
                    inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                    inputValue={restrictedIpAddress}
                    value={
                      Util._.get(dealershipDto, "restrictedIpsSet", []) || []
                    }
                    onChangeInput={setRestrictedIpAddress}
                    onChange={(value: string[]) =>
                      setDealershipDtoValue(
                        "restrictedIpsSet",
                        TagsInputUtil.trimValues(value)
                      )
                    }
                  />
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      Channel Partner Source
                    </label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    placeholder={"Channel Partner Source"}
                    loadOptions={(inputValue: string): any =>
                      promiseCrmSources(inputValue, crmSourceList)
                    }
                    onChange={(value: any) => {
                      setDealershipDtoValue(
                        "crmSource",
                        value
                          ? {
                              name: value.label,
                              id: value.value,
                            }
                          : null
                      );
                    }}
                  />
                </div>
              </div>
            </Panel>
            <Panel
              style={{ marginTop: 10 }}
              header={"Dealership Administrator"}
            >
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      Administrator First Name
                    </label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={"Administrator First Name"}
                    defaultValue={getDealershipDtoValue(
                      "administratorFirstName"
                    )}
                    onChange={(e: any) =>
                      setDealershipDtoValue(
                        "administratorFirstName",
                        e.target.value
                      )
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["administratorFirstName"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      Administrator Last Name
                    </label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={"Administrator Last Name"}
                    defaultValue={getDealershipDtoValue(
                      "administratorLastName"
                    )}
                    onChange={(e: any) =>
                      setDealershipDtoValue(
                        "administratorLastName",
                        e.target.value
                      )
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["administratorLastName"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      Administrator Email Address
                    </label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={"Administrator Email Address"}
                    defaultValue={getDealershipDtoValue(
                      "administratorEmailAddress"
                    )}
                    onChange={(e: any) =>
                      setDealershipDtoValue(
                        "administratorEmailAddress",
                        Util.getDefaultIfNull(e.target.value, "").trim()
                      )
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["administratorEmailAddress"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Administrator Phone</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputMask
                    style={{ width: "100%" }}
                    mask={Util.phone_mask}
                    placeholder={"Administrator Phone Number"}
                    value={getDealershipDtoValue("administratorPhoneNumber")}
                    onChange={(e: any) =>
                      setDealershipDtoValue(
                        "administratorPhoneNumber",
                        e.target.value
                      )
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["administratorPhoneNumber"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      Weekly Lead Receipt Recipients
                    </label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <TagsInput
                    onlyUnique={true}
                    validationRegex={Util.emailRegex}
                    onValidationReject={(tags) =>
                      TagsInputUtil.onValidationReject(
                        tags,
                        INPUT_TAGS_TYPES.EMAIL
                      )
                    }
                    inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                    inputValue={weeklyLeadRecipientsInput}
                    value={getDealershipDtoValue(
                      "performanceReportsRecipientsSet",
                      []
                    )}
                    onChangeInput={setWeeklyLeadRecipientsInput}
                    onChange={(value: string[]) =>
                      setDealershipDtoValue(
                        "performanceReportsRecipientsSet",
                        TagsInputUtil.trimValues(value)
                      )
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["performanceReportsRecipientsSet"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      Human Readable (in email) Lead Recipients
                    </label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <TagsInput
                    onlyUnique={true}
                    validationRegex={Util.emailRegex}
                    onValidationReject={(tags) =>
                      TagsInputUtil.onValidationReject(
                        tags,
                        INPUT_TAGS_TYPES.EMAIL
                      )
                    }
                    inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                    inputValue={leadRecipientsInput}
                    value={getDealershipDtoValue(
                      "leadSummaryRecipientsSet",
                      []
                    )}
                    onChangeInput={setLeadRecipientsInput}
                    onChange={(value: string[]) =>
                      setDealershipDtoValue(
                        "leadSummaryRecipientsSet",
                        TagsInputUtil.trimValues(value)
                      )
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["leadSummaryRecipientsSet"]}
                  </label>
                </div>
              </div>
            </Panel>
            <Panel
              style={{ marginTop: 10 }}
              header={"Dealership General Manager"}
            >
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      General Manager First Name
                    </label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={"General Manager First Name"}
                    defaultValue={getDealershipDtoValue(
                      "generalManagerFirstName"
                    )}
                    onChange={(e: any) =>
                      setDealershipDtoValue(
                        "generalManagerFirstName",
                        e.target.value
                      )
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["generalManagerFirstName"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      General Manager Last Name
                    </label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={"General Manager Last Name"}
                    defaultValue={getDealershipDtoValue(
                      "generalManagerLastName"
                    )}
                    onChange={(e: any) =>
                      setDealershipDtoValue(
                        "generalManagerLastName",
                        e.target.value
                      )
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["generalManagerLastName"]}
                  </label>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      General Manager Email Address
                    </label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={"General Manager Email Address"}
                    defaultValue={getDealershipDtoValue(
                      "generalManagerEmailAddress"
                    )}
                    onChange={(e: any) =>
                      setDealershipDtoValue(
                        "generalManagerEmailAddress",
                        Util.getDefaultIfNull(e.target.value, "").trim()
                      )
                    }
                  />
                  <label className={"warning-label"}>
                    {dealershipDtoWarnings["generalManagerEmailAddress"]}
                  </label>
                </div>
              </div>
            </Panel>

            <DataView
              value={assetList}
              layout={"grid"}
              loading={loading}
              // @ts-ignore
              style={{ marginTop: 10, textAlign: "left" }}
              emptyMessage={"No assets found"}
              itemTemplate={renderGridItem}
              header={
                <div style={{ textAlign: "left" }}>
                  <span style={{ fontSize: 14, fontWeight: "bold" }}>
                    Choose initial offer
                  </span>
                </div>
              }
            />
            <ImageUploading
              value={newAssetImageFile}
              onChange={(e: ImageListType) => setNewAssetImageFile([e[0]])}
              dataURLKey="data_url"
              maxFileSize={2000000}
              onError={onError}
              acceptType={["jpg", "gif", "png", "svg"]}
            >
              {({ imageList, onImageUpload }) => (
                <div
                  style={{
                    borderRadius: "4px",
                    borderBottom: "1px solid #c8c8c8",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <ImageIcon fontSize={"large"} />
                  <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                    Max file size: 0.5mb, accepted: jpg|gif|png|svg
                  </span>
                  <Button
                    id="apply-btn"
                    className="no-icon-buttons"
                    label={"Choose Image"}
                    onClick={() => onImageUpload()}
                  />
                  {imageList?.length > 0 &&
                    imageList?.map((image, index) => (
                      <div key={index} className="uploadPictureContainer">
                        <img
                          src={image["data_url"]}
                          alt=""
                          style={{
                            width: "320px",
                            height: "220px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    ))}
                </div>
              )}
            </ImageUploading>
            <div style={{ marginTop: 10 }}>
              <Button
                id="apply-btn"
                className="no-icon-buttons"
                onClick={() => onSubmit()}
                label={"Submit"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LaunchRequest;
