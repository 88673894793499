import _ from "lodash";

export default class ChatsViewService {
  get dataPollTimeout(): number {
    let timeout: number;
    const source = process.env.REACT_APP_PERSISTENT_CHAT_POLL_INTERVAL;

    if (_.isString(source)) {
      timeout = parseInt(source);

      if (isNaN(timeout)) {
        throw new Error('Ivalid value set for REACT_APP_PERSISTENT_CHAT_POLL_INTERVAL!');
      }
    } else {
      throw new Error('REACT_APP_PERSISTENT_CHAT_POLL_INTERVAL is not configured!');
    }

    return timeout;
  }
}
