import "../models/models_select.css";

import { chain, map } from "lodash";

import { IMakeOptions } from "../../../../index.dts";
import React from "react";
import Select from "react-select";
import { StringSelectOption } from "../../../util/string_select_option";
import { useTranslation } from "react-i18next";
import Util from "../../../util/Util";

interface IProps {
  isDisabled: boolean;
  onChange: (update: string[]) => void;
  options: IMakeOptions;
  selected: string[];
  maxYears?: number;
}

export function YearsSelect(props: IProps) {
  const { t }: any = useTranslation();

  function getOptions(): any[] {
    return chain(props.options)
      .map((value, key) => {
        return {
          label: key,
          options: chain(value)
            .map((v) => new StringSelectOption(v))
            .orderBy("label")
            .value(),
        };
      })
      .orderBy("label")
      .value();
  }

  function onChange(values: any): void {
    if (props.maxYears && values?.length > props.maxYears) {
      Util.warning("You can select a maximum of 3 Years");
      return;
    }

    props.onChange(map(values, (item: any) => item.value));
  }

  return (
    <div className={"models-select-container"}>
      <Select
        backspaceRemovesValue={false}
        //cacheOptions={true}
        className={"models-select"}
        closeMenuOnSelect={false}
        //defaultOptions={true}
        escapeClearsValue={false}
        //filter={true}
        isClearable={true}
        isDisabled={props.isDisabled}
        isMulti={true}
        onChange={onChange}
        options={getOptions()}
        placeholder={t("selectModels")}
        value={props.selected.map((select) => new StringSelectOption(select))}
      />
    </div>
  );
}
