import React, { useEffect, useState } from "react";
import "./visualizer.css";
import Util from "../../../util/Util";
import { getVisitorHistory } from "../../../actions/kaninActions";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import moment from "moment";

interface IProps {
  visitorId: string;
  dealershipId: string;
}

export function VisitHistory({ visitorId, dealershipId }: IProps) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<{ dateTime: string; url: string }[]>([]);

  useEffect(() => {
    if (!Util.isEmpty(visitorId) && !Util.isEmpty(dealershipId)) {
      getData(dealershipId, visitorId);
    }
  }, [visitorId, dealershipId]);

  const getData = (dealershipId: string, visitorId: string) => {
    getVisitorHistory(dealershipId, visitorId)
      .then((response) => {
        setData(
          Util.sortByDate(
            response.data.history.map((item: any) => {
              return {
                url: item.url,
                dateTime: moment(item.dateTime).format(Util.usDateTimeFormat),
              };
            }) || [],
            "dateTime"
          )
        );
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => setLoading(false));
  };

  const urlTemplate = (data: any) => {
    return (
      <div style={{ wordWrap: "break-word" }}>
        <a rel={"noopener noreferrer"} href={data.url} target={"_blank"}>
          {data.url}
        </a>
      </div>
    );
  };

  return (
    <DataTable
      loading={loading}
      filterDisplay="row"
      value={data}
      //responsive={true}
      resizableColumns={true}
      columnResizeMode={"expand"}
      scrollable={true}
      scrollHeight={"400px"}
    >
      <Column
        field="dateTime"
        header="Date Time"
        filter={true}
        filterMatchMode={"contains"}
        sortable={true}
      />
      <Column
        field="url"
        header="Url"
        filter={true}
        filterMatchMode={"contains"}
        sortable={true}
        body={urlTemplate}
      />
    </DataTable>
  );
}

export default VisitHistory;
