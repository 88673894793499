import {
  CampaignDto,
  CampaignSearchDto,
  CampaignUpdateDto,
  CarsListDto,
  FilteredVehicleCampaignFiltersDto,
  IPageable,
} from "../../index.dts";
import Axios from "../config/Axios";

export const createMyCampaign = (dto: CampaignDto): any =>
  Axios.instance.post(`myCampaign`, dto);
export const updateMyCampaign = (id: number, dto: CampaignUpdateDto): any =>
  Axios.instance.put(`myCampaign/${id}`, dto);
export const getMyCampaign = (id: number): any =>
  Axios.instance.get(`myCampaign/${id}`);
export const deleteMyCampaign = (id: number): any =>
  Axios.instance.delete(`myCampaign/${id}`);
export const getMyCampaignsList = (
  searchRequestDto: CampaignSearchDto
): Promise<IPageable<any>> =>
  Axios.instance.post(`myCampaign/list`, searchRequestDto);
export const getCarsListRequest = (dto: CarsListDto): any =>
  Axios.instance.post(`inventory/list`, dto);
export const getActiveNotificationsList = (payload: {
  dealershipId: number;
  myCampaignId: number | null;
}): any => Axios.instance.post(`/myTraffic/notifications/list-mc`, payload);
export const getFilteredCamapignFiltersList = (
  searchRequestDto: FilteredVehicleCampaignFiltersDto
): any => Axios.instance.post(`inventory/filtered/list`, searchRequestDto);
export const getAllActiveCampaigns = (dealershipId: number): any =>
  Axios.instance.get(`myCampaign/rank/list/${dealershipId}`);
export const editRanksOfMyCampaign = (
  myCampaignRequestDtoList: CampaignDto[]
): any => Axios.instance.put(`myCampaign/rank/list/`, myCampaignRequestDtoList);
export const rankMyCampaignsOnetime = () =>
  Axios.instance.get(`myCampaign/rank/onetime/migration`);
export const getValuesForDropdowns = (): any =>
  Axios.instance.get(`smartPixl/uniqueValues`);
