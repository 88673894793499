import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./config/store";
import { createRoot } from "react-dom/client";

import App from "./App";

const backup = console.warn;

console.warn = function filterWarnings(...msg: any) {
  const suppressedWarnings = [
    "Warning: A history supports only one prompt at a time",
  ];

  if (!suppressedWarnings.some((entry) => msg.includes(entry))) {
    backup.apply(console, msg);
  }
};

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
