import {IActiveChat, IStartChatResponse} from "../views/chats";

import Axios from "../config/Axios";
import {AxiosResponse} from "../../index.dts";
import DealershipActiveVisitorDetailsAdapter from "../adapters/dealership_active_visitor_details_adapter";
import IActiveVisitorDetails from "../components/visitor_details/active_visitor_details";

export function getVisitorDetails(dealershipRefId: string, visitorRefId: string): Promise<IActiveVisitorDetails> {
  return new Promise((resolve, reject) => {
    Axios.instance.get(`kanin/visitor?siteid=${dealershipRefId}&visitorId=${visitorRefId}`)
      .then(response => resolve(new DealershipActiveVisitorDetailsAdapter(response.data)))
      .catch(error => reject(error));
  });
};

export function listChats(): Promise<AxiosResponse<IActiveChat[]>> {
  return Axios.instance.get("persistent-chat/active/list");
}

export function startChat(chatId: number): Promise<AxiosResponse<IStartChatResponse>> {
  return Axios.instance.post("/persistent-chat/join", {chatHistoryId: chatId});
}

export const onChatEnd = (chatId: number) => Axios.instance.post("rts/chat/end", {chatHistoryId: chatId});
export const onPersistentChatEnd = (args: { visitorRefId: string; dealershipRefId: string }) => Axios.instance.post("rts/chat/persistent/end", args);