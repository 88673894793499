import React, { useEffect, useState } from "react";
import "./performanceReport.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Util from "../../util/Util";
import { Panel } from "primereact/panel";
import "react-tagsinput/react-tagsinput.css";
import { PerformanceReportTabKeys, PERMISSIONS } from "../../util/Enums";
import MyAcquisitionServicePerformanceReport from "../reports/components/my_acquisition_service/MyAcquisitionServicePerformanceReport";
import MyAcquisitionSalePerformanceReport from "../reports/components/my_acquisition_sale/MyAcquisitionSalePerformanceReport";
import { TabView, TabPanel } from "primereact/tabview";
import AAPerformanceReport from "../reports/components/performance_report/AAPerformanceReport";

export const PERFORMANCE_REPORT_TABS: {
  [key: string]: { name: any; permissions: PERMISSIONS[] };
} = {
  [PerformanceReportTabKeys.MY_ACQUISITIONS_SERVICE]: {
    name: "myAcquisitions Service",
    permissions: [PERMISSIONS.DEALERSHIP_MANAGEMENT],
  },
  [PerformanceReportTabKeys.MY_ACQUISITIONS_SALE]: {
    name: "myAcquisitions Sale",
    permissions: [PERMISSIONS.DEALERSHIP_MANAGEMENT],
  },
  [PerformanceReportTabKeys.MY_ACQUISITIONS_PERFORMANCE]: {
    name: "myAcquisitions Performance",
    permissions: [PERMISSIONS.DEALERSHIP_MANAGEMENT],
  },
};

export function PerformanceReport(props: any) {
  const getPerformanceReportMenu = (): any[] => {
    return Object.entries(PERFORMANCE_REPORT_TABS)
      .filter((entry) => Util.hasAnyAuthority(...entry[1].permissions))
      .map((entry) => {
        const key = entry[0];
        const value = entry[1];

        switch (key) {
          case PerformanceReportTabKeys.MY_ACQUISITIONS_SERVICE:
            return (
              <TabPanel key={key} header={value.name} leftIcon="pi pi-cog">
                <MyAcquisitionServicePerformanceReport />
              </TabPanel>
            );
          case PerformanceReportTabKeys.MY_ACQUISITIONS_SALE:
            return (
              <TabPanel key={key} header={value.name} leftIcon="pi pi-dollar">
                <MyAcquisitionSalePerformanceReport />
              </TabPanel>
            );
          case PerformanceReportTabKeys.MY_ACQUISITIONS_PERFORMANCE:
            return (
              <TabPanel
                key={key}
                header={value.name}
                leftIcon="pi pi-chart-bar"
              >
                <AAPerformanceReport />
              </TabPanel>
            );
          default:
            return null;
        }
      });
  };

  return (
    <Panel
      header={
        <div style={{ display: "flex" }}>
          <label>myAcquisitions Performance</label>
        </div>
      }
    >
      <TabView renderActiveOnly={true} className="tabview-custom">
        {getPerformanceReportMenu()}
      </TabView>
    </Panel>
  );
}

export default PerformanceReport;
