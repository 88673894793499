import React, {CSSProperties} from "react";

interface IProps {
    style?: CSSProperties;
    message: string;
}

export function Info(props: IProps) {

    return (
        <div style={props.style} title={props.message}>
            <i className={"pi pi-question-circle"}/>
        </div>
    );
}

export default Info
