import { BaseChartDataset } from "./base_chart_dataset";
import { ChartConfig } from "../chart_config";

export class AverageEngagementScoreDataset extends BaseChartDataset {
  constructor(args: { label: string, data?: number[] }) {
    super({
      backgroundColor: ChartConfig.averageScore.color,
      borderColor: ChartConfig.averageScore.color,
      borderWidth: ChartConfig.averageScore.borderWidth,
      color: ChartConfig.averageScore.color,
      data: args.data ?? [],
      label: args.label,
      yAxisID: ChartConfig.averageScore.id,
    });
  }
}