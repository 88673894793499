import "../../App.css";
import "./visitor_details.css";

import { TabPanel, TabView } from "primereact/tabview";

import { ConversionHistory } from "../my_traffic/visualizer/ConversionHistory";
import { LeadDetails } from "../my_traffic/visualizer/LeadDetails";
import Converters from "../../util/converters";
import IActiveVisitorDetails from "./active_visitor_details";
import React from "react";
import Util from "../../util/Util";
import VisitHistory from "../my_traffic/visualizer/VisitHistory";
import { useTranslation } from "react-i18next";

export interface IVisitorDetails {
  readonly dealership: {
    readonly id: number;
    readonly refId: string;
  };
  readonly visitor: IActiveVisitorDetails;
  readonly isVrtEnabled?: boolean;
}

export default function VisitorDrawer(props: IVisitorDetails): any {
  const { t }: any = useTranslation();

  return (
    <div className={"visitor-details full-height-width"}>
      <div className={"p-grid"}>
        <div className={"p-col-12"}>
          <div className={"row"}>
            <img
              title={t("visitorId")}
              className={"sidebar-img"}
              src={"assets/icons/visitor_id.svg"}
              alt={""}
            />
            <div className={"sidebar-text"}>
              <span>{props.visitor.refId}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={"p-grid"}>
        <div className={"p-col-12"}>
          <div className={"row"}>
            <img
              title={t("page")}
              className={"sidebar-img"}
              src={"assets/icons/page.svg"}
              alt={""}
            />
            <div className={"sidebar-text"}>
              <span>
                {Converters.pageClassToPageType(
                  props.visitor.pageClass
                ).toUpperCase()}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={"p-grid"}>
        <div className={"p-col-12"}>
          <div className={"row"}>
            <img
              title={`${t("visitorDetails.visits.part1")} 90 ${t(
                "visitorDetails.visits.part2"
              )}`}
              className={"sidebar-img"}
              src={"assets/icons/visits.svg"}
              alt={""}
            />
            <div className={"sidebar-text"}>
              <span>
                {props.visitor.visits.organic + props.visitor.visits.paid}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={"p-grid"}>
        <div className={"p-col-12"}>
          <div className={"row"}>
            <img
              title={t("sessionTime")}
              className={"sidebar-img"}
              src={"assets/icons/session_time.svg"}
              alt={""}
            />
            <div className={"sidebar-text"}>
              <span>{Util.secondsToTime(props.visitor.sessionTime)}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={"p-grid"}>
        <div className={"p-col-12"}>
          <div className={"row"}>
            <img
              title={t("organic")}
              className={"sidebar-img"}
              src={"assets/icons/organic.svg"}
              alt={""}
            />
            <div className={"sidebar-text"}>
              <span>{props.visitor.visits.organic}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={"p-grid"}>
        <div className={"p-col-12"}>
          <div className={"row"}>
            <img
              title={t("paid")}
              className={"sidebar-img"}
              src={"assets/icons/dollar.svg"}
              alt={""}
            />
            <div className={"sidebar-text"}>
              <span>{props.visitor.visits.paid}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={"p-grid"}>
        <div className={"p-col-12"}>
          <div className={"row"}>
            <img
              title={t("make")}
              className={"sidebar-img"}
              src={"assets/icons/wheel.svg"}
              alt={""}
            />
            <div className={"sidebar-text"}>
              <span>{props.visitor.make || "\u2014"}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={"p-grid"}>
        <div className={"p-col-12"}>
          <div className={"row"}>
            <img
              title={t("model")}
              className={"sidebar-img"}
              src={"assets/icons/wheel.svg"}
              alt={""}
            />
            <div className={"sidebar-text"}>
              <span>{props.visitor.model || "\u2014"}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={"p-grid"}>
        <div className={"p-col-12"}>
          <div className={"row"}>
            <img
              title={t("engagementScore")}
              className={"sidebar-img"}
              src={"assets/icons/e_score.svg"}
              alt={""}
            />
            <div className={"sidebar-text"}>
              <span>{Math.round(props.visitor.engagementScore)}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={"p-grid"}>
        <div className={"p-col-12"}>
          <div className={"row"}>
            <img
              title={t("currentURL")}
              className={"sidebar-img"}
              src={"assets/icons/web.svg"}
              alt={""}
            />
            <div className={"sidebar-text"}>
              <a href={props.visitor.url} target={"_blabk"}>
                {props.visitor.url}
              </a>
            </div>
          </div>
        </div>
      </div>
      <TabView className="tabview-custom">
        <TabPanel header={t("conversionHistory")}>
          <ConversionHistory
            visitorId={props.visitor.refId}
            dealershipId={props.dealership.id}
          />
        </TabPanel>
        <TabPanel
          contentClassName={"visitor-history"}
          header={t("visitsHistory")}
        >
          <VisitHistory
            visitorId={props.visitor.refId}
            dealershipId={props.dealership.refId}
          />
        </TabPanel>
        {props.isVrtEnabled && (
          <TabPanel header={t("Lead Details")}>
            <LeadDetails
              visitorId={props.visitor.refId}
              dealershipId={props.dealership.id}
            />
          </TabPanel>
        )}
      </TabView>
    </div>
  );
}
