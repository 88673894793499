import React from "react";
import { Dialog } from "primereact/dialog";
import { Galleria } from "primereact/galleria";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

const ImageGallery = ({
  isOpen,
  onRequestClose,
  images,
  currentIndex,
  setCurrentIndex,
}) => {
  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  const itemTemplate = (item) => {
    return (
      <img
        src={item.itemImageSrc}
        alt={item.alt}
        style={{ width: "100%", display: "block" }}
      />
    );
  };

  return (
    <Dialog
      header="Image Gallery"
      visible={isOpen}
      style={{ width: "80vw" }}
      onHide={onRequestClose}
    >
      <Galleria
        value={images}
        responsiveOptions={responsiveOptions}
        numVisible={5}
        circular
        showItemNavigators
        showThumbnails={false}
        activeIndex={currentIndex}
        item={itemTemplate}
        onItemChange={(e) => setCurrentIndex(e.index)}
      />
    </Dialog>
  );
};

export default ImageGallery;
