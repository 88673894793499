import React, { useEffect, useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import Editable from "../editable/Editable";
import Util from "../../util/Util";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import AsyncSelect from "react-select/async";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import Select from "react-select";
import { MODE } from "../../util/Enums";
import { Panel } from "primereact/panel";
import { IPersistentChat } from "../../../index.dts";
import {
  getChatBots,
  getNotificationUsers,
} from "../../actions/MyTrafficNotificationActions";
import SelectableImage from "../selectable_image/SelectableImage";
import "./persistent_chat.css";
import { IDS } from "../../views/constants";

interface IProps {
  mode: MODE;
  value: IPersistentChat | undefined;
  dealershipId: null | number;
  onChange: (persistentChat: IPersistentChat) => void;
  onAvatarUpload: (avatar: File) => void;
  errors?: { [key: string]: string };
}

const PersistentChat = (props: IProps) => {
  const [persistentChat, setPersistentChat] = useState<IPersistentChat>();

  useEffect(() => {
    if (props.value) {
      setPersistentChat(Util.deepClone(props.value));
    }
  }, [props.value]);

  const setPersistentChatValue = (field: string, value: any) => {
    if (!persistentChat) {
      return;
    }

    // @ts-ignore
    persistentChat[field] = value;

    onUpdate(persistentChat);
  };

  const onUpdate = (persistentChat: IPersistentChat): void => {
    props.onChange(persistentChat);
  };

  const onUsePersistentChatEnabled = (value: boolean): void => {
    if (persistentChat) {
      persistentChat.enabled = value;

      onUpdate(persistentChat);
      return;
    }

    onUpdate({
      enabled: value,
      chatBot: null,
      endTime: "23:59:59",
      startTime: "00:00:00",
      daysOfWeek: Util.daysOfWeek,
      avatarObjectId: null,
      engagementScore: 3,
      threshold: 60,
      crmEmail: null,
      message:
        "Have you already test driven our latest models? You absolutely should!",
      users: [],
    });
  };

  const promiseChatBots = (): any =>
    new Promise((resolve) => {
      getChatBots().then((response) => {
        resolve(
          response.data.map((item) => {
            return {
              value: item.id,
              label: item.botNickName,
            };
          })
        );
      });
    });

  const promiseUsers = (inputValue: string, dealershipId: null | number) =>
    new Promise((resolve) => {
      if (!dealershipId) {
        resolve([]);
        return;
      }

      getNotificationUsers({
        dealershipId: dealershipId,
        userEmail: inputValue,
      }).then((response) => {
        resolve(
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.email,
            };
          })
        );
      });
    });

  const onStartTimeChange = (event: any): void => {
    const value = event.value;

    if (!value) {
      return;
    }

    setPersistentChatValue("startTime", moment(value).format("HH:mm:ss"));
  };

  const onEndTimeChange = (event: any): void => {
    const value = event.value;

    if (!value) {
      return;
    }

    setPersistentChatValue("endTime", moment(value).format("HH:mm:ss"));
  };

  return (
    <Panel
      id={IDS.dealershipsForm.panel.persistentChat}
      header={
        <div style={{ display: "flex", height: 18 }}>
          <label>Persistent Chat</label>
          <div style={{ marginTop: 2, marginLeft: 5 }}>
            <InputSwitch
              id={IDS.dealershipsForm.switch.persistentChatEnabled}
              checked={Util._.get(persistentChat, "enabled", false)}
              onChange={(e: any) => onUsePersistentChatEnabled(e.target.value)}
            />
          </div>
        </div>
      }
    >
      <Editable editable={Util._.get(persistentChat, "enabled", false)}>
        <div className={"p-grid"}>
          <div
            style={{ borderRight: "1px solid #F1F1F5" }}
            className={"p-col-12 p-lg-5"}
          >
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-12 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Greeting Message</label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-12"}>
                <InputText
                  id={IDS.dealershipsForm.inputs.persistentChatMessage}
                  style={{ width: "100%" }}
                  value={Util._.get(persistentChat, "message", "")}
                  placeholder={"Message"}
                  onChange={(e: any) =>
                    setPersistentChatValue("message", e.target.value)
                  }
                />
              </div>
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-12 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Engagement Score</label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-12"}>
                <InputNumber
                  id={IDS.dealershipsForm.inputs.persistentChatEngScore}
                  showButtons={true}
                  style={{ width: "100%" }}
                  placeholder={"Engagement Score"}
                  value={Util._.get(persistentChat, "engagementScore", 0)}
                  onChange={(e: any) =>
                    setPersistentChatValue("engagementScore", e.value)
                  }
                />
              </div>
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-12 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    AI Chatbot (if no user join the visitor's chat)
                  </label>
                  <span className={"red"}>*</span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-12"}>
                <AsyncSelect
                  id={IDS.dropdown.chatBot}
                  cacheOptions
                  defaultOptions
                  value={Util._.get(persistentChat, "chatBot", null)}
                  placeholder={"Select Chatbot"}
                  loadOptions={promiseChatBots}
                  onChange={(value: any) => {
                    setPersistentChatValue("chatBot", value);
                  }}
                />
              </div>
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-12 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Channel Partner Email</label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-12"}>
                <InputText
                  id={IDS.dealershipsForm.inputs.persistentChatCrmEmail}
                  style={{ width: "100%" }}
                  value={Util.getDefaultIfNull(persistentChat?.crmEmail, "")}
                  placeholder={"Channel Partner Email"}
                  onChange={(e: any) =>
                    setPersistentChatValue("crmEmail", e.target.value?.trim())
                  }
                />
                <label className={"warning-label"}>
                  {props.errors?.crmEmail}
                </label>
              </div>
            </div>
          </div>

          <div
            style={{ borderRight: "1px solid #F1F1F5" }}
            className={"p-col-12 p-lg-5"}
          >
            <div className={"p-grid"}>
              <div
                style={{ paddingBottom: 0 }}
                className={"p-col-12 p-lg-12 p-label-col"}
              >
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Allow Notifications for time period
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-12"}>
                <label style={{ fontSize: 10 }}>
                  Select days of week and set time period for which user will
                  handle visitor's chat requests, otherwise the platform will
                  join AI Chatbot automatically in the chat channel.
                </label>
                <Select
                  id={IDS.dropdown.daysOfWeek}
                  isMulti={true}
                  //style={{ width: "100%" }}
                  value={Util._.get(persistentChat, "daysOfWeek", null)}
                  options={Util.daysOfWeek}
                  className={"multi-select"}
                  onChange={(values: any) =>
                    setPersistentChatValue("daysOfWeek", values || [])
                  }
                  placeholder={"Select days of week"}
                  closeMenuOnSelect={false}
                />
              </div>
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-4"}>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-12 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Start Time</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-12"}>
                    <Calendar
                      id={IDS.calendar.startTime}
                      timeOnly={true}
                      showTime={true}
                      readOnlyInput={false}
                      hourFormat={"12"}
                      placeholder={"HH:MM AM/PM"}
                      style={{ width: "100%" }}
                      inputStyle={{ width: "100%" }}
                      value={
                        persistentChat
                          ? moment(
                              `${Util.defaultYear} ${Util._.get(
                                persistentChat,
                                "startTime"
                              )}`
                            ).toDate()
                          : undefined
                      }
                      onChange={onStartTimeChange}
                    />
                  </div>
                </div>
              </div>

              <div className={"p-col-12 p-lg-4"}>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-12 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>End Time</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-12"}>
                    <Calendar
                      id={IDS.calendar.endTime}
                      timeOnly={true}
                      showTime={true}
                      readOnlyInput={false}
                      hourFormat={"12"}
                      placeholder={"HH:MM AM/PM"}
                      style={{ width: "100%" }}
                      inputStyle={{ width: "100%" }}
                      value={
                        persistentChat
                          ? moment(
                              `${Util.defaultYear} ${Util._.get(
                                persistentChat,
                                "endTime"
                              )}`
                            ).toDate()
                          : undefined
                      }
                      onChange={onEndTimeChange}
                    />
                  </div>
                </div>
              </div>

              <div className={"p-col-12 p-lg-4"}>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-12 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        Delay before join AI Chatbot
                      </label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-12"}>
                    <InputNumber
                      id={IDS.dealershipsForm.inputs.persistentChatThreshold}
                      showButtons={true}
                      style={{ width: "100%", minWidth: 0 }}
                      placeholder={"Threshold"}
                      suffix={" (sec)"}
                      value={Util._.get(persistentChat, "threshold", 0)}
                      onChange={(e: any) =>
                        setPersistentChatValue("threshold", e.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-12 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Users</label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-12"}>
                {props.mode === MODE.EDIT ? (
                  <AsyncSelect
                    id={IDS.dropdown.usersDropdown}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    cacheOptions
                    defaultOptions
                    className={"multi-select"}
                    value={Util._.get(persistentChat, "users", [])}
                    loadOptions={(inputValue: string): any =>
                      promiseUsers(inputValue, props.dealershipId)
                    }
                    onChange={(values) =>
                      setPersistentChatValue("users", values || [])
                    }
                  />
                ) : (
                  <div>
                    <label style={{ fontSize: 18 }}>
                      There are no assigned users yet.
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={"p-col-12 p-lg-2"}>
            <div className={"p-grid"} style={{ textAlign: "center" }}>
              <div className={"p-col-12 p-lg-12 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Avatar</label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-12"}>
                <SelectableImage
                  id={IDS.dealershipsForm.inputs.persistentChatAvatar}
                  maxSize={2000000}
                  onChange={props.onAvatarUpload}
                  imgClassName={"avatar"}
                  inputId={"profile_picture_select_input"}
                  url={Util.getAssetUrl(
                    Util._.get(persistentChat, "avatarObjectId", null)
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </Editable>
    </Panel>
  );
};

export default PersistentChat;
