import "./visualizer.css";

import {
  CHAT_HISTORY_TYPE,
  ENGAGEMENT_INTERACTIONS,
  PERMISSIONS,
} from "../../../util/Enums";
import {
  IAsset,
  IChatGreeting,
  IDealershipData,
  IDealershipStats,
  IDealershipVisitor,
  IModal,
  IStore,
} from "../../../../index.dts";
import React, { useEffect, useRef, useState } from "react";
import Stats, { IStats } from "../../../components/my_traffic/visualizer/Stats";
import {
  getDealershipStats,
  getLeadsAvgScore,
  getSwimlanes,
  setSwimLanesConfig,
} from "../../../actions/myTrafficActions";
import { openChat, sendOffer } from "../../../actions/engagementActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Button } from "primereact/button";
import ChatGreetingsModal from "../../../components/chat_greetings_modal/ChatGreetingsModal";
import ChatModes from "../../../constants/chat_modes";
import CloseButton from "../../../components/close_button/CloseButton";
import DealershipActiveVisitorDetailsAdapter from "../../../adapters/dealership_active_visitor_details_adapter";
import Legend from "../../../components/my_traffic/visualizer/Legend";
import SendOfferModal from "../../../components/send_offer_modal/SendOfferModal";
import { Sidebar } from "primereact/sidebar";
import Spinner from "../../../components/spinner/Spinner";
import Util from "../../../util/Util";
import VisitorDrawer from "../../../components/visitor_details/VisitorDrawer";
import { confirmAlert } from "react-confirm-alert";
import { getDealershipData } from "../../../actions/kaninActions";
import moment from "moment";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import { useHistory } from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import { PERIODS } from "../../../util/common";
import { get } from "lodash";
import VisitorDetails from "../../../visitor_details/VisitorDetails";

const queryString = require("query-string");

interface IProps {
  dealershipId: number;
  externalRefId: string;
  dealershipTimezone: string;
  chatMode: ChatModes;
}

interface IState {
  DIAGRAM_SVG: any;
  SELECTED_NODE: any;
  SELECTED_VISITOR: IDealershipVisitor | undefined;
  LANE_TITLES: string[];
}

enum BUBBLES_BORDER_COLORS {
  WHITE = "#fafaf5",
  RED = "#FC5A5A",
  GREEN = "#3DD598",
  BLUE = "#50b5ff",
  ORANGE = "#ff9100",
  TRANSPARENT = "transparent",
}

enum BUBBLES_BACKGROUND_COLORS {
  RED = "#FC5A5A",
  GREEN = "#3DD598",
  ORANGE = "#ff9100",
  BLACK = "#001021",
  BLUE = "#50b5ff",
}

enum TEXT_COLORS {
  BLACK = "#001021",
  WHITE = "#fafaf5",
  GRAY = "#929293",
}

enum FRAME_COLORS {
  GRAY = "#f1f1f5",
  WHITE = "#ffffff",
}

const STATE: IState = {
  DIAGRAM_SVG: undefined,
  LANE_TITLES: ["HOME", "SRP", "VDP", "SERVICE", "OTHER"],
  SELECTED_NODE: undefined,
  SELECTED_VISITOR: undefined,
};

const DIMENSIONS = {
  width: 0,
  height: 0,
};

export const resetStats = (): IStats => {
  return {
    engagements: 0,
    activeSessions: 0,
    sessionsToday: 0,
    period: {
      period: moment().format(Util.localDateFormat),
      visits: { paid: 0, organic: 0, total: 0, adProviders: {} },
      leads: { paid: 0, organic: 0, total: 0, adProviders: {} },
    },
    today: {
      visits: { paid: 0, organic: 0, total: 0, adProviders: {} },
      leads: { paid: 0, organic: 0, total: 0, adProviders: {} },
    },
  };
};

export const isRestrictedIP = (visitor: IDealershipVisitor): boolean => {
  return Util._.get(visitor, "isBlocked", false);
};

export const showEngagementError = (error: any): void => {
  const response = Util._.get(error, "response.data", null);

  if (response.status === 403) {
    const message = Util._.get(response, "message", "");

    if (
      message.toLowerCase().includes("has pending chat") ||
      message.toLowerCase().includes("has pending offer") ||
      message.toLowerCase().includes("already engaged in his current session")
    ) {
      Util.warning(message);
    } else {
      Util.showError(error);
    }
  } else {
    Util.showError(error);
  }
};

export function Visualizer(props: IProps) {
  const legendRef = useRef<any>();
  const dispatch = useDispatch();
  const history = useHistory();
  const statsRef = useRef<any>();
  const [showLegendOpen, setShowLegendOpen] = useState(false);
  const visitorDetailsRef = useRef<IModal>();
  const sendOfferModalRef = useRef<any>();
  const chatGreetingModalRef = useRef<any>();

  const [showAes, setShowAes] = useState<boolean>(false);

  const size = useWindowSize();
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const forceUpdate = useForceUpdate();
  const [stats, setStats] = useState<IStats>(resetStats());
  const chatWidget = useSelector(
    (store: IStore) => store.myTraffic.chatWidget,
    shallowEqual
  );
  const visitorChatMap = useSelector(
    (store: IStore) => store.myTraffic.visitorChatMap,
    shallowEqual
  );
  const swimLanesConfig = useSelector(
    (store: IStore) => store.myTraffic.swimLanesConfig,
    shallowEqual
  );

  const [endDate, setEndDate] = useState<string>(
    moment(PERIODS.LAST_30_DAYS.to).format(Util.localDateFormat)
  );
  const [startDate, setStartDate] = useState<string>(
    moment(PERIODS.LAST_30_DAYS.from).format(Util.localDateFormat)
  );

  const [pending, setPending] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [visitors, setVisitors] = useState<IDealershipVisitor[]>([]);
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  const [engagements, setEngagements] = useState<number>(0);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        showLegendOpen &&
        legendRef.current &&
        !legendRef.current.contains(e.target)
      ) {
        setTimeout(() => {
          setShowLegendOpen(false);
        }, 300);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showLegendOpen]);

  useEffect(() => {
    STATE.SELECTED_VISITOR = undefined;
  }, [props.dealershipId]);

  useEffect(() => {
    appendDiagram();

    if (Object.keys(swimLanesConfig).length === 0) {
      getSwimLanesConfiguration();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showAes) {
      getAes(startDate, endDate);
    }
    // eslint-disable-next-line
  }, [showAes, startDate, endDate, props.dealershipId]);

  useEffect(() => {
    if (Object.keys(swimLanesConfig).length === 0) {
      return;
    }

    setLoading(true);
    sendInitialRequest(
      props.externalRefId,
      props.dealershipId,
      startDate,
      endDate
    ).finally(() => {
      setPending(false);
      setLoading(false);
      Util.globalSpinner().hide();
    });

    const statsInterval = setInterval(() => {
      getStats(props.dealershipId, startDate, endDate)
        .then((response: any) => {
          updateStats(response);
        })
        .catch((error) => {
          Util.showError(error);
        });
    }, 60000);

    const dataQueryInterval = setInterval(() => {
      getData(props.externalRefId);
    }, parseInt(process.env.REACT_APP_MY_TRAFFIC_DATA_TIMEOUT_INTERVAL || "3000"));

    return () => {
      clearInterval(statsInterval);
      clearInterval(dataQueryInterval);
    };

    // eslint-disable-next-line
  }, [props.dealershipId, startDate, endDate, swimLanesConfig]);

  useEffect(() => {
    DIMENSIONS.width = size.width;
    DIMENSIONS.height =
      size.height -
      (Util.hasAnyAuthority(PERMISSIONS.RTS_STATISTICS) ? 320 : 60);

    if (STATE.DIAGRAM_SVG) {
      STATE.DIAGRAM_SVG.selectAll(".bubble-g").remove();
      STATE.DIAGRAM_SVG.select("#avg_score_line").remove();
      STATE.DIAGRAM_SVG.select("#avg_score_text").remove();
      STATE.DIAGRAM_SVG.attr(
        "viewBox",
        `0 0 ${DIMENSIONS.width} ${DIMENSIONS.height}`
      );
    }
    // eslint-disable-next-line
  }, [size]);

  useEffect(() => {
    if (!pending) {
      parseQueryString(window.location.search);
    }
    // eslint-disable-next-line
  }, [window.location.search, pending]);

  const parseQueryString = (search: string): void => {
    const queryObj = queryString.parse(search);

    const visitorExtRefId = Util._.get(queryObj, "visitorExtRefId", null);

    if (!Util.isEmpty(visitorExtRefId)) {
      selectVisitorByExtReefId(visitorExtRefId);
    }

    history.replace({ search: undefined });
  };

  const selectVisitorByExtReefId = (visitorExtRefId: string): void => {
    const visitor = visitors.find((item) => item.visitorID === visitorExtRefId);

    if (!visitor) {
      confirmAlert({
        title: "The visitor is offline !!!",
        message: "The visitor you want to engage with is no longer online.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {},
            className: "confirm-save-btn",
          },
        ],
      });
      return;
    }

    STATE.SELECTED_VISITOR = visitor;
    setDrawerVisible(true);
  };

  const sendInitialRequest = (
    dealershipRefId: string,
    dealershipId: number,
    startDate: string,
    endDate: string
  ) =>
    new Promise((resolve, reject) => {
      return Promise.all([
        getData(dealershipRefId),
        getStats(dealershipId, startDate, endDate),
      ])
        .then((responses: any) => {
          resolve(responses);
          updateStats(responses[1]);
        })
        .catch((error) => {
          reject(error);
          Util.showError(error);
        });
    });

  const getSwimLanesConfiguration = () =>
    new Promise<void>((resolve, reject) => {
      Util.globalSpinner().show();
      getSwimlanes()
        .then((response) => {
          resolve();
          dispatch(setSwimLanesConfig(response.data));
        })
        .catch((error) => {
          reject();
          Util.showError(error);
        })
        .finally(() => {
          Util.globalSpinner().hide();
        });
    });

  const getStats = (dealershipId: number, startDate: string, endDate: string) =>
    new Promise((resolve, reject) => {
      getDealershipStats(dealershipId, startDate, endDate)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getData = (dealershipId: string): Promise<IDealershipData> =>
    new Promise((resolve, reject) => {
      getDealershipData(dealershipId)
        .then((response) => {
          resolve(response.data);
          const visitors = generateBubbles(response.data);

          setVisitors(visitors);
          setEngagements(response.data.engagements);

          updateBubbles(
            STATE.DIAGRAM_SVG,
            visitors,
            response.data.averageScore
          );
        })
        .catch((error) => {
          setStats(resetStats());
          updateBubbles(STATE.DIAGRAM_SVG, [], 0);

          reject(error);
        });
    });

  const getAes = (startDate: string, endDate: string): void => {
    const from = moment(startDate)
      .set({
        hours: 0,
        minutes: 0,
        seconds: 0,
      })
      .format(Util.localDateTimeFormat);

    const to = moment(endDate)
      .set({
        hours: 23,
        minutes: 59,
        seconds: 59,
      })
      .format(Util.localDateTimeFormat);

    getLeadsAvgScore(props.dealershipId, from, to)
      .then((response) => {
        statsRef.current.showAvgLeads(response.data);
      })
      .catch((error) => {
        Util.showError(error);
      });
  };

  const appendDiagram = (): void => {
    STATE.DIAGRAM_SVG = Util.D3.select("#my-traffic-diagram")
      .append("svg")
      .attr("id", "main-svg")
      .attr("height", "100%")
      .attr("width", "100%")
      .attr("viewBox", `0 0 ${DIMENSIONS.width} ${DIMENSIONS.height}`);

    appendDefs(STATE.DIAGRAM_SVG);
    appendLanes(STATE.DIAGRAM_SVG);
  };

  const appendDefs = (svg: any) => {
    const defs = svg.append("defs");

    const dropShadowFilter = defs
      .append("svg:filter")
      .attr("id", "drop-shadow")
      .attr("filterUnits", "userSpaceOnUse")
      .attr("width", "250%")
      .attr("height", "250%");
    dropShadowFilter
      .append("svg:feGaussianBlur")
      .attr("in", "SourceGraphic")
      .attr("stdDeviation", 5)
      .attr("result", "blur-out");
    dropShadowFilter
      .append("svg:feColorMatrix")
      .attr("in", "blur-out")
      .attr("type", "matrix")
      .attr(
        "values",
        `.333 .333 .333 0 0 
                             .333 .333 .333 0 0 
                             .333 .333 .333 0 0 
                             0 0 0 1 0`
      )
      .attr("result", "color-out");
    dropShadowFilter
      .append("svg:feOffset")
      .attr("in", "color-out")
      .attr("dx", 0)
      .attr("dy", 0)
      .attr("result", "the-shadow");
    dropShadowFilter
      .append("svg:feBlend")
      .attr("in", "SourceGraphic")
      .attr("in2", "the-shadow")
      .attr("mode", "normal");
  };

  const appendLanes = (svg: any): void => {
    STATE.LANE_TITLES.forEach((item: string, index: number) => {
      const width = 100 / STATE.LANE_TITLES.length;
      svg
        .append("rect")
        .attr("class", "frame")
        .attr("stroke-width", "0.1%")
        .attr("height", "100%")
        .attr("width", `${width}%`)
        .attr("x", `${index * width}%`)
        .attr("y", "0%")
        .attr("rx", "4px")
        .attr("fill", index % 2 === 0 ? FRAME_COLORS.GRAY : FRAME_COLORS.WHITE);

      const LANE_MID = ((index + 0.5) * 100) / STATE.LANE_TITLES.length;
      svg
        .append("text")
        .text(STATE.LANE_TITLES[index])
        .attr("x", LANE_MID + "%")
        .attr("y", "95%")
        .attr("font-family", "sans-serif")
        .attr("font-size", "1.5vw")
        .attr("text-anchor", "middle")
        .attr("letter-spacing", 10)
        .attr("fill", TEXT_COLORS.GRAY);
    });
  };

  const updateStats = (data: IDealershipStats): void => {
    setStats({
      sessionsToday: get(data, "visitsToday.total", 0),
      today: {
        visits: {
          paid: get(data, "visitsToday.paid", 0),
          organic: get(data, "visitsToday.organic", 0),
          total: get(data, "visitsToday.total", 0),
          //@ts-ignore
          adProviders: get(data, "visitsToday.adProviders", {}),
        },
        leads: {
          paid: get(data, "leadsToday.paid", 0),
          organic: get(data, "leadsToday.organic", 0),
          total: get(data, "leadsToday.total", 0),
          adProviders: get(data, "leadsToday.adProviders", {}),
        },
      },
      period: {
        period: get(data, "visitsLastNDays.period", ""),
        visits: {
          paid: get(data, "visitsLastNDays.paid", 0),
          organic: get(data, "visitsLastNDays.organic", 0),
          total: get(data, "visitsLastNDays.total", 0),
          //@ts-ignore
          adProviders: get(data, "visitsLastNDays.adProviders", {}),
        },
        leads: {
          paid: get(data, "leadsLastNDays.paid", 0),
          organic: get(data, "leadsLastNDays.organic", 0),
          total: get(data, "leadsLastNDays.total", 0),
          adProviders: get(data, "leadsLastNDays.adProviders", {}),
        },
      },
    });
  };

  const updateBubbles = (
    svg: any,
    data: IDealershipVisitor[],
    averageScore: number
  ): void => {
    let groups = svg
      .selectAll(".bubble-g")
      .data(data, (d: IDealershipVisitor) => d.visitorID);

    groups.exit().remove();

    const bubbleGroups = groups
      .enter()
      .append("g")
      .attr("class", "bubble-g")
      .attr("cursor", "pointer")
      .attr("x", (d: IDealershipVisitor) => d.metaData.dx)
      .attr("y", (d: IDealershipVisitor) => d.metaData.dy)
      .attr(
        "transform",
        (d: IDealershipVisitor) =>
          `translate(${
            d.metaData.r +
            d.metaData.borderWidth +
            Math.floor(
              Math.random() *
                (DIMENSIONS.width * (100 / STATE.LANE_TITLES.length / 100) -
                  2 * d.metaData.r -
                  2 * d.metaData.borderWidth)
            )
          })`
      )
      .attr("id", (d: IDealershipVisitor) => d.visitorID)
      .call(
        Util.D3.drag() // call specific function when circle is dragged
          .on("start", dragStarted)
          .on("drag", dragged)
          .on("end", dragEnded)
      );

    bubbleGroups
      .append("circle")
      .attr("class", "bubble")
      .attr("opacity", "0.8")
      .attr("cx", (d: IDealershipVisitor) => d.metaData.dx)
      .attr("cy", (d: IDealershipVisitor) => d.metaData.dy)
      .attr("filter", "url(#drop-shadow)");

    bubbleGroups
      .append("text")
      .attr("class", "e-score")
      .attr("fill", (d: IDealershipVisitor) =>
        d.metaData.fill !== BUBBLES_BACKGROUND_COLORS.BLACK
          ? TEXT_COLORS.BLACK
          : TEXT_COLORS.WHITE
      )
      .attr("font-family", "sans-serif")
      .attr("dominant-baseline", "middle")
      .attr("text-anchor", "middle")
      .attr("font-weight", "bold")
      .attr("x", (d: IDealershipVisitor) => d.metaData.dx)
      .attr("y", (d: IDealershipVisitor) => d.metaData.dy);

    groups = groups.merge(bubbleGroups);
    const t = Util.D3.transition().duration(3000).ease(Util.D3.easeLinear);

    groups
      .select(".bubble")
      .attr("class", (d: IDealershipVisitor) => "bubble " + d.pageClass);

    groups
      .select(".bubble")
      .transition(t)
      .attr("cx", (d: IDealershipVisitor) => d.metaData.dx)
      .attr("cy", (d: IDealershipVisitor) => d.metaData.dy)
      .attr("r", (d: IDealershipVisitor) => d.metaData.r)
      .attr("fill", (d: IDealershipVisitor) => d.metaData.fill)
      .attr("stroke-width", (d: IDealershipVisitor) => d.metaData.borderWidth)
      .attr("stroke", (d: IDealershipVisitor) => d.metaData.borderColor);

    groups
      .select(".e-score")
      .transition(t)
      .text((d: IDealershipVisitor) => d.metaData.eScoreLabel)
      .attr("x", (d: IDealershipVisitor) => d.metaData.dx)
      .attr("y", (d: IDealershipVisitor) => d.metaData.dy)
      .attr("font-size", (d: IDealershipVisitor) => d.metaData.r * 0.8);

    groups.on(
      "click",
      function (event: IDealershipVisitor, d: IDealershipVisitor) {
        // @ts-ignore
        STATE.SELECTED_NODE = this;
        STATE.SELECTED_VISITOR = d;
        setDrawerVisible(true);

        d.metaData.borderColor = getBorderColor(d);

        Util.D3.select(STATE.SELECTED_NODE)
          .select(".bubble")
          .attr("stroke", d.metaData.borderColor)
          .attr("stroke-dasharray", 5);

        forceUpdate();
      }
    );

    function dragStarted(event: any, d: any) {
      // @ts-ignore
      const circle = Util.$(this).children(".bubble")[0];

      if (circle) {
        Util.D3.select(circle).attr("stroke", "red");
      }
    }

    function dragged(event: any, d: any) {
      let dx = event.x;
      if (event.x > d.metaData.xTo - d.metaData.r - d.metaData.borderWidth) {
        dx = d.metaData.xTo - d.metaData.r - d.metaData.borderWidth;
      } else if (
        event.x <
        d.metaData.xFrom + d.metaData.r + d.metaData.borderWidth
      ) {
        dx = d.metaData.xFrom + d.metaData.r + d.metaData.borderWidth;
      }
      // @ts-ignore
      Util.D3.select(this).attr("transform", (d: any) => {
        return `translate(${dx - d.metaData.dx})`;
      });
    }

    function dragEnded(dragEvent: any, d: any) {
      // @ts-ignore
      const node = this;

      const circle = Util.$(node).children(".bubble")[0];
      if (circle) {
        setTimeout(() => {
          if (STATE.SELECTED_NODE !== node) {
            Util.D3.select(circle).attr(
              "stroke",
              (d: any) => d.metaData.borderColor
            );
          }
        });
      }
    }

    updateSelectedVisitor(data);
    updateAvgScoreLine(svg, averageScore);
  };

  const updateAvgScoreLine = (svg: any, averageScore: number) => {
    svg.select("#avg_score_line").remove();
    svg.select("#avg_score_text").remove();

    const y: number = parseFloat(
      `${(1 - averageScore * 0.0095) * (DIMENSIONS.height - 100)}`
    );

    svg
      .append("rect")
      .attr("id", "avg_score_line")
      .attr("height", "1px")
      .attr("width", "100%")
      .attr("x", "0%")
      .attr("y", y)
      .attr("fill", "red")
      .attr("opacity", "1");

    svg
      .append("text")
      .attr("id", "avg_score_text")
      .attr("x", "5px")
      .attr("y", y - 10)
      .attr("font-family", "sans-serif")
      .attr("fill", TEXT_COLORS.BLACK)
      .attr("font-size", "1em")
      .attr("opacity", "0.7")
      .attr("pointer-events", "none")
      .text(
        `Last ${30} days avg. Engagement score: ${Math.round(averageScore)}`
      );
  };

  const updateSelectedVisitor = (data: IDealershipVisitor[]) => {
    if (STATE.SELECTED_VISITOR !== undefined) {
      const index = data.findIndex(
        (visitor) =>
          visitor.visitorID ===
          (STATE.SELECTED_VISITOR as IDealershipVisitor).visitorID
      );

      if (index !== -1) {
        STATE.SELECTED_VISITOR = data[index];
      }
    }
  };

  const generateBubbles = (data: any): any[] => {
    return data.visitors.map((visitor: any) => {
      try {
        const borderWidth = 3;
        visitor["metaData"] = {
          dx: getX(visitor),
          dy: getY(visitor, borderWidth),
          r: getR(visitor),
          xFrom: getX(visitor),
          xTo:
            getX(visitor) +
            (DIMENSIONS.width * (100 / STATE.LANE_TITLES.length)) / 100,
          borderWidth: borderWidth,
          borderColor: getBorderColor(visitor),
          eScoreLabel: getEScore(visitor),
          fill: getFill(visitor),
        };
      } catch (e) {
        console.warn(e);
      }
      return visitor;
    });
  };

  const onHide = (): void => {
    const circle = Util.$(STATE.SELECTED_NODE).children(".bubble")[0];

    STATE.SELECTED_NODE = undefined;
    STATE.SELECTED_VISITOR = undefined;

    if (circle) {
      Util.D3.select(circle).attr("stroke-dasharray", 0);
      Util.D3.select(circle).attr("stroke", (d: any) => {
        d.metaData.borderColor = getBorderColor(d);
        return d.metaData.borderColor;
      });
    }

    setDrawerVisible(false);
  };

  const getPageTypeFromPageClass = (pageClass: string): string => {
    for (const [key, value] of Object.entries(swimLanesConfig)) {
      const valuesArray: string[] = Util.getDefaultIfNull(value, []);

      const index = valuesArray.findIndex(
        (item) => item.toLowerCase() === pageClass.toLowerCase()
      );

      if (index !== -1) {
        return key;
      }
    }

    return "other";
  };

  const getX = (visitor: IDealershipVisitor): number => {
    const pageType = getPageTypeFromPageClass(visitor.pageClass);
    const index = STATE.LANE_TITLES.findIndex(
      (item) => item.toLowerCase() === pageType.toLowerCase()
    );
    if (index !== -1) {
      return DIMENSIONS.width * (100 / STATE.LANE_TITLES.length / 100) * index;
    }

    return (
      DIMENSIONS.width *
      (100 / STATE.LANE_TITLES.length / 100) *
      (STATE.LANE_TITLES.length - 1)
    );
  };

  const getY = (visitor: IDealershipVisitor, borderWidth: number): number => {
    if (Math.round(visitor.eScore) >= 100) {
      return getR(visitor) + borderWidth;
    } else {
      return (
        (1 - Math.round(visitor.eScore) * 0.0095) * (DIMENSIONS.height - 100)
      );
    }
  };

  const getR = (visitor: IDealershipVisitor): number => {
    const min = 12;
    const max = 50;
    const r = visitor.sessionTime / 60 + min;

    return r < min ? min : r > max ? max : r;
  };

  const getEScore = (visitor: IDealershipVisitor): string | number => {
    const leads = Util._.get(visitor, "leads", null);
    const isOrphan = Util._.get(visitor, "owner.is_orphan", false);
    const hasLeads =
      leads !== null &&
      Object.keys(leads).filter((key) => key !== "native").length > 0;
    const hasNativeLeads =
      leads !== null &&
      Object.keys(leads).filter((key) => key === "native").length > 0;
    const hasSmartPixlData = Util._.get(visitor, "hasSmartPixlData", false);
    const disposition = Util._.get(visitor, "disposition");

    if (
      dealershipContext?.blockOffersEnabled == true &&
      visitor?.eScore > dealershipContext?.engagementScoreThreshold
    ) {
      return "B";
    } else if (hasSmartPixlData) {
      return "D";
    } else if (isRestrictedIP(visitor)) {
      return "R";
    } else if (disposition === "SOLD") {
      return "S";
    } else if (hasLeads) {
      return "C";
    } else if (hasNativeLeads) {
      return "W";
    } else if (isOrphan) {
      return "O";
    } else if (visitor.eScore >= 100) {
      return "MAX";
    }

    return Math.round(visitor.eScore);
  };

  const getFill = (visitor: IDealershipVisitor): string => {
    const leads = Util._.get(visitor, "leads", {});
    const disposition = Util._.get(visitor, "disposition");
    const isOrphan = Util._.get(visitor, "owner.is_orphan", false);
    const hasLeads =
      leads !== null &&
      Object.keys(leads).filter((key) => key !== "native").length > 0;
    const hasNativeLeads =
      leads !== null &&
      Object.keys(leads).filter((key) => key === "native").length > 0;
    if (
      dealershipContext?.blockOffersEnabled == true &&
      visitor?.eScore > dealershipContext?.engagementScoreThreshold
    ) {
      return BUBBLES_BACKGROUND_COLORS.RED;
    } else if (isRestrictedIP(visitor)) {
      return BUBBLES_BACKGROUND_COLORS.ORANGE;
    } else if (
      isOrphan ||
      hasLeads ||
      disposition === "SOLD" ||
      hasNativeLeads ||
      visitor.engagements.some((e) =>
        Object.values([
          ENGAGEMENT_INTERACTIONS.CHAT_ACCEPTED,
          ENGAGEMENT_INTERACTIONS.CHAT_IGNORED,
          ENGAGEMENT_INTERACTIONS.OFFER_ACCEPTED,
          ENGAGEMENT_INTERACTIONS.OFFER_IGNORED,
          ENGAGEMENT_INTERACTIONS.AI_CHAT_ACCEPTED,
          ENGAGEMENT_INTERACTIONS.AI_CHAT_IGNORED,
        ]).includes(e)
      )
    ) {
      return BUBBLES_BACKGROUND_COLORS.BLUE;
    } else if (
      visitor.botOrgPaid.toLowerCase() === "organic" ||
      visitor.botOrgPaid.toLowerCase() === "paid_gmb" ||
      visitor.hasSmartPixlData === true
    ) {
      return BUBBLES_BACKGROUND_COLORS.GREEN;
    } else if (visitor.botOrgPaid.includes("paid")) {
      return BUBBLES_BACKGROUND_COLORS.RED;
    }
    return BUBBLES_BACKGROUND_COLORS.BLACK;
  };

  const getBorderColor = (visitor: IDealershipVisitor): string => {
    const botOrgPaid = Util._.get(visitor, "botOrgPaid");

    if (isRestrictedIP(visitor)) {
      return BUBBLES_BORDER_COLORS.WHITE;
    } else if (STATE.SELECTED_VISITOR?.visitorID === visitor.visitorID) {
      return BUBBLES_BORDER_COLORS.RED;
    } else if (
      botOrgPaid === "organic" &&
      Util._.get(visitor, "numPriorPaidVisitsInLast90Days", 0) > 0
    ) {
      return BUBBLES_BORDER_COLORS.RED;
    } else if (
      Util._.includes(botOrgPaid, "paid") &&
      Util._.get(visitor, "numPriorOrganicVisitsInLast90Days", 0) > 0
    ) {
      return BUBBLES_BORDER_COLORS.GREEN;
    }

    return BUBBLES_BORDER_COLORS.WHITE;
  };

  const onOpenChat = () => {
    if (STATE.SELECTED_VISITOR && !isRestrictedIP(STATE.SELECTED_VISITOR)) {
      setDrawerVisible(false);
      chatGreetingModalRef.current.show({ ...STATE.SELECTED_VISITOR });

      STATE.SELECTED_VISITOR = undefined;
    }
  };

  const onSendOffer = () => {
    if (STATE.SELECTED_VISITOR && !isRestrictedIP(STATE.SELECTED_VISITOR)) {
      setDrawerVisible(false);
      sendOfferModalRef.current.show(props.dealershipId, {
        ...STATE.SELECTED_VISITOR,
      });
      STATE.SELECTED_VISITOR = undefined;
    }
  };

  const onSendChatGreeting = (
    visitor?: IDealershipVisitor,
    chatGreeting?: IChatGreeting
  ): void => {
    if (!visitor) {
      return;
    }

    Util.globalSpinner().show();
    openChat({
      visitorExtRefId: visitor.visitorID,
      chatGreetingId: Util._.get(chatGreeting, "id", null),
      dealershipId: props.dealershipId,
      visitorSession: visitor.sessionID,
      startedInUrl: visitor.currentUrl,
      make: visitor.make,
      model: visitor.model,
      year: visitor.year,
      pageType: visitor.pageClass,
      engagementScore: Math.round(visitor.eScore),
      sessionTime: visitor.sessionTime,
      campaignType: visitor.botOrgPaid,
    })
      .then((response) => {
        const visitorId = get(response, "data.visitorId", null);
        const channelId = get(response, "data.channelId", null);
        const chatHistoryId = get(response, "data.id", null);

        if (!visitorId || !channelId || !chatHistoryId) {
          return;
        }

        chatWidget.start({
          channelId: channelId,
          label: dealershipContext.name,
          isCallsEnabled: dealershipContext.chatMode === ChatModes.Calls,
        });

        visitorChatMap.set(visitorId.toString(), {
          chatHistoryId: chatHistoryId,
          type: CHAT_HISTORY_TYPE.my_traffic,
        });
      })
      .catch((error) => {
        showEngagementError(error);
      })
      .finally(() => Util.globalSpinner().hide());
  };

  const onSendStaticOffer = (
    asset: IAsset,
    visitor: IDealershipVisitor
  ): void => {
    if (!visitor) {
      return;
    }

    Util.globalSpinner().show();
    sendOffer({
      assetId: asset.id,
      dealershipId: props.dealershipId,
      type: "STATIC",
      visitorId: visitor.visitorID,
      visitorSession: visitor.sessionID,
    })
      .then((response) => {
        Util.success("Offer sent successfully");
      })
      .catch((error) => {
        showEngagementError(error);
      })
      .finally(() => Util.globalSpinner().hide());
  };

  const onVisitorDetails = (): void => {
    if (!STATE.SELECTED_VISITOR) {
      return;
    }

    visitorDetailsRef.current?.show(STATE.SELECTED_VISITOR.visitorID);
  };

  const onSendDynamicOffer = (
    htmlBody: string,
    visitor: IDealershipVisitor
  ): void => {
    if (!visitor) {
      return;
    }

    Util.globalSpinner().show();
    sendOffer({
      dealershipId: props.dealershipId,
      type: "DYNAMIC",
      visitorId: visitor.visitorID,
      visitorSession: visitor.sessionID,
      htmlBody: htmlBody,
      htmlStyle: null,
    })
      .then((response) => {
        Util.success("Offer sent successfully");
      })
      .catch((error) => {
        showEngagementError(error);
      })
      .finally(() => Util.globalSpinner().hide());
  };

  const onInteractionModalHide = (): void => {
    STATE.SELECTED_VISITOR = undefined;
  };

  const onRangeChange = (startDate: string, endDate: string): void => {
    setEndDate(moment(endDate).format(Util.localDateFormat));
    setStartDate(moment(startDate).format(Util.localDateFormat));
  };

  const onAes = (): void => {
    setShowAes(!showAes);

    if (showAes) {
      statsRef.current.hideAvgLeads();
    }
  };

  return (
    <div id={"visualizer-view"}>
      <Spinner visible={pending} />
      {Util.hasAnyAuthority(PERMISSIONS.RTS_STATISTICS) ? (
        <Stats
          ref={statsRef}
          data={{
            today: stats.today,
            period: stats.period,
            sessionsToday: stats.sessionsToday,
            engagements: engagements,
            activeSessions: visitors.length,
          }}
          startDate={startDate}
          endDate={endDate}
          pending={loading}
          onRangeChange={onRangeChange}
          dealershipId={props.dealershipId}
          dealershipTimezone={props.dealershipTimezone}
        />
      ) : null}
      <div id="my-traffic-diagram">
        <div
          style={{
            position: "absolute",
            right: 28,
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <button
            style={{
              width: 30,
              minWidth: 30,
              backgroundColor: "transparent",
              color: "blue",
              border: "none",
              position: "relative",
              top: 1,
            }}
            title={"Legend"}
            onClick={() => {
              if (!showLegendOpen) {
                setShowLegendOpen(true);
              }
            }}
          >
            <i className={"pi pi-info-circle"} />
          </button>
          {showLegendOpen && (
            <div ref={legendRef}>
              <Legend />
            </div>
          )}
        </div>
      </div>
      <Sidebar
        className="sidebar-selected-session"
        modal={true}
        style={{ width: 400 }}
        position={"right"}
        visible={drawerVisible}
        onHide={onHide}
        showCloseIcon={false}
      >
        {STATE.SELECTED_VISITOR ? (
          <div style={{ width: "100%", height: "100%", padding: "15px" }}>
            <div>
              <h2>Selected Session</h2>
              <CloseButton onHide={onHide} />
            </div>
            {Util.hasAnyAuthority(
              PERMISSIONS.RTS_LEADS_MANAGEMENT,
              PERMISSIONS.RTS_STATISTICS
            ) ? (
              <div className={"sidebar-controls"}>
                <div
                  style={{
                    display: "flex",
                    width: "max-content",
                  }}
                >
                  <Button
                    onClick={onSendOffer}
                    className={"p-button p-button-success no-icon-buttons"}
                    label={"Send Offer"}
                    disabled={isRestrictedIP(STATE.SELECTED_VISITOR)}
                  />
                  {Util.isChatEnabled(props.chatMode) ? (
                    <Button
                      className="no-icon-buttons"
                      onClick={onOpenChat}
                      label={"Open Chat"}
                      disabled={isRestrictedIP(STATE.SELECTED_VISITOR)}
                    />
                  ) : null}
                  {Util.hasAnyAuthority(
                    PERMISSIONS.RTS_SCORING_MODEL_MANAGEMENT
                  ) ? (
                    <Button
                      label={"Details"}
                      className={"p-button p-button-warning no-icon-buttons"}
                      onClick={onVisitorDetails}
                    />
                  ) : null}
                </div>
              </div>
            ) : null}
            <VisitorDrawer
              dealership={{
                id: props.dealershipId,
                refId: props.externalRefId,
              }}
              visitor={
                new DealershipActiveVisitorDetailsAdapter(
                  STATE.SELECTED_VISITOR
                )
              }
              isVrtEnabled={dealershipContext.leadRecordsEnabled}
            />
          </div>
        ) : null}
      </Sidebar>
      <SendOfferModal
        ref={sendOfferModalRef}
        onHide={onInteractionModalHide}
        onStaticOffer={onSendStaticOffer}
        onDynamicOffer={onSendDynamicOffer}
      />
      <ChatGreetingsModal
        ref={chatGreetingModalRef}
        onSelect={onSendChatGreeting}
      />

      {Util.hasAnyAuthority(PERMISSIONS.ALL_DEALERSHIP) &&
      Util.hasAnyAuthority(PERMISSIONS.RTS_STATISTICS) ? (
        <div style={{ position: "fixed", right: 0, bottom: 0 }}>
          <div className={"aes-container"}></div>
          <Button
            onClick={onAes}
            className={"aes-button no-icon-buttons"}
            label={showAes ? "Hide AES" : "Show AES"}
          />
        </div>
      ) : null}
      <VisitorDetails
        dealershipRefId={props.externalRefId}
        ref={visitorDetailsRef}
      />
    </div>
  );
}

export default Visualizer;
