import React, { useEffect, useRef, useState } from "react";
import {
  DEVICE_TYPES,
  MODE,
  OFFER_TYPES,
  VISIBILITY_TYPES,
} from "../../util/Enums";
import {
  IAsset,
  ICrmSource,
  IDealershipContext,
  IOffer,
  IOfferCompact,
  IOfferDto,
  IOfferTemplate,
  ISelectValue,
  IStore,
  IZipsRange,
} from "../../../index.dts";
import { InputText } from "primereact/inputtext";
import AsyncSelect from "react-select/async";
import {
  createTemplate,
  getOfferMultiphaseTemplate,
  getTemplate,
  getTemplateList,
} from "../../actions/templateActions";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { createOffer, getOffer, updateOffer } from "../../actions/offerActions";
import Util from "../../util/Util";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import Axios from "../../config/Axios";
import { getAsset, getAssetList } from "../../actions/assetActions";
import parse from "html-react-parser";
import { TEMPLATE_BODY_CONSTANTS } from "../templates/TemplatesForm";
import { confirmAlert } from "react-confirm-alert";
import { Calendar } from "primereact/calendar";
import moment from "moment-timezone";
import { RadioButton } from "primereact/radiobutton";
import { getDealershipData } from "../../actions/kaninActions";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { groupBadgeStyles, groupStyles } from "../../util/styles";
import AceEditor from "react-ace";

import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-html";
import { Panel } from "primereact/panel";
import { getCrmSourceList } from "../../actions/crmSourceActions";
import { InputTextarea } from "primereact/inputtextarea";
import Info from "../../components/info/Info";
import { DEFAULT_PULL_UP_SOURCE } from "../../constants/offer";
import TagsInput from "react-tagsinput";
import {
  INPUT_TAGS_TYPES,
  TAG_INPUT_PLACEHOLDERS,
} from "../../util/tags_input";
import TagsInputUtil from "../../util/tags_input/TagsInputUtil";
import { Fieldset } from "primereact/fieldset";
import { IDS } from "../constants";
import { useTranslation } from "react-i18next";
import { get, isEqual } from "lodash";
import { DEFAULT_PULL_UP_LEFT_DIV } from "../../constants/left_div";
import { DEFAULT_NEUTRAL_BTN_SOURCE } from "../../constants/scta_neutral";
import { DEFAULT_TRIGGERED_BTN_SOURCE } from "../../constants/scta_triggered";
import { Accordion, AccordionTab } from "primereact/accordion";

const hash = require("object-hash");

enum MultiphaseTheme {
  MULTIPHASE_ROUNDED_EDGE = "MULTIPHASE_ROUNDED_EDGE",
  MULTIPHASE_SHARP_EDGE = "MULTIPHASE_SHARP_EDGE",
}

export function OffersForm(props: any) {
  const history = useHistory();
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();
  const unblockHandle = useRef<any>();

  const dealershipContext: IDealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  const [mode, setMode] = useState<MODE>(MODE.NEW);
  const [offer, setOffer] = useState<IOffer>({
    id: 0,
    externalRefId: "",
    name: "",
    active: true,
    crmDestinationEmail: "",
    customerMessage: "",
    deviceType: DEVICE_TYPES["All Devices"],
    excludeUrls: "",
    excludeZips: "",
    excludeZipsMobile: "",
    expirable: false,
    goalAttachmentNotification: "",
    includeUrls: "",
    includeZips: "",
    leadLimit: 0,
    leadSummaryRecipients: "",
    makesToExclude:
      "service, part, parts, accessories, accessory, maintenance, recall, body",
    makesToLimit: "",
    typesToLimit: "",
    maxOffersPerSession: 4,
    maxSessionLength: 600,
    minSessionDuration: 45,
    minVdpDuration: 15,
    modelsToExclude:
      "service, part, parts, accessories, accessory, maintenance, recall, body",
    modelsToLimit: "",
    vinsToLimit: "",
    vinsToExclude: "",
    offerType: OFFER_TYPES.All,
    pullUpOffer: false,
    blendedOffer: false,
    popUpOffer: true,
    sendReceiptMessage: false,
    showOnEveryVisit: false,
    showOnLeave: false,
    termsAndConditions:
      "Coupon must be presented up-front prior to negotiations. Cannot be combined with other offers. Internet Offers Only. A Test Drive Appointment and, or Appointment with Sales Management must be scheduled at Time of Offer in order to receive Offer. Offer not valid with previous price quotes. Additional terms on dealer email/certificate apply. $500 cash Offers applicable only to shoppers with valid Driver’s License and valid auto insurance that have not purchased a vehicle from dealer or competing dealerships or independent sellers within the last 30 days. See dealer for details.",
    uniquePageViews: 2,
    visibilityType: VISIBILITY_TYPES["All Visitors"],
    yearsToLimit: "",
    asset: null,
    multiphaseAsset: null,
    singleCtaAsset: null,
    template: null,
    startDate: null,
    endDate: null,
    created: "",
    engagementThreshold: 1,
    useEngagementScore: false,
    staticEngagementThreshold: true,
    pullUpOfferSource: DEFAULT_PULL_UP_SOURCE,
    neutralButtonSource: DEFAULT_NEUTRAL_BTN_SOURCE,
    triggeredButtonSource: DEFAULT_TRIGGERED_BTN_SOURCE,
    sideBannerSource: DEFAULT_PULL_UP_LEFT_DIV,
    sideBanner: false,
    useSubSource: false,
    crmSource: null,
    ignoreMakesToLimit: false,
    ignoreModelsToLimit: false,
    ignoreYearsToLimit: false,
    ignoreVinsToLimit: false,
    includeZipsRange: [],
    excludeZipsRange: [],
    excludeZipsMobileRange: [],
  });

  const [avgScore, setAvgScore] = useState<number>(0);
  const [crmSourceList, setCrmSourceList] = useState<ICrmSource[]>([]);
  const [includeZipsRangeArray, setIncludeZipsRangeArray] = useState<
    IZipsRange[]
  >([]);
  const [excludeZipsRangeArray, setExcludeZipsRangeArray] = useState<
    IZipsRange[]
  >([]);
  const [excludeZipsMobileRangeArray, setExcludeZipsMobileRangeArray] =
    useState<IZipsRange[]>([]);

  const [objectHash, setObjectHash] = useState<string>(hashCode(offer));

  const [assetList, setAssetList] = useState<IAsset[]>([]);
  const [selectedDealership, setSelectedDealership] = useState<ISelectValue>({
    value: dealershipContext.id,
    label: dealershipContext.name,
  });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [multiphaseOffer, setMultiphaseOffer] = useState<boolean>(false);
  const [multiphaseTemplates, setMultiphaseTemplates] = useState<
    IOfferTemplate[]
  >([]);
  const [templatesList, setTemplatesList] = useState<any[]>([]);

  useEffect(() => {
    onMount(props.location.state);

    return () => {
      history.block(() => {
        return true;
      });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDealershipData(dealershipContext.externalRefId).then((response) => {
      setAvgScore(response.data.averageScore);
    });
    // eslint-disable-next-line
  }, [dealershipContext.externalRefId]);

  useEffect(() => {
    if (mode === MODE.NEW) {
      offer.asset = null;
      offer.template = null;
      setSelectedDealership({
        value: dealershipContext.id,
        label: dealershipContext.name,
      });
    }
    // eslint-disable-next-line
  }, [dealershipContext]);

  useEffect(() => {
    unblockHandle.current = history.block((targetLocation: any) => {
      if (isSaving) {
        return true; // Allow navigation if saving
      }
      return onLeave(targetLocation);
    });
    return () => {
      unblockHandle.current.current && unblockHandle.current.current();
    };
    // eslint-disable-next-line
  }, [history.location, offer, objectHash, isSaving]);

  useEffect(() => {
    if (offer.template?.dealershipId) {
      Util.globalSpinner().show();
      getAssetList(offer.template.dealershipId)
        .then((response) => {
          setAssetList(
            response.data.data.map((item: IAsset) => {
              return {
                id: item.id,
                objectId: item.objectId,
                key: `(${item.id}) ${item.objectId}`,
              };
            })
          );
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => {
          Util.globalSpinner().hide();
        });
    }
    // eslint-disable-next-line
  }, [offer.template?.dealershipId]);

  const setValue = (field: string, value: any) => {
    const type = typeof value;

    switch (type) {
      case "string":
        // @ts-ignore
        offer[field] = value;
        break;
      default:
        // @ts-ignore
        offer[field] = value;
    }
    forceUpdate();
  };

  const onMount = (state: { mode: MODE; offer?: IOfferCompact } | null) => {
    if (state) {
      setMode(state.mode);

      if (state.mode === MODE.EDIT && state.offer) {
        Util.globalSpinner().show();
        const promises = [getOffer(state.offer.id)];
        if (state.offer.template.assetId) {
          promises.push(getAsset(state.offer.template.assetId));
        }
        Axios.all(promises)
          .then((responses) => {
            const data: IOffer = responses[0].data;
            data["templateAsset"] = responses[1]?.data;

            const offerData: IOffer = {
              id: get(data, "id"),
              externalRefId: get(data, "externalRefId"),
              asset: get(data, "asset"),
              multiphaseAsset: get(data, "multiphaseAsset"),
              singleCtaAsset: get(data, "singleCtaAsset"),
              template: get(data, "template"),
              pullUpOffer: get(data, "pullUpOffer"),
              popUpOffer: get(data, "popUpOffer"),
              blendedOffer: get(data, "blendedOffer"),
              showOnLeave: get(data, "showOnLeave"),
              expirable: get(data, "expirable"),
              showOnEveryVisit: get(data, "showOnEveryVisit"),
              sendReceiptMessage: get(data, "sendReceiptMessage"),
              customerMessage: get(data, "customerMessage"),
              termsAndConditions: get(data, "termsAndConditions"),
              excludeZips: get(data, "excludeZips"),
              excludeZipsMobile: get(data, "excludeZipsMobile"),
              includeZips: get(data, "includeZips"),
              excludeUrls: get(data, "excludeUrls"),
              includeUrls: get(data, "includeUrls"),
              yearsToLimit: get(data, "yearsToLimit"),
              modelsToExclude: get(data, "modelsToExclude"),
              modelsToLimit: get(data, "modelsToLimit"),
              makesToExclude: get(data, "makesToExclude"),
              makesToLimit: get(data, "makesToLimit"),
              typesToLimit: get(data, "typesToLimit"),
              goalAttachmentNotification: get(
                data,
                "goalAttachmentNotification"
              ),
              leadLimit: get(data, "leadLimit"),
              maxOffersPerSession: get(data, "maxOffersPerSession"),
              uniquePageViews: get(data, "uniquePageViews"),
              minVdpDuration: get(data, "minVdpDuration"),
              maxSessionLength: get(data, "maxSessionLength"),
              minSessionDuration: get(data, "minSessionDuration"),
              leadSummaryRecipients: get(data, "leadSummaryRecipients"),
              crmDestinationEmail: get(data, "crmDestinationEmail"),
              deviceType: get(data, "deviceType"),
              visibilityType: get(data, "visibilityType"),
              offerType: get(data, "offerType"),
              name: get(data, "name"),
              active: get(data, "active"),
              templateAsset: get(data, "templateAsset"),
              startDate: get(data, "startDate"),
              endDate: get(data, "endDate"),
              created: get(data, "created"),
              engagementThreshold: get(data, "engagementThreshold"),
              useEngagementScore: get(data, "useEngagementScore"),
              staticEngagementThreshold: get(data, "staticEngagementThreshold"),
              pullUpOfferSource: get(data, "pullUpOfferSource"),
              neutralButtonSource:
                get(data, "neutralButtonSource") ?? DEFAULT_NEUTRAL_BTN_SOURCE,
              triggeredButtonSource:
                get(data, "triggeredButtonSource") ??
                DEFAULT_TRIGGERED_BTN_SOURCE,
              sideBannerSource:
                get(data, "sideBannerSource") ?? DEFAULT_PULL_UP_LEFT_DIV,
              sideBanner: get(data, "sideBanner"),
              useSubSource: get(data, "useSubSource"),
              crmSource: get(data, "crmSource"),
              vinsToLimit: get(data, "vinsToLimit"),
              vinsToExclude: get(data, "vinsToExclude"),
              ignoreMakesToLimit: get(data, "ignoreMakesToLimit"),
              ignoreModelsToLimit: get(data, "ignoreModelsToLimit"),
              ignoreYearsToLimit: get(data, "ignoreYearsToLimit"),
              ignoreVinsToLimit: get(data, "ignoreVinsToLimit"),
              includeZipsRange: get(data, "includeZipsRange"),
              excludeZipsRange: get(data, "excludeZipsRange"),
              excludeZipsMobileRange: get(data, "excludeZipsMobileRange"),
            };
            setMultiphaseOffer(data?.template?.multiphaseEnabled ?? false);
            setIncludeZipsRangeArray(get(data, "includeZipsRange") ?? []);
            setExcludeZipsRangeArray(get(data, "excludeZipsRange") ?? []);
            setExcludeZipsMobileRangeArray(
              get(data, "excludeZipsMobileRange") ?? []
            );
            if (data?.template?.singleCtaEnabled == true) {
              offerData.pullUpOffer = false;
              offerData.popUpOffer = false;
              offerData.showOnLeave = false;
              offerData.blendedOffer = false;
            }
            setOffer(offerData);
            setObjectHash(hashCode(offerData));

            setSelectedDealership({
              value: get(offerData, "template.dealershipId"),
              label: get(offerData, "template.dealershipName"),
            } as any);
          })
          .catch((error) => {
            Util.showError(error);
          })
          .finally(() => {
            Util.globalSpinner().hide();
          });
      }
    }
  };

  // Arrow function to get the highest Multiphase number
  const getNextMultiphaseNumber = (templatesList) => {
    const multiphaseTemplates = templatesList.filter((template) =>
      template.name.startsWith("Multiphase")
    );
    const numbers = multiphaseTemplates.map((template) => {
      const match = template.name.match(/Multiphase (\d+)/);
      return match ? parseInt(match[1], 10) : 0;
    });
    const maxNumber = Math.max(...numbers, 0);
    return maxNumber + 1;
  };

  const promiseTemplates = (inputValue: string, dealershipId?: number) =>
    new Promise((resolve) => {
      getTemplateList({
        pageNumber: 0,
        pageLimit: 40,
        sorting: [],
        name: inputValue,
        dealershipIds: dealershipId ? [dealershipId] : undefined,
      }).then((response) => {
        setTemplatesList(response?.data?.content);
        const multiTemplates = response?.data?.content?.filter(
          (item: IOfferTemplate) => item?.multiphaseEnabled === true
        );
        setMultiphaseTemplates(multiTemplates);
        resolve(
          response.data.content.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
              assetId: item.assetId,
            };
          })
        );
      });
    });

  const onTemplateChange = (props: any) => {
    Util.globalSpinner().show();
    if (props.assetId) {
      Axios.all([getTemplate(props.value), getAsset(props.assetId)])
        .then((responses) => {
          const tempOffer = { ...offer };
          tempOffer.template = responses[0].data;
          if (responses[0].data?.singleCtaEnabled == true) {
            tempOffer.pullUpOffer = false;
            tempOffer.popUpOffer = false;
            tempOffer.showOnLeave = false;
            tempOffer.blendedOffer = false;
          }
          tempOffer.templateAsset = responses[1].data;
          setMultiphaseOffer(tempOffer?.template?.multiphaseEnabled ?? false);
          setOffer(tempOffer);
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => {
          Util.globalSpinner().hide();
        });
    } else {
      getTemplate(props.value)
        .then((response) => {
          offer.templateAsset = undefined;
          setValue("template", response.data);
          setMultiphaseOffer(response.data?.multiphaseEnabled ?? false);
          setValue("singleCtaAsset", response?.data?.singleCtaAsset);
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => {
          Util.globalSpinner().hide();
        });
    }
  };

  const onChangeAsset = (props: any) => {
    if (props.value) {
      const index = assetList.findIndex((asset) => asset.id === props.value);
      if (index > -1) {
        setValue("asset", assetList[index]);
      }
    } else if (offer.template?.assetId && !offer.templateAsset) {
      Util.globalSpinner().show();
      offer.asset = null;
      getAsset(offer.template.assetId)
        .then((response) => {
          setValue("templateAsset", response.data);
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => {
          Util.globalSpinner().hide();
        });
    } else {
      setValue("asset", null);
    }
  };

  const onChangeSctaAsset = (props: any) => {
    if (props.value) {
      const index = assetList.findIndex(
        (singleCtaAsset) => singleCtaAsset.id === props.value
      );
      if (index > -1) {
        setValue("singleCtaAsset", assetList[index]);
      }
    } else {
      setValue("singleCtaAsset", null);
    }
  };

  const onChangeMultiphaseAsset = (props: any) => {
    if (props.value) {
      const index = assetList.findIndex(
        (multiphaseAsset) => multiphaseAsset.id === props.value
      );
      if (index > -1) {
        setValue("multiphaseAsset", assetList[index]);
      }
    } else if (offer.template?.assetId && !offer.templateAsset) {
      Util.globalSpinner().show();
      offer.asset = null;
      getAsset(offer.template.assetId)
        .then((response) => {
          setValue("templateAsset", response.data);
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => {
          Util.globalSpinner().hide();
        });
    } else {
      setValue("multiphaseAsset", null);
    }
  };

  const offerToDto = (offer: IOffer): IOfferDto => {
    return {
      externalRefId: get(offer, "externalRefId"),
      name: get(offer, "name"),
      active: get(offer, "active"),
      templateId: get(offer, "template.id"),
      assetId: get(offer, "asset.id") ?? null,
      multiphaseAssetId: get(offer, "multiphaseAsset.id") ?? null,
      singleCtaAssetId: get(offer, "singleCtaAsset.id") ?? null,
      offerType: get(offer, "offerType"),
      visibilityType: get(offer, "visibilityType"),
      deviceType: get(offer, "deviceType"),
      excludeZipsMobile: get(offer, "excludeZipsMobile"),
      crmDestinationEmail: get(offer, "crmDestinationEmail"),
      leadSummaryRecipients: get(offer, "leadSummaryRecipients"),
      minSessionDuration: get(offer, "minSessionDuration"),
      maxSessionLength: get(offer, "maxSessionLength"),
      minVdpDuration: get(offer, "minVdpDuration"),
      uniquePageViews: get(offer, "uniquePageViews"),
      maxOffersPerSession: get(offer, "maxOffersPerSession"),
      leadLimit: get(offer, "leadLimit"),
      goalAttachmentNotification: get(offer, "goalAttachmentNotification"),
      makesToLimit: get(offer, "makesToLimit"),
      typesToLimit: get(offer, "typesToLimit"),
      makesToExclude: get(offer, "makesToExclude"),
      yearsToLimit: get(offer, "yearsToLimit"),
      showOnEveryVisit: get(offer, "showOnEveryVisit"),
      expirable: get(offer, "expirable"),
      includeUrls: get(offer, "includeUrls"),
      excludeUrls: get(offer, "excludeUrls"),
      includeZips: get(offer, "includeZips"),
      excludeZips: get(offer, "excludeZips"),
      termsAndConditions: get(offer, "termsAndConditions"),
      sendReceiptMessage: get(offer, "sendReceiptMessage"),
      showOnLeave: get(offer, "showOnLeave"),
      pullUpOffer: get(offer, "pullUpOffer"),
      popUpOffer: get(offer, "popUpOffer"),
      blendedOffer: get(offer, "blendedOffer"),
      customerMessage: get(offer, "customerMessage"),
      modelsToExclude: get(offer, "modelsToExclude"),
      modelsToLimit: get(offer, "modelsToLimit"),
      engagementThreshold: get(offer, "engagementThreshold"),
      useEngagementScore: get(offer, "useEngagementScore"),
      staticEngagementThreshold: get(offer, "staticEngagementThreshold", false),
      startDate: offer.startDate
        ? moment(offer.startDate).format(Util.localDateFormat)
        : undefined,
      endDate: offer.endDate
        ? moment(offer.endDate).format(Util.localDateFormat)
        : undefined,
      pullUpOfferSource: get(offer, "pullUpOfferSource"),
      neutralButtonSource: get(offer, "neutralButtonSource"),
      triggeredButtonSource: get(offer, "triggeredButtonSource"),
      sideBannerSource: get(offer, "sideBannerSource"),
      sideBanner: get(offer, "sideBanner"),
      useSubSource: get(offer, "useSubSource"),
      crmSourceId: get(offer, "crmSource.id"),
      vinsToLimit: get(offer, "vinsToLimit"),
      vinsToExclude: get(offer, "vinsToExclude"),
      ignoreMakesToLimit: get(offer, "ignoreMakesToLimit"),
      ignoreModelsToLimit: get(offer, "ignoreModelsToLimit"),
      ignoreYearsToLimit: get(offer, "ignoreYearsToLimit"),
      ignoreVinsToLimit: get(offer, "ignoreVinsToLimit"),
      includeZipsRange: includeZipsRangeArray,
      excludeZipsRange: excludeZipsRangeArray,
      excludeZipsMobileRange: excludeZipsMobileRangeArray,
    };
  };

  const isValid = (offer: IOffer): boolean => {
    if (Util.isEmpty(offer.name)) {
      Util.warning("Invalid Offer Name");
      return false;
    }

    if (!offer.template) {
      Util.warning("Invalid Offer Template");
      return false;
    }

    return true;
  };

  function hashCode(offer: IOffer) {
    return hash(offer);
  }

  const onLeave = (targetLocation: any): boolean => {
    const offerHash = hashCode(offer);

    if (!isEqual(offerHash, objectHash)) {
      confirmAlert({
        title: "There are unsaved changes!",
        message: "Discard local changes, or save to continue!",
        buttons: [
          {
            label: "Save",
            className: "confirm-save-btn",
            onClick: () => {
              onSave(targetLocation);
            },
          },
          {
            label: "Discard",
            onClick: () => {
              setObjectHash(offerHash);
              setTimeout(() => {
                history.push(targetLocation);
              }, 0);
            },
            className: "confirm-discard-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
      return false;
    }
    return true;
  };

  const onDealershipChange = (props: ISelectValue) => {
    offer.asset = null;
    offer.template = null;
    setSelectedDealership(props);
  };

  const onAddIncludeRange = () => {
    let tempIncludeZipsRangeArray: IZipsRange[] = [];
    let tempZipRange = {
      start: "",
      end: "",
    };
    tempIncludeZipsRangeArray.push(...includeZipsRangeArray, tempZipRange);
    setIncludeZipsRangeArray(tempIncludeZipsRangeArray);
  };

  const onRemoveIncludeRange = () => {
    setIncludeZipsRangeArray((previousArr) => previousArr.slice(0, -1));
  };

  const onAddExcludeRange = () => {
    let tempExcludeZipsRangeArray: IZipsRange[] = [];
    let tempZipRange = {
      start: "",
      end: "",
    };
    tempExcludeZipsRangeArray.push(...excludeZipsRangeArray, tempZipRange);
    setExcludeZipsRangeArray(tempExcludeZipsRangeArray);
  };

  const onRemoveExcludeRange = () => {
    setExcludeZipsRangeArray((previousArr) => previousArr.slice(0, -1));
  };

  const onAddExcludeMobileRange = () => {
    let tempExcludeZipsMobileRangeArray: IZipsRange[] = [];
    let tempZipRange = {
      start: "",
      end: "",
    };
    tempExcludeZipsMobileRangeArray.push(
      ...excludeZipsMobileRangeArray,
      tempZipRange
    );
    setExcludeZipsMobileRangeArray(tempExcludeZipsMobileRangeArray);
  };

  const onRemoveExcludeMobileRange = () => {
    setExcludeZipsMobileRangeArray((previousArr) => previousArr.slice(0, -1));
  };

  const onSave = (targetLocation?: any) => {
    setIsSaving(true);
    if (isValid(offer)) {
      Util.globalSpinner().show();
      if (mode === MODE.NEW) {
        createOffer(offerToDto(offer))
          .then((response) => {
            setObjectHash(hashCode(offer));
            setTimeout(() => {
              if (targetLocation) {
                history.push(targetLocation);
              } else {
                history.push(Util.PATH_NAMES.OFFERS);
              }
              Util.success("Offer created successfully.");
            });
            setIsSaving(false);
          })
          .catch((error) => {
            Util.showError(error);
            setIsSaving(false);
          })
          .finally(() => {
            Util.globalSpinner().hide();
          });
      } else {
        updateOffer(offer.id, offerToDto(offer))
          .then((response) => {
            setObjectHash(hashCode(offer));
            setTimeout(() => {
              if (targetLocation) {
                history.push(targetLocation);
              } else {
                history.push(Util.PATH_NAMES.OFFERS);
              }
              Util.success("Offer updated successfully.");
            });
            setIsSaving(false);
          })
          .catch((error) => {
            Util.showError(error);
            setIsSaving(false);
          })
          .finally(() => {
            Util.globalSpinner().hide();
          });
      }
    }
  };

  const onBack = () => {
    history.push(Util.PATH_NAMES.OFFERS);
  };

  const assetDropdownTemplate = (asset: IAsset) => {
    return (
      <div>
        <img
          style={{ width: 50, height: 50 }}
          alt={asset.objectId}
          src={Util.getAssetUrl(asset.objectId)}
        />
        <span style={{ marginLeft: 5 }}>{asset.objectId}</span>
      </div>
    );
  };

  const sctaAssetDropdownTemplate = (singleCtaAsset: IAsset) => {
    return (
      <div>
        <img
          style={{ width: 50, height: 50 }}
          alt={singleCtaAsset.objectId}
          src={Util.getAssetUrl(singleCtaAsset.objectId)}
        />
        <span style={{ marginLeft: 5 }}>{singleCtaAsset.objectId}</span>
      </div>
    );
  };

  const multiphaseAssetDropdownTemplate = (multiphaseAsset: IAsset) => {
    return (
      <div>
        <img
          style={{ width: 50, height: 50 }}
          alt={multiphaseAsset.objectId}
          src={Util.getAssetUrl(multiphaseAsset.objectId)}
        />
        <span style={{ marginLeft: 5 }}>{multiphaseAsset.objectId}</span>
      </div>
    );
  };

  const showPreview = (): boolean => {
    return !!(offer.template && (offer.asset || offer.templateAsset));
  };

  const getCalendarDates = (): Date[] | Date => {
    if (offer.expirable) {
      const dates: Date[] = [];
      if (offer.startDate) {
        dates.push(moment(offer.startDate).toDate());

        if (offer.endDate) {
          dates.push(moment(offer.endDate).toDate());
        }
      }
      return dates;
    }
    return moment(offer.startDate).toDate();
  };

  const onEngagementThresholdChange = (value: number): void => {
    if (value >= 1 && value <= 100) {
      setValue("engagementThreshold", value);
    } else {
      forceUpdate();
    }
  };

  const formatGroupLabel = (data: any) => {
    return (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.total}</span>
      </div>
    );
  };

  const isAssetDisabled = (): boolean => {
    return !get(offer, "template.dealershipId");
  };

  const getAssetValue = (): number => {
    return get(offer, "asset.id", 0);
  };

  const getSctaAssetValue = (): number => {
    return (
      get(offer, "singleCtaAsset.id", 0) ??
      get(offer, "template.singleCtaAsset.id", 0)
    );
  };

  const getMultiphaseAssetValue = (): number => {
    return get(offer, "multiphaseAsset.id", 0);
  };

  const promiseCrmSources = (inputValue: string, crmSourceList: ICrmSource[]) =>
    new Promise((resolve) => {
      if (crmSourceList.length === 0) {
        getCrmSourceList().then((response) => {
          setCrmSourceList(response.data.data);
          resolve(
            response.data.data.map((item: ICrmSource) => {
              return {
                label: item.name,
                value: item.id,
              };
            })
          );
        });
      } else {
        resolve(
          crmSourceList
            .filter((item: ICrmSource) =>
              item.name.toLowerCase().includes(inputValue.toLowerCase())
            )
            .map((item: ICrmSource) => {
              return {
                label: item.name,
                value: item.id,
              };
            })
        );
      }
    });

  const getSCTAPreview = (): React.ReactElement | null => {
    if (!showPreview()) {
      return null;
    }

    const showSCTA = (bodyString) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = bodyString;
      const sctaDiv = tempDiv.querySelector("#single-cta-preview");
      if (sctaDiv) {
        sctaDiv.setAttribute("style", "display: block;");
      }
      // Find the element with id "multiphase-one-form" and add the style attribute
      const form1 = tempDiv.querySelector("#multiphase-one-form");
      if (form1) {
        form1.setAttribute("style", "display: none;");
      }

      // Find the element with id "multiphase-two-form" and add the style attribute
      const form2 = tempDiv.querySelector("#multiphase-two-form");
      if (form2) {
        form2.setAttribute("style", "display: none;");
      }

      // Find the normal offer element with class name "pi_pop_up_main_container"
      const normalOffer = tempDiv.querySelector(".pi_pop_up_main_container");
      if (normalOffer) {
        normalOffer.setAttribute("style", "display: none;");
      }
      return tempDiv.innerHTML;
    };

    let bodyString = offer?.template?.body;
    bodyString = showSCTA(bodyString);

    return (
      <div id={"offers-preview"} style={{ borderRadius: 0 }}>
        {parse(
          `<div><style>${offer?.template?.style}</style>${bodyString
            .replace(
              TEMPLATE_BODY_CONSTANTS.IMAGE_SOURCE_PLACEHOLDER,
              Util.getAssetUrl(
                get(offer as any, "multiphaseAsset.objectId", null) ||
                  get(offer, "templateAsset.objectId", null)
              )
            )
            .replace(
              TEMPLATE_BODY_CONSTANTS.IMAGE_SCTA,
              Util.getAssetUrl(
                get(offer as any, "singleCtaAsset.objectId", null) ||
                  get(offer as any, "template.singleCtaAsset.objectId", null)
              )
            )
            .replaceAll(
              TEMPLATE_BODY_CONSTANTS.MAIN_COLOR,
              `${offer?.template?.multiphaseMainColor}`
            )
            .replaceAll(
              TEMPLATE_BODY_CONSTANTS.BORDER_RADIUS_MULTI,
              String(offer?.template?.multiphaseTheme) ===
                String("MULTIPHASE_SHARP_EDGE")
                ? "0"
                : "16px"
            )
            .replaceAll(
              /SUBMIT/g,
              String(offer?.template?.multiphaseTheme) ===
                String("MULTIPHASE_SHARP_EDGE")
                ? "Submit"
                : "SUBMIT"
            )
            .replaceAll(
              TEMPLATE_BODY_CONSTANTS.SCTA_BACKGROUND_COLOR,
              `${offer?.template?.singleCtaBackgroundColor}`
            )
            .replaceAll(
              TEMPLATE_BODY_CONSTANTS.BACKGROUND_COLOR,
              `${offer?.template?.multiphaseBackgroundColor}`
            )}</div>`
        )}
      </div>
    );
  };

  const getSecondPartPreview = (): React.ReactElement | null => {
    if (!showPreview()) {
      return null;
    }

    const modifyBody = (bodyString) => {
      // Create a temporary DOM element to manipulate the HTML string
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = bodyString;

      // Find the element with id "multiphase-one-form" and add the style attribute
      const form1 = tempDiv.querySelector("#multiphase-one-form");
      if (form1) {
        form1.setAttribute("style", "display: none;");
      }

      // Find the element with id "multiphase-two-form" and add the style attribute
      const form2 = tempDiv.querySelector("#multiphase-two-form");
      if (form2) {
        form2.setAttribute("style", "display: block;");
      }

      return tempDiv.innerHTML;
    };

    let bodyString = offer?.template?.body;
    bodyString = modifyBody(bodyString);

    return (
      <div id={"offers-preview"}>
        {parse(
          `<div><style>${offer?.template?.style}</style>${bodyString
            .replace(
              TEMPLATE_BODY_CONSTANTS.IMAGE_SOURCE_PLACEHOLDER,
              Util.getAssetUrl(
                get(offer as any, "multiphaseAsset.objectId", null) ||
                  get(offer, "templateAsset.objectId", null)
              )
            )
            .replace(
              TEMPLATE_BODY_CONSTANTS.IMAGE_SCTA,
              Util.getAssetUrl(
                get(offer as any, "singleCtaAsset.objectId", null) ||
                  get(offer as any, "template.singleCtaAsset.objectId", null)
              )
            )
            .replaceAll(
              TEMPLATE_BODY_CONSTANTS.MAIN_COLOR,
              `${offer?.template?.multiphaseMainColor}`
            )
            .replaceAll(
              TEMPLATE_BODY_CONSTANTS.BORDER_RADIUS_MULTI,
              String(offer?.template?.multiphaseTheme) ===
                String("MULTIPHASE_SHARP_EDGE")
                ? "0"
                : "16px"
            )
            .replaceAll(
              /SUBMIT/g,
              String(offer?.template?.multiphaseTheme) ===
                String("MULTIPHASE_SHARP_EDGE")
                ? "Submit"
                : "SUBMIT"
            )
            .replaceAll(
              TEMPLATE_BODY_CONSTANTS.BACKGROUND_COLOR,
              `${offer?.template?.multiphaseBackgroundColor}`
            )}</div>`
        )}
      </div>
    );
  };

  const getPreview = (): React.ReactElement | null => {
    if (!showPreview()) {
      return null;
    }

    return (
      <div id={"offers-preview"}>
        {parse(
          `<div><style>${offer?.template?.style}</style>${offer.template?.body
            .replace(
              TEMPLATE_BODY_CONSTANTS.IMAGE_SOURCE_PLACEHOLDER,
              Util.getAssetUrl(
                get(offer as any, "asset.objectId", null) ||
                  get(offer, "templateAsset.objectId", null)
              )
            )
            .replace(
              TEMPLATE_BODY_CONSTANTS.IMAGE_SCTA,
              Util.getAssetUrl(
                get(offer as any, "singleCtaAsset.objectId", null) ||
                  get(offer as any, "template.singleCtaAsset.objectId", null)
              )
            )
            .replaceAll(
              TEMPLATE_BODY_CONSTANTS.MAIN_COLOR,
              `${offer?.template?.multiphaseMainColor}`
            )
            .replaceAll(
              TEMPLATE_BODY_CONSTANTS.BORDER_RADIUS_MULTI,
              String(offer?.template?.multiphaseTheme) ===
                String("MULTIPHASE_SHARP_EDGE")
                ? "0"
                : "16px"
            )
            .replaceAll(
              /SUBMIT/g,
              String(offer?.template?.multiphaseTheme) ===
                String("MULTIPHASE_SHARP_EDGE")
                ? "Submit"
                : "SUBMIT"
            )
            .replaceAll(
              TEMPLATE_BODY_CONSTANTS.BACKGROUND_COLOR,
              `${offer?.template?.multiphaseBackgroundColor}`
            )}</div>`
        )}
      </div>
    );
  };

  const getUseEngScorePanel = (): React.ReactElement | null => {
    if (!dealershipContext.rtsEnabled) {
      return null;
    }

    return (
      <AccordionTab
        // id={IDS.offersForm.panel.useEngagementScore}
        style={{ width: "100%", marginBottom: 10 }}
        header={
          <div style={{ display: "flex", height: 18 }}>
            <label>Use Eng Score</label>
            <div style={{ marginTop: 2, marginLeft: 5 }}>
              <InputSwitch
                id={IDS.offersForm.switch.useEngagementScore}
                checked={offer.useEngagementScore}
                onChange={(e: any) =>
                  setValue("useEngagementScore", e.target.value)
                }
              />
            </div>
          </div>
        }
      >
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-6"}>
            <div style={{ display: "flex" }}>
              <label>Static Eng Threshold (1-100)</label>
              <div
                style={{
                  border: "1px solid #cccccc",
                  width: "100%",
                  display: "flex",
                  height: 35,
                  minWidth: 0,
                }}
              >
                <RadioButton
                  id={IDS.offersForm.radio.useStaticEngagementScore}
                  name="useEngagementScore"
                  checked={offer.staticEngagementThreshold}
                  disabled={!offer.useEngagementScore}
                  onChange={(e: any) =>
                    setValue("staticEngagementThreshold", e.target.checked)
                  }
                  style={{ padding: 7 }}
                />
                <InputNumber
                  id={IDS.offersForm.inputs.staticEngagementThreshold}
                  min={0}
                  max={101}
                  style={{ width: "100%" }}
                  showButtons={true}
                  value={offer.engagementThreshold}
                  disabled={
                    !offer.useEngagementScore ||
                    !offer.staticEngagementThreshold
                  }
                  onChange={(e: any) => onEngagementThresholdChange(e.value)}
                  className={"eng-score-input"}
                />
              </div>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div style={{ display: "flex" }}>
              <label>Dynamic Eng Threshold</label>
              <div
                style={{
                  border: "1px solid #cccccc",
                  width: "100%",
                  display: "flex",
                  height: 35,
                  minWidth: 0,
                }}
              >
                <RadioButton
                  id={IDS.offersForm.radio.useDynamicEngagementScore}
                  name="useEngagementScore"
                  checked={!offer.staticEngagementThreshold}
                  disabled={!offer.useEngagementScore}
                  onChange={(e: any) =>
                    setValue("staticEngagementThreshold", !e.target.checked)
                  }
                  style={{ padding: 7 }}
                />
                <InputNumber
                  id={IDS.offersForm.inputs.avgScore}
                  readOnly
                  style={{ width: "100%" }}
                  value={avgScore}
                  disabled={true}
                  className={"eng-score-input"}
                />
              </div>
            </div>
          </div>
        </div>
      </AccordionTab>
    );
  };

  return (
    <div id={IDS.offersForm.wrapper}>
      <div className="card card-w-title datatable-demo">
        <Panel
          id={IDS.offersForm.panel.preview}
          style={{
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.3)",
            marginBottom: 10,
          }}
          header={
            <>
              <div style={{ display: "flex", marginTop: 5, marginBottom: 5 }}>
                <div
                  style={{
                    display: "flex",
                    height: 18,
                    marginRight: 5,
                  }}
                >
                  <label>Pull Up Offer</label>
                  <div style={{ marginTop: 2, marginLeft: 5 }}>
                    <InputSwitch
                      id={IDS.offersForm.switch.pullUpOffer}
                      checked={offer.pullUpOffer}
                      onChange={(e: any) =>
                        setValue("pullUpOffer", e.target.value)
                      }
                      disabled={offer?.template?.singleCtaEnabled == true}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    height: 18,
                    marginRight: 5,
                    marginLeft: 5,
                  }}
                >
                  <label>Pop Up Offer</label>
                  <div style={{ marginTop: 2, marginLeft: 5 }}>
                    <InputSwitch
                      id={IDS.offersForm.switch.popUpOffer}
                      checked={offer.popUpOffer}
                      onChange={(e: any) =>
                        setValue("popUpOffer", e.target.value)
                      }
                      disabled={offer?.template?.singleCtaEnabled == true}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    height: 18,
                    marginRight: 5,
                    marginLeft: 5,
                  }}
                >
                  <label>Show On Leave</label>
                  <div style={{ marginTop: 2, marginLeft: 5 }}>
                    <InputSwitch
                      id={IDS.offersForm.switch.showOnLeave}
                      checked={offer.showOnLeave}
                      onChange={(e: any) =>
                        setValue("showOnLeave", e.target.value)
                      }
                      disabled={offer?.template?.singleCtaEnabled == true}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    height: 18,
                    marginRight: 5,
                    marginLeft: 5,
                  }}
                >
                  <label>Blended Offer</label>
                  <div style={{ marginTop: 2, marginLeft: 5 }}>
                    <InputSwitch
                      id={IDS.offersForm.switch.blendedOffer}
                      checked={offer.blendedOffer}
                      onChange={(e: any) =>
                        setValue("blendedOffer", e.target.value)
                      }
                      disabled={offer?.template?.singleCtaEnabled == true}
                    />
                    <Info
                      style={{ float: "right", marginLeft: 10 }}
                      message={
                        "Display initial Pull Up Offer. On page change, display pop up offer. Afterwards the Pull Up text will remain for the current session."
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    height: 18,
                    marginRight: 5,
                    marginLeft: 5,
                  }}
                >
                  <label>Multiphase Offer</label>
                  <div style={{ marginTop: 2, marginLeft: 5 }}>
                    <InputSwitch
                      id={IDS.offersForm.switch.multiphaseOffer}
                      checked={multiphaseOffer}
                      onChange={(e) => {
                        setMultiphaseOffer(e?.target?.value);
                        if (e?.target?.value)
                          if (multiphaseTemplates?.length > 0) {
                            let templateObj = {
                              value: multiphaseTemplates[0]?.id,
                              label: multiphaseTemplates[0]?.name,
                              assetId: multiphaseTemplates[0]?.assetId,
                            };
                            onTemplateChange(templateObj);
                          } else {
                            const hasMultiphaseName = templatesList?.some(
                              (template) => template.name === "Multiphase"
                            );
                            // Determine the next Multiphase number
                            const nextMultiphaseNumber =
                              getNextMultiphaseNumber(templatesList);
                            const newTemplateName = `Multiphase ${nextMultiphaseNumber}`;
                            getOfferMultiphaseTemplate(true, false).then(
                              (response) => {
                                createTemplate({
                                  active: true,
                                  name: hasMultiphaseName
                                    ? newTemplateName
                                    : "Multiphase",
                                  multiphaseEnabled: true,
                                  body: response?.data?.body,
                                  style: response?.data?.style,
                                  assetId: null,
                                  assetObjectId: null,
                                  dealershipId:
                                    templatesList &&
                                    templatesList[0]?.dealershipId,
                                  dealershipName:
                                    templatesList &&
                                    templatesList[0]?.dealershipName,
                                  multiphaseTheme:
                                    MultiphaseTheme.MULTIPHASE_ROUNDED_EDGE,
                                  multiphaseText:
                                    "I agree to receive promotional text messages on my phone!",
                                  multiphaseMainColor: "#2fc072",
                                  multiphaseBackgroundColor: "#fff",
                                  singleCtaBackgroundColor: "",
                                  singleCtaEnabled: false,
                                  singleCtaAsset: null,
                                  buttons: undefined,
                                })
                                  .then((response) => {
                                    let templateObj = {
                                      value: response?.data?.id,
                                      label: response?.data?.name,
                                      assetId: response?.data?.assetId,
                                    };
                                    onTemplateChange(templateObj);
                                  })
                                  .catch((error) => {
                                    Util.showError(error);
                                  });
                              }
                            );
                          }
                      }}
                      disabled={offer?.template?.singleCtaEnabled == true}
                    />
                  </div>
                </div>
              </div>
              {offer?.template?.singleCtaEnabled && (
                <div
                  style={{
                    fontSize: "small",
                    fontStyle: "italic",
                    color: "red",
                  }}
                >
                  <label>
                    The options above are disabled, because the selected
                    template is Single CTA
                  </label>
                </div>
              )}
            </>
          }
        >
          {offer?.template?.singleCtaEnabled && getSCTAPreview()}
          {getPreview()}
          {offer?.template?.multiphaseEnabled && getSecondPartPreview()}
        </Panel>

        <Panel
          id={IDS.offersForm.panel.basic}
          style={{ width: "100%", marginBottom: 10 }}
          header={"Basic"}
        >
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Offer Name</label>
                <span className={"red"}>*</span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                style={{ width: "100%" }}
                value={offer.name}
                placeholder={"Offer Name"}
                onChange={(e: any) => setValue("name", e.target.value)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Dealership</label>
                <span className={"red"}>*</span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <AsyncSelect
                id={IDS.dropdown.dealership}
                menuPosition={"fixed"}
                cacheOptions
                defaultOptions
                value={selectedDealership}
                loadOptions={Util.promiseMyDealerships}
                placeholder={"Select Dealership"}
                formatGroupLabel={formatGroupLabel}
                onChange={(data: any) => onDealershipChange(data)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Template</label>
                <span className={"red"}>*</span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <AsyncSelect
                id={IDS.dropdown.templateDropdown}
                key={selectedDealership.value}
                isDisabled={!selectedDealership.value}
                cacheOptions
                defaultOptions
                value={
                  offer.template
                    ? {
                        label: offer.template.name,
                        value: offer.template.id,
                      }
                    : null
                }
                placeholder={"Template"}
                loadOptions={(inputValue): any =>
                  promiseTemplates(inputValue, selectedDealership.value)
                }
                onChange={onTemplateChange}
              />
            </div>
          </div>
          {offer?.template?.singleCtaEnabled && (
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Default Single CTA Asset
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <Dropdown
                  id={IDS.dropdown.sctaAssetDropdown}
                  style={{ width: "100%" }}
                  disabled={isAssetDisabled()}
                  placeholder={"Select Asset"}
                  optionLabel={"key"}
                  optionValue={"id"}
                  options={assetList}
                  showClear={true}
                  value={getSctaAssetValue()}
                  onChange={onChangeSctaAsset}
                  itemTemplate={sctaAssetDropdownTemplate}
                />
              </div>
            </div>
          )}
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Asset</label>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <Dropdown
                id={IDS.dropdown.assetDropdown}
                style={{ width: "100%" }}
                disabled={isAssetDisabled()}
                placeholder={"Select Asset"}
                optionLabel={"key"}
                optionValue={"id"}
                options={assetList}
                showClear={true}
                value={getAssetValue()}
                onChange={onChangeAsset}
                itemTemplate={assetDropdownTemplate}
              />
            </div>
          </div>
          {offer?.template?.multiphaseEnabled && (
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Multiphase Asset</label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <Dropdown
                  id={IDS.dropdown.multiphaseAssetDropdown}
                  style={{ width: "100%" }}
                  disabled={isAssetDisabled()}
                  placeholder={"Select Multiphase Asset"}
                  optionLabel={"key"}
                  optionValue={"id"}
                  options={assetList}
                  showClear={true}
                  value={getMultiphaseAssetValue()}
                  onChange={onChangeMultiphaseAsset}
                  itemTemplate={multiphaseAssetDropdownTemplate}
                />
              </div>
            </div>
          )}
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Show to</label>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <Dropdown
                id={IDS.dropdown.visitorType}
                style={{ width: "100%" }}
                placeholder={"Show to"}
                value={offer.visibilityType}
                options={Object.entries(VISIBILITY_TYPES).map((entry) => {
                  return {
                    label: entry[0],
                    value: entry[1],
                  };
                })}
                onChange={(e) => {
                  setValue("visibilityType", e.value);
                  forceUpdate();
                }}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Show On</label>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <Dropdown
                id={IDS.dropdown.device}
                style={{ width: "100%" }}
                placeholder={"Show On"}
                value={offer.deviceType}
                options={Object.entries(DEVICE_TYPES).map((entry) => {
                  return {
                    label: entry[0],
                    value: entry[1],
                  };
                })}
                onChange={(e) => {
                  setValue("deviceType", e.value);
                  forceUpdate();
                }}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Lead Summary Recipients</label>
              </div>
            </div>
            <div
              id={IDS.offersForm.inputs.leadSummaryRecipients}
              className={"p-col-12 p-lg-9"}
            >
              <TagsInput
                onlyUnique={true}
                validationRegex={Util.emailRegex}
                onValidationReject={(tags) =>
                  TagsInputUtil.onValidationReject(tags, INPUT_TAGS_TYPES.EMAIL)
                }
                inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                value={Util.getStringArray(offer.leadSummaryRecipients)}
                onChange={(value: string[]) =>
                  setValue("leadSummaryRecipients", TagsInputUtil.join(value))
                }
              />
            </div>
          </div>
        </Panel>

        <Accordion multiple>
          <AccordionTab
            // id={IDS.offersForm.panel.restrictions}
            style={{ width: "100%", marginBottom: 10 }}
            header={"Restrictions"}
          >
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Minimum Page View Duration (Seconds)
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <InputNumber
                  id={IDS.offersForm.inputs.minSessionDuration}
                  min={0}
                  showButtons={true}
                  style={{ width: "100%" }}
                  value={offer.minSessionDuration}
                  placeholder={"Minimum Page View Duration (Seconds)"}
                  onChange={(e: any) => {
                    setValue("minSessionDuration", e.value);
                  }}
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Minimum VDP Duration (Seconds)
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <InputNumber
                  id={IDS.offersForm.inputs.minVdpDuration}
                  min={0}
                  showButtons={true}
                  style={{ width: "100%" }}
                  value={offer.minVdpDuration}
                  placeholder={"Minimum VDP Duration (Seconds)"}
                  onChange={(e: any) => setValue("minVdpDuration", e.value)}
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Session Length (Seconds)
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <InputNumber
                  id={IDS.offersForm.inputs.maxSessionLength}
                  min={0}
                  showButtons={true}
                  style={{ width: "100%" }}
                  value={offer.maxSessionLength}
                  placeholder={"Session Length (Seconds)"}
                  onChange={(e: any) => setValue("maxSessionLength", e.value)}
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Unique Page Views</label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <InputNumber
                  id={IDS.offersForm.inputs.uniquePageViews}
                  min={0}
                  showButtons={true}
                  style={{ width: "100%" }}
                  value={offer.uniquePageViews}
                  placeholder={"Unique Page Views"}
                  onChange={(e: any) => setValue("uniquePageViews", e.value)}
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Maximum Offers Per Session
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <InputNumber
                  id={IDS.offersForm.inputs.maxOffersPerSession}
                  min={0}
                  showButtons={true}
                  style={{ width: "100%" }}
                  value={offer.maxOffersPerSession}
                  placeholder={"Maximum Offers Per Session"}
                  onChange={(e: any) =>
                    setValue("maxOffersPerSession", e.value)
                  }
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Lead Limit</label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <InputNumber
                  id={IDS.offersForm.inputs.leadLimit}
                  min={0}
                  showButtons={true}
                  style={{ width: "100%" }}
                  value={offer.leadLimit}
                  placeholder={"Lead Limit"}
                  onChange={(e: any) => setValue("leadLimit", e.value)}
                />
              </div>
            </div>
          </AccordionTab>

          <AccordionTab
            // id={IDS.offersForm.panel.restrictionsBasedOnUrl}
            style={{ width: "100%", marginBottom: 10 }}
            header={"Restrictions based on URL"}
          >
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Offer Type</label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <Dropdown
                  id={IDS.dropdown.offerType}
                  style={{ width: "100%" }}
                  placeholder={"Offer Type"}
                  value={offer.offerType}
                  options={Object.entries(OFFER_TYPES).map((entry) => {
                    return {
                      label: entry[0],
                      value: entry[1],
                    };
                  })}
                  onChange={(e) => {
                    setValue("offerType", e.value);
                    forceUpdate();
                  }}
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Only URLs
                    <Info
                      style={{ float: "right", marginLeft: 2 }}
                      message={"Make sure to hit Enter after each url."}
                    />
                  </label>
                </div>
              </div>
              <div
                id={IDS.offersForm.inputs.includeUrls}
                className={"p-col-12 p-lg-9"}
              >
                <TagsInput
                  onlyUnique={true}
                  validationRegex={Util.urlRegex}
                  onValidationReject={(tags) =>
                    TagsInputUtil.onValidationReject(tags, INPUT_TAGS_TYPES.URL)
                  }
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(offer.includeUrls)}
                  onChange={(value: string[]) =>
                    setValue("includeUrls", TagsInputUtil.join(value))
                  }
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Exclude Urls
                    <Info
                      style={{ float: "right", marginLeft: 2 }}
                      message={"Make sure to hit Enter after each url."}
                    />
                  </label>
                </div>
              </div>
              <div
                id={IDS.offersForm.inputs.excludeUrls}
                className={"p-col-12 p-lg-9"}
              >
                <TagsInput
                  onlyUnique={true}
                  validationRegex={Util.urlRegex}
                  onValidationReject={(tags) =>
                    TagsInputUtil.onValidationReject(tags, INPUT_TAGS_TYPES.URL)
                  }
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(offer.excludeUrls)}
                  onChange={(value: string[]) =>
                    setValue("excludeUrls", TagsInputUtil.join(value))
                  }
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Limit Types To...
                    <Info
                      style={{ float: "right", marginLeft: 2 }}
                      message={"Make sure to hit Enter after each type."}
                    />
                  </label>
                </div>
              </div>
              <div
                id={IDS.offersForm.inputs.typesToLimit}
                className={"p-col-12 p-lg-9"}
              >
                <TagsInput
                  onlyUnique={true}
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(offer.typesToLimit)}
                  onChange={(value: string[]) =>
                    setValue("typesToLimit", TagsInputUtil.join(value))
                  }
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Limit Years To...
                    <Info
                      style={{ float: "right", marginLeft: 2 }}
                      message={"Make sure to hit Enter after each year."}
                    />
                  </label>
                </div>
              </div>
              <div
                id={IDS.offersForm.inputs.yearsToLimit}
                className={"p-col-12 p-lg-9"}
              >
                <TagsInput
                  onlyUnique={true}
                  validationRegex={Util.yearRegex}
                  onValidationReject={(tags) =>
                    TagsInputUtil.onValidationReject(
                      tags,
                      INPUT_TAGS_TYPES.YEAR
                    )
                  }
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(offer.yearsToLimit)}
                  onChange={(value: string[]) =>
                    setValue("yearsToLimit", TagsInputUtil.join(value))
                  }
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Limit Makes To...
                    <Info
                      style={{ float: "right", marginLeft: 2 }}
                      message={"Make sure to hit Enter after each make."}
                    />
                  </label>
                </div>
              </div>
              <div
                id={IDS.offersForm.inputs.makesToLimit}
                className={"p-col-12 p-lg-9"}
              >
                <TagsInput
                  onlyUnique={true}
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(offer.makesToLimit)}
                  onChange={(value: string[]) =>
                    setValue("makesToLimit", TagsInputUtil.join(value))
                  }
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Exclude Makes
                    <Info
                      style={{ float: "right", marginLeft: 2 }}
                      message={"Make sure to hit Enter after each make."}
                    />
                  </label>
                </div>
              </div>
              <div
                id={IDS.offersForm.inputs.makesToExclude}
                className={"p-col-12 p-lg-9"}
              >
                <TagsInput
                  onlyUnique={true}
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(offer.makesToExclude)}
                  onChange={(value: string[]) =>
                    setValue("makesToExclude", TagsInputUtil.join(value))
                  }
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Limit Models To...
                    <Info
                      style={{ float: "right", marginLeft: 2 }}
                      message={"Make sure to hit Enter after each model."}
                    />
                  </label>
                </div>
              </div>
              <div
                id={IDS.offersForm.inputs.modelsToLimit}
                className={"p-col-12 p-lg-9"}
              >
                <TagsInput
                  onlyUnique={true}
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(offer.modelsToLimit)}
                  onChange={(value: string[]) =>
                    setValue("modelsToLimit", TagsInputUtil.join(value))
                  }
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Exclude Models
                    <Info
                      style={{ float: "right", marginLeft: 2 }}
                      message={"Make sure to hit Enter after each model."}
                    />
                  </label>
                </div>
              </div>
              <div
                id={IDS.offersForm.inputs.modelsToExclude}
                className={"p-col-12 p-lg-9"}
              >
                <TagsInput
                  onlyUnique={true}
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(offer.modelsToExclude)}
                  onChange={(value: string[]) =>
                    setValue("modelsToExclude", TagsInputUtil.join(value))
                  }
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Limit Vins To...
                    <Info
                      style={{ float: "right", marginLeft: 2 }}
                      message={"Make sure to hit Enter after each model."}
                    />
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <TagsInput
                  onlyUnique={true}
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(offer.vinsToLimit)}
                  onChange={(value: string[]) =>
                    setValue("vinsToLimit", TagsInputUtil.join(value))
                  }
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Exclude Vins
                    <Info
                      style={{ float: "right", marginLeft: 2 }}
                      message={"Make sure to hit Enter after each model."}
                    />
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <TagsInput
                  onlyUnique={true}
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(offer.vinsToExclude)}
                  onChange={(value: string[]) =>
                    setValue("vinsToExclude", TagsInputUtil.join(value))
                  }
                />
              </div>
            </div>
          </AccordionTab>

          <AccordionTab
            // id={IDS.offersForm.panel.restrictionsBasedOnZip}
            style={{ width: "100%", marginBottom: 10 }}
            header={"Restrictions based on ZIP Code"}
          >
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Only ZIPs
                    <Info
                      style={{ float: "right", marginLeft: 2 }}
                      message={"Make sure to hit Enter after each zip."}
                    />
                  </label>
                </div>
              </div>
              <div
                id={IDS.offersForm.inputs.includeZips}
                className={"p-col-12 p-lg-9"}
              >
                <TagsInput
                  onlyUnique={true}
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(offer.includeZips)}
                  onChange={(value: string[]) =>
                    setValue("includeZips", TagsInputUtil.join(value))
                  }
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}></div>
              </div>
              <div
                id={IDS.offersForm.inputs.includeZips}
                className={"p-col-12 p-lg-9"}
              >
                <div
                  key={-1}
                  style={{
                    display: "flex",
                    columnGap: "10px",
                    justifyContent: "flex-start",
                    alignItems: "baseline",
                  }}
                >
                  <Button
                    style={{ marginRight: 5 }}
                    onClick={onAddIncludeRange}
                    icon="pi pi-plus"
                    disabled={includeZipsRangeArray.length > 9}
                    label={"Add Range"}
                  />
                </div>
                {includeZipsRangeArray &&
                  includeZipsRangeArray.length > 0 &&
                  includeZipsRangeArray.map(
                    (zipRange: IZipsRange, index: number) => {
                      return (
                        <div
                          key={index}
                          style={
                            index == 0
                              ? {
                                  display: "flex",
                                  columnGap: "10px",
                                  justifyContent: "flex-start",
                                  alignItems: "baseline",
                                  margin: "5px 0",
                                  marginTop: "-30px",
                                }
                              : {
                                  display: "flex",
                                  columnGap: "10px",
                                  justifyContent: "flex-start",
                                  alignItems: "baseline",
                                  margin: "5px 0",
                                }
                          }
                        >
                          <p style={{ width: "100px", marginRight: 5 }}></p>
                          <InputText
                            id={IDS.offersForm.inputs.includeZipRange}
                            style={{ width: "15%" }}
                            value={zipRange?.start}
                            placeholder="From"
                            className={"zip-range-input"}
                            onChange={(e) => {
                              const newRange = {
                                ...zipRange,
                                start: e?.target?.value,
                              };
                              const newArray = [...includeZipsRangeArray];
                              newArray[index] = newRange;
                              setIncludeZipsRangeArray(newArray);
                            }}
                          />
                          <i className="pi pi-minus"></i>
                          <InputText
                            id={IDS.offersForm.inputs.includeZipRange}
                            style={{ width: "15%" }}
                            value={zipRange?.end}
                            placeholder="To"
                            className={"zip-range-input"}
                            onChange={(e) => {
                              const newRange = {
                                ...zipRange,
                                end: e?.target?.value,
                              };
                              const newArray = [...includeZipsRangeArray];
                              newArray[index] = newRange;
                              setIncludeZipsRangeArray(newArray);
                            }}
                          />
                          {index + 1 == includeZipsRangeArray?.length && (
                            <i
                              title="Remove range"
                              className="pi pi-times"
                              style={{ cursor: "pointer" }}
                              onClick={onRemoveIncludeRange}
                            ></i>
                          )}
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Exclude ZIPs
                    <Info
                      style={{ float: "right", marginLeft: 2 }}
                      message={"Make sure to hit Enter after each zip."}
                    />
                  </label>
                </div>
              </div>
              <div
                id={IDS.offersForm.inputs.excludeZips}
                className={"p-col-12 p-lg-9"}
              >
                <TagsInput
                  onlyUnique={true}
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(offer.excludeZips)}
                  onChange={(value: string[]) =>
                    setValue("excludeZips", TagsInputUtil.join(value))
                  }
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}></div>
              </div>
              <div
                id={IDS.offersForm.inputs.excludeZips}
                className={"p-col-12 p-lg-9"}
              >
                <div
                  key={-1}
                  style={{
                    display: "flex",
                    columnGap: "10px",
                    justifyContent: "flex-start",
                    alignItems: "baseline",
                  }}
                >
                  <Button
                    style={{ marginRight: 5 }}
                    onClick={onAddExcludeRange}
                    disabled={excludeZipsRangeArray.length > 9}
                    icon="pi pi-plus"
                    label={"Add Range"}
                  />
                </div>
                {excludeZipsRangeArray &&
                  excludeZipsRangeArray.length > 0 &&
                  excludeZipsRangeArray.map(
                    (zipRange: IZipsRange, index: number) => {
                      return (
                        <div
                          key={index}
                          style={
                            index == 0
                              ? {
                                  display: "flex",
                                  columnGap: "10px",
                                  justifyContent: "flex-start",
                                  alignItems: "baseline",
                                  margin: "5px 0",
                                  marginTop: "-30px",
                                }
                              : {
                                  display: "flex",
                                  columnGap: "10px",
                                  justifyContent: "flex-start",
                                  alignItems: "baseline",
                                  margin: "5px 0",
                                }
                          }
                        >
                          <p style={{ width: "100px", marginRight: 5 }}></p>
                          <InputText
                            id={IDS.offersForm.inputs.excludeZipRange}
                            style={{ width: "15%" }}
                            value={zipRange?.start}
                            placeholder="From"
                            className={"zip-range-input"}
                            onChange={(e) => {
                              const newRange = {
                                ...zipRange,
                                start: e?.target?.value,
                              };
                              const newArray = [...excludeZipsRangeArray];
                              newArray[index] = newRange;
                              setExcludeZipsRangeArray(newArray);
                            }}
                          />
                          <i className="pi pi-minus"></i>
                          <InputText
                            id={IDS.offersForm.inputs.excludeZipRange}
                            style={{ width: "15%" }}
                            value={zipRange?.end}
                            placeholder="To"
                            className={"zip-range-input"}
                            onChange={(e) => {
                              const newRange = {
                                ...zipRange,
                                end: e?.target?.value,
                              };
                              const newArray = [...excludeZipsRangeArray];
                              newArray[index] = newRange;
                              setExcludeZipsRangeArray(newArray);
                            }}
                          />
                          {index + 1 == excludeZipsRangeArray?.length && (
                            <i
                              title="Remove range"
                              className="pi pi-times"
                              style={{ cursor: "pointer" }}
                              onClick={onRemoveExcludeRange}
                            ></i>
                          )}
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Exclude ZIPs (mobile)
                    <Info
                      style={{ float: "right", marginLeft: 2 }}
                      message={"Make sure to hit Enter after each zip."}
                    />
                  </label>
                </div>
              </div>
              <div
                id={IDS.offersForm.inputs.excludeZipsMobile}
                className={"p-col-12 p-lg-9"}
              >
                <TagsInput
                  onlyUnique={true}
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(offer.excludeZipsMobile)}
                  onChange={(value: string[]) =>
                    setValue("excludeZipsMobile", TagsInputUtil.join(value))
                  }
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}></div>
              </div>
              <div
                id={IDS.offersForm.inputs.excludeZipsMobile}
                className={"p-col-12 p-lg-9"}
              >
                <div
                  key={-1}
                  style={{
                    display: "flex",
                    columnGap: "10px",
                    justifyContent: "flex-start",
                    alignItems: "baseline",
                  }}
                >
                  <Button
                    style={{ marginRight: 5 }}
                    onClick={onAddExcludeMobileRange}
                    disabled={excludeZipsMobileRangeArray.length > 9}
                    icon="pi pi-plus"
                    label={"Add Range"}
                  />
                </div>
                {excludeZipsMobileRangeArray &&
                  excludeZipsMobileRangeArray.length > 0 &&
                  excludeZipsMobileRangeArray.map(
                    (zipRange: IZipsRange, index: number) => {
                      return (
                        <div
                          key={index}
                          style={
                            index == 0
                              ? {
                                  display: "flex",
                                  columnGap: "10px",
                                  justifyContent: "flex-start",
                                  alignItems: "baseline",
                                  margin: "5px 0",
                                  marginTop: "-30px",
                                }
                              : {
                                  display: "flex",
                                  columnGap: "10px",
                                  justifyContent: "flex-start",
                                  alignItems: "baseline",
                                  margin: "5px 0",
                                }
                          }
                        >
                          <p style={{ width: "100px", marginRight: 5 }}></p>
                          <InputText
                            id={IDS.offersForm.inputs.excludeZipMobileRange}
                            style={{ width: "15%" }}
                            value={zipRange?.start}
                            placeholder="From"
                            className={"zip-range-input"}
                            onChange={(e) => {
                              const newRange = {
                                ...zipRange,
                                start: e?.target?.value,
                              };
                              const newArray = [...excludeZipsMobileRangeArray];
                              newArray[index] = newRange;
                              setExcludeZipsMobileRangeArray(newArray);
                            }}
                          />
                          <i className="pi pi-minus"></i>
                          <InputText
                            id={IDS.offersForm.inputs.excludeZipMobileRange}
                            style={{ width: "15%" }}
                            value={zipRange?.end}
                            placeholder="To"
                            className={"zip-range-input"}
                            onChange={(e) => {
                              const newRange = {
                                ...zipRange,
                                end: e?.target?.value,
                              };
                              const newArray = [...excludeZipsMobileRangeArray];
                              newArray[index] = newRange;
                              setExcludeZipsMobileRangeArray(newArray);
                            }}
                          />
                          {index + 1 == excludeZipsMobileRangeArray?.length && (
                            <i
                              title="Remove range"
                              className="pi pi-times"
                              style={{ cursor: "pointer" }}
                              onClick={onRemoveExcludeMobileRange}
                            ></i>
                          )}
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          </AccordionTab>

          <AccordionTab
            // id={IDS.offersForm.panel.crmSource}
            style={{ width: "100%", marginBottom: 10 }}
            header={"Channel Partner Source"}
          >
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Use offer name as sub-source name
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <InputSwitch
                  id={IDS.offersForm.switch.useSubSource}
                  checked={offer.useSubSource}
                  onChange={(e: any) =>
                    setValue("useSubSource", e.target.value)
                  }
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Channel Partner Source
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <AsyncSelect
                  id={IDS.dropdown.crmSource}
                  cacheOptions
                  defaultOptions
                  value={
                    offer.crmSource
                      ? {
                          label: offer.crmSource.name,
                          value: offer.crmSource.id,
                        }
                      : null
                  }
                  placeholder={"Channel Partner Source"}
                  loadOptions={(inputValue: string): any =>
                    promiseCrmSources(inputValue, crmSourceList)
                  }
                  onChange={(value: any) => {
                    setValue(
                      "crmSource",
                      value
                        ? {
                            name: value.label,
                            id: value.value,
                          }
                        : null
                    );
                  }}
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Channel Partner Destination Email Address
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <InputText
                  id={IDS.offersForm.inputs.crmDestinationEmail}
                  style={{ width: "100%" }}
                  value={Util.getDefaultIfNull(offer.crmDestinationEmail, "")}
                  placeholder={"Channel Partner Destination Email Address"}
                  onChange={(e: any) =>
                    setValue("crmDestinationEmail", e.target.value)
                  }
                />
              </div>
            </div>
          </AccordionTab>

          <AccordionTab
            style={{ width: "100%", marginBottom: 10 }}
            header={"Messages"}
          >
            <div style={{ marginTop: 10 }} className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Goal Attainment Notification
                    <Info
                      style={{ float: "right", marginLeft: 2 }}
                      message={"List of comma separated values."}
                    />
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <InputTextarea
                  id={IDS.offersForm.inputs.goalAttainmentNotification}
                  style={{ width: "100%" }}
                  value={Util.getDefaultIfNull(
                    offer.goalAttachmentNotification,
                    ""
                  )}
                  placeholder={"Goal Attainment Notification"}
                  onChange={(e: any) =>
                    setValue("goalAttachmentNotification", e.target.value)
                  }
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Terms And Conditions</label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <InputTextarea
                  id={IDS.offersForm.inputs.termsAndConditions}
                  style={{ width: "100%" }}
                  value={Util.getDefaultIfNull(offer.termsAndConditions, "")}
                  placeholder={"Terms And Conditions"}
                  onChange={(e: any) =>
                    setValue("termsAndConditions", e.target.value)
                  }
                />
              </div>
            </div>
          </AccordionTab>

          <AccordionTab
            style={{ width: "100%", marginBottom: 10 }}
            header={
              <div style={{ display: "flex", height: 18 }}>
                <label title={t("offers.defaultReceiptMessage")}>
                  Send auto text response
                </label>
                <div style={{ marginTop: 2, marginLeft: 5 }}>
                  <InputSwitch
                    id={IDS.offersForm.switch.sendReceiptMessage}
                    checked={offer.sendReceiptMessage}
                    onChange={(e: any) =>
                      setValue("sendReceiptMessage", e.target.value)
                    }
                  />
                </div>
              </div>
            }
          >
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Message To Customer
                    <Info
                      style={{ float: "right", marginLeft: 2 }}
                      message={t("offers.defaultCustomerMessage")}
                    />
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <InputTextarea
                  id={IDS.offersForm.inputs.customerMessage}
                  style={{ width: "100%" }}
                  value={Util.getDefaultIfNull(offer.customerMessage, "")}
                  placeholder={"Message To Customer"}
                  onChange={(e: any) =>
                    setValue("customerMessage", e.target.value)
                  }
                />
              </div>
            </div>
          </AccordionTab>

          {getUseEngScorePanel()}

          <AccordionTab
            // id={IDS.offersForm.panel.pullUp}
            style={{ width: "100%", marginBottom: 10 }}
            header={"Pull Up"}
          >
            <div
              style={{ marginBottom: 5, textAlign: "center" }}
              className={"p-grid"}
            >
              <div className={"p-col-12 p-lg-6"}>
                <Fieldset
                  id={IDS.offersForm.inputs.pullUpOfferSource}
                  legend={"Source"}
                  toggleable={false}
                  style={{ height: "100%" }}
                >
                  <AceEditor
                    mode="html"
                    onChange={(code) => {
                      setValue("pullUpOfferSource", code);
                      forceUpdate();
                    }}
                    style={{ width: "100%" }}
                    value={offer.pullUpOfferSource}
                    name="PULL_UP_SOURCE"
                    editorProps={{ $blockScrolling: true }}
                  />
                </Fieldset>
              </div>
              <div className={"p-col-12 p-lg-6"}>
                <Fieldset
                  legend={"Preview"}
                  toggleable={false}
                  style={{ height: "100%" }}
                >
                  {parse(
                    `<div>${Util.getDefaultIfNull(
                      offer.pullUpOfferSource,
                      ""
                    )}</div>`
                  )}
                </Fieldset>
              </div>
            </div>
            <div
              style={{ marginBottom: 5, textAlign: "center" }}
              className={"p-grid"}
            >
              <div className={"p-col-12 p-lg-6"}>
                <Fieldset
                  id={IDS.offersForm.inputs.sideBannerSource}
                  legend={"Side Banner"}
                  toggleable={false}
                  style={{ height: "100%" }}
                >
                  <div
                    style={{
                      position: "relative",
                      top: "-3.5rem",
                      right: "-9rem",
                    }}
                  >
                    <InputSwitch
                      id={IDS.offersForm.switch.sideBanner}
                      checked={offer.sideBanner}
                      onChange={(e: any) =>
                        setValue("sideBanner", e.target.value)
                      }
                    />
                  </div>
                  <AceEditor
                    mode="html"
                    onChange={(code) => {
                      setValue("sideBannerSource", code);
                      forceUpdate();
                    }}
                    style={{ width: "100%", marginTop: "-2rem" }}
                    value={offer.sideBannerSource}
                    name="SIDE_BANNER_SOURCE"
                    editorProps={{ $blockScrolling: true }}
                  />
                </Fieldset>
              </div>
              <div className={"p-col-12 p-lg-6"}>
                <Fieldset
                  legend={"Preview"}
                  toggleable={false}
                  style={{ height: "100%" }}
                >
                  {parse(
                    `<div>${Util.getDefaultIfNull(
                      offer.sideBannerSource,
                      ""
                    )}</div>`
                  )}
                </Fieldset>
              </div>
            </div>
          </AccordionTab>
          {offer?.template?.singleCtaEnabled == true && (
            <AccordionTab
              // id={IDS.offersForm.panel.singleCTA}
              style={{ width: "100%", marginBottom: 10 }}
              header={"Single CTA"}
            >
              <div
                style={{ marginBottom: 5, textAlign: "center" }}
                className={"p-grid"}
              >
                <div className={"p-col-12 p-lg-6"}>
                  <Fieldset
                    id={IDS.offersForm.inputs.neutralButtonSource}
                    legend={"Neutral Button"}
                    toggleable={false}
                    style={{ height: "100%" }}
                  >
                    <AceEditor
                      mode="html"
                      onChange={(code) => {
                        setValue("neutralButtonSource", code);
                        forceUpdate();
                      }}
                      style={{ width: "100%" }}
                      value={offer.neutralButtonSource}
                      name="NUTRAL_BTN_SOURCE"
                      editorProps={{ $blockScrolling: true }}
                    />
                  </Fieldset>
                </div>
                <div className={"p-col-12 p-lg-6"}>
                  <Fieldset
                    legend={"Preview"}
                    toggleable={false}
                    style={{ height: "100%" }}
                  >
                    {parse(
                      `<div>${Util.getDefaultIfNull(
                        offer.neutralButtonSource,
                        ""
                      )}</div>`
                    )}
                  </Fieldset>
                </div>
              </div>
              <div
                style={{ marginBottom: 5, textAlign: "center" }}
                className={"p-grid"}
              >
                <div className={"p-col-12 p-lg-6"}>
                  <Fieldset
                    id={IDS.offersForm.inputs.triggeredButtonSource}
                    legend={"Triggered Button"}
                    toggleable={false}
                    style={{ height: "100%" }}
                  >
                    <AceEditor
                      mode="html"
                      onChange={(code) => {
                        setValue("triggeredButtonSource", code);
                        forceUpdate();
                      }}
                      style={{ width: "100%", marginTop: "-2rem" }}
                      value={offer.triggeredButtonSource}
                      name="TRIGGERED_BTN_SOURCE"
                      editorProps={{ $blockScrolling: true }}
                    />
                  </Fieldset>
                </div>
                <div className={"p-col-12 p-lg-6"}>
                  <Fieldset
                    legend={"Preview"}
                    toggleable={false}
                    style={{ height: "100%" }}
                  >
                    {parse(
                      `<div>${Util.getDefaultIfNull(
                        offer.triggeredButtonSource,
                        ""
                      )}</div>`
                    )}
                  </Fieldset>
                </div>
              </div>
            </AccordionTab>
          )}

          <AccordionTab
            // id={IDS.offersForm.panel.other}
            style={{ width: "100%", marginBottom: 10 }}
            header={"Other"}
          >
            <div className={"p-grid"}>
              <div className={"p-col-12 p-xl-2 p-lg-4 p-md-2"}>
                <div className={"switch-container"}>
                  <label className={"input-switch-label"}>Active</label>
                  <InputSwitch
                    id={IDS.offersForm.switch.active}
                    checked={offer.active}
                    onChange={(e: any) => setValue("active", e.target.value)}
                  />
                </div>
              </div>

              <div className={"p-col-12 p-xl-2 p-lg-4 p-md-2"}>
                <div className={"switch-container"}>
                  <label className={"input-switch-label"}>
                    Ever Visited
                    <Info
                      style={{ float: "right", marginLeft: 2 }}
                      message={
                        "If this option is checked, after the first visit to some of the details pages of the particular models, the offer will be shown on every page."
                      }
                    />
                  </label>
                  <InputSwitch
                    id={IDS.offersForm.switch.showOnEveryVisit}
                    checked={offer.showOnEveryVisit}
                    onChange={(e: any) =>
                      setValue("showOnEveryVisit", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
        <Panel
          id={"expirable-panel"}
          style={{ width: "100%", marginBottom: 10 }}
          header={
            <div style={{ display: "flex", height: 30 }}>
              <label style={{ marginTop: 5 }}>Expirable</label>
              <div style={{ marginTop: 7, marginLeft: 5 }}>
                <InputSwitch
                  id={IDS.switch.expirable}
                  checked={offer.expirable}
                  onChange={(e: any) => setValue("expirable", e.target.value)}
                />
              </div>
              <div style={{ display: "flex", marginLeft: 10 }}>
                <label
                  style={{ marginTop: 5 }}
                  className={"input-switch-label"}
                >
                  {offer.expirable ? "Start-End Date" : "Start Date"}
                </label>
                <Calendar
                  id={IDS.calendar.startTime}
                  value={getCalendarDates()}
                  onChange={(e: any) => {
                    if (offer.expirable) {
                      setValue("startDate", e.value[0]);
                      setValue("endDate", e.value[1]);
                    } else {
                      setValue("startDate", e.value);
                    }
                  }}
                  selectionMode={offer.expirable ? "range" : undefined}
                  readOnlyInput
                />
              </div>
            </div>
          }
        />

        <div>
          <Button
            id={IDS.button.save}
            style={{ marginRight: 5 }}
            label={"Save"}
            icon={"pi pi-save"}
            onClick={() => onSave()}
          />
          <Button
            id={IDS.button.back}
            onClick={onBack}
            className={"p-button-warning"}
            icon={"pi pi-arrow-left"}
            label={"Back"}
          />
        </div>
      </div>
    </div>
  );
}

export default OffersForm;
