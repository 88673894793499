import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import {IStore} from "../../../../../index.dts";
import {MakeModelDataViewService} from "../../../make_model_data_view/service/MakeModelDataViewService";
import {MakeModelDataView} from "../../../make_model_data_view/MakeModelDataView";


const MakeModel = () => {
  const service = new MakeModelDataViewService();
  const dealership = useSelector((store: IStore) => store.auth.dealershipContext, shallowEqual);

  const renderer = (): React.ReactElement => {

    return (
      <div className={"leads-container"}>
        <MakeModelDataView
          dealershipId={dealership.id}
          dealershipRefId={dealership.externalRefId}
          dataAction={service.getMakeModelDataAction({dealershipId: dealership.id})}
        />
      </div>
    )
  }

  return renderer();
}

export default MakeModel;