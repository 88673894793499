import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { logoutSuccess, refreshToken } from "../actions/authActions";
import { COOKIES } from "../util/Enums";
import Util from "../util/Util";
import store from "./store";

export default class Axios {
  static cancelToken = axios.CancelToken.source();
  static cancelTokenTimeOut: any = 0;
  static readonly instance: AxiosInstance = Axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/`,
    withCredentials: true,
    cancelToken: Axios.cancelToken.token,
  });

  static create(config: AxiosRequestConfig): AxiosInstance {
    const instance = axios.create(config);

    instance.interceptors.request.use((value) => {
      value.headers["X-Requested-With"] = "XMLHttpRequest";
      if (value.url)
        if (
          value.url !== "public/oauth/token" &&
          !value.url.includes("public/")
        ) {
          const auth: any = JSON.parse(Util.Cookie.get(COOKIES.AUTH) || "{}");
          const access_token = Util._.get(auth, "access_token", null);

          if (access_token) {
            value.headers.Authorization = `Bearer ${access_token}`;
          }
        }
      return value;
    });

    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const response = Util._.get(error, "response", null);
        const status = Util._.get(response, "status", null);
        const url: string = Util.getDefaultIfNull(
          Util._.get(error, "config.url", null),
          ""
        );

        if (status === 401 && !url.includes("public")) {
          const message: string = Util.getDefaultIfNull(
            Util._.get(
              response,
              "data.message",
              Util._.get(response, "data.error_description", null)
            ),
            ""
          );

          if (!url.endsWith("user/logout")) {
            if (Axios.userIsDisabled(message)) {
              store.dispatch(logoutSuccess());
              window.location.reload();
              return Axios.sleep(1000);
            }

            const token = Util.Cookie.get(COOKIES.REFRESH_TOKEN);
            if (!token) {
              store.dispatch(logoutSuccess());
              window.location.reload();
              return Axios.sleep(1000);
            }

            try {
              const response = await refreshToken(token);

              Util.Cookie.set(
                COOKIES.REFRESH_TOKEN,
                response.data.refresh_token
              );
              Util.Cookie.set(COOKIES.AUTH, response.data, {
                expires: new Date(Date.now() + response.data.expires_in),
              });

              return await instance.request(error.config);
            } catch (e) {
              store.dispatch(logoutSuccess());
              window.location.reload();
              return Axios.sleep(1000);
            }
          } else {
            if (Axios.userIsDisabled(message)) {
              store.dispatch(logoutSuccess());
              return Promise.reject({
                response: {
                  data: {
                    message:
                      "Your account is disabled, you do not have access! Please contact our support if you have questions?",
                  },
                },
              });
            }
          }
        }

        return Promise.reject(error);
      }
    );

    return instance;
  }

  static resetCancelToken = () => {
    clearTimeout(Axios.cancelTokenTimeOut);
    Axios.cancelToken = axios.CancelToken.source();
    Axios.instance.defaults.cancelToken = Axios.cancelToken.token;
  };

  static async all<T>(values: (T | Promise<T>)[]): Promise<T[]> {
    return await axios.all(values);
  }

  static isCancel(value: any): boolean {
    return axios.isCancel(value);
  }

  static removeCookies = () => {
    Util.Cookie.remove(COOKIES.AUTH);
    Util.Cookie.remove(COOKIES.REFRESH_TOKEN);
    Util.Cookie.remove(
      COOKIES.USER_IS_NOTIFIED_THAT_HE_IS_NOT_ASSOCIATED_WITH_ANY_DEALERSHIPS
    );
  };

  static sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static userIsDisabled = (message: string): boolean => {
    return message.toLowerCase().includes("user is disabled");
  };
}
