import {
  AxiosResponse,
  IGroupDealership,
  IGroupDealershipSearchRequest,
  IGroupSelectedDealershipSearchRequest,
  IPageable,
  IRtsGroup,
  IRtsGroupDto,
  IRtsGroupSearchRequestDto,
} from "../../index.dts";
import Axios from "../config/Axios";

export const getRtsGroupsBySearchCriteria = (
  searchCriteria: IRtsGroupSearchRequestDto
): Promise<IPageable<IRtsGroup>> =>
  Axios.instance.post("rts/groups/list", searchCriteria);
export const createRtsGroup = (
  dto: IRtsGroupDto
): Promise<AxiosResponse<IRtsGroup>> => Axios.instance.post("rts/groups", dto);
export const getRtsGroup = (id: number): Promise<AxiosResponse<IRtsGroup>> =>
  Axios.instance.get(`rts/groups/${id}`);
export const updateRtsGroup = (id: number, dto: IRtsGroupDto) =>
  Axios.instance.put(`rts/groups/${id}`, dto);
export const deleteRtsGroups = (ids: number[]) =>
  Axios.all(ids.map((id) => Axios.instance.delete(`rts/groups/${id}`)));
export const getGroupDealerships = (
  searchRequest: IGroupDealershipSearchRequest
): Promise<IPageable<IGroupDealership>> =>
  Axios.instance.post("rts/groups/dealerships", searchRequest);
export const getSelectedGroupDealerships = (
  searchRequest: IGroupSelectedDealershipSearchRequest
): Promise<IPageable<IGroupDealership>> =>
  Axios.instance.post("/rts/groups/dealerships/filtered", searchRequest);
