import React, { useEffect, useRef, useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { InputNumber } from "primereact/inputnumber";
import {
  AsyncPaginate,
  reduceGroupedOptions,
} from "react-select-async-paginate";
import { getChatGreetings } from "../../../actions/chatGreetingActions";
import { shallowEqual, useSelector } from "react-redux";
import {
  IChatBotDto,
  IChatBotWorkflow,
  IChatBotWorkflowDto,
  IStore,
} from "../../../../index.dts";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { MODE } from "../../../util/Enums";
import {
  createChatBotWorkflow,
  createDealershipCampaign,
  deleteChatBotWorkflows,
  getChatBots,
  getMyTrafficNotification,
  updateChatBotWorkflow,
  updateDealershipCampaign,
} from "../../../actions/MyTrafficNotificationActions";
import Util from "../../../util/Util";
import AsyncSelect from "react-select/async";
// import {
//   SortableContainer,
//   SortableElement,
//   SortableHandle,
// } from "react-sortable-hoc";
import Select, { components } from "react-select";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CloseButton from "../../close_button/CloseButton";
import { Sidebar } from "primereact/sidebar";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import { confirmAlert } from "react-confirm-alert";
import { Moment } from "moment-timezone";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { getChatbotStats } from "../../../actions/kaninActions";

interface IProps {
  location: {
    state: {
      mode: MODE;
      dealershipId: number;
      notificationId?: number;
      dealershipExtRefId: string;
    };
  };
}

interface IChatbotStats {
  aiChatLeads: number;
  aiEngagements: number;
  attempts: number;
  delegates: number;
  userChatLeads: number;
}

const SortableSelect = AsyncSelect;

export function NotificationForm(props: IProps) {
  const history: any = useHistory();
  const forceUpdate = useForceUpdate();

  const [chatbotStatsVisible, setChatbotStatsVisible] =
    useState<boolean>(false);

  const workflowGridRef = useRef<any>(null);

  const [chatGreetingsLimit] = useState<number>(50);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [notificationExtRefId, setNotificationExtRefId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [segment, setSegment] = useState<string>("");
  const [zipCodes, setZipCodes] = useState<string>("");

  const [delaySend, setDelaySend] = useState<number>(2);
  const [engagementScore, setEngagementScore] = useState<number>(2);

  const [enabled, setEnabled] = useState<boolean>(true);
  const [useChatBot, setUseChatBot] = useState<boolean>(false);
  const [useSharkTank, setUseSharkTank] = useState<boolean>(false);
  const [limitByZipCodes, setLimitByZipCodes] = useState<boolean>(false);

  const [chatbotId, setChatbotId] = useState<number>();
  const [chatGreetingId, setChatGreetingId] = useState<number>();
  const [chatGreetingName, setChatGreetingName] = useState<string>("");

  const [users, setUsers] = useState<any[]>([]);
  const [chatBotWorkflows, setChatBotWorkflows] = useState<IChatBotWorkflow[]>(
    []
  );

  const [sidebarMode, setSidebarMode] = useState<MODE>(MODE.NEW);
  const [workflowId, setWorkflowId] = useState<number | string>(0);
  const [sidebarChatbotId, setSidebarChatbotId] = useState<number>(0);
  const [sidebarChatbotName, setSidebarChatbotName] = useState<string>();
  const [sidebarChatGreetingId, setSidebarChatGreetingId] = useState<number>(0);
  const [sidebarChatGreetingName, setSidebarChatGreetingName] =
    useState<string>("");
  const [sidebarStartTime, setSidebarStartTime] = useState<string>("06:00");
  const [sidebarEndTime, setSidebarEndTime] = useState<string>("18:00");
  const [sidebarDaysOfWeek, setSidebarDaysOfWeek] = useState<
    { label: string; value: string }[]
  >([]);
  const [chatBotSidebarVisible, setChatBotSidebarVisible] =
    useState<boolean>(false);

  const [selectedChatBotWorkflows, setSelectedChatBotWorkflows] = useState<
    any[]
  >([]);

  const [chatBots, setChatBots] = useState<IChatBotDto[]>([]);

  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<string[]>([]);

  const [chatbotStats, setChatbotStats] = useState<IChatbotStats>({
    aiChatLeads: 0,
    attempts: 0,
    aiEngagements: 0,
    delegates: 0,
    userChatLeads: 0,
  });

  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  const SortableMultiValue = (props: any) => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { ...props.innerProps, onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
  };

  const SortableMultiValueLabel = (props: any) => (
    <components.MultiValueLabel {...props} />
  );

  useEffect(() => {
    const dealershipId = Util._.get(props, "location.state.dealershipId", null);
    if (dealershipId != null) {
      getDealershipChatBots(dealershipId);
    }
    // eslint-disable-next-line
  }, [props.location]);

  useEffect(() => {
    const mode = Util._.get(props, "location.state.mode", null);
    const notificationId = Util._.get(
      props,
      "location.state.notificationId",
      null
    );

    if (mode === MODE.EDIT && notificationId) {
      getNotification(notificationId);
    }
    // eslint-disable-next-line
  }, []);

  const getDealershipChatBots = (dealershipId: number) => {
    Util.globalSpinner().show();
    getChatBots(dealershipId)
      .then((response) => {
        setChatBots(response.data);
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => {
        Util.globalSpinner().hide();
      });
  };

  const getNotification = (notificationId: number) => {
    Util.globalSpinner().show();
    getMyTrafficNotification(notificationId)
      .then((response) => {
        setName(response.data.name);
        setMessage(response.data.message);
        setSegment(response.data.segment);
        setDelaySend(response.data.delaySend);
        setEnabled(response.data.enabled);
        setZipCodes(response.data.zipCodes);
        setLimitByZipCodes(response.data.limitByZipCodes);
        setEngagementScore(response.data.engagementScore);
        setUseSharkTank(response.data.useSharkTank);
        setUseChatBot(response.data.useChatBot);
        setChatbotId(response.data.chatbotId);
        setChatGreetingId(response.data.chatGreetingId);
        setChatGreetingName(response.data.chatGreetingName);
        setNotificationExtRefId(response.data.externalRefId);

        if (Array.isArray(response.data.chatBotWorkflows)) {
          setChatBotWorkflows(response.data.chatBotWorkflows);
        }

        if (Array.isArray(response.data.users)) {
          setUsers(
            response.data.users
              .sort((a: any, b: any) =>
                b.order.toString().localeCompare(a.order.toString())
              )
              .map((item: any) => {
                return {
                  label: item.user.email,
                  value: item.user.id,
                };
              })
          );
        }
      })
      .catch((error) => Util.showError(error))
      .finally(() => Util.globalSpinner().hide());
  };

  const promiseChatGreetings = async (
    inputValue: string,
    loadedOptions: any,
    { page }: any
  ): Promise<any> => {
    const response = await getChatGreetings(dealershipContext.id, {
      filter: [],
      sorting: [],
      paging: {
        page: page,
        pageLimit: chatGreetingsLimit,
      },
    });

    return {
      options: response.data.content.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      }),
      hasMore: page < response.data.totalPages,
      additional: {
        page: page + 1,
      },
    };
  };

  const onSave = () => {
    const result = validate();

    setErrors(result);
    if (Object.keys(result).length > 0) {
      return;
    }

    if (props.location.state.mode === MODE.NEW) {
      onCreate();
    } else if (
      props.location.state.mode === MODE.EDIT &&
      props.location.state.notificationId
    ) {
      onUpdate(props.location.state.notificationId);
    }
  };

  const validate = (): { [key: string]: string } => {
    const result: { [key: string]: string } = {};

    if (Util.isEmpty(name)) {
      result["name"] = "Please enter notification name.";
    }

    if (Util.isEmpty(message)) {
      result["message"] = "Please enter notification prompt.";
    }

    if (useChatBot) {
      if (!chatbotId) {
        result["chatbot"] = "Please enter notification chatbot.";
      }

      if (!chatGreetingId) {
        result["greeting"] = "Please enter notification chat greeting.";
      }
    }

    return result;
  };

  const onCreate = () => {
    Util.globalSpinner().show();
    createDealershipCampaign({
      name: name,
      dealershipId: dealershipContext.id,
      segment: segment,
      message: message,
      engagementScore: engagementScore,
      delaySend: delaySend,
      limitByZipCodes: limitByZipCodes,
      enabled: enabled,
      zipCodes: zipCodes,
      useChatBot: useChatBot,
      useSharkTank: useSharkTank,
      chatbotId: chatbotId,
      chatGreetingId: chatGreetingId,
      users: users.map((user, index) => {
        return {
          userId: user.value,
          order: users.length - index,
        };
      }),
      chatBotWorkflows: chatBotWorkflows.map((item) => {
        return {
          chatBotId: item.chatBot.id,
          chatGreetingId: item.chatGreeting.id,
          daysOfWeek: item.daysOfWeek,
          endTime: item.endTime,
          startTime: item.startTime,
        };
      }),
    })
      .then((response) => {
        Util.success("MyTraffic notification created successfully.");
        history.push(Util.PATH_NAMES.MY_TRAFFIC, { activeIndex: 4 });
      })
      .catch((error) => Util.showError(error))
      .finally(() => Util.globalSpinner().hide());
  };

  const onUpdate = (notificationId: number) => {
    Util.globalSpinner().show();
    updateDealershipCampaign(notificationId, {
      name: name,
      dealershipId: dealershipContext.id,
      segment: segment,
      message: message,
      engagementScore: engagementScore,
      delaySend: delaySend,
      limitByZipCodes: limitByZipCodes,
      enabled: enabled,
      zipCodes: zipCodes,
      useChatBot: useChatBot,
      useSharkTank: useSharkTank,
      chatbotId: chatbotId,
      chatGreetingId: chatGreetingId,
      users: users.map((user, index) => {
        return {
          userId: user.value,
          order: users.length - index,
        };
      }),
    })
      .then((response) => {
        Util.success("MyTraffic notification updated successfully.");
        history.push(Util.PATH_NAMES.MY_TRAFFIC, { activeIndex: 4 });
      })
      .catch((error) => Util.showError(error))
      .finally(() => Util.globalSpinner().hide());
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const newValue = arrayMove(users, oldIndex, newIndex);
    setUsers(newValue);
  };

  const onBack = () => {
    history.push(Util.PATH_NAMES.MY_TRAFFIC, { activeIndex: 4 });
  };

  const onChatGreetingChange = (data: any): void => {
    setChatGreetingId(data.value);
    setChatGreetingName(data.label);
  };

  const onSidebarChatGreetingChange = (data: any): void => {
    setSidebarChatGreetingId(data.value);
    setSidebarChatGreetingName(data.label);
  };

  const onSidebarChatBotChange = (e: any): void => {
    const value = e.target.value;
    const index = chatBots.findIndex((item) => item.id === value);

    if (index !== -1) {
      setSidebarChatbotId(chatBots[index].id || 0);
      setSidebarChatbotName(chatBots[index].botNickName);
    }
  };

  const onAddChatBotWorkflow = (): void => {
    setSidebarMode(MODE.NEW);
    setWorkflowId(Util.uniqId());
    setChatBotSidebarVisible(true);
  };

  const onEditChatBotWorkflow = (): void => {
    if (selectedChatBotWorkflows.length === 1) {
      const periodChatbot: IChatBotWorkflow = selectedChatBotWorkflows[0];

      const workflowId = Util._.get(periodChatbot, "id", "");
      const chatbotId = Util._.get(periodChatbot, "chatBot.id", 0);
      const chatGreetingId = Util._.get(periodChatbot, "chatGreeting.id", 0);

      setWorkflowId(workflowId);
      setSidebarEndTime(periodChatbot.endTime);
      setSidebarStartTime(periodChatbot.startTime);
      setSidebarChatGreetingName(
        Util._.get(periodChatbot, "chatGreeting.name", "")
      );
      setSidebarChatGreetingId(chatGreetingId);
      setSidebarChatbotId(chatbotId);
      setSidebarChatbotName(Util._.get(periodChatbot, "chatBot.botUserId", ""));

      setSidebarDaysOfWeek(
        periodChatbot.daysOfWeek.split(",").map((item) => {
          const index = Util.daysOfWeek.findIndex((day) => day.value === item);
          if (index !== -1) {
            return {
              value: Util.daysOfWeek[index].value,
              label: Util.daysOfWeek[index].label,
            };
          }
          return {
            value: "",
            label: "",
          };
        })
      );

      setSidebarMode(MODE.EDIT);
      setChatBotSidebarVisible(true);
    }
  };

  const onDeleteChatBotWorkflow = (): void => {
    if (selectedChatBotWorkflows.length > 0) {
      confirmAlert({
        title: `You are about to delete ${
          selectedChatBotWorkflows.length
        } chatbot workflow${selectedChatBotWorkflows.length > 1 ? "s" : ""}!`,
        message: "Are you sure you want to do this?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              if (props.location.state.mode === MODE.NEW) {
                selectedChatBotWorkflows.forEach((workflow) => {
                  const index = chatBotWorkflows.findIndex(
                    (item) => item.id === workflow.id
                  );
                  if (index > -1) {
                    chatBotWorkflows.splice(index, 1);
                  }
                });

                setSelectedChatBotWorkflows([]);
              } else {
                Util.globalSpinner().show();
                deleteChatBotWorkflows(
                  selectedChatBotWorkflows.map((item) => item.id)
                )
                  .then(() => {
                    selectedChatBotWorkflows.forEach((workflow) => {
                      const index = chatBotWorkflows.findIndex(
                        (item) => item.id === workflow.id
                      );
                      if (index > -1) {
                        chatBotWorkflows.splice(index, 1);
                      }
                    });

                    setSelectedChatBotWorkflows([]);
                    Util.success("Chatbot Workflow(s) deleted successfully.");
                  })
                  .catch((error) => {
                    Util.showError(error);
                  })
                  .finally(() => Util.globalSpinner().hide());
              }
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    } else {
      Util.warning("Please choose at least one chatbot workflow.");
    }
  };

  const onHideChatBotSidebar = (): void => {
    setSidebarEndTime("18:00");
    setSidebarStartTime("06:00");
    setSidebarChatGreetingName("");
    setSidebarChatbotName("");
    setSidebarChatGreetingId(0);
    setSidebarChatbotId(0);
    setSidebarDaysOfWeek([]);
    setWorkflowId(0);
    setSelectedChatBotWorkflows([]);
    setChatBotSidebarVisible(false);
  };

  const isChatWorkflowValid = (args: IChatBotWorkflowDto): boolean => {
    if (!args.chatBotId) {
      Util.warning("Invalid Chatbot");
      return false;
    }
    if (!args.chatGreetingId) {
      Util.warning("Invalid Chat Greeting");
      return false;
    }
    if (Util.isEmpty(args.startTime)) {
      Util.warning("Invalid startTime");
      return false;
    }
    if (Util.isEmpty(args.endTime)) {
      Util.warning("Invalid endTime");
      return false;
    }

    if (Util.isEmpty(args.daysOfWeek)) {
      Util.warning("Invalid daysOfWeek");
      return false;
    }

    if (
      moment(`${Util.defaultYear} ${args.startTime}`).isAfter(
        moment(`${Util.defaultYear} ${args.endTime}`)
      )
    ) {
      Util.warning("Start time must be before end time");
      return false;
    }

    if (chatBotWorkflows !== null && chatBotWorkflows.length > 0) {
      for (let i = 0; i < chatBotWorkflows.length; i++) {
        const chatBotWorkFlow = chatBotWorkflows[i];

        if (chatBotWorkFlow.id !== args.id) {
          if (checkChatBotWorkflowForPeriodOverlapping(chatBotWorkFlow, args)) {
            Util.warning(
              "The period of the workflow you are trying to create/update overlaps an existing workflow."
            );
            return false;
          }
        }
      }
    }

    return true;
  };

  const checkChatBotWorkflowForPeriodOverlapping = (
    workflow1: IChatBotWorkflow,
    workflow2: IChatBotWorkflowDto
  ): boolean => {
    const daysOfWeek: string[] = workflow1.daysOfWeek.split(",");

    const startTime1 = moment(`${Util.defaultYear} ${workflow1.startTime}`);
    const endTime1 = moment(`${Util.defaultYear} ${workflow1.endTime}`);

    const startTime2 = moment(`${Util.defaultYear} ${workflow2.startTime}`);
    const endTime2 = moment(`${Util.defaultYear} ${workflow2.endTime}`);

    for (let i = 0; i < daysOfWeek.length; i++) {
      const day = daysOfWeek[i];
      if (
        workflow2.daysOfWeek.includes(day) &&
        isOverlapping(startTime1, endTime1, startTime2, endTime2)
      ) {
        return true;
      }
    }

    return false;
  };

  const isOverlapping = (
    startTime1: Moment,
    endTime1: Moment,
    startTime2: Moment,
    endTime2: Moment
  ): boolean => {
    return (
      !startTime1.isAfter(endTime2) &&
      !startTime1.isSame(endTime2) &&
      !startTime2.isAfter(endTime1) &&
      !startTime2.isSame(endTime1)
    );
  };

  const onSavePeriodChatBots = (): void => {
    if (
      !isChatWorkflowValid({
        id: workflowId,
        chatBotId: sidebarChatbotId,
        chatGreetingId: sidebarChatGreetingId,
        daysOfWeek: sidebarDaysOfWeek.map((item) => item.value).join(","),
        startTime: sidebarStartTime,
        endTime: sidebarEndTime,
      })
    ) {
      return;
    }

    if (props.location.state.mode === MODE.NEW) {
      if (sidebarMode === MODE.NEW) {
        chatBotWorkflows.push({
          id: workflowId,
          chatBot: {
            id: sidebarChatbotId,
            botNickName: sidebarChatbotName,
          },
          chatGreeting: {
            id: sidebarChatGreetingId,
            name: sidebarChatGreetingName,
          },
          startTime: sidebarStartTime,
          endTime: sidebarEndTime,
          daysOfWeek: sidebarDaysOfWeek.map((item) => item.value).join(","),
        });
      } else {
        const id = selectedChatBotWorkflows[0].id;

        const index = chatBotWorkflows.findIndex((item) => item.id === id);

        if (index !== -1) {
          chatBotWorkflows[index] = {
            id: workflowId,
            chatBot: {
              id: sidebarChatbotId,
              botNickName: sidebarChatbotName,
            },
            chatGreeting: {
              id: sidebarChatGreetingId,
              name: sidebarChatGreetingName,
            },
            startTime: sidebarStartTime,
            endTime: sidebarEndTime,
            daysOfWeek: sidebarDaysOfWeek.map((item) => item.value).join(","),
          };
        }
      }

      forceUpdate();
      onHideChatBotSidebar();
    } else {
      if (sidebarMode === MODE.NEW) {
        const notificationId = Util._.get(
          props,
          "location.state.notificationId"
        );

        Util.globalSpinner().show();
        createChatBotWorkflow(notificationId, {
          chatBotId: sidebarChatbotId,
          chatGreetingId: sidebarChatGreetingId,
          startTime: sidebarStartTime,
          endTime: sidebarEndTime,
          daysOfWeek: sidebarDaysOfWeek.map((item) => item.value).join(","),
        })
          .then((response) => {
            Util.success("Workflow created successfully");

            if (Array.isArray(chatBotWorkflows)) {
              chatBotWorkflows.push(response.data);
            } else {
              setChatBotWorkflows([response.data]);
            }

            onHideChatBotSidebar();
          })
          .catch((error) => {
            Util.showError(error);
          })
          .finally(() => Util.globalSpinner().hide());
      } else {
        Util.globalSpinner().show();
        updateChatBotWorkflow(selectedChatBotWorkflows[0].id, {
          chatBotId: sidebarChatbotId,
          chatGreetingId: sidebarChatGreetingId,
          startTime: sidebarStartTime,
          endTime: sidebarEndTime,
          daysOfWeek: sidebarDaysOfWeek.map((item) => item.value).join(","),
        })
          .then((response) => {
            Util.success("Workflow updated successfully");

            const index = chatBotWorkflows.findIndex(
              (item) => item.id === response.data.id
            );

            if (index !== -1) {
              chatBotWorkflows[index] = response.data;
            }

            onHideChatBotSidebar();
          })
          .catch((error) => {
            Util.showError(error);
          })
          .finally(() => Util.globalSpinner().hide());
      }
    }
  };

  const daysOfWeekTemplate = (data: any) => {
    const days = data.daysOfWeek.split(",").map((value: string) => {
      const index = Util.daysOfWeek.findIndex((item) => item.value === value);
      if (index !== -1) {
        return Util.daysOfWeek[index].label;
      }
      return "";
    });

    return (
      <div>
        {days.map((day: string, index: number) => (
          <div
            key={index.toString()}
            style={{
              float: "left",
              margin: 2,
              padding: 5,
              backgroundColor: "#81c5f8",
              borderRadius: 4,
            }}
          >
            {day}
          </div>
        ))}
      </div>
    );
  };

  const startTimeBody = (data: any) => {
    return (
      <div>{moment(`2020-01-01 ${data.startTime}`).format("hh:mm A")}</div>
    );
  };

  const endTimeBody = (data: any) => {
    return <div>{moment(`2020-01-01 ${data.endTime}`).format("hh:mm A")}</div>;
  };

  const onDaysOfWeekFilterChange = (values: any) => {
    const value = (values || []).join(",");

    workflowGridRef.current.filter(value, "daysOfWeek", "custom");

    setSelectedDaysOfWeek(values);
  };

  const daysOfWeekFilter = (
    <MultiSelect
      style={{ width: "100%" }}
      value={selectedDaysOfWeek}
      options={Util.daysOfWeek}
      onChange={(e) => onDaysOfWeekFilterChange(e.value)}
    />
  );

  const daysOfWeekFilterFunction = (value: any, filter: any) => {
    const valuesArray = value.split(",");
    const filterSet = new Set(filter.split(","));

    return valuesArray.some((item: string) => filterSet.has(item));
  };

  const timeFilterFunction = (value: any, filter: any) => {
    const formattedValue = moment(`2020-01-01 ${value}`).format("hh:mm A");

    return formattedValue
      ?.toLowerCase()
      ?.includes(filter == null ? "" : filter?.toLowerCase());
  };

  const showChatbotStats = (): void => {
    if (props.location.state.mode === MODE.EDIT) {
      const dealershipExtRefId = Util._.get(
        props,
        "location.state.dealershipExtRefId",
        null
      );

      if (notificationExtRefId && dealershipExtRefId) {
        Util.globalSpinner().show();
        getChatbotStats(dealershipExtRefId, notificationExtRefId)
          .then((response) => {
            setChatbotStats(Util._.get(response, "data.total"));
            setChatbotStatsVisible(true);
          })
          .catch((error) => Util.showError(error))
          .finally(() => Util.globalSpinner().hide());
      } else {
        Util.warning(Util.defaultErrorMessage);
      }
    }
  };

  const reactNode = (): React.ReactNode => {
    if (dealershipContext.id === 0) {
      return null;
    }

    return (
      <div id={"notification-form-view"}>
        <div className={"p-grid"}>
          <div className="p-col-12">
            <div className="card card-w-title datatable-demo">
              <Panel header={"Main Details"}>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Enabled</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <InputSwitch
                      checked={enabled}
                      onChange={(e: any) => setEnabled(e.target.value)}
                    />
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        Notification Event Name
                      </label>
                      <span className={"red"}>*</span>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <InputText
                      style={{ width: "100%" }}
                      value={Util.getDefaultIfNull(name, "")}
                      placeholder={"Notification Event Name"}
                      onChange={(e: any) => setName(e.target.value)}
                    />
                    <label className={"warning-label"}>{errors.name}</label>
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        Custom Notification Prompt
                      </label>
                      <span className={"red"}>* </span>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <InputText
                      style={{ width: "100%" }}
                      value={Util.getDefaultIfNull(message, "")}
                      placeholder={"Custom Notification Prompt"}
                      onChange={(e: any) => setMessage(e.target.value)}
                    />
                    <label className={"warning-label"}>{errors.message}</label>
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        <span>Segment(s) </span>
                        <i
                          className={"pi pi-info-circle info icon"}
                          title={
                            "Add a segment (word/section of a URL on your website) for a vehicle, vehicles, or webpage that you would like to track for this campaign. Example: mustang, Taurus (CASE SENSITIVE)"
                          }
                        />
                      </label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <InputText
                      style={{ width: "100%" }}
                      value={Util.getDefaultIfNull(segment, "")}
                      placeholder={"e.g. mustang, Taurus, etc."}
                      onChange={(e: any) => setSegment(e.target.value)}
                    />
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Engagement Score</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <InputNumber
                      min={1}
                      max={100}
                      showButtons={true}
                      className={"sidebar-item"}
                      placeholder={"Engagement Score"}
                      value={engagementScore}
                      onChange={(e: any) => setEngagementScore(e.value)}
                    />
                  </div>
                </div>
              </Panel>
              {Util.isChatEnabled(dealershipContext.chatMode) ? (
                <Panel
                  style={{ marginTop: 10 }}
                  header={
                    <div style={{ display: "flex", height: 30 }}>
                      <label>Use Chatbot</label>
                      <div style={{ marginTop: 2, marginLeft: 5 }}>
                        <InputSwitch
                          checked={useChatBot}
                          onChange={(e: any) => setUseChatBot(e.target.value)}
                        />
                      </div>
                      <Button
                        className="no-icon-buttons"
                        disabled={
                          Util._.get(props, "location.state.mode", MODE.NEW) ===
                          MODE.NEW
                        }
                        style={{ marginLeft: "auto" }}
                        label={"Chatbot Stats"}
                        onClick={showChatbotStats}
                      />
                    </div>
                  }
                >
                  <div className={"p-grid"}>
                    <div className={"p-col-12 p-lg-6 p-label-col"}>
                      <div className={"p-grid"}>
                        <div className={"p-col-12 p-lg-2 p-label-col"}>
                          <div className={"label-container"}>
                            <label className={"input-label"}>
                              Default ChatBot
                            </label>
                          </div>
                        </div>
                        <div className={"p-col-12 p-lg-10"}>
                          <Dropdown
                            disabled={!useChatBot}
                            style={{ width: "100%" }}
                            options={chatBots}
                            optionLabel={"botNickName"}
                            optionValue={"id"}
                            value={chatbotId}
                            onChange={(e) => setChatbotId(e.target.value)}
                          />
                          <label className={"warning-label"}>
                            {errors.chatbot}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className={"p-col-12 p-lg-6 p-label-col"}>
                      <div className={"p-grid"}>
                        <div className={"p-col-12 p-lg-2 p-label-col"}>
                          <div className={"label-container"}>
                            <label className={"input-label"}>
                              Default Chat Greeting
                            </label>
                          </div>
                        </div>
                        <div className={"p-col-12 p-lg-10"}>
                          <AsyncPaginate
                            key={dealershipContext.id.toString()}
                            menuPosition={"fixed"}
                            //cacheOptions
                            defaultOptions
                            isDisabled={!useChatBot}
                            value={{
                              value: chatGreetingId,
                              label: chatGreetingName,
                            }}
                            loadOptions={promiseChatGreetings}
                            placeholder={"Select Chat Greeting"}
                            reduceOptions={reduceGroupedOptions}
                            onChange={onChatGreetingChange}
                            additional={{ page: 0 }}
                          />
                          <label className={"warning-label"}>
                            {errors.greeting}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"p-grid"}>
                    <div className={"p-col-12 p-lg-12 p-label-col"}>
                      <div
                        id={"period-workflows"}
                        className={
                          "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"
                        }
                      >
                        <DataTable
                          ref={workflowGridRef}
                          filterDisplay="row"
                          resizableColumns={true}
                          columnResizeMode={"expand"}
                          value={chatBotWorkflows}
                          editMode={"row"}
                          sortMode={"multiple"}
                          className="p-datatable-responsive-demo"
                          selection={selectedChatBotWorkflows}
                          onSelectionChange={(e) =>
                            setSelectedChatBotWorkflows(e.value)
                          }
                          header={
                            <div
                              style={{
                                display: "flex",
                                textAlign: "left",
                                height: 30,
                              }}
                            >
                              <label>Workflows</label>
                              {useChatBot ? (
                                <div className={"p-grid-header-components"}>
                                  <Button
                                    label={"New"}
                                    icon={"pi pi-plus"}
                                    style={{ marginRight: 5 }}
                                    onClick={onAddChatBotWorkflow}
                                    disabled={!useChatBot}
                                  />
                                  <Button
                                    label={"Edit"}
                                    icon={"pi pi-pencil"}
                                    onClick={onEditChatBotWorkflow}
                                    disabled={
                                      !useChatBot ||
                                      selectedChatBotWorkflows.length !== 1
                                    }
                                  />
                                  <Button
                                    label={"Delete"}
                                    icon={"pi pi-trash"}
                                    className={"p-button-danger"}
                                    onClick={onDeleteChatBotWorkflow}
                                    disabled={
                                      !useChatBot ||
                                      selectedChatBotWorkflows.length === 0
                                    }
                                  />
                                </div>
                              ) : null}
                            </div>
                          }
                        >
                          {useChatBot ? (
                            <Column
                              selectionMode="multiple"
                              style={{ width: "3em" }}
                            />
                          ) : null}
                          <Column
                            filter={true}
                            sortable={true}
                            field={"chatBot.botNickName"}
                            sortField={"ipAddress"}
                            header={"Chat Bot"}
                            filterMatchMode={"contains"}
                          />
                          <Column
                            filter={true}
                            sortable={true}
                            field={"chatGreeting.name"}
                            sortField={"chatGreeting.name"}
                            header={"Chat Greeting"}
                            filterMatchMode={"contains"}
                          />
                          <Column
                            filter={true}
                            sortable={true}
                            field={"daysOfWeek"}
                            sortField={"daysOfWeek"}
                            header="Days Of Week"
                            body={daysOfWeekTemplate}
                            style={{ overflow: "visible" }}
                            filterFunction={daysOfWeekFilterFunction}
                            filterElement={daysOfWeekFilter}
                          />
                          <Column
                            field={"startTime"}
                            header="Start Time"
                            sortField="startTime"
                            filter={true}
                            sortable={true}
                            style={{ overflow: "visible" }}
                            body={startTimeBody}
                            filterMatchMode={"custom"}
                            filterFunction={timeFilterFunction}
                          />
                          <Column
                            field={"endTime"}
                            header="End Time"
                            filter={true}
                            sortable={true}
                            style={{ overflow: "visible" }}
                            body={endTimeBody}
                            filterMatchMode={"custom"}
                            filterFunction={timeFilterFunction}
                          />
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </Panel>
              ) : null}
              <Panel
                style={{ marginTop: 10 }}
                header={
                  <div style={{ display: "flex", height: 18 }}>
                    <label>Limited By Zip Codes</label>
                    <div style={{ marginTop: 2, marginLeft: 5 }}>
                      <InputSwitch
                        checked={limitByZipCodes}
                        onChange={(e: any) =>
                          setLimitByZipCodes(e.target.value)
                        }
                      />
                    </div>
                  </div>
                }
              >
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Zip Codes</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <InputText
                      style={{ width: "100%" }}
                      value={Util.getDefaultIfNull(zipCodes, "")}
                      disabled={!limitByZipCodes}
                      onChange={(e: any) => setZipCodes(e.target.value)}
                    />
                  </div>
                </div>
              </Panel>
              <Panel
                style={{ marginTop: 10 }}
                header={
                  <div style={{ display: "flex", height: 18 }}>
                    <label>Use Shark Tank</label>
                    <div style={{ marginTop: 2, marginLeft: 5 }}>
                      <InputSwitch
                        checked={useSharkTank}
                        onChange={(e: any) => setUseSharkTank(e.target.value)}
                      />
                    </div>
                  </div>
                }
              >
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label>
                        <span>Round Robin Delay Send </span>
                        <i
                          className={"pi pi-info-circle info icon"}
                          title={
                            "Messages are sending in a round-robin sequence. You can set the delay in sending a message to the next user!"
                          }
                        />
                      </label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <InputNumber
                      min={0}
                      disabled={useSharkTank}
                      showButtons={true}
                      className={"sidebar-item"}
                      placeholder={"Delay Send"}
                      value={delaySend}
                      onChange={(e: any) => {
                        if (e.value >= 1) {
                          setDelaySend(e.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </Panel>
              <Panel style={{ marginTop: 10 }} header="Users">
                <SortableSelect
                  id={"test"}
                  //axis={"xy"}
                  //distance={4}
                  value={users}
                  //filter={true}
                  isMulti={true}
                  cacheOptions={true}
                  //useDragHandle={true}
                  defaultOptions={true}
                  menuPlacement={"top"}
                  className={"multi-select"}
                  closeMenuOnSelect={false}
                  // getHelperDimensions={({ node }) =>
                  //   node.getBoundingClientRect()
                  // }
                  onChange={(values: any) =>
                    setUsers(Util.getDefaultIfNull(values, []))
                  }
                  // onSortEnd={onSortEnd}
                  components={{
                    MultiValue: SortableMultiValue,
                    MultiValueLabel: SortableMultiValueLabel,
                  }}
                  key={dealershipContext.id.toString()}
                  loadOptions={(inputValue: string): any =>
                    Util.promiseUsers(inputValue, dealershipContext.id)
                  }
                  styles={{
                    option: (styles, { data }) => {
                      const pushSubscribed = Util._.get(
                        data,
                        "pushSubscribed",
                        false
                      );
                      return {
                        ...styles,
                        color: pushSubscribed ? "green" : "#000",
                      };
                    },
                    multiValue: (styles, { data }) => {
                      const pushSubscribed = Util._.get(
                        data,
                        "pushSubscribed",
                        false
                      );
                      return {
                        ...styles,
                        color: pushSubscribed ? "green" : "#000",
                      };
                    },
                    multiValueLabel: (styles, { data }) => {
                      const pushSubscribed = Util._.get(
                        data,
                        "pushSubscribed",
                        false
                      );
                      return {
                        ...styles,
                        color: pushSubscribed ? "green" : "#000",
                      };
                    },
                    multiValueRemove: (styles, { data }) => {
                      const pushSubscribed = Util._.get(
                        data,
                        "pushSubscribed",
                        false
                      );
                      return {
                        ...styles,
                        color: pushSubscribed ? "green" : "#000",
                      };
                    },
                  }}
                />
              </Panel>
              <div style={{ marginTop: 10 }}>
                <Button
                  className="no-icon-buttons"
                  onClick={onSave}
                  label={"Save"}
                />
                <Button
                  onClick={onBack}
                  className={"p-button p-button-warning no-icon-buttons"}
                  label={"Back"}
                />
              </div>
            </div>
          </div>
        </div>
        <Sidebar
          style={{ width: 350 }}
          position={"right"}
          visible={chatBotSidebarVisible}
          onHide={onHideChatBotSidebar}
          showCloseIcon={false}
        >
          <div
            id={"period-chatbots-sidebar"}
            style={{ width: "100%", height: "100%", padding: 15 }}
          >
            <CloseButton onHide={onHideChatBotSidebar} />
            <div
              style={{
                marginTop: 25,
                marginLeft: -15,
                marginRight: -15,
                borderBottom: "1px solid #afa6a6",
              }}
            >
              <label
                style={{
                  fontSize: 20,
                  marginLeft: 20,
                }}
              >
                {sidebarMode === MODE.EDIT ? "Edit Workflow" : "Add Workflow"}
              </label>
            </div>
            <div style={{ padding: 5, height: "100%", width: "100%" }}>
              <div style={{ width: "100%", marginBottom: 5 }}>
                <label>Chat Bot</label>
                <Dropdown
                  disabled={!useChatBot}
                  style={{ width: "100%" }}
                  options={chatBots}
                  optionLabel={"botNickName"}
                  optionValue={"id"}
                  value={sidebarChatbotId}
                  onChange={onSidebarChatBotChange}
                />
              </div>
              <div style={{ width: "100%", marginBottom: 5 }}>
                <label>Chat Greeting</label>
                <AsyncPaginate
                  key={dealershipContext.id.toString()}
                  //cacheOptions
                  defaultOptions
                  isDisabled={!useChatBot}
                  value={{
                    value: sidebarChatGreetingId,
                    label: sidebarChatGreetingName,
                  }}
                  loadOptions={promiseChatGreetings}
                  placeholder={"Select Chat Greeting"}
                  reduceOptions={reduceGroupedOptions}
                  onChange={onSidebarChatGreetingChange}
                  additional={{ page: 0 }}
                />
              </div>
              <div style={{ width: "100%", marginBottom: 5 }}>
                <label>Days Of Week</label>
                <Select
                  isMulti={true}
                  //style={{ width: "100%" }}
                  value={sidebarDaysOfWeek}
                  options={Util.daysOfWeek}
                  className={"multi-select"}
                  onChange={(values: any) => setSidebarDaysOfWeek(values || [])}
                  placeholder={"Select days of week"}
                  closeMenuOnSelect={false}
                />
              </div>
              <div style={{ width: "100%", marginBottom: 5, display: "flex" }}>
                <div style={{ width: "100%", marginRight: 2 }}>
                  <label>Start Time</label>
                  <Calendar
                    readOnlyInput={true}
                    style={{ width: "100%" }}
                    value={moment(
                      `${Util.defaultYear} ${sidebarStartTime}`
                    ).toDate()}
                    onChange={(e: any) =>
                      setSidebarStartTime(moment(e.value).format("HH:mm"))
                    }
                    timeOnly={true}
                    hourFormat={"12"}
                  />
                </div>
                <div style={{ width: "100%", marginLeft: 2 }}>
                  <label>End Time</label>
                  <Calendar
                    readOnlyInput={true}
                    style={{ width: "100%" }}
                    value={moment(
                      `${Util.defaultYear} ${sidebarEndTime}`
                    ).toDate()}
                    onChange={(e: any) =>
                      setSidebarEndTime(moment(e.value).format("HH:mm"))
                    }
                    timeOnly={true}
                    hourFormat={"12"}
                  />
                </div>
              </div>
              <Button
                label={
                  props.location.state.mode === MODE.NEW
                    ? sidebarMode === MODE.NEW
                      ? "Add"
                      : "Save"
                    : "Save"
                }
                style={{ float: "right" }}
                icon={
                  props.location.state.mode === MODE.NEW
                    ? "pi pi-plus"
                    : "pi pi-save"
                }
                onClick={onSavePeriodChatBots}
              />
            </div>
          </div>
        </Sidebar>
        <Dialog
          header={"Chatbot Overall Stats"}
          visible={chatbotStatsVisible}
          onHide={() => setChatbotStatsVisible(false)}
        >
          <div>
            <label>AI/Chat Attempts:</label>
            <span className={"chatbot-stats-value"}>
              {chatbotStats.attempts}
            </span>
          </div>
          <div>
            <label>Shopper/Visitor Engagements:</label>
            <span className={"chatbot-stats-value"}>
              {chatbotStats.aiEngagements}
            </span>
          </div>
          <div>
            <label>Chats accepted by Sales Rep(s):</label>
            <span className={"chatbot-stats-value"}>
              {chatbotStats.delegates}
            </span>
          </div>
          <div>
            <label>Leads captured by Sales Reps:</label>
            <span className={"chatbot-stats-value"}>
              {chatbotStats.userChatLeads}
            </span>
          </div>
          <div>
            <label>Leads captured by AI/Bot:</label>
            <span className={"chatbot-stats-value"}>
              {chatbotStats.aiChatLeads}
            </span>
          </div>
        </Dialog>
      </div>
    );
  };

  return reactNode();
}

export default NotificationForm;

const arrayMove = (array: any, from: number, to: number) => {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
};
