import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Chart as Chart2 } from "primereact/chart";
import useWindowSize from "../../hooks/useWindowSize";
import {
  DataTable,
  DataTableSelectionChangeParams,
} from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import moment from "moment";
import Util from "../../util/Util";
import AsyncSelect from "react-select/async";
import { groupBadgeStyles, groupStyles } from "../../util/styles";
import {
  createReport,
  getDailyReport,
  getGeneratedLeads,
} from "../../actions/dashboardActions";
import { IGeneratedLeadDto, IStore } from "../../../index.dts";
import { Sidebar } from "primereact/sidebar";
import CloseButton from "../../components/close_button/CloseButton";
import { Calendar } from "primereact/calendar";
import Select from "react-select";
import { Panel } from "primereact/panel";
import Spinner from "../../components/spinner/Spinner";
import { Dialog } from "primereact/dialog";
import { shallowEqual, useSelector } from "react-redux";
import { PERIODS } from "../../util/common";
import { LeadFileDownloader } from "../../services/file_export/LeadFileDownloader";
import { IDS } from "../constants";

// const Chart = require("react-chartjs-2");
import { Chart } from "chart.js";
import { useTranslation } from "react-i18next";
const originalLegendOnClick = Chart.defaults.plugins.legend?.onClick; //defaults.global.legend.onClick;

const getLegendId = (chartId: string, label: string): string => {
  return `${chartId}_${label}`;
};

enum DEVICES {
  ALL = "All",
  MOBILE = "Mobile",
  DESKTOP = "Desktop",
}

enum TYPES {
  ALL = "All",
  SALES = "Sales",
  SERVICE = "Service",
}

const options = {
  responsive: true,
  animation: {
    duration: 0,
  },
  tooltips: {
    mode: "index",
    intersect: true,
  },
  scales: {
    "y-axis-1": {
      title: {
        display: true,
        text: "Page Views",
      },
      type: "linear",
      display: true,
      position: "left",
      id: "y-axis-1",
    },
    "y-axis-2": {
      title: {
        display: true,
        text: "Average Time Spent",
      },
      type: "linear",
      display: true,
      position: "left",
      id: "y-axis-2",
    },
    "y-axis-3": {
      title: {
        display: true,
        text: "Unique Visits",
      },
      type: "linear",
      display: true,
      position: "right",
      id: "y-axis-3",
    },
    "y-axis-4": {
      title: {
        display: true,
        text: "Returning Visitors",
      },
      type: "linear",
      display: true,
      position: "right",
      id: "y-axis-4",
    },
    "y-axis-5": {
      title: {
        display: true,
        text: "New Visitors",
      },
      type: "linear",
      display: true,
      position: "right",
      id: "y-axis-5",
    },
  },
};

const secondChartOptions = {
  responsive: true,
  animation: {
    duration: 0,
  },
  stacked: false,
  tooltips: {
    mode: "index",
    intersect: true,
  },
  scales: {
    "y-axis-1": {
      type: "linear",
      display: true,
      position: "right",
      id: "y-axis-1",
      title: {
        display: true,
        text: "% Shown",
      },
    },
    "y-axis-2": {
      type: "linear",
      display: true,
      position: "right",
      id: "y-axis-2",
      title: {
        display: true,
        text: "% Conversion",
      },
    },
  },
};

const thirdChartOptions = {
  responsive: true,
  animation: {
    duration: 0,
  },
  stacked: false,
  tooltips: {
    mode: "index",
    intersect: true,
  },
  scales: {
    "y-axis-1": {
      type: "linear",
      display: true,
      position: "left",
      id: "y-axis-1",
      title: {
        display: true,
        text: "Shown",
      },
    },
    "y-axis-2": {
      type: "linear",
      display: true,
      position: "right",
      id: "y-axis-2",
      title: {
        display: true,
        text: "Pulls",
      },
    },
    "y-axis-3": {
      type: "linear",
      display: true,
      position: "right",
      id: "y-axis-3",
      title: {
        display: true,
        text: "Leads",
      },
    },
  },
};

const forthChartOptions = {
  responsive: true,
  animation: {
    duration: 0,
  },
  stacked: false,
  tooltips: {
    mode: "index",
    intersect: true,
  },
  scales: {
    "y-axis-1": {
      type: "linear",
      display: true,
      position: "right",
      id: "y-axis-1",
      title: {
        display: true,
        text: "Leads",
      },
    },
    "y-axis-2": {
      type: "linear",
      display: true,
      position: "left",
      id: "y-axis-2",
      title: {
        display: true,
        text: "Unique Visitors",
      },
    },
  },
};

const LABELS = {
  NEW_VISITORS: "New Visitors",
  NEW_PAGE_VIEWS: "New Page Views",
  USED_PAGE_VIEWS: "Used Page Views",
  UNIQUE_VISITORS: "Unique Visitors",
  TOTAL_PAGE_VIEWS: "Total Page Views",
  AVERAGE_TIME_SPENT: "Average Time Spent",
  RETURNING_VISITORS: "Returning Visitors",
  CERTIFIED_PAGE_VIEWS: "Certified Page Views",

  PERCENT_SHOWN: "% Shown",
  PERCENT_CONVERSION: "% Conversion",

  SHOWN: "Shown",
  PULLS: "Pulls",
  LEADS: "Leads",
};

export default function Dashboard() {
  const size = useWindowSize();
  const { t } = useTranslation();
  const [chartWidth, setChartWidth] = useState<number>(
    size.width > 1220 ? size.width : 1220
  );

  const [pending, setPending] = useState<boolean>(false);
  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const [selectedAll, setSelectedAll] = useState<boolean>(true);

  const [type, setType] = useState<TYPES>(TYPES.ALL);
  const [device, setDevice] = useState<DEVICES>(DEVICES.ALL);
  const [period, setPeriod] = useState<any>("LAST_30_DAYS");
  const [endDate, setEndDate] = useState<string>(PERIODS.LAST_30_DAYS.to);
  const [startDate, setStartDate] = useState<string>(PERIODS.LAST_30_DAYS.from);
  const [dealership, setDealership] = useState<{
    label: string;
    value: number;
    externalRefId: string;
  }>({
    label: "All",
    value: 0,
    externalRefId: "all",
  });
  const [offer, setOffer] = useState<{
    label: string;
    value: number;
    externalRefId: string;
  }>({
    label: "All",
    value: 0,
    externalRefId: "all",
  });

  const [firstChartData, setFirstChartData] = useState<any>();
  const [secondChartData, setSecondChartData] = useState<any>();
  const [thirdChartData, setThirdChartData] = useState<any>();
  const [forthChartData, setForthChartData] = useState<any>();

  const [avgUniqueVisitors, setAvgUniqueVisitors] = useState<number>(0);
  const [websiteLeads, setWebsiteLeads] = useState<number>(0);
  const [leadLiftProjection, setLeadLiftProjection] = useState<number>(0);
  const [leadLiftPercentage, setLeadLiftPercentage] = useState<number>(0);

  const [hiddenDataSet] = useState(new Set<string>());
  const [hiddenDataSet1] = useState(new Set<string>());

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [leadsPending, setLeadsPending] = useState<boolean>(false);
  const [leadsData, setLeadsData] = useState<IGeneratedLeadDto[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);

  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [selectedLead, setSelectedLead] = useState();
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();

  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  const vehicleOrderFields = [
    "year",
    "make",
    "model",
    "series",
    "stock",
    "plate_number",
    "vin",
    "vehicle_type",
  ];

  useEffect(() => {
    if (Chart.defaults.plugins.legend) {
      Chart.defaults.plugins.legend.onClick = function (
        e: any,
        legendItem: any,
        legend: any
      ) {
        const chartId = Util._.get(e, "native.target.parentNode.id", "");
        const legendId = getLegendId(chartId, legendItem.text);
        if (chartId == IDS.dashboard.chart.mainChart) {
          if (hiddenDataSet1.has(legendId)) {
            hiddenDataSet1.delete(legendId);
          } else {
            hiddenDataSet1.add(legendId);
          }
        } else {
          if (hiddenDataSet.has(legendId)) {
            hiddenDataSet.delete(legendId);
          } else {
            hiddenDataSet.add(legendId);
          }
        }
        originalLegendOnClick.call(this, e, legendItem, legend);
      };
    }
    return () => {
      Chart.defaults.plugins.legend.onClick = originalLegendOnClick;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const legendId1 = getLegendId(
      IDS.dashboard.chart.mainChart,
      LABELS.TOTAL_PAGE_VIEWS
    );
    const legendId2 = getLegendId(
      IDS.dashboard.chart.mainChart,
      LABELS.NEW_PAGE_VIEWS
    );
    const legendId3 = getLegendId(
      IDS.dashboard.chart.mainChart,
      LABELS.USED_PAGE_VIEWS
    );
    const legendId4 = getLegendId(
      IDS.dashboard.chart.mainChart,
      LABELS.CERTIFIED_PAGE_VIEWS
    );
    const legendId5 = getLegendId(
      IDS.dashboard.chart.mainChart,
      LABELS.AVERAGE_TIME_SPENT
    );
    const legendId6 = getLegendId(
      IDS.dashboard.chart.mainChart,
      LABELS.RETURNING_VISITORS
    );
    const legendId7 = getLegendId(
      IDS.dashboard.chart.mainChart,
      LABELS.NEW_VISITORS
    );
    const legendId8 = getLegendId(
      IDS.dashboard.chart.mainChart,
      LABELS.UNIQUE_VISITORS
    );

    if (selectedAll === false) {
      hiddenDataSet1.clear();
      hiddenDataSet1.add(legendId1);
      hiddenDataSet1.add(legendId2);
      hiddenDataSet1.add(legendId3);
      hiddenDataSet1.add(legendId4);
      hiddenDataSet1.add(legendId5);
      hiddenDataSet1.add(legendId6);
      hiddenDataSet1.add(legendId7);
      hiddenDataSet1.add(legendId8);
    } else if (selectedAll === true) {
      if (hiddenDataSet1.has(legendId1)) {
        hiddenDataSet1.delete(legendId1);
      }
      if (hiddenDataSet1.has(legendId2)) {
        hiddenDataSet1.delete(legendId2);
      }
      if (hiddenDataSet1.has(legendId3)) {
        hiddenDataSet1.delete(legendId3);
      }
      if (hiddenDataSet1.has(legendId4)) {
        hiddenDataSet1.delete(legendId4);
      }
      if (hiddenDataSet1.has(legendId5)) {
        hiddenDataSet1.delete(legendId5);
      }
      if (hiddenDataSet1.has(legendId6)) {
        hiddenDataSet1.delete(legendId6);
      }
      if (hiddenDataSet1.has(legendId7)) {
        hiddenDataSet1.delete(legendId7);
      }
      if (hiddenDataSet1.has(legendId8)) {
        hiddenDataSet1.delete(legendId8);
      }
    }

    getChartData();
  }, [selectedAll]);

  useEffect(() => {
    if (dealershipContext.id !== 0) {
      getChartData();
      getLeadsData(0, limit).finally(() => {
        setPage(0);
      });
    }
    // eslint-disable-next-line
  }, [dealershipContext]);

  useEffect(() => {
    if (size.width !== chartWidth) {
      if (size.width > 1220) {
        setChartWidth(size.width);
      } else {
        setChartWidth(1220);
      }
    }
    // eslint-disable-next-line
  }, [size]);

  const getDailyReportQuery = (): string => {
    const to = moment(endDate).format(Util.localDateFormat);
    const from = moment(startDate).format(Util.localDateFormat);

    let query = `?startDate=${from}&endDate=${to}`;

    if (dealership.value !== 0) {
      query += `&dealershipRefId=${dealership.externalRefId}`;
    }

    if (offer.value !== 0) {
      query += `&offer=${offer.externalRefId}`;
    }

    if (device !== DEVICES.ALL) {
      query += `&device=${device.toLowerCase()}`;
    }

    if (type !== TYPES.ALL) {
      query += `&sales=${type}`;
    }

    return query;
  };

  const getChartData = () =>
    new Promise((resolve, reject) => {
      setPending(true);
      getDailyReport(getDailyReportQuery())
        .then((response) => {
          generateChartData(response.data);

          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);

          reject(error);
        })
        .finally(() => setPending(false));
    });

  const getLeadsData = (
    page: number,
    limit: number,
    sorting?: { field: string; direction: string }
  ) =>
    new Promise((resolve, reject) => {
      setLeadsPending(true);

      const to = moment(endDate)
        .set({
          hour: 23,
          minute: 59,
          second: 59,
        })
        .format(Util.localDateTimeFormat);
      const from = moment(startDate)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
        })
        .format(Util.localDateTimeFormat);

      getGeneratedLeads({
        page: page,
        pageLimit: limit,
        device:
          device.toLowerCase() !== "all" ? device.toLowerCase() : undefined,
        type: type.toLowerCase(),
        offerId: offer.value !== 0 ? offer.value : undefined,
        dealershipId: dealership.value !== 0 ? dealership.value : undefined,
        toDate: to,
        fromDate: from,
        sorting: sorting,
        timezone: dealershipContext.timezone,
      })
        .then((response) => {
          setLeadsData(response.data.content);
          setTotalItemsCount(response.data.totalElements);

          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);
          reject(error);
        })
        .finally(() => {
          setLeadsPending(false);
        });
    });

  const generateChartData = (data: { reports: any[] }): any => {
    let totalLeads: number = 0;
    let allUniqueVisitors: number = 0;

    const reportData: any = data.reports.reduce(
      (accumulator, currentValue, index) => {
        totalLeads += currentValue.totalLeads;
        allUniqueVisitors += currentValue.uniqueVisitors;

        if (index === 0) {
          accumulator["labels"] = [
            moment(currentValue.reportDate).format(Util.usDateFormat),
          ];
          accumulator["newVisitors"] = [currentValue.newVisitors];
          accumulator["conditionNew"] = [currentValue.conditionNew];
          accumulator["conditionUsed"] = [currentValue.conditionUsed];
          accumulator["conditionCertified"] = [currentValue.conditionCertified];
          accumulator["uniqueVisitors"] = [currentValue.uniqueVisitors];
          accumulator["totalPageViews"] = [currentValue.totalPageViews];
          accumulator["averageTimeSpent"] = [currentValue.averageTimeSpent];
          accumulator["returningVisitors"] = [currentValue.returningVisitors];
          accumulator["popupsShown"] = [currentValue.popupsShown];
          accumulator["popupLeads"] = [currentValue.popupLeads];
          accumulator["pullupLeads"] = [currentValue.pullupLeads];
          accumulator["pullupsClicked"] = [currentValue.pullupsClicked];
          accumulator["pullupsShown"] = [currentValue.pullupsShown];
          accumulator["totalLeads"] = [currentValue.totalLeads];
          let percentShown =
            currentValue.popupsShown !== 0 && currentValue.totalPageViews !== 0
              ? parseFloat(
                  (
                    Util._.divide(
                      currentValue.popupsShown,
                      currentValue.totalPageViews
                    ) * 100
                  ).toString()
                ).toFixed(2)
              : "0";
          let percentConversion =
            currentValue.popupLeads !== 0 && currentValue.totalPageViews !== 0
              ? parseFloat(
                  (
                    Util._.divide(
                      currentValue.popupLeads,
                      currentValue.totalPageViews
                    ) * 100
                  ).toString()
                ).toFixed(2)
              : "0";

          accumulator["percentShown"] = [percentShown];
          accumulator["percentConversion"] = [percentConversion];
        } else {
          accumulator["labels"].push(
            moment(currentValue.reportDate).format(Util.usDateFormat)
          );
          accumulator["newVisitors"].push(currentValue.newVisitors);
          accumulator["conditionNew"].push(currentValue.conditionNew);
          accumulator["conditionUsed"].push(currentValue.conditionUsed);
          accumulator["conditionCertified"].push(
            currentValue.conditionCertified
          );
          accumulator["uniqueVisitors"].push(currentValue.uniqueVisitors);
          accumulator["totalPageViews"].push(currentValue.totalPageViews);
          accumulator["averageTimeSpent"].push(currentValue.averageTimeSpent);
          accumulator["returningVisitors"].push(currentValue.returningVisitors);
          accumulator["popupsShown"].push(currentValue.popupsShown);
          accumulator["popupLeads"].push(currentValue.popupLeads);
          accumulator["pullupLeads"].push(currentValue.pullupLeads);
          accumulator["pullupsClicked"].push(currentValue.pullupsClicked);
          accumulator["pullupsShown"].push(currentValue.pullupsShown);
          accumulator["totalLeads"].push(currentValue.totalLeads);
          let percentShown =
            currentValue.popupsShown !== 0 && currentValue.totalPageViews !== 0
              ? parseFloat(
                  (
                    Util._.divide(
                      currentValue.popupsShown,
                      currentValue.totalPageViews
                    ) * 100
                  ).toString()
                ).toFixed(2)
              : "0";
          let percentConversion =
            currentValue.popupLeads !== 0 && currentValue.totalPageViews !== 0
              ? parseFloat(
                  (
                    Util._.divide(
                      currentValue.popupLeads,
                      currentValue.totalPageViews
                    ) * 100
                  ).toString()
                ).toFixed(2)
              : "0";
          accumulator["percentShown"].push(percentShown);
          accumulator["percentConversion"].push(percentConversion);
        }

        return accumulator;
      },
      {}
    );

    if (Object.keys(reportData).length > 0 && allUniqueVisitors > 0) {
      const avgUniqueVisitors = Util._.divide(
        allUniqueVisitors,
        reportData.labels.length
      );
      const websiteLeads = Util._.multiply(avgUniqueVisitors, 0.017);
      const leadLiftProjection = Util._.multiply(websiteLeads, 0.3);
      const leadLiftPercentage = Util._.divide(totalLeads, leadLiftProjection);

      setAvgUniqueVisitors(Math.round(avgUniqueVisitors));
      setWebsiteLeads(Math.round(websiteLeads));
      setLeadLiftProjection(Math.round(leadLiftProjection));
      setLeadLiftPercentage(Math.round(leadLiftPercentage));
    } else {
      setAvgUniqueVisitors(0);
      setWebsiteLeads(0);
      setLeadLiftProjection(0);
      setLeadLiftPercentage(0);
    }

    setFirstChartData({
      labels: reportData.labels,
      datasets: [
        {
          type: "line",
          label: LABELS.TOTAL_PAGE_VIEWS,
          borderColor: "#9c9999",
          backgroundColor: "#9c9999",
          borderWidth: 2,
          fill: false,
          lineTension: 0,
          data: reportData.totalPageViews,
          yAxisID: "y-axis-1",
          hidden: hiddenDataSet1.has(
            getLegendId(IDS.dashboard.chart.mainChart, LABELS.TOTAL_PAGE_VIEWS)
          ),
        },
        {
          type: "line",
          label: LABELS.NEW_PAGE_VIEWS,
          borderColor: Util.stringToColor(LABELS.NEW_PAGE_VIEWS),
          backgroundColor: Util.stringToColor(LABELS.NEW_PAGE_VIEWS),
          borderWidth: 2,
          fill: false,
          lineTension: 0,
          data: reportData.conditionNew,
          yAxisID: "y-axis-1",
          hidden: hiddenDataSet1.has(
            getLegendId(IDS.dashboard.chart.mainChart, LABELS.NEW_PAGE_VIEWS)
          ),
        },
        {
          type: "line",
          label: LABELS.USED_PAGE_VIEWS,
          borderColor: Util.stringToColor(LABELS.USED_PAGE_VIEWS),
          backgroundColor: Util.stringToColor(LABELS.USED_PAGE_VIEWS),
          borderWidth: 2,
          fill: false,
          lineTension: 0,
          data: reportData.conditionUsed,
          yAxisID: "y-axis-1",
          hidden: hiddenDataSet1.has(
            getLegendId(IDS.dashboard.chart.mainChart, LABELS.USED_PAGE_VIEWS)
          ),
        },
        {
          type: "line",
          label: LABELS.CERTIFIED_PAGE_VIEWS,
          borderColor: Util.stringToColor(LABELS.CERTIFIED_PAGE_VIEWS),
          backgroundColor: Util.stringToColor(LABELS.CERTIFIED_PAGE_VIEWS),
          borderWidth: 2,
          fill: false,
          lineTension: 0,
          data: reportData.conditionCertified,
          yAxisID: "y-axis-1",
          hidden: hiddenDataSet1.has(
            getLegendId(
              IDS.dashboard.chart.mainChart,
              LABELS.CERTIFIED_PAGE_VIEWS
            )
          ),
        },
        {
          type: "line",
          label: LABELS.AVERAGE_TIME_SPENT,
          borderColor: "#133ec8",
          backgroundColor: "#133ec8",
          borderWidth: 2,
          fill: false,
          lineTension: 0,
          data: reportData.averageTimeSpent,
          yAxisID: "y-axis-2",
          hidden: hiddenDataSet1.has(
            getLegendId(
              IDS.dashboard.chart.mainChart,
              LABELS.AVERAGE_TIME_SPENT
            )
          ),
        },
        {
          type: "line",
          label: LABELS.RETURNING_VISITORS,
          borderColor: "#47b3c8",
          backgroundColor: "#47b3c8",
          borderWidth: 2,
          fill: false,
          lineTension: 0,
          data: reportData.returningVisitors,
          yAxisID: "y-axis-4",
          hidden: hiddenDataSet1.has(
            getLegendId(
              IDS.dashboard.chart.mainChart,
              LABELS.RETURNING_VISITORS
            )
          ),
        },
        {
          type: "line",
          label: LABELS.NEW_VISITORS,
          borderColor: "#0c357b",
          backgroundColor: "#0c357b",
          borderWidth: 2,
          fill: false,
          lineTension: 0,
          data: reportData.newVisitors,
          yAxisID: "y-axis-5",
          hidden: hiddenDataSet1.has(
            getLegendId(IDS.dashboard.chart.mainChart, LABELS.NEW_VISITORS)
          ),
        },
        {
          type: "bar",
          label: LABELS.UNIQUE_VISITORS,
          backgroundColor: "#51A7EE",
          data: reportData.uniqueVisitors,
          yAxisID: "y-axis-3",
          hidden: hiddenDataSet1.has(
            getLegendId(IDS.dashboard.chart.mainChart, LABELS.UNIQUE_VISITORS)
          ),
        },
      ],
    });

    setSecondChartData({
      labels: reportData.labels,
      datasets: [
        {
          label: LABELS.PERCENT_SHOWN,
          fill: false,
          borderColor: "#47b3c8",
          backgroundColor: "#47b3c8",
          yAxisID: "y-axis-1",
          lineTension: 0,
          data: reportData.percentShown,
          hidden: hiddenDataSet.has(
            getLegendId(
              IDS.dashboard.chart.conversionChart,
              LABELS.PERCENT_SHOWN
            )
          ),
        },
        {
          label: LABELS.PERCENT_CONVERSION,
          fill: false,
          borderColor: "#cacaca",
          backgroundColor: "#cacaca",
          yAxisID: "y-axis-2",
          lineTension: 0,
          data: reportData.percentConversion,
          hidden: hiddenDataSet.has(
            getLegendId(
              IDS.dashboard.chart.conversionChart,
              LABELS.PERCENT_CONVERSION
            )
          ),
        },
      ],
    });

    setThirdChartData({
      labels: reportData.labels,
      datasets: [
        {
          label: LABELS.SHOWN,
          fill: false,
          borderColor: "#3B5999",
          backgroundColor: "#3B5999",
          yAxisID: "y-axis-1",
          lineTension: 0,
          data: reportData.pullupsShown,
          hidden: hiddenDataSet.has(
            getLegendId(IDS.dashboard.chart.pullsChart, LABELS.SHOWN)
          ),
        },
        {
          label: LABELS.PULLS,
          fill: false,
          borderColor: "#51A7EE",
          backgroundColor: "#51A7EE",
          yAxisID: "y-axis-2",
          lineTension: 0,
          data: reportData.pullupsClicked,
          hidden: hiddenDataSet.has(
            getLegendId(IDS.dashboard.chart.pullsChart, LABELS.PULLS)
          ),
        },
        {
          label: LABELS.LEADS,
          fill: false,
          borderColor: "#cacaca",
          backgroundColor: "#cacaca",
          yAxisID: "y-axis-3",
          lineTension: 0,
          data: reportData.pullupLeads,
          hidden: hiddenDataSet.has(
            getLegendId(IDS.dashboard.chart.pullsChart, LABELS.LEADS)
          ),
        },
      ],
    });

    setForthChartData({
      labels: reportData.labels,
      datasets: [
        {
          label: LABELS.LEADS,
          fill: false,
          borderColor: "#ADACAB",
          backgroundColor: "#ADACAB",
          yAxisID: "y-axis-1",
          lineTension: 0,
          data: reportData.totalLeads,
          hidden: hiddenDataSet.has(
            getLegendId(IDS.dashboard.chart.leadsChart, LABELS.LEADS)
          ),
        },
        {
          type: "bar",
          label: LABELS.UNIQUE_VISITORS,
          borderColor: "#51A7EE",
          backgroundColor: "#51A7EE",
          data: reportData.uniqueVisitors,
          yAxisID: "y-axis-2",
          hidden: hiddenDataSet.has(
            getLegendId(IDS.dashboard.chart.leadsChart, LABELS.UNIQUE_VISITORS)
          ),
        },
      ],
    });
  };

  const onPeriodChange = (item: any) => {
    if (item.value !== "CUSTOM_PERIOD") {
      const period = PERIODS[item.value];

      PERIODS.CUSTOM_PERIOD.label = "Custom Period";
      PERIODS.CUSTOM_PERIOD.to = null;
      PERIODS.CUSTOM_PERIOD.from = null;

      setEndDate(period.to);
      setStartDate(period.from);
    } else {
      setSidebarVisible(true);
    }

    setPeriod(item.value);
  };

  const onPage = (event: any) => {
    getLeadsData(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
    });
  };

  const onSort = (data: any) => {
    setSortField(data.sortField);
    setSortOrder(data.sortOrder);

    getLeadsData(page, limit, {
      field: data.sortField,
      direction: data.sortOrder === 1 ? "asc" : "desc",
    });
  };

  const onDealershipChange = (dealership: any) => {
    setDealership(dealership);
  };

  const onOfferChange = (offer: any) => {
    setOffer(offer);
  };

  const onApply = (): void => {
    getChartData();
    getLeadsData(0, limit);
    setExportDisabled(false);
  };

  const formatGroupLabel = (data: any) => {
    return (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.total}</span>
      </div>
    );
  };

  const promiseMyDealerships = (inputValue: string): any =>
    new Promise(async (resolve) => {
      const response: any = await Util.promiseMyDealerships(inputValue);

      response[0].options.unshift({
        label: "All",
        value: 0,
        externalRefId: "all",
      });

      resolve(response);
    });

  const promiseOffers = (inputValue: string): any =>
    new Promise(async (resolve) => {
      const dealershipName =
        dealership.value !== 0 ? dealership.label : undefined;
      const response: any = await Util.promiseMyOffers(
        inputValue,
        dealershipName
      );

      response[0].options.unshift({
        label: "All",
        value: 0,
        externalRefId: "all",
      });

      resolve(response);
    });

  const createdTemplate = (data: any) => {
    return <div>{data.zonedCreated}</div>;
  };

  const onHideSidebar = (): void => {
    if (PERIODS.CUSTOM_PERIOD.label === "Custom Period") {
      setPeriod("LAST_30_DAYS");
    }

    setSidebarVisible(false);
  };

  const onApplyCustomPeriod = () => {
    if (
      PERIODS.CUSTOM_PERIOD.from &&
      (PERIODS.CUSTOM_PERIOD.from === PERIODS.CUSTOM_PERIOD.to ||
        moment(PERIODS.CUSTOM_PERIOD.from).isBefore(
          moment(PERIODS.CUSTOM_PERIOD.to)
        ))
    ) {
      PERIODS.CUSTOM_PERIOD.label = `${moment(
        PERIODS.CUSTOM_PERIOD.from
      ).format(Util.usDateFormat)} - ${moment(PERIODS.CUSTOM_PERIOD.to).format(
        Util.usDateFormat
      )}`;

      setEndDate(PERIODS.CUSTOM_PERIOD.to);
      setStartDate(PERIODS.CUSTOM_PERIOD.from);

      onHideSidebar();
    } else {
      Util.warning("Invalid date range.");
    }
  };

  const onSelectLead = (e: DataTableSelectionChangeParams): void => {
    setSelectedLead(e.value);
  };

  const onHideSelectedLead = (): void => {
    setSelectedLead(undefined);
  };

  const getPrefix = (): string => {
    let prefix: string;

    if (dealership.value === 0) {
      prefix = "All_dealers";
    } else {
      prefix = Util.getDefaultIfNull(
        Util._.get(dealership, "label"),
        ""
      ).replaceAll(" ", "_");
    }

    return prefix;
  };

  const getSuffix = (startDate: string, endDate: string): string => {
    return `from_${moment(startDate).format("DD.MMMM.YYYY")}_to_${moment(
      endDate
    ).format("DD.MMMM.YYYY")}`;
  };

  const onExport = (): void => {
    const to = moment(endDate).format(Util.localDateTimeFormat);
    const from = moment(startDate).format(Util.localDateTimeFormat);

    const dealershipIds: any = [];

    if (dealership.value !== 0) {
      dealershipIds.push(dealership.value);
    }

    Util.globalSpinner().show();
    createReport({
      fromDate: from,
      toDate: to,
      pageType: type.toLowerCase(),
      dealershipIds: dealershipIds,
      offerId: offer.value !== 0 ? offer.value : undefined,
      device: device.toLowerCase() !== "all" ? device : undefined,
      timezone: dealershipContext.timezone,
    })
      .then((response) => {
        const reportId = response.data.id;

        if (Util.isEmpty(reportId)) {
          Util.warning(Util.defaultErrorMessage);
          return;
        }

        setExportDisabled(true);
        const fileDownloader = new LeadFileDownloader(
          reportId,
          getPrefix(),
          getSuffix(startDate, endDate)
        );
        fileDownloader.download();
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => {
        Util.globalSpinner().hide();
      });
  };

  const sentToContent = (rowData) => {
    const sentToEmails = rowData?.sentTo?.split(",");
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {sentToEmails?.map((email, index) => (
          <p key={index}>{email}</p>
        ))}
      </div>
    );
  };

  return (
    <div id={IDS.dashboard.wrapper}>
      <Spinner visible={pending} />
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div className="card card-w-title datatable-demo">
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-2 p-md-6 p-sm-12"}>
                <div style={{ height: 20 }}>
                  <span className={"dashboard-filter-title"}>Dealership</span>
                </div>
                <AsyncSelect
                  id={IDS.dropdown.dealership}
                  menuPosition={"fixed"}
                  cacheOptions
                  defaultOptions
                  value={dealership}
                  loadOptions={promiseMyDealerships}
                  placeholder={"Select Dealership"}
                  formatGroupLabel={formatGroupLabel}
                  onChange={onDealershipChange}
                />
              </div>
              <div className={"p-col-12 p-lg-2 p-md-6 p-sm-12"}>
                <div style={{ height: 20 }}>
                  <span className={"dashboard-filter-title"}>Offer</span>
                </div>
                <AsyncSelect
                  id={IDS.dropdown.offer}
                  key={dealership.value}
                  menuPosition={"fixed"}
                  cacheOptions
                  defaultOptions
                  value={offer}
                  loadOptions={promiseOffers}
                  placeholder={"Select a Offer"}
                  formatGroupLabel={formatGroupLabel}
                  onChange={onOfferChange}
                />
              </div>
              <div className={"p-col-12 p-lg-2 p-md-6 p-sm-12"}>
                <div style={{ height: 20 }}>
                  <span className={"dashboard-filter-title"}>Period</span>
                </div>
                <Select
                  id={IDS.dropdown.period}
                  value={{
                    label: PERIODS[period].label,
                    value: period,
                  }}
                  //style={{ width: "100%" }}
                  options={Object.keys(PERIODS).map((key: any) => {
                    return {
                      label: PERIODS[key].label,
                      value: key,
                    };
                  })}
                  placeholder={"Select period"}
                  onChange={(item: any) => onPeriodChange(item)}
                />
              </div>
              <div className={"p-col-12 p-lg-2 p-md-6 p-sm-12"}>
                <div style={{ height: 20 }}>
                  <span className={"dashboard-filter-title"}>Device</span>
                </div>
                <Dropdown
                  id={IDS.dropdown.device}
                  style={{ width: "100%" }}
                  value={device}
                  options={Object.values(DEVICES).map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  })}
                  onChange={(e: any) => setDevice(e.target.value)}
                  placeholder="Select device"
                />
              </div>
              <div className={"p-col-12 p-lg-2 p-md-6 p-sm-12"}>
                <div style={{ height: 20 }}>
                  <span className={"dashboard-filter-title"}>Visitor Type</span>
                </div>
                <Dropdown
                  id={IDS.dropdown.visitorType}
                  style={{ width: "100%" }}
                  value={type}
                  options={Object.values(TYPES).map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  })}
                  onChange={(e: any) => setType(e.target.value)}
                  placeholder="Select type"
                />
              </div>
              <div className={"p-col-12 p-lg-1 p-md-6 p-sm-12"}>
                <Button
                  id={IDS.button.apply}
                  onClick={onApply}
                  style={{ marginTop: 23, width: "100%" }}
                  label="Apply"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Panel
        style={{ marginBottom: 5 }}
        header={
          <div style={{ display: "inline-flex", width: "100%" }}>
            <div
              style={{
                padding: 5,
                display: "inline-block",
              }}
            >
              <div
                style={{
                  marginRight: 10,
                  paddingRight: 10,
                  float: "left",
                  borderRight: "1px solid #d8d8d8",
                }}
              >
                <label style={{ fontSize: 28, fontWeight: "bold" }}>
                  {avgUniqueVisitors}
                </label>
                <div style={{ marginTop: 5 }}>
                  <label style={{ color: "rgb(105 105 116)", fontSize: 12 }}>
                    Avg MUV
                  </label>
                </div>
              </div>

              <div
                style={{
                  marginRight: 10,
                  paddingRight: 10,
                  float: "left",
                  borderRight: "1px solid #d8d8d8",
                }}
              >
                <label style={{ fontSize: 28, fontWeight: "bold" }}>
                  {websiteLeads}
                </label>
                <div style={{ marginTop: 5 }}>
                  <label style={{ color: "rgb(105 105 116)", fontSize: 12 }}>
                    Est: Website Leads (1.7% CR)
                  </label>
                </div>
              </div>

              <div
                style={{
                  marginRight: 10,
                  paddingRight: 10,
                  float: "left",
                  borderRight: "1px solid #d8d8d8",
                }}
              >
                <label style={{ fontSize: 28, fontWeight: "bold" }}>
                  {leadLiftProjection}
                </label>
                <div style={{ marginTop: 5 }}>
                  <label style={{ color: "rgb(105 105 116)", fontSize: 12 }}>
                    Lead lift projection (30%)
                  </label>
                </div>
              </div>

              <div style={{ marginRight: 20, float: "left" }}>
                <label style={{ fontSize: 28, fontWeight: "bold" }}>
                  {leadLiftPercentage}%
                </label>
                <div style={{ marginTop: 5 }}>
                  <label style={{ color: "rgb(105 105 116)", fontSize: 12 }}>
                    Actual lead lift percentage
                  </label>
                </div>
              </div>
            </div>
            <Button
              onClick={() => setSelectedAll(!selectedAll)}
              id="apply-btn"
              style={{ marginLeft: "auto", height: "33px" }}
              className="no-icon-buttons"
              label={selectedAll ? t("unselectAll") : t("selectAll")}
            />
          </div>
        }
      >
        <Chart2
          id={IDS.dashboard.chart.mainChart}
          type={"bar"}
          key={`${chartWidth}1`}
          width={(chartWidth - 100).toString()}
          height={"500"}
          data={firstChartData}
          options={options}
        />
      </Panel>

      <Panel style={{ marginBottom: 5 }}>
        <Chart2
          id={IDS.dashboard.chart.conversionChart}
          type={"line"}
          height={"500"}
          key={chartWidth}
          width={(chartWidth - 100).toString()}
          data={secondChartData}
          options={secondChartOptions}
        />
      </Panel>

      <Panel style={{ marginBottom: 5 }}>
        <Chart2
          id={IDS.dashboard.chart.pullsChart}
          type={"line"}
          height={"500"}
          key={chartWidth}
          width={(chartWidth - 100).toString()}
          data={thirdChartData}
          options={thirdChartOptions}
        />
      </Panel>

      <Panel style={{ marginBottom: 5 }}>
        <Chart2
          id={IDS.dashboard.chart.leadsChart}
          type={"line"}
          height={"500"}
          key={chartWidth}
          width={(chartWidth - 100).toString()}
          data={forthChartData}
          options={forthChartOptions}
        />
      </Panel>

      <div className={"p-grid"}>
        <div className="p-col-12">
          <div className="card card-w-title datatable-demo">
            <div
              className={
                limit > 10
                  ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting"
                  : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
              }
            >
              <DataTable
                resizableColumns={true}
                columnResizeMode={"fit"}
                rowsPerPageOptions={Util.rowsPerPageOptions}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                value={leadsData}
                paginator={true}
                scrollable
                totalRecords={totalItemsCount}
                rows={limit}
                lazy={true}
                first={first}
                loading={leadsPending}
                onPage={onPage}
                selectionMode={"single"}
                sortMode={"single"}
                onSelectionChange={onSelectLead}
                onSort={onSort}
                sortField={sortField}
                sortOrder={sortOrder}
                header={
                  <div style={{ height: 30 }}>
                    <Button
                      id={IDS.button.export}
                      style={{ float: "right" }}
                      icon={"pi pi-download"}
                      label={"Export"}
                      title={"Export Leads as CSV"}
                      disabled={exportDisabled}
                      onClick={onExport}
                    />
                  </div>
                }
              >
                <Column
                  field="zonedCreated"
                  header="Date"
                  body={createdTemplate}
                />
                <Column field="device" header="Device" />
                <Column field="dealershipName" header="Dealership" />
                <Column field="offerName" header="Offer" />
                <Column field="metadata.first_name" header="First Name" />
                <Column field="metadata.last_name" header="Last Name" />
                <Column field="metadata.email" header="Email Address" />
                <Column field="sentTo" body={sentToContent} header="Sent To" />
                <Column field={"disposition"} header={"Disposition"} />
                <Column field="pageType" header="SP/Interest" />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        style={{ width: 318 }}
        position={"right"}
        visible={sidebarVisible}
        onHide={onHideSidebar}
        showCloseIcon={false}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <CloseButton onHide={onHideSidebar} />
          <div
            style={{
              marginTop: 25,
              marginLeft: -15,
              marginRight: -15,
              borderBottom: "1px solid #afa6a6",
            }}
          >
            <label style={{ fontSize: 20, marginLeft: 20 }}>
              Custom Period
            </label>
          </div>
          <div style={{ padding: 5, width: "100%" }}>
            <div style={{ width: "100%", display: "grid", padding: "0 10px" }}>
              <label>From</label>
              <Calendar
                yearNavigator={true}
                monthNavigator={true}
                maxDate={moment().toDate()}
                inputStyle={{ width: "100%" }}
                yearRange={`2010:${moment().format("YYYY")}`}
                value={moment(PERIODS.CUSTOM_PERIOD.from).toDate()}
                onChange={(e: any) => {
                  const currentTime = moment();
                  const h = currentTime.get("hour");
                  const m = currentTime.get("minute");
                  const s = currentTime.get("seconds");

                  const from = moment(e.value).set({
                    hours: h,
                    minutes: m,
                    seconds: s,
                  });

                  PERIODS.CUSTOM_PERIOD.from = from.format(
                    Util.localDateTimeFormat
                  );
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginTop: 5,
                display: "grid",
                padding: "0 10px",
              }}
            >
              <label>To</label>
              <Calendar
                yearNavigator={true}
                monthNavigator={true}
                maxDate={moment().toDate()}
                inputStyle={{ width: "100%" }}
                yearRange={`2010:${moment().format("YYYY")}`}
                value={moment(PERIODS.CUSTOM_PERIOD.to).toDate()}
                onChange={(e: any) => {
                  const currentTime = moment();
                  const h = currentTime.get("hour");
                  const m = currentTime.get("minute");
                  const s = currentTime.get("seconds");

                  const to = moment(e.value).set({
                    hours: h,
                    minutes: m,
                    seconds: s,
                  });

                  PERIODS.CUSTOM_PERIOD.to = to.format(
                    Util.localDateTimeFormat
                  );
                }}
              />
            </div>
            <Button
              className="no-icon-buttons"
              label={"Apply"}
              style={{ marginTop: 5, float: "right" }}
              onClick={onApplyCustomPeriod}
            />
          </div>
        </div>
      </Sidebar>
      <Dialog
        modal={true}
        visible={!!selectedLead}
        onHide={onHideSelectedLead}
        header={"Lead Details"}
      >
        <div>
          <label>First visit:</label>
          <span className={"lead-details-value"}>
            {Util._.get(selectedLead, "zonedCreated", null)}
          </span>
        </div>

        <div>
          <label>Page Views:</label>
          <span className={"lead-details-value"}>
            {Util._.get(selectedLead, "pageViews", 0)}
          </span>
        </div>

        <div>
          <label>Engaged time:</label>
          <span className={"lead-details-value"}>
            {Util._.get(selectedLead, "totalEngagedTime", "0 minutes")}
          </span>
        </div>

        <div>
          <label>Duration time:</label>
          <span className={"lead-details-value"}>
            {Util._.get(selectedLead, "totalDurationTime", "0 minutes")}
          </span>
        </div>

        <div>
          <label>Interests with percentages:</label>
          <span className={"lead-details-value"}>
            {Util._.get(selectedLead, "stats", "")}
          </span>
        </div>
        <div>
          {(() => {
            let pii = Util._.get(selectedLead, "metadata", "")?.pii;
            let vehicles = Util._.get(selectedLead, "metadata", "")?.vehicles;
            if (pii || vehicles) {
              return (
                <>
                  <h4>Registered PII to this lead:</h4>
                </>
              );
            } else {
              return null;
            }
          })()}
          {(() => {
            let pii = Util._.get(selectedLead, "metadata", "")?.pii;
            if (pii) {
              return (
                <>
                  {pii.map((item, index) => (
                    <div key={index}>
                      {
                        // Render specific fields first: address -> city -> state -> zip code
                        ["address", "city", "state", "zipcode"].map((field) => {
                          let value = item[field];
                          let displayKey = field;

                          if (value !== undefined) {
                            if (
                              Array.isArray(value) &&
                              (field === "emails" || field === "phone")
                            ) {
                              value = value.join(", ");
                            }

                            displayKey = displayKey
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase());

                            return (
                              <p key={field}>
                                <strong>{displayKey}:</strong> {value ?? "N/A"}
                              </p>
                            );
                          }
                          return null;
                        })
                      }

                      {
                        // Render other fields in the object
                        Object.entries(item).map(([key, value]) => {
                          if (
                            ![
                              "address",
                              "city",
                              "state",
                              "zipcode",
                              "allFieldsNull",
                            ].includes(key)
                          ) {
                            if (key === "emails" && Array.isArray(value)) {
                              value = value.join(", ");
                            }
                            if (key === "phone" && Array.isArray(value)) {
                              value = value.join(", ");
                            }
                            key = key
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase());
                            return (
                              <p key={key}>
                                <>
                                  <strong>{key}:</strong> {value ?? "N/A"}
                                </>
                              </p>
                            );
                          }
                          return null;
                        })
                      }

                      <hr />
                    </div>
                  ))}
                </>
              );
            } else {
              return null;
            }
          })()}

          {(() => {
            let vehicles = Util._.get(selectedLead, "metadata", "")?.vehicles;
            if (vehicles) {
              return (
                <>
                  <h4>Registered vehicles to this lead:</h4>
                  {vehicles.map((vehicle, index) => (
                    <div key={index}>
                      <h5>Vehicle # {index + 1}</h5>

                      {
                        // Render specific fields first
                        vehicleOrderFields.map((field) => {
                          let value = vehicle[field];
                          let displayKey = field;

                          if (field === "series") {
                            displayKey = "Trim";
                          }

                          if (value !== undefined) {
                            return (
                              <p key={field}>
                                <strong>
                                  {displayKey
                                    .replace(/_/g, " ")
                                    .replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )}
                                  :
                                </strong>{" "}
                                {value ?? "N/A"}
                              </p>
                            );
                          }
                          return null;
                        })
                      }

                      {
                        // Render other fields in the object
                        Object.entries(vehicle).map(([key, value]) => {
                          if (!vehicleOrderFields.includes(key)) {
                            return (
                              <p key={key}>
                                <>
                                  <strong>
                                    {key
                                      .replace(/_/g, " ")
                                      .replace(/\b\w/g, (char) =>
                                        char.toUpperCase()
                                      )}
                                    :
                                  </strong>{" "}
                                  {value ?? "N/A"}
                                </>
                              </p>
                            );
                          }
                          return null;
                        })
                      }

                      <hr />
                    </div>
                  ))}
                </>
              );
            } else {
              return null;
            }
          })()}
        </div>
      </Dialog>
    </div>
  );
}
