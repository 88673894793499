import React, { useEffect, useState } from "react";
import "./event_logs.css";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import { Column } from "primereact/column";
import Util from "../../util/Util";
import { getEventLogs } from "../../actions/eventLogsActions";
import { IEventLog, IStore } from "../../../index.dts";
import { InputText } from "primereact/inputtext";
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  SEARCH_OPERATIONS,
} from "../../util/Enums";
import { Dropdown } from "primereact/dropdown";
import moment from "moment-timezone";
import { shallowEqual, useSelector } from "react-redux";
import { Button } from "primereact/button";
import AsyncSelect from "react-select/async";
import { getUserList } from "../../actions/userManagementActions";
import { useTranslation } from "react-i18next";
import { IDS } from "../constants";
import { DateRangePeriods } from "../../components/data_range_picker/date_range_periods";
import { DateRangePickerWidget } from "../../components/data_range_picker/DateRangePickerWidget";

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

export function EventLogs() {
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [first, setFirst] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortMap] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(
    new Map([
      [
        "sort",
        {
          field: "created",
          order: -1,
        },
      ],
    ])
  );

  const [eventLogList, setEventLogList] = useState<IEventLog[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);

  const [actionFilterValue, setActionFilterValue] = useState(EVENT_ACTIONS.ALL);
  const [categoryFilterValue, setCategoryFilterValue] = useState(
    EVENT_CATEGORIES.ALL
  );
  const period = DateRangePeriods.get(DateRangePeriods.keys.last30Days);
  const [createdPeriod, setCreatedPeriod] = useState<{
    start?: Date;
    end?: Date;
  }>({
    start: period.start,
    end: period.end,
  });
  const [descriptionFilterValue, setDescriptionFilterValue] =
    useState<string>("");

  const [userFilterValue, setUserFilterValue] = useState<
    { label: string; value: number; externalRefId?: string }[]
  >([]);
  const [dealershipFilterValue, setDealershipFilterValue] = useState<
    { label: string; value: number; externalRefId?: string }[]
  >([]);

  useEffect(() => {
    if (dealershipContext.id === 0) {
      return;
    }

    const hasDealership = dealershipFilterValue.includes({
      label: dealershipContext.name,
      value: dealershipContext.id,
    });

    if (!hasDealership) {
      setDealershipFilterValue([
        { label: dealershipContext.name, value: dealershipContext.id },
      ]);
    }

    // eslint-disable-next-line
  }, [dealershipContext]);

  useEffect(() => {
    if (dealershipContext.id === 0) {
      return;
    }

    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getData(0, limit).finally(() => {
        setPage(0);
        setFirst(0);
        searchTimeoutInterval = 500;
      });
    }, searchTimeoutInterval);
    // eslint-disable-next-line
  }, [
    userFilterValue,
    actionFilterValue,
    categoryFilterValue,
    createdPeriod,
    dealershipFilterValue,
    descriptionFilterValue,
  ]);

  const getData = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      const filter: any[] = [];

      if (actionFilterValue && actionFilterValue !== EVENT_ACTIONS.ALL) {
        filter.push({
          field: "action",
          value: actionFilterValue,
          operation: SEARCH_OPERATIONS.EQUAL,
        });
      }

      if (categoryFilterValue && categoryFilterValue !== EVENT_CATEGORIES.ALL) {
        filter.push({
          field: "category",
          value: categoryFilterValue,
          operation: SEARCH_OPERATIONS.EQUAL,
        });
      }

      if (userFilterValue.length > 0) {
        filter.push({
          field: "user",
          value: userFilterValue.map((item) => item.value),
          operation: SEARCH_OPERATIONS.IN,
        });
      }

      if (!Util.isEmpty(descriptionFilterValue)) {
        filter.push({
          field: "description",
          value: descriptionFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (dealershipFilterValue.length > 0) {
        filter.push({
          field: "dealership",
          value: dealershipFilterValue.map((item) => item.value),
          operation: SEARCH_OPERATIONS.IN,
        });
      }

      if (createdPeriod.start) {
        filter.push({
          field: "created",
          operation: SEARCH_OPERATIONS.GREATER_THAN,
          value: `${moment(createdPeriod.start).format("YYYY-MM-DDT")}00:00:00`,
        });
      }

      if (createdPeriod.end) {
        filter.push({
          field: "created",
          operation: SEARCH_OPERATIONS.LESS_THAN,
          value: `${moment(createdPeriod.end).format("YYYY-MM-DDT")}23:59:59`,
        });
      }

      getEventLogs({
        filter: filter,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
        paging: { page: page, pageLimit: limit },
      })
        .then((response) => {
          setEventLogList(
            response.data.content.map((item: any) => {
              return {
                action: item.action,
                category: item.category,
                dealership: item.dealership,
                description: item.description,
                user: item.user,
                created: item.created,
              };
            })
          );
          setTotalElements(response.data.totalElements);

          setLoading(false);
          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);
          setLoading(false);
          reject(error);
        });
    });
  const onPage = (event: any) => {
    getData(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
    });
  };

  const onSort = (data: any) => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getData(page, limit);
    }
  };

  const actionFilter = (
    <Dropdown
      value={actionFilterValue}
      options={Object.values(EVENT_ACTIONS).map((item) => {
        return {
          value: item,
          label: item,
        };
      })}
      style={{ width: "100%", boxShadow: "none" }}
      onChange={(e: any) => setActionFilterValue(e.target.value)}
    />
  );

  const categoryFilter = (
    <Dropdown
      value={categoryFilterValue}
      options={Object.values(EVENT_CATEGORIES).map((item) => {
        return {
          value: item,
          label: item,
        };
      })}
      style={{ width: "100%", boxShadow: "none" }}
      onChange={(e: any) => setCategoryFilterValue(e.target.value)}
    />
  );

  const descriptionFilter = (
    <InputText
      style={{ width: "100%" }}
      value={descriptionFilterValue}
      onChange={(e: any) => setDescriptionFilterValue(e.target.value)}
    />
  );

  const dealershipFilter = (
    <AsyncSelect
      isMulti={true}
      defaultOptions={true}
      isClearable={true}
      cacheOptions
      closeMenuOnSelect={false}
      menuPosition={"fixed"}
      className={"multi-select"}
      value={dealershipFilterValue}
      placeholder={"Select Dealerships"}
      loadOptions={Util.promiseMyDealerships}
      //style={{ width: "100%", boxShadow: "none" }}
      onChange={(e: any) => {
        setDealershipFilterValue(Util.getDefaultIfNull(e, []));
      }}
    />
  );

  const promiseUsers = (searchValue: string): any =>
    new Promise((resolve) => {
      getUserList({
        filter: [
          {
            value: searchValue,
            field: "email",
            operation: SEARCH_OPERATIONS.LIKE,
          },
        ],
        sorting: [],
        paging: { page: 0, pageLimit: 50 },
      }).then((response) => {
        resolve(
          response.data.content.map((item: any) => {
            return {
              value: item.id,
              label: item.email,
            };
          })
        );
      });
    });

  const userFilter = (
    <AsyncSelect
      isMulti={true}
      defaultOptions={true}
      closeMenuOnSelect={false}
      menuPosition={"fixed"}
      className={"multi-select"}
      value={userFilterValue}
      placeholder={"Select Users"}
      loadOptions={promiseUsers}
      //style={{ width: "100%", boxShadow: "none" }}
      onChange={(e: any) => setUserFilterValue(e)}
    />
  );

  const createdTemplate = (data: any) => {
    return <div>{moment(data.created).format(Util.usDateFormat)}</div>;
  };

  function onCreatedPeriodChange(start?: Date, end?: Date): void {
    setCreatedPeriod({
      start: start ? moment(start).set({ hour: 0, minute: 0, second: 0 }).toDate() : undefined,
      end: end ? moment(end).set({ hour: 23, minute: 59, second: 59 }).toDate() : undefined,
    });
  }

  const createdFilter = (
    <DateRangePickerWidget
      start={createdPeriod.start}
      end={createdPeriod.end}
      ranges={DateRangePeriods.options} // Replace 'Options' with the range definitions
      onChange={onCreatedPeriodChange}
    />
  );

  const onClear = (): void => {
    setDescriptionFilterValue("");
    setActionFilterValue(EVENT_ACTIONS.ALL);
    setCategoryFilterValue(EVENT_CATEGORIES.ALL);

    setUserFilterValue([]);
    setCreatedPeriod({
      start: undefined,
      end: undefined,
    });
    setDealershipFilterValue([]);
  };

  return (
    <div id={"event-logs-view"}>
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div className="card card-w-title datatable-demo">
            <div
              className={
                limit > 10
                  ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting"
                  : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
              }
            >
              <DataTable
                resizableColumns={true}
                columnResizeMode={"expand"}
                rowsPerPageOptions={Util.rowsPerPageOptions}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                value={eventLogList}
                paginator={true}
                scrollable
                totalRecords={totalElements}
                filterDisplay="row"
                rows={limit}
                lazy={true}
                first={first}
                loading={loading}
                onPage={onPage}
                onSort={onSort}
                sortMode={"multiple"}
                multiSortMeta={Array.from(sortMap.values())}
                header={
                  <div className={"p-grid-header-components"}>
                    <Button
                      id={IDS.button.clear}
                      icon={"pi pi-filter"}
                      label={t("clear")}
                      onClick={onClear}
                    />
                  </div>
                }
              >
                <Column
                  filter={true}
                  sortable={true}
                  field={"action"}
                  sortField={"action"}
                  header="Action"
                  filterElement={actionFilter}
                  style={{ overflow: "visible" }}
                />
                <Column
                  filter={true}
                  sortable={true}
                  field={"category"}
                  sortField={"category"}
                  header="Category"
                  filterElement={categoryFilter}
                  style={{ overflow: "visible" }}
                />
                <Column
                  filter={true}
                  sortable={true}
                  field={"user.email"}
                  header="User"
                  filterElement={userFilter}
                />
                <Column
                  filter={true}
                  sortable={true}
                  field={"dealership.name"}
                  sortField={"dealerships.name"}
                  header="Dealerships"
                  filterElement={dealershipFilter}
                />
                <Column
                  filter={true}
                  sortable={true}
                  field={"description"}
                  sortField={"description"}
                  header="Item"
                  filterElement={descriptionFilter}
                />
                <Column
                  filter={true}
                  sortable={true}
                  field={"created"}
                  sortField={"Created"}
                  header="Created"
                  body={createdTemplate}
                  style={{ overflow: "visible" }}
                  filterElement={createdFilter}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventLogs;
