import { shallowEqual, useSelector } from "react-redux";

import { ChartConfig } from "../../../../util/chart/chart_config";
import { IStore } from "../../../../../index.dts";
import React, { useEffect, useRef, useState } from "react";
import { SmartSpendDataView } from "../../../smart_spend_data_view/SmartSpendDataView";
import { SmartSpendDataViewService } from "../../../smart_spend_data_view/smart_spend_data_view_service";
import { useTranslation } from "react-i18next";
import Spinner from "../../../spinner/Spinner";

export function Leads() {
  const dealership = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  const service = new SmartSpendDataViewService();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /**
   * HOOKS
   */
  const { t } = useTranslation();
  let isMountedRef = useRef(false);

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    if (isMountedRef.current) setIsLoading(false);
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  function getSpinner() {
    return isLoading ? (
      <Spinner
        visible={isLoading}
        width={20}
        height={20}
        style={{
          position: "absolute",
          width: 40,
          height: 40,
          top: "unset",
        }}
      />
    ) : null;
  }

  /**
   * VIEW
   */

  return (
    <div className={"leads-container"}>
      {getSpinner()}
      <SmartSpendDataView
        dataAction={service.getDataAction({
          dealershipId: dealership.id,
          type: "leads",
        })}
        dealershipId={dealership.id}
        dealershipRefId={dealership.externalRefId}
        scale={{
          id: ChartConfig.leads.id,
          label: t(ChartConfig.leads.labelKey),
        }}
      />
    </div>
  );
}
