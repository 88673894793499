import React from "react";
import {Badge, IconButton} from "@mui/material";
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';

interface IProps {
  count: number;
  visible: boolean;

  onClick(e: any): void
}

const NotificationBadge = (props: IProps) => {

  return (
    <div style={{marginTop: 2}}>
      <IconButton onClick={props.onClick}>
        <Badge badgeContent={props.count} color="error">
          <CircleNotificationsIcon style={{color: props.visible ? "#000" : "#FFFFFF"}}/>
        </Badge>
      </IconButton>
    </div>
  )
}

export default NotificationBadge;