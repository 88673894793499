import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Toast } from "primereact/toast";

const GlobalGrowl = forwardRef((props: any, ref: any) => {
  const growlRef: any = useRef();

  const [life] = useState<number>(5000);

  const success = (message: string) => {
    growlRef.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: life,
    });
  };

  const warning = (message: React.ReactNode) => {
    growlRef.current.show({
      severity: "warn",
      summary: "Warning",
      detail: message,
      life: life,
    });
  };

  const error = (message: string) => {
    growlRef.current.show({
      severity: "error",
      summary: "UH OH!",
      detail: message,
      life: life,
    });
  };

  const info = (message: string) => {
    growlRef.current.show({
      severity: "info",
      summary: "Info",
      detail: message,
      life: life,
    });
  };

  useImperativeHandle(ref, () => ({
    success: success,
    warning: warning,
    error: error,
    info: info,
  }));

  return <Toast ref={growlRef} />;
});

export default GlobalGrowl;
