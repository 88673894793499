import { IOfferDataRequestDto, IOfferSubmitRequestDto } from "../../index.dts";
import Axios from "../config/Axios";

export const getIOfferToken = (dealershipId: number) =>
  Axios.instance.get(`iOffer/${dealershipId}/get-token`);

export const forwardIOfferData = (dto: IOfferDataRequestDto) =>
  Axios.instance.post("/public/iOffer/data", dto);

export const iOfferLeadSubmit = (dto: any) =>
  Axios.instance.post("/public/ioffer/lead/submit", dto);
