export enum ProductTypes {
  all,
  new,
  used,
  certified,
  service,
  unknown
}

export class ProductTypesUtil {
  public static getName(value?: ProductTypes): string | undefined {
    let name;

    switch (value) {
      case ProductTypes.certified:
        name = "certified";
        break;
      case ProductTypes.new:
        name = "new";
        break;
      case ProductTypes.used:
        name = "used";
        break;
      case ProductTypes.unknown:
        name = "unknown";
        break;
      default:
        name = undefined;
        break;
    }

    return name;
  }
}