import React, { useEffect, useState } from "react";
import "./cdkROAssist.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import {
  activateDealerships,
  deactivateDealerships,
} from "../../actions/dealershipActions";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import moment from "moment-timezone";
import Util from "../../util/Util";
import { MODE, PERMISSIONS, SEARCH_OPERATIONS } from "../../util/Enums";
import { Calendar } from "primereact/calendar";
import { useTranslation } from "react-i18next";
import { IDS } from "../constants";
import { get, isArray, isEmpty } from "lodash";
import {
  DealershipCdkDataDto,
  DealershipCdkDataOpCodeCreateDto,
  IStore,
  IUser,
} from "../../../index.dts";
import {
  createLaborTypes,
  createServiceAdvisors,
  createServiceTechnicians,
  getAllCDKRecords,
  getLaborTypes,
  getRONumber,
  getServiceAdvisors,
  getServiceTechnician,
  updateLaborTypes,
  updateServiceAdvisors,
  updateServiceTechnicians,
} from "../../actions/cdkROassist";
import { Dialog } from "primereact/dialog";
import AsyncSelect from "react-select/async";
import { shallowEqual, useSelector } from "react-redux";
import { Sidebar } from "primereact/sidebar";
import CloseButton from "../../components/close_button/CloseButton";
import TagsInput from "react-tagsinput";
import { TAG_INPUT_PLACEHOLDERS } from "../../util/tags_input";
import TagsInputUtil from "../../util/tags_input/TagsInputUtil";
import { useForceUpdate } from "../../hooks/useForceUpdate";

let searchTimeout: any;
let searchTimeoutInterval: number = 0;
type status = "open" | "inProgress" | "waiting" | "closed" | "all";

const timezones: { label: string; value: any }[] = Util.getTimezones();
timezones.unshift({ label: "All", value: null });

export function CdkROAssist() {
  const { t }: any = useTranslation();
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  const user: IUser = useSelector(
    (store: IStore) => store.auth.user,
    shallowEqual
  );

  const isROAdmin =
    user?.role?.permissions?.filter(
      (permission) => permission?.name === "RO_ADMINISTRATOR"
    )?.length > 0;
  const isROAdvisor =
    user?.role?.permissions?.filter(
      (permission) => permission?.name === "RO_ADVISOR"
    )?.length > 0;
  const isROTech =
    user?.role?.permissions?.filter(
      (permission) => permission?.name === "RO_PARTS_TECH"
    )?.length > 0;

  const [sortMap] = useState<Map<string, { field: string; order: 1 | 0 | -1 }>>(
    new Map()
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState<number>(0);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [totalItemsCountOpcodes, setTotalItemsCountOpcodes] =
    useState<number>(0);
  const [selectedROs, setSelectedROs] = useState<any[]>([]);
  const [ROs, setROs] = useState<DealershipCdkDataDto[]>([]);

  const [roNumberFilterValue, setRoNumberFilterValue] = useState<string>("");
  const [roNumber, setRoNumber] = useState<string>("");
  const [customerFirstNameFilterValue, setCustomerFirstNameFilterValue] =
    useState<string>("");
  const [customerLastNameFilterValue, setCustomerLastNameFilterValue] =
    useState<string>("");
  const [addressFilterValue, setAddressFilterValue] = useState<string>("");
  const [yearFilterValue, setYearFilterValue] = useState<string>("");
  const [makeFilterValue, setMakeFilterValue] = useState<string>("");
  const [modelFilterValue, setModelFilterValue] = useState<string>("");
  const [vinFilterValue, setVinFilterValue] = useState<string>("");
  const [mileageInFilterValue, setMileageInFilterValue] = useState<string>("");
  const [mileageOutFilterValue, setMileageOutFilterValue] =
    useState<string>("");
  const [tagFilterValue, setTagFilterValue] = useState<string>("");
  const [lineFilterValue, setLineFilterValue] = useState<string>("");
  const [hourSoldFilterValue, setHourSoldFilterValue] = useState<string>("");
  const [laborSalesFilterValue, setLaborSalesFilterValue] =
    useState<string>("");
  const [salesFilterValue, setSalesFilterValue] = useState<string>("");
  const [techNumberFilterValue, setTechNumberFilterValue] =
    useState<string>("");
  const [laborTypeFilterValue, setLaborTypeFilterValue] = useState<string>("");
  const [paymentMethodFilterValue, setPaymentMethodFilterValue] =
    useState<string>("");
  const [shopSupplyFilterValue, setShopSupplyFilterValue] =
    useState<string>("");
  const [taxesFilterValue, setTaxesFilterValue] = useState<string>("");
  const [totalFilterValue, setTotalFilterValue] = useState<string>("");
  const [serviceAdvisorFilterValue, setServiceAdvisorFilterValue] =
    useState<string>("");
  const [serviceTechnicianFilterValue, setServiceTechnicianFilterValue] =
    useState<string>("");

  const [roOpcodes, setRoOpcodes] = useState<
    DealershipCdkDataOpCodeCreateDto[]
  >([]);
  const [openRoOpcodesModal, setOpenRoOpcodesModal] = useState<boolean>(false);
  const [opCodesFilterValue, setOpCodesFilterValue] = useState<string>("");

  const [roOpenedFilterValue, setRoOpenedFilterValue] = useState<string>("");
  const [roClosedFilterValue, setRoClosedFilterValue] = useState<string>("");
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [laborTypes, setLaborTypes] = useState<string>("");
  const [serviceAdvisors, setServiceAdvisors] = useState<string>("");
  const [serviceTechnicians, setServiceTechnicians] = useState<string>("");

  const [statusValue, setStatusValue] = useState<status>("all");

  useEffect(() => {
    sortMap.set("created", { field: "created", order: -1 });
    getRONumber(dealershipContext?.id).then((response) => {
      if (response?.data) {
        setRoNumber(response?.data?.currentRoNumber);
      }
    });
  }, [dealershipContext?.id]);

  useEffect(() => {
    searchTimeout = setTimeout(() => {
      getCDKRecords(0, limit).finally(() => {
        setPage(0);
        setFirst(0);
        searchTimeoutInterval = 300;
        setSelectedROs([]);
      });
    }, searchTimeoutInterval);

    return () => {
      clearTimeout(searchTimeout);
    };
    // eslint-disable-next-line
  }, [
    roNumberFilterValue,
    customerFirstNameFilterValue,
    customerLastNameFilterValue,
    addressFilterValue,
    yearFilterValue,
    makeFilterValue,
    modelFilterValue,
    vinFilterValue,
    mileageInFilterValue,
    mileageOutFilterValue,
    tagFilterValue,
    lineFilterValue,
    hourSoldFilterValue,
    laborSalesFilterValue,
    salesFilterValue,
    techNumberFilterValue,
    laborTypeFilterValue,
    paymentMethodFilterValue,
    shopSupplyFilterValue,
    taxesFilterValue,
    totalFilterValue,
    serviceAdvisorFilterValue,
    serviceTechnicianFilterValue,
    opCodesFilterValue,
    roOpenedFilterValue,
    roClosedFilterValue,
    statusValue,
    dealershipContext?.id,
  ]);

  const onClear = () => {
    sortMap.clear();
    setStatusValue("open");
    setRoNumberFilterValue("");
    setCustomerFirstNameFilterValue("");
    setCustomerLastNameFilterValue("");
    setAddressFilterValue("");
    setYearFilterValue("");
    setMakeFilterValue("");
    setModelFilterValue("");
    setVinFilterValue("");
    setMileageInFilterValue("");
    setMileageOutFilterValue("");
    setTagFilterValue("");
    setLineFilterValue("");
    setHourSoldFilterValue("");
    setLaborSalesFilterValue("");
    setSalesFilterValue("");
    setTechNumberFilterValue("");
    setLaborTypeFilterValue("");
    setPaymentMethodFilterValue("");
    setShopSupplyFilterValue("");
    setTaxesFilterValue("");
    setTotalFilterValue("");
    setServiceAdvisorFilterValue("");
    setServiceTechnicianFilterValue("");
    setRoOpenedFilterValue("");
    setRoClosedFilterValue("");
    // setCreatedFilterArray([]);
    // setUpdatedFilterArray([]);
  };

  const onNew = () => {
    history.push(Util.PATH_NAMES.CDK_RO_ASSIST_CREATE, { mode: MODE.NEW });
  };

  const onDefineVariables = () => {
    setShowSidebar(true);
  };

  const onHideSidebar = (): void => {
    setShowSidebar(false);
  };

  const onCreateNewVariable = () => {
    let laborTypesObj = {
      codes: laborTypes,
    };

    let serviceAdvisorsObj = {
      advisors: serviceAdvisors,
    };

    let serviceTechniciansObj = {
      technicians: serviceTechnicians,
    };

    getLaborTypes(dealershipContext?.id)
      .then((response) => {
        if (response?.data?.id) {
          updateLaborTypes(dealershipContext?.id, laborTypesObj).then(
            (response) => {
              Util.success("Labor types updated successfully!");
            }
          );
        }
      })
      .catch((e) => {
        createLaborTypes(dealershipContext?.id, laborTypesObj).then(
          (response) => {
            Util.success("Labor types created successfully!");
          }
        );
      });

    getServiceAdvisors(dealershipContext?.id)
      .then((response) => {
        if (response?.data?.id) {
          updateServiceAdvisors(dealershipContext?.id, serviceAdvisorsObj).then(
            (response) => {
              Util.success("Service Advisors updated successfully!");
            }
          );
        }
      })
      .catch((e) => {
        createServiceAdvisors(dealershipContext?.id, serviceAdvisorsObj).then(
          (response) => {
            Util.success("Service Advisors created successfully!");
          }
        );
      });

    getServiceTechnician(dealershipContext?.id)
      .then((response) => {
        if (response?.data?.id) {
          updateServiceTechnicians(
            dealershipContext?.id,
            serviceTechniciansObj
          ).then((response) => {
            Util.success("Service Technicians updated successfully!");
          });
        }
      })
      .catch((e) => {
        createServiceTechnicians(
          dealershipContext?.id,
          serviceTechniciansObj
        ).then((response) => {
          Util.success("Service Technicians created successfully!");
        });
      });

    setShowSidebar(false);
    forceUpdate();
  };

  const onEdit = () => {
    if (selectedROs.length > 0) {
      if (selectedROs[0].status === "closed") {
        Util.warning(t("dealerships.editRONotAvailable"));
      } else {
        history.push(Util.PATH_NAMES.CDK_RO_ASSIST_EDIT, {
          mode: MODE.EDIT,
          cdkRO: selectedROs[0],
        });
      }
    } else {
      Util.warning(t("dealerships.chooseRO"));
    }
  };

  const getStatusLabel = (statusValue: status): any => {
    switch (statusValue) {
      case "open":
        return "Open";
      case "inProgress":
        return "In Progress";
      case "waiting":
        return "Waiting";
      case "closed":
        return "Closed";
      default:
        return "";
    }
  };

  const getStatusFilter = (statusValue: status): any => {
    switch (statusValue) {
      case "open":
        return {
          field: "status",
          value: "open",
          operation: SEARCH_OPERATIONS.LIKE,
        };
      case "inProgress":
        return {
          field: "status",
          value: "inProgress",
          operation: SEARCH_OPERATIONS.LIKE,
        };
      case "waiting":
        return {
          field: "status",
          value: "waiting",
          operation: SEARCH_OPERATIONS.LIKE,
        };
      case "closed":
        return {
          field: "status",
          value: "closed",
          operation: SEARCH_OPERATIONS.LIKE,
        };
      case "all":
        return {
          field: "status",
          value: "",
          operation: SEARCH_OPERATIONS.LIKE,
        };
      default:
        return null;
    }
  };

  const getCDKRecords = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      const filter: any[] = [];
      const statusFilter = getStatusFilter(statusValue);

      if (statusFilter) {
        filter.push(statusFilter);
      }

      if (dealershipContext?.id) {
        filter.push({
          field: "dealership",
          value: dealershipContext?.id,
          operation: SEARCH_OPERATIONS.EQUAL,
        });
      }

      if (roNumberFilterValue) {
        filter.push({
          field: "roNumber",
          value: roNumberFilterValue,
          operation: SEARCH_OPERATIONS.EQUAL,
        });
      }

      if (customerFirstNameFilterValue) {
        filter.push({
          field: "customerFirstName",
          value: customerFirstNameFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (customerLastNameFilterValue) {
        filter.push({
          field: "customerLastName",
          value: customerLastNameFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (addressFilterValue) {
        filter.push({
          field: "address",
          value: addressFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (yearFilterValue) {
        filter.push({
          field: "year",
          value: yearFilterValue,
          operation: SEARCH_OPERATIONS.EQUAL,
        });
      }

      if (makeFilterValue) {
        filter.push({
          field: "make",
          value: makeFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (modelFilterValue) {
        filter.push({
          field: "model",
          value: modelFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (vinFilterValue) {
        filter.push({
          field: "vin",
          value: vinFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (mileageInFilterValue) {
        filter.push({
          field: "mileageIn",
          value: mileageInFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (mileageOutFilterValue) {
        filter.push({
          field: "mileageOut",
          value: mileageOutFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (tagFilterValue) {
        filter.push({
          field: "tag",
          value: tagFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (lineFilterValue) {
        filter.push({
          field: "line",
          value: lineFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (hourSoldFilterValue) {
        filter.push({
          field: "hourSold",
          value: hourSoldFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (laborSalesFilterValue) {
        filter.push({
          field: "laborSales",
          value: laborSalesFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (salesFilterValue) {
        filter.push({
          field: "sales",
          value: salesFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (techNumberFilterValue) {
        filter.push({
          field: "techNumber",
          value: techNumberFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (laborTypeFilterValue) {
        filter.push({
          field: "laborType",
          value: laborTypeFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (paymentMethodFilterValue) {
        filter.push({
          field: "paymentMethod",
          value: paymentMethodFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (shopSupplyFilterValue) {
        filter.push({
          field: "shopSupply",
          value: shopSupplyFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (taxesFilterValue) {
        filter.push({
          field: "taxes",
          value: taxesFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (totalFilterValue) {
        filter.push({
          field: "total",
          value: totalFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (serviceAdvisorFilterValue) {
        filter.push({
          field: "serviceAdvisor",
          value: serviceAdvisorFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (serviceTechnicianFilterValue) {
        filter.push({
          field: "serviceTechnician",
          value: serviceTechnicianFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (roOpenedFilterValue) {
        filter.push({
          field: "roOpened",
          value: moment(roOpenedFilterValue).format(Util.localDateTimeFormat),
          operation: SEARCH_OPERATIONS.EQUAL,
        });
      }

      if (roClosedFilterValue) {
        filter.push({
          field: "roClosed",
          value: moment(roClosedFilterValue).format(Util.localDateTimeFormat),
          operation: SEARCH_OPERATIONS.EQUAL,
        });
      }

      setLoading(true);
      getAllCDKRecords({
        filter: filter,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
        paging: { page: page, pageLimit: limit },
      })
        .then((response) => {
          setLoading(false);
          setROs(response.data.content);
          setTotalItemsCount(response.data.totalElements);

          resolve(response);
        })
        .catch((error) => {
          setLoading(false);
          Util.showError(error);

          reject(error);
        });
    });

  const onPage = (event: any) => {
    getCDKRecords(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
      setSelectedROs([]);
    });
  };

  const onSort = (data: any) => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getCDKRecords(page, limit);
    }
  };

  const onStateEditorChange = (e: any, rowData: any) => {
    if (!e.value) {
      rowData.deleted = false;
      onActivate(rowData.id);
    } else {
      rowData.deleted = true;
      onDeactivate(rowData.id);
    }
  };

  const onDeactivate = (id: number) => {
    setLoading(true);
    deactivateDealerships([id])
      .then(() => {
        setLoading(false);
        Util.success(t("dealerships.deactivatedSuccessfully"));
      })
      .catch((error) => {
        setLoading(false);
        Util.showError(error);
      });
  };

  const onActivate = (id: number) => {
    setLoading(true);
    activateDealerships([id])
      .then(() => {
        setLoading(false);
        Util.success(t("dealerships.activatedSuccessfully"));
      })
      .catch((error) => {
        setLoading(false);
        Util.showError(error);
      });
  };

  const roNumberBody = (rowData: any, roObj: any) => {
    return <p>{roNumber + roObj?.rowIndex}</p>;
  };

  const roNumberFilter = (
    <InputText
      style={{ width: "100%" }}
      value={roNumberFilterValue}
      onChange={(e: any) => {
        setRoNumberFilterValue(e.target.value || "");
      }}
    />
  );

  const customerFirstNameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={customerFirstNameFilterValue}
      onChange={(e: any) => {
        setCustomerFirstNameFilterValue(e.target.value || "");
      }}
    />
  );

  const customerLastNameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={customerLastNameFilterValue}
      onChange={(e: any) => {
        setCustomerLastNameFilterValue(e.target.value || "");
      }}
    />
  );

  const addressFilter = (
    <InputText
      style={{ width: "100%" }}
      value={addressFilterValue}
      onChange={(e: any) => {
        setAddressFilterValue(e.target.value || "");
      }}
    />
  );

  const yearFilter = (
    <InputText
      style={{ width: "100%" }}
      value={yearFilterValue}
      onChange={(e: any) => {
        setYearFilterValue(e.target.value || "");
      }}
    />
  );

  const makeFilter = (
    <InputText
      style={{ width: "100%" }}
      value={makeFilterValue}
      onChange={(e: any) => {
        setMakeFilterValue(e.target.value || "");
      }}
    />
  );

  const modelFilter = (
    <InputText
      style={{ width: "100%" }}
      value={modelFilterValue}
      onChange={(e: any) => {
        setModelFilterValue(e.target.value || "");
      }}
    />
  );

  const vinFilter = (
    <InputText
      style={{ width: "100%" }}
      value={vinFilterValue}
      onChange={(e: any) => {
        setVinFilterValue(e.target.value || "");
      }}
    />
  );

  const mileageInFilter = (
    <InputText
      style={{ width: "100%" }}
      value={mileageInFilterValue}
      onChange={(e: any) => {
        setMileageInFilterValue(e.target.value || "");
      }}
    />
  );

  const mileageOutFilter = (
    <InputText
      style={{ width: "100%" }}
      value={mileageOutFilterValue}
      onChange={(e: any) => {
        setMileageOutFilterValue(e.target.value || "");
      }}
    />
  );

  const tagFilter = (
    <InputText
      style={{ width: "100%" }}
      value={tagFilterValue}
      onChange={(e: any) => {
        setTagFilterValue(e.target.value || "");
      }}
    />
  );

  const lineFilter = (
    <InputText
      style={{ width: "100%" }}
      value={lineFilterValue}
      onChange={(e: any) => {
        setLineFilterValue(e.target.value || "");
      }}
    />
  );

  const hourSoldFilter = (
    <InputText
      style={{ width: "100%" }}
      value={hourSoldFilterValue}
      onChange={(e: any) => {
        setHourSoldFilterValue(e.target.value || "");
      }}
    />
  );

  const laborSalesFilter = (
    <InputText
      style={{ width: "100%" }}
      value={laborSalesFilterValue}
      onChange={(e: any) => {
        setLaborSalesFilterValue(e.target.value || "");
      }}
    />
  );

  const salesFilter = (
    <InputText
      style={{ width: "100%" }}
      value={salesFilterValue}
      onChange={(e: any) => {
        setSalesFilterValue(e.target.value || "");
      }}
    />
  );

  const techNumberFilter = (
    <InputText
      style={{ width: "100%" }}
      value={techNumberFilterValue}
      onChange={(e: any) => {
        setTechNumberFilterValue(e.target.value || "");
      }}
    />
  );

  const paymentMethodFilter = (
    <InputText
      style={{ width: "100%" }}
      value={paymentMethodFilterValue}
      onChange={(e: any) => {
        setPaymentMethodFilterValue(e.target.value || "");
      }}
    />
  );

  const shopSupplyFilter = (
    <InputText
      style={{ width: "100%" }}
      value={shopSupplyFilterValue}
      onChange={(e: any) => {
        setShopSupplyFilterValue(e.target.value || "");
      }}
    />
  );

  const taxesFilter = (
    <InputText
      style={{ width: "100%" }}
      value={taxesFilterValue}
      onChange={(e: any) => {
        setTaxesFilterValue(e.target.value || "");
      }}
    />
  );

  const totalFilter = (
    <InputText
      style={{ width: "100%" }}
      value={totalFilterValue}
      onChange={(e: any) => {
        setTotalFilterValue(e.target.value || "");
      }}
    />
  );

  const getCalendarValue = (value?: string): Date | undefined => {
    if (!value) {
      return;
    }

    return moment(value).toDate();
  };

  const dateBody = (data: any, field: string, formatter: string) => {
    const date = moment(data[field]);
    if (date.isValid()) {
      return date.format(formatter);
    }
    return null;
  };

  const onRoOpenedChange = (e: any): void => {
    if (e.value) {
      setRoOpenedFilterValue(
        moment(get(e, "value")).format(Util.localDateTimeFormat)
      );
    } else {
      setRoOpenedFilterValue("");
    }
  };

  const onRoClosedChange = (e: any): void => {
    if (e.value) {
      setRoClosedFilterValue(
        moment(get(e, "value")).format(Util.localDateTimeFormat)
      );
    } else {
      setRoClosedFilterValue("");
    }
  };

  const roOpenedFilter = (
    <Calendar
      style={{ width: "100%" }}
      inputId={"roOpenedInput"}
      inputStyle={{ width: "100%" }}
      value={getCalendarValue(roOpenedFilterValue)}
      onChange={onRoOpenedChange}
    />
  );

  const roClosedFilter = (
    <Calendar
      style={{ width: "100%" }}
      inputId={"roClosedInput"}
      inputStyle={{ width: "100%" }}
      value={getCalendarValue(roClosedFilterValue)}
      onChange={onRoClosedChange}
    />
  );

  const statusFilter = (
    <Dropdown
      value={statusValue}
      options={[
        { label: "All", value: "all" },
        { label: "Open", value: "open" },
        { label: "In Progress", value: "inProgress" },
        { label: "Waiting", value: "waiting" },
        { label: "Closed", value: "closed" },
      ]}
      style={{ width: "100%", boxShadow: "none" }}
      onChange={(e: any) => {
        setStatusValue(e.target.value);
      }}
    />
  );

  const promiseServiceAdvisors = (): any =>
    new Promise((resolve) => {
      getServiceAdvisors(dealershipContext?.id)
        .then((response) => {
          if (response?.data?.advisors) {
            setServiceAdvisors(response?.data?.advisors);
          }
          resolve(
            response?.data?.advisors?.split(",")?.map((item) => {
              return {
                label: item,
                value: item,
              };
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    });

  const promiseServiceTechnicians = (): any =>
    new Promise((resolve) => {
      getServiceTechnician(dealershipContext?.id)
        .then((response) => {
          if (response?.data?.technicians) {
            setServiceTechnicians(response?.data?.technicians);
          }
          resolve(
            response?.data?.technicians?.split(",")?.map((item) => {
              return {
                label: item,
                value: item,
              };
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    });

  const promiseLaborType = (): any =>
    new Promise((resolve) => {
      getLaborTypes(dealershipContext?.id)
        .then((response) => {
          if (response?.data?.codes) {
            setLaborTypes(response?.data?.codes);
          }
          resolve(
            response?.data?.codes?.split(",")?.map((item) => {
              return {
                label: item,
                value: item,
              };
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    });

  const laborTypeFilter = (
    <AsyncSelect
      cacheOptions
      defaultOptions
      isClearable={true}
      loadOptions={promiseLaborType}
      menuPosition={"fixed"}
      onChange={(e: any) => {
        setLaborTypeFilterValue(e?.value);
      }}
      placeholder={"All"}
      value={
        laborTypeFilterValue
          ? {
              label: laborTypeFilterValue,
              value: laborTypeFilterValue,
            }
          : null
      }
    />
  );

  const serviceAdvisorFilter = (
    <AsyncSelect
      cacheOptions
      defaultOptions
      isClearable={true}
      loadOptions={promiseServiceAdvisors}
      menuPosition={"fixed"}
      onChange={(e: any) => {
        setServiceAdvisorFilterValue(e?.value);
      }}
      placeholder={"All"}
      value={
        serviceAdvisorFilterValue
          ? {
              label: serviceAdvisorFilterValue,
              value: serviceAdvisorFilterValue,
            }
          : null
      }
    />
  );

  const serviceTechnicianFilter = (
    <AsyncSelect
      cacheOptions
      defaultOptions
      isClearable={true}
      loadOptions={promiseServiceTechnicians}
      menuPosition={"fixed"}
      onChange={(e: any) => {
        setServiceTechnicianFilterValue(e?.value);
      }}
      placeholder={"All"}
      value={
        serviceTechnicianFilterValue
          ? {
              label: serviceTechnicianFilterValue,
              value: serviceTechnicianFilterValue,
            }
          : null
      }
    />
  );

  // const createdFilter = (
  //   <Calendar
  //     style={{ width: "100%" }}
  //     inputId={"createdInput"}
  //     inputStyle={{ width: "100%" }}
  //     value={createdFilterArray}
  //     selectionMode="range"
  //     onChange={(e) => {
  //       if (e.value) {
  //         setCreatedFilterArray(e?.value);
  //       } else {
  //         setCreatedFilterArray([]);
  //         (document.getElementById("createdInput") as HTMLInputElement).value =
  //           "";
  //       }
  //     }}
  //   />
  // );

  // const updatedFilter = (
  //   <Calendar
  //     inputId={"updatedInput"}
  //     style={{ width: "100%" }}
  //     inputStyle={{ width: "100%" }}
  //     value={updatedFilterArray}
  //     selectionMode={"range"}
  //     onChange={(e): any => {
  //       if (e.value) {
  //         setUpdatedFilterArray(e?.value);
  //       } else {
  //         setUpdatedFilterArray([]);
  //         (document.getElementById("updatedInput") as HTMLInputElement).value =
  //           "";
  //       }
  //     }}
  //   />
  // );

  const createdTemplate = (data: any) => {
    return (
      <div>
        {moment(Util.utcToLocal(data.created)).format(Util.usDateTimeFormat)}
      </div>
    );
  };

  const updatedTemplate = (data: any) => {
    return (
      <div>
        {moment(Util.utcToLocal(data.updated)).format(Util.usDateTimeFormat)}
      </div>
    );
  };

  return (
    <div id={IDS.dealerships.wrapper}>
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div className="card card-w-title datatable-demo">
            <div
              className={
                "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"
              }
            >
              <DataTable
                resizableColumns={true}
                scrollable
                style={{ height: "auto" }}
                autoLayout={true}
                columnResizeMode={"expand"}
                rowsPerPageOptions={Util.rowsPerPageOptions}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                value={ROs}
                paginator={true}
                totalRecords={totalItemsCount}
                rows={limit}
                lazy={true}
                first={first}
                loading={loading}
                onPage={onPage}
                filterDisplay="row"
                selection={selectedROs}
                onSelectionChange={(e) => setSelectedROs(e.value)}
                onSort={onSort}
                sortMode={"multiple"}
                multiSortMeta={Array.from(sortMap.values())}
                header={
                  <div
                    className={"p-grid-header-components"}
                    style={{ minHeight: "40px" }}
                  >
                    <Button
                      id={IDS.button.clear}
                      style={{ position: "absolute", left: "10px" }}
                      icon={"pi pi-filter"}
                      label={t("clear")}
                      onClick={onClear}
                    />
                    <Button
                      id={IDS.button.new}
                      style={{ marginRight: 5 }}
                      icon={"pi pi-plus"}
                      label={t("new")}
                      onClick={onNew}
                    />
                    <Button
                      id={IDS.button.edit}
                      label={"Edit"}
                      style={{ marginRight: 5 }}
                      icon={"pi pi-pencil"}
                      onClick={onEdit}
                      disabled={
                        selectedROs.length !== 1 ||
                        (!isROAdmin &&
                          !isROTech &&
                          isROAdvisor &&
                          selectedROs[0]?.status === "closed")
                      }
                    />
                    {isROAdmin && (
                      <Button
                        id={IDS.button.new}
                        style={{ marginRight: 5 }}
                        icon={"pi pi-tags"}
                        label={t("cdkRO.defineVariables")}
                        onClick={onDefineVariables}
                      />
                    )}
                  </div>
                }
              >
                <Column
                  selectionMode="multiple"
                  style={{ width: "3em", flex: "0.5" }}
                />
                <Column
                  filter={true}
                  sortable={true}
                  field={"roNumber"}
                  //sortField={"roNumber"}
                  body={roNumberBody}
                  header={t("cdkRO.roNumber")}
                  filterElement={roNumberFilter}
                />
                <Column
                  field="customerFirstName"
                  header={t("cdkRO.customerFirstName")}
                  filter={true}
                  filterElement={customerFirstNameFilter}
                  sortable={true}
                />
                <Column
                  field="customerLastName"
                  header={t("cdkRO.customerLastName")}
                  filter={true}
                  filterElement={customerLastNameFilter}
                  sortable={true}
                />
                <Column
                  field="address"
                  header={t("cdkRO.address")}
                  filter={true}
                  filterElement={addressFilter}
                  sortable={true}
                />
                <Column
                  field="status"
                  header={t("status")}
                  filter={true}
                  body={(rowData: any) => {
                    return <p>{getStatusLabel(rowData?.status)}</p>;
                  }}
                  filterElement={statusFilter}
                  sortable={true}
                />
                <Column
                  field="laborType"
                  header={t("cdkRO.laborType")}
                  filter={true}
                  filterElement={laborTypeFilter}
                  style={{ minWidth: "150px" }}
                  sortable={true}
                />
                <Column
                  field="serviceAdvisor"
                  header={t("cdkRO.serviceAdvisor")}
                  filter={true}
                  filterElement={serviceAdvisorFilter}
                  style={{ minWidth: "150px" }}
                  sortable={true}
                />
                <Column
                  field="serviceTechnician"
                  header={t("cdkRO.serviceTechnician")}
                  filter={true}
                  filterElement={serviceTechnicianFilter}
                  style={{ minWidth: "150px" }}
                  sortable={true}
                />
                <Column
                  field="year"
                  header={t("cdkRO.year")}
                  filter={true}
                  filterElement={yearFilter}
                  sortable={true}
                />
                <Column
                  field="make"
                  header={t("cdkRO.make")}
                  filter={true}
                  filterElement={makeFilter}
                  sortable={true}
                />
                <Column
                  field="model"
                  header={t("cdkRO.model")}
                  filter={true}
                  filterElement={modelFilter}
                  sortable={true}
                />
                <Column
                  field="vin"
                  header={t("cdkRO.vin")}
                  filter={true}
                  filterElement={vinFilter}
                  sortable={true}
                />
                <Column
                  field="mileageIn"
                  header={t("cdkRO.mileageIn")}
                  filter={true}
                  filterElement={mileageInFilter}
                  sortable={true}
                />
                <Column
                  field="mileageOut"
                  header={t("cdkRO.mileageOut")}
                  filter={true}
                  filterElement={mileageOutFilter}
                  sortable={true}
                />
                <Column
                  field="tag"
                  header={t("cdkRO.tag")}
                  filter={true}
                  filterElement={tagFilter}
                  sortable={true}
                />
                <Column
                  field="roOpened"
                  header={t("cdkRO.roOpened")}
                  body={(data: any) =>
                    dateBody(data, "roOpened", Util.usDateTimeFormat)
                  }
                  filter={true}
                  filterElement={roOpenedFilter}
                  sortable={true}
                />
                <Column
                  field="roClosed"
                  header={t("cdkRO.roClosed")}
                  body={(data: any) =>
                    dateBody(data, "roClosed", Util.usDateTimeFormat)
                  }
                  filter={true}
                  filterElement={roClosedFilter}
                  sortable={true}
                />
                <Column
                  field="line"
                  header={t("cdkRO.line")}
                  filter={true}
                  filterElement={lineFilter}
                  sortable={true}
                />
                <Column
                  field="hourSold"
                  header={t("cdkRO.hourSold")}
                  filter={true}
                  filterElement={hourSoldFilter}
                  sortable={true}
                />
                <Column
                  field="laborSales"
                  header={t("cdkRO.laborSales")}
                  filter={true}
                  filterElement={laborSalesFilter}
                  sortable={true}
                />
                <Column
                  field="opCodes"
                  header={t("cdkRO.opCodes")}
                  filter={true}
                  body={(rowData: any) => {
                    const opcodes = rowData?.opCodes
                      .map((opcode) => opcode?.name)
                      ?.toString();
                    return (
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOpenRoOpcodesModal(true);
                          setRoOpcodes(rowData?.opCodes);
                          setTotalItemsCountOpcodes(rowData?.opCodes?.length);
                        }}
                      >
                        {opcodes}
                      </a>
                    );
                  }}
                  // filterElement={opCodesFilter}
                  sortable={true}
                />
                <Column
                  field="sales"
                  header={t("cdkRO.sales")}
                  filter={true}
                  filterElement={salesFilter}
                  sortable={true}
                />
                <Column
                  field="techNumber"
                  header={t("cdkRO.techNumber")}
                  filter={true}
                  filterElement={techNumberFilter}
                  sortable={true}
                />
                <Column
                  field="paymentMethod"
                  header={t("cdkRO.paymentMethod")}
                  filter={true}
                  filterElement={paymentMethodFilter}
                  sortable={true}
                />
                <Column
                  field="shopSupply"
                  header={t("cdkRO.shopSupply")}
                  filter={true}
                  filterElement={shopSupplyFilter}
                  sortable={true}
                />
                <Column
                  field="taxes"
                  header={t("cdkRO.taxes")}
                  filter={true}
                  filterElement={taxesFilter}
                  sortable={true}
                />
                <Column
                  field="total"
                  header={t("cdkRO.total")}
                  filter={true}
                  filterElement={totalFilter}
                  sortable={true}
                />

                {/* <Column
                  field="created"
                  header={t("created")}
                  filter={true}
                  filterElement={createdFilter}
                  sortable={true}
                  style={{ overflow: "visible" }}
                  body={createdTemplate}
                />
                <Column
                  field="updated"
                  header={t("updated")}
                  filter={true}
                  filterElement={updatedFilter}
                  sortable={true}
                  style={{ overflow: "visible" }}
                  body={updatedTemplate}
                /> */}
              </DataTable>
              <Dialog
                onHide={() => setOpenRoOpcodesModal(false)}
                visible={openRoOpcodesModal && roOpcodes?.length > 0}
                style={{ width: "80vw" }}
                header="RO Opcodes"
              >
                <DataTable
                  resizableColumns={true}
                  columnResizeMode={"expand"}
                  lazy={true}
                  value={roOpcodes}
                  rowsPerPageOptions={Util.rowsPerPageOptions}
                  // paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  // currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                  // paginator={true}
                  // rows={limit} //to be changed
                  totalRecords={totalItemsCountOpcodes}
                >
                  <Column sortable={false} field={"name"} header={"Name"} />
                  <Column
                    sortable={true}
                    field={"description"}
                    header={"Description"}
                  />
                  <Column
                    field={"parts"}
                    header={"Parts"}
                    body={(rowData: any) => {
                      return (
                        <div>
                          {" "}
                          {rowData?.parts?.map((part, index) => {
                            return (
                              <div
                                style={
                                  index < rowData?.parts?.length - 1
                                    ? {
                                        display: "flex",
                                        flexDirection: "column",
                                        borderBottom: "1px solid gray",
                                      }
                                    : {
                                        display: "flex",
                                        flexDirection: "column",
                                      }
                                }
                                key={index}
                              >
                                <p>Part name: {part?.name}</p>
                                <p>Quantity: {part?.quantity}</p>
                                <p>Part price: {part?.partPrice} $</p>
                              </div>
                            );
                          })}
                        </div>
                      );
                    }}
                  />
                  <Column
                    sortable={true}
                    field={"laborType"}
                    header={"Labor Type"}
                  />
                  <Column
                    sortable={true}
                    field={"parts"}
                    header={"Parts Total $"}
                    body={(rowData: any) => {
                      const partsTotal = rowData?.parts
                        ?.map((part) => {
                          const partPrice = parseFloat(
                            part?.partPrice.replace(",", ".")
                          );
                          const quantity = parseInt(part?.quantity);
                          return partPrice * quantity;
                        })
                        ?.reduce((a, b) => a + b, 0.0);

                      return (
                        <p>
                          {partsTotal ? "$" + partsTotal.toFixed(2) : "N/A"}
                        </p>
                      );
                    }}
                  />
                  <Column
                    sortable={true}
                    field={"laborCost"}
                    header={"Labor $"}
                    body={(rowData: any) => {
                      const laborCost = parseFloat(
                        rowData?.laborCost.replace(",", ".")
                      );
                      return (
                        <p>{laborCost ? "$" + laborCost.toFixed(2) : "N/A"}</p>
                      );
                    }}
                  />
                  <Column sortable={true} field={"total"} header={"Total $"} />
                </DataTable>
              </Dialog>
              <Sidebar
                style={{ width: 350 }}
                position={"right"}
                visible={showSidebar}
                dismissable={false}
                showCloseIcon={false}
                onHide={onHideSidebar}
              >
                <div style={{ width: "100%", height: "100%" }}>
                  <CloseButton onHide={onHideSidebar} />
                  <div
                    style={{
                      marginTop: 25,
                      marginLeft: -15,
                      marginRight: -15,
                      borderBottom: "1px solid #afa6a6",
                    }}
                  >
                    <label style={{ fontSize: 20, marginLeft: 15 }}>
                      Add/Remove variables
                    </label>
                  </div>
                  <div id={"new-role-container"}>
                    <div style={{ margin: 5 }} className={"label-container"}>
                      <label className={"input-label"}>Labor Type</label>
                    </div>
                    <div style={{ margin: 5 }}>
                      <TagsInput
                        onlyUnique={true}
                        inputProps={{
                          placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT,
                        }}
                        value={Util.getStringArray(laborTypes)}
                        onChange={(value: string[]) =>
                          setLaborTypes(TagsInputUtil.join(value))
                        }
                      />
                    </div>
                  </div>
                  <div id={"new-role-container"}>
                    <div style={{ margin: 5 }} className={"label-container"}>
                      <label className={"input-label"}>Service Advisor</label>
                    </div>
                    <div style={{ margin: 5 }}>
                      <TagsInput
                        onlyUnique={true}
                        inputProps={{
                          placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT,
                        }}
                        value={Util.getStringArray(serviceAdvisors)}
                        onChange={(value: string[]) =>
                          setServiceAdvisors(TagsInputUtil.join(value))
                        }
                      />
                    </div>
                  </div>
                  <div id={"new-role-container"}>
                    <div style={{ margin: 5 }} className={"label-container"}>
                      <label className={"input-label"}>
                        Service Technician
                      </label>
                    </div>
                    <div style={{ margin: 5 }}>
                      <TagsInput
                        onlyUnique={true}
                        inputProps={{
                          placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT,
                        }}
                        value={Util.getStringArray(serviceTechnicians)}
                        onChange={(value: string[]) =>
                          setServiceTechnicians(TagsInputUtil.join(value))
                        }
                      />
                    </div>
                  </div>

                  <Button
                    onClick={onCreateNewVariable}
                    style={{ float: "right" }}
                    className="no-icon-buttons"
                    label={"Save"}
                  />
                </div>
              </Sidebar>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CdkROAssist;
