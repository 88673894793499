import {BaseFileExportService} from "./FileDownloader";
import {downloadReport} from "../../actions/dashboardActions";
import {AxiosResponse} from "../../../index.dts";
import Util from "../../util/Util";
import {FILE_EXPORT_STATUS} from "../../util/Enums";
import store from "../../config/store";
import {removeProgress, setProgress} from "../../actions/mainActions";
import { downloadAcquisitionExport } from "../../actions/MyTrafficNotificationActions";

const fileDownload = require('js-file-download');

export class LeadFileDownloader extends BaseFileExportService {
  private warningAlertIsDisplayed = false;

  // eslint-disable-next-line
  constructor(reportId: string, prefix: string, suffix: string) {
    super(reportId, prefix, suffix);
  }

  //@Override
  download(callback?: () => void) {
    downloadReport(this.reportId)
      .then(response => {
        this.onResponse(response, callback);
      })
      .catch(error => {
        Util.showError(error)
      })
  }

  downloadAcquisitionExport(callback?: () => void) {
    downloadAcquisitionExport(this.reportId)
      .then(response => {
        this.onAcquisitionResponse(response, callback);
      })
      .catch(error => {
        Util.showError(error)
      })
  }

  private onAcquisitionResponse = async (response: AxiosResponse, callback?: () => void) => {
    const fileName = `${this.prefix}_leads_report-${this.suffix}`
    const contentType = response.headers["content-type"];

    if (contentType === "application/json") {
      const result = JSON.parse(await response.data.text());
      if (Util._.get(result, "status") === FILE_EXPORT_STATUS.NOT_READY && !this.warningAlertIsDisplayed) {
        this.warningAlertIsDisplayed = true;
        Util.warning("Please remain on the site. Your file will be downloaded shortly. You can check the progress in the profile menu");
      }

      const progress = Util._.get(result, "progress", 0);
      this.setProgress(progress, fileName);

      setTimeout(() => {
        this.downloadAcquisitionExport(callback);
      }, this.timeoutMs);

    } else {
      this.removeProgress();

      callback?.()

      if (contentType === "application/csv") {
        fileDownload(response.data, `${fileName}.csv`);
      } else {
        fileDownload(response.data, `${fileName}.zip`);
      }
    }
  }

  private onResponse = async (response: AxiosResponse, callback?: () => void) => {
    const fileName = `${this.prefix}_leads_report-${this.suffix}`
    const contentType = response.headers["content-type"];

    if (contentType === "application/json") {
      const result = JSON.parse(await response.data.text());
      if (Util._.get(result, "status") === FILE_EXPORT_STATUS.NOT_READY && !this.warningAlertIsDisplayed) {
        this.warningAlertIsDisplayed = true;
        Util.warning("Please remain on the site. Your file will be downloaded shortly. You can check the progress in the profile menu");
      }

      const progress = Util._.get(result, "progress", 0);
      this.setProgress(progress, fileName);

      setTimeout(() => {
        this.download(callback);
      }, this.timeoutMs);

    } else {
      this.removeProgress();

      callback?.()

      if (contentType === "application/csv") {
        fileDownload(response.data, `${fileName}.csv`);
      } else {
        fileDownload(response.data, `${fileName}.zip`);
      }
    }
  }

  private setProgress = (progress: number, fileName: string) => {
    store.dispatch(setProgress({
      id: this.reportId,
      name: fileName,
      value: progress
    }))
  }

  private removeProgress = () => {
    store.dispatch(removeProgress(this.reportId))
  }
}