import React, { useCallback, useEffect, useState } from "react";
import { Panel } from "primereact/panel";
import Util from "../../util/Util";
import { IAsset, IDealership, IOffer, IOfferCompact } from "../../../index.dts";
import { getOffer, getOfferList } from "../../actions/offerActions";
import moment from "moment-timezone";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import { Column } from "primereact/column";
import { OFFER_TYPES, PERMISSIONS } from "../../util/Enums";
import {
  updateDealershipOfferAsset,
  updateDealershipOfferExpiration,
  updateDealershipOfferLimit,
  updateDealershipOfferName,
  updateDealershipOfferState,
  updateDealershipOfferTerms,
} from "../../actions/supportPortalActions";
import { Button } from "primereact/button";
import CloseButton from "../../components/close_button/CloseButton";
import { Sidebar } from "primereact/sidebar";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import AssetsDataView from "./AssetsDataView";
import Select from "react-select";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import ImageUploading from "react-images-uploading";
import ImageIcon from "@mui/icons-material/Image";
import { ErrorsType, ImageListType } from "react-images-uploading/dist/typings";
import Axios from "../../config/Axios";
import { createAsset } from "../../actions/assetActions";
import { Dropdown } from "primereact/dropdown";
import { IDS } from "../constants";
import { useTranslation } from "react-i18next";
import "./modify_offers.css";
import { DateRangePickerWidget } from "../../components/data_range_picker/DateRangePickerWidget";
import { DateRangePeriods } from "../../components/data_range_picker/date_range_periods";

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

interface IProps {
  dealership: IDealership | undefined;
}

export function ModifyOffers({ dealership }: IProps) {
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();

  const [filterName, setFilterName] = useState("");
  const [filterTemplate, setFilterTemplate] = useState("");
  const [dealershipId, setDealershipId] = useState(
    Util._.get(dealership, "id", 0)
  );
  const [createdPeriod, setCreatedPeriod] = useState<{
    start?: Date;
    end?: Date;
  }>({
    start: undefined,
    end: undefined,
  });
  const [offerTypeFilterValue, setOfferTypeFilterValue] = useState<string>();

  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [page, setPage] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [sortMap] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(new Map());

  const [loading, setLoading] = useState<boolean>(false);
  const [offerList, setOfferList] = useState<IOfferCompact[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);

  const [offer, setOffer] = useState<IOffer>();
  const [compactOffer, setCompactOffer] = useState<IOfferCompact>();
  const [editExpirationDate, setEditExpirationDate] = useState<boolean>(false);
  const [editTermsAndConditions, setEditTermsAndConditions] =
    useState<boolean>(false);

  const [editAsset, setEditAsset] = useState<boolean>(false);

  const [editLimitOffer, setEditLimitOffer] = useState<boolean>(false);

  const [assetSidebarEnabled, setAssetSidebarEnabled] =
    useState<boolean>(false);
  const [newAssetImageFiles, setNewAssetImageFiles] = useState<ImageListType>(
    []
  );

  const onClear = () => {
    sortMap.clear();
    setFilterName("");
    setFilterTemplate("");
    setOfferTypeFilterValue(undefined);
    setCreatedPeriod({
      start: undefined,
      end: undefined,
    });
  };

  useEffect(() => {
    return () => {
      searchTimeoutInterval = 0;
    };
  }, []);

  useEffect(() => {
    const dId = Util._.get(dealership, "id", "");
    if (dealershipId !== dId) {
      searchTimeoutInterval = 0;
      setDealershipId(dId);
    }
    // eslint-disable-next-line
  }, [dealership]);

  useEffect(() => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getOffers(0, limit).finally(() => {
        setPage(0);
        setFirst(0);
        searchTimeoutInterval = 500;
      });
    }, searchTimeoutInterval);
    // eslint-disable-next-line
  }, [
    filterName,
    dealershipId,
    filterTemplate,
    createdPeriod,
    offerTypeFilterValue,
  ]);

  const setValue = useCallback(
    (field, value) => {
      if (offer) {
        if (value instanceof String) {
          setOffer({ ...offer, [field]: value.trim() });
        } else {
          setOffer({ ...offer, [field]: value });
        }
      }
    },
    [offer]
  );

  const setCompactOfferValue = useCallback(
    (field, value) => {
      if (compactOffer) {
        if (value instanceof String) {
          setCompactOffer({ ...compactOffer, [field]: value.trim() });
        } else {
          setCompactOffer({ ...compactOffer, [field]: value });
        }
      }
    },
    [compactOffer]
  );

  const getOffers = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      getOfferList({
        pageLimit: limit,
        pageNumber: page,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
        name: filterName,
        offerType: Util.getDefaultIfNull(offerTypeFilterValue, undefined),
        templateName: filterTemplate,
        dealershipIds: [dealershipId],
        toDate: createdPeriod.end
          ? moment(createdPeriod.end).format(Util.localDateTimeFormat)
          : undefined,
        fromDate: createdPeriod.start
          ? moment(createdPeriod.start).format(Util.localDateTimeFormat)
          : undefined,
      })
        .then((response) => {
          resolve(response);
          setOfferList(response.data.content);
          setTotalItemsCount(response.data.totalElements);
        })
        .catch((error) => {
          reject(error);
          Util.showError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const onPage = (event: any) => {
    getOffers(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
    });
  };

  const onSort = (data: any) => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getOffers(page, limit);
    }
  };

  const nameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={filterName}
      onChange={(e: any) => setFilterName(e.target.value.trim())}
    />
  );

  const templateFilter = (
    <InputText
      style={{ width: "100%" }}
      value={filterTemplate}
      onChange={(e: any) => setFilterTemplate(e.target.value.trim())}
    />
  );

  function onCreatedPeriodChange(start?: Date, end?: Date): void {
    setCreatedPeriod({
      start: start ? moment(start).set({ hour: 0, minute: 0, second: 0 }).toDate() : undefined,
      end: end ? moment(end).set({ hour: 23, minute: 59, second: 59 }).toDate() : undefined,
    });
  }

  const createdFilter = (
    <DateRangePickerWidget
      start={createdPeriod.start}
      end={createdPeriod.end}
      ranges={DateRangePeriods.options} // Replace 'Options' with the range definitions
      onChange={onCreatedPeriodChange}
    />
  );

  const createdOffer = (data: any) => {
    return (
      <div>
        {moment(Util.utcToLocal(data.created)).format(Util.usDateTimeFormat)}
      </div>
    );
  };

  const actionsTemplate = (data: IOfferCompact) => {
    return (
      <div>
        <Button
          icon={"pi pi-image"}
          title={"Change Asset Image"}
          onClick={() => onOpenAssetSlide(data)}
          disabled={
            !Util.hasAnyAuthority(PERMISSIONS.DEALERSHIP_OFFER_ASSET_UPDATE)
          }
        />
        <Button
          icon={data.active ? "pi pi-times" : "pi pi-undo"}
          className={data.active ? "p-button-danger" : "p-button-success"}
          title={data.active ? "Deactivate Offer" : "Activate Offer"}
          onClick={() => onActiveChange(data)}
          disabled={
            !Util.hasAnyAuthority(PERMISSIONS.DEALERSHIP_OFFER_STATUS_UPDATE)
          }
        />
        <Button
          icon={"pi pi-calendar"}
          title={"Set Expiration Date"}
          className={"p-button-warning"}
          onClick={() => onOpenExpirationDateSlide(data)}
          disabled={
            !Util.hasAnyAuthority(
              PERMISSIONS.DEALERSHIP_OFFER_EXPIRATION_UPDATE
            )
          }
        />
        <Button
          icon={"pi pi-exclamation-triangle"}
          title={"Limit Offer"}
          className={"p-button-info"}
          onClick={() => onOpenLimitOfferSlide(data)}
          disabled={
            !Util.hasAnyAuthority(PERMISSIONS.DEALERSHIP_OFFER_LIMITATION)
          }
        />
        <Button
          icon={"pi pi-list"}
          title={"Edit Terms And Conditions"}
          className={"p-button-secondary"}
          onClick={() => onOpenEditTermsAndConditionsSlide(data)}
          disabled={
            !Util.hasAnyAuthority(PERMISSIONS.DEALERSHIP_OFFER_TERMS_UPDATE)
          }
        />
      </div>
    );
  };

  const onOpenLimitOfferSlide = (data: IOfferCompact): void => {
    getOfferById(data.id).then(() => setEditLimitOffer(true));
  };

  const onOpenEditTermsAndConditionsSlide = (data: IOfferCompact) => {
    getOfferById(data.id).then(() => setEditTermsAndConditions(true));
  };

  const onOpenAssetSlide = (data: IOfferCompact): void => {
    setEditAsset(true);
    setCompactOffer(data);
  };

  const onError = (errors: ErrorsType, files?: ImageListType): void => {
    if (errors?.maxFileSize) {
      Util.warning("File size exceeds the maximum allowed size.");
    }
  };

  const onOpenExpirationDateSlide = (data: IOfferCompact): void => {
    setCompactOffer(data);
    setEditExpirationDate(true);
  };

  const getOfferById = (id: number) =>
    new Promise((resolve, reject) => {
      Util.globalSpinner().show();
      getOffer(id)
        .then((response) => {
          resolve(response);
          setOffer(response.data);
        })
        .catch((error) => {
          reject(error);
          Util.showError(error);
        })
        .finally(() => Util.globalSpinner().hide());
    });

  const activeTemplate = (data: IOffer): React.ReactElement => {
    if (data.active) {
      return <div className={"status-running"}>Running</div>;
    }
    return <div className={"status-deactivate"}>Deactivated</div>;
  };

  const expiryTemplate = (data: IOffer): React.ReactElement => {
    if (data.expirable) {
      return (
        <div className={"expiry"}>
          {moment(data.endDate).format(Util.usDateFormat)}
        </div>
      );
    }
    return <div className={"non-expiry"}>Not expirable</div>;
  };

  const onActiveChange = (data: IOfferCompact) => {
    if (dealership) {
      data.active = !data.active;
      updateDealershipOfferState(dealership.id, data.id, data.active)
        .then(() => {
          Util.success(
            `Offer ${data.active ? "activated" : "deactivated"} successfully.`
          );
        })
        .catch((error) => {
          Util.showError(error);
          data.active = !data.active;
          forceUpdate();
        });
      forceUpdate();
    }
  };

  const onHideExpirationDateSidebar = (): void => {
    setOffer(undefined);
    setEditExpirationDate(false);
  };

  const onSaveExpirationDate = (): void => {
    if (!dealership || !compactOffer) {
      Util.warning(Util.defaultErrorMessage);
      return;
    }

    if (compactOffer.expirable && Util.isEmpty(compactOffer.endDate)) {
      Util.warning("Invalid end date");
      return;
    }

    const endDate = compactOffer.expirable
      ? moment(compactOffer.endDate)
      : null;
    const startDate = moment(compactOffer.startDate);
    const expirable = compactOffer.expirable;
    const notificationEmail = compactOffer.notificationEmail;

    if (endDate && endDate.isBefore(startDate)) {
      Util.warning("Invalid date range");
      return;
    }

    const endDateFormated = endDate
      ? endDate.format(Util.localDateFormat)
      : null;
    const startDateFormated = startDate.format(Util.localDateFormat);

    Util.globalSpinner().show();
    updateDealershipOfferExpiration(
      dealership.id,
      compactOffer.id,
      startDateFormated,
      endDateFormated,
      expirable,
      notificationEmail
    )
      .then(() => {
        getOffers(page, limit);
        onHideExpirationDateSidebar();

        Util.success("Offer Dates updated successfully.");
      })
      .catch((error) => Util.showError(error))
      .finally(() => Util.globalSpinner().hide());
  };

  const onHideTermsAndConditionsSidebar = (): void => {
    setOffer(undefined);
    setEditTermsAndConditions(false);
  };

  const onSaveTermsAndConditions = (): void => {
    if (dealership && offer) {
      Util.globalSpinner().show();
      updateDealershipOfferTerms(
        dealership.id,
        offer.id,
        offer.termsAndConditions
      )
        .then(() => {
          onHideTermsAndConditionsSidebar();
          Util.success("Terms & Conditions successfully updated.");
        })
        .catch((error) => Util.showError(error))
        .finally(() => Util.globalSpinner().hide());
    }
  };

  const assetTemplate = (rowData: IOfferCompact) => {
    const getSrc = (): string => {
      if (rowData.asset) {
        return Util.getAssetUrl(Util._.get(rowData, "asset.objectId", null));
      }
      if (rowData.template.assetObjectId) {
        return Util.getAssetUrl(
          Util._.get(rowData, "template.assetObjectId", null)
        );
      }
      return "";
    };

    return (
      <React.Fragment>
        <img
          alt={"..."}
          src={getSrc()}
          width="45%"
          style={{ verticalAlign: "middle", minWidth: 95 }}
        />
      </React.Fragment>
    );
  };

  const onHideAssetSidebar = (): void => {
    setEditAsset(false);
    setCompactOffer(undefined);
  };

  const onSaveAsset = (asset: IAsset) => {
    if (dealership && compactOffer) {
      Util.globalSpinner().show();
      updateDealershipOfferAsset(dealership.id, compactOffer.id, asset)
        .then(() => {
          onHideAssetSidebar();
          getOffers(page, limit);
          Util.success("Offer Asset updated successfully.");
        })
        .catch((error) => Util.showError(error))
        .finally(() => Util.globalSpinner().hide());
    } else {
      Util.warning(Util.defaultErrorMessage);
    }
  };

  const onHideLimitOfferSidebar = (): void => {
    setOffer(undefined);
    setEditLimitOffer(false);
  };

  const getDeviceType = (): any => {
    if (offer) {
      const entry = Object.entries(OFFER_TYPES).find(
        (entry) => entry[1] === offer.offerType
      );
      if (entry) {
        return {
          label: entry[0],
          value: entry[1],
        };
      }
    }
    return undefined;
  };

  const onSaveLimitOffer = () => {
    if (dealership && offer) {
      Util.globalSpinner().show();
      updateDealershipOfferLimit(dealership.id, offer)
        .then(() => {
          onHideLimitOfferSidebar();
          Util.success("Offer Limit updated successfully.");
        })
        .catch((error) => Util.showError(error))
        .finally(() => Util.globalSpinner().hide());
    } else {
      Util.warning(Util.defaultErrorMessage);
    }
  };

  const onHideUploadAssetSidebar = (): void => {
    setNewAssetImageFiles([]);
    setAssetSidebarEnabled(false);
  };

  const onUploadAsset = (): void => {
    if (newAssetImageFiles.length > 0) {
      if (dealership && dealership.id !== 0) {
        Util.globalSpinner().show();
        Axios.all(
          newAssetImageFiles.map((File) => {
            const formData = new FormData();
            //@ts-ignore
            formData.append("file", File?.file);
            return createAsset(dealership.id, formData);
          })
        )
          .then(() => {
            Util.success(
              `Asset${
                newAssetImageFiles.length > 1 ? "s" : ""
              } uploaded successfully.`
            );

            onHideUploadAssetSidebar();
          })
          .catch((error) => {
            Util.showError(error);
          })
          .finally(() => {
            Util.globalSpinner().hide();
          });
      }
    } else {
      Util.warning("Please attach an image.");
    }
  };

  const handleKeypress = (e, props) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      updateOfferName(props.rowData);
    }
  };

  const offerNameEditor = (props: any) => {
    return (
      <div className="p-inputgroup edit-offer-name">
        <InputText
          placeholder="Offer Name"
          value={props.value}
          onChange={(e: any) => {
            props.editorCallback(e.target.value);
          }}
          onKeyDown={(e: any) => handleKeypress(e, props)}
        />
        <Button
          style={{ width: 30, minWidth: 30 }}
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            document.getElementById("outside-element")?.click();
            updateOfferName(props.rowData);
          }}
        />
      </div>
    );
  };

  const updateOfferName = (compactOffer: IOfferCompact) => {
    if (!dealership || !compactOffer) {
      Util.warning(Util.defaultErrorMessage);
      return;
    }

    const offerId = Util._.get(compactOffer, "id", null);
    const dealershipId = Util._.get(dealership, "id", null);

    Util.globalSpinner().show();
    updateDealershipOfferName(dealershipId, offerId, compactOffer.name)
      .then(() => {
        getOffers(page, limit);
        Util.success("Offer Name updated successfully.");
      })
      .catch((error) => Util.error(error))
      .finally(() => Util.globalSpinner().hide());
  };

  const offerTypeBody = (data: IOffer) => {
    return <div>{Util.getEnumKeyByValue(OFFER_TYPES, data.offerType)}</div>;
  };

  const offerTypeFilter = (
    <Dropdown
      showClear={true}
      value={offerTypeFilterValue}
      options={Object.entries(OFFER_TYPES).map((entry) => {
        return {
          label: entry[0],
          value: entry[1],
        };
      })}
      style={{ width: "100%", boxShadow: "none", backgroundColor: "#FFFFFF" }}
      onChange={(e: any) => {
        setOfferTypeFilterValue(e.target.value);
      }}
    />
  );

  const renderer = (): any => {
    const notificationEmail = Util.getDefaultIfNull(
      Util._.get(compactOffer, "notificationEmail", null),
      ""
    );
    const endDate = moment(Util._.get(compactOffer, "endDate", null));
    const startDate = moment(Util._.get(compactOffer, "startDate", null));
    const expirable = Util._.get(compactOffer, "expirable", false);

    return (
      <Panel
        header={
          <div style={{ display: "flex" }} id="outside-element">
            <label>Modify Offers</label>
            {Util.hasAnyAuthority(PERMISSIONS.ASSETS_MANAGEMENT) ? (
              <div className={"p-grid-header-components"}>
                <Button
                  disabled={!dealership || dealership.id === 0}
                  onClick={() => setAssetSidebarEnabled(true)}
                  icon={"pi pi-plus"}
                  label={"Upload Asset"}
                />
              </div>
            ) : null}
          </div>
        }
      >
        <div className={"p-grid"}>
          <div className="p-col-12">
            <div className="card card-w-title datatable-demo">
              <div className="p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows">
                <DataTable
                  loading={loading}
                  dataKey="id"
                  lazy={true}
                  resizableColumns={true}
                  columnResizeMode={"expand"}
                  autoLayout={true}
                  rowsPerPageOptions={Util.rowsPerPageOptions}
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                  rows={limit}
                  first={first}
                  value={offerList}
                  paginator={true}
                  filterDisplay="row"
                  sortMode={"multiple"}
                  onPage={onPage}
                  onSort={onSort}
                  multiSortMeta={Array.from(sortMap.values())}
                  totalRecords={totalItemsCount}
                  header={
                    <div
                      style={{ minHeight: "30px" }}
                      className={"p-grid-header-components"}
                    >
                      <Button
                        id={IDS.button.clear}
                        style={{ position: "absolute", left: "10px" }}
                        icon={"pi pi-filter"}
                        label={t("clear")}
                        onClick={onClear}
                      />
                    </div>
                  }
                >
                  <Column
                    field="name"
                    header="Name"
                    filter={true}
                    sortable={true}
                    className="editable-field"
                    style={{ minWidth: "90px" }}
                    filterElement={nameFilter}
                    editor={
                      Util.hasAnyAuthority(
                        PERMISSIONS.DEALERSHIP_OFFER_TERMS_UPDATE
                      )
                        ? offerNameEditor
                        : undefined
                    }
                  />
                  <Column
                    field="template.name"
                    header="Template"
                    style={{ minWidth: "90px" }}
                    filter={true}
                    sortable={true}
                    filterElement={templateFilter}
                  />
                  <Column
                    field="created"
                    header="Created"
                    style={{ minWidth: "90px" }}
                    filter={true}
                    sortable={true}
                    body={createdOffer}
                    filterElement={createdFilter}
                  />
                  <Column
                    field="offerType"
                    header="Offer Type"
                    style={{ minWidth: "80px" }}
                    filter={true}
                    sortable={true}
                    filterElement={offerTypeFilter}
                    body={offerTypeBody}
                  />
                  <Column
                    header="Asset"
                    style={{ minWidth: "90px" }}
                    filter={false}
                    sortable={false}
                    body={assetTemplate}
                  />
                  <Column
                    field="active"
                    header="Status"
                    style={{ minWidth: "90px" }}
                    filter={false}
                    sortable={true}
                    body={activeTemplate}
                  />
                  <Column
                    field="endDate"
                    header="Expiry"
                    style={{ minWidth: "90px" }}
                    filter={false}
                    sortable={true}
                    body={expiryTemplate}
                  />
                  <Column
                    header={"Actions"}
                    filter={false}
                    style={{ minWidth: "210px" }}
                    sortable={false}
                    body={actionsTemplate}
                  />
                </DataTable>
              </div>
            </div>
          </div>
        </div>
        <Sidebar
          style={{ width: 350 }}
          position={"right"}
          visible={editExpirationDate}
          onHide={onHideExpirationDateSidebar}
          showCloseIcon={false}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <CloseButton onHide={onHideExpirationDateSidebar} />
            <div
              style={{
                marginTop: 25,
                marginLeft: -15,
                marginRight: -15,
                borderBottom: "1px solid #afa6a6",
              }}
            >
              <label style={{ fontSize: 20, marginLeft: 20 }}>
                Edit Expiration Date
              </label>
            </div>
            <div style={{ padding: 5, width: "100%" }}>
              <div className={"switch-container"}>
                <label className={"input-switch-label"}>Expirable</label>
                <InputSwitch
                  checked={expirable}
                  onChange={(e) => setCompactOfferValue("expirable", e.value)}
                />
              </div>
              {expirable ? (
                <div>
                  <div style={{ width: "100%" }}>
                    <span>Start Date</span>
                    <Calendar
                      readOnlyInput={true}
                      style={{ width: "100%" }}
                      inputStyle={{ width: "100%" }}
                      value={startDate.toDate()}
                      maxDate={endDate.toDate()}
                      onChange={(e: any) =>
                        setCompactOfferValue(
                          "startDate",
                          moment(e.value).format(Util.localDateFormat)
                        )
                      }
                    />
                  </div>
                  <div style={{ marginTop: 10, width: "100%" }}>
                    <span>End Date</span>
                    <Calendar
                      readOnlyInput={true}
                      style={{ width: "100%" }}
                      inputStyle={{ width: "100%" }}
                      value={endDate.toDate()}
                      minDate={startDate.toDate()}
                      onChange={(e: any) =>
                        setCompactOfferValue(
                          "endDate",
                          moment(e.value).format(Util.localDateFormat)
                        )
                      }
                    />
                  </div>
                </div>
              ) : null}
              <div style={{ marginTop: 10, width: "100%" }}>
                <span>Notification Email</span>
                <InputText
                  style={{ width: "100%" }}
                  value={notificationEmail}
                  placeholder={"Notification Email"}
                  disabled={!expirable}
                  maxLength={100}
                  onChange={(e: any) =>
                    setCompactOfferValue("notificationEmail", e.target.value)
                  }
                />
                {expirable && endDate.isValid() ? (
                  <label style={{ marginTop: 10 }}>
                    This Offer will expire on{" "}
                    <span
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {endDate.format(Util.usDateFormat)}
                    </span>{" "}
                    and a notification TWO DAYS prior to this expiration date
                    will be sent to:{" "}
                    <span
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {!Util.isEmpty(notificationEmail)
                        ? notificationEmail
                        : "No Notification Email Set"}
                    </span>
                  </label>
                ) : null}
              </div>
              <Button
                label={"Save"}
                style={{ float: "right", marginTop: 5 }}
                onClick={onSaveExpirationDate}
              />
            </div>
          </div>
        </Sidebar>
        <Sidebar
          style={{ width: 350 }}
          position={"right"}
          visible={editTermsAndConditions}
          onHide={onHideTermsAndConditionsSidebar}
          showCloseIcon={false}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <CloseButton onHide={onHideTermsAndConditionsSidebar} />
            <div
              style={{
                marginTop: 25,
                marginLeft: -15,
                marginRight: -15,
                borderBottom: "1px solid #afa6a6",
              }}
            >
              <label style={{ fontSize: 20, marginLeft: 20 }}>
                Edit Terms & Conditions
              </label>
            </div>
            <div style={{ padding: 5, height: "100%", width: "100%" }}>
              <label>Offer Name: {offer?.name}</label>
              <div style={{ width: "100%" }}>
                <InputTextarea
                  value={offer?.termsAndConditions}
                  onChange={(e: any) =>
                    setValue("termsAndConditions", e.target.value)
                  }
                  style={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    maxHeight: 400,
                    minHeight: 50,
                    marginTop: 5,
                    width: "100%",
                  }}
                />
              </div>
              <div className={"sidebar-item"}>
                <Button
                  label={"Save"}
                  style={{ float: "right" }}
                  onClick={onSaveTermsAndConditions}
                />
              </div>
            </div>
          </div>
        </Sidebar>
        <Sidebar
          style={{ width: 350 }}
          position={"right"}
          visible={editAsset}
          onHide={onHideAssetSidebar}
          showCloseIcon={false}
        >
          {editAsset ? (
            <div style={{ width: "100%", height: "100%" }}>
              <CloseButton onHide={onHideAssetSidebar} />
              <div
                style={{
                  marginTop: 25,
                  marginLeft: -15,
                  marginRight: -15,
                  borderBottom: "1px solid #afa6a6",
                }}
              >
                <label style={{ fontSize: 20, marginLeft: 20 }}>
                  Change Asset
                </label>
              </div>
              <div
                style={{
                  padding: 5,
                  height: "calc(100% - 50px)",
                  overflowY: "auto",
                  width: "100%",
                }}
              >
                <AssetsDataView onChange={onSaveAsset} />
              </div>
            </div>
          ) : null}
        </Sidebar>
        <Sidebar
          style={{ width: 350 }}
          position={"right"}
          visible={editLimitOffer}
          onHide={onHideLimitOfferSidebar}
          showCloseIcon={false}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <CloseButton onHide={onHideLimitOfferSidebar} />
            <div
              style={{
                marginTop: 25,
                marginLeft: -15,
                marginRight: -15,
                borderBottom: "1px solid #afa6a6",
              }}
            >
              <label style={{ fontSize: 20, marginLeft: 20 }}>
                Limit Offer
              </label>
            </div>
            <div
              style={{
                padding: 5,
                height: "calc(100% - 50px)",
                overflowY: "auto",
                width: "100%",
              }}
            >
              <Select
                value={getDeviceType()}
                options={Object.entries(OFFER_TYPES).map((entry) => {
                  return {
                    label: entry[0],
                    value: entry[1],
                  };
                })}
                onChange={(e: any) => setValue("offerType", e.value)}
              />
              <div className={"sidebar-item"}>
                <Button
                  label={"Save"}
                  style={{ float: "right" }}
                  onClick={onSaveLimitOffer}
                />
              </div>
            </div>
          </div>
        </Sidebar>
        <Sidebar
          style={{ width: 350 }}
          position={"right"}
          visible={assetSidebarEnabled}
          onHide={onHideUploadAssetSidebar}
          showCloseIcon={false}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <CloseButton onHide={onHideUploadAssetSidebar} />
            <div
              style={{
                marginTop: 25,
                marginLeft: -15,
                marginRight: -15,
                borderBottom: "1px solid #afa6a6",
              }}
            >
              <label style={{ fontSize: 20, marginLeft: 20 }}>
                Import Asset
              </label>
            </div>
            <div
              style={{
                padding: 5,
                height: "100%",
                width: "100%",
                textAlign: "center",
              }}
            >
              <span style={{ color: "red", fontWeight: 700 }}>
                Caution, using any NON-STANDARD BEST PRACTICE creative may
                degrade lead volume and/or lead close rate performance.
              </span>
              {/* <ImageUploader
                ref={imageUploaderRef}
                withIcon={true}
                singleImage={false}
                buttonText='Choose image'
                onChange={(files: File[]) => setNewAssetImageFiles(files)}
                imgExtension={['.jpg', '.png', '.gif', '.svg', '.jpeg']}
                maxFileSize={2000000}
                withPreview={true}
                label={"Max file size: 2mb"}
                accept={".gif, .jpg, .png, .svg, .jpeg"}
              /> */}
              <ImageUploading
                multiple
                value={newAssetImageFiles}
                onChange={(files: ImageListType) =>
                  setNewAssetImageFiles(files)
                }
                maxNumber={20}
                dataURLKey="data_url"
                maxFileSize={2000000}
                onError={onError}
                acceptType={["jpg", "gif", "png", "svg", "jpeg"]}
              >
                {({ imageList, onImageUpload }) => (
                  <div
                    style={{
                      borderRadius: "4px",
                      borderBottom: "1px solid #c8c8c8",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <ImageIcon fontSize={"large"} />
                    <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                      Max file size: 2mb, accepted: jpg|gif|png|svg|jpeg
                    </span>
                    <Button
                      className="no-icon-buttons"
                      label={"Choose Image"}
                      onClick={() => onImageUpload()}
                    />
                    {imageList?.length > 0 &&
                      imageList?.map((image, index) => (
                        <div key={index} className="uploadPictureContainer">
                          <img
                            src={image["data_url"]}
                            alt=""
                            style={{
                              width: "320px",
                              height: "220px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      ))}
                  </div>
                )}
              </ImageUploading>
              <Button
                label={"Upload"}
                style={{ float: "right" }}
                onClick={onUploadAsset}
                icon={"pi pi-upload"}
              />
            </div>
          </div>
        </Sidebar>
      </Panel>
    );
  };

  return renderer();
}

export default ModifyOffers;
