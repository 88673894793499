import "./user_notifications.css";

import { IMyTrafficNotificationLog, IStore } from "../../../index.dts";
import { NOTIFICATION_ACTION_TEXT, NotificationType } from "../../util/Enums";
import React, { useEffect, useRef, useState } from "react";
import {
  clearNotifications,
  getMyNotifications,
  getMyNotificationsCount,
} from "../../actions/userActions";
import { shallowEqual, useSelector } from "react-redux";

import InfiniteScroll from "react-infinite-scroll-component";
import NotificationBadge from "../notification_badge/NotificationBadge";
import Util from "../../util/Util";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import NotificationService from "../../services/notification/NotificationService";
import Menu from "@mui/material/Menu";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import useWindowSize from "../../hooks/useWindowSize";

const UserNotifications = React.memo(() => {
  const size = useWindowSize();
  const forceUpdate = useForceUpdate();
  const notificationRef = useRef<any>();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [pending, setPending] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);

  const [hasMore, setHasMore] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);
  const [limit] = useState<number>(10);

  const [notifications] = useState<Map<number, IMyTrafficNotificationLog>>(
    new Map()
  );
  const [unreadNotificationCount, setUnreadNotificationCount] =
    useState<number>(0);

  const notificationService = useSelector(
    (store: IStore) => store.myTraffic.notificationService,
    shallowEqual
  );
  const notificationsRefreshKey = useSelector(
    (store: IStore) => store.myTraffic.notificationsRefreshKey,
    shallowEqual
  );
  let isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMountedRef.current) {
      getNotificationCount();
      notifications.clear();
      getNotifications(0, limit).finally(() => {
        setPage(0);
      });
    }
    // eslint-disable-next-line
  }, [notificationsRefreshKey]);

  const getNotificationCount = () =>
    new Promise((resolve, reject) => {
      getMyNotificationsCount()
        .then((response) => {
          if (isMountedRef.current) {
            resolve(void 0);
            setUnreadNotificationCount(Util._.get(response, "data", 0));
          }
        })
        .catch(() => {
          reject();
        });
    });

  const getNotifications = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      getMyNotifications({
        filter: [],
        sorting: [{ field: "created", direction: "desc" }],
        paging: {
          page: page,
          pageLimit: limit,
        },
      })
        .then((response) => {
          if (isMountedRef.current) {
            response.data.content.forEach((item) => {
              notifications.set(item.id, item);
            });

            forceUpdate();

            setHasMore(response.data.totalPages !== response.data.number);

            resolve(response);
          }
        })
        .catch((error) => {
          console.error(error);

          resolve(error);
        });
    });

  const getNotificationActionText = (
    notification: IMyTrafficNotificationLog
  ): string => {
    switch (notification.type) {
      case NotificationType.CHATBOT:
      case NotificationType.PERSISTENT_CHAT:
        return NOTIFICATION_ACTION_TEXT.JOIN_CHAT;
      case NotificationType.BEBACK:
      case NotificationType.CAMPAIGN:
      case NotificationType.NOTIFICATION:
      case NotificationType.NOTIFICATION_CHAT_DISABLED:
      case NotificationType.CAMPAIGN_CHAT_DISABLED:
        return NOTIFICATION_ACTION_TEXT.SELECT_VISITOR;

      case NotificationType.ENGAGEMENT_OFFER_LEAD:
        return NOTIFICATION_ACTION_TEXT.NAVIGATE_TO_ENG_LEADS;
      case NotificationType.TRAFFIC_MARKER_REMINDER:
        return NOTIFICATION_ACTION_TEXT.NAVIGATE_TO_VISITS_AND_LEADS;
      case NotificationType.TRAFFIC_ALERT:
        return NOTIFICATION_ACTION_TEXT.NAVIGATE_TO_ALERTS;
    }

    return NOTIFICATION_ACTION_TEXT.NO_ACTION;
  };

  const itemTemplate = (notification: IMyTrafficNotificationLog): any => {
    if (!notification) {
      return null;
    }

    const id = Util._.get(notification, "id", 0);
    const body = Util._.get(notification, "body", null);
    const name = Util._.get(notification, "name", null);
    const created = Util._.get(notification, "created", null);
    const unread = NotificationService.isUnread(notification);
    const actionText = getNotificationActionText(notification);

    return (
      <div
        onClick={() => onClick(notification)}
        key={id}
        className="p-col-12 notification-container"
      >
        <div
          className="product-grid-item card notification-button"
          title={actionText}
        >
          <div style={{ position: "relative", pointerEvents: "none" }}>
            <div className={"action"}>
              <label>{actionText}</label>
            </div>
            <div>
              <div>
                <label>{name}</label>
              </div>
              <div style={{ fontWeight: "normal" }}>
                <label>{body}</label>
              </div>
              {unread ? <div className={"badge"} /> : null}
              <div>
                <label
                  style={{
                    fontWeight: "normal",
                    color: unread ? "#39A3F4" : "#000",
                  }}
                >
                  {Util.fromNow(created)}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onClick = (notification: IMyTrafficNotificationLog): void => {
    setVisible(false);
    notificationService?.handleNotification(notification.id);
  };

  const onPage = () => {
    getNotifications(page + 1, limit).finally(() => {
      setPage(page + 1);
    });
  };

  const clearAllNotifications = (): void => {
    if (isMountedRef.current) {
      setPending(true);
      clearNotifications()
        .then((response) => {
          getNotifications(0, limit).finally(() => {
            setPage(0);
            notifications.clear();
            setHasMore(false);
            setPending(false);
          });
          getNotificationCount();
        })
        .catch((error) => {
          setPending(false);
          Util.showError(error);
        });
    }
  };

  const onToggle = (e: any): void => {
    setVisible(!visible);
    setAnchorEl(e.currentTarget);
  };

  return (
    <div ref={notificationRef}>
      <NotificationBadge
        visible={visible}
        count={unreadNotificationCount}
        onClick={onToggle}
      />
      <Menu
        id={"overlay-panel"}
        open={visible}
        anchorEl={anchorEl}
        style={{ marginTop: 10 }}
        onClose={() => setVisible(false)}
      >
        <div
          id={"user-notifications"}
          style={{ width: 350, backgroundColor: "#FFFFFF" }}
        >
          <div
            style={{
              position: "relative",
              height: 42,
              textAlign: "start",
              borderBottom: "1px solid #f5f5f5",
            }}
          >
            <label
              style={{ fontSize: 18, position: "absolute", top: 0, left: 5 }}
            >
              Notifications
            </label>
            <LoadingButton
              loading={pending}
              style={{ position: "absolute", right: 5, bottom: 5 }}
              onClick={clearAllNotifications}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              Clear all
            </LoadingButton>
          </div>
          <div
            id={"scrollableTarget"}
            style={{
              width: "100%",
              overflowY: "auto",
              cursor: "auto",
              margin: 5,
              maxHeight: size.height - 200,
            }}
          >
            <InfiniteScroll
              next={onPage}
              dataLength={notifications.size}
              hasMore={hasMore}
              endMessage={"No more notifications"}
              scrollableTarget={"scrollableTarget"}
              loader={<div>Loading ...</div>}
            >
              {Array.from(notifications.values()).map((notification, index) => (
                <div key={index}>{itemTemplate(notification)}</div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </Menu>
    </div>
  );
});

export default UserNotifications;
