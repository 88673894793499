import Axios from "../config/Axios";
import {
  ISearchCriteriaCDK,
  DealershipCdkDataCreateUpdateDto,
} from "../../index.dts";

export const getAllCDKRecords = (searchCriteria: ISearchCriteriaCDK) =>
  Axios.instance.post(`dealerships/list-cdk-data`, searchCriteria);

export const getServiceAdvisors = (dealershipId: number) =>
  Axios.instance.get(
    `dealerships/${dealershipId}/cdk-ro-assist-service-advisor`
  );

export const getServiceTechnician = (dealershipId: number) =>
  Axios.instance.get(
    `dealerships/${dealershipId}/cdk-ro-assist-service-technician`
  );

export const getLaborTypes = (dealershipId: number) =>
  Axios.instance.get(`dealerships/${dealershipId}/cdk-ro-assist-labor-type`);

export const getVinNumber = (vinNo: string) =>
  Axios.instance.get(`dealerships/cdk-data-vin/${vinNo}`);

export const createLaborTypes = (dealershipId: number, laborTypeObj: any) =>
  Axios.instance.post(
    `dealerships/${dealershipId}/cdk-ro-assist-labor-type`,
    laborTypeObj
  );

export const createServiceAdvisors = (
  dealershipId: number,
  serviceAdvisorObj: any
) =>
  Axios.instance.post(
    `dealerships/${dealershipId}/cdk-ro-assist-service-advisor`,
    serviceAdvisorObj
  );

export const createServiceTechnicians = (
  dealershipId: number,
  serviceTechnicianObj: any
) =>
  Axios.instance.post(
    `dealerships/${dealershipId}/cdk-ro-assist-service-technician`,
    serviceTechnicianObj
  );

export const updateLaborTypes = (dealershipId: number, laborTypeObj: any) =>
  Axios.instance.put(
    `dealerships/${dealershipId}/cdk-ro-assist-labor-type`,
    laborTypeObj
  );

export const updateServiceAdvisors = (
  dealershipId: number,
  serviceAdvisorObj: any
) =>
  Axios.instance.put(
    `dealerships/${dealershipId}/cdk-ro-assist-service-advisor`,
    serviceAdvisorObj
  );

export const updateServiceTechnicians = (
  dealershipId: number,
  serviceTechnicianObj: any
) =>
  Axios.instance.put(
    `dealerships/${dealershipId}/cdk-ro-assist-service-technician`,
    serviceTechnicianObj
  );

export const createCDKData = (
  dealershipId: number,
  cdkDataObj: DealershipCdkDataCreateUpdateDto
) => Axios.instance.post(`dealerships/${dealershipId}/cdk-data`, cdkDataObj);

export const getCDKData = (roId: number) =>
  Axios.instance.get(`dealerships/${roId}/cdk-data`);

export const updateCDKData = (
  cdkROId: number,
  cdkDataObj: DealershipCdkDataCreateUpdateDto
) => Axios.instance.put(`dealerships/${cdkROId}/cdk-data`, cdkDataObj);

export const getRONumber = (dealershipId: number) =>
  Axios.instance.get(`dealerships/${dealershipId}/ro-number`);
