import React, { useEffect, useState } from "react";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import Util from "../../../util/Util";
import { Column } from "primereact/column";
import { IBlockedIpAddress } from "../../../../index.dts";
import moment from "moment-timezone";
import {
  addBlockedIpAddress,
  deleteBlockedIpAddresses,
  getBlockedIpAddresses,
  updateBlockedIpAddress,
} from "../../../actions/globalSettingsActions";
import { InputText } from "primereact/inputtext";
import { SEARCH_OPERATIONS } from "../../../util/Enums";
import { Button } from "primereact/button";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import { confirmAlert } from "react-confirm-alert";
import { IDS } from "../../../views/constants";
import { useTranslation } from "react-i18next";

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

export function BlockedIpAddress(props: any) {
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState<number>(0);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [ipAddresses, setIpAddresses] = useState<IBlockedIpAddress[]>([]);
  const [selectedIpAddresses, setSelectedIpAddresses] = useState<
    IBlockedIpAddress[]
  >([]);

  const [nameFilterValue, setNameFilterValue] = useState<string>("");
  const [ipAddressFilterValue, setIpAddressFilterValue] = useState<string>("");
  const [updatedByUserFilterValue, setUpdatedByUserFilterValue] =
    useState<string>("");
  const [sortMap] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(new Map());

  const onClear = () => {
    sortMap.clear();
    setIpAddressFilterValue("");
    setNameFilterValue("");
    setUpdatedByUserFilterValue("");
  };

  useEffect(() => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getData(0, limit).finally(() => {
        setPage(0);
        setFirst(0);
        searchTimeoutInterval = 500;
        setSelectedIpAddresses([]);
      });
    }, searchTimeoutInterval);
    // eslint-disable-next-line
  }, [nameFilterValue, ipAddressFilterValue, updatedByUserFilterValue]);

  const getData = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      const filter: any[] = [];
      if (nameFilterValue) {
        filter.push({
          field: "name",
          value: nameFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (ipAddressFilterValue) {
        filter.push({
          field: "ipAddress",
          value: ipAddressFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (updatedByUserFilterValue) {
        filter.push({
          field: "updatedBy",
          value: updatedByUserFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      setLoading(true);
      getBlockedIpAddresses({
        filter: filter,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
        paging: {
          page: page,
          pageLimit: limit,
        },
      })
        .then((response) => {
          setIpAddresses(response.data.content);
          setTotalItemsCount(response.data.totalElements);

          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);

          reject(error);
        })
        .finally(() => setLoading(false));
    });

  const updatedTemplate = (data: any) => {
    if (!data.updated) {
      return null;
    }
    return (
      <div>
        {moment(Util.utcToLocal(data.updated)).format(Util.usDateTimeFormat)}
      </div>
    );
  };

  const onPage = (event: any) => {
    getData(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
      setSelectedIpAddresses([]);
    });
  };

  const onSort = (data: any) => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getData(page, limit);
    }
  };

  const nameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={nameFilterValue}
      onChange={(e: any) => {
        setNameFilterValue(e.target.value || "");
      }}
    />
  );

  const ipAddressFilter = (
    <InputText
      style={{ width: "100%" }}
      value={ipAddressFilterValue}
      onChange={(e: any) => {
        setIpAddressFilterValue(e.target.value || "");
      }}
    />
  );

  const updatedByUserFilter = (
    <InputText
      style={{ width: "100%" }}
      value={updatedByUserFilterValue}
      onChange={(e: any) => {
        setUpdatedByUserFilterValue(e.target.value || "");
      }}
    />
  );

  const onAdd = () => {
    ipAddresses.unshift({
      id: Util.uniqId(),
      name: "",
      ipAddress: "",
      updated: "",
      created: "",
      updatedBy: "",
    });

    forceUpdate();

    setTimeout(() => {
      Util.$("#blocking-ips .p-row-editor-init-icon")[0]?.click();
      Util.$("#blocking-ips .website-vendor-editor")[0]?.focus();
    });
  };

  const onDelete = () => {
    if (selectedIpAddresses.length > 0) {
      confirmAlert({
        title: `You are about to delete ${
          selectedIpAddresses.length
        } blocked ip address${selectedIpAddresses.length > 1 ? "es" : ""}!`,
        message: "Are you sure you want to do this?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              Util.globalSpinner().show();
              deleteBlockedIpAddresses(
                selectedIpAddresses.map(
                  (blockedIpAddress) => blockedIpAddress.id
                )
              )
                .then(() => {
                  getData(0, limit).finally(() => {
                    setPage(0);
                    setSelectedIpAddresses([]);
                  });
                  Util.success("Blocked Ip Address deleted successfully.");
                })
                .catch((error) => {
                  Util.showError(error);
                })
                .finally(() => {
                  Util.globalSpinner().hide();
                });
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    }
  };

  const onRowEditCancel = (props: any): void => {
    if (typeof props.data.id === "string") {
      const index = ipAddresses.findIndex((item) => item.id === props.data.id);

      if (index !== -1) {
        ipAddresses.splice(index, 1);

        forceUpdate();
      }
    }
  };

  const onRowEditSave = (props: any): void => {
    props.originalEvent.preventDefault();
    if (typeof props.data.id === "string") {
      addBlockedIpAddress({
        name: props.newData.name,
        ipAddress: props.newData.ipAddress,
      })
        .then((response) => {
          const index = ipAddresses.findIndex(
            (item) => (item.id = props.data.id)
          );

          if (index !== -1) {
            ipAddresses[index] = response.data;

            setTotalItemsCount(totalItemsCount + 1);
          }

          Util.success("Blocked ip address created successfully");
          forceUpdate();
        })
        .catch((error) => {
          Util.showError(error);
        });
    } else {
      updateBlockedIpAddress(props.data.id, {
        name: props.newData.name,
        ipAddress: props.newData.ipAddress,
      })
        .then(() => {
          getData(page, limit);
          Util.success("Blocked ip address updated successfully");
          forceUpdate();
        })
        .catch((error) => {
          Util.showError(error);
        });
    }
  };

  const nameEditor = (props: any) => {
    return (
      <InputText
        style={{ width: "100%" }}
        value={props.value}
        onChange={(e: any) => props.editorCallback(e.target.value)}
        placeholder="Enter Location"
      />
    );
  };

  const ipAddressEditor = (props: any) => {
    return (
      <InputText
        style={{ width: "100%" }}
        value={props.value}
        onChange={(e: any) => props.editorCallback(e.target.value)}
        placeholder="Enter IP address"
      />
    );
  };

  return (
    <div className={"p-grid"}>
      <div className="p-col-12">
        <div className="card card-w-title datatable-demo">
          <div
            id={"blocking-ips"}
            className={
              limit > 10
                ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting"
                : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
            }
          >
            <DataTable
              resizableColumns={true}
              filterDisplay="row"
              columnResizeMode={"expand"}
              rowsPerPageOptions={Util.rowsPerPageOptions}
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
              value={ipAddresses}
              paginator={true}
              scrollable
              totalRecords={totalItemsCount}
              rows={limit}
              lazy={true}
              editMode={"row"}
              first={first}
              loading={loading}
              onPage={onPage}
              selection={selectedIpAddresses}
              onSelectionChange={(e) => setSelectedIpAddresses(e.value)}
              onSort={onSort}
              sortMode={"multiple"}
              onRowEditComplete={onRowEditSave}
              onRowEditCancel={onRowEditCancel}
              header={
                <div style={{ display: "flex", textAlign: "left" }}>
                  <span>Blocked Ip Addresses</span>
                  <div className={"p-grid-header-components"}>
                    <Button
                      id={IDS.button.clear}
                      icon={"pi pi-filter"}
                      label={t("clear")}
                      onClick={onClear}
                    />
                    <Button
                      label={"New"}
                      icon={"pi pi-plus"}
                      style={{ marginRight: 5 }}
                      onClick={onAdd}
                    />
                    <Button
                      label={"Delete"}
                      icon={"pi pi-trash"}
                      className="p-button-danger"
                      disabled={selectedIpAddresses.length === 0}
                      onClick={onDelete}
                    />
                  </div>
                </div>
              }
              multiSortMeta={Array.from(sortMap.values())}
            >
              <Column
                selectionMode="multiple"
                style={{ width: "3em", flex: "0.1" }}
              />
              <Column
                filter={true}
                sortable={true}
                field={"ipAddress"}
                sortField={"ipAddress"}
                header="Ip Address"
                filterElement={ipAddressFilter}
                editor={ipAddressEditor}
              />
              <Column
                filter={true}
                sortable={true}
                field={"name"}
                sortField={"name"}
                header="Location"
                filterElement={nameFilter}
                editor={nameEditor}
              />
              <Column
                filter={true}
                sortable={true}
                field={"updatedBy"}
                sortField={"updatedBy"}
                header="Updated By"
                filterElement={updatedByUserFilter}
              />
              <Column
                field="updated"
                header="Updated"
                filter={false}
                sortable={true}
                style={{ overflow: "visible" }}
                body={updatedTemplate}
              />
              <Column
                rowEditor
                headerStyle={{ width: "7rem" }}
                bodyStyle={{ textAlign: "center" }}
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockedIpAddress;
