export const fontFamilies = [
  { name: "Abadi MT" },
  { name: "Agency FB" },
  { name: "Aharoni Bold" },
  { name: "Aldhabi" },
  { name: "Algerian" },
  { name: "Almanac MT" },
  { name: "American Uncial" },
  { name: "Andale Mono" },
  { name: "Andalus" },
  { name: "Andy" },
  { name: "AngsanaUPC" },
  { name: "Angsana New" },
  { name: "Aparajita" },
  { name: "Arabic Transparent" },
  { name: "Arabic Typesetting" },
  { name: "Arial" },
  { name: "Arial Black" },
  { name: "Arial Narrow" },
  { name: "Arial Narrow Special" },
  { name: "Arial Rounded MT" },
  { name: "Arial Special" },
  { name: "Arial Unicode MS" },
  { name: "Augsburger Initials" },
  { name: "Avenir Next LT Pro" },
  { name: "Bahnschrift" },
  { name: "Baskerville Old Face" },
  { name: "Batang & BatangChe" },
  { name: "Bauhaus 93" },
  { name: "Beesknees ITC" },
  { name: "Bell MT" },
  { name: "Bembo" },
  { name: "Berlin Sans FB" },
  { name: "Bernard MT Condensed" },
  { name: "Bickley Script" },
  { name: "Biome" },
  { name: "BIZ UDGothic" },
  { name: "BIZ UDMincho Medium" },
  { name: "Blackadder ITC" },
  { name: "Bodoni MT" },
  { name: "Bodoni MT Condensed" },
  { name: "Bon Apetit MT" },
  { name: "Bookman Old Style" },
  { name: "Bookshelf Symbol" },
  { name: "Book Antiqua" },
  { name: "Bradley Hand ITC" },
  { name: "Braggadocio" },
  { name: "BriemScript" },
  { name: "Britannic Bold" },
  { name: "Broadway" },
  { name: "BrowalliaUPC" },
  { name: "Browallia New" },
  { name: "Brush Script MT" },
  { name: "Calibri" },
  { name: "Californian FB" },
  { name: "Calisto MT" },
  { name: "Cambria" },
  { name: "Cambria Math" },
  { name: "Candara" },
  { name: "Cariadings" },
  { name: "Castellar" },
  { name: "Cavolini" },
  { name: "Centaur" },
  { name: "Century" },
  { name: "Century Gothic" },
  { name: "Century Schoolbook" },
  { name: "Chiller" },
  { name: "Colonna MT" },
  { name: "Comic Sans MS" },
  { name: "Consolas" },
  { name: "Constantia" },
  { name: "Contemporary Brush" },
  { name: "Cooper Black" },
  { name: "Copperplate Gothic" },
  { name: "Corbel" },
  { name: "CordiaUPC" },
  { name: "Cordia New" },
  { name: "Courier New" },
  { name: "Curlz MT" },
  { name: "Dante" },
  { name: "DaunPenh" },
  { name: "David" },
  { name: "Daytona" },
  { name: "Desdemona" },
  { name: "DFKai-SB" },
  { name: "DilleniaUPC" },
  { name: "Directions MT" },
  { name: "DokChampa" },
  { name: "Dotum & DotumChe" },
  { name: "Ebrima" },
  { name: "Eckmann" },
  { name: "Edda" },
  { name: "Edwardian Script ITC" },
  { name: "Elephant" },
  { name: "Engravers MT" },
  { name: "Enviro" },
  { name: "Eras ITC" },
  { name: "Estrangelo Edessa" },
  { name: "EucrosiaUPC" },
  { name: "Euphemia" },
  { name: "Eurostile" },
  { name: "FangSong" },
  { name: "Felix Titling" },
  { name: "Fine Hand" },
  { name: "Fixed Miriam Transparent" },
  { name: "Flexure" },
  { name: "Footlight MT" },
  { name: "Forte" },
  { name: "Franklin Gothic" },
  { name: "Franklin Gothic Medium" },
  { name: "FrankRuehl" },
  { name: "FreesiaUPC" },
  { name: "Freestyle Script" },
  { name: "French Script MT" },
  { name: "Futura" },
  { name: "Gabriola" },
  { name: "Gadugi" },
  { name: "Garamond" },
  { name: "Garamond MT" },
  { name: "Gautami" },
  { name: "Georgia" },
  { name: "Georgia Ref" },
  { name: "Gigi" },
  { name: "Gill Sans MT" },
  { name: "Gill Sans MT Condensed" },
  { name: "Gisha" },
  { name: "Gloucester" },
  { name: "Goudy Old Style" },
  { name: "Goudy Stout" },
  { name: "Gradl" },
  { name: "Grotesque" },
  { name: "Gulim & GulimChe" },
  { name: "Gungsuh & GungsuhChe" },
  { name: "Hadassah Friedlaender" },
  { name: "Haettenschweiler" },
  { name: "Harlow Solid Italic" },
  { name: "Harrington" },
  { name: "HGGothicE" },
  { name: "HGMinchoE" },
  { name: "HGSoeiKakugothicUB" },
  { name: "High Tower Text" },
  { name: "Holidays MT" },
  { name: "HoloLens MDL2 Assets" },
  { name: "Impact" },
  { name: "Imprint MT Shadow" },
  { name: "Informal Roman" },
  { name: "IrisUPC" },
  { name: "Iskoola Pota" },
  { name: "JasmineUPC" },
  { name: "Javanese Text" },
  { name: "Jokerman" },
  { name: "Juice ITC" },
  { name: "KaiTi" },
  { name: "Kalinga" },
  { name: "Kartika" },
  { name: "Keystrokes MT" },
  { name: "Khmer UI" },
  { name: "Kigelia" },
  { name: "Kino MT" },
  { name: "KodchiangUPC" },
  { name: "Kokila" },
  { name: "Kristen ITC" },
  { name: "Kunstler Script" },
  { name: "Lao UI" },
  { name: "Latha" },
  { name: "LCD" },
  { name: "Leelawadee" },
  { name: "Levenim MT" },
  { name: "LilyUPC" },
  { name: "Lucida Blackletter" },
  { name: "Lucida Bright" },
  { name: "Lucida Bright Math" },
  { name: "Lucida Calligraphy" },
  { name: "Lucida Console" },
  { name: "Lucida Fax" },
  { name: "Lucida Handwriting" },
  { name: "Lucida Sans" },
  { name: "Lucida Sans Typewriter" },
  { name: "Lucida Sans Unicode" },
  { name: "Magneto" },
  { name: "Maiandra GD" },
  { name: "Malgun Gothic" },
  { name: "Mangal" },
  { name: "Map Symbols" },
  { name: "Marlett" },
  { name: "Matisse ITC" },
  { name: "Matura MT Script Capitals" },
  { name: "McZee" },
  { name: "Mead Bold" },
  { name: "Meiryo" },
  { name: "Mercurius Script MT Bold" },
  { name: "Microsoft GothicNeo" },
  { name: "Microsoft Himalaya" },
  { name: "Microsoft JhengHei" },
  { name: "Microsoft JhengHei UI" },
  { name: "Microsoft New Tai Lue" },
  { name: "Microsoft PhagsPa" },
  { name: "Microsoft Sans Serif" },
  { name: "Microsoft Tai Le" },
  { name: "Microsoft Uighur" },
  { name: "Microsoft YaHei" },
  { name: "Microsoft YaHei UI" },
  { name: "Microsoft Yi Baiti" },
  { name: "MingLiU" },
  { name: "MingLiU-ExtB" },
  { name: "MingLiU_HKSCS" },
  { name: "MingLiU_HKSCS-ExtB" },
  { name: "Minion Web" },
  { name: "Miriam" },
  { name: "Miriam Fixed" },
  { name: "Mistral" },
  { name: "Modern Love" },
  { name: "Modern No. 20" },
  { name: "Mongolian Baiti" },
  { name: "Monotype.com" },
  { name: "Monotype Corsiva" },
  { name: "Monotype Sorts" },
  { name: "MoolBoran" },
  { name: "MS Gothic" },
  { name: "MS LineDraw" },
  { name: "MS Mincho" },
  { name: "MS Outlook" },
  { name: "MS PGothic" },
  { name: "MS PMincho" },
  { name: "MS Reference" },
  { name: "MS UI Gothic" },
  { name: "MT Extra" },
  { name: "MV Boli" },
  { name: "Myanmar Text" },
  { name: "Narkisim" },
  { name: "News Gothic MT" },
  { name: "New Caledonia" },
  { name: "Niagara" },
  { name: "Nirmala UI" },
  { name: "Nyala" },
  { name: "OCR-B-Digits" },
  { name: "OCRB" },
  { name: "OCR A Extended" },
  { name: "Old English Text MT" },
  { name: "Onyx" },
  { name: "Palace Script MT" },
  { name: "Palatino Linotype" },
  { name: "Papyrus" },
  { name: "Parade" },
  { name: "Parchment" },
  { name: "Parties MT" },
  { name: "Peignot Medium" },
  { name: "Pepita MT" },
  { name: "Perpetua" },
  { name: "Perpetua Titling MT" },
  { name: "Placard Condensed" },
  { name: "Plantagenet Cherokee" },
  { name: "Playbill" },
  { name: "PMingLiU" },
  { name: "PMingLiU-ExtB" },
  { name: "Poor Richard" },
  { name: "Posterama" },
  { name: "Pristina" },
  { name: "Quire Sans" },
  { name: "Raavi" },
  { name: "Rage Italic" },
  { name: "Ransom" },
  { name: "Ravie" },
  { name: "RefSpecialty" },
  { name: "Rockwell" },
  { name: "Rockwell Nova" },
  { name: "Rod" },
  { name: "Runic MT Condensed" },
  { name: "Sabon Next LT" },
  { name: "Sagona" },
  { name: "Sakkal Majalla" },
  { name: "Script MT Bold" },
  { name: "Segoe Chess" },
  { name: "Segoe Print" },
  { name: "Segoe Script" },
  { name: "Segoe UI" },
  { name: "Segoe UI Symbol" },
  { name: "Selawik" },
  { name: "Shonar Bangla" },
  { name: "Showcard Gothic" },
  { name: "Shruti" },
  { name: "Signs MT" },
  { name: "SimHei" },
  { name: "Simplified Arabic Fixed" },
  { name: "SimSun" },
  { name: "SimSun-ExtB" },
  { name: "Sitka" },
  { name: "NSimSun" },
  { name: "Snap ITC" },
  { name: "Sports MT" },
  { name: "STCaiyun" },
  { name: "Stencil" },
  { name: "STFangsong" },
  { name: "STHupo" },
  { name: "STKaiti" },
  { name: "Stop" },
  { name: "STXihei" },
  { name: "STXingkai" },
  { name: "STXinwei" },
  { name: "STZhongsong" },
  { name: "Sylfaen" },
  { name: "Symbol" },
  { name: "Tahoma" },
  { name: "Tempo Grunge" },
  { name: "Tempus Sans ITC" },
  { name: "Temp Installer Font" },
  { name: "The Hand" },
  { name: "The Serif Hand" },
  { name: "Times New Roman" },
  { name: "Times New Roman Special" },
  { name: "Tisa Offc Serif Pro" },
  { name: "Traditional Arabic" },
  { name: "Transport MT" },
  { name: "Trebuchet MS" },
  { name: "Tunga" },
  { name: "Tw Cen MT" },
  { name: "Univers" },
  { name: "Urdu Typesetting" },
  { name: "Utsaah" },
  { name: "Vacation MT" },
  { name: "Vani" },
  { name: "Verdana" },
  { name: "Verdana Ref" },
  { name: "Vijaya" },
  { name: "Viner Hand ITC" },
  { name: "Vivaldi" },
  { name: "Vixar ASCI" },
  { name: "Vladimir Script" },
  { name: "Vrinda" },
  { name: "Walbaum" },
  { name: "Webdings" },
  { name: "Westminster" },
  { name: "Wide Latin" },
  { name: "Wingdings" },
];
