import React from "react";
import {TabPanel, TabView} from "primereact/tabview";
import DealershipUsers from "../../components/dealerships_and_users/dealership_users/DealershipUsers";
import UserDealerships from "../../components/dealerships_and_users/user_dealerships/UserDealerships";
import Util from "../../util/Util";
import {PERMISSIONS} from "../../util/Enums";
import "./dealership_and_users.css";

interface IProps {

}

const DealershipsAndUsers = (props: IProps) => {


  const renderer = (): React.ReactElement => {
    return (
      <div id={"dealerships-and-users"}>
        {Util.hasAnyAuthority(PERMISSIONS.DEALERSHIP_MANAGEMENT) ? (
          <div style={{padding: 0}} className="card">
            <TabView renderActiveOnly={true} className="tabview-custom">
              <TabPanel header={"Dealership Users"} leftIcon="pi pi-users">
                <DealershipUsers/>
              </TabPanel>
              {Util.hasAnyAuthority(PERMISSIONS.USERS_VIEW) ? (
                <TabPanel header={"User Dealerships"} leftIcon="pi pi-user">
                  <UserDealerships/>
                </TabPanel>
              ) : null}
            </TabView>
          </div>
        ) : null}
      </div>
    )
  }

  return renderer();
}

export default DealershipsAndUsers;