import Axios from "../config/Axios";
import actions from "../constants/actions";


export const setSwimLanesConfig = (payload: { [key: string]: string[] }) => ({
  type: actions.SET_SWIM_LANES_CONFIG,
  payload: payload
});

export const getDealershipStats = (dealershipId: number, startDate: string, endDate: string) => Axios.instance.get("rts/visitsAndLeads", {
  params: {
    dealershipId: dealershipId,
    startDate: startDate,
    endDate: endDate
  }
})
export const getSwimlanes = () => Axios.instance.get("myTraffic/configuration/swimlanes");
export const getLeadsAvgScore = (dealershipId: number, startDate: string, endDate: string) => Axios.instance.get(`myTraffic/leads/avg-score?dealershipId=${dealershipId}&startDate=${startDate}&endDate=${endDate}`);
export const getVisitorConversionHistory = (dealershipId: number, visitorRefId: string, limit: number) => Axios.instance.get(`myTraffic/visitor/${visitorRefId}/conversion-history?dealershipId=${dealershipId}&limit=${limit}`);
export const getVisitorsAndLeadsReport = (dealershipId: number, startDate: string, endDate: string) => Axios.instance.get("rts/peakHours", {
  params: {
    dealershipId: dealershipId,
    startDate: startDate,
    endDate: endDate
  }
})
interface Filter {
  field: string;
  operation: string;
  value: string | number;
}
export const getVisitorExternalData = (dealershipId: number, visitorRefId: string) => {
  const filters: Filter[] = [
    {
      field: "dealershipId",
      operation: "=",
      value: dealershipId,
    },
    {
      field: "visitorExtId",
      operation: "=",
      value: visitorRefId,
    },
  ];

  return Axios.instance.post("visitors/external-data/list", { filter: filters });
};