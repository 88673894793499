import Axios from "../config/Axios";
import {
  AxiosResponse,
  IChatBotDto,
  IChatBotWorkflowDto,
  IMyTrafficNotificationDto,
  IPageable,
  ISearchCriteria,
  ISearchMyTrafficNotificationsDto,
  IACquisitionsExportRequestDto,
  FilteredVehicleCampaignFiltersDto,
  VisitorExternalDataStatsRequestDto,
  VisitorExternalDataStatsDto,
  ProspectsSearchRequestDto,
  IVisitorExternalData,
  IAquisitionsExportReportRequestDto
} from "../../index.dts";

export const getMyTrafficNotification = (id: number) =>
  Axios.instance.get(`myTraffic/notifications/${id}`);

export const getMyCampaignsNotification = (
  id: number,
  searchRequestDto: FilteredVehicleCampaignFiltersDto
) => Axios.instance.post(`myTraffic/notifications/mc/${id}`, searchRequestDto);

export const getMyTrafficNotificationList = (
  searchDto: ISearchMyTrafficNotificationsDto
): Promise<IPageable> =>
  Axios.instance.post(`myTraffic/notifications/list`, searchDto);

export const getVisitorsExternalAcquisitionOffers = (
  visitorExternalDataId: number
) =>
  Axios.instance.get(`/visitors/external-data/${visitorExternalDataId}/offers`);

export const getVisitorsExternalDataStats = (
  dto: VisitorExternalDataStatsRequestDto
): Promise<AxiosResponse<VisitorExternalDataStatsDto>> =>
  Axios.instance.post(`/visitors/external-data/stats`, dto);

export const getVisitorsExternalDataList = (
  dto: ISearchCriteria
): Promise<IPageable> =>
  Axios.instance.post("/visitors/external-data/list", dto);

export const getInventoryStatus = (id: number) =>
  Axios.instance.get(`/dealerships/${id}/provider-config/status`);

export const getT2DataProxyCall = (
  phoneNumber: string,
  dealershipExtRefId: string,
  email?: string
) =>
  Axios.instance.get(
    `/visitors/t2-proxy-call?phoneNumber=${phoneNumber}&email=${email}&dealershipExtRefId=${dealershipExtRefId}`
  );

export const getT2VinDataProxyCall = (
  vin: string,
  dealershipExtRefId: string
) =>
  Axios.instance.get(
    `/visitors/t2-vin-proxy-call?vin=${vin}&dealershipExtRefId=${dealershipExtRefId}`
  );

export const getT2PlateDataProxyCall = (
  plate: string,
  state: string,
  dealershipExtRefId: string
) =>
  Axios.instance.get(
    `/visitors/t2-plate-proxy-call?plate=${plate}&state=${state.toUpperCase()}&dealershipExtRefId=${dealershipExtRefId}`
  );

export const getT2PiiDataProxyCall = (
  ssn: string,
  dl: string,
  dealershipExtRefId: string
) =>
  Axios.instance.get(
    `/visitors/t2-pii-proxy-call?ssn=${ssn}&dl=${dl.toUpperCase()}&dealershipExtRefId=${dealershipExtRefId}`
  );

export const getBaseMyTrafficNotificationList = (
  searchDto: ISearchMyTrafficNotificationsDto
): Promise<IPageable> =>
  Axios.instance.post(`myTraffic/notifications/base-list`, searchDto);

export const deleteDealershipCampaign = (id: number) =>
  Axios.instance.delete(`myTraffic/notifications/${id}`);

export const createDealershipCampaign = (dto: IMyTrafficNotificationDto) =>
  Axios.instance.post("myTraffic/notifications", dto);

export const updateDealershipCampaign = (
  id: number,
  dto: IMyTrafficNotificationDto
) => Axios.instance.put(`myTraffic/notifications/${id}`, dto);

export const getMyTrafficNotificationLogList = (
  dto: ISearchCriteria
): Promise<IPageable> => Axios.instance.post("notification-log/list", dto);

export const getNotificationUsers = (dto: {
  dealershipId: number;
  userEmail?: string;
}) => Axios.instance.post("myTraffic/notifications/users", dto);

export const deleteDealershipCampaigns = (ids: number[]) =>
  Axios.all(
    ids.map((id) => Axios.instance.delete(`myTraffic/notifications/${id}`))
  );

export const createChatBotWorkflow = (
  notificationId: number,
  dto: IChatBotWorkflowDto
) =>
  Axios.instance.post(
    `myTraffic/notifications/${notificationId}/chatbot-workflow`,
    dto
  );

export const updateChatBotWorkflow = (id: number, dto: IChatBotWorkflowDto) =>
  Axios.instance.put(`myTraffic/notifications/chatbot-workflow/${id}`, dto);

export const deleteChatBotWorkflows = (ids: number[]) =>
  Axios.all(
    ids.map((id) =>
      Axios.instance.delete(`myTraffic/notifications/chatbot-workflow/${id}`)
    )
  );

export const createAcquisitionReport = (dto: IACquisitionsExportRequestDto) =>
  Axios.instance.post("leads/createAcquisitionReport", dto);

export const exportAcquisitionData = (dto: IAquisitionsExportReportRequestDto) =>
  Axios.instance.post("leads/export-acquisition-data", dto);

export const downloadAcquisitionReport = (reportId: string) =>
  Axios.instance.get(`leads/downloadAcquisitionReport?id=${reportId}`, {
    responseType: "blob",
  });

export const downloadAcquisitionExport = (reportId: string) =>
  Axios.instance.get(`leads/downloadAcquisitionExport?id=${reportId}`, {
    responseType: "blob",
  });

export const getChatBots = (
  dealershipId?: number
): Promise<AxiosResponse<IChatBotDto[]>> => {
  if (dealershipId) {
    return Axios.instance.get(`chatbots?dealershipId=${dealershipId}`);
  }

  return Axios.instance.get(`chatbots`);
};

export const getClickInsReport = (visitorExternalDataId: number) =>
  Axios.instance.get(
    `visitors/click-ins/get-report?visitorExternalDataId=${visitorExternalDataId}`
  );

export const listVisitorsExternalData = (dto: ProspectsSearchRequestDto) =>
  Axios.instance.post("prospects/leads/list", dto);

export const getVisitorsExternalDataListByDealership = (
  id: number
): Promise<any> =>
  Axios.instance.get(`/visitors/external-data/${id}/list`);
