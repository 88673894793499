import React, { useEffect, useState } from "react";
import "./offers.css";
import Util from "../../util/Util";
import { MODE, OFFER_TYPES, PERMISSIONS } from "../../util/Enums";
import { Button } from "primereact/button";
import { IOffer, IOfferCompact, IStore } from "../../../index.dts";
import {
  deleteOffers,
  getOfferList,
  updateOfferIsActive,
} from "../../actions/offerActions";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { confirmAlert } from "react-confirm-alert";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { Dropdown } from "primereact/dropdown";
import { IDS } from "../constants";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { DateRangePeriods } from "../../components/data_range_picker/date_range_periods";
import { DateRangePickerWidget } from "../../components/data_range_picker/DateRangePickerWidget";

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

export function Offers() {
  const history = useHistory();
  const { t }: any = useTranslation();
  const forceUpdate = useForceUpdate();
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  const [filterName, setFilterName] = useState<string>("");
  const [filterTemplate, setFilterTemplate] = useState<string>("");
  const [filterDealership, setFilterDealership] = useState<string>(
    dealershipContext.id !== 0 ? dealershipContext.name : ""
  );
  const [createdPeriod, setCreatedPeriod] = useState<{
    start?: Date;
    end?: Date;
  }>({
    start: undefined,
    end: undefined,
  });
  const [startDateFilterValue, setStartDateFilterValue] = useState<string>("");
  const [endDateFilterValue, setEndDateFilterValue] = useState<string>("");
  const [offerTypeFilterValue, setOfferTypeFilterValue] = useState<string>();

  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [page, setPage] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [sortMap] = useState<Map<string, { field: string; order: 1 | 0 | -1 }>>(
    new Map()
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [offerList, setOfferList] = useState<IOfferCompact[]>([]);
  const [selectedOffers, setSelectedOffers] = useState<IOfferCompact[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [crmSourceNameFilterValue, setCrmSourceNameFilterValue] =
    useState<string>("");

  const globalSpinner = useSelector(
    (store: IStore) => store.myTraffic.globalSpinner,
    shallowEqual
  );

  const onClear = () => {
    sortMap.clear();
    setFilterName("");
    setCrmSourceNameFilterValue("");
    setFilterTemplate("");
    setOfferTypeFilterValue(undefined);
    setStartDateFilterValue("");
    setEndDateFilterValue("");
    setCreatedPeriod({
      start: undefined,
      end: undefined,
    });
  };

  useEffect(() => {
    sortMap.set("created", { field: "created", order: -1 });
  }, []);

  useEffect(() => {
    if (filterDealership !== dealershipContext.name)
      setFilterDealership(
        dealershipContext.id !== 0 ? dealershipContext.name : ""
      );

    // eslint-disable-next-line
  }, [dealershipContext]);

  useEffect(() => {
    searchTimeout = setTimeout(() => {
      getOffers(0, limit).finally(() => {
        setPage(0);
        setFirst(0);
        setSelectedOffers([]);
        searchTimeoutInterval = 500;
      });
    }, searchTimeoutInterval);

    return () => {
      clearTimeout(searchTimeout);
    };
    // eslint-disable-next-line
  }, [
    filterName,
    filterDealership,
    crmSourceNameFilterValue,
    filterTemplate,
    createdPeriod,
    startDateFilterValue,
    endDateFilterValue,
    offerTypeFilterValue,
  ]);

  const getOffers = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      getOfferList({
        pageLimit: limit,
        pageNumber: page,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
        name: filterName ? filterName : undefined,
        templateName: filterTemplate ? filterTemplate : undefined,
        dealershipName: filterDealership ? filterDealership : undefined,
        crmSourceName: crmSourceNameFilterValue
          ? crmSourceNameFilterValue
          : undefined,
        offerType: Util.getDefaultIfNull(offerTypeFilterValue, undefined),
        toDate: createdPeriod.end
          ? moment(createdPeriod.end).format(Util.localDateTimeFormat)
          : undefined,
        fromDate: createdPeriod.start
          ? moment(createdPeriod.start).format(Util.localDateTimeFormat)
          : undefined,
        startDate: startDateFilterValue
          ? moment(startDateFilterValue).format(Util.localDateTimeFormat)
          : undefined,
        endDate: endDateFilterValue
          ? moment(endDateFilterValue).format(Util.localDateTimeFormat)
          : undefined,
      })
        .then((response) => {
          resolve(response);
          setOfferList(response.data.content);
          setTotalItemsCount(response.data.totalElements);
        })
        .catch((error) => {
          reject(error);
          Util.showError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const onNew = () => {
    history.push(Util.PATH_NAMES.OFFERS_CREATE, { mode: MODE.NEW });
  };

  const onEdit = () => {
    if (selectedOffers.length === 1) {
      history.push(Util.PATH_NAMES.OFFERS_EDIT, {
        mode: MODE.EDIT,
        offer: selectedOffers[0],
      });
    } else {
      Util.warning("Please choose only one offer.");
    }
  };

  const onDelete = () => {
    if (selectedOffers.length > 0) {
      confirmAlert({
        title: `You are about to delete ${selectedOffers.length} offer${
          selectedOffers.length > 1 ? "s" : ""
        }!`,
        message: "Are you sure you want to do this?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              globalSpinner.show();
              deleteOffers(selectedOffers.map((offer) => offer.id))
                .then(() => {
                  selectedOffers.forEach((offer) => {
                    const index = offerList.findIndex((o) => o.id === offer.id);
                    if (index > -1) {
                      offerList.splice(index, 1);
                    }
                  });
                  setSelectedOffers([]);
                  Util.success("Offers deleted successfully.");
                })
                .catch((error) => {
                  Util.showError(error);
                })
                .finally(() => {
                  globalSpinner.hide();
                });
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    } else {
      Util.warning("Please choose at least one offer.");
    }
  };

  const onPage = (event: any) => {
    getOffers(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
      setSelectedOffers([]);
    });
  };

  const onSort = (data: any) => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getOffers(page, limit);
    }
  };

  const nameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={filterName}
      onChange={(e: any) => setFilterName(e.target.value)}
    />
  );

  const dealershipFilter = (
    <InputText
      style={{ width: "100%" }}
      value={filterDealership}
      onChange={(e: any) => setFilterDealership(e.target.value)}
    />
  );

  const crmSourceNameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={crmSourceNameFilterValue}
      onChange={(e: any) => {
        setCrmSourceNameFilterValue(e.target.value || "");
      }}
    />
  );

  const templateFilter = (
    <InputText
      style={{ width: "100%" }}
      value={filterTemplate}
      onChange={(e: any) => setFilterTemplate(e.target.value)}
    />
  );

  function onCreatedPeriodChange(start?: Date, end?: Date): void {
    setCreatedPeriod({
      start: start ? moment(start).set({ hour: 0, minute: 0, second: 0 }).toDate() : undefined,
      end: end ? moment(end).set({ hour: 23, minute: 59, second: 59 }).toDate() : undefined,
    });
  }

  const createdFilter = (
    <DateRangePickerWidget
      start={createdPeriod.start}
      end={createdPeriod.end}
      ranges={DateRangePeriods.options} // Replace 'Options' with the range definitions
      onChange={onCreatedPeriodChange}
    />
  );

  const onStartDateChange = (e: any): void => {
    if (e.value) {
      setStartDateFilterValue(
        moment(get(e, "value")).format(Util.localDateFormat)
      );
    } else {
      setStartDateFilterValue("");
    }
  };

  const onEndDateChange = (e: any): void => {
    if (e.value) {
      setEndDateFilterValue(
        moment(get(e, "value")).format(Util.localDateFormat)
      );
    } else {
      setEndDateFilterValue("");
    }
  };

  const getCalendarValue = (value?: string): Date | undefined => {
    if (!value) {
      return;
    }

    return moment(value).toDate();
  };

  const startDateFilter = (
    <Calendar
      style={{ width: "100%" }}
      inputId={"startDateInput"}
      inputStyle={{ width: "100%" }}
      value={getCalendarValue(startDateFilterValue)}
      onChange={onStartDateChange}
    />
  );

  const endDateFilter = (
    <Calendar
      style={{ width: "100%" }}
      inputId={"endDateInput"}
      inputStyle={{ width: "100%" }}
      value={getCalendarValue(endDateFilterValue)}
      onChange={onEndDateChange}
    />
  );

  const offerTypeFilter = (
    <Dropdown
      showClear={true}
      value={offerTypeFilterValue}
      options={Object.entries(OFFER_TYPES).map((entry) => {
        return {
          label: entry[0],
          value: entry[1],
        };
      })}
      style={{ width: "100%", boxShadow: "none", backgroundColor: "#FFFFFF" }}
      onChange={(e: any) => {
        setOfferTypeFilterValue(e.target.value);
      }}
    />
  );

  const dateBody = (data: any, field: string, formatter: string) => {
    const date = moment(data[field]);
    if (date.isValid()) {
      return date.format(formatter);
    }
    return null;
  };

  const dateBodyCreated = (data: any, field: string, formatter: string) => {
    const date = moment(Util.utcToLocal(data[field]));
    if (date.isValid()) {
      return date.format(formatter);
    }
    return null;
  };

  const activeTemplate = (data: IOffer) => {
    return (
      <InputSwitch
        onChange={() => onActiveChange(data)}
        checked={data.active}
      />
    );
  };

  const offerTypeBody = (data: IOffer) => {
    return <div>{Util.getEnumKeyByValue(OFFER_TYPES, data.offerType)}</div>;
  };

  const assetTemplate = (rowData: IOfferCompact) => {
    const getSrc = (): string => {
      if (rowData.asset) {
        return Util.getAssetUrl(Util._.get(rowData, "asset.objectId", null));
      }
      if (rowData.template.assetObjectId) {
        return Util.getAssetUrl(
          Util._.get(rowData, "template.assetObjectId", null)
        );
      }
      return "";
    };

    return (
      <React.Fragment>
        <img
          alt={"..."}
          src={getSrc()}
          width="100%"
          style={{ verticalAlign: "middle", minWidth: 95 }}
        />
      </React.Fragment>
    );
  };

  const onActiveChange = (data: IOffer) => {
    data.active = !data.active;
    updateOfferIsActive(data.id, { active: data.active })
      .then(() => {
        Util.success(
          `Offer ${data.active ? "activated" : "deactivated"} successfully.`
        );
      })
      .catch((error) => {
        Util.showError(error);
        data.active = !data.active;
        forceUpdate();
      });
    forceUpdate();
  };

  return (
    <div id={IDS.offers.wrapper} style={{ marginBottom: "-10px" }}>
      <div className={"p-grid"}>
        <div className="p-col-12" style={{ paddingBottom: 0 }}>
          <div className="card card-w-title datatable-demo">
            <div
              className={
                "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows offers"
              }
            >
              <DataTable
                tableStyle={{ overflow: "visible" }}
                resizableColumns={true}
                scrollable
                scrollHeight="580"
                autoLayout={true}
                columnResizeMode={"expand"}
                rowsPerPageOptions={Util.rowsPerPageOptions}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                lazy={true}
                rows={limit}
                first={first}
                filterDisplay="row"
                value={offerList}
                paginator={true}
                loading={loading}
                sortMode={"multiple"}
                onPage={onPage}
                onSort={onSort}
                multiSortMeta={Array.from(sortMap.values())}
                totalRecords={totalItemsCount}
                selection={selectedOffers}
                header={
                  Util.hasAnyAuthority(PERMISSIONS.OFFERS_MANAGEMENT) ? (
                    <div className={"p-grid-header-components"}>
                      <Button
                        id={IDS.button.clear}
                        style={{ position: "absolute", left: "10px" }}
                        icon={"pi pi-filter"}
                        label={t("clear")}
                        onClick={onClear}
                      />
                      <Button
                        id={IDS.button.new}
                        style={{ marginRight: 5 }}
                        onClick={onNew}
                        icon="pi pi-plus"
                        label={"New"}
                      />
                      <Button
                        id={IDS.button.edit}
                        label={"Edit"}
                        style={{ marginRight: 5 }}
                        icon={"pi pi-pencil"}
                        onClick={onEdit}
                        disabled={selectedOffers.length !== 1}
                      />
                      <Button
                        id={IDS.button.delete}
                        onClick={onDelete}
                        icon="pi pi-trash"
                        className={"p-button-danger"}
                        label={"Delete"}
                        disabled={selectedOffers.length === 0}
                      />
                    </div>
                  ) : null
                }
                onSelectionChange={(e) => setSelectedOffers(e.value)}
              >
                <Column
                  selectionMode="multiple"
                  style={{ width: "3em", flex: "0.2" }}
                />
                <Column
                  field="name"
                  header="Name"
                  filter={true}
                  sortable={true}
                  filterElement={nameFilter}
                />
                <Column
                  header="Asset"
                  style={{ width: 120 }}
                  filter={false}
                  sortable={false}
                  body={assetTemplate}
                />
                <Column
                  field="template.dealershipName"
                  filterField={"d.name"}
                  sortField={"d.name"}
                  header="Dealerships"
                  filter={true}
                  sortable={true}
                  filterElement={dealershipFilter}
                />
                <Column
                  filter={true}
                  sortable={true}
                  field={"crmSource.name"}
                  filterField={"c.name"}
                  sortField={"d.crmSource.name"}
                  header={t("crmSourceName")}
                  filterElement={crmSourceNameFilter}
                />
                <Column
                  field="template.name"
                  header="Template"
                  filter={true}
                  sortable={true}
                  filterElement={templateFilter}
                />
                <Column
                  field="offerType"
                  header="Offer Type"
                  filter={true}
                  sortable={true}
                  filterElement={offerTypeFilter}
                  style={{ overflow: "visible" }}
                  body={offerTypeBody}
                />
                <Column
                  field="startDate"
                  header="Start Date"
                  filter={true}
                  sortable={true}
                  body={(data: any) =>
                    dateBody(data, "startDate", Util.usDateFormat)
                  }
                  style={{ overflow: "visible" }}
                  filterElement={startDateFilter}
                />
                <Column
                  field="endDate"
                  header="End Date"
                  filter={true}
                  sortable={true}
                  body={(data: any) =>
                    dateBody(data, "endDate", Util.usDateFormat)
                  }
                  style={{ overflow: "visible" }}
                  filterElement={endDateFilter}
                />
                <Column
                  field="created"
                  header="Created"
                  filter={true}
                  sortable={true}
                  body={(data: any) =>
                    dateBodyCreated(data, "created", Util.usDateTimeFormat)
                  }
                  style={{ overflow: "visible" }}
                  filterElement={createdFilter}
                />
                <Column
                  field="active"
                  header="Active"
                  filter={false}
                  sortable={true}
                  body={activeTemplate}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Offers;
