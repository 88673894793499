import Downloadable from "./index";

export abstract class BaseFileExportService implements Downloadable {
  private readonly _prefix: string;
  private readonly _suffix: string;
  private readonly _reportId: string;

  private _timeoutMs: number = 3000;

  protected constructor(reportId: string, prefix: string, suffix: string) {
    this._prefix = prefix;
    this._suffix = suffix;
    this._reportId = reportId;
  }


  get timeoutMs(): number {
    return this._timeoutMs;
  }

  set timeoutMs(value: number) {
    this._timeoutMs = value;
  }


  protected get prefix(): string {
    return this._prefix;
  }

  protected get suffix(): string {
    return this._suffix;
  }

  protected get reportId(): string {
    return this._reportId;
  }

  download(callback?: () => void): void {
  }
}