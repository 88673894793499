import React, { CSSProperties } from "react";

interface IProps {
  children: any;
  editable: boolean;
  style?: CSSProperties;
}

const Editable = (props: IProps) => {
  const getStyle = (): CSSProperties => {
    const style: CSSProperties = props?.style ? props.style : {};

    if (!props.editable) {
      style.opacity = 0.3;
      style.pointerEvents = "none";
    }

    return style;
  };

  return <div style={getStyle()}>{props.children}</div>;
};

export default Editable;
