import React, { useImperativeHandle, useRef, useState } from "react";
import "./visualizer.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Select from "react-select";
import { Sidebar } from "primereact/sidebar";
import CloseButton from "../../close_button/CloseButton";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import Util from "../../../util/Util";
import { Button } from "primereact/button";
import LeadModal from "../lead_modal/LeadModal";
import { PERIODS } from "../../../util/common";

interface IProps {
  data: IStats;
  startDate: string;
  endDate: string;
  pending: boolean;
  dealershipId: number;
  dealershipTimezone: string;

  onRangeChange(startDate: string, endDate: string): any;
}

export interface IStats {
  engagements?: number;
  activeSessions?: number;
  sessionsToday: number;
  today: {
    leads: {
      paid: number;
      organic: number;
      total: number;
      adProviders: { [key: string]: number };
    };
    visits: {
      paid: number;
      organic: number;
      total: number;
      adProviders: { [key: string]: number };
    };
  };
  period: {
    period: string;
    leads: {
      paid: number;
      organic: number;
      total: number;
      adProviders: { [key: string]: number };
    };
    visits: {
      paid: number;
      organic: number;
      total: number;
      adProviders: { [key: string]: number };
    };
  };
}

export const LEADS_STYLE = buildStyles({
  pathColor: `#0062ff`,
  textColor: "black",
  textSize: "25px",
});

export const VISITS_STYLE = buildStyles({
  pathColor: `#3dd598`,
  textColor: "black",
  textSize: "25px",
});

interface ILeadAvgScores {
  botAvgScore: number;
  organicAvgScore: number;
  otherAvgScore: number;
  paidAvgScore: number;
}

const Stats = React.forwardRef(
  (
    {
      dealershipId,
      endDate,
      startDate,
      data,
      onRangeChange,
      dealershipTimezone,
      pending,
    }: IProps,
    ref: any
  ) => {
    const leadModalRef = useRef<any>();
    const [period, setPeriod] = useState<any>("LAST_30_DAYS");

    const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
    const [leadAvgScores, setLeadAvgScores] = useState<ILeadAvgScores>();

    const onPeriodChange = (item: any) => {
      if (item.value !== "CUSTOM_PERIOD") {
        const period = PERIODS[item.value];

        PERIODS.CUSTOM_PERIOD.label = "Custom Period";
        PERIODS.CUSTOM_PERIOD.to = null;
        PERIODS.CUSTOM_PERIOD.from = null;

        onRangeChange(period.from, period.to);
      } else {
        setSidebarVisible(true);
      }

      setPeriod(item.value);
    };

    const onHideSidebar = (): void => {
      if (PERIODS.CUSTOM_PERIOD.label === "Custom Period") {
        setPeriod("LAST_30_DAYS");
      }

      setSidebarVisible(false);
    };

    const onApplyCustomPeriod = () => {
      if (
        PERIODS.CUSTOM_PERIOD.from &&
        (PERIODS.CUSTOM_PERIOD.from === PERIODS.CUSTOM_PERIOD.to ||
          moment(PERIODS.CUSTOM_PERIOD.from).isBefore(
            moment(PERIODS.CUSTOM_PERIOD.to)
          ))
      ) {
        PERIODS.CUSTOM_PERIOD.label = `${PERIODS.CUSTOM_PERIOD.from} - ${PERIODS.CUSTOM_PERIOD.to}`;

        onRangeChange(PERIODS.CUSTOM_PERIOD.from, PERIODS.CUSTOM_PERIOD.to);

        onHideSidebar();
      } else {
        Util.warning("Invalid date range.");
      }
    };

    const onOpenLeadsModal = (
      campaignSearch: string,
      startDate: string,
      endDate: string
    ): void => {
      leadModalRef.current.show(campaignSearch, startDate, endDate);
    };

    useImperativeHandle(ref, () => ({
      showAvgLeads: showAvgLeads,
      hideAvgLeads: hideAvgLeads,
    }));

    const showAvgLeads = (leadAvgScores: ILeadAvgScores): void => {
      setLeadAvgScores(leadAvgScores);
    };

    const hideAvgLeads = (): void => {
      setLeadAvgScores(undefined);
    };

    return (
      <div
        id={"real-time-stats"}
        className={"p-grid"}
        style={{
          border: "1px solid rgb(213, 213, 213)",
          background: "rgb(0 210 255 / 8%)",
        }}
      >
        <div
          id={"stats"}
          className={"p-col-12 p-xl-2 p-lg-2"}
          style={{ border: "1px solid rgb(213, 213, 213)" }}
        >
          <div className={"p-grid content"}>
            <div className={"p-col-12 p-lg-12 p-md-0"}>
              <label className={"title"}>ANALYTICS</label>
            </div>
            <div className={"p-col-12 p-lg-12 p-md-4 p-sm-4"}>
              <label className={"value"}>{data.activeSessions}</label>
              <div>
                <span className={"text"}>Human Sessions (active sessions)</span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-12 p-md-4 p-sm-4"}>
              <label className={"value"}>{data.engagements}</label>
              <div>
                <span className={"text"}>Total Engagements</span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-12 p-md-4 p-sm-4"}>
              <label className={"value"}>{data.sessionsToday}</label>
              <div>
                <span className={"text"}>Sessions Today</span>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            border: "1px solid rgb(213, 213, 213)",
          }}
          id={"today"}
          className={"p-col-12 p-xl-5 p-lg-5"}
        >
          <label className={"title"}>TODAY</label>
          <div style={{ margin: "auto" }} className={"p-grid"}>
            <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4 chart-wrapper"}>
              <div
                onClick={() => {
                  onOpenLeadsModal(
                    "",
                    moment().format(Util.localDateTimeFormat),
                    moment().format(Util.localDateTimeFormat)
                  );
                }}
                className={"chart-container cursor-pointer"}
              >
                <CircularProgressbar
                  value={data.today.leads.total}
                  maxValue={data.today.leads.total}
                  text={data.today.leads.total.toString()}
                  styles={LEADS_STYLE}
                  strokeWidth={4}
                />
                <label className={"text"}>
                  <span className={"lead"}>Leads</span> Total
                </label>
              </div>
            </div>
            <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4 chart-wrapper"}>
              <div
                onClick={() => {
                  onOpenLeadsModal(
                    "organic",
                    moment().format(Util.localDateTimeFormat),
                    moment().format(Util.localDateTimeFormat)
                  );
                }}
                className={"chart-container cursor-pointer"}
              >
                <CircularProgressbar
                  value={data.today.leads.organic}
                  maxValue={data.today.leads.total}
                  text={data.today.leads.organic.toString()}
                  styles={LEADS_STYLE}
                  strokeWidth={4}
                />
                <span className={"lead"}>Leads</span>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <label className={"text"}> Organic/Direct</label>
                </div>
              </div>
            </div>
            <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4 chart-wrapper"}>
              <div
                onClick={() => {
                  onOpenLeadsModal(
                    "paid_",
                    moment().format(Util.localDateTimeFormat),
                    moment().format(Util.localDateTimeFormat)
                  );
                }}
                className={"chart-container cursor-pointer"}
              >
                <div>
                  <CircularProgressbar
                    value={data.today.leads.paid}
                    maxValue={data.today.leads.total}
                    text={data.today.leads.paid.toString()}
                    styles={LEADS_STYLE}
                    strokeWidth={4}
                  />
                  <label className={"text"}>
                    <span className={"lead"}>Leads</span> Paid
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div style={{ margin: "auto" }} className={"p-grid"}>
            <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4 chart-wrapper"}>
              <div className={"chart-container"}>
                <CircularProgressbar
                  value={data.today.visits.total}
                  maxValue={data.today.visits.total}
                  text={data.today.visits.total.toString()}
                  styles={VISITS_STYLE}
                  strokeWidth={4}
                />
                <label className={"text"}>
                  <span className={"visit"}>Visits</span> Total
                </label>
              </div>
            </div>
            <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4 chart-wrapper"}>
              <div className={"chart-container"}>
                <CircularProgressbar
                  value={data.today.visits.organic}
                  maxValue={data.today.visits.total}
                  text={data.today.visits.organic.toString()}
                  styles={VISITS_STYLE}
                  strokeWidth={4}
                />
                <span className={"visit"}>Visits</span>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <label className={"text"}>Organic/Direct</label>
                </div>
              </div>
            </div>
            <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4 chart-wrapper"}>
              <div className={"chart-container"}>
                <div>
                  <CircularProgressbar
                    value={data.today.visits.paid}
                    maxValue={data.today.visits.total}
                    text={data.today.visits.paid.toString()}
                    styles={VISITS_STYLE}
                    strokeWidth={4}
                  />
                </div>
                <label className={"text"}>
                  <span className={"visit"}>Visits</span> Paid
                </label>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            textAlign: "center",
            padding: 0,
            border: "1px solid rgb(213, 213, 213)",
          }}
          id={"period"}
          className={"p-col-12 p-xl-5 p-lg-5"}
        >
          <div style={{ maxWidth: 200, margin: "auto" }}>
            <Select
              isLoading={pending}
              value={{
                label: PERIODS[period].label,
                value: period,
              }}
              //style={{ width: "100%" }}
              options={Object.keys(PERIODS).map((key: any) => {
                return {
                  label: PERIODS[key].label,
                  value: key,
                };
              })}
              placeholder={"Select period"}
              onChange={(item: any) => onPeriodChange(item)}
            />
          </div>
          <div style={{ margin: "auto" }} className={"p-grid"}>
            <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4 chart-wrapper"}>
              <div
                onClick={() => {
                  onOpenLeadsModal("", startDate, endDate);
                }}
                className={"chart-container cursor-pointer"}
              >
                <CircularProgressbar
                  value={data.period.leads.total}
                  maxValue={data.period.leads.total}
                  text={data.period.leads.total.toString()}
                  styles={LEADS_STYLE}
                  strokeWidth={4}
                />
                <span className={"lead"}>Leads</span>
                <br />
                <div style={{ width: "max-content", margin: "auto" }}>
                  <span className={"text"}>
                    Total{" "}
                    {leadAvgScores ? (
                      <span className={"red"}>
                        AES(
                        {Math.round(
                          Util._.get(leadAvgScores, "totalOrgPaidAvgScore", 0) *
                            100
                        ) / 100}
                        )
                      </span>
                    ) : null}
                  </span>
                </div>
              </div>
            </div>
            <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4 chart-wrapper"}>
              <div
                onClick={() => {
                  onOpenLeadsModal("organic", startDate, endDate);
                }}
                className={"chart-container cursor-pointer"}
              >
                <CircularProgressbar
                  value={data.period.leads.organic}
                  maxValue={data.period.leads.total}
                  text={data.period.leads.organic.toString()}
                  styles={LEADS_STYLE}
                  strokeWidth={4}
                />
                <span className={"lead"}>Leads</span>
                <br />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <span className={"text"} style={{ textAlign: "center" }}>
                    Organic/Direct{" "}
                    {leadAvgScores ? (
                      <span className={"red"}>
                        AES(
                        {Math.round(
                          Util._.get(leadAvgScores, "organicAvgScore", 0) * 100
                        ) / 100}
                        )
                      </span>
                    ) : null}
                  </span>
                </div>
              </div>
            </div>
            <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4 chart-wrapper"}>
              <div
                onClick={() => {
                  onOpenLeadsModal("paid_", startDate, endDate);
                }}
                className={"chart-container cursor-pointer"}
              >
                <div>
                  <CircularProgressbar
                    value={data.period.leads.paid}
                    maxValue={data.period.leads.total}
                    text={data.period.leads.paid.toString()}
                    styles={LEADS_STYLE}
                    strokeWidth={4}
                  />
                </div>
                <span className={"lead"}>Leads</span>
                <br />
                <div style={{ width: "max-content", margin: "auto" }}>
                  <span className={"text"}>
                    Paid{" "}
                    {leadAvgScores ? (
                      <span className={"red"}>
                        AES(
                        {Math.round(
                          Util._.get(leadAvgScores, "paidAvgScore", 0) * 100
                        ) / 100}
                        )
                      </span>
                    ) : null}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div style={{ margin: "auto" }} className={"p-grid"}>
            <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4 chart-wrapper"}>
              <div className={"chart-container"}>
                <CircularProgressbar
                  value={data.period.visits.total}
                  maxValue={data.period.visits.total}
                  text={data.period.visits.total.toString()}
                  styles={VISITS_STYLE}
                  strokeWidth={4}
                />
                <label className={"text"}>
                  <span className={"visit"}>Visits</span> Total
                </label>
              </div>
            </div>
            <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4 chart-wrapper"}>
              <div className={"chart-container"}>
                <CircularProgressbar
                  value={data.period.visits.organic}
                  maxValue={data.period.visits.total}
                  text={data.period.visits.organic.toString()}
                  styles={VISITS_STYLE}
                  strokeWidth={4}
                />
                <span className={"visit"}>Visits</span>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <label className={"text"}>Organic/Direct</label>
                </div>
              </div>
            </div>
            <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4 chart-wrapper"}>
              <div className={"chart-container"}>
                <div>
                  <CircularProgressbar
                    value={data.period.visits.paid}
                    maxValue={data.period.visits.total}
                    text={data.period.visits.paid.toString()}
                    styles={VISITS_STYLE}
                    strokeWidth={4}
                  />
                </div>
                <label className={"text"}>
                  <span className={"visit"}>Visits</span> Paid
                </label>
              </div>
            </div>
          </div>
        </div>

        <Sidebar
          style={{ width: 300 }}
          position={"right"}
          visible={sidebarVisible}
          onHide={onHideSidebar}
          showCloseIcon={false}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <CloseButton onHide={onHideSidebar} />
            <div
              style={{
                marginTop: 25,
                marginLeft: -15,
                marginRight: -15,
                borderBottom: "1px solid #afa6a6",
              }}
            >
              <label style={{ fontSize: 20, marginLeft: 20 }}>
                Custom Period
              </label>
            </div>
            <div style={{ padding: 5, width: "100%" }}>
              <label>From</label>
              <div style={{ width: "100%" }}>
                <Calendar
                  yearNavigator={true}
                  monthNavigator={true}
                  maxDate={moment().toDate()}
                  inputStyle={{ width: "100%" }}
                  yearRange={`2010:${moment().format("YYYY")}`}
                  value={moment(PERIODS.CUSTOM_PERIOD.from).toDate()}
                  onChange={(e: any) => {
                    const currentTime = moment();
                    const h = currentTime.get("hour");
                    const m = currentTime.get("minute");
                    const s = currentTime.get("seconds");

                    const from = moment(e.value).set({
                      hours: h,
                      minutes: m,
                      seconds: s,
                    });

                    PERIODS.CUSTOM_PERIOD.from = from.format(
                      Util.localDateTimeFormat
                    );
                  }}
                />
              </div>
              <label>To</label>
              <div style={{ width: "100%" }}>
                <Calendar
                  yearNavigator={true}
                  monthNavigator={true}
                  maxDate={moment().toDate()}
                  inputStyle={{ width: "100%" }}
                  yearRange={`2010:${moment().format("YYYY")}`}
                  value={moment(PERIODS.CUSTOM_PERIOD.to).toDate()}
                  onChange={(e: any) => {
                    const currentTime = moment();
                    const h = currentTime.get("hour");
                    const m = currentTime.get("minute");
                    const s = currentTime.get("seconds");

                    const to = moment(e.value).set({
                      hours: h,
                      minutes: m,
                      seconds: s,
                    });

                    PERIODS.CUSTOM_PERIOD.to = to.format(
                      Util.localDateTimeFormat
                    );
                  }}
                />
              </div>
              <Button
                label={"Apply"}
                style={{ marginTop: 5, float: "right" }}
                onClick={onApplyCustomPeriod}
              />
            </div>
          </div>
        </Sidebar>
        <LeadModal
          ref={leadModalRef}
          dealershipId={dealershipId}
          dealershipTimezone={dealershipTimezone}
        />
      </div>
    );
  }
);

export default Stats;
