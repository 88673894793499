import Axios from "../config/Axios";
import {
  IGeneratedLeadDto, ILeadsExportRequestDto,
  ILeadsSearchRequestDto,
  IPageable
} from "../../index.dts";

export const getDailyReport = (query: string) => Axios.instance.get(`reports/daily${query}`);
export const getGeneratedLeads = (dto: ILeadsSearchRequestDto): Promise<IPageable<IGeneratedLeadDto>> => Axios.instance.post('/leads/list', dto);
export const createReport = (dto: ILeadsExportRequestDto) => Axios.instance.post("leads/createReport", dto);
export const downloadReport = (reportId: string) => Axios.instance.get(`leads/downloadReport?id=${reportId}`, {responseType: "blob"});