import {
  IStore,
  ITrafficMarker,
  ITrafficMarkerRequestDto,
} from "../../../../../index.dts";
import React, { useEffect, useState } from "react";
import {
  createTrafficMarker,
  deleteTrafficMarkers,
  getTrafficMarkers,
  updateTrafficMarker,
} from "../../../../actions/trafficMarkerActions";
import { shallowEqual, useSelector } from "react-redux";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import Util from "../../../../util/Util";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import { get } from "lodash";
import { useForceUpdate } from "../../../../hooks/useForceUpdate";
import { IDS } from "../../../../views/constants";
import { useTranslation } from "react-i18next";

interface IProps {
  dealershipId: number;
}

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

let hasErrors: boolean = false;

const EventMarkers = (props: IProps) => {
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);
  const [first, setFirst] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [sortMap] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(new Map([["created", { field: "created", order: -1 }]]));

  const [selectedRows, setSelectedRows] = useState<ITrafficMarker[]>([]);

  const [marker, setMarker] = useState<ITrafficMarker>();
  const [markers, setMarkers] = useState<ITrafficMarker[]>([]);

  const user = useSelector((store: IStore) => store.auth.user, shallowEqual);

  const [titleFilterValue, setTitleFilterValue] = useState<string>("");
  const [ownerFilterValue, setOwnerFilterValue] = useState<string>("");
  const [startDateFilterValue, setStartDateFilterValue] = useState<string>("");
  const [reminderDateFilterValue, setReminderDateFilterValue] =
    useState<string>("");

  const onClear = () => {
    sortMap.clear();
    setTitleFilterValue("");
    setOwnerFilterValue("");
    setStartDateFilterValue("");
    setReminderDateFilterValue("");
  };

  useEffect(() => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getTrafficMarkerList(props.dealershipId, 0, limit).finally(() => {
        setPage(0);
        setSelectedRows([]);
        searchTimeoutInterval = 300;
      });
    }, searchTimeoutInterval);
    // eslint-disable-next-line
  }, [
    props.dealershipId,
    titleFilterValue,
    ownerFilterValue,
    startDateFilterValue,
    reminderDateFilterValue,
  ]);

  const onPage = (event: any) => {
    setLimit(event.rows);
    setFirst(event.first);

    getTrafficMarkerList(props.dealershipId, event.page, event.rows).finally(
      () => {
        setPage(event.page);
      }
    );
  };

  const onSort = (event: any) => {
    sortMap.clear();
    if (event && Array.isArray(event.multiSortMeta)) {
      event.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getTrafficMarkerList(props.dealershipId, page, limit);
    }
  };

  const onRowEditCancel = (): void => {
    setMarker(undefined);
  };

  const onRowEditSave = (props: any): void => {
    props.originalEvent.preventDefault();

    if (hasErrors) {
      return;
    }

    const marker: ITrafficMarker = get(props, "newData", null);

    if (marker.id === 0) {
      createMarker(marker);
    } else {
      updateMarker(marker.id, marker);
    }
  };

  const createMarker = (marker: ITrafficMarker): void => {
    Util.globalSpinner().show();
    createTrafficMarker(marker)
      .then((response) => {
        markers.unshift(response.data);
        Util.success("Traffic marker created successfully");

        forceUpdate();
      })
      .catch((error) => Util.showError(error))
      .finally(() => {
        setMarker(undefined);
        Util.globalSpinner().hide();
      });
  };

  const updateMarker = (id: number, marker: ITrafficMarker): void => {
    Util.globalSpinner().show();
    updateTrafficMarker(id, marker)
      .then((response) => {
        Util.success("Traffic marker updated successfully");
      })
      .catch((error) => Util.showError(error))
      .finally(() => Util.globalSpinner().hide());
  };

  const onAdd = (): void => {
    if (marker) {
      return;
    }

    setMarker({
      axisValue: "",
      id: 0,
      title: "",
      startDate: null,
      reminderDate: null,
      dealershipId: props.dealershipId,
      created: moment().format(Util.localDateTimeFormat),
      owner: {
        id: user.id,
        email: user.email,
      },
    });

    setTimeout(() => {
      Util.$("#event_markers .p-row-editor-init-icon")[0]?.click();
      Util.$("#event_markers .title-editor")[0]?.focus();
    });

    setTimeout(() => {
      document.getElementById("title-editor")?.focus();
    }, 130);
  };

  const onDelete = (): void => {
    const ids = selectedRows
      .filter((marker) => marker.id !== 0)
      .map((marker) => marker.id);

    if (ids.length === 0) {
      return;
    }

    confirmAlert({
      title: `You are about to delete ${ids.length} event marker${
        ids.length > 1 ? "s" : ""
      }!`,
      message: "Are you sure you want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setLoading(true);
            deleteTrafficMarkers(ids)
              .then(() => {
                Util.success("Traffic markers deleted successfully");
              })
              .catch((error) => Util.showError(error))
              .finally(() => {
                getTrafficMarkerList(props.dealershipId, 0, limit)
                  .catch((error) => Util.showError(error))
                  .finally(() => {
                    setPage(0);
                    setSelectedRows([]);
                  });
              });
          },
          className: "confirm-save-btn",
        },
        {
          label: "Cancel",
          className: "confirm-cancel-btn",
          onClick: () => {},
        },
      ],
    });
  };

  const getTrafficMarkerList = (
    dealershipId: number,
    pageNumber: number,
    pageLimit: number
  ) =>
    new Promise((resolve, reject) => {
      setLoading(true);

      const dto: ITrafficMarkerRequestDto = {
        dealershipId: dealershipId,
        pageNumber: pageNumber,
        pageLimit: pageLimit,
        title: titleFilterValue,
        owner: ownerFilterValue,
        startDate: startDateFilterValue,
        endDate: reminderDateFilterValue,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
      };

      getTrafficMarkers(dto)
        .then((response) => {
          resolve(response);

          setMarkers(response.data.content);
          setTotalRecords(response.data.totalElements);
        })
        .catch((error) => {
          reject(error);
          Util.showError(error);
        })
        .finally(() => setLoading(false));
    });

  const onCalendarChange = (rowData: any, field: string, value: any): void => {
    if (value) {
      rowData[field] = moment(value).format(Util.localDateFormat);
      return;
    }

    rowData[field] = undefined;
  };

  const getCalendarValue = (value?: string): Date | undefined => {
    if (!value) {
      return;
    }

    return moment(value).toDate();
  };

  const markerEditor = (
    props: any,
    field: "title" | "startDate" | "reminderDate"
  ) => {
    switch (field) {
      case "title":
        return (
          <InputText
            placeholder="Enter title"
            id="title-editor"
            className={"title-editor"}
            maxLength={254}
            value={props.value}
            onChange={(e: any) => props.editorCallback(e.target.value)}
          />
        );
      case "startDate":
        return (
          <Calendar
            placeholder="Enter start date"
            style={{ width: "100%" }}
            inputStyle={{ width: "100%" }}
            value={getCalendarValue(props.rowData[field])}
            onChange={(e): any =>
              onCalendarChange(props.rowData, field, e.value)
            }
          />
        );
      case "reminderDate":
        return (
          <Calendar
            placeholder="Enter reminder date"
            style={{ width: "100%" }}
            inputStyle={{ width: "100%" }}
            value={getCalendarValue(props.rowData[field])}
            onChange={(e): any =>
              onCalendarChange(props.rowData, field, e.value)
            }
          />
        );
    }
  };

  const titleEditor = (props: any) => {
    return markerEditor(props, "title");
  };

  const startDateEditor = (props: any) => {
    return markerEditor(props, "startDate");
  };

  const reminderDateEditor = (props: any) => {
    return markerEditor(props, "reminderDate");
  };

  const getMarkers = (): ITrafficMarker[] => {
    const array = marker ? [marker] : [];

    return array.concat(markers);
  };

  const rowEditorValidator = (rowData: any): boolean => {
    hasErrors = false;

    const title = get(rowData, "title", null);
    const startDate = get(rowData, "startDate", null);
    const reminderDate = get(rowData, "reminderDate", null);

    if (Util.isEmpty(title)) {
      Util.warning("Marker title is empty.");

      hasErrors = true;
    } else if (title.length > 254) {
      Util.warning("The Marker title is too long.");

      hasErrors = true;
    } else if (Util.isEmpty(startDate)) {
      Util.warning("Start date is empty.");

      hasErrors = true;
    } else if (Util.isEmpty(reminderDate)) {
      Util.warning("Reminder Date date is empty.");

      hasErrors = true;
    } else if (moment(startDate).isSameOrAfter(moment(reminderDate))) {
      Util.warning(
        `Traffic Marker reminder date {${reminderDate}} should be after start date {${startDate}}`
      );

      hasErrors = true;
    }

    return !hasErrors;
  };

  const titleFilter = (
    <InputText
      style={{ width: "100%" }}
      value={titleFilterValue}
      onChange={(e: any) => {
        setTitleFilterValue(
          Util.getDefaultIfNull(get(e, "target.value", null), "")
        );
      }}
    />
  );

  const ownerFilter = (
    <InputText
      style={{ width: "100%" }}
      value={ownerFilterValue}
      onChange={(e: any) => {
        setOwnerFilterValue(
          Util.getDefaultIfNull(get(e, "target.value", null), "")
        );
      }}
    />
  );

  const startDateFilter = (
    <Calendar
      style={{ width: "100%" }}
      inputStyle={{ width: "100%" }}
      value={
        !Util.isEmpty(startDateFilterValue)
          ? moment(startDateFilterValue).toDate()
          : undefined
      }
      onChange={(e): any => {
        const value = get(e, "value");
        if (value) {
          setStartDateFilterValue(
            moment(String(value)).format(Util.localDateFormat)
          );
          return;
        }

        setStartDateFilterValue("");
      }}
    />
  );

  const reminderDateFilter = (
    <Calendar
      style={{ width: "100%" }}
      inputStyle={{ width: "100%" }}
      value={
        !Util.isEmpty(reminderDateFilterValue)
          ? moment(reminderDateFilterValue).toDate()
          : undefined
      }
      onChange={(e): any => {
        const value = get(e, "value");
        if (value) {
          setReminderDateFilterValue(
            moment(String(value)).format(Util.localDateFormat)
          );
          return;
        }

        setReminderDateFilterValue("");
      }}
    />
  );

  const startDateTemplate = (data: any) => {
    return <div>{moment(data.startDate).format(Util.usDateFormat)}</div>;
  };

  const reminderDateTemplate = (data: any) => {
    return <div>{moment(data.reminderDate).format(Util.usDateFormat)}</div>;
  };

  const titleBody = (data: any) => {
    return <div style={{ wordBreak: "break-word" }}>{data.title}</div>;
  };

  const checkboxTemplate = (data: ITrafficMarker) => {
    const index = selectedRows.findIndex((m) => m.id === data.id);
    return (
      <Checkbox
        checked={index !== -1}
        disabled={data.owner.id !== user.id || data.id === 0}
        onChange={(e) => onSelectRow(e.checked, data)}
      />
    );
  };

  const onSelectRow = (checked: boolean, marker: ITrafficMarker): void => {
    const index = selectedRows.findIndex((m) => m.id === marker.id);

    if (index !== -1) {
      selectedRows.splice(index, 1);
    } else {
      selectedRows.push(marker);
    }

    forceUpdate();
  };

  return (
    <Panel
      id={"event_markers"}
      style={{ padding: 2 }}
      header={
        <div style={{ display: "flex" }}>
          <span>Markers</span>
          <div className={"p-grid-header-components"}>
            <Button
              id={IDS.button.clear}
              icon={"pi pi-filter"}
              label={t("clear")}
              onClick={onClear}
            />
            <Button
              disabled={!!marker}
              style={{ marginRight: 5 }}
              onClick={onAdd}
              icon="pi pi-plus"
              label={"Add"}
            />
            <Button
              disabled={selectedRows.length === 0}
              style={{ marginRight: 5 }}
              onClick={onDelete}
              icon="pi pi-trash"
              className={"p-button-danger"}
              label={"Delete"}
            />
          </div>
        </div>
      }
    >
      <div
        className={
          limit > 10
            ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting"
            : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
        }
      >
        <DataTable
          rowsPerPageOptions={Util.rowsPerPageOptions}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
          value={getMarkers()}
          lazy={true}
          scrollable
          paginator={true}
          editMode={"row"}
          totalRecords={totalRecords}
          rows={limit}
          first={first}
          loading={loading}
          filterDisplay="row"
          onPage={onPage}
          onSort={onSort}
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          onRowEditComplete={onRowEditSave}
          onRowEditCancel={onRowEditCancel}
          rowEditValidator={rowEditorValidator}
          sortMode={"multiple"}
          multiSortMeta={Array.from(sortMap.values())}
        >
          <Column
            sortable={false}
            body={checkboxTemplate}
            style={{ width: "3em" }}
          />
          <Column
            filter={true}
            sortable={!marker}
            field={"title"}
            header={"Title"}
            editor={titleEditor}
            body={titleBody}
            filterElement={titleFilter}
          />
          <Column
            filter={true}
            sortable={!marker}
            field={"startDate"}
            header={"Start Date"}
            editor={startDateEditor}
            body={startDateTemplate}
            filterElement={startDateFilter}
          />
          <Column
            filter={true}
            sortable={!marker}
            field={"reminderDate"}
            header={"Reminder Date"}
            body={reminderDateTemplate}
            editor={reminderDateEditor}
            filterElement={reminderDateFilter}
          />
          <Column
            filter={true}
            sortable={!marker}
            field={"owner.email"}
            header={"Owner"}
            filterElement={ownerFilter}
          />
          <Column
            rowEditor
            headerStyle={{ width: "7rem" }}
            bodyStyle={{ textAlign: "center" }}
          />
        </DataTable>
      </div>
    </Panel>
  );
};

export default EventMarkers;
