export const DEFAULT_TRIGGERED_BTN_SOURCE = `
<style type="text/css">
    @-webkit-keyframes pi-color-change {
        0% { color: #007CC2; }
        50% { color: inherit; }
        100% { color: #007CC2; }
    }
    @-moz-keyframes pi-color-change {
        0% { color: #007CC2; }
        50% { color: inherit; }
        100% { color: #007CC2; }
    }
    @-ms-keyframes pi-color-change {
        0% { color: #007CC2; }
        50% { color: inherit; }
        100% { color: #007CC2; }
    }
    @-o-keyframes pi-color-change {
        0% { color: #007CC2; }
        50% { color: inherit; }
        100% { color: #007CC2; }
    }
    @keyframes pi-color-change {
        0% { color: #007CC2; }
        50% { color: inherit; }
        100% { color: #007CC2; }
    }
  
    /* New animation for background color */
    @-webkit-keyframes bg-color-change {
        0% { background-color: inherit; }
        50% { background-color: #007CC2; }
        100% { background-color: inherit; }
    }
    @-moz-keyframes bg-color-change {
        0% { background-color: inherit; }
        50% { background-color: #007CC2; }
        100% { background-color: inherit; }
    }
    @-ms-keyframes bg-color-change {
        0% { background-color: inherit; }
        50% { background-color: #007CC2; }
        100% { background-color: inherit; }
    }
    @-o-keyframes bg-color-change {
        0% { background-color: inherit; }
        50% { background-color: #007CC2; }
        100% { background-color: inherit; }
    }
    @keyframes bg-color-change {
        0% { background-color: inherit; }
        50% { background-color: #007CC2; }
        100% { background-color: inherit; }
    }
</style>

<div class="triggered-btn-template" style="
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
    width: 100%;">
    <div style="
        -webkit-animation: bg-color-change 2s infinite;
        -moz-animation: bg-color-change 2s infinite;
        -o-animation: bg-color-change 2s infinite;
        -ms-animation: bg-color-change 2s infinite;
        animation: bg-color-change 2s infinite;
        width: 300px;
        color: white;
        padding: 5px 10px;
        border-radius: 20px;
    ">
        <span style="
            color: red;
            font-size: 20px;
            font-weight: bold;
            -webkit-animation: pi-color-change 2s infinite;
            -moz-animation: pi-color-change 2s infinite;
            -o-animation: pi-color-change 2s infinite;
            -ms-animation: pi-color-change 2s infinite;
            animation: pi-color-change 2s infinite;
            cursor: pointer;
            ">
            I'm interested!
        </span>
    </div>
</div>`;
