import {IChatWidgetEndChatResult, IUser} from "../../../index.dts";

import {CHAT_ACTIONS, CHAT_HISTORY_TYPE} from "../../util/Enums";
import {StartChatParams} from ".";
import Util from "../../util/Util";
import store from "../../config/store";
import {onChatEnd, onPersistentChatEnd} from "../../actions/chatActions";

const _pi_lib = Util._.get(window, "_pi_lib", null);

export default class ChatWidget {
  public instance: any;

  public initialize(fn: Function) {
    if (!this.instance) {
      return;
    }

    this.instance.initialize(fn);
  }

  public join(args: any) {
    if (!this.instance) {
      return
    }

    this.instance.join(args);
  }

  public isInitialized(): boolean {
    if (!this.instance) {
      return false;
    }

    return !!this.instance._initialized
  }

  public open(): void {
    if (!this.instance) {
      return
    }

    this.instance.open();
  }

  public init(user: IUser) {
    try {
      this.instance = new _pi_lib.ChatWidget({
        appId: process.env.REACT_APP_SEND_BIRD_APP_ID,
        user: {
          id: Util._.get(user, "externalRefId", null),
          name: Util._.get(user, "email"),
          avatar: 'https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/7_avatar-512.png',
        },
        defaults: {
          avatar: 'https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/7_avatar-512.png',
          nickname: 'Visitor',
          userTitle: 'Visitor',
        },
        callbacks: {
          onChatEnded: (result: IChatWidgetEndChatResult, error: string) => {
            if (error) {
              Util.error(error);
              return;
            }
            this.onChatEnded(user, result);
          },
          onUserAction: (result: any, error: any) => {
            if (error) {
              Util.error(error);
              return;
            }
            this.onUserAction(result);
          }
        }
      })
    } catch (e) {
      console.error(e);
    }
  }

  private onChatEnded = (user: IUser, result: IChatWidgetEndChatResult) => {
    const visitorChatMap = store.getState().myTraffic.visitorChatMap;

    const visitor = result.channel.members.find(item => visitorChatMap.has(item.id));

    if (!visitor) {
      console.log("Visitor is null");
      return;
    }

    const data = visitorChatMap.get(visitor.id);

    const type = Util._.get(data, "type", null);

    if (!type) {
      console.log("Chat type is null");
      return;
    }

    if (type === CHAT_HISTORY_TYPE.my_traffic) {
      const chatHistoryId = Util._.get(data, "chatHistoryId", null);

      if (!chatHistoryId) {
        console.log("Chat history id is null");
        return;
      }

      onChatEnd(chatHistoryId);
    } else {
      const visitorExtRefId = Util._.get(data, "visitorExtRefId", null);
      const dealershipExtRefId = Util._.get(data, "dealershipExtRefId", null);

      if (!visitorExtRefId) {
        console.log("Visitor extRefId is null");
        return;
      }

      if (!dealershipExtRefId) {
        console.log("Dealership extRefId is null");
        return;
      }

      onPersistentChatEnd({
        visitorRefId: visitorExtRefId,
        dealershipRefId: dealershipExtRefId
      });
    }

    visitorChatMap.delete(visitor.id);
  }

  private onUserAction = (result: any) => {
    const code = Util._.get(result, "action.code", null);
    const userId = Util._.get(result, "action.userId", null);

    const visitor = result.channel.members.find((item: any) => item.id === userId);
    const name = Util._.get(visitor, "name", null);

    switch (code) {
      case CHAT_ACTIONS.JOINED_CHAT:
        Util.success(`The visitor joins the chat session! (Visitor: ${name})`);
        break
      case CHAT_ACTIONS.CLOSED_CHAT:
        Util.warning(`The visitor left the chat session!. (Visitor: ${name})`);
        break;
      default:
        Util.warning(`Unimplemented chat action "${code}"`);
    }
  }

  start(data: StartChatParams): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isInitialized()) {
        this.join(data);
        resolve();
      } else {
        this.initialize((_result: any, error: any) => {
          if (error) {
            reject(error);
          } else {
            this.open();
            this.join(data);
          }
        });
      }
    });
  }
}