import React, { useEffect, useState } from "react";
import "./brands.css";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import Util from "../../util/Util";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import {
  createBrand,
  deleteBrands,
  getBrandList,
  updateBrand,
} from "../../actions/brandActions";
import { IBrand } from "../../../index.dts";
import moment from "moment-timezone";
import { InputText } from "primereact/inputtext";
import { confirmAlert } from "react-confirm-alert";
import { Sidebar } from "primereact/sidebar";
import CloseButton from "../../components/close_button/CloseButton";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { MODE } from "../../util/Enums";
import { IDS } from "../constants";
import { useTranslation } from "react-i18next";

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

const WARNINGS = {
  NAME: "",
};

export function Brands(props: any) {
  const forceUpdate = useForceUpdate();
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const { t } = useTranslation();

  const [mode, setMode] = useState<MODE>(MODE.NEW);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(0);
  const [limit, setLimit] = useState(Util.dataGridDefaultLimit);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [brandList, setBrandList] = useState<IBrand[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<IBrand[]>([]);
  const [sortMap] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(new Map());

  const [nameFilterValue, setNameFilterValue] = useState("");

  const [selectedBrandName, setSelectedBrandName] = useState<string>("");

  useEffect(() => {
    return () => {
      resetWarnings();
    };
  }, []);

  const onClear = () => {
    sortMap.clear();
    setNameFilterValue("");
  };

  useEffect(() => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getData(0, limit).finally(() => {
        setPage(0);
        setFirst(0);
        searchTimeoutInterval = 500;
        setSelectedBrands([]);
      });
    }, searchTimeoutInterval);
    // eslint-disable-next-line
  }, [nameFilterValue]);

  const getData = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      getBrandList({
        name: nameFilterValue,
        pageLimit: limit,
        pageNumber: page,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
      })
        .then((response) => {
          setBrandList(response.data.content);
          setTotalItemsCount(response.data.totalElements);

          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);

          reject(error);
        })
        .finally(() => setLoading(false));
    });

  const onNew = (): void => {
    setMode(MODE.NEW);
    setShowSidebar(true);
  };

  const onEdit = (): void => {
    if (selectedBrands.length === 1) {
      setMode(MODE.EDIT);
      setSelectedBrandName(selectedBrands[0].name);

      setShowSidebar(true);
    } else {
      Util.warning("Please choose only one brand");
    }
  };

  const onDelete = (): void => {
    if (selectedBrands.length > 0) {
      confirmAlert({
        title: `You are about to delete ${selectedBrands.length} brand${
          selectedBrands.length > 1 ? "s" : ""
        }!`,
        message: "Are you sure you want to do this?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              Util.globalSpinner().show();
              deleteBrands(selectedBrands.map((brand) => brand.id))
                .then(() => Util.success("Brand(s) deleted successfully."))
                .catch((error) => Util.showError(error))
                .finally(() => {
                  Util.globalSpinner().hide();
                  getData(0, limit).finally(() => {
                    setPage(0);
                    setFirst(0);
                    setSelectedBrands([]);
                  });
                });
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    } else {
      Util.warning("Please choose at least one brand.");
    }
  };

  const onSort = (data: any): void => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getData(page, limit);
    }
  };

  const resetWarnings = (): void => {
    WARNINGS.NAME = "";
  };

  const onPage = (event: any): void => {
    getData(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
      setSelectedBrands([]);
    });
  };

  const createdTemplate = (data: any) => {
    return (
      <div>
        {moment(Util.utcToLocal(data.created)).format(Util.usDateTimeFormat)}
      </div>
    );
  };

  const nameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={nameFilterValue}
      onChange={(e: any) => {
        setNameFilterValue(e.target.value || "");
      }}
    />
  );

  const onHideSidebar = (): void => {
    setShowSidebar(false);
    setSelectedBrandName("");
  };

  const isValid = (): boolean => {
    resetWarnings();
    if (Util.isEmpty(selectedBrandName)) {
      WARNINGS.NAME = "Invalid name.";
    }
    return !WARNINGS.NAME.trim();
  };

  const onSave = (): void => {
    if (isValid()) {
      Util.globalSpinner().show();
      if (mode === MODE.NEW) {
        createBrand(selectedBrandName)
          .then(() => {
            Util.success("Brand created successfully.");
          })
          .catch((error) => Util.showError(error))
          .finally(() => {
            getData(page, limit).finally(() => {
              onHideSidebar();
              setSelectedBrands([]);
              Util.globalSpinner().hide();
            });
          });
      } else {
        updateBrand(selectedBrands[0].id, selectedBrandName)
          .then(() => {
            Util.success("Brand updated successfully.");
          })
          .catch((error) => Util.showError(error))
          .finally(() => {
            getData(page, limit).finally(() => {
              onHideSidebar();
              setSelectedBrands([]);
              Util.globalSpinner().hide();
            });
          });
      }
    } else {
      forceUpdate();
    }
  };

  return (
    <div id={"brands-view"}>
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div className="card card-w-title datatable-demo">
            <div
              className={
                limit > 10
                  ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting"
                  : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
              }
            >
              <DataTable
                resizableColumns={true}
                filterDisplay="row"
                columnResizeMode={"expand"}
                rowsPerPageOptions={Util.rowsPerPageOptions}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                paginator={true}
                scrollable
                rows={limit}
                lazy={true}
                first={first}
                totalRecords={totalItemsCount}
                value={brandList}
                loading={loading}
                sortMode={"multiple"}
                multiSortMeta={Array.from(sortMap.values())}
                selection={selectedBrands}
                onPage={onPage}
                onSort={onSort}
                onSelectionChange={(e) => setSelectedBrands(e.value)}
                header={
                  <div className={"p-grid-header-components"}>
                    <Button
                      id={IDS.button.clear}
                      style={{ position: "absolute", left: "10px" }}
                      icon={"pi pi-filter"}
                      label={t("clear")}
                      onClick={onClear}
                    />
                    <Button
                      style={{ marginRight: 5 }}
                      onClick={onNew}
                      icon="pi pi-plus"
                      label={"New"}
                    />
                    <Button
                      onClick={onEdit}
                      icon={"pi pi-pencil"}
                      label={"Edit"}
                      disabled={selectedBrands.length !== 1}
                    />
                    <Button
                      onClick={onDelete}
                      icon="pi pi-trash"
                      className="p-button-danger"
                      label={"Delete"}
                      disabled={selectedBrands.length === 0}
                    />
                  </div>
                }
              >
                <Column
                  selectionMode="multiple"
                  style={{ width: "3em", flex: "0.03" }}
                />
                <Column
                  field="name"
                  header="Name"
                  filter={true}
                  sortable={true}
                  filterElement={nameFilter}
                />
                <Column
                  style={{ overflow: "visible" }}
                  field="created"
                  header="Created"
                  filter={false}
                  sortable={true}
                  body={createdTemplate}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        style={{ width: 350 }}
        position={"right"}
        visible={showSidebar}
        onHide={onHideSidebar}
        showCloseIcon={false}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <CloseButton onHide={onHideSidebar} />
          <div
            style={{
              marginTop: 25,
              marginLeft: -15,
              marginRight: -15,
              borderBottom: "1px solid #afa6a6",
            }}
          >
            <label
              style={{
                fontSize: 20,
                marginLeft: 20,
              }}
            >
              {mode === MODE.NEW ? "Create Brand" : "Edit Brand"}
            </label>
          </div>
          <div style={{ padding: 5, height: "100%", width: "100%" }}>
            <div className={"grid"}>
              <label>Name</label>
              <div style={{ width: "100%" }}>
                <InputText
                  maxLength={50}
                  style={{ width: "100%" }}
                  value={selectedBrandName}
                  onChange={(e: any) => setSelectedBrandName(e.target.value)}
                />
                <span className={"warning-label"}>{WARNINGS.NAME}</span>
              </div>
            </div>
            <div style={{ marginTop: 10 }} className={"grid"}>
              <Button
                onClick={onSave}
                style={{ float: "right" }}
                label={"Save"}
                icon={"pi pi-save"}
              />
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default Brands;
