import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDS } from "../constants";
import {
  DealershipCdkDataDto,
  DealershipCdkDataOpCodeCreateDto,
  IStore,
  DealershipCdkDataCreateUpdateDto,
  DealershipCdkDataOpCodePartsDto,
  IUser,
} from "../../../index.dts";
import { Dropdown } from "primereact/dropdown";
import TagsInput from "react-tagsinput";
import {
  createCDKData,
  getCDKData,
  getLaborTypes,
  getServiceAdvisors,
  getServiceTechnician,
  getVinNumber,
  updateCDKData,
} from "../../actions/cdkROassist";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import Util from "../../util/Util";
import AsyncSelect from "react-select/async";
import { shallowEqual, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { MODE } from "../../util/Enums";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";

export function CdkROAssistForm(props: any) {
  const { t }: any = useTranslation();
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const user: IUser = useSelector(
    (store: IStore) => store.auth.user,
    shallowEqual
  );
  const [selectedOpCodes, setSelectedOpcodes] = useState<any[]>([]);
  const [backModal, setBackModal] = useState<boolean>(false);

  const isROAdmin =
    user?.role?.permissions?.filter(
      (permission) => permission?.name === "RO_ADMINISTRATOR"
    )?.length > 0;
  const isROAdvisor =
    user?.role?.permissions?.filter(
      (permission) => permission?.name === "RO_ADVISOR"
    )?.length > 0;
  const isROTech =
    user?.role?.permissions?.filter(
      (permission) => permission?.name === "RO_PARTS_TECH"
    )?.length > 0;

  useEffect(() => {
    onMount(props.location.state);

    return () => {
      history.block(() => {
        return true;
      });
    };
    // eslint-disable-next-line
  }, []);

  const onMount = (
    state: { mode: MODE; cdkRO?: DealershipCdkDataDto } | null
  ) => {
    if (state) {
      setMode(state.mode);
      if (state.mode === MODE.EDIT && state.cdkRO) {
        getCDKData(state.cdkRO.id).then((response) => {
          if (response?.data) {
            setCdkRO(response?.data);
          }
        });
      }
    }
  };

  const [spinnerActivated, setSpinnerActivated] = useState<boolean>(false);
  const [mode, setMode] = useState<MODE>(MODE.NEW);
  const [showOpCodesModal, setShowOpcodesModal] = useState<boolean>(false);
  const [showOpCodePartModal, setShowOpcodePartModal] =
    useState<boolean>(false);
  const [showCloseROModal, setShowCloseROModal] = useState<boolean>(false);
  const [showDeleteROModal, setShowDeleteROModal] = useState<boolean>(false);
  const [parts, setParts] = useState<any>([]);
  const [partsToAdd, setPartsToAdd] = useState<any>([]);
  const [loadParts, setLoadParts] = useState<boolean>(false);
  const [cdkRO, setCdkRO] = useState<DealershipCdkDataDto>({
    id: 0,
    status: "open",
    customerFirstName: "",
    customerLastName: "",
    address: "",
    roNumber: 0, //autogenerated from BE
    year: 0,
    make: "",
    model: "",
    vin: "",
    mileageIn: "",
    mileageOut: "",
    tag: "",
    roOpened: "",
    roClosed: "",
    line: "",
    opCodes: [],
    hourSold: "",
    laborSales: "",
    sales: "",
    techNumber: "",
    laborType: "",
    paymentMethod: "",
    shopSupply: "",
    taxes: "",
    total: "",
    serviceAdvisor: "",
    serviceTechnician: "",
  });

  const [opcode, setOpcode] = useState<DealershipCdkDataOpCodeCreateDto>({
    name: "",
    description: "",
    laborType: "",
    laborCost: "",
    parts: [],
    total: "",
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [opcodepart, setOpcodepart] = useState<DealershipCdkDataOpCodePartsDto>(
    {
      name: "",
      quantity: 0,
      partPrice: "",
    }
  );
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  useEffect(() => {
    const fetchParts = () => {
      setParts(partsToAdd);
    };

    fetchParts();
  }, [loadParts, partsToAdd]);

  const setValue = (field: string, value: any) => {
    const type = typeof value;

    switch (type) {
      case "string":
        // @ts-ignore
        cdkRO[field] = value;
        break;
      default:
        // @ts-ignore
        cdkRO[field] = value;
    }
    forceUpdate();
  };

  const setValueOpcode = (field: string, value: any) => {
    const type = typeof value;

    switch (type) {
      case "string":
        // @ts-ignore
        opcode[field] = value;
        break;
      default:
        // @ts-ignore
        opcode[field] = value;
    }
    forceUpdate();
  };

  const setValueOpcodepart = (field: string, value: any) => {
    const type = typeof value;

    switch (type) {
      case "string":
        // @ts-ignore
        opcodepart[field] = value;
        break;
      default:
        // @ts-ignore
        opcodepart[field] = value;
    }
    forceUpdate();
  };

  const onBack = () => {
    setBackModal(true);
  };

  const onSaveBack = () => {
    history.push(Util.PATH_NAMES.CDK_RO_ASSIST);
  };

  const onSaveOpcodes = () => {
    let opcodesArray = cdkRO?.opCodes;

    let newParts = partsToAdd;
    opcode.parts = newParts;
    if (isEdit) {
      opcodesArray.map((opObj: any) => {
        if (opObj?.id === opcode?.id) {
          return opcode;
        }
      });
    } else {
      opcodesArray.push(opcode);
    }

    setValue("opCodes", opcodesArray);
    setShowOpcodesModal(false);
    setPartsToAdd([]);
    setSelectedOpcodes([]);
  };

  const onDelete = () => {
    let opcodesArray = cdkRO?.opCodes;
    opcodesArray = opcodesArray?.filter(function (opcodesArrayObj) {
      return !selectedOpCodes.find(function (selectedOpcodesObj) {
        return opcodesArrayObj.id === selectedOpcodesObj.id;
      });
    });
    setValue("opCodes", opcodesArray);
    setShowDeleteROModal(false);
  };

  const onSaveOpcodepart = () => {
    partsToAdd.push(opcodepart);
    setShowOpcodePartModal(false);
  };

  const onSaveCloseRO = () => {
    cdkRO.status = "closed";
    const now = new Date();
    const formattedDateTime = formatDateTime(now);
    cdkRO.roClosed = formattedDateTime;
    onSave();
  };

  const onNewOpCodesModal = () => {
    setShowOpcodesModal(true);
    setOpcode({
      name: "",
      description: "",
      laborType: "",
      laborCost: "",
      parts: [],
      total: "",
    });
    setIsEdit(false);
    setPartsToAdd([]);
    setLoadParts(true);
  };

  const onEdit = () => {
    if (selectedOpCodes.length > 0) {
      setShowOpcodesModal(true);
      setOpcode(selectedOpCodes[0]);
      setIsEdit(true);
      setPartsToAdd(selectedOpCodes[0]?.parts);
      setLoadParts(true);
    }
  };

  const onNewOpCodePartModal = () => {
    setShowOpcodePartModal(true);
    setOpcodepart({
      name: "",
      quantity: 0,
      partPrice: "",
    });
  };

  const onCloseROModal = () => {
    setShowCloseROModal(true);
  };

  const onHideCloseROModal = () => {
    setShowCloseROModal(false);
  };

  const onHideOpCodesModal = () => {
    setShowOpcodesModal(false);
    setOpcode({
      name: "",
      description: "",
      laborType: "",
      laborCost: "",
      parts: [],
      total: "",
    });
    setPartsToAdd([]);
    setLoadParts(false);
  };

  const onHideOpCodePartModal = () => {
    setShowOpcodePartModal(false);
    setOpcodepart({
      name: "",
      quantity: 0,
      partPrice: "",
    });
  };

  const onSave = () => {
    if (mode === MODE.NEW) {
      const now = new Date();
      const formattedDateTime = formatDateTime(now);
      let createDto = {
        status: cdkRO.status,
        customerFirstName: cdkRO.customerFirstName,
        customerLastName: cdkRO.customerLastName,
        address: cdkRO.address,
        roNumber: cdkRO.roNumber,
        year: cdkRO.year,
        make: cdkRO.make,
        model: cdkRO.model,
        vin: cdkRO.vin,
        mileageIn: cdkRO.mileageIn,
        mileageOut: cdkRO.mileageOut,
        tag: cdkRO.tag,
        roOpened: formattedDateTime,
        roClosed: cdkRO.roClosed,
        line: cdkRO.line,
        opCodes: cdkRO.opCodes,
        hourSold: cdkRO.hourSold,
        laborSales: cdkRO.laborSales,
        sales: cdkRO.sales,
        techNumber: cdkRO.techNumber,
        laborType: cdkRO.laborType,
        paymentMethod: cdkRO.paymentMethod,
        shopSupply: cdkRO.shopSupply,
        taxes: cdkRO.taxes,
        total: cdkRO.total,
        serviceAdvisor: cdkRO.serviceAdvisor,
        serviceTechnician: cdkRO.serviceTechnician,
      };
      createCDKData(dealershipContext?.id, createDto)
        .then((response) => {
          if (response?.data) {
            Util.success("RO created successfully.");
            history.push(Util.PATH_NAMES.CDK_RO_ASSIST);
          }
        })
        .catch((error) => {
          Util.error(error.response.data.message);
        });
    } else {
      let updateDto = {
        status: cdkRO.status,
        customerFirstName: cdkRO.customerFirstName,
        customerLastName: cdkRO.customerLastName,
        address: cdkRO.address,
        roNumber: cdkRO.roNumber,
        year: cdkRO.year,
        make: cdkRO.make,
        model: cdkRO.model,
        vin: cdkRO.vin,
        mileageIn: cdkRO.mileageIn,
        mileageOut: cdkRO.mileageOut,
        tag: cdkRO.tag,
        roOpened: cdkRO.roOpened,
        roClosed: cdkRO.roClosed,
        line: cdkRO.line,
        opCodes: cdkRO.opCodes,
        hourSold: cdkRO.hourSold,
        laborSales: cdkRO.laborSales,
        sales: cdkRO.sales,
        techNumber: cdkRO.techNumber,
        laborType: cdkRO.laborType,
        paymentMethod: cdkRO.paymentMethod,
        shopSupply: cdkRO.shopSupply,
        taxes: cdkRO.taxes,
        total: cdkRO.total,
        serviceAdvisor: cdkRO.serviceAdvisor,
        serviceTechnician: cdkRO.serviceTechnician,
      };
      updateCDKData(cdkRO.id, updateDto)
        .then((response) => {
          if (response?.data) {
            Util.success("RO updated successfully.");
            history.push(Util.PATH_NAMES.CDK_RO_ASSIST);
          }
        })
        .catch((error) => {
          Util.error(error.response.data.message);
        });
    }
  };

  const promiseLaborType = (): any =>
    new Promise((resolve) => {
      getLaborTypes(dealershipContext?.id).then((response) => {
        resolve(
          response?.data?.codes?.split(",")?.map((item) => {
            return {
              label: item,
              value: item,
            };
          })
        );
      });
    });

  const promiseServiceAdvisors = (): any =>
    new Promise((resolve) => {
      getServiceAdvisors(dealershipContext?.id).then((response) => {
        resolve(
          response?.data?.advisors?.split(",")?.map((item) => {
            return {
              label: item,
              value: item,
            };
          })
        );
      });
    });

  const promiseServiceTechnician = (): any =>
    new Promise((resolve) => {
      getServiceTechnician(dealershipContext?.id).then((response) => {
        resolve(
          response?.data?.technicians?.split(",")?.map((item) => {
            return {
              label: item,
              value: item,
            };
          })
        );
      });
    });

  function validateVin(vin) {
    let re = new RegExp("^[A-HJ-NPR-Z\\d]{8}[\\dX][A-HJ-NPR-Z\\d]{2}\\d{6}$");
    return vin.match(re);
  }

  const validateVinNumber = (vin: string) =>
    new Promise((resolve) => {
      const isVinValid = validateVin(vin);
      if (isVinValid !== null) {
        setSpinnerActivated(true);
        getVinNumber(vin)
          .then((response) => {
            resolve(response?.data);
            if (response?.data) {
              setValue("make", response?.data?.make);
              setValue("model", response?.data?.model);
              setValue("year", response?.data?.year);
            }
          })
          .finally(() => setSpinnerActivated(false));
      } else {
        Util.warning("Please double check your VIN number!");
      }
    });

  const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  return (
    <div id={IDS.offersForm.wrapper}>
      <div className="card card-w-title datatable-demo">
        <Panel
          id={IDS.offersForm.panel.basic}
          style={{ width: "100%", marginBottom: 10 }}
          header={"RO Form"}
        >
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Customer First Name</label>
                <span className={"red"}>*</span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.customerFirstName}
                placeholder={"Customer First Name"}
                onChange={(e: any) =>
                  setValue("customerFirstName", e.target.value)
                }
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Customer Last Name</label>
                <span className={"red"}>*</span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.customerLastName}
                placeholder={"Customer Last Name"}
                onChange={(e: any) =>
                  setValue("customerLastName", e.target.value)
                }
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Address</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.address}
                placeholder={"Address"}
                onChange={(e: any) => setValue("address", e.target.value)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>VIN</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div
              className={"p-col-12 p-lg-9"}
              style={{ display: "inline-flex" }}
            >
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.vin}
                placeholder={"VIN"}
                onChange={(e: any) => {
                  if (e?.target?.value?.length > 16) {
                    if (e?.target?.value?.length == 17)
                      setValue("vin", e.target.value);
                    validateVinNumber(e.target.value);
                  } else {
                    setValue("vin", e.target.value);
                  }
                }}
              />
              {spinnerActivated && (
                <i
                  style={{ marginLeft: "-20px" }}
                  className="pi pi-spin pi-spinner"
                />
              )}
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Year</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.year == 0 ? "" : cdkRO.year}
                placeholder={"Year"}
                onChange={(e: any) => setValue("year", e.target.value)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Make</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.make}
                placeholder={"Make"}
                onChange={(e: any) => setValue("make", e.target.value)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Model</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.model}
                placeholder={"Model"}
                onChange={(e: any) => setValue("model", e.target.value)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Mileage In</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.mileageIn}
                placeholder={"Mileage In"}
                onChange={(e: any) => setValue("mileageIn", e.target.value)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Mileage Out</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.mileageOut}
                placeholder={"Mileage Out"}
                onChange={(e: any) => setValue("mileageOut", e.target.value)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Tag</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.tag}
                placeholder={"Tag"}
                onChange={(e: any) => setValue("tag", e.target.value)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Line</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.line}
                placeholder={"Line"}
                onChange={(e: any) => setValue("line", e.target.value)}
              />
            </div>
          </div>
          {/** opCodes */}
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Hour Sold</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.hourSold}
                placeholder={"Hour sold"}
                onChange={(e: any) => setValue("hourSold", e.target.value)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Labor Sales</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.laborSales}
                placeholder={"Labor Sales"}
                onChange={(e: any) => setValue("laborSales", e.target.value)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Sales</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.sales}
                placeholder={"Sales"}
                onChange={(e: any) => setValue("sales", e.target.value)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Tech Number</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.techNumber}
                placeholder={"Tech Number"}
                onChange={(e: any) => setValue("techNumber", e.target.value)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Status</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <Dropdown
                value={cdkRO.status}
                disabled={!isROAdmin && isROTech}
                placeholder="Status"
                options={[
                  { label: "Open", value: "open" },
                  { label: "In Progress", value: "inProgress" },
                  { label: "Waiting", value: "waiting" },
                ]}
                style={{ width: "100%", boxShadow: "none" }}
                onChange={(e: any) => setValue("status", e.target.value)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Labor Type</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <AsyncSelect
                cacheOptions
                isDisabled={!isROAdmin && isROTech}
                defaultOptions
                isClearable={true}
                loadOptions={promiseLaborType}
                menuPosition={"fixed"}
                onChange={(e: any) => setValue("laborType", e?.value)}
                placeholder={
                  !isEmpty(cdkRO.laborType)
                    ? cdkRO.laborType
                    : t("cdkRO.laborType")
                }
                value={cdkRO.laborType}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Service Advisor</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <AsyncSelect
                cacheOptions
                isDisabled={!isROAdmin && isROTech}
                defaultOptions
                isClearable={true}
                loadOptions={promiseServiceAdvisors}
                menuPosition={"fixed"}
                onChange={(e: any) => setValue("serviceAdvisor", e?.value)}
                placeholder={
                  !isEmpty(cdkRO.serviceAdvisor)
                    ? cdkRO.serviceAdvisor
                    : t("cdkRO.serviceAdvisor")
                }
                value={cdkRO.serviceAdvisor}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Service Technician</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <AsyncSelect
                cacheOptions
                isDisabled={!isROAdmin && isROTech}
                defaultOptions
                isClearable={true}
                loadOptions={promiseServiceTechnician}
                menuPosition={"fixed"}
                onChange={(e: any) => setValue("serviceTechnician", e?.value)}
                placeholder={
                  !isEmpty(cdkRO.serviceTechnician)
                    ? cdkRO.serviceTechnician
                    : t("cdkRO.serviceTechnician")
                }
                value={cdkRO.serviceTechnician}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Payment Method</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.paymentMethod}
                placeholder={"Payment Method"}
                onChange={(e: any) => setValue("paymentMethod", e.target.value)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Shop Supply</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.shopSupply}
                placeholder={"Shop Supply"}
                onChange={(e: any) => setValue("shopSupply", e.target.value)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Taxes</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                id={IDS.inputs.name}
                disabled={!isROAdmin && isROTech}
                style={{ width: "100%" }}
                value={cdkRO.taxes}
                placeholder={"Taxes"}
                onChange={(e: any) => setValue("taxes", e.target.value)}
              />
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>OP Codes</label>
                <span className={"red"}></span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <DataTable
                resizableColumns={true}
                scrollable
                style={{ height: "auto" }}
                autoLayout={true}
                columnResizeMode={"expand"}
                value={cdkRO?.opCodes}
                rowsPerPageOptions={Util.rowsPerPageOptions}
                // paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                // currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                // paginator={true}
                // rows={limit} //to be changed
                sortMode="multiple"
                totalRecords={cdkRO?.opCodes?.length}
                responsiveLayout="scroll"
                selection={selectedOpCodes}
                onSelectionChange={(e) => setSelectedOpcodes(e.value)}
                header={
                  <div
                    className={"p-grid-header-components"}
                    style={{ minHeight: "40px" }}
                  >
                    <Button
                      id={IDS.button.new}
                      style={{ position: "absolute", left: "10px" }}
                      icon={"pi pi-plus"}
                      label={t("new")}
                      onClick={onNewOpCodesModal}
                    />
                    <Button
                      id={IDS.button.edit}
                      disabled={selectedOpCodes.length !== 1}
                      label={"Edit"}
                      style={{ position: "absolute", left: "85px" }}
                      icon={"pi pi-pencil"}
                      onClick={onEdit}
                    />
                    <Button
                      disabled={selectedOpCodes.length === 0}
                      onClick={() => setShowDeleteROModal(true)}
                      style={{ position: "absolute", left: "160px" }}
                      icon="pi pi-trash"
                      className="p-button-danger"
                      label={"Delete"}
                    />
                  </div>
                }
              >
                <Column
                  selectionMode="multiple"
                  style={{ width: "3em", flex: "0.2" }}
                />
                <Column sortable={true} field={"name"} header={"Name"} />
                <Column
                  sortable={true}
                  field={"description"}
                  header={"Description"}
                />
                <Column
                  field={"parts"}
                  header={"Parts"}
                  body={(rowData: any) => {
                    return (
                      <div>
                        {" "}
                        {rowData?.parts?.map((part, index) => {
                          return (
                            <div
                              style={
                                index < rowData?.parts?.length - 1
                                  ? {
                                      display: "flex",
                                      flexDirection: "column",
                                      borderBottom: "1px solid gray",
                                    }
                                  : {
                                      display: "flex",
                                      flexDirection: "column",
                                    }
                              }
                              key={index}
                            >
                              <p>Part name: {part?.name}</p>
                              <p>Quantity: {part?.quantity}</p>
                              <p>Part price: {part?.partPrice} $</p>
                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                />
                <Column
                  sortable={true}
                  field={"laborType"}
                  header={"Labor Type"}
                />
                <Column
                  sortable={true}
                  field={"laborCost"}
                  header={"Labor $"}
                  body={(rowData: any) => {
                    const laborCost = parseFloat(
                      rowData?.laborCost.replace(",", ".")
                    );
                    return (
                      <p>{laborCost ? "$" + laborCost.toFixed(2) : "N/A"}</p>
                    );
                  }}
                />
                <Column
                  sortable={true}
                  field={"parts"}
                  header={"Parts Total $"}
                  body={(rowData: any) => {
                    const partsTotal = rowData?.parts
                      ?.map((part) => {
                        const partPrice = parseFloat(
                          part?.partPrice.replace(",", ".")
                        );
                        const quantity = parseInt(part?.quantity);
                        return partPrice * quantity;
                      })
                      ?.reduce((a, b) => a + b, 0.0);

                    return (
                      <p>{partsTotal ? "$" + partsTotal.toFixed(2) : "N/A"}</p>
                    );
                  }}
                />

                <Column
                  sortable={true}
                  field={"total"}
                  header={"Total $"}
                  body={(rowData: any) => {
                    if (rowData?.total) {
                      return <p>{rowData?.total}</p>;
                    }
                    const partsTotal = rowData?.parts
                      ?.map((part) => {
                        const partPrice = parseFloat(
                          part?.partPrice.replace(",", ".")
                        );
                        const quantity = parseInt(part?.quantity);
                        return partPrice * quantity;
                      })
                      ?.reduce((a, b) => a + b, 0.0);

                    const laborCost = parseFloat(
                      rowData?.laborCost?.replace(",", ".") || "0"
                    );
                    const totalCost = partsTotal + laborCost;

                    return (
                      <p>{totalCost ? "$" + totalCost.toFixed(2) : "N/A"}</p>
                    );
                  }}
                />
              </DataTable>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"printable-disclosure-div"}>
                <p>
                  <b>
                    PLEASE READ CAREFULLY, CHECK ONE OF THE STATEMENTS BELOW,
                    AND SIGN: I UNDERSTAND THAT, UNDER STATE LAW, I AM ENTITLED
                    TO A WRITTEN ESTIMATE IF MY FINAL BILL WILL EXCEED $100.
                    <br />
                    <br />
                    __ I REQUEST A WRITTEN ESTIMATE.
                    <br />
                    __ I DO NOT REQUEST A WRITTEN ESTIMATE AS LONG AS THE REPAIR
                    COSTS DO NOT EXCEED $ _________. THE SH MAY NOT EXCEED THIS
                    AMOUNT WITHOUT MY WRITTEN OR ORAL APPROVAL.
                    <br />
                    __ I DO NOT REQUEST A WRITTEN ESTIMATE.
                    <br />
                    <br />
                    <br />
                    SIGNED: ______________________________ DATE:
                    ______________________________
                  </b>
                </p>
                <hr />
                <p>
                  <b>PAYMENT TERMS:</b> I agree to pay for the inspection and
                  repairs I authorize, along with the necessary materials, in
                  Cash or approved credit card upon completion of the Repairs
                  unless the Dealership agrees to other payment arrangements in
                  advance. An express lien is hereby acknowledged to secure the
                  cost of labor, materials, and any other authorized charges.
                  <br />
                  <b>SHOP SUPPLY COSTS:</b> A charge equal to 20% of the total
                  cost of labor and parts, not to exceed $39,95, will be added
                  to the Repair Order. This charge represents costs and profits
                  to the motor vehicle repair facility for miscellaneous shop
                  supplies or waste disposal. The State of Florida requires a
                  $1.00 fee to be collected for each new tire sold in the state
                  [s.403.718], and a $1,50 fee to be collected for each new or
                  remanufactured lead-acid battery sold in the state
                  [s.403.7185].
                  <br />
                  <b>LABOR AND PARTS COSTS:</b> Labor charges are based on both
                  a flat rate and an hourly rate unless otherwise indicated. All
                  parts installed are new unless otherwise indicated. You are
                  entitled, upon request, to inspect all parts removed from the
                  vehicle or, provided that the Dealership does not have a
                  warranty arrangement or exchange parts program with the
                  manufacturer, supplier or distributor, to have then returned.
                  <br />
                  Discard Replaced Parts _______ (INITIAL) Save Replaced Parts
                  _________ (INITIAL)
                  <br />
                  <b>
                    <u>WARRANTY STATEMENT AND DISCLAIMER:</u> PLEASE SEE THE
                    DEALERSHIP'S LIMITED WARRANTY ON THE REVERSE SIDE OF THIS
                    REPAIR ORDER.{" "}
                  </b>
                  I understand that the Dealership is not responsible for any
                  delays caused by unavailability, of parts or shipping by the
                  supplier or transporter, nor damage to the vehicle or articles
                  left in the vehicle in case of fire, theft, hail, wind or any
                  other cause beyond its control.
                  <br />
                  I hereby grant the Dealership permission to operate the
                  vehicle on streets, highways of public roadways for the
                  purpose of testing and/or inspecting the vehicle. I authorize
                  the retrieval on-board data as needed to facilitate vehicle
                  repair as well as sharing that data with the venicle
                  manufacturer for diagnostic and research purposes.
                  <br />
                  <br />
                  <br />
                  <b>
                    Customer
                    Signature:______________________________________________
                    Date:_________________________________
                  </b>
                </p>
              </div>
            </div>
          </div>
        </Panel>
        <Dialog
          style={{ width: 700 }}
          modal={true}
          visible={showOpCodesModal}
          onHide={onHideOpCodesModal}
        >
          <div className="card card-w-title datatable-demo">
            <Panel
              id={IDS.offersForm.panel.basic}
              style={{ width: "100%", marginBottom: 10 }}
              header={"OP Code Form"}
            >
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Name</label>
                    <span className={"red"}></span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    id={IDS.inputs.name}
                    style={{ width: "100%" }}
                    value={opcode.name}
                    placeholder={"Name"}
                    onChange={(e: any) =>
                      setValueOpcode("name", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Description</label>
                    <span className={"red"}></span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    id={IDS.inputs.name}
                    style={{ width: "100%" }}
                    value={opcode.description}
                    placeholder={"Description"}
                    onChange={(e: any) =>
                      setValueOpcode("description", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Labor Type</label>
                    <span className={"red"}></span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    isClearable={true}
                    loadOptions={promiseLaborType}
                    menuPosition={"fixed"}
                    onChange={(e: any) => setValueOpcode("laborType", e?.value)}
                    placeholder={
                      !isEmpty(opcode.laborType)
                        ? opcode.laborType
                        : t("cdkRO.laborType")
                    }
                    value={opcode.laborType}
                  />
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Labor Cost</label>
                    <span className={"red"}></span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    id={IDS.inputs.name}
                    style={{ width: "100%" }}
                    value={opcode.laborCost}
                    placeholder={"Labor Cost"}
                    onChange={(e: any) => {
                      setValueOpcode("laborCost", e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Parts</label>
                    <span className={"red"}></span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <DataTable
                    resizableColumns={true}
                    columnResizeMode={"expand"}
                    value={parts}
                    rowsPerPageOptions={Util.rowsPerPageOptions}
                    // paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    // currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                    // paginator={true}
                    // rows={limit} //to be changed
                    totalRecords={cdkRO?.opCodes?.length}
                    sortMode="multiple"
                    header={
                      <div
                        className={"p-grid-header-components"}
                        style={{ minHeight: "40px" }}
                      >
                        <Button
                          id={IDS.button.new}
                          style={{ position: "absolute", left: "10px" }}
                          icon={"pi pi-plus"}
                          label={t("cdkRO.addPart")}
                          onClick={onNewOpCodePartModal}
                          disabled={opcode.name != "" ? false : true}
                        />
                      </div>
                    }
                  >
                    <Column sortable={true} field={"name"} header={"Name"} />
                    <Column
                      sortable={true}
                      field={"quantity"}
                      header={"Quantity"}
                    />
                    <Column
                      sortable={true}
                      field={"partPrice"}
                      header={"Part Price"}
                    />
                  </DataTable>
                </div>
              </div>
            </Panel>

            <div>
              <Button
                id={IDS.button.save}
                style={{ marginRight: 5 }}
                label={"Save"}
                icon={"pi pi-save"}
                onClick={() => onSaveOpcodes()}
              />
              <Button
                id={IDS.button.back}
                onClick={() => {
                  setShowOpcodesModal(false);
                  setSelectedOpcodes([]);
                }}
                className={"p-button-warning"}
                icon={"pi pi-arrow-left"}
                label={"Back"}
              />
            </div>
          </div>
        </Dialog>
        <Dialog
          style={{ width: 700 }}
          modal={true}
          visible={showOpCodePartModal}
          onHide={onHideOpCodePartModal}
        >
          <div className="card card-w-title datatable-demo">
            <Panel
              id={IDS.offersForm.panel.basic}
              style={{ width: "100%", marginBottom: 10 }}
              header={"Add Parts"}
            >
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Name</label>
                    <span className={"red"}></span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    id={IDS.inputs.name}
                    style={{ width: "100%" }}
                    value={opcodepart.name}
                    placeholder={"Name"}
                    onChange={(e: any) =>
                      setValueOpcodepart("name", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Quantity</label>
                    <span className={"red"}></span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    id={IDS.inputs.name}
                    style={{ width: "100%" }}
                    value={opcodepart.quantity}
                    placeholder={"Quantity"}
                    onChange={(e: any) =>
                      setValueOpcodepart("quantity", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Part Price</label>
                    <span className={"red"}></span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    id={IDS.inputs.name}
                    style={{ width: "100%" }}
                    value={opcodepart.partPrice}
                    placeholder={"Part Price"}
                    onChange={(e: any) =>
                      setValueOpcodepart("partPrice", e.target.value)
                    }
                  />
                </div>
              </div>
            </Panel>

            <div>
              <Button
                id={IDS.button.save}
                style={{ marginRight: 5 }}
                label={"Save"}
                icon={"pi pi-save"}
                onClick={() => onSaveOpcodepart()}
              />
              <Button
                id={IDS.button.back}
                onClick={() => setShowOpcodePartModal(false)}
                className={"p-button-warning"}
                icon={"pi pi-arrow-left"}
                label={"Back"}
              />
            </div>
          </div>
        </Dialog>
        <Dialog
          style={{ width: 700 }}
          modal={true}
          visible={showCloseROModal}
          onHide={onHideCloseROModal}
        >
          <div className="card card-w-title datatable-demo">
            <Panel
              id={IDS.offersForm.panel.basic}
              style={{ width: "100%", marginBottom: 10 }}
              header={"Are you sure you want to close this RO?"}
            >
              <h3>You will not be able to edit it, once closed.</h3>
            </Panel>

            <div>
              <Button
                id={IDS.button.save}
                style={{ marginRight: 5 }}
                label={"Yes"}
                icon={"pi pi-save"}
                onClick={() => onSaveCloseRO()}
              />
              <Button
                id={IDS.button.back}
                onClick={() => setShowCloseROModal(false)}
                className={"p-button-warning"}
                icon={"pi pi-arrow-left"}
                label={"No"}
              />
            </div>
          </div>
        </Dialog>
        <Dialog
          style={{ width: 700 }}
          modal={true}
          visible={showDeleteROModal}
          onHide={() => setShowDeleteROModal(false)}
        >
          <div className="card card-w-title datatable-demo">
            <Panel
              id={IDS.offersForm.panel.basic}
              style={{ width: "100%", marginBottom: 10 }}
              header={"Are you sure you want to delete this/ these OP Codes?"}
            >
              <h3>You will not be able to revert it.</h3>
            </Panel>

            <div>
              <Button
                onClick={onDelete}
                style={{ marginRight: 5 }}
                icon="pi pi-trash"
                className="p-button-danger"
                label={"Delete"}
              />
              <Button
                id={IDS.button.back}
                onClick={() => setShowDeleteROModal(false)}
                className={"p-button-warning"}
                icon={"pi pi-arrow-left"}
                label={"No"}
              />
            </div>
          </div>
        </Dialog>
        <Dialog
          style={{ width: 700 }}
          modal={true}
          visible={backModal}
          onHide={() => setBackModal(false)}
        >
          <div className="card card-w-title datatable-demo">
            <Panel
              id={IDS.offersForm.panel.basic}
              style={{ width: "100%", marginBottom: 10 }}
              header={"Are you sure you want to go back?"}
            >
              <h3>Make sure to have saved any updates.</h3>
            </Panel>

            <div>
              <Button
                onClick={onSaveBack}
                id={IDS.button.save}
                style={{ marginRight: 5 }}
                label={"Yes"}
                icon={"pi pi-save"}
              />
              <Button
                id={IDS.button.back}
                onClick={() => setBackModal(false)}
                className={"p-button-warning"}
                icon={"pi pi-arrow-left"}
                label={"No"}
              />
            </div>
          </div>
        </Dialog>

        <div className="main-buttons">
          <Button
            id={IDS.button.save}
            style={{ marginRight: 5 }}
            label={"Save"}
            icon={"pi pi-save"}
            onClick={() => onSave()}
          />
          <Button
            id={IDS.button.back}
            onClick={onBack}
            className={"p-button-warning"}
            icon={"pi pi-arrow-left"}
            label={"Back"}
          />
          <Button
            label={"Export as PDF"}
            icon={"pi pi-print"}
            onClick={() => {
              window.print();
            }}
          />
          {mode !== MODE.NEW && (
            <Button
              id={IDS.button.closeRO}
              icon={"pi pi-times"}
              label={t("Close RO")}
              onClick={onCloseROModal}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CdkROAssistForm;
