import React, { useEffect, useState } from "react";
import Util from "../../../util/Util";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getVisitorConversionHistory } from "../../../actions/myTrafficActions";
import moment from "moment-timezone";
import { ENGAGEMENT_INTERACTIONS } from "../../../util/Enums";
import { VisitorSmartPixlDataDto } from "../../../../index.dts";

interface IProps {
  visitorId: string;
  dealershipId: number;
}

interface IConversionHistory {
  metadata: any;
  city: string;
  zip: string;
  device: string;
  events: { created: string; event: string }[];
  visitorSmartPixlDataDto: VisitorSmartPixlDataDto | null;
}

export function ConversionHistory({ visitorId, dealershipId }: IProps) {
  const [loading, setLoading] = useState(true);
  const [conversionHistory, setConversionHistory] =
    useState<IConversionHistory>({
      city: "",
      device: "",
      events: [],
      metadata: null,
      zip: "",
      visitorSmartPixlDataDto: null,
    });

  useEffect(() => {
    if (Util.isEmpty(visitorId)) {
      return;
    }

    getData(dealershipId, visitorId);
  }, [visitorId, dealershipId]);

  const getData = (dealershipId: number, visitorRefId: string) => {
    setLoading(true);
    getVisitorConversionHistory(dealershipId, visitorRefId, 1000)
      .then((response) => {
        const events: any[] = Util._.get(response, "data.events", []);

        let lastPopUpIgnored: any = null;
        response.data.events = events.filter((item) => {
          if (item.event === ENGAGEMENT_INTERACTIONS.POPUP_IGNORED) {
            if (lastPopUpIgnored === null) {
              lastPopUpIgnored = item.created;
              return true;
            }
            return false;
          }
          return true;
        });

        setConversionHistory(response.data);
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => setLoading(false));
  };

  const createdTemplate = (data: any) => {
    return (
      <div>
        {moment(Util.utcToLocal(data.created)).format(Util.usDateTimeFormat)}
      </div>
    );
  };

  return (
    <div>
      <div>
        <div className={"conv-history-item"}>
          <label>Name: </label>
          <span className={"conv-history-label"}>
            {Util._.get(conversionHistory, "metadata.first_name", "")
              ? Util._.get(conversionHistory, "metadata.first_name", "")
              : Util._.get(
                  conversionHistory.visitorSmartPixlDataDto,
                  "firstName",
                  ""
                )}{" "}
            {Util._.get(conversionHistory, "metadata.last_name", "")
              ? Util._.get(conversionHistory, "metadata.last_name", "")
              : Util._.get(
                  conversionHistory.visitorSmartPixlDataDto,
                  "lastName",
                  ""
                )}
          </span>
        </div>
        <div className={"conv-history-item"}>
          <label>Email: </label>
          <span className={"conv-history-label"}>
            {Util._.get(conversionHistory, "metadata.email", "")
              ? Util._.get(conversionHistory, "metadata.email", "")
              : Util._.get(
                  conversionHistory.visitorSmartPixlDataDto,
                  "email",
                  ""
                )}
          </span>
        </div>
        <div className={"conv-history-item"}>
          <label>Phone: </label>
          <span className={"conv-history-label"}>
            {Util._.get(conversionHistory, "metadata.phone", "")}
          </span>
        </div>
        <div className={"conv-history-item"}>
          <label>Device: </label>
          <span className={"conv-history-label"}>
            {Util._.get(conversionHistory, "device", "")}
          </span>
        </div>
        <div className={"conv-history-item"}>
          <label>Zip: </label>
          <span className={"conv-history-label"}>
            {Util._.get(conversionHistory, "zip", "")
              ? Util._.get(conversionHistory, "zip", "")
              : Util._.get(
                  conversionHistory.visitorSmartPixlDataDto,
                  "zipCode",
                  ""
                )}
          </span>
        </div>
        <div className={"conv-history-item"}>
          <label>City: </label>
          <span className={"conv-history-label"}>
            {Util._.get(conversionHistory, "city", "")
              ? Util._.get(conversionHistory, "city", "")
              : Util._.get(
                  conversionHistory.visitorSmartPixlDataDto,
                  "city",
                  ""
                )}
          </span>
        </div>

        {conversionHistory.visitorSmartPixlDataDto?.address && (
          <div className={"conv-history-item"}>
            <label>Address: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "address",
                ""
              )}
            </span>
          </div>
        )}
        {conversionHistory.visitorSmartPixlDataDto?.state && (
          <div className={"conv-history-item"}>
            <label>State: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "state",
                ""
              )}
            </span>
          </div>
        )}
        {conversionHistory.visitorSmartPixlDataDto !== null &&
        conversionHistory.visitorSmartPixlDataDto?.married !== null ? (
          <div className={"conv-history-item"}>
            <label>Marital Status: </label>
            <span className={"conv-history-label"}>
              {conversionHistory.visitorSmartPixlDataDto?.married === true
                ? "Married"
                : conversionHistory.visitorSmartPixlDataDto?.married === false
                ? "Single"
                : ""}
            </span>
          </div>
        ) : (
          ""
        )}
        {conversionHistory.visitorSmartPixlDataDto?.gender && (
          <div className={"conv-history-item"}>
            <label>Gender: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "gender",
                ""
              )}
            </span>
          </div>
        )}

        {conversionHistory.visitorSmartPixlDataDto?.language && (
          <div className={"conv-history-item"}>
            <label>Language: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "language",
                ""
              )}
            </span>
          </div>
        )}
        {conversionHistory.visitorSmartPixlDataDto !== null &&
        conversionHistory.visitorSmartPixlDataDto?.hasChildren !== null ? (
          <div className={"conv-history-item"}>
            <label>Children: </label>
            <span className={"conv-history-label"}>
              {conversionHistory.visitorSmartPixlDataDto?.hasChildren === true
                ? "Yes"
                : conversionHistory.visitorSmartPixlDataDto?.hasChildren ===
                  false
                ? "No"
                : ""}
            </span>
          </div>
        ) : (
          ""
        )}
        {conversionHistory.visitorSmartPixlDataDto?.numberOfChildren && (
          <div className={"conv-history-item"}>
            <label>Number of Children: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "numberOfChildren",
                ""
              )}
            </span>
          </div>
        )}
        {conversionHistory.visitorSmartPixlDataDto !== null &&
        conversionHistory.visitorSmartPixlDataDto?.homeOwner !== null ? (
          <div className={"conv-history-item"}>
            <label>Home / Rent: </label>
            <span className={"conv-history-label"}>
              {conversionHistory.visitorSmartPixlDataDto?.homeOwner === true
                ? "Home Owner"
                : conversionHistory.visitorSmartPixlDataDto?.homeOwner === false
                ? "Renter"
                : ""}
            </span>
          </div>
        ) : (
          ""
        )}
        {conversionHistory.visitorSmartPixlDataDto?.yearsInResidence && (
          <div className={"conv-history-item"}>
            <label>Years in Residence: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "yearsInResidence",
                ""
              )}
            </span>
          </div>
        )}
        {conversionHistory.visitorSmartPixlDataDto?.autoVin && (
          <div className={"conv-history-item"}>
            <label>Auto VIN: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "autoVin",
                ""
              )}
            </span>
          </div>
        )}
        {conversionHistory.visitorSmartPixlDataDto?.year && (
          <div className={"conv-history-item"}>
            <label>Year: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "year",
                ""
              )}
            </span>
          </div>
        )}
        {conversionHistory.visitorSmartPixlDataDto?.make && (
          <div className={"conv-history-item"}>
            <label>Make: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "make",
                ""
              )}
            </span>
          </div>
        )}
        {conversionHistory.visitorSmartPixlDataDto?.model && (
          <div className={"conv-history-item"}>
            <label>Model: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "model",
                ""
              )}
            </span>
          </div>
        )}
        {conversionHistory.visitorSmartPixlDataDto?.firstSeen && (
          <div className={"conv-history-item"}>
            <label>First Seen: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "firstSeen",
                ""
              )}
            </span>
          </div>
        )}
        {conversionHistory.visitorSmartPixlDataDto?.lastSeen && (
          <div className={"conv-history-item"}>
            <label>Last Seen: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "lastSeen",
                ""
              )}
            </span>
          </div>
        )}
        {conversionHistory.visitorSmartPixlDataDto?.age && (
          <div className={"conv-history-item"}>
            <label>Age: </label>
            <span className={"conv-history-label"}>
              {Util._.get(conversionHistory.visitorSmartPixlDataDto, "age", "")}
            </span>
          </div>
        )}
        {conversionHistory.visitorSmartPixlDataDto?.income && (
          <div className={"conv-history-item"}>
            <label>Income: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "income",
                ""
              )}
            </span>
          </div>
        )}
        {conversionHistory.visitorSmartPixlDataDto?.education && (
          <div className={"conv-history-item"}>
            <label>Education: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "education",
                ""
              )}
            </span>
          </div>
        )}
        {conversionHistory.visitorSmartPixlDataDto?.occupation && (
          <div className={"conv-history-item"}>
            <label>Occupation: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "occupation",
                ""
              )}
            </span>
          </div>
        )}
        {conversionHistory.visitorSmartPixlDataDto?.homeValueFrom && (
          <div className={"conv-history-item"}>
            <label>Home value from: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "homeValueFrom",
                ""
              )}
            </span>
          </div>
        )}

        {conversionHistory.visitorSmartPixlDataDto?.homeValueTo && (
          <div className={"conv-history-item"}>
            <label>Home value to: </label>
            <span className={"conv-history-label"}>
              {Util._.get(
                conversionHistory.visitorSmartPixlDataDto,
                "homeValueTo",
                ""
              )}
            </span>
          </div>
        )}
        {conversionHistory.visitorSmartPixlDataDto !== null &&
        conversionHistory.visitorSmartPixlDataDto?.businessOwner !== null ? (
          <div style={{ paddingTop: 2, paddingBottom: 2 }}>
            <label>Business Owner: </label>
            <span className={"conv-history-label"}>
              {conversionHistory.visitorSmartPixlDataDto?.businessOwner === true
                ? "Yes"
                : conversionHistory.visitorSmartPixlDataDto?.businessOwner ===
                  false
                ? "No"
                : ""}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
      <DataTable
        loading={loading}
        filterDisplay="row"
        value={conversionHistory.events}
        //responsive={true}
        resizableColumns={true}
        columnResizeMode={"expand"}
        scrollable={true}
        scrollHeight={"300px"}
      >
        <Column
          field="created"
          header="Date Time"
          filter={true}
          filterMatchMode={"contains"}
          sortable={true}
          body={createdTemplate}
        />
        <Column
          field="event"
          header="Event"
          filter={true}
          filterMatchMode={"contains"}
          sortable={true}
        />
      </DataTable>
    </div>
  );
}
