import { RadioFilter } from "../radio_filter/RadioFilter";
import React from "react";
import { useTranslation } from "react-i18next";
import PageTypes from "../../util/enum/page_types";

interface IProps {
  onChange: (update: number) => void;
  type?: PageTypes;
}

export function PageTypeRadio(props: IProps) {
  const { t } = useTranslation();

  function onChange(value: number): void {
    props.onChange(value);
  }

  return (
    <RadioFilter
      name={"product-type-radio"}
      onChange={onChange}
      options={[
        {
          label: t("all"),
          value: PageTypes.all,
        },
        {
          label: t("srp"),
          value: PageTypes.srp,
        },
        {
          label: t("vdp"),
          value: PageTypes.vdp,
        },
      ]}
      selected={props.type}
    />
  );
}