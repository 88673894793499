import React, {useEffect, useRef, useState} from "react";
import {Panel} from "primereact/panel";
import moment from "moment";
import Util from "../../util/Util";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {getPersistentChatReport} from "../../actions/persistentChatActions";
import {IPersistentChatStats} from "./index";
import DateRangePickerModal from "../data_range_picker/DateRangePickerModal";
import Spinner from "../spinner/Spinner";
import RefreshButton from "../refresh_button/RefreshButton";
import "./persistent-chat-stats.css";
import PersistentChatUserStatistics from "./PersistentChatUserStatistics";

interface IPops {
  readonly dealershipId: number;
  readonly dealershipName: string;
  readonly persistentChatEnabled: boolean;
}

const LEAD_STYLE = buildStyles({
  pathColor: `#0062ff`,
  textColor: 'black',
  textSize: "25px"
})

const STYLE = buildStyles({
  pathColor: `#3dd598`,
  textColor: 'black',
  textSize: "25px"
});


const PersistentChatStatistics = (props: IPops) => {
  const persistentChatUserStatsRef = useRef<any>();
  const [pending, setPending] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DD'));

  const [stats, setStats] = useState<IPersistentChatStats>({
    totalAccepted: 0,
    totalLeads: 0,
    totalAcceptedByChatbot: 0,
    totalAcceptedByUsers: 0,
    totalChatbotLeads: 0,
    totalShown: 0,
    totalUsed: 0,
    totalUserLeads: 0
  });

  useEffect(() => {
    if (props.dealershipId) {
      getData(props.dealershipId, startDate, endDate);
    }
    // eslint-disable-next-line
  }, [props.dealershipId]);

  const getData = (dealershipId: number, startDate?: string, endDate?: string) => {
    if (!props.persistentChatEnabled) {
      return;
    }

    setPending(true);
    getPersistentChatReport({
      dealershipId: dealershipId,
      startDate: startDate,
      endDate: endDate
    }).then(response => {
      setStats(response.data);
    }).catch(error => {
      Util.showError(error)
    }).finally(() => {
      setPending(false);
    })
  }

  const onRangeChange = (startDate: string, endDate: string): void => {
    getData(props.dealershipId, startDate, endDate);

    setStartDate(startDate);
    setEndDate(endDate);
  }

  const onRefresh = () => {
    getData(props.dealershipId, startDate, endDate);
  }

  return (
    <Panel id={"persistent_chat_stats"} header={(
      <div style={{display: "flex"}}>
        <label style={{whiteSpace: "nowrap"}}>{props.dealershipName}</label>
        <div style={{textAlign: "center", position: "relative", width: "100%"}}>
          <Spinner
            visible={pending}
            width={20}
            height={20}
            style={{position: "absolute", width: 40, height: 40, top: "unset"}}/>
        </div>
        <div style={{float: "right", display: "flex"}}>
          <DateRangePickerModal
            start={startDate}
            end={endDate}
            onChange={onRangeChange}
          />
          <RefreshButton style={{height: 38, width: 38, marginLeft: 2}} onClick={onRefresh}/>
        </div>
      </div>
    )}>
      <div style={{textAlign: "center"}} id={"period"} className={"p-col-12 p-lg-12"}>
        <div style={{margin: "auto"}} className={"p-grid"}>
          <div className={"p-col-12 p-lg-3 p-md-4 p-sm-4"}>
            <div className={"chart-container"}>
              <CircularProgressbar
                value={stats.totalShown}
                maxValue={stats.totalShown}
                text={stats.totalShown.toString()}
                styles={STYLE}
                strokeWidth={4}
              />
            </div>
            < label className={"text"}>Total shown</label>
          </div>

          <div className={"p-col-12 p-lg-3 p-md-4 p-sm-4"}>
            <div className={"chart-container"}>
              <CircularProgressbar
                value={stats.totalUsed}
                maxValue={stats.totalUsed}
                text={stats.totalUsed.toString()}
                styles={STYLE}
                strokeWidth={4}
              />
            </div>
            <label className={"text"}>Total active</label>
          </div>

          <div className={"p-col-12 p-lg-3 p-md-4 p-sm-4"}>
            <div onClick={() => {
              persistentChatUserStatsRef.current.show(props.dealershipId, startDate, endDate)
            }} className={"chart-container cursor-pointer"}>
              <CircularProgressbar
                value={stats.totalAcceptedByUsers}
                maxValue={stats.totalAcceptedByUsers}
                text={stats.totalAcceptedByUsers.toString()}
                styles={STYLE}
                strokeWidth={4}
              />
            </div>
            <label className={"text"}>Total accepted by user(s)</label>
          </div>


          <div className={"p-col-12 p-lg-3 p-md-4 p-sm-4"}>
            <div className={"chart-container"}>
              <CircularProgressbar
                value={stats.totalAcceptedByChatbot}
                maxValue={stats.totalAcceptedByChatbot}
                text={stats.totalAcceptedByChatbot.toString()}
                styles={STYLE}
                strokeWidth={4}
              />
            </div>
            <label className={"text"}>Total accepted by AI Chatbot</label>
          </div>

        </div>

        <div style={{margin: "auto"}} className={"p-grid"}>
          <div className={"p-col-12 p-lg-3 p-md-4 p-sm-4"}>
            <div className={"chart-container"}>
              <CircularProgressbar
                value={stats.totalLeads}
                maxValue={stats.totalLeads}
                text={stats.totalLeads.toString()}
                styles={LEAD_STYLE}
                strokeWidth={4}
              />
            </div>
            <label className={"text"}>Total Leads</label>
          </div>

          <div className={"p-col-12 p-lg-3 p-md-4 p-sm-4"}>
            <div onClick={() => {
              persistentChatUserStatsRef.current.show(props.dealershipId, startDate, endDate)
            }} className={"chart-container cursor-pointer"}>
              <CircularProgressbar
                value={stats.totalUserLeads}
                maxValue={stats.totalLeads}
                text={stats.totalUserLeads.toString()}
                styles={LEAD_STYLE}
                strokeWidth={4}
              />
            </div>
            <label className={"text"}>Total leads by user(s)</label>
          </div>

          <div className={"p-col-12 p-lg-3 p-md-4 p-sm-4"}>
            <div className={"chart-container"}>
              <CircularProgressbar
                value={stats.totalChatbotLeads}
                maxValue={stats.totalLeads}
                text={stats.totalChatbotLeads.toString()}
                styles={LEAD_STYLE}
                strokeWidth={4}
              />
            </div>
            <label className={"text"}>Total leads by AI ChatBot</label>
          </div>

        </div>
      </div>
      <PersistentChatUserStatistics ref={persistentChatUserStatsRef}/>
    </Panel>
  )
}

export default PersistentChatStatistics;