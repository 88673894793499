import { IPageable, SemVendorsSearchRequestDto } from "../../index.dts";

import Axios from "../config/Axios";

export const createSemVendor = (dto: { name: string, segment: string, dealershipId: number }) => Axios.instance.post("admin/semvendors", dto);
export const updateSemVendor = (id: number, dto: { name: string, segment: string, dealershipId: number }) => Axios.instance.put(`admin/semvendors/${id}`, dto);
export const deleteSemVendors = (ids: number[]) => Axios.all(ids.map(id => Axios.instance.delete(`admin/semvendors/${id}`)));
export const getSemVendorList = (dto: SemVendorsSearchRequestDto): Promise<IPageable> => Axios.instance.post("admin/semvendors/list", dto);

export function listSEMVendors(args: { dealershipId: any }): Promise<any[]> {
  return new Promise((resolve, reject) => {
    getSemVendorList({
      sorting: [],
      pageLimit: 100000000,
      pageNumber: 0,
      dealershipIds: [args.dealershipId]
    })
      .then(response => resolve(response.data.content))
      .catch(error => reject(error));
  });
}