import { Column } from "primereact/column";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import {
  ICampaignInfo,
  IChatGreeting,
  IMyTrafficNotificationDto,
  IMyTrafficNotificationFull,
  IOffer,
  IStore,
} from "../../../../index.dts";
import { CAMPAIGN_EDIT_TYPES, MODE, OFFER_TYPES } from "../../../util/Enums";
import Util from "../../../util/Util";
import { Button } from "primereact/button";
import { Alert } from "@mui/material";
import { get, isEqual } from "lodash";
import { updateMyCampaign } from "../../../actions/campaignsActions";
import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

type Props = {
  campaignInfo: ICampaignInfo;
  setStep: React.Dispatch<
    React.SetStateAction<{
      step: number | string;
      payload?: any;
    }>
  >;
  mode: MODE.EDIT | MODE.NEW | MODE.DUPLICATE;
  initialData: ICampaignInfo;
  setInitialData: React.Dispatch<React.SetStateAction<ICampaignInfo>>;
};

const CampaignActionsEdit = (props: Props) => {
  const { campaignInfo, setStep, mode, initialData, setInitialData } = props;
  const history = useHistory();
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  const dateBody = (data: any, field: string, formatter: string) => {
    const date = moment(data[field]);
    if (date.isValid()) {
      return date.format(formatter);
    }
    return null;
  };

  const dateBodyCreated = (data: any, field: string, formatter: string) => {
    const date = moment(Util.utcToLocal(data[field]));
    if (date.isValid()) {
      return date.format(formatter);
    }
    return null;
  };

  const transformNotificationsToDto = (
    notifications: IMyTrafficNotificationFull[]
  ): IMyTrafficNotificationDto[] => {
    const formattedDtos: IMyTrafficNotificationDto[] = notifications.map(
      (notification) => {
        return {
          ...((mode === MODE.EDIT || mode === MODE.DUPLICATE) && {
            id: notification?.id || null,
          }),
          name: notification?.name,
          dealershipId: dealershipContext.id,
          segment: notification?.segment,
          message: notification?.message,
          engagementScore: notification?.engagementScore,
          delaySend: notification?.delaySend,
          limitByZipCodes: notification?.limitByZipCodes,
          enabled: notification?.enabled,
          zipCodes: notification?.zipCodes,
          useChatBot: notification?.useChatBot,
          useSharkTank: notification?.useSharkTank,
          chatbotId: notification?.chatbotId,
          chatGreetingId: notification?.chatGreetingId,
          users: notification?.users?.map((user, index) => {
            return {
              userId: user?.userId,
              order: notification?.users?.length - index,
            };
          }),
          chatBotWorkflows: notification?.chatBotWorkflows?.map((item) => {
            return {
              chatBotId: item.chatBot.id,
              chatGreetingId: item.chatGreeting.id,
              daysOfWeek: item.daysOfWeek,
              endTime: item.endTime,
              startTime: item.startTime,
            };
          }),
        };
      }
    );
    return formattedDtos;
  };

  const offerTypeBody = (data: IOffer) => {
    return <div>{Util.getEnumKeyByValue(OFFER_TYPES, data.offerType)}</div>;
  };

  const previewBody = (props: IChatGreeting): React.ReactNode => {
    return (
      <div className={"rts-template-item-campaigns"}>
        <div
          id={"rts-template-chat-permission-container"}
          style={{ backgroundColor: `#${props.backgroundColor}` }}
        >
          <img
            id={"rts-template-chat-permission-close-svg"}
            src={"assets/icons/close.svg"}
            alt={""}
          />
          <div id={"rts-template-chat-permission-header"}>{props.name}</div>
          <div id={"rts-template-chat-permission-question-container"}>
            <div id={"rts-template-chat-permission-question-header"}>
              {props.message}
            </div>
            <div
              id={"rts-template-chat-permission-question-button"}
              style={{ backgroundColor: `#${props.buttonBackgroundColor}` }}
            >
              <div id={"rts-template-chat-permission-question-yes"}>
                <span>
                  {props.buttonLabel}{" "}
                  <img
                    id={"rts-template-chat-permission-question-arrow"}
                    src={"assets/icons/arrow.svg"}
                    alt={""}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Panel style={{ width: "100%", marginBottom: 10 }} header={"Edit Actions"}>
      <Alert sx={{ margin: "5px 0 15px 0" }} severity="warning">
        {mode === MODE.NEW
          ? "Edits made here will not impact the original Offers, Greetings or Notifications"
          : "Edits made here will immediately take effect on the Offer, Greeting or Notifications"}
      </Alert>
      <div>
        {campaignInfo?.offer && (
          <div className={"p-grid"} style={{ marginBottom: "20px" }}>
            <div className={"p-col-12 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Offer</label>
              </div>
            </div>
            <div className={"p-col-12 p-label-col"}>
              <div
                className={
                  "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"
                }
              >
                <DataTable
                  tableStyle={{ overflow: "visible" }}
                  resizableColumns={true}
                  columnResizeMode={"expand"}
                  value={[campaignInfo?.offer]}
                >
                  <Column field="name" header="Name" />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field="template.dealershipName"
                    header="Dealerships"
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field="template.name"
                    header="Template"
                  />
                  <Column
                    field="offerType"
                    header="Offer Type"
                    style={{
                      overflow: "visible",
                      background: "white",
                      cursor: "default",
                    }}
                    body={offerTypeBody}
                  />
                  <Column
                    field="startDate"
                    header="Start Date"
                    body={(data: any) =>
                      dateBody(data, "startDate", Util.usDateFormat)
                    }
                    style={{
                      overflow: "visible",
                      background: "white",
                      cursor: "default",
                    }}
                  />
                  <Column
                    field="endDate"
                    header="End Date"
                    body={(data: any) =>
                      dateBody(data, "endDate", Util.usDateFormat)
                    }
                    style={{
                      overflow: "visible",
                      background: "white",
                      cursor: "default",
                    }}
                  />
                  <Column
                    field="created"
                    header="Created"
                    body={(data: any) =>
                      dateBodyCreated(data, "created", Util.usDateTimeFormat)
                    }
                    style={{
                      overflow: "visible",
                      background: "white",
                      cursor: "default",
                    }}
                  />
                  <Column
                    field="edit"
                    header=""
                    style={{ background: "white", cursor: "default" }}
                    body={(rowData: any) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <EditIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setStep({
                              step: CAMPAIGN_EDIT_TYPES.OFFER_EDIT,
                              payload: "actionsEditPage",
                            });
                          }}
                        />
                      </div>
                    )}
                  />
                </DataTable>
              </div>
            </div>
          </div>
        )}
        {campaignInfo?.greeting && (
          <div className={"p-grid"} style={{ marginBottom: "20px" }}>
            <div className={"p-col-12 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Greeting</label>
              </div>
            </div>
            <div className={"p-col-12 p-label-col"} style={{ display: "flex" }}>
              {previewBody(campaignInfo?.greeting)}
              <div>
                <EditIcon
                  style={{ marginLeft: "15px", cursor: "pointer" }}
                  onClick={() => {
                    setStep({
                      step: CAMPAIGN_EDIT_TYPES.GREETING_EDIT,
                      payload: "actionsEditPage",
                    });
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {campaignInfo?.notifications &&
          campaignInfo?.notifications?.length > 0 && (
            <div className={"p-grid"}>
              <div className={"p-col-12 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Notifications</label>
                </div>
              </div>
              <div
                className={
                  "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"
                }
              >
                <DataTable
                  tableStyle={{ overflow: "visible" }}
                  resizableColumns={true}
                  columnResizeMode={"expand"}
                  value={campaignInfo?.notifications}
                >
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field={"name"}
                    header={"Name"}
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field={"segment"}
                    header={"Segment"}
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field={"message"}
                    header={"Prompt"}
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field={"engagementScore"}
                    header={"Eng. Score"}
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field={"delaySend"}
                    header={"Delay send"}
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field={"zipCodes"}
                    header={"ZIP Codes"}
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field={"usersCount"}
                    header={"Users Count"}
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field="edit"
                    header=""
                    body={(rowData: any) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <EditIcon
                          onClick={() => {
                            setStep({
                              step: CAMPAIGN_EDIT_TYPES.NOTIFICATION_EDIT,
                              payload: {
                                notifId: rowData?.id,
                                previousPage: "actionsEditPage",
                              },
                            });
                          }}
                        />
                      </div>
                    )}
                  />
                </DataTable>
              </div>
            </div>
          )}
        <div style={{ marginTop: "10px" }}>
          <Button
            onClick={() =>
              setStep((prev: any) => {
                return {
                  ...prev,
                  step: (prev?.step as number) - 1,
                };
              })
            }
            className={"p-button-warning"}
            icon={"pi pi-arrow-left"}
            label={"Back"}
          />
          <Button
            style={{ marginRight: 5 }}
            label={"Next"}
            icon={"pi pi-save"}
            onClick={() =>
              setStep((prev: any) => {
                return {
                  ...prev,
                  step: (prev?.step as number) + 1,
                };
              })
            }
          />
          {(mode === MODE.EDIT) && (
            <Button
              style={{ marginRight: 15 }}
              label={"Update and Exit"}
              icon={"pi pi-save"}
              disabled={isEqual(campaignInfo, initialData)}
              onClick={() => {
                Util.globalSpinner().show();
                updateMyCampaign(campaignInfo?.id as number, {
                  active: campaignInfo?.active,
                  knownVisitor: campaignInfo?.knownVisitor,
                  name: campaignInfo?.name,
                  vehicleFilters: campaignInfo?.filters,
                  expirable: get(campaignInfo, "expirable"),
                  startDate: campaignInfo?.startDate
                    ? moment(campaignInfo?.startDate).format(
                        Util.localDateFormat
                      )
                    : (undefined as any),
                  endDate: campaignInfo?.endDate
                    ? moment(campaignInfo?.endDate).format(Util.localDateFormat)
                    : (undefined as any),
                  ...(campaignInfo?.notifications &&
                    campaignInfo?.notifications?.length > 0 && {
                      rtsNotifications: transformNotificationsToDto(
                        campaignInfo?.notifications
                      ),
                    }),
                  automaticallyDisabled: campaignInfo?.automaticallyDisabled,
                  ...(campaignInfo?.sources &&
                    campaignInfo?.sources?.length > 0 && {
                      semVendorIds: campaignInfo?.sources,
                    }),
                })
                  .then(() => {
                    setInitialData(campaignInfo);
                    setTimeout(() => {
                      history?.push("/my-campaigns");
                    }, 1000);
                  })
                  .catch((error: any) => {
                    Util.showError(error);
                  })
                  .finally(() => {
                    Util.globalSpinner().hide();
                  });
              }}
            />
          )}
        </div>
      </div>
    </Panel>
  );
};

export default CampaignActionsEdit;
