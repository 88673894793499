import React, { useEffect, useRef, useState } from "react";
import "./global_settings.css";
import TagsInput from "react-tagsinput";
import Util from "../../util/Util";
import { Button } from "primereact/button";
import {
  getSettingsList,
  saveSettings,
} from "../../actions/globalSettingsActions";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputTextarea } from "primereact/inputtextarea";
import { confirmAlert } from "react-confirm-alert";
import { SettingsName } from "../../util/Enums";
import { InputText } from "primereact/inputtext";
import { AxiosResponse } from "../../../index.dts";
import { useHistory } from "react-router-dom";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { InputSwitch } from "primereact/inputswitch";
import BlockedIpAddress from "../../components/settings/blocked_ip_address/BlockedIpAddress";
import { TAG_INPUT_PLACEHOLDERS } from "../../util/tags_input";

interface ISupportPortalValue {
  q: string;
  a: string;
}

interface IWebsiteVendor {
  id: number | string | null;
  value: string;
  created: string;
  updated: string;
  vendorSpecificTags: boolean;
  isNew?: boolean;
}

interface ISettings {
  websiteVendors: any[];
  leadFilteringMap: any;
  supportPortalFaqValues: any;
  adminEmailFilteringMap: any;
}

export function GlobalSettings() {
  const history = useHistory();
  const forceUpdate = useForceUpdate();
  const unblockHandle = useRef<any>();

  const [websiteVendorLimit, setWebsiteVendorLimit] = useState<number>(
    Util.dataGridDefaultLimit
  );
  const [websiteVendorFirst, setWebsiteVendorFirst] = useState<number>(0);
  const [websiteVendorPage, setWebsiteVendorPage] = useState<number>(0);

  const [supportPortalLimit, setSupportPortalLimit] = useState<number>(
    Util.dataGridDefaultLimit
  );
  const [supportPortalFirst, setSupportPortalFirst] = useState<number>(0);
  const [supportPortalPage, setSupportPortalPage] = useState<number>(0);

  const [leadFilteringInput, setLeadFilteringInput] = useState<string>("");
  const [leadFilteringMap, setLeadFilteringMap] = useState<
    Map<string, { id: number | null; value: string }>
  >(new Map());

  const [adminEmailFilteringInput, setAdminEmailFilteringInput] =
    useState<string>("");
  const [adminEmailFilteringMap, setAdminEmailFilteringMap] = useState<
    Map<string, { id: number | null; value: string }>
  >(new Map());

  const [webSiteVendors, setWebSiteVendors] = useState<IWebsiteVendor[]>([]);
  const [selectedWebSiteVendors, setSelectedWebSiteVendors] = useState<
    IWebsiteVendor[]
  >([]);

  const [supportPortalFaqValues, setSupportPortalFaqValues] = useState<
    {
      id: number | string | null;
      isNew?: boolean;
      value: ISupportPortalValue;
    }[]
  >([]);
  const [selectedSupportPortalFaqValues, setSelectedSupportPortalFaqValues] =
    useState<
      {
        id: number | string | null;
        isNew?: boolean;
        value: ISupportPortalValue;
      }[]
    >([]);

  const [websiteVendorEditingMap] = useState<Map<string, IWebsiteVendor>>(
    new Map()
  );
  const [supportPortalEditingMap] = useState<Map<string, ISupportPortalValue>>(
    new Map()
  );

  const [currentSettings, setCurrentSettings] = useState<ISettings>();

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    unblockHandle.current = history.block((targetLocation: any) => {
      return onLeave(targetLocation);
    });
    return () => {
      unblockHandle.current.current && unblockHandle.current.current();
    };
    // eslint-disable-next-line
  }, [
    history.location,
    leadFilteringMap,
    adminEmailFilteringMap,
    webSiteVendors,
    supportPortalFaqValues,
    currentSettings,
  ]);

  const getSettings = () => {
    Util.globalSpinner().show();
    getSettingsList()
      .then((response) => {
        setSettings(response);
      })
      .catch((error) => Util.showError(error))
      .finally(() => Util.globalSpinner().hide());
  };

  const setSettings = (response: AxiosResponse) => {
    const LEAD_FILTERING_LIST = (
      response.data.settings[SettingsName.LEAD_FILTERING] || []
    ).map((item: any) => {
      return [item.value, { id: item.id, value: item.value }];
    });

    const ADMINISTRATORS_EMAIL_LIST = (
      response.data.settings[SettingsName.ADMINISTRATOR_EMAIL] || []
    ).map((item: any) => {
      return [item.value, { id: item.id, value: item.value }];
    });

    const SUPPORT_PORTAL_FAQ_LIST = (
      response.data.settings[SettingsName.SUPPORT_PORTAL_FAQ] || []
    ).map((item: any) => {
      item.value = JSON.parse(item.value);
      return item;
    });

    setWebSiteVendors(response.data.websiteVendors);
    setLeadFilteringMap(new Map(LEAD_FILTERING_LIST));
    setSupportPortalFaqValues(SUPPORT_PORTAL_FAQ_LIST);
    setAdminEmailFilteringMap(new Map(ADMINISTRATORS_EMAIL_LIST));

    setCurrentSettings({
      websiteVendors: Util.deepClone(response.data.websiteVendors),
      supportPortalFaqValues: Util.deepClone(SUPPORT_PORTAL_FAQ_LIST),
      leadFilteringMap: new Map(Util.deepClone(LEAD_FILTERING_LIST)),
      adminEmailFilteringMap: new Map(
        Util.deepClone(ADMINISTRATORS_EMAIL_LIST)
      ),
    });
  };

  const onLeave = (targetLocation: any): boolean => {
    const dto: ISettings = {
      websiteVendors: webSiteVendors,
      supportPortalFaqValues: supportPortalFaqValues,
      leadFilteringMap: leadFilteringMap,
      adminEmailFilteringMap: adminEmailFilteringMap,
    };
    if (!Util._.isEqual(dto, currentSettings)) {
      confirmAlert({
        title: "There are unsaved changes!",
        message: "Discard local changes, or save to continue!",
        buttons: [
          {
            label: "Save",
            className: "confirm-save-btn",
            onClick: () => {
              onSave().then(() => {
                setTimeout(() => {
                  history.push(targetLocation);
                });
              });
            },
          },
          {
            label: "Discard",
            onClick: () => {
              setCurrentSettings(dto);
              setTimeout(() => {
                history.push(targetLocation);
              }, 0);
            },
            className: "confirm-discard-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
      return false;
    }
    if (supportPortalEditingMap.size > 0) {
      confirmAlert({
        title:
          "There are unsaved support_portal portal frequently asked question(s) !",
        message: "Discard local changes, or save to continue!",
        buttons: [
          {
            label: "Discard",
            onClick: () => {
              supportPortalEditingMap.clear();
              history.push(targetLocation);
            },
            className: "confirm-discard-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
      return false;
    }
    return true;
  };

  const getId = (id: string | number | null): number | null => {
    if (typeof id === "string") {
      return null;
    }
    return id;
  };

  const getSettingsListDto = (): any[] => {
    const settings: any[] = [];

    adminEmailFilteringMap.forEach((item) => {
      settings.push({
        name: SettingsName.ADMINISTRATOR_EMAIL,
        value: item.value,
        id: item.id,
      });
    });
    leadFilteringMap.forEach((item) => {
      settings.push({
        name: SettingsName.LEAD_FILTERING,
        value: item.value,
        id: item.id,
      });
    });
    supportPortalFaqValues.forEach((item) => {
      settings.push({
        id: getId(item.id),
        name: SettingsName.SUPPORT_PORTAL_FAQ,
        value: JSON.stringify(item.value),
      });
    });
    return settings;
  };

  const onSave = () =>
    new Promise((resolve, reject) => {
      if (
        websiteVendorEditingMap.size === 0 &&
        supportPortalEditingMap.size === 0
      ) {
        Util.globalSpinner().show();
        saveSettings({
          settings: getSettingsListDto(),
          websiteVendors: webSiteVendors
            .filter((item) => !Util.isEmpty(item.value))
            .map((item) => {
              return {
                id: getId(item.id),
                value: item.value,
                vendorSpecificTags: item.vendorSpecificTags,
              };
            }),
        })
          .then((response) => {
            resolve(response);
            setSettings(response);
            Util.success("Settings saved successfully.");
          })
          .catch((error) => {
            reject(error);
            Util.showError(error);
          })
          .finally(() => Util.globalSpinner().hide());
      } else {
        reject();
        Util.warning("You are in edit mode");
      }
    });

  const onAddWebsiteVendor = (): void => {
    if (websiteVendorEditingMap.size === 0) {
      webSiteVendors.unshift({
        id: Util.uniqId(),
        isNew: true,
        value: "",
        created: "",
        updated: "",
        vendorSpecificTags: false,
      });

      forceUpdate();

      setTimeout(() => {
        Util.$("#website-vendors .p-row-editor-init-icon")[0]?.click();
        Util.$("#website-vendors .website-vendor-editor")[0]?.focus();
      });
    } else {
      Util.warning("There is an incomplete website vendor.");
    }
  };

  const onAddSupportPortalFAQ = (): void => {
    if (supportPortalEditingMap.size === 0) {
      supportPortalFaqValues.unshift({
        id: Util.uniqId(),
        isNew: true,
        value: { q: "", a: "" },
      });
      forceUpdate();
      setTimeout(() => {
        Util.$(
          "#support_portal-portal-faq .p-row-editor-init-icon"
        )[0]?.click();
        Util.$(
          "#support_portal-portal-faq .support_portal-portal-editor"
        )[0]?.focus();
      });
    } else {
      Util.warning("There is an incomplete question.");
    }
  };

  const onDeleteWebsiteVendors = () => {
    const recordsOnPage = webSiteVendors.slice(
      websiteVendorPage * websiteVendorLimit,
      websiteVendorPage * websiteVendorLimit + websiteVendorLimit
    );

    const recordsToDelete = selectedWebSiteVendors.filter((item) =>
      recordsOnPage.some((i) => item.id === i.id)
    );
    if (recordsToDelete.length > 0) {
      confirmAlert({
        title: `You are about to delete ${recordsToDelete.length} record${
          recordsToDelete.length > 1 ? "s" : ""
        }!`,
        message: "Are you sure you want to do this?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              recordsToDelete.forEach((item) => {
                const index = webSiteVendors.findIndex((i) => i.id === item.id);
                if (index !== -1) {
                  webSiteVendors.splice(index, 1);
                }
              });
              setSelectedWebSiteVendors([]);
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    } else {
      Util.warning("Please choose at least one record.");
    }
  };

  const onDeleteSupportPortalFAQ = () => {
    const recordsOnPage = supportPortalFaqValues.slice(
      supportPortalPage * supportPortalLimit,
      supportPortalPage * supportPortalLimit + supportPortalLimit
    );

    const recordsToDelete = selectedSupportPortalFaqValues.filter((item) =>
      recordsOnPage.some((i) => item.id === i.id)
    );
    if (recordsToDelete.length > 0) {
      confirmAlert({
        title: `You are about to delete ${recordsToDelete.length} record${
          recordsToDelete.length > 1 ? "s" : ""
        }!`,
        message: "Are you sure you want to do this?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              recordsToDelete.forEach((item) => {
                const index = supportPortalFaqValues.findIndex(
                  (i) => i.id === item.id
                );
                if (index !== -1) {
                  supportPortalFaqValues.splice(index, 1);
                }
              });
              setSelectedSupportPortalFaqValues([]);
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    } else {
      Util.warning("Please choose at least one record.");
    }
  };

  const onWebsiteVendorsRowEditInit = (props: any): void => {
    websiteVendorEditingMap.set(props.data.id, props.data);
  };

  const onWebsiteVendorsRowEditSave = (props: any): void => {
    if (websiteVendorEditingMap.has(props.data.id)) {
      const row = websiteVendorEditingMap.get(props.data.id) as IWebsiteVendor;
      if (Util.isEmpty(row.value)) {
        Util.warning("The value cannot be empty.");
      } else {
        const index = webSiteVendors.findIndex((item) => item.id === row.id);
        if (index !== -1) {
          webSiteVendors[index].value = row.value;
          webSiteVendors[index].vendorSpecificTags = row.vendorSpecificTags;

          websiteVendorEditingMap.delete(props.data.id);
          forceUpdate();
        }
      }
    }
  };

  const onWebsiteVendorsRowEditCancel = (props: any): void => {
    if (props.data.isNew) {
      webSiteVendors.splice(0, 1);
    }
    forceUpdate();
    websiteVendorEditingMap.delete(props.data.id);
  };

  const websiteVendorsRowEditorValidator = (data: any): boolean => {
    if (websiteVendorEditingMap.has(data.id)) {
      const item = websiteVendorEditingMap.get(data.id) as IWebsiteVendor;
      return !Util.isEmpty(item.value);
    }
    return true;
  };

  const onWebsiteVendorsPage = (event: any) => {
    setWebsiteVendorPage(event.page);
    setWebsiteVendorLimit(event.rows);
    setWebsiteVendorFirst(event.first);
    setSelectedWebSiteVendors([]);
  };

  const websiteVendorInputTextEditor = (props: any) => {
    return (
      <InputText
        className={"website-vendor-editor"}
        style={{ width: "100%" }}
        defaultValue={props.rowData.value}
        onChange={(e: any) => {
          if (websiteVendorEditingMap.has(props.rowData.id)) {
            const item = websiteVendorEditingMap.get(
              props.rowData.id
            ) as IWebsiteVendor;
            item.value = e.target.value;
          }
        }}
      />
    );
  };

  const vendorSpecificTagEditor = (props: any) => {
    const item = websiteVendorEditingMap.get(props.rowData.id);
    return (
      <InputSwitch
        style={{ width: 40, height: 12 }}
        className={"website-vendor-editor"}
        checked={item?.vendorSpecificTags}
        onChange={(e: any) => {
          if (item) {
            item.vendorSpecificTags = e.value;
            forceUpdate();
          }
        }}
      />
    );
  };

  const supportPortalInputTextEditor = (props: any, field: "q" | "a") => {
    return (
      <InputTextarea
        className={"support_portal-portal-editor"}
        style={{ minWidth: "100%", maxWidth: "100%", minHeight: 50 }}
        defaultValue={props.rowData.value[field]}
        onChange={(e: any) => {
          if (supportPortalEditingMap.has(props.rowData.id)) {
            const value = supportPortalEditingMap.get(
              props.rowData.id
            ) as ISupportPortalValue;
            value[field] = e.target.value;
          }
        }}
      />
    );
  };

  const questionEditor = (props: any) => {
    return supportPortalInputTextEditor(props, "q");
  };

  const answerEditor = (props: any) => {
    return supportPortalInputTextEditor(props, "a");
  };

  const onSupportPortalRowEditInit = (props: any): void => {
    supportPortalEditingMap.set(props.data.id, props.data.value);
  };

  const onSupportPortalRowEditSave = (props: any): void => {
    if (Util.isEmpty(props.data.value.q) || Util.isEmpty(props.data.value.a)) {
      Util.warning("The question and the answer cannot be empty.");
    } else {
      const index = supportPortalFaqValues.findIndex(
        (item) => item.id === props.data.id
      );
      if (index !== -1 && supportPortalEditingMap.has(props.data.id)) {
        const item = supportPortalEditingMap.get(
          props.data.id
        ) as ISupportPortalValue;

        supportPortalFaqValues[index] = {
          id: props.data.id,
          value: item,
        };

        forceUpdate();
        supportPortalEditingMap.delete(props.data.id);
      }
    }
  };

  const onSupportPortalRowEditCancel = (props: any): void => {
    if (props.data.isNew) {
      supportPortalFaqValues.splice(0, 1);
    }
    forceUpdate();
    supportPortalEditingMap.delete(props.data.id);
  };

  const supportPortalRowEditorValidator = (data: any): boolean => {
    return !(Util.isEmpty(data.value.q) || Util.isEmpty(data.value.a));
  };

  const onSupportPortalPage = (event: any) => {
    setSupportPortalPage(event.page);
    setSupportPortalLimit(event.rows);
    setSupportPortalFirst(event.first);
    setSelectedSupportPortalFaqValues([]);
  };

  const onLeadsChange = (values: string[]) => {
    if (values.length > leadFilteringMap.size) {
      const item = values[values.length - 1];
      leadFilteringMap.set(item, { id: null, value: item });
    } else {
      leadFilteringMap.forEach((item, index, obj) => {
        if (!values.includes(item.value)) {
          obj.delete(item.value);
        }
      });
    }
    forceUpdate();
  };

  const onAdministratorEmailsChange = (values: string[]) => {
    if (values.length > adminEmailFilteringMap.size) {
      const item = values[values.length - 1];
      adminEmailFilteringMap.set(item, { id: null, value: item });
    } else {
      adminEmailFilteringMap.forEach((item, index, obj) => {
        if (!values.includes(item.value)) {
          obj.delete(item.value);
        }
      });
    }
    forceUpdate();
  };

  const vendorSpecificTagsTemplate = (data: IWebsiteVendor) => {
    if (data.vendorSpecificTags) {
      return <div className={"status-enabled"}>Enabled</div>;
    }
    return <div className={"status-disabled"}>Disabled</div>;
  };

  return (
    <div id={"global-settings-view"}>
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div className="card card-w-title datatable-demo">
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Lead Filtering</label>
                  <p className={"description"}>
                    Type words or phrases. All leads will be checked again these
                    words/phrases (case insensitive) and not processed if they
                    contain some of them.
                  </p>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <TagsInput
                  onlyUnique={true}
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  inputValue={leadFilteringInput}
                  value={Array.from(leadFilteringMap.keys())}
                  onChangeInput={setLeadFilteringInput}
                  onChange={onLeadsChange}
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    Platform Administrators Emails
                  </label>
                  <p className={"description"}>
                    Type the emails of the platform administrators. They will
                    receive undelivered emails summary, etc.
                  </p>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <TagsInput
                  onlyUnique={true}
                  validationRegex={Util.emailRegex}
                  onValidationReject={(tags) =>
                    Util.warning(`"${tags[0]}" is not a valid e-mail address.`)
                  }
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  inputValue={adminEmailFilteringInput}
                  value={Array.from(adminEmailFilteringMap.keys())}
                  onChangeInput={setAdminEmailFilteringInput}
                  onChange={onAdministratorEmailsChange}
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div id={"website-vendors"} className={"p-col-12 p-lg-12"}>
                <div
                  className={
                    "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
                  }
                >
                  <DataTable
                    rowEditValidator={websiteVendorsRowEditorValidator}
                    rowsPerPageOptions={Util.rowsPerPageOptions}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                    value={Util.deepClone(webSiteVendors)}
                    paginator={true}
                    editMode={"row"}
                    scrollable
                    totalRecords={webSiteVendors.length}
                    rows={websiteVendorLimit}
                    first={websiteVendorFirst}
                    filterDisplay="row"
                    onPage={onWebsiteVendorsPage}
                    selection={selectedWebSiteVendors}
                    onSelectionChange={(e) =>
                      setSelectedWebSiteVendors(e.value)
                    }
                    onRowEditInit={onWebsiteVendorsRowEditInit}
                    onRowEditSave={onWebsiteVendorsRowEditSave}
                    onRowEditCancel={onWebsiteVendorsRowEditCancel}
                    header={
                      <div style={{ display: "flex" }}>
                        <span style={{ marginRight: 5 }}>Website Vendors</span>
                        <i
                          title={`Type the Website Vendors. Based on the dealership Website Vendor, different tag implementation emails are sent.`}
                          className={"pi pi-info-circle info-icon"}
                        />
                        <div className={"p-grid-header-components"}>
                          <Button
                            style={{ marginRight: 5 }}
                            onClick={onAddWebsiteVendor}
                            icon="pi pi-plus"
                            label={"Add"}
                          />
                          <Button
                            onClick={onDeleteWebsiteVendors}
                            icon="pi pi-trash"
                            className="p-button-danger"
                            label={"Delete"}
                          />
                        </div>
                      </div>
                    }
                  >
                    <Column
                      selectionMode="multiple"
                      style={{ width: "3em", flex: "0.05" }}
                    />
                    <Column
                      filter={true}
                      field={"value"}
                      header={"Vendor"}
                      editor={websiteVendorInputTextEditor}
                    />
                    <Column
                      field={"vendorSpecificTags"}
                      header={"Specific Tags"}
                      style={{ width: 150 }}
                      body={vendorSpecificTagsTemplate}
                      editor={vendorSpecificTagEditor}
                    />
                    <Column
                      rowEditor
                      headerStyle={{ width: "7rem" }}
                      bodyStyle={{ textAlign: "center" }}
                    />
                  </DataTable>
                </div>
              </div>
            </div>
            <div className={"p-grid"}>
              <div
                id={"support_portal-portal-faq"}
                className={"p-col-12 p-lg-12"}
              >
                <div
                  className={
                    "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
                  }
                >
                  <DataTable
                    rowEditValidator={supportPortalRowEditorValidator}
                    rowsPerPageOptions={Util.rowsPerPageOptions}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                    value={Util.deepClone(supportPortalFaqValues)}
                    paginator={true}
                    editMode={"row"}
                    scrollable
                    totalRecords={supportPortalFaqValues.length}
                    rows={supportPortalLimit}
                    filterDisplay="row"
                    first={supportPortalFirst}
                    onPage={onSupportPortalPage}
                    selection={selectedSupportPortalFaqValues}
                    onSelectionChange={(e) =>
                      setSelectedSupportPortalFaqValues(e.value)
                    }
                    onRowEditInit={onSupportPortalRowEditInit}
                    onRowEditSave={onSupportPortalRowEditSave}
                    onRowEditCancel={onSupportPortalRowEditCancel}
                    header={
                      <div style={{ display: "flex" }}>
                        <span>Support Portal FAQ</span>
                        <div className={"p-grid-header-components"}>
                          <Button
                            style={{ marginRight: 5 }}
                            onClick={onAddSupportPortalFAQ}
                            icon="pi pi-plus"
                            label={"Add"}
                          />
                          <Button
                            onClick={onDeleteSupportPortalFAQ}
                            icon="pi pi-trash"
                            className="p-button-danger"
                            label={"Delete"}
                          />
                        </div>
                      </div>
                    }
                  >
                    <Column
                      selectionMode="multiple"
                      style={{ width: "3em", flex: "0.05" }}
                    />
                    <Column
                      filter={true}
                      field={"value.q"}
                      header={"Question"}
                      editor={questionEditor}
                    />
                    <Column
                      filter={true}
                      field={"value.a"}
                      header={"Answer"}
                      editor={answerEditor}
                    />
                    <Column
                      rowEditor
                      headerStyle={{ width: "7rem" }}
                      bodyStyle={{ textAlign: "center" }}
                    />
                  </DataTable>
                </div>
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <Button onClick={onSave} label={"Save"} icon={"pi pi-save"} />
                <Button
                  id="apply-btn"
                  onClick={getSettings}
                  label={"Cancel"}
                  className={"p-button-warning"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <BlockedIpAddress />
    </div>
  );
}

export default GlobalSettings;
