export const ascEvents = [
  { name: "affiliation" },
  { name: "affiliation_id" },
  { name: "alert" },
  { name: "agent_escalation" },
  { name: "asc_comm_engagement" },
  { name: "asc_comm_submission" },
  { name: "asc_comm_submission_parts" },
  { name: "asc_comm_submission_sales" },
  { name: "asc_comm_submission_sales_appt" },
  { name: "asc_comm_submission_service" },
  { name: "asc_comm_submission_service_appt" },
  { name: "asc_comm_submission_other" },
  { name: "asc_click_to_call" },
  { name: "asc_cta_interaction" },
  { name: "asc_element_configuration" },
  { name: "asc_element_subtype" },
  { name: "asc_form_engagement" },
  { name: "asc_form_submission" },
  { name: "asc_form_submission_other" },
  { name: "asc_form_submission_parts" },
  { name: "asc_form_submission_sales" },
  { name: "asc_form_submission_sales_appt" },
  { name: "asc_form_submission_service" },
  { name: "asc_form_submission_service_appt" },
  { name: "asc_item_pageview" },
  { name: "asc_itemlist_pageview" },
  { name: "asc_media_interaction" },
  { name: "asc_menu_interaction" },
  { name: "asc_pageview" },
  { name: "asc_purchase" },
  { name: "asc_retail_process" },
  { name: "asc_special_offer" },
  { name: "asc_voice_engagement" },
  { name: "asc_voice_engagement_30" },
  { name: "asc_voice_engagement_60" },
  { name: "asc_voice_engagement_120" },
  { name: "asc_voice_sale" },
  { name: "asc_voice_submission" },
  { name: "asc_voice_submission_other" },
  { name: "asc_voice_submission_parts" },
  { name: "asc_voice_submission_sales" },
  { name: "asc_voice_submission_sales_appt" },
  { name: "asc_voice_submission_service" },
  { name: "asc_voice_submission_service_appt" },
  { name: "asc_video_call_engagement" },
  { name: "asc_video_call_submission" },
  { name: "asc_video_call_submission_sales" },
  { name: "asc_video_call_submission_service" },
  { name: "asc_video_submission_parts" },
  { name: "asc_video_submission_sales_appt" },
  { name: "asc_video_submission_service_appt" },
  { name: "asc_video_submission_other" },
  { name: "back" },
  { name: "body" },
  { name: "digital_retailing_handoff" },
  { name: "download" },
  { name: "element_text" },
  { name: "finished" },
  { name: "hero_image" },
  { name: "inline" },
  { name: "inspire" },
  { name: "item_location" },
  { name: "keystroke" },
  { name: "kebab_menu" },
  { name: "menu" },
  { name: "none" },
  { name: "offer" },
  { name: "phone_tree_keypress" },
  { name: "pagination" },
  { name: "payment_calculator" },
  { name: "product_name" },
  { name: "quote" },
  { name: "retention" },
  { name: "send" },
  { name: "shipment" },
  { name: "showroom" },
  { name: "skipped" },
  { name: "specials" },
  { name: "submission_id" },
  { name: "test_drive" },
  { name: "thank_you" },
  { name: "thumbnail" },
  { name: "viewed" },
  { name: "video_submission_other" },
  { name: "video_submission_sales_appt" },
  { name: "video_submission_service_appt" },
  { name: "video_submission_parts" },
  { name: "video_submission_sales" },
  { name: "video_call_submission_sales" },
  { name: "video_call_submission_service" },
  { name: "event_action" },
];
