import "react-tabs/style/react-tabs.css";
import "./stats.css";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import EventAlerts from "./event_alerts/EventAlerts";
import EventMarkers from "./event_markers/EventMarkers";
import { IStore } from "../../../../index.dts";
import { Leads } from "./leads/Leads";
import { PageViews } from "./page_views/PageViews";
import PeakHours from "./peak_hours/PeakHours";
import PersistentChatStatistics from "../../persistent_chat_statistics/PersistentChatStatistics";
import React, { useEffect } from "react";
import { StatsTabKeys } from "../../../util/Enums";
import { Visits } from "./visits/Visits";
import { updateMyTrafficStatsTabIndex } from "../../../actions/userActions";
import { useTranslation } from "react-i18next";
import MakeModel from "./make_model/MakeModel";
import { entries } from "lodash";

interface IProps {
  dealershipId: number;
  externalRefId: string;
  dealershipName: string;
  dealershipCreated: string;
  persistentChatEnabled: boolean;
}

export const STATS_TAB_KEY = "my_traffic_stats_active_tab";

const Stats = (props: IProps) => {
  const { t }: any = useTranslation();
  const dispatch = useDispatch();

  const activeIndex = useSelector(
    (store: IStore) => store.myTraffic.statsActiveTabIndex,
    shallowEqual
  );

  useEffect(() => {
    //TODO improve
    if (activeIndex === 7) {
      onOpenPersistentChatStats(props.persistentChatEnabled);
    }
    // eslint-disable-next-line
  }, [activeIndex, props.persistentChatEnabled]);

  const onOpenPersistentChatStats = (persistentChatEnabled: boolean): void => {
    if (!persistentChatEnabled) {
      onTabChange(0);
    }
  };

  const onTabChange = (index: number) => {
    dispatch(updateMyTrafficStatsTabIndex(index));
  };

  const renderer = (): any => {
    return (
      <Tabs onSelect={onTabChange} selectedIndex={activeIndex}>
        <TabList>
          {entries(StatsTabKeys).map((entry) => {
            if (entry[1] === StatsTabKeys.PERSISTENT_CHAT) {
              return (
                <Tab key={entry[0]} disabled={!props.persistentChatEnabled}>
                  {t(entry[1])}
                </Tab>
              );
            }

            return <Tab key={entry[0]}>{t(entry[1])}</Tab>;
          })}
        </TabList>

        <TabPanel className={"tab-content"}>
          <Visits />
        </TabPanel>

        <TabPanel className={"tab-content"}>
          <PageViews />
        </TabPanel>

        <TabPanel className={"tab-content"}>
          <Leads />
        </TabPanel>

        <TabPanel className={"tab-content"}>
          <MakeModel />
        </TabPanel>

        <TabPanel className={"tab-content"}>
          <PeakHours dealershipId={props.dealershipId} />
        </TabPanel>

        <TabPanel className={"tab-content"}>
          <EventMarkers dealershipId={props.dealershipId} />
        </TabPanel>

        <TabPanel className={"tab-content"}>
          <EventAlerts dealershipId={props.dealershipId} />
        </TabPanel>

        <TabPanel
          disabled={!props.persistentChatEnabled}
          className={"tab-content"}
        >
          <PersistentChatStatistics
            dealershipId={props.dealershipId}
            dealershipName={props.dealershipName}
            persistentChatEnabled={props.persistentChatEnabled}
          />
        </TabPanel>
      </Tabs>
    );
  };

  return renderer();
};

export default Stats;
