import * as d3 from "d3";

import { COOKIES, PERMISSIONS } from "./Enums";
import {
  IDealership,
  IDealershipContext,
  IDealershipDto,
  IPermission,
} from "../../index.dts";

import $ from "jquery";
import { BrowserHistory } from "history";
import ChatModes from "../constants/chat_modes";
import Cookie from "js-cookie";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { getMyDealerships } from "../actions/dealershipActions";
import { getNotificationUsers } from "../actions/MyTrafficNotificationActions";
import { getOfferList } from "../actions/offerActions";
import moment from "moment-timezone";
import { resendDealershipWeeklyReport } from "../actions/supportPortalActions";
import store from "../config/store";
import { split } from "lodash";

const url = require("url");
const _ = require("lodash");
const uniqId = require("uniqid");
const objectMapper = require("object-mapper");
const validator = require("email-validator");

export default class Util {
  public static defaultYear = "1970-01-01";
  public static dataGridDefaultLimit = 10;
  public static rowsPerPageOptions = [10, 20, 50, 100];
  public static localDateFormat = "YYYY-MM-DD";
  public static localDateTimeFormat = "YYYY-MM-DDTHH:mm:ss";
  public static usDateFormat = "MM/DD/YYYY";
  public static usDateTimeFormat = "MM/DD/YYYY HH:mm:ss";
  public static customDateTimeFormat = "MM/DD/YYYY HH:mm";
  public static usCalendarDateFormat = "mm/dd/yy";
  public static startYear = "2000";
  public static phone_mask = "(999) 999-9999";
  public static yearRange = `${Util.startYear}:${moment().year()}}`;
  // eslint-disable-next-line no-control-regex
  public static emailRegex = new RegExp(
    /(?:[A-Za-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  );
  public static ipAddressRegex = new RegExp(
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  );
  // eslint-disable-next-line
  public static yearRegex = new RegExp(/^(19[5-9]\d|20[0-4]\d|2050)$/);
  // eslint-disable-next-line
  public static urlRegex = new RegExp(
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
    "i"
  );
  public static hour24Regex = new RegExp(/^([01]?[0-9]|2[0-3])$/);
  public static numbersRegex = /^\d+$/;
  public static defaultLogoPath = "/assets/images/logo.png";
  public static defaultTemplateAsset = `/assets/images/default-template-asset.jpg`;
  public static objectMapper = objectMapper;
  public static defaultWarningDealershipMessage =
    "Please select a dealership first.";
  public static defaultErrorMessage =
    "Apologies! We found an issue and we’re working on it. Please REFRESH your browser and try again.";

  public static _ = _;
  public static url = url;

  public static Cookie = Cookie;
  public static D3 = d3;
  public static $ = $;
  public static uniqId = uniqId;

  public static daysOfWeek: {
    fullName: string;
    label: string;
    value: string;
  }[] = [
    { fullName: "Monday", label: "Mon", value: "1" },
    { fullName: "Tuesday", label: "Tue", value: "2" },
    { fullName: "Wednesday", label: "Wed", value: "3" },
    { fullName: "Thursday", label: "Thu", value: "4" },
    { fullName: "Friday", label: "Fri", value: "5" },
    { fullName: "Saturday", label: "Sat", value: "6" },
    { fullName: "Sunday", label: "Sun", value: "7" },
  ];

  private static defaultDealership = {
    value: 0,
    label: "",
  };

  public static moment = (date: string, tzFormat: string): string => {
    const defaultTimezone = Util._.get(
      "process/browser",
      "env.REACT_APP_DEALERSHIP_TIMEZONE",
      "America/New_York"
    );

    const timezone: string = Util.getIfNullOrEmpty(
      store.getState().auth.dealershipContext.timezone,
      defaultTimezone
    );

    return Util.convertLocalToTimezone(
      date,
      Util.localDateTimeFormat,
      timezone,
      tzFormat
    );
  };

  public static convertLocalToTimezone(
    localDt: string,
    localDtFormat: any,
    timezone: string,
    tzFormat: string
  ): string {
    return moment(localDt, localDtFormat).tz(timezone).format(tzFormat);
  }

  public static globalSpinner = () => {
    return store.getState().myTraffic.globalSpinner;
  };

  public static getIfNullOrEmpty<T, E>(source: T, ifNullOrEmpty: E): T | E {
    if (source === null || source === undefined) {
      return ifNullOrEmpty;
    }

    if (typeof source === "string" && !source.trim()) {
      return ifNullOrEmpty;
    }

    if (Array.isArray(source) && source.length === 0) {
      return ifNullOrEmpty;
    }

    return source;
  }

  public static success = (message: string) => {
    store.getState().myTraffic.growl.success(message);
  };

  public static deepClone = <T>(obj: T): T => {
    try {
      return JSON.parse(JSON.stringify(obj));
    } catch (e) {
      return obj;
    }
  };

  public static warning = (message: React.ReactNode) => {
    store.getState().myTraffic.growl.warning(message);
  };

  public static info = (message: string) => {
    store.getState().myTraffic.growl.info(message);
  };

  public static error = (message: string) => {
    store.getState().myTraffic.growl.error(message);
  };

  public static showError = (error: any) => {
    const response = Util._.get(error, "response.data", null);

    if (response) {
      if (Array.isArray(response.errors) && response.errors.length > 0) {
        Util.error(response.errors.map((e: any) => `${e.defaultMessage} \n`));
      } else {
        const message = Util._.get(response, "message", null);
        Util.error(Util.getDefaultIfNull(message, Util.defaultErrorMessage));
      }
    } else {
      Util.error(Util.defaultErrorMessage);
    }
  };

  public static isEmpty = (str?: string | null): boolean => {
    return !(str && str.trim());
  };

  public static isMobile() {
    return window.innerWidth <= 640;
  }

  public static isDesktop() {
    return window.innerWidth > 1024;
  }

  public static addLastTag = (
    array: string[],
    input: string,
    validationRegex: RegExp
  ): void => {
    if (!Util.isEmpty(input)) {
      if (validationRegex.test(input)) {
        array.push(input);
      } else {
        Util.warning(`Invalid input "${input}".`);
      }
    }
  };

  public static secondsToTime(s: number) {
    const dateObj = new Date(s * 1000);
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getSeconds();

    return (
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0")
    );
  }

  public static PATH_NAMES = {
    CHATS_VIEW: "/chats",
    DASHBOARD: "/",
    DEALERSHIPS: "/dealerships",
    DEALERSHIPS_AND_USERS: "/dealerships/assign-users",
    DEALERSHIPS_EDIT: "/dealerships/edit",
    DEALERSHIPS_CREATE: "/dealerships/create",
    OFFERS: "/offers",
    OFFERS_EDIT: "/offers/edit",
    OFFERS_CREATE: "/offers/create",
    TEMPLATES: "/templates",
    TEMPLATES_EDIT: "/templates/edit",
    TEMPLATES_CREATE: "/templates/create",
    ASSETS: "/application-assets",
    VE2_DEALERSHIP_PROFILE: "/dealership/configuration-profile",
    REPORTS: "/reports",
    MAG_EXTERNAL_REP: "/mag-external",
    CDK_RO_ASSIST: "/cdk-ro-assist",
    CDK_RO_ASSIST_CREATE: "/cdk-ro-assist/create",
    CDK_RO_ASSIST_EDIT: "/cdk-ro-assist/edit",
    SUPPORT_PORTAL: "/support-portal",
    IOFFER_PORTAL: "/iOffer-portal",
    MY_TRAFFIC: "/my-traffic",
    MY_TRAFFIC_WISH_LIST: "/my-traffic/my-acquisitions-sales",
    MY_TRAFFIC_PERFORMANCE_REPORT: "/my-traffic/performance-report",
    MY_TRAFFIC_EDIT_EVENT_ALERT: "/my-traffic/edit-alert",
    MY_TRAFFIC_CREATE_EVENT_ALERT: "/my-traffic/create-alert",
    MY_TRAFFIC_CREATE_CAMPAIGN: "/my-traffic/create-campaign",
    MY_TRAFFIC_EDIT_CAMPAIGN: "/my-traffic/edit-campaign",
    MY_TRAFFIC_CREATE_NOTIFICATION: "/my-traffic/create-notification",
    MY_TRAFFIC_EDIT_NOTIFICATION: "/my-traffic/edit-notification",
    MY_TRAFFIC_GROUP_CREATE: "/my-traffic/create-group",
    MY_TRAFFIC_GROUP_EDIT: "/my-traffic/edit-group",
    MY_CAMPAIGNS: "/my-campaigns",
    MY_CAMPAIGNS_EDIT: "/my-campaigns/edit",
    MY_CAMPAIGNS_DUPLICATE: "/my-campaigns/duplicate",
    MY_CAMPAIGNS_CREATE: "/my-campaigns/create",
    GLOBAL_SETTINGS: "/global-settings",
    USERS: "/users",
    EVENT_LOGS: "/event-logs",
    ROLES: "/roles",
    CRM_SOURCES: "/channel-partners",
    LAUNCH_REQUEST: "/launch-request",
    LOGIN: "/login",
    OFFER_CERTIFICATE: "/offer-certificate/:dealerId/:guid",
    OFFER_FORM: "/offer-form/:iOfferDataId/:acquisitionSource",
    PROFILE: "/profile",
    BRANDS: "/brands",
    ENTERPRISE_GROUP: "/enterprise-group",
    ENTERPRISE_GROUP_EDIT: "/enterprise-group/edit",
    ENTERPRISE_GROUP_CREATE: "/enterprise-group/create",
    NO_PERMISSIONS: "/no-permissions",
  };

  public static ROUTE_AND_PERMISSIONS: { [key: string]: PERMISSIONS[] } = {
    [Util.PATH_NAMES.CHATS_VIEW]: [PERMISSIONS.RTS_PERSISTENT_CHAT],
    [Util.PATH_NAMES.DASHBOARD]: [PERMISSIONS.DEALERSHIP_VIEW_REPORTS],
    [Util.PATH_NAMES.DEALERSHIPS]: [
      PERMISSIONS.DEALERSHIP_MANAGEMENT,
      PERMISSIONS.DEALERSHIP_ACCESS,
    ],
    [Util.PATH_NAMES.DEALERSHIPS_AND_USERS]: [
      PERMISSIONS.DEALERSHIP_MANAGEMENT,
      PERMISSIONS.USERS_VIEW,
    ],
    [Util.PATH_NAMES.DEALERSHIPS_EDIT]: [PERMISSIONS.DEALERSHIP_MANAGEMENT],
    [Util.PATH_NAMES.DEALERSHIPS_CREATE]: [PERMISSIONS.DEALERSHIP_MANAGEMENT],
    [Util.PATH_NAMES.OFFERS]: [PERMISSIONS.OFFERS_ACCESS],
    [Util.PATH_NAMES.MY_CAMPAIGNS]: [PERMISSIONS.MY_CAMPAIGNS_ACCESS], // ->>>>>>>>>>>>>>>>>>> CHANGE THIS IN THE BE AND FE WHEN READY
    [Util.PATH_NAMES.MY_CAMPAIGNS_CREATE]: [
      PERMISSIONS.MY_CAMPAIGNS_MANAGEMENT,
    ], // ->>>>>>>>>>>>>>>>>>> CHANGE THIS IN THE BE AND FE WHEN READY
    [Util.PATH_NAMES.MY_CAMPAIGNS_EDIT]: [PERMISSIONS.MY_CAMPAIGNS_MANAGEMENT], // ->>>>>>>>>>>>>>>>>>> CHANGE THIS IN THE BE AND FE WHEN READY
    [Util.PATH_NAMES.MY_CAMPAIGNS_DUPLICATE]: [
      PERMISSIONS.MY_CAMPAIGNS_MANAGEMENT,
    ], // ->>>>>>>>>>>>>>>>>>> CHANGE THIS IN THE BE AND FE WHEN READY
    [Util.PATH_NAMES.OFFERS_EDIT]: [PERMISSIONS.OFFERS_MANAGEMENT],
    [Util.PATH_NAMES.OFFERS_CREATE]: [PERMISSIONS.OFFERS_MANAGEMENT],
    [Util.PATH_NAMES.TEMPLATES]: [
      PERMISSIONS.TEMPLATES_ACCESS,
      PERMISSIONS.TEMPLATES_MANAGEMENT,
    ],
    [Util.PATH_NAMES.TEMPLATES_EDIT]: [PERMISSIONS.TEMPLATES_MANAGEMENT],
    [Util.PATH_NAMES.TEMPLATES_CREATE]: [PERMISSIONS.TEMPLATES_MANAGEMENT],
    [Util.PATH_NAMES.ASSETS]: [PERMISSIONS.ASSETS_MANAGEMENT],
    [Util.PATH_NAMES.VE2_DEALERSHIP_PROFILE]: [
      PERMISSIONS.DEALERSHIP_MANAGEMENT,
    ],
    [Util.PATH_NAMES.MY_TRAFFIC_WISH_LIST]: [PERMISSIONS.DEALERSHIP_MANAGEMENT],
    [Util.PATH_NAMES.MY_TRAFFIC_PERFORMANCE_REPORT]: [
      PERMISSIONS.DEALERSHIP_MANAGEMENT,
    ],
    [Util.PATH_NAMES.REPORTS]: [PERMISSIONS.VIEW_REPORTS],
    [Util.PATH_NAMES.MAG_EXTERNAL_REP]: [PERMISSIONS.MAG_EXTERNAL_REP],
    [Util.PATH_NAMES.CDK_RO_ASSIST]: [
      PERMISSIONS.RO_ADMINISTRATOR,
      PERMISSIONS.RO_PARTS_TECH,
      PERMISSIONS.RO_ADVISOR,
    ],
    [Util.PATH_NAMES.CDK_RO_ASSIST_CREATE]: [
      PERMISSIONS.RO_ADMINISTRATOR,
      PERMISSIONS.RO_PARTS_TECH,
      PERMISSIONS.RO_ADVISOR,
    ],
    [Util.PATH_NAMES.CDK_RO_ASSIST_EDIT]: [
      PERMISSIONS.RO_ADMINISTRATOR,
      PERMISSIONS.RO_PARTS_TECH,
      PERMISSIONS.RO_ADVISOR,
    ],
    [Util.PATH_NAMES.SUPPORT_PORTAL]: [
      PERMISSIONS.DEALERSHIP_PAUSE_OFFERS,
      PERMISSIONS.DEALERSHIP_WEEKLY_REPORTS,
      PERMISSIONS.DEALERSHIP_OFFER_ASSET_UPDATE,
      PERMISSIONS.DEALERSHIP_OFFER_STATUS_UPDATE,
      PERMISSIONS.DEALERSHIP_OFFER_EXPIRATION_UPDATE,
      PERMISSIONS.DEALERSHIP_OFFER_LIMITATION,
      PERMISSIONS.DEALERSHIP_OFFER_TERMS_UPDATE,
      PERMISSIONS.RTS_ADD_USER,
      PERMISSIONS.RTS_RESEND_INVITATION,
      PERMISSIONS.DEALERSHIP_SUPPORT_REQUEST,
      PERMISSIONS.DEALERSHIP_SEND_TAGS,
      PERMISSIONS.DEALERSHIP_OFFER_RESTRICT_IP_ADDRESS,
      PERMISSIONS.DEALERSHIP_RESEND_WEEKLY_REPORT,
    ],
    [Util.PATH_NAMES.IOFFER_PORTAL]: [
      // permission to be added ?!
    ],
    [Util.PATH_NAMES.MY_TRAFFIC]: [
      PERMISSIONS.RTS_ACCESS,
      PERMISSIONS.RTS_GROUPS_MANAGEMENT,
    ],
    [Util.PATH_NAMES.GLOBAL_SETTINGS]: [PERMISSIONS.SETTINGS_MANAGEMENT],
    [Util.PATH_NAMES.USERS]: [PERMISSIONS.USERS_MANAGEMENT],
    [Util.PATH_NAMES.EVENT_LOGS]: [PERMISSIONS.VIEW_EVENT_LOGS],
    [Util.PATH_NAMES.ROLES]: [PERMISSIONS.ROLES_MANAGEMENT],
    [Util.PATH_NAMES.CRM_SOURCES]: [PERMISSIONS.DEALERSHIP_MANAGEMENT],
    [Util.PATH_NAMES.LAUNCH_REQUEST]: [PERMISSIONS.DEALERSHIP_LAUNCH_REQUEST],
    [Util.PATH_NAMES.PROFILE]: [],
    [Util.PATH_NAMES.ENTERPRISE_GROUP]: [
      PERMISSIONS.RTS_ENTERPRISE_GROUPS_MANAGE_ALL,
      PERMISSIONS.RTS_ENTERPRISE_GROUPS_MANAGE_OWN,
    ],
    [Util.PATH_NAMES.ENTERPRISE_GROUP_EDIT]: [
      PERMISSIONS.RTS_ENTERPRISE_GROUPS_MANAGE_ALL,
      PERMISSIONS.RTS_ENTERPRISE_GROUPS_MANAGE_OWN,
    ],
    [Util.PATH_NAMES.ENTERPRISE_GROUP_CREATE]: [
      PERMISSIONS.RTS_ENTERPRISE_GROUPS_MANAGE_ALL,
      PERMISSIONS.RTS_ENTERPRISE_GROUPS_MANAGE_OWN,
    ],
    [Util.PATH_NAMES.MY_TRAFFIC_CREATE_CAMPAIGN]: [
      PERMISSIONS.RTS_GROUP_CAMPAIGNS_MANAGEMENT,
    ],
    [Util.PATH_NAMES.MY_TRAFFIC_EDIT_CAMPAIGN]: [
      PERMISSIONS.RTS_GROUP_CAMPAIGNS_MANAGEMENT,
    ],
    [Util.PATH_NAMES.BRANDS]: [PERMISSIONS.DEALERSHIP_MANAGEMENT],
    [Util.PATH_NAMES.MY_TRAFFIC_CREATE_NOTIFICATION]: [
      PERMISSIONS.RTS_NOTIFICATIONS,
    ],
    [Util.PATH_NAMES.MY_TRAFFIC_EDIT_NOTIFICATION]: [
      PERMISSIONS.RTS_NOTIFICATIONS,
    ],
    [Util.PATH_NAMES.MY_TRAFFIC_EDIT_EVENT_ALERT]: [PERMISSIONS.RTS_STATISTICS],
    [Util.PATH_NAMES.MY_TRAFFIC_CREATE_EVENT_ALERT]: [
      PERMISSIONS.RTS_STATISTICS,
    ],
    [Util.PATH_NAMES.MY_TRAFFIC_GROUP_CREATE]: [
      PERMISSIONS.RTS_GROUPS_MANAGEMENT,
    ],
    [Util.PATH_NAMES.MY_TRAFFIC_GROUP_EDIT]: [
      PERMISSIONS.RTS_GROUPS_MANAGEMENT,
    ],
  };

  public static removeAlert() {
    const alert = document.getElementById("react-confirm-alert");
    if (alert) alert.remove();

    const svg = document.getElementById("react-confirm-alert-firm-svg");
    if (svg) svg.remove();
  }

  public static hasSpecialCharacters(str: string): boolean {
    return new RegExp(/[^\w\s]/).test(str);
  }

  public static hasUpperCase(str: string): boolean {
    return new RegExp(/[A-Z]/).test(str);
  }

  public static hasNumber(str: string): boolean {
    return new RegExp(/[0-9]/).test(str);
  }

  public static validateEmail(mail?: string | null): boolean {
    if (!mail) {
      return false;
    }

    const array = `${mail}`.split(",");

    for (const email of array) {
      const isValid = validator.validate(email.trim()) && email.length <= 256;

      if (!isValid) {
        return false;
      }
    }

    return true;
  }

  public static validatePhoneNumber(phoneNumber: string): boolean {
    return new RegExp(
      /^(?:\+?1[-.●]?)?\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/
    ).test(phoneNumber);
  }

  public static validatePassword(password: string): boolean {
    return (
      new RegExp(
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/
      ).test(password) && password.length <= 64
    );
  }

  public static validateURL(url: string): boolean {
    return Util.urlRegex.test(url);
  }

  public static validateIpAddress(ip: string): boolean {
    return Util.ipAddressRegex.test(ip);
  }

  public static emptyObject = (obj: any): void => {
    for (let key in obj) {
      delete obj[key];
    }
  };

  public static getTimezones = () => {
    return moment.tz.names().map((item) => {
      return {
        label: `${item} (GMT ${moment.tz(item).format("Z")})`,
        value: item,
      };
    });
  };

  public static sortByDate = (array: any[], field?: string): any[] => {
    return array.sort((a, b) => {
      if (field) {
        if (moment(a[field], Util.usDateTimeFormat).isBefore(b[field])) {
          return 1;
        }
        if (moment(a[field], Util.usDateTimeFormat).isAfter(b[field])) {
          return -1;
        }
        return 0;
      } else {
        if (moment(a, Util.usDateTimeFormat).isBefore(b)) {
          return 1;
        }
        if (moment(a, Util.usDateTimeFormat).isAfter(b)) {
          return -1;
        }
        return 0;
      }
    });
  };

  public static getDealershipContext = (): any => {
    try {
      const dealershipContextStr: string | undefined = Cookie.get(
        COOKIES.DEALERSHIP_CONTEXT
      );

      if (!Util.isEmpty(dealershipContextStr)) {
        return JSON.parse(dealershipContextStr as string);
      }
      return Util.defaultDealership;
    } catch (e) {
      console.error(e);
      return Util.defaultDealership;
    }
  };

  public static transform = (
    key: string,
    defaultValue: any
  ): { key: string; transform: any } => {
    return {
      key: key,
      transform: function (value: any) {
        return value ? value : defaultValue;
      },
    };
  };

  public static utcToLocal = (date: string, format?: string) => {
    if (!date) {
      return "";
    }

    return moment(moment.utc(date).toDate())
      .local()
      .format(format || "YYYY-MM-DD HH:mm:ss");
  };

  public static utcToTimezone = (
    date: string,
    timezone?: string,
    format?: string
  ): string => {
    if (!date) {
      return "";
    }
    const defaultTimezone = Util._.get(
      "process/browser",
      "env.REACT_APP_DEALERSHIP_TIMEZONE",
      "America/New_York"
    );

    return moment
      .utc(date)
      .tz(Util.getDefaultIfNull(timezone, defaultTimezone))
      .format(format || "YYYY-MM-DD HH:mm:ss");
  };

  public static timezoneToUtc = (
    date: string,
    timezone: string,
    format?: string
  ) => {
    if (!date) {
      return "";
    }

    const defaultTimezone = Util._.get(
      process,
      "env.REACT_APP_DEALERSHIP_TIMEZONE",
      "America/New_York"
    );

    return moment
      .tz(date, timezone || defaultTimezone)
      .utc()
      .format();
  };

  public static localToUtc = (date: string, format?: string) => {
    if (!date) {
      return "";
    }

    return moment(date)
      .utc()
      .format(format || "YYYY-MM-DD HH:mm:ss");
  };

  public static hasAnyAuthority = (...permissions: PERMISSIONS[]): boolean => {
    if (permissions.length === 0) {
      return true;
    }

    const userPermissions = store.getState().auth.user.role.permissions;
    return (
      userPermissions.findIndex((item) => permissions.includes(item.name)) !==
      -1
    );
  };

  public static getRouteByPermission = (
    permissions: IPermission[],
    path: string,
    history: BrowserHistory
  ): string => {
    const routePermissions: string[] = Util._.get(
      Util.ROUTE_AND_PERMISSIONS,
      path,
      []
    );

    if (Util.ROUTE_AND_PERMISSIONS[path].length === 0) {
      return path;
    }

    if (path.startsWith("/login")) {
      for (const [route, per] of Object.entries(Util.ROUTE_AND_PERMISSIONS)) {
        if (permissions.some((permission) => per.includes(permission.name))) {
          return route;
        }
      }
      return "/no-permissions";
    }

    if (
      permissions.some((permission) =>
        routePermissions.includes(permission.name)
      )
    ) {
      return path;
    } else {
      for (const [route, per] of Object.entries(Util.ROUTE_AND_PERMISSIONS)) {
        if (permissions.some((permission) => per.includes(permission.name))) {
          return route;
        }
      }
      return "/no-permissions";
    }
  };

  public static resendWeeklyReport(dealership: {
    name: string;
    id: number;
  }): void {
    confirmAlert({
      title: `Resend weekly report!`,
      message: `Are you sure you want to resend the weekly report for "${dealership.name}".`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            Util.globalSpinner().show();
            resendDealershipWeeklyReport(dealership.id)
              .then((response) => {
                Util.success("Weekly report sent successfully.");
              })
              .catch((error) => Util.showError(error))
              .finally(() => Util.globalSpinner().hide());
          },
          className: "confirm-save-btn",
        },
        {
          label: "Cancel",
          className: "confirm-cancel-btn",
          onClick: () => {},
        },
      ],
    });
  }

  public static getMenu = (): any[] => {
    const menu: any = [];
    if (
      Util.hasAnyAuthority(
        ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.DASHBOARD]
      )
    ) {
      menu.push({
        label: "Dashboard",
        icon: "pi pi-fw pi-home",
        to: Util.PATH_NAMES.DASHBOARD,
      });
    }
    if (
      Util.hasAnyAuthority(
        ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.DEALERSHIPS]
      )
    ) {
      menu.push({
        label: "Dealerships",
        icon: "pi pi-fw pi-users",
        to: Util.PATH_NAMES.DEALERSHIPS,
      });
    }
    if (
      Util.hasAnyAuthority(
        ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.OFFERS]
      )
    ) {
      menu.push({
        label: "Offers",
        icon: "pi pi-fw pi-list",
        to: Util.PATH_NAMES.OFFERS,
      });
    }
    if (
      Util.hasAnyAuthority(
        ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.TEMPLATES]
      )
    ) {
      menu.push({
        label: "Templates",
        icon: "pi pi-fw pi-image",
        to: Util.PATH_NAMES.TEMPLATES,
      });
    }
    if (
      Util.hasAnyAuthority(
        ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.ASSETS]
      )
    ) {
      menu.push({
        label: "Assets",
        icon: "pi pi-fw pi-images",
        to: Util.PATH_NAMES.ASSETS,
      });
    }
    if (
      Util.hasAnyAuthority(
        ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.REPORTS]
      )
    ) {
      menu.push({
        label: "Reports",
        icon: "pi pi-fw pi-chart-line",
        to: Util.PATH_NAMES.REPORTS,
      });
    }
    if (
      Util.hasAnyAuthority(
        ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.SUPPORT_PORTAL]
      )
    ) {
      menu.push({
        label: "Support Portal",
        icon: "pi pi-fw pi-user",
        to: Util.PATH_NAMES.SUPPORT_PORTAL,
      });
    }
    if (
      Util.hasAnyAuthority(
        ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.IOFFER_PORTAL]
      )
    ) {
      menu.push({
        label: "iOffer Portal",
        icon: "pi pi-fw pi-user",
        to: Util.PATH_NAMES.IOFFER_PORTAL,
      });
    }
    if (
      Util.hasAnyAuthority(
        ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.MY_TRAFFIC]
      )
    ) {
      menu.push({
        label: "My Traffic",
        icon: "pi pi-fw pi-chart-bar",
        to: Util.PATH_NAMES.MY_TRAFFIC,
      });
    }

    if (
      Util.hasAnyAuthority(
        ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.MY_CAMPAIGNS]
      )
    ) {
      menu.push({
        label: "My Campaigns",
        icon: "pi pi-fw pi-flag",
        to: Util.PATH_NAMES.MY_CAMPAIGNS,
      });
    }

    if (
      Util.hasAnyAuthority(
        ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.ENTERPRISE_GROUP]
      )
    ) {
      menu.push({
        label: "Enterprise Groups",
        icon: "pi pi-fw pi-users",
        to: Util.PATH_NAMES.ENTERPRISE_GROUP,
      });
    }

    if (
      Util.hasAnyAuthority(
        ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.CHATS_VIEW]
      )
    ) {
      menu.push({
        label: "Active Chats",
        icon: "pi pi-fw pi-send",
        to: Util.PATH_NAMES.CHATS_VIEW,
      });
    }

    if (
      Util.hasAnyAuthority(
        ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.MAG_EXTERNAL_REP]
      )
    ) {
      menu.push({
        label: "MAG",
        icon: "pi pi-fw pi-database",
        to: Util.PATH_NAMES.MAG_EXTERNAL_REP,
      });
    }

    if (
      Util.hasAnyAuthority(
        ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.CDK_RO_ASSIST]
      )
    ) {
      menu.push({
        label: "CDK RO Assist",
        icon: "pi pi-circle-fill",
        to: Util.PATH_NAMES.CDK_RO_ASSIST,
      });
    }

    if (
      Util.hasAnyAuthority(
        PERMISSIONS.SETTINGS_MANAGEMENT,
        PERMISSIONS.USERS_MANAGEMENT,
        PERMISSIONS.ROLES_MANAGEMENT,
        PERMISSIONS.VIEW_EVENT_LOGS,
        PERMISSIONS.DEALERSHIP_LAUNCH_REQUEST
      )
    ) {
      const settings: any = {
        label: "Settings",
        icon: "pi pi-fw pi-cog",
        items: [],
      };

      if (
        Util.hasAnyAuthority(
          ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.BRANDS]
        )
      ) {
        settings.items.push({
          label: "Brands",
          icon: "pi pi-fw pi-globe",
          to: Util.PATH_NAMES.BRANDS,
        });
      }

      if (
        Util.hasAnyAuthority(
          ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.USERS]
        )
      ) {
        settings.items.push({
          label: "Users",
          icon: "pi pi-fw pi-users",
          to: Util.PATH_NAMES.USERS,
        });
      }

      if (
        Util.hasAnyAuthority(
          ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.ROLES]
        )
      ) {
        settings.items.push({
          label: "Roles",
          icon: "pi pi-fw pi-key",
          to: Util.PATH_NAMES.ROLES,
        });
      }

      if (
        Util.hasAnyAuthority(
          ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.LAUNCH_REQUEST]
        )
      ) {
        settings.items.push({
          label: "Launch Request",
          icon: "pi pi-fw pi-users",
          to: Util.PATH_NAMES.LAUNCH_REQUEST,
        });
      }

      if (
        Util.hasAnyAuthority(
          ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.EVENT_LOGS]
        )
      ) {
        settings.items.push({
          label: "Event Logs",
          icon: "pi pi-fw pi-file",
          to: Util.PATH_NAMES.EVENT_LOGS,
        });
      }

      if (
        Util.hasAnyAuthority(
          ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.GLOBAL_SETTINGS]
        )
      ) {
        settings.items.push({
          label: "Global Settings",
          icon: "pi pi-fw pi-cog",
          to: Util.PATH_NAMES.GLOBAL_SETTINGS,
        });
      }

      if (
        Util.hasAnyAuthority(
          ...Util.ROUTE_AND_PERMISSIONS[Util.PATH_NAMES.CRM_SOURCES]
        )
      ) {
        settings.items.push({
          label: "Channel Partners",
          icon: "pi pi-fw pi-sitemap",
          to: Util.PATH_NAMES.CRM_SOURCES,
        });
      }

      menu.push(settings);
    }

    return menu;
  };

  public static promiseMyDealerships = (inputValue: string): any =>
    new Promise((resolve) => {
      getMyDealerships({
        pageNumber: 0,
        pageLimit: 50,
        dealershipName: inputValue,
        sorting: [],
      }).then((response) => {
        resolve([
          {
            label: "Dealerships",
            total: response.data.totalElements,
            options: response.data.content.map((item) => {
              return {
                value: item.id,
                label: item.name,
                externalRefId: item.externalRefId,
              };
            }),
          },
        ]);
      });
    });

  public static promiseMyDealershipsForReports = (inputValue: string): any =>
    new Promise((resolve) => {
      getMyDealerships({
        pageNumber: 0,
        pageLimit: 50,
        dealershipName: inputValue,
        sorting: [],
      }).then((response) => {
        resolve([
          {
            label: "Dealerships",
            total: response.data.totalElements,
            options: [
              { externalRefId: "ALL", label: "All", value: 0 },
              ...response.data.content.map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                  externalRefId: item.externalRefId,
                };
              }),
            ],
          },
        ]);
      });
    });

  public static promiseMyOffers = (
    inputValue: string,
    dealershipName?: string
  ) =>
    new Promise((resolve) => {
      if (
        Util.hasAnyAuthority(
          PERMISSIONS.OFFERS_ACCESS,
          PERMISSIONS.SUPPORT_PORTAL_ACCESS
        )
      ) {
        getOfferList({
          pageNumber: 0,
          pageLimit: 20,
          dealershipName: dealershipName,
          name: inputValue,
          sorting: [],
        }).then((response) => {
          resolve([
            {
              label: "Offers",
              total: response.data.totalElements,
              options: response.data.content.map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                  externalRefId: item.externalRefId,
                };
              }),
            },
          ]);
        });
      } else {
        resolve([
          {
            label: "Offers",
            total: 0,
            options: [],
          },
        ]);
      }
    });

  public static stringToColor = (str: string): string => {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
  };

  public static sumArrays = (a: any, b: any) => {
    return _.map(a, function (value: any, index: number) {
      const second = _.get(b, index, 0);
      return value + second;
    });
  };

  public static styleString = (style: Object) => {
    return Object.entries(style)
      .map(([k, v]) => `${k}:${v}`)
      .join(";");
  };

  public static getVerifyUrl = (
    dealership: IDealership | IDealershipDto | undefined
  ): string | undefined => {
    const domain = Util.getDealershipDomain(dealership);

    if (!domain) {
      return undefined;
    }

    if (!dealership) {
      return undefined;
    }

    return `${domain}/public/verify?siteid=${dealership.externalRefId}`;
  };

  public static getDealershipDomain = (
    dealership: IDealership | IDealershipDto | undefined
  ): string | undefined => {
    if (!dealership) {
      return undefined;
    }

    if (dealership.crmSource) {
      if (!Util.isEmpty(dealership.crmSource.subdomain)) {
        const subdomain = dealership.crmSource.subdomain;

        return `https://${subdomain}.${process.env.REACT_APP_DOMAIN}${process.env.PUBLIC_URL}`;
      }
    }

    return `https://pureinfluencer.${process.env.REACT_APP_DOMAIN}${process.env.PUBLIC_URL}`;
  };

  public static getDefaultIfNull(source: any, defaultValue: any) {
    if (source === null || source === undefined) {
      return defaultValue;
    }
    return source;
  }

  public static fromNow = (date: string): string | null => {
    if (!date) {
      return null;
    }

    const dealershipDate = Util.utcToLocal(date, Util.localDateTimeFormat);

    return moment(dealershipDate).fromNow();
  };

  public static parseDelimitedStringToArray(source: string, delimiter: string) {
    delimiter = delimiter || ",";
    const splitValue = _.split(source, delimiter);

    return _.chain(splitValue)
      .map(function (value: string) {
        return _.trim(value);
      })
      .compact()
      .value();
  }

  public static getAssetUrl(uri: string): any {
    if (Util.isEmpty(uri)) {
      return null;
    }
    return `${process.env.REACT_APP_AWS_S3}/${uri}`;
  }

  public static isChatEnabled(chatMode: ChatModes): boolean {
    return [ChatModes.Calls, ChatModes.Text].includes(chatMode);
  }

  public static getVisitorName = (
    defaultValue: string,
    metadata: any
  ): string => {
    const email = Util.getDefaultIfNull(
      Util._.get(metadata, "email", null),
      ""
    ).trim();
    const lastName = Util.getDefaultIfNull(
      Util._.get(metadata, "last_name", null),
      ""
    ).trim();
    const firstName = Util.getDefaultIfNull(
      Util._.get(metadata, "first_name", null),
      ""
    ).trim();

    if (!Util.isEmpty(firstName) || !Util.isEmpty(lastName)) {
      return `${firstName} ${lastName}`;
    }
    if (!Util.isEmpty(email)) {
      return email;
    }

    return defaultValue;
  };

  public static getDealershipContextFromResponseData = (
    dealership: any
  ): IDealershipContext => {
    const dealershipId = Util._.get(dealership, "id", 0);
    const deleted = Util._.get(dealership, "deleted", false);
    const rtsEnabled = Util._.get(dealership, "rtsEnabled", false);
    const myCampaignsEnabled = Util._.get(
      dealership,
      "myCampaignsEnabled",
      false
    );
    const serviceRecordsEnabled = Util._.get(
      dealership,
      "serviceRecordsEnabled",
      false
    );
    const autoAcquireNoEngagementEnabled = Util._.get(
      dealership,
      "autoAcquireNoEngagementEnabled",
      false
    );
    const externalRefId = Util._.get(dealership, "externalRefId", null);
    const name = Util._.get(dealership, "name", null);
    const timezone = Util._.get(dealership, "timezone", null);
    const created = Util._.get(dealership, "created");
    const chatMode = Util._.get(dealership, "chatMode");
    const logoObjectId = Util._.get(dealership, "crmSource.logoObjectId");
    const persistentChatEnabled = Util._.get(
      dealership,
      "persistentChatEnabled",
      false
    );
    const m1Enabled = Util._.get(dealership, "m1Enabled", false);
    const fraudGuardEnabled = Util._.get(
      dealership,
      "fraudGuardEnabled",
      false
    );
    const blockOffersEnabled = Util._.get(
      dealership,
      "blockOffersEnabled",
      false
    );
    const engagementScoreThreshold = Util._.get(
      dealership,
      "engagementScoreThreshold",
      50
    );
    const gaPropertyId = Util._.get(dealership, "gaPropertyId", 0);
    const useGaEnabled = Util._.get(dealership, "useGaEnabled", false);
    const crmSource = Util._.get(dealership, "crmSource", null);
    const leadRecordsEnabled = Util._.get(
      dealership,
      "leadRecordsEnabled",
      false
    );
    const iOfferEnabled = Util._.get(dealership, "iOfferEnabled", false);

    return {
      id: dealershipId,
      name: name,
      deleted: deleted,
      timezone: timezone,
      rtsEnabled: rtsEnabled,
      myCampaignsEnabled: myCampaignsEnabled,
      serviceRecordsEnabled: serviceRecordsEnabled,
      autoAcquireNoEngagementEnabled: autoAcquireNoEngagementEnabled,
      externalRefId: externalRefId,
      created: created,
      chatMode: chatMode,
      logoPath: Util.getAssetUrl(logoObjectId),
      persistentChatEnabled: persistentChatEnabled,
      m1Enabled: m1Enabled,
      fraudGuardEnabled: fraudGuardEnabled,
      blockOffersEnabled: blockOffersEnabled,
      engagementScoreThreshold: engagementScoreThreshold,
      gaPropertyId: gaPropertyId,
      useGaEnabled: useGaEnabled,
      crmSource: crmSource,
      leadRecordsEnabled: leadRecordsEnabled,
      iOfferEnabled: iOfferEnabled,
    };
  };

  public static getEnumKeyByValue<T extends { [index: string]: string }>(
    myEnum: T,
    enumValue: string
  ): keyof T | undefined {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : undefined;
  }

  public static toJSON(proto: any) {
    let jsoned: any = {};
    let toConvert = proto || this;
    Object.getOwnPropertyNames(toConvert).forEach((prop) => {
      const val = toConvert[prop];
      // don't include those
      if (prop === "toJSON" || prop === "constructor") {
        return;
      }
      if (typeof val === "function") {
        jsoned[prop] = val.bind(jsoned);
        return;
      }
      jsoned[prop] = val;
    });

    const inherited = Object.getPrototypeOf(toConvert);
    if (inherited !== null) {
      Object.keys(this.toJSON(inherited)).forEach((key) => {
        if (!!jsoned[key] || key === "constructor" || key === "toJSON") return;
        if (typeof inherited[key] === "function") {
          jsoned[key] = inherited[key].bind(jsoned);
          return;
        }
        jsoned[key] = inherited[key];
      });
    }
    return jsoned;
  }

  public static promiseUsers = (inputValue: string, dealershipId: number) =>
    new Promise((resolve) => {
      getNotificationUsers({
        dealershipId: dealershipId,
        userEmail: inputValue,
      }).then((response) => {
        resolve(
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.email,
              pushSubscribed: item.pushSubscribed,
            };
          })
        );
      });
    });

  public static getDaysOfWeekFromStringArray = (array: string[]) => {
    if (!Array.isArray(array)) {
      return [];
    }

    return Util.daysOfWeek.filter((item) => array.includes(item.value));
  };

  public static getStringArray = (multiStr?: string): string[] => {
    if (Util.isEmpty(multiStr)) {
      return [];
    }

    return split(multiStr, ",");
  };

  public static fmt_num = (val?: number): any => {
    if (val) {
      return val.toFixed(2);
    }
    return val;
  };

  public static isValidInteger(value: string): boolean {
    const num = parseInt(value);
    return !isNaN(num) && value === num.toString();
  }

  public static capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  public static dynamicCapitalize(str: string, splitPattern?: string): string {
    const words = splitPattern ? str.split(splitPattern) : [str];
    return words.map(this.capitalize).join(" ");
  }
}
