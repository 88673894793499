import Axios from "../config/Axios";
import {
  FilteredVehicleCampaignFiltersDto,
  IOfferDto,
  IOfferSearchRequestDto,
  IPageable,
} from "../../index.dts";

export const deleteOffers = (offerIds: number[]) =>
  Axios.all(offerIds.map((id) => Axios.instance.delete(`offers/${id}`)));
export const createOffer = (offer: IOfferDto) =>
  Axios.instance.post("offers", offer);
export const getOffer = (offerId: number) =>
  Axios.instance.get(`offers/${offerId}`);
export const updateOffer = (offerId: number, offer: IOfferDto) =>
  Axios.instance.put(`offers/${offerId}`, offer);
export const getOfferList = (
  searchRequestDto: IOfferSearchRequestDto
): Promise<IPageable<any>> =>
  Axios.instance.post(`offers/list`, searchRequestDto);
export const updateOfferIsActive = (
  offerId: number,
  dto: { active: boolean }
) => Axios.instance.put(`offers/${offerId}`, dto);
export const getMyCampaignsOfferList = (
  searchRequestDto: IOfferSearchRequestDto
): Promise<IPageable<any>> =>
  Axios.instance.post(`offers-mc/list`, searchRequestDto);
export const getMyCampaignsOffer = (
  offerId: number,
  searchRequestDto: FilteredVehicleCampaignFiltersDto
) => Axios.instance.post(`offers-mc/${offerId}`, searchRequestDto);
export const updateMyCampaignsOffer = (offerId: number, offer: IOfferDto) =>
  Axios.instance.put(`offers-mc/${offerId}`, offer);
