import "./radio_filter.css";

import { RadioButton } from "primereact/radiobutton";
import React from "react";

interface IProps {
  name: string;
  onChange: (value: number) => void;
  options: { label: string; value: number; title?: string }[];
  selected?: number;
}

export function RadioFilter(props: IProps) {
  function onChange(checked: boolean, value: number): void {
    if (checked) {
      props.onChange(value);
    }
  }

  return (
    <div className={"radio-filter-container"}>
      {props.options.map((o) => (
        <div className={"radio-filter-item"} key={o.value} title={o?.title}>
          <label
            style={{ cursor: "pointer" }}
            className={"radio-filter-item-label"}
            htmlFor={o.label}
          >
            {o.label}
          </label>
          <RadioButton
            checked={o.value === props.selected}
            id={o.label}
            name={props.name}
            onChange={(e) => onChange(e.checked, e.value)}
            value={o.value}
          />
        </div>
      ))}
    </div>
  );
}
