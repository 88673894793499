export enum ViewTypes {
  uniqueVisitors,
  pageViews,
}

export class ViewTypesUtil {
  public static getName(value?: ViewTypes): string | undefined {
    let name;

    switch (value) {
      case ViewTypes.uniqueVisitors:
        name = "uniqueVisitors";
        break;
      case ViewTypes.pageViews:
        name = "pageViews";
        break;
      default:
        name = undefined;
        break;
    }

    return name;
  }
}
