import "./engagement_leads.css";

import {
  IChatGreeting,
  IDealershipData,
  IDealershipVisitor,
  IEngagementLeadDto,
  IStore,
} from "../../../../index.dts";
import {
  CHAT_HISTORY_TYPE,
  LEAD_ACTIONS,
  PERMISSIONS,
} from "../../../util/Enums";
import React, { useEffect, useRef, useState } from "react";
import { getLeadById, openChat } from "../../../actions/engagementActions";
import {
  getMyTrafficLeads,
  saveEngagementLeadHistory,
} from "../../../actions/engLeadsAction";
import {
  isRestrictedIP,
  showEngagementError,
} from "../../../views/my_traffic/visualizer/Visualizer";
import { shallowEqual, useSelector } from "react-redux";

import { Button } from "primereact/button";
import ChatGreetingsModal from "../../chat_greetings_modal/ChatGreetingsModal";
import ChatModes from "../../../constants/chat_modes";
import { Column } from "primereact/column";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import EngLeadTypeDropdown from "../../lead_type_dropdown/EngLeadTypeDropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import parse from "html-react-parser";
import Util from "../../../util/Util";
import { getDealershipData } from "../../../actions/kaninActions";
import moment from "moment";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import { get } from "lodash";
import { IDS } from "../../../views/constants";
import { useTranslation } from "react-i18next";
import { DateRangePickerWidget } from "../../data_range_picker/DateRangePickerWidget";
import { DateRangePeriods } from "../../data_range_picker/date_range_periods";

interface IProps {
  dealershipId: number;
  dealershipRefId: string;
  dealershipTimezone: string;
  chatMode: ChatModes;
}

let interval: any = 0;
let timeout: any = 0;
const $ = require("jquery");

const EngagementLeads = (props: IProps) => {
  const forceUpdate = useForceUpdate();
  const chatGreetingModalRef = useRef<any>();
  const { t } = useTranslation();

  const [reviewDialogVisible, setReviewDialogVisible] =
    useState<boolean>(false);

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [first, setFirst] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);

  const [period, setPeriod] = useState<{ start?: Date; end?: Date }>({
    start: undefined,
    end: undefined,
  });

  const [leadData, setLeadData] = useState<IEngagementLeadDto[]>([]);
  const [visitorsMap] = useState<Map<string, IDealershipVisitor>>(new Map());
  const chatWidget = useSelector(
    (store: IStore) => store.myTraffic.chatWidget,
    shallowEqual
  );
  const visitorChatMap = useSelector(
    (store: IStore) => store.myTraffic.visitorChatMap,
    shallowEqual
  );

  const [selectedLead, setSelectedLead] = useState();
  const [leadTypeCounts, setLeadTypeCounts] = useState<any>();
  const [leadTypePercentages, setLeadTypePercentages] = useState<any>();

  const [nameFilterValue, setNameFilterValue] = useState<string>("");
  const [dispositionFilterValue, setDispositionFilterValue] =
    useState<string>("");
  const [emailFilterValue, setEmailFilterValue] = useState<string>("");
  const [phoneFilterValue, setPhoneFilterValue] = useState<string>("");
  const [deviceFilterValue, setDeviceFilterValue] = useState<string>();
  const [engLeadTypeFilterValue, setEngLeadTypeFilterValue] = useState<
    string | null
  >(null);
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  const onClear = () => {
    setNameFilterValue("");
    setEmailFilterValue("");
    setPhoneFilterValue("");
    setPeriod({ start: undefined, end: undefined });
  };

  useEffect(() => {
    getData(props.dealershipRefId).catch((error) => {
      Util.showError(error);
    });

    interval = setInterval(() => {
      getData(props.dealershipRefId);
    }, parseInt(process.env.REACT_APP_MY_TRAFFIC_DATA_TIMEOUT_INTERVAL || "3000"));

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [props.dealershipRefId]);

  useEffect(() => {
    timeout = setTimeout(() => {
      getLeadsData(0, limit).finally(() => {
        setPage(0);
      });
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [
    props.dealershipId,
    nameFilterValue,
    emailFilterValue,
    phoneFilterValue,
    deviceFilterValue,
    engLeadTypeFilterValue,
    period,
  ]);

  const getData = (dealershipRefId: string): Promise<IDealershipData> =>
    new Promise((resolve, reject) => {
      getDealershipData(dealershipRefId)
        .then((response) => {
          const visitors: IDealershipVisitor[] = Util._.get(
            response,
            "data.visitors",
            []
          );
          visitorsMap.clear();
          visitors.forEach((item) => {
            visitorsMap.set(item.visitorID, item);
          });
          forceUpdate();
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getLeadsData = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      setLoading(true);

      getMyTrafficLeads({
        page: page,
        pageLimit: limit,
        dealershipId: props.dealershipId,

        name: nameFilterValue,
        email: emailFilterValue,
        phone: phoneFilterValue,

        endDate: period.end
          ? moment(period.end).format(Util.localDateFormat)
          : undefined,
        startDate: period.start
          ? moment(period.start).format(Util.localDateFormat)
          : undefined,
      })
        .then((response) => {
          dataNormalization(response?.data?.leadsPage?.content);
          setLeadTypeCounts(response?.data?.leadTypeCounts);
          setLeadTypePercentages(response?.data?.leadTypePercentages);

          setLeadData(response?.data?.leadsPage?.content);
          setTotalItemsCount(response?.data?.leadsPage?.totalElements);

          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);
          reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const dataNormalization = (data: IEngagementLeadDto[]): void => {
    data.forEach((item) => {
      item["name"] = `${Util.getDefaultIfNull(
        Util._.get(item, "metadata.first_name", null),
        ""
      )} ${Util.getDefaultIfNull(
        Util._.get(item, "metadata.last_name", null),
        ""
      )}`.trim();
    });
  };

  const createdTemplate = (data: any) => {
    return <div>{moment(data.created).format(Util.usDateFormat)}</div>;
  };

  const dealershipCreatedTemplate = (data: any) => {
    return (
      <div>
        {Util.utcToTimezone(
          Util._.get(data, "created"),
          props.dealershipTimezone,
          Util.usDateTimeFormat
        )}{" "}
        ({props.dealershipTimezone})
      </div>
    );
  };

  const emailTemplate = (data: any) => {
    return (
      <CopyToClipboard
        text={Util.getDefaultIfNull(
          Util._.get(data, "userEmail", null),
          Util._.get(data, "userRefId", "")
        )}
        onCopy={() => Util.success("Copied to clipboard!")}
      >
        <div style={{ wordBreak: "break-word" }}>
          {Util.getDefaultIfNull(
            Util._.get(data, "userEmail", null),
            Util._.get(data, "userRefId", "")
          )}
        </div>
      </CopyToClipboard>
    );
  };

  const onPage = (event: any) => {
    getLeadsData(event.page, event.rows).finally(() => {
      setLimit(event.rows);
      setFirst(event.first);
      setPage(event.page);
    });
  };

  const isChatEnabled = (data: any): boolean => {
    if (
      !Util.hasAnyAuthority(
        PERMISSIONS.RTS_LEADS_MANAGEMENT,
        PERMISSIONS.RTS_STATISTICS
      )
    ) {
      return false;
    }

    const visitor = visitorsMap.get(Util._.get(data, "visitorRefId"));

    return !(!visitor || visitor.isBlocked);
  };

  const actionsTemplate = (data: any) => {
    return (
      <div className={"action-buttons"}>
        <Button
          icon={"pi pi-search"}
          label={"Review"}
          onClick={() => onReview(data)}
          disabled={
            !Util.hasAnyAuthority(
              PERMISSIONS.RTS_LEADS_MANAGEMENT,
              PERMISSIONS.RTS_STATISTICS
            )
          }
        />
        {Util.hasAnyAuthority(
          PERMISSIONS.RTS_LEADS_MANAGEMENT,
          PERMISSIONS.RTS_STATISTICS
        ) && Util.isChatEnabled(props.chatMode) ? (
          <Button
            icon={"pi pi-comments"}
            label={"Chat"}
            className={"p-button-success"}
            onClick={() => onOpenChat(data)}
            disabled={!isChatEnabled(data)}
          />
        ) : null}
      </div>
    );
  };

  const onReview = (data: any): void => {
    const leadId = Util._.get(data, "id", null);

    Util.globalSpinner().show();
    getLeadById(leadId)
      .then((response) => {
        setSelectedLead(response.data);
        setReviewDialogVisible(true);
      })
      .catch((error) => Util.showError(error))
      .finally(() => {
        getLeadsData(page, limit);
        Util.globalSpinner().hide();
      });
  };

  const onOpenChat = (data: any): void => {
    if (
      !Util.hasAnyAuthority(
        PERMISSIONS.RTS_LEADS_MANAGEMENT,
        PERMISSIONS.RTS_STATISTICS
      )
    ) {
      Util.warning("Access Denied");
      return;
    }

    const visitor = visitorsMap.get(Util._.get(data, "visitorRefId"));

    if (visitor && !isRestrictedIP(visitor)) {
      chatGreetingModalRef.current.show(visitor);
    }
  };

  const onSendChatGreeting = (
    visitor?: IDealershipVisitor,
    chatGreeting?: IChatGreeting
  ): void => {
    if (!visitor) {
      return;
    }

    Util.globalSpinner().show();
    openChat({
      visitorExtRefId: visitor.visitorID,
      chatGreetingId: Util._.get(chatGreeting, "id", null),
      dealershipId: props.dealershipId,
      visitorSession: visitor.sessionID,
      startedInUrl: visitor.currentUrl,
      make: visitor.make,
      model: visitor.model,
      year: visitor.year,
      pageType: visitor.pageClass,
      engagementScore: Math.round(visitor.eScore),
      sessionTime: visitor.sessionTime,
    })
      .then((response) => {
        const visitorId = get(response, "data.visitorId", null);
        const channelId = get(response, "data.channelId", null);
        const chatHistoryId = get(response, "data.id", null);

        if (!visitorId || !channelId || !chatHistoryId) {
          return;
        }

        chatWidget.start({
          channelId: channelId,
          label: dealershipContext.name,
          isCallsEnabled: dealershipContext.chatMode === ChatModes.Calls,
        });

        visitorChatMap.set(visitorId.toString(), {
          chatHistoryId: chatHistoryId,
          type: CHAT_HISTORY_TYPE.my_traffic,
        });

        saveEngagementLeadHistory({
          action: LEAD_ACTIONS.CHAT,
          dealershipRefId: props.dealershipRefId,
          visitorRefId: Util._.get(visitor, "visitorID"),
        }).finally(() => {
          getLeadsData(page, limit);
        });
      })
      .catch((error) => {
        showEngagementError(error);
      })
      .finally(() => Util.globalSpinner().hide());
  };

  const onHideReviewDialog = (): void => {
    setReviewDialogVisible(false);
  };

  const getAcceptedOffer = (selectedLead: any): string => {
    const creativeHtml = Util._.get(selectedLead, "creativeHtml", null);
    const creativeHref = Util._.get(selectedLead, "creativeHref", null);

    if (creativeHtml) {
      return getCreativeHtml(creativeHtml);
    } else if (creativeHref) {
      return getCreativeHref(creativeHref);
    }

    return "";
  };

  const getCreativeHref = (creativeHref: string): string => {
    return `<img width="100%" height="100%" src="${creativeHref}" alt="..."/>`;
  };

  const getCreativeHtml = (creativeHtml: string): string => {
    const div = document.createElement("div");
    div.innerHTML = creativeHtml;

    const selected = div.getElementsByClassName("pi_pop_up_content");

    const item = Util._.get(selected, "[0]", null);

    if (!item) {
      return "";
    }

    const image = $(item).children("img")[0];

    if (!image) {
      return Util._.get(item, "innerHTML", "");
    }

    $(image).css("width", "100%");
    $(image).css("height", "100%");

    return Util._.get(image, "outerHTML", "");
  };

  const nameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={nameFilterValue}
      onChange={(e: any) => {
        setNameFilterValue(
          Util.getIfNullOrEmpty(Util._.get(e, "target.value", null), "")
        );
      }}
    />
  );

  const dispositionFilter = (
    <InputText
      style={{ width: "100%" }}
      value={dispositionFilterValue}
      onChange={(e: any) => {
        setDispositionFilterValue(
          Util.getIfNullOrEmpty(Util._.get(e, "target.value", null), "")
        );
      }}
    />
  );

  const emailFilter = (
    <InputText
      style={{ width: "100%" }}
      value={emailFilterValue}
      onChange={(e: any) => {
        setEmailFilterValue(
          Util.getIfNullOrEmpty(Util._.get(e, "target.value", null), "")
        );
      }}
    />
  );

  const phoneFilter = (
    <InputText
      style={{ width: "100%" }}
      value={phoneFilterValue}
      onChange={(e: any) => {
        setPhoneFilterValue(
          Util.getIfNullOrEmpty(Util._.get(e, "target.value", null), "")
        );
      }}
    />
  );

  const deviceFilter = (
    <Dropdown
      style={{ width: "100%" }}
      options={[
        { value: null, label: "All" },
        { value: "desktop", label: "desktop" },
        {
          value: "mobile",
          label: "mobile",
        },
      ]}
      value={deviceFilterValue}
      onChange={(e: any) => {
        setDeviceFilterValue(e.value);
      }}
    />
  );

  const leadTypeFilter = (
    <EngLeadTypeDropdown
      value={engLeadTypeFilterValue}
      onChange={(e: any) => setEngLeadTypeFilterValue(e.value)}
    />
  );

  const dateFilter = (
    <DateRangePickerWidget
      start={period.start} // Pass the start date
      end={period.end} // Pass the end date
      ranges={DateRangePeriods.options} // Predefined ranges (e.g., today, last 30 days)
      onChange={(start?: Date, end?: Date): void => {
        // Update the period state when the date range changes
        setPeriod({
          start: start ? moment(start).toDate() : undefined,
          end: end ? moment(end).toDate() : undefined,
        });
      }}
    />
  );

  return (
    <div>
      <div className="card card-w-title datatable-demo">
        <div style={{ display: "flex", columnGap: "20px", fontWeight: "bold" }}>
          {leadTypeCounts &&
            leadTypePercentages &&
            Object.keys(leadTypeCounts)?.map(
              (leadType: string, index: number) => {
                return (
                  <p key={index}>
                    {leadType}: {leadTypeCounts[leadType]}
                    {" ("}
                    {Number(leadTypePercentages[leadType]).toFixed(1)}
                    {"%"}
                    {")"}
                  </p>
                );
              }
            )}
        </div>
      </div>
      <div
        className={
          limit > 10
            ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting"
            : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
        }
        style={{
          borderTop: "1px solid #d8d8d8",
        }}
      >
        <DataTable
          resizableColumns={true}
          filterDisplay="row"
          scrollable
          autoLayout={true}
          columnResizeMode={"expand"}
          rowsPerPageOptions={Util.rowsPerPageOptions}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
          paginator={true}
          totalRecords={totalItemsCount}
          rows={limit}
          lazy={true}
          first={first}
          loading={loading}
          sortMode={"single"}
          value={leadData}
          onPage={onPage}
          header={
            <div
              style={{ minHeight: "30px" }}
              className={"p-grid-header-components"}
            >
              <Button
                id={IDS.button.clear}
                style={{ position: "absolute", left: "10px" }}
                icon={"pi pi-filter"}
                label={t("clear")}
                onClick={onClear}
              />
            </div>
          }
        >
          <Column
            filter={true}
            field={"created"}
            header={"Date"}
            body={createdTemplate}
            style={{ overflow: "visible" }}
            filterElement={dateFilter}
          />
          <Column
            filter={true}
            field={"name"}
            header={"Name"}
            filterElement={nameFilter}
          />
          <Column
            filter={true}
            field={"metadata.email"}
            header={"Email"}
            filterElement={emailFilter}
          />
          <Column
            filter={true}
            field={"metadata.phone"}
            header={"Phone"}
            filterElement={phoneFilter}
          />
          <Column
            field={"device"}
            header={"Device"}
            style={{ overflow: "visible" }}
            filterElement={deviceFilter}
          />
          <Column
            field={"leadType"}
            header={"Lead Type"}
            style={{ overflow: "visible", width: 130 }}
            filterElement={leadTypeFilter}
          />
          <Column
            field={"disposition"}
            header={"Disposition"}
            style={{ overflow: "visible", width: 130 }}
            filterElement={dispositionFilter}
          />
          <Column
            header={"Actions"}
            body={actionsTemplate}
            style={{ width: 175 }}
          />
        </DataTable>
      </div>
      <Dialog
        visible={reviewDialogVisible}
        onHide={onHideReviewDialog}
        style={{ width: 900 }}
        header={`Engagement Lead Details`}
      >
        <Panel header={"Visitor Information"}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>First Name</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>{Util._.get(selectedLead, "metadata.first_name")}</span>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Last Name</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>{Util._.get(selectedLead, "metadata.last_name")}</span>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Email</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>{Util._.get(selectedLead, "metadata.email")}</span>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Phone</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>{Util._.get(selectedLead, "metadata.phone")}</span>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Last Seen</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>
                    {Util.utcToTimezone(
                      Util.getDefaultIfNull(
                        Util._.get(selectedLead, "lastSeen", null),
                        Util._.get(selectedLead, "updated")
                      ),
                      props.dealershipTimezone,
                      Util.usDateTimeFormat
                    )}{" "}
                    ({props.dealershipTimezone})
                  </span>
                </div>
              </div>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>City</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>{Util._.get(selectedLead, "city")}</span>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Zip</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>{Util._.get(selectedLead, "zip")}</span>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Device</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>{Util._.get(selectedLead, "device")}</span>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Status</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>{Util._.get(selectedLead, "status")}</span>
                </div>
              </div>
            </div>
          </div>
        </Panel>

        <Panel style={{ marginTop: 10 }} header={"Lead Information"}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Date</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>
                    {Util.utcToTimezone(
                      Util._.get(selectedLead, "updated"),
                      props.dealershipTimezone,
                      Util.usDateTimeFormat
                    )}{" "}
                    ({props.dealershipTimezone})
                  </span>
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Campaign</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>{Util._.get(selectedLead, "campaignType")}</span>
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Lead Type</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>{Util._.get(selectedLead, "leadType")}</span>
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Engagement Score</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>{Util._.get(selectedLead, "engagementScore")}</span>
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>First Visit Date</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>
                    {Util.utcToTimezone(
                      Util._.get(selectedLead, "firstVisit"),
                      props.dealershipTimezone,
                      Util.usDateTimeFormat
                    )}{" "}
                    ({props.dealershipTimezone})
                  </span>
                </div>
              </div>
            </div>

            <div className={"p-col-12 p-lg-6"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Page Views</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>{Util._.get(selectedLead, "pageViews")}</span>
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Stats</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>{Util._.get(selectedLead, "stats")}</span>
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Total Duration Time</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>{Util._.get(selectedLead, "totalDurationTime")}</span>
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Total Engaged Time</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-8"}>
                  <span>{Util._.get(selectedLead, "totalEngagedTime")}</span>
                </div>
              </div>
            </div>
          </div>
        </Panel>

        <div id={"eng-lead-history"} className={"p-grid"}>
          <div className={"p-col-12 p-lg-6"}>
            <Panel style={{ marginTop: 10 }} header={"Engagement History"}>
              <DataTable
                scrollable={true}
                scrollHeight={"217px"}
                filterDisplay="row"
                value={Util._.get(
                  selectedLead,
                  "engagementLeadHistory",
                  []
                ).sort((a: any, b: any) => moment(b.created).diff(a.created))}
              >
                <Column
                  filter={true}
                  sortable={true}
                  field={"userEmail"}
                  header={"User"}
                  body={emailTemplate}
                />
                <Column
                  filter={true}
                  sortable={true}
                  field={"action"}
                  header={"Action"}
                />
                <Column
                  filter={true}
                  sortable={true}
                  field={"created"}
                  header={"Date"}
                  body={dealershipCreatedTemplate}
                />
              </DataTable>
            </Panel>
          </div>

          <div className={"p-col-12 p-lg-6"}>
            <Panel style={{ marginTop: 10 }} header={"Accepted Offer"}>
              <div style={{ textAlign: "center", height: 300 }}>
                {parse(getAcceptedOffer(selectedLead))}
              </div>
            </Panel>
          </div>
        </div>
      </Dialog>
      <ChatGreetingsModal
        ref={chatGreetingModalRef}
        onSelect={onSendChatGreeting}
      />
    </div>
  );
};

export default EngagementLeads;
