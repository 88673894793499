import { ProductTypes } from "../../util/enum/product_types";
import { RadioFilter } from "../radio_filter/RadioFilter";
import React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  onChange: (update: number) => void;
  type?: ProductTypes;
}

export function ProductTypeRadio(props: IProps) {
  const { t } = useTranslation();

  function onChange(value: number): void {
    props.onChange(value);
  }

  return (
    <RadioFilter
      name={"product-type-radio"}
      onChange={onChange}
      options={[
        {
          label: t("all"),
          value: ProductTypes.all,
        },
        {
          label: t("new"),
          value: ProductTypes.new,
        },
        {
          label: t("used"),
          value: ProductTypes.used,
        },
        {
          label: t("certified"),
          value: ProductTypes.certified
        },
        {
          label: t("service"),
          value: ProductTypes.service
        }
      ]}
      selected={props.type}
    />
  );
}