import React, { useEffect, useState } from "react";
import "./wishList.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  getWishListConfiguration,
  createWishListConfiguration,
  updateWishListConfiguration,
} from "../../actions/wishListActions";
import Util from "../../util/Util";
import { IWishList, IStore } from "../../../index.dts";
import { IDS } from "../constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import TagsInputUtil from "../../util/tags_input/TagsInputUtil";
import {
  INPUT_TAGS_TYPES,
  TAG_INPUT_PLACEHOLDERS,
} from "../../util/tags_input";
import {
  DealerNotificationType,
  AcquisitionEmailCadence,
} from "../../util/Enums";
import { Panel } from "primereact/panel";
import Select from "react-select";
import { Button } from "primereact/button";
import "react-tagsinput/react-tagsinput.css";
import TagsInput from "react-tagsinput";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import EmailTypes from "../../util/enum/email_types";
import { EmailRadioFilter } from "../../components/radio_filter/EmailRadioFilter";
import { isNull } from "lodash";

export function AutoAcquire(props: any) {
  const { t }: any = useTranslation();
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [wishList, setWishList] = useState<IWishList>({
    id: 0,
    dealerNotificationType: DealerNotificationType.NOTIFICATION,
    dealerNotificationTo: "",
    acquisitionEmailCadence: AcquisitionEmailCadence.VAL_90,
    acquisitionYears: "",
    acquisitionMakes: "",
    reminderEmailBodyContent: "",
    reminderEmailOffers: "",
    acquisitionEmailTemplateUrl: "",
    daysToFirstEmailForCustomers: 10,
    emailType: true,
    dynamicEmail: "",
    smtpConfiguration: {
      host: "",
      emailAddress: "",
      username: "",
      password: "",
      port: 0,
    },
    ignoreVehiclesUnder: 0,
    replyToEmails: "",
    dealershipEmailTemplateUrl: "",
    usedCarManagerName: "",
    deanonymizedIncentive: 0,
    deanonymizedEmailDummyVehicle: "",
  });

  const [emailSMTP, setEmailSMT] = useState<any>(
    wishList?.emailType ? EmailTypes.email : EmailTypes.emailSMTP
  );

  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  const [warnings] = useState<{ [key: string]: string }>({
    dealerNotificationType: "",
    dealerNotificationTo: "",
    acquisitionEmailCadence: "",
    smtpConfigurationHost: "",
    smtpConfigurationEmailAddress: "",
    smtpConfigurationUsername: "",
    smtpConfigurationPassword: "",
    smtpConfigurationPort: "",
    dynamicEmail: "",
    deanonymizedIncentive: "",
  });

  useEffect(() => {
    return () => {
      Util.removeAlert();
    };
  }, []);

  useEffect(() => {
    if (props?.dealer?.id !== 0) {
      getConfig(props?.dealer?.id);
    }
  }, [dealershipContext]);

  const getConfig = (dealershipId: number) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      getWishListConfiguration(dealershipId)
        .then((response) => {
          if (response.data.smtpConfiguration !== null) {
            response.data.smtpConfiguration.password = "NULLFROMAPI";
          } else {
            response.data.smtpConfiguration = {
              host: "",
              emailAddress: "",
              username: "",
              password: "",
              port: 0,
            };
          }
          setWishList({
            ...response.data,
            daysToFirstEmailForCustomers: isNull(
              response.data?.daysToFirstEmailForCustomers
            )
              ? Number(10)
              : Number(response.data?.daysToFirstEmailForCustomers),
          });
          setEmailSMT(
            response?.data?.emailType ? EmailTypes.email : EmailTypes.emailSMTP
          );
        })
        .catch((error) => {
          setWishList({
            id: 0,
            dealerNotificationType: DealerNotificationType.NOTIFICATION,
            dealerNotificationTo: "",
            acquisitionEmailCadence: AcquisitionEmailCadence.VAL_90,
            acquisitionYears: "",
            acquisitionMakes: "",
            reminderEmailBodyContent: "",
            reminderEmailOffers: "",
            daysToFirstEmailForCustomers: 10,
            acquisitionEmailTemplateUrl: "",
            emailType: true,
            dynamicEmail: "",
            smtpConfiguration: {
              host: "",
              emailAddress: "",
              username: "",
              password: "",
              port: 0,
            },
            ignoreVehiclesUnder: 0,
            replyToEmails: "",
            dealershipEmailTemplateUrl: "",
            usedCarManagerName: "",
            deanonymizedIncentive: 0,
            deanonymizedEmailDummyVehicle: "",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const setValue = (field: string, value: any) => {
    if (field.includes("smtp")) {
      switch (field) {
        case "smtpConfigurationEmailAddress":
          if (!Util.isEmpty(value) && !Util.validateEmail(value)) {
            setWarningValue(field, t("validation.email"));
          } else {
            warnings[field] = "";
          }
          wishList.smtpConfiguration.emailAddress = value || "";
          forceUpdate();
          break;
        case "smtpConfigurationPort":
          wishList.smtpConfiguration.port = value || 0;
          forceUpdate();
          break;
        case "smtpConfigurationHost":
          wishList.smtpConfiguration.host = value || "";
          forceUpdate();
          break;
        case "smtpConfigurationUsername":
          wishList.smtpConfiguration.username = value || "";
          forceUpdate();
          break;
        case "smtpConfigurationPassword":
          wishList.smtpConfiguration.password = value || "";
          forceUpdate();
          break;
        default:
          break;
      }
    } else if (field == "daysToFirstEmailForCustomers") {
      wishList.daysToFirstEmailForCustomers = value || 0;
    } else if (field == "deanonymizedIncentive") {
      if (value > 999999) {
        setWarningValue(field, "Value is larger than 999999");
      } else {
        warnings[field] = "";
      }
      wishList.deanonymizedIncentive = value || null;
      forceUpdate();
    } else {
      wishList[field] = value || "";
      forceUpdate();
    }
  };

  const setWarningValue = (
    field: string,
    value?: string,
    disableUpdate?: boolean
  ) => {
    if (value) {
      warnings[field] = value;
    }

    if (!disableUpdate) {
      forceUpdate();
    }
  };

  const acquisitionEmailCadenceValues: {
    label: AcquisitionEmailCadence;
    value: AcquisitionEmailCadence;
  }[] = [
    {
      label: AcquisitionEmailCadence.VAL_30,
      value: AcquisitionEmailCadence.VAL_30,
    },
    {
      label: AcquisitionEmailCadence.VAL_60,
      value: AcquisitionEmailCadence.VAL_60,
    },
    {
      label: AcquisitionEmailCadence.VAL_90,
      value: AcquisitionEmailCadence.VAL_90,
    },
    {
      label: AcquisitionEmailCadence.VAL_120,
      value: AcquisitionEmailCadence.VAL_120,
    },
    {
      label: AcquisitionEmailCadence.VAL_180,
      value: AcquisitionEmailCadence.VAL_180,
    },
  ];

  const dealerNotificationTypeValues: {
    label: DealerNotificationType;
    value: DealerNotificationType;
  }[] = [
    {
      label: DealerNotificationType.NOTIFICATION,
      value: DealerNotificationType.NOTIFICATION,
    },
    {
      label: DealerNotificationType.ACQUISITION,
      value: DealerNotificationType.ACQUISITION,
    },
    {
      label: DealerNotificationType.BOTH,
      value: DealerNotificationType.BOTH,
    },
  ];

  const onSave = () => {
    const validateArray = Object.entries(warnings).map((entire) => {
      return {
        field: entire[0],
        warningString: entire[1],
      };
    });

    for (const item of validateArray) {
      if (item.warningString?.trim() !== "") {
        forceUpdate();
        Util.warning(item.warningString?.trim());
        Util.globalSpinner().hide();
        return;
      }
    }

    if (wishList.id !== 0) {
      onEdit(wishList);
    } else {
      onCreate(wishList);
    }
  };

  const onCreate = (wishList: IWishList) => {
    Util.globalSpinner().show();
    const dto: any = {
      ...wishList,
      deanonymizedEmailDummyVehicle:
        wishList?.deanonymizedEmailDummyVehicle == ""
          ? null
          : wishList?.deanonymizedEmailDummyVehicle,
    };
    createWishListConfiguration(props?.dealer?.id, dto)
      .then((response) => {
        history.push(Util.PATH_NAMES.DEALERSHIPS);
        Util.globalSpinner().hide();
        Util.success(t("wishList.validations.createdSuccessfully"));
      })
      .catch((error) => {
        Util.globalSpinner().hide();
        Util.error(error.response.data.message);
      });
  };

  const onEdit = (wishList: IWishList) => {
    Util.globalSpinner().show();
    if (wishList.smtpConfiguration.password === "NULLFROMAPI") {
      wishList.smtpConfiguration.password = "";
    }

    const dto: any = {
      ...wishList,
      deanonymizedEmailDummyVehicle:
        wishList?.deanonymizedEmailDummyVehicle == ""
          ? null
          : wishList?.deanonymizedEmailDummyVehicle,
    };
    updateWishListConfiguration(props?.dealer?.id, dto)
      .then((response) => {
        history.push(Util.PATH_NAMES.DEALERSHIPS);
        Util.globalSpinner().hide();
        Util.success(t("wishList.validations.updatedSuccessfully"));
      })
      .catch((error) => {
        Util.globalSpinner().hide();
        Util.error(error.response.data.message);
      });
  };

  const getAcquisitionEmailCadenceValues = () => {
    if (wishList.acquisitionEmailCadence) {
      return {
        label: wishList.acquisitionEmailCadence,
        value: wishList.acquisitionEmailCadence,
      };
    }
    return;
  };

  const getDealerNotificationTypeValues = () => {
    if (wishList.dealerNotificationType) {
      return {
        label: wishList.dealerNotificationType,
        value: wishList.dealerNotificationType,
      };
    }
    return;
  };

  const launchIOffer = () => {
    history.push(Util.PATH_NAMES.IOFFER_PORTAL);
  };

  return (
    <div id={"assets-view"}>
      <div className={"p-grid"}>
        <div className="p-col-12">
          <Panel
            id={IDS.wishList.panel.wishList}
            key={"Wish List Setup"}
            style={{ marginTop: 10 }}
            header={
              t("wishList.labels.mainHeader") + " | " + dealershipContext.name
            }
          >
            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("wishList.labels.dealerNotificationType")}
                  </label>
                  <span className={"red"}>*</span>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <Select
                  id={IDS.wishList.inputs.dealerNotificationType}
                  //filter={true}
                  options={dealerNotificationTypeValues}
                  value={getDealerNotificationTypeValues()}
                  //style={{ width: "100%", boxShadow: "none" }}
                  onChange={(item: any) => {
                    setValue("dealerNotificationType", item.value);
                    forceUpdate();
                  }}
                />
                <label className={"warning-label"}>
                  {warnings.dealerNotificationType}
                </label>
              </div>

              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("wishList.labels.dealerNotificationsTo")}
                  </label>
                  <span className={"red"}>*</span>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <TagsInput
                  onlyUnique={true}
                  validationRegex={Util.emailRegex}
                  onValidationReject={(tags) =>
                    TagsInputUtil.onValidationReject(
                      tags,
                      INPUT_TAGS_TYPES.EMAIL
                    )
                  }
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(wishList.dealerNotificationTo)}
                  onChange={(value: string[]) =>
                    setValue("dealerNotificationTo", TagsInputUtil.join(value))
                  }
                />
                <label className={"warning-label"}>
                  {warnings.dealerNotificationTo}
                </label>
              </div>
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("wishList.labels.daysToFirstEmailForCustomers")}
                  </label>
                  <span className={"red"}>*</span>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <InputNumber
                  id={IDS.wishList.inputs.daysToFirstEmailForCustomers}
                  format={false}
                  min={-2147483648}
                  max={2147483648}
                  style={{ width: "100%" }}
                  value={Number(wishList.daysToFirstEmailForCustomers)}
                  placeholder={t(
                    "wishList.labels.daysToFirstEmailForCustomers"
                  )}
                  onChange={(e: any) =>
                    setValue("daysToFirstEmailForCustomers", e.value)
                  }
                />
                <label className={"warning-label"}>
                  {warnings.daysToFirstEmailForCustomers}
                </label>
              </div>

              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("wishList.labels.replyToEmails")}
                  </label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <TagsInput
                  onlyUnique={true}
                  validationRegex={Util.emailRegex}
                  onValidationReject={(tags) =>
                    TagsInputUtil.onValidationReject(
                      tags,
                      INPUT_TAGS_TYPES.EMAIL
                    )
                  }
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(wishList.replyToEmails)}
                  onChange={(value: string[]) =>
                    setValue("replyToEmails", TagsInputUtil.join(value))
                  }
                />
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.declinedAcquisitionCadence")}
                  </label>
                  <span className={"red"}>*</span>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <Select
                  id={IDS.wishList.inputs.acquisitionEmailCadence}
                  //filter={true}
                  options={acquisitionEmailCadenceValues}
                  value={getAcquisitionEmailCadenceValues()}
                  //style={{ width: "100%", boxShadow: "none" }}
                  onChange={(item: any) => {
                    setValue("acquisitionEmailCadence", item.value);
                    forceUpdate();
                  }}
                />
                <label className={"warning-label"}>
                  {warnings.acquisitionEmailCadence}
                </label>
              </div>

              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.ignoreVehiclesUnder")}
                  </label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"} style={{ display: "flex" }}>
                <div style={{ marginLeft: -18, padding: 5 }}>$</div>
                <InputNumber
                  format={true}
                  min={0}
                  max={2147483648}
                  style={{ width: "100%", height: 35 }}
                  value={wishList.ignoreVehiclesUnder}
                  placeholder={t("vehicleExchange.ignoreVehiclesUnder")}
                  onChange={(e: any) =>
                    setValue("ignoreVehiclesUnder", e.value)
                  }
                />
              </div>
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("wishList.labels.usedCarManagerName")}
                  </label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <InputText
                  style={{ width: "100%" }}
                  value={wishList.usedCarManagerName || ""}
                  placeholder={t("wishList.labels.usedCarManagerName")}
                  name="name"
                  onChange={(e: any) =>
                    setValue("usedCarManagerName", e.target.value)
                  }
                />
              </div>
              <div
                className={"p-col-6 p-lg-6 p-label-col"}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  className={"p-col-12 p-lg-12 p-label-col"}
                  style={{ display: "flex" }}
                >
                  <div className={"p-col-6 p-lg-6 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        {t("wishList.labels.deanonymizedIncentive")}
                      </label>
                    </div>
                  </div>
                  <div className={"p-col-6 p-lg-6"}>
                    <InputNumber
                      id={IDS.wishList.inputs.deanonymizedIncentive}
                      format={false}
                      min={0}
                      max={1000000}
                      style={{ width: "100%" }}
                      value={wishList.deanonymizedIncentive || null}
                      placeholder={"Deanonymized Incentive"}
                      onChange={(e: any) =>
                        setValue("deanonymizedIncentive", e.value)
                      }
                    />
                    <label className={"warning-label"}>
                      {warnings.deanonymizedIncentive}
                    </label>
                  </div>
                </div>
                <div
                  className={"p-col-12 p-lg-12 p-label-col"}
                  style={{ display: "flex" }}
                >
                  <div className={"p-col-6 p-lg-6 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        {t("wishList.labels.deanonymizedEmailDummyVehicle")}
                      </label>
                    </div>
                  </div>
                  <div className={"p-col-6 p-lg-6"}>
                    <InputText
                      style={{ width: "100%" }}
                      value={wishList.deanonymizedEmailDummyVehicle || ""}
                      placeholder={t(
                        "wishList.labels.deanonymizedEmailDummyVehicle"
                      )}
                      name="name"
                      onChange={(e: any) => {
                        const inputValue = e.target.value;

                        // Allow only up to 100 characters
                        if (inputValue.length <= 100) {
                          setValue("deanonymizedEmailDummyVehicle", inputValue);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-6 p-lg-6 p-label-col"}>
                <h3>Acquisitions</h3>
              </div>
              <EmailRadioFilter
                name={"product-type-radio"}
                onChange={(value: number) => {
                  setEmailSMT(value);
                  setWishList((prev) => {
                    return {
                      ...prev,
                      emailType: value === EmailTypes.email,
                    };
                  });
                }}
                options={[
                  {
                    label: "Email",
                    value: EmailTypes.email,
                  },
                  {
                    label: "Email SMTP Configuration",
                    value: EmailTypes.emailSMTP,
                  },
                ]}
                selected={emailSMTP}
              />
              <div className={"p-col-6 p-lg-6 p-label-col"}></div>
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("wishList.labels.acquisitionYears")}
                  </label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <TagsInput
                  onlyUnique={true}
                  validationRegex={Util.yearRegex}
                  onValidationReject={(tags) =>
                    TagsInputUtil.onValidationReject(
                      tags,
                      INPUT_TAGS_TYPES.YEAR
                    )
                  }
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(wishList.acquisitionYears || "")}
                  onChange={(value: string[]) =>
                    setValue("acquisitionYears", TagsInputUtil.join(value))
                  }
                />
                <label className={"warning-label"}>
                  {warnings.acquisitionYears}
                </label>
              </div>

              {emailSMTP === EmailTypes.email && (
                <div className="p-col-6 p-lg-6">
                  <div className="p-inputgroup">
                    <InputText
                      placeholder="Email"
                      value={wishList.dynamicEmail || ""}
                      onChange={(e: any) => {
                        setValue("dynamicEmail", e.target.value);
                      }}
                    />
                    <span className="p-inputgroup-addon">
                      @bestpriceformycar.ai
                    </span>
                  </div>
                  <label className={"warning-label"}>
                    {warnings.dynamicEmail}
                  </label>
                </div>
              )}
              {emailSMTP === EmailTypes.emailSMTP && (
                <>
                  <div className={"p-col-3 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        {t("wishList.labels.smtpConfigurationHost")}
                      </label>
                      <span className={"red"}>*</span>
                    </div>
                  </div>
                  <div className={"p-col-3 p-lg-3"}>
                    <InputText
                      id={IDS.wishList.inputs.smtpConfigurationHost}
                      style={{ width: "100%" }}
                      value={wishList.smtpConfiguration?.host || ""}
                      name={"name"}
                      placeholder={"Host"}
                      onChange={(e: any) =>
                        setValue("smtpConfigurationHost", e.target.value)
                      }
                    />
                    <label className={"warning-label"}>
                      {warnings.smtpConfigurationHost}
                    </label>
                  </div>
                </>
              )}
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("wishList.labels.acquisitionMakes")}
                  </label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <TagsInput
                  onlyUnique={true}
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(wishList.acquisitionMakes || "")}
                  onChange={(value: string[]) =>
                    setValue(
                      "acquisitionMakes",
                      TagsInputUtil.joinWithUpperCase(value)
                    )
                  }
                />
                <label className={"warning-label"}>
                  {warnings.acquisitionMakes}
                </label>
              </div>

              {emailSMTP === EmailTypes.emailSMTP && (
                <>
                  <div className={"p-col-3 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        {t("wishList.labels.smtpConfigurationPort")}
                      </label>
                      <span className={"red"}>*</span>
                    </div>
                  </div>
                  <div className={"p-col-3 p-lg-3"}>
                    <InputNumber
                      id={IDS.wishList.inputs.smtpConfigurationPort}
                      format={false}
                      min={-2147483648}
                      max={2147483648}
                      style={{ width: "100%" }}
                      value={wishList.smtpConfiguration?.port || 0}
                      placeholder={"Port"}
                      onChange={(e: any) =>
                        setValue("smtpConfigurationPort", e.value)
                      }
                    />
                    <label className={"warning-label"}>
                      {warnings.smtpConfigurationPort}
                    </label>
                  </div>
                </>
              )}
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>&nbsp;</label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>&nbsp;</div>

              {emailSMTP === EmailTypes.emailSMTP && (
                <>
                  <div className={"p-col-3 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        {t("wishList.labels.smtpConfigurationEmailAddress")}
                      </label>
                      <span className={"red"}>*</span>
                    </div>
                  </div>
                  <div className={"p-col-3 p-lg-3"}>
                    <InputText
                      id={IDS.wishList.inputs.smtpConfigurationEmailAddress}
                      style={{ width: "100%" }}
                      value={wishList.smtpConfiguration?.emailAddress || ""}
                      name={"name"}
                      placeholder={"Email Address"}
                      onChange={(e: any) =>
                        setValue(
                          "smtpConfigurationEmailAddress",
                          e.target.value
                        )
                      }
                    />
                    <label className={"warning-label"}>
                      {warnings.smtpConfigurationEmailAddress}
                    </label>
                  </div>
                </>
              )}
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>&nbsp;</label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>&nbsp;</div>

              {emailSMTP === EmailTypes.emailSMTP && (
                <>
                  <div className={"p-col-3 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        {t("wishList.labels.smtpConfigurationUsername")}
                      </label>
                      <span className={"red"}>*</span>
                    </div>
                  </div>
                  <div className={"p-col-3 p-lg-3"}>
                    <InputText
                      id={IDS.wishList.inputs.smtpConfigurationUsername}
                      style={{ width: "100%" }}
                      value={wishList.smtpConfiguration?.username || ""}
                      name={"name"}
                      placeholder={"Username"}
                      onChange={(e: any) =>
                        setValue("smtpConfigurationUsername", e.target.value)
                      }
                    />
                    <label className={"warning-label"}>
                      {warnings.smtpConfigurationUsername}
                    </label>
                  </div>
                </>
              )}
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>&nbsp;</label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>&nbsp;</div>

              {emailSMTP === EmailTypes.emailSMTP && (
                <>
                  <div className={"p-col-3 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        {t("wishList.labels.smtpConfigurationPassword")}
                      </label>
                      {wishList.id === 0 && <span className={"red"}>*</span>}
                    </div>
                  </div>
                  <div className={"p-col-3 p-lg-3"}>
                    <Password
                      disabled={false}
                      style={{ width: "100%" }}
                      feedback={false}
                      placeholder={"Password"}
                      value={wishList.smtpConfiguration?.password || ""}
                      onChange={(e: any) =>
                        setValue("smtpConfigurationPassword", e.target.value)
                      }
                    />
                    <label className={"warning-label"}>
                      {warnings.smtpConfigurationPassword}
                    </label>
                  </div>
                </>
              )}
            </div>
            <div className={"p-grid"}>
              <>
                <div className={"p-col-3 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>{"iOffer Settings"}</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-3 p-lg-3"}>
                  <Button
                    label={"Launch iOffer"}
                    onClick={launchIOffer}
                    className={"p-button-secondary no-icon-buttons"}
                  />
                  <label className={"warning-label"}>
                    {warnings.smtpConfigurationEmailAddress}
                  </label>
                </div>
              </>
            </div>

            <h3>Reminder Email Content</h3>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("wishList.labels.acquisitionEmailTemplateUrl")}
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <TagsInput
                  onlyUnique={true}
                  validationRegex={Util.urlRegex}
                  onValidationReject={(tags) =>
                    TagsInputUtil.onValidationReject(tags, INPUT_TAGS_TYPES.URL)
                  }
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(
                    wishList.acquisitionEmailTemplateUrl
                  )}
                  onChange={(e: any) =>
                    setValue(
                      "acquisitionEmailTemplateUrl",
                      TagsInputUtil.setUniqueValue(e)
                    )
                  }
                  aria-describedby="send-time-help"
                />
                <small id="acquisitionEmailTemplateUrl-help">
                  Unlayer URL format is
                  'https://api.unlayer.com/v2/templates/TEMPLATE_ID/render' when
                  TEMPLATE_ID is the ID of the Unlayer's template.
                  <br />
                  If this value is empty, it will set the default Unlayer
                  template.
                </small>
                <label className={"warning-label"}>
                  {warnings.acquisitionEmailTemplateUrl}
                </label>
              </div>
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("wishList.labels.dealershipEmailTemplateUrl")}
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <TagsInput
                  onlyUnique={true}
                  validationRegex={Util.urlRegex}
                  onValidationReject={(tags) =>
                    TagsInputUtil.onValidationReject(tags, INPUT_TAGS_TYPES.URL)
                  }
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(
                    wishList.dealershipEmailTemplateUrl
                  )}
                  onChange={(e: any) =>
                    setValue(
                      "dealershipEmailTemplateUrl",
                      TagsInputUtil.setUniqueValue(e)
                    )
                  }
                  aria-describedby="send-time-help"
                />
                <small id="dealershipEmailTemplateUrl-help">
                  Unlayer URL format is
                  'https://api.unlayer.com/v2/templates/TEMPLATE_ID/render' when
                  TEMPLATE_ID is the ID of the Unlayer's template.
                  <br />
                  If this value is empty, it will set the default Unlayer
                  template.
                </small>
                <label className={"warning-label"}>
                  {warnings.dealershipEmailTemplateUrl}
                </label>
              </div>
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("wishList.labels.emailBodyContent")}
                  </label>
                </div>
              </div>
              <div className={"p-col-9 p-lg-9"}>
                <InputTextarea
                  id={IDS.wishList.inputs.emailBodyContent}
                  value={wishList.reminderEmailBodyContent || ""}
                  style={{
                    width: "100%",
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: 35,
                  }}
                  onChange={(e: any) =>
                    setValue("reminderEmailBodyContent", e.target.value)
                  }
                />
              </div>
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("wishList.labels.emailOffers")}
                  </label>
                </div>
              </div>
              <div className={"p-col-9 p-lg-9"}>
                <InputTextarea
                  id={IDS.wishList.inputs.emailOffers}
                  value={wishList.reminderEmailOffers || ""}
                  style={{
                    width: "100%",
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: 35,
                  }}
                  onChange={(e: any) =>
                    setValue("reminderEmailOffers", e.target.value)
                  }
                />
              </div>
            </div>
          </Panel>
          <div style={{ marginTop: 10 }}>
            <Button
              id={IDS.button.save}
              onClick={() => onSave()}
              label={"Save"}
              icon={"pi pi-save"}
            />
            <Button
              id={IDS.button.back}
              style={{ marginRight: 5 }}
              icon={"pi pi-arrow-left"}
              className={"p-button-warning"}
              label={"Back"}
              onClick={() => history.push(Util.PATH_NAMES.DEALERSHIPS)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutoAcquire;
