import {IDateArgs} from "../../../../index.dts";

import {getSmartSpendMakeModelData} from "../../../actions/kaninActions";
import {ISmartSpendDataAction} from "../MakeModelDataView";

export class MakeModelDataViewService {
  getMakeModelDataAction(args: {
    dealershipId: any
  }): ISmartSpendDataAction {
    return (params: IDateArgs) => getSmartSpendMakeModelData({
      date: params,
      dealershipId: args.dealershipId
    });
  }
}