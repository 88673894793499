import React, { useState } from "react";
import { IVisitorPage } from "../../index.dts";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Util from "../util/Util";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { get } from "lodash";
import moment from "moment";

interface IProps {
  pages?: IVisitorPage[];
}

const Pages = (props: IProps) => {
  const [limit] = useState(Util.dataGridDefaultLimit);

  const urlTemplate = (data: any): React.ReactElement => {
    return (
      <CopyToClipboard
        text={get(data, "url", "")}
        onCopy={() => Util.success("Copied to clipboard!")}
      >
        <div
          style={{
            color: "#39A3F4",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {get(data, "url", "")}
        </div>
      </CopyToClipboard>
    );
  };

  const dateTemplate = (data: any): React.ReactElement => {
    return <div>{moment(get(data, "ts")).format(Util.usDateFormat)}</div>;
  };

  function filterDate(value: any, filter: any): boolean {
    const date = value ? moment(value).format(Util.usDateFormat) : "";

    return date?.toLowerCase()?.includes(filter?.toLowerCase());
  }

  const render = (): React.ReactElement => {
    return (
      <DataTable
        value={props?.pages}
        resizableColumns={true}
        columnResizeMode={"expand"}
        rowsPerPageOptions={Util.rowsPerPageOptions}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
        paginator={true}
        totalRecords={props?.pages?.length}
        rows={limit}
        filterDisplay="row"
        scrollable={true}
        sortField={"ts"}
        sortOrder={-1}
      >
        <Column filter={true} sortable={true} field={"id"} header={"ID"} />
        <Column
          filter={true}
          sortable={true}
          field={"page_type"}
          header={"Page Type"}
        />
        <Column
          filter={true}
          sortable={true}
          field={"duration_score.result"}
          header={"Duration Score Result"}
        />
        <Column
          filter={true}
          sortable={true}
          field={"duration_score.reason"}
          header={"Duration Score Reason"}
        />
        <Column
          filter={true}
          sortable={true}
          field={"scroll_score.result"}
          header={"Scroll Score Result"}
        />
        <Column
          filter={true}
          sortable={true}
          field={"scroll_score.reason"}
          header={"Scroll Score Reason"}
        />
        <Column
          filter={true}
          sortable={true}
          field={"url"}
          header={"URL"}
          body={urlTemplate}
        />
        <Column
          filter={true}
          sortable={true}
          field={"ts"}
          header={"Date"}
          body={dateTemplate}
          filterMatchMode={"custom"}
          filterFunction={filterDate}
        />
      </DataTable>
    );
  };

  return render();
};

export default Pages;
