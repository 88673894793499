import React, {useEffect, useState} from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {getAssetList} from "../../actions/assetActions";
import Util from "../../util/Util";
import {IAsset, IStore} from "../../../index.dts";
import {DataView} from "primereact/dataview";
import {shallowEqual, useSelector} from "react-redux";

interface IProps {
    onChange(asset: IAsset): void;
}

export function AssetsDataView({onChange}: IProps) {
    const dealershipContext = useSelector((store: IStore) => store.auth.dealershipContext, shallowEqual);

    const [loading, setLoading] = useState<boolean>(false);
    const [assetList, setAssetList] = useState<IAsset[]>([]);

    useEffect(() => {
        return () => {
            Util.removeAlert();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (dealershipContext.id !== 0) {
            getAssets(dealershipContext.id)
        } else {
            setAssetList([]);
        }
        // eslint-disable-next-line
    }, [dealershipContext]);

    const getAssets = (dealershipId: number) => new Promise((resolve, reject) => {
        setLoading(true);
        getAssetList(dealershipId)
            .then(response => {
                setAssetList(response.data.data);
            })
            .catch(error => {
                Util.showError(error);
            })
            .finally(() => {
                setLoading(false)
            })
    });

    const itemTemplate = (product: any, layout: string) => {
        if (!product) {
            return;
        }
        return renderGridItem(product);
    };

    const renderGridItem = (asset: IAsset) => {
        return (
            <div className="p-col-12 p-lg-6">
                <div onClick={() => onChange(asset)} className="asset-container card">
                    <img height={100} style={{width: "100%"}}
                         src={Util.getAssetUrl(asset.objectId)}
                         alt={"..."}/>
                </div>
            </div>
        );
    };

    return (
        <DataView
            value={assetList}
            layout={"grid"}
            loading={loading}
            // @ts-ignore
            style={{minHeight: 50}}
            emptyMessage={"No assets found"}
            itemTemplate={itemTemplate}
        />
    );
}

export default AssetsDataView
