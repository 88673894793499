import React, {useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import {Accordion, AccordionTab} from "primereact/accordion";
import Linkify from 'react-linkify';
import {getSettingsByName, getSummaryData} from "../../actions/globalSettingsActions";
import {PERMISSIONS, SettingsName} from "../../util/Enums";
import Util from "../../util/Util";
import {IDealership} from "../../../index.dts";
import moment from "moment";
import {ProgressSpinner} from "primereact/progressspinner";

interface IProps {
    dealership: IDealership | undefined
}

export function Summary(props: IProps) {

    const [summaryData, setSummaryData] = useState({
        total: 0,
        weekTotal: 0,
        monthTotal: 0,
        estimatedLeadLift: 0,
        totalUniqueVisitors: 0,
    })
    const [FAQList, setFAQList] = useState<{ q: string, a: string }[]>([]);

    const [pending, setPending] = useState<boolean>(false);

    useEffect(() => {
        getFAQ();
    }, [])

    useEffect(() => {
        if (props.dealership) {
            getSummary(props.dealership.id);
        }
        // eslint-disable-next-line
    }, [props.dealership])

    const getSummary = (dealershipId: number): void => {
        if (Util.hasAnyAuthority(PERMISSIONS.RTS_ACCESS) && props.dealership) {
            setPending(true)
            getSummaryData(dealershipId)
                .then(response => {
                    setSummaryData(response.data);
                })
                .catch(error => {
                    Util.showError(error);
                })
                .finally(() => {
                    setPending(false)
                })
        }
    }

    const getFAQ = (): void => {
        if (Util.hasAnyAuthority(PERMISSIONS.SETTINGS_MANAGEMENT, PERMISSIONS.DEALERSHIP_SUPPORT_REQUEST)) {
            Util.globalSpinner().show();
            getSettingsByName(SettingsName.SUPPORT_PORTAL_FAQ)
                .then(response => {
                    setFAQList(response.data.map((item: any) => {
                        return Util._.attempt(JSON.parse.bind(null, item.value));
                    }))
                })
                .catch(error => Util.showError(error))
                .finally(() => Util.globalSpinner().hide());
        }
    }

    const componentDecorator = (href: string, text: string, key: number) => (
        <a href={href} key={key} target="_blank" rel="noopener noreferrer">
            {text}
        </a>
    );

    return (
        <div style={{height: "100%"}}>
            <Panel style={{minHeight: 140, marginBottom: 5}} className={"card"} header={(
                <label>Summary {pending ? (
                    <ProgressSpinner style={{width: 20, height: 20, marginLeft: 5}} strokeWidth={"4"}/>
                ) : null}</label>
            )}>
                <div className={"p-grid"}>
                    <div className={"p-col-12 p-lg-3"}>
                        <span className={"summary-value"}>{summaryData.weekTotal}</span>
                        <label className={"summary-text"}>Leads in the past 7 days</label>
                    </div>
                    <div className={"p-col-12 p-lg-3"}>
                        <span className={"summary-value"}>{summaryData.monthTotal}</span>
                        <label className={"summary-text"}>Leads in the past 30 days</label>
                    </div>
                    <div className={"p-col-12 p-lg-3"}>
                        <span className={"summary-value"}>{summaryData.total}</span>
                        <label className={"summary-text"}>Leads since launch | Launch
                            date: {moment(Util._.get(props, "dealership.created")).format(Util.usDateFormat)}</label>
                    </div>
                    <div className={"p-col-12 p-lg-3"}>
                        <span className={"summary-value"}>{summaryData.estimatedLeadLift}</span>
                        <label className={"summary-text"}>Estimated Lead lift</label>
                    </div>
                </div>
            </Panel>
            {Util.hasAnyAuthority(PERMISSIONS.SETTINGS_MANAGEMENT, PERMISSIONS.DEALERSHIP_SUPPORT_REQUEST) ?
                <Panel className={"card"} header={"FAQ's & ISSUES"}>
                    <Accordion multiple={true}>
                        {FAQList.map((item, index) =>
                            <AccordionTab key={index} header={item.q}>
                                <Linkify componentDecorator={componentDecorator}>{item.a}</Linkify>
                            </AccordionTab>)}
                    </Accordion>
                </Panel>
                : null}
        </div>
    );
}

export default Summary
