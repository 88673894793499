import { BaseChartDataset } from "./base_chart_dataset";
import { ChartConfig } from "../chart_config";
import Util from "../../Util";

export class ColoredDataset extends BaseChartDataset {
  constructor(args: { label: string; data?: number[]; yAxisID: string }) {
    const color =
      args.label === "Facebook"
        ? Util.stringToColor("NoFacebookFriends")
        : Util.stringToColor(args.label);

    super({
      backgroundColor: color,
      borderColor: color,
      borderWidth: ChartConfig.border.width,
      color: color,
      data: args.data ?? [],
      label: args.label,
      yAxisID: args.yAxisID,
    });
  }
}
