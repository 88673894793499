import Axios from "../config/Axios";
import { IBrand, IBrandSearchDto, IPageable } from "../../index.dts";

export const getBrandList = (
  searchDto: IBrandSearchDto
): Promise<IPageable<IBrand>> =>
  Axios.instance.post(`admin/brands/list`, searchDto);
export const deleteBrands = (ids: number[]) =>
  Axios.all(ids.map((id) => Axios.instance.delete(`admin/brands/${id}`)));
export const createBrand = (name: string) =>
  Axios.instance.post("admin/brands", { name });
export const updateBrand = (id: number, name: string) =>
  Axios.instance.put(`admin/brands/${id}`, { name });
export const getBrandListUniteDigital = () =>
  Axios.instance.get(`dealerships/unite-digital/brands`);
