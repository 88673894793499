import { isBoolean, isNumber, isString } from "lodash";

import { IChartDataset } from "../../../../index.dts";

export abstract class BaseChartDataset implements IChartDataset {
  readonly backgroundColor: string | undefined;
  readonly borderColor: string | undefined;
  readonly borderWidth: number = 1.5;
  readonly color: string | undefined;
  readonly data: number[];
  readonly fill: boolean = false;
  readonly label: string;
  readonly lineTension: number = 0;
  readonly yAxisID: string;

  constructor(args: IChartDataset) {
    if (isString(args.backgroundColor)) {
      this.backgroundColor = args.backgroundColor;
    }

    if (isString(args.borderColor)) {
      this.borderColor = args.borderColor;
    }

    if (isNumber(args.borderWidth)) {
      this.borderWidth = args.borderWidth;
    }

    if (isString(args.color)) {
      this.color = args.color;
    }

    this.data = args.data;

    if (isBoolean(args.fill)) {
      this.fill = args.fill;
    }

    this.label = args.label;

    if (isNumber(args.lineTension)) {
      this.lineTension = args.lineTension;
    }

    this.yAxisID = args.yAxisID;
  }
}