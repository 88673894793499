import React, { useState } from "react";
// import { withRouter } from "react-router";
import { AppMenu } from "../AppMenu";
import Util from "../util/Util";
import AppTopBar from "../AppTopBar";
import classNames from "classnames";
import { LAYOUT_MODES, TOP_BAR_ITEMS } from "../util/Enums";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IStore } from "../../index.dts";
import { logout, logoutSuccess } from "../actions/authActions";
import AppBreadcrumb from "../AppBreadcrumb";
import { useHistory, withRouter } from "react-router-dom";
import { updateDevice } from "../actions/deviceActions";

const AuthorizedViewsWrapper = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  const [menu] = useState(Util.getMenu());
  const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb as any);

  const [menuActive, setMenuActive] = useState<boolean>(false);
  const [menuClick, setMenuClick] = useState<boolean>(false);
  const [layoutMode] = useState<LAYOUT_MODES>(
    menu.length > 1 ? LAYOUT_MODES.STATIC : LAYOUT_MODES.OVERLAY
  );
  const [topBarMenuActive, setTopBarMenuActive] = useState<boolean>(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState<boolean>(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] =
    useState(false);

  const user = useSelector((store: IStore) => store.auth.user, shallowEqual);
  const device = useSelector(
    (store: IStore) => store.auth.device,
    shallowEqual
  );

  const onMenuButtonClick = (event: any) => {
    setMenuClick(true);
    setTopBarMenuActive(false);
    if (layoutMode === LAYOUT_MODES.OVERLAY && !Util.isMobile()) {
      setOverlayMenuActive(!overlayMenuActive);
    } else {
      if (Util.isDesktop())
        setStaticMenuDesktopInactive(!staticMenuDesktopInactive);
      else setStaticMenuMobileActive(!staticMenuMobileActive);
    }

    event.preventDefault();
  };

  const onTopBarMenuButtonClick = (event: any) => {
    setTopBarMenuActive(!topBarMenuActive);

    hideOverlayMenu();
    event.preventDefault();
  };

  const onTopBarItemClick = (event: {
    item: TOP_BAR_ITEMS;
    originalEvent: any;
  }) => {
    switch (event.item) {
      case TOP_BAR_ITEMS.LOGOUT:
        onLogout();
        break;
      case TOP_BAR_ITEMS.PROFILE:
        history.push(Util.PATH_NAMES.PROFILE);
        break;
    }
    event.originalEvent.preventDefault();
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
  };

  const isHorizontal = (): boolean => {
    return layoutMode === LAYOUT_MODES.HORIZONTAL;
  };

  const isSlim = (): boolean => {
    return layoutMode === LAYOUT_MODES.SLIM;
  };

  const onDocumentClick = (event: any) => {
    if (topBarMenuActive) setTopBarMenuActive(false);

    if (menuClick) {
      if (isHorizontal() || isSlim()) {
        setMenuActive(false);
      }

      hideOverlayMenu();
    }

    setMenuClick(false);
  };

  const onLogout = () => {
    Util.globalSpinner().show();
    updateDevice(device.id, { enabled: false }).finally(() => {
      logout().finally(() => {
        dispatch(logoutSuccess());
        window.location.reload();
      });
    });
  };

  const onMenuClick = () => {
    setMenuClick(true);
  };

  const onRootMenuItemClick = () => {
    setMenuActive(!menuActive);
  };

  const onMenuItemClick = (event: any) => {
    if (!event.item.items) {
      hideOverlayMenu();
    }
    if (!event.item.items && (isHorizontal() || isSlim())) {
      setMenuActive(false);
    }
  };

  return (
    <div
      style={{ height: "100%" }}
      className={classNames("layout-wrapper", {
        "layout-horizontal": layoutMode === "horizontal",
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-slim": layoutMode === "slim",
        "layout-static-inactive": staticMenuDesktopInactive,
        "layout-mobile-active": staticMenuMobileActive,
        "layout-overlay-active": overlayMenuActive,
      })}
      onClick={onDocumentClick}
    >
      <AppTopBar
        darkTheme={false}
        userName={user.email}
        topBarMenuActive={topBarMenuActive}
        onTopBarItemClick={onTopBarItemClick}
        onMenuButtonClick={onMenuButtonClick}
        onTopBarMenuButtonClick={onTopBarMenuButtonClick}
      />

      <div className="layout-menu-container" onClick={onMenuClick}>
        <div className="layout-menu-content">
          <AppMenu
            // model={
            //   dealershipContext.myCampaignsEnabled
            //     ? menu
            //     : menu.filter(
            //         (menuObj) => menuObj && menuObj.label !== "My Campaigns"
            //       )
            // }
            model={
              dealershipContext.myCampaignsEnabled
                ? dealershipContext.iOfferEnabled
                  ? menu
                  : menu.filter(
                      (menuObj) => menuObj && menuObj.label !== "iOffer Portal"
                    )
                : dealershipContext.iOfferEnabled
                ? menu.filter(
                    (menuObj) => menuObj && menuObj.label !== "My Campaigns"
                  )
                : menu
                    .filter(
                      (menuObj) => menuObj && menuObj.label !== "My Campaigns"
                    )
                    .filter(
                      (menuObj) => menuObj && menuObj.label !== "iOffer Portal"
                    )
            }
            layoutMode={layoutMode}
            active={menuActive}
            onMenuItemClick={onMenuItemClick}
            onRootMenuItemClick={onRootMenuItemClick}
          />
        </div>
      </div>

      <div className="layout-content">
        <AppBreadCrumbWithRouter />
        <div className={"layout-content-container"}>{props.children}</div>
      </div>
    </div>
  );
};

export default AuthorizedViewsWrapper;
