import {IKeyOptions, ISmartSpendData, ITrafficMakeModel} from "../../../index.dts";
import {forEach, includes, isArray} from "lodash";

import {BaseSmartSpendFilterResolver} from "../smart_spend_filter/base_smart_spend_filter_resolver";
import {ISmartSpendFilterData} from "../smart_spend_filter/SmartSpendFilter";
import moment from "moment";

export class SmartSpendChartService {
  public getDatesLabels(source: ISmartSpendData | undefined, format: string): string[] {
    return source?.dates?.map(date => moment(date).format(format)) ?? [];
  }

  public getFilteredData(source: any, filter: ISmartSpendFilterData, size?: number): number[] {
    const resolver = new BaseSmartSpendFilterResolver();
    return resolver.resolve(source, filter, size);
  }

  public getMakeModels(source: ITrafficMakeModel): IKeyOptions {
    return Object.entries(source).reduce(function (current: { [key: string]: string[] }, value) {
      current[value[0]] = Object.keys(value[1])
      return current
    }, {});
  }

  public getMakeModelOptions(source?: ISmartSpendData): IKeyOptions {
    let result: any = {};
    const dailyTotal = "daily_total";

    function pluck(target: any): void {
      forEach(target, (value: any) => {
        forEach(value, (_value: any, _key: string) => {
          if (_key !== dailyTotal) {
            forEach(_value, (__value: any, __key: string) => {
              if (__key !== dailyTotal) {
                if (!isArray(result[__key])) {
                  result[__key] = [];
                }

                forEach(__value, (___value: any, ___key: string) => {
                  if (___key !== dailyTotal) {
                    if (!includes(result[__key], ___key)) {
                      result[__key].push(___key);
                    }
                  }
                });
              }
            });
          }
        });
      });
    }

    pluck(source?.semVendors);
    pluck(source?.channels);

    return result;
  }
}