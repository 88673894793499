import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { ToggleButton } from "primereact/togglebutton";
import { Button } from "primereact/button";
import {
  getT2DataProxyCall,
  getT2VinDataProxyCall,
  getT2PlateDataProxyCall,
  getT2PiiDataProxyCall,
} from "../../../actions/MyTrafficNotificationActions";
import Util from "../../../util/Util";
import { ResponseT2Data, VehicleDataResponse, PiiT2Response } from "./index";
import "./fraudGuard.css";
import VisitorsExternalData from "../visitors_external_data/VisitorsExternalData";
import VehicleData from "../vehicle_data/VehicleData";
import T2PiiData from "../t2_pii_data/T2PiiData";
import { TabView, TabPanel } from "primereact/tabview";
import { shallowEqual, useSelector } from "react-redux";
import { IStore } from "../../../../index.dts";

const FraudGuard = (ref: any) => {
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  const [t2DataLoading, setT2DataLoading] = useState<boolean>(false);
  const [t2DataVinLoading, setT2DataVinLoading] = useState<boolean>(false);
  const [t2DataPlateLoading, setT2DataPlateLoading] = useState<boolean>(false);
  const [t2DataPiiLoading, setT2DataPiiLoading] = useState<boolean>(false);
  const [piiChecked, setPiiChecked] = useState<boolean>(false);
  const [vinChecked, setVinChecked] = useState<boolean>(false);
  const [plateChecked, setPlateChecked] = useState<boolean>(false);
  const [piiSsnChecked, setPiiSsnChecked] = useState<boolean>(false);

  // T2 call
  const [visitorsT2ExternalData, setVisitorsT2ExternalData] =
    useState<ResponseT2Data>();
  const [t2PhoneNumber, setT2PhoneNumber] = useState<string>("");
  const [t2Vin, setT2Vin] = useState<string>("");
  const [visitorsT2VinExternalData, setVisitorsT2VinExternalData] =
    useState<VehicleDataResponse>();
  const [t2Plate, setT2Plate] = useState<string>("");
  const [t2State, setT2State] = useState<string>("");
  const [visitorsT2PlateExternalData, setVisitorsT2PlateExternalData] =
    useState<VehicleDataResponse>();
  const [visitorsT2PiiExternalData, setVisitorsT2PiiExternalData] =
    useState<PiiT2Response>();
  const [t2Ssn, setT2Ssn] = useState<string>("");
  const [t2Dl, setT2Dl] = useState<string>("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      t2PhoneNumber === "" &&
      t2Vin === "" &&
      t2Plate === "" &&
      t2State === "" &&
      t2Ssn === "" &&
      t2Dl === ""
    ) {
      Util.warning("At least one field is required.");
      setT2DataLoading(false);
      return;
    }

    {
      /* Customer Detail/Results */
    }
    getT2Data(t2PhoneNumber);

    {
      /* VIN Detail/Results */
    }
    getT2VinData(t2Vin);

    {
      /* Plate Detail/Results */
    }
    getT2PlateData(t2Plate, t2State);

    {
      /* Pii Detail/Result */
    }
    getT2PiiData(t2Ssn, t2Dl);
  };

  // T2 API Call
  const getT2Data = (phoneNumber: string) =>
    new Promise((resolve, reject) => {
      if (phoneNumber != "") {
        setT2DataLoading(true);
        getT2DataProxyCall(phoneNumber, dealershipContext?.externalRefId)
          .then((response) => {
            setT2DataLoading(false);
            if (response.data.Vehicles === null && response.data.Pii) {
              Util.warning("Data not found with that search criteria.");
              setVisitorsT2ExternalData(undefined);
            } else {
              setVisitorsT2ExternalData(response.data);
              resolve(response);
            }
          })
          .catch((error) => {
            console.error("Error sending data:", error);
            setT2DataLoading(false);
          });
      }
    });

  const getT2VinData = (vin: string) =>
    new Promise((resolve, reject) => {
      if (vin != "") {
        setT2DataVinLoading(true);
        getT2VinDataProxyCall(vin, dealershipContext?.externalRefId)
          .then((response) => {
            setT2DataVinLoading(false);
            if (response.data === null) {
              Util.warning("VIN data not found with that search criteria.");
              setVisitorsT2VinExternalData(undefined);
            } else {
              setVisitorsT2VinExternalData(response.data);
              resolve(response);
            }
          })
          .catch((error) => {
            console.error("Error sending data:", error);
            setT2DataVinLoading(false);
          });
      }
    });

  const getT2PlateData = (plate: string, state: string) =>
    new Promise((resolve, reject) => {
      if (plate != "" && state != "") {
        setT2DataPlateLoading(true);
        getT2PlateDataProxyCall(plate, state, dealershipContext?.externalRefId)
          .then((response) => {
            setT2DataPlateLoading(false);
            if (response.data === null) {
              Util.warning("Plate data not found with that search criteria.");
              setVisitorsT2PlateExternalData(undefined);
            } else {
              setVisitorsT2PlateExternalData(response.data);
              resolve(response);
            }
          })
          .catch((error) => {
            console.error("Error sending data:", error);
            setT2DataPlateLoading(false);
          });
      } else {
        if (plate != "") {
          Util.warning("State is required together with Plate.");
          setT2DataPlateLoading(false);
          return;
        }
      }
    });

  const getT2PiiData = (ssn: string, dl: string) =>
    new Promise((resolve, reject) => {
      setT2DataPiiLoading(true);
      getT2PiiDataProxyCall(ssn, dl, dealershipContext?.externalRefId)
        .then((response) => {
          setT2DataPiiLoading(false);
          if (response.data === null) {
            Util.warning("PII data not found with that search criteria.");
            setVisitorsT2PiiExternalData(undefined);
          } else {
            setVisitorsT2PiiExternalData(response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          Util.showError(error);
          console.error("Error sending data:", error.message);
          setT2DataPiiLoading(false);
          setVisitorsT2PiiExternalData(undefined);
        });
    });

  return (
    <div
      id={"visitor-external-data-mytraffic"}
      className={
        "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"
      }
      style={{ overflowX: "auto" }}
    >
      {/* Customer Detail/Results */}
      <div style={{ display: "flex", marginBottom: 20, marginLeft: 5 }}>
        <div style={{ width: 200, marginRight: 20 }}>
          <label htmlFor="phoneNumber">Phone Number:</label>
          <InputText
            style={{ width: "100%" }}
            onChange={(e: any) => {
              setT2PhoneNumber(e.target.value);
            }}
            value={t2PhoneNumber}
          />
        </div>

        <div style={{ width: 150, marginTop: 18 }}>
          <ToggleButton
            onLabel="Approved"
            offLabel="Rejected"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            checked={piiChecked}
            onChange={(e) => setPiiChecked(e.value)}
            disabled={t2PhoneNumber == "" ? true : false}
          />
        </div>
      </div>

      {/* VIN Detail/Results */}
      <div style={{ display: "flex", marginBottom: 20, marginLeft: 5 }}>
        <div style={{ width: 200, marginRight: 20 }}>
          <label htmlFor="vin">VIN:</label>
          <InputText
            style={{ width: "100%" }}
            onChange={(e: any) => {
              setT2Vin(e.target.value);
            }}
            value={t2Vin}
          />
        </div>

        <div style={{ width: 150, marginTop: 18 }}>
          <ToggleButton
            onLabel="Approved"
            offLabel="Rejected"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            checked={vinChecked}
            onChange={(e) => setVinChecked(e.value)}
            disabled={t2Vin == "" ? true : false}
          />
        </div>
      </div>

      {/* Plate Detail/Results */}
      <div style={{ display: "flex", marginBottom: 20, marginLeft: 5 }}>
        <div style={{ width: 200, marginRight: 20 }}>
          <label htmlFor="plate">Plate:</label>
          <InputText
            style={{ width: "100%" }}
            onChange={(e: any) => {
              setT2Plate(e.target.value);
            }}
            value={t2Plate}
          />
        </div>

        <div style={{ width: 200, marginRight: 20 }}>
          <label htmlFor="state">State:</label>
          <InputText
            style={{ width: "100%" }}
            onChange={(e: any) => {
              setT2State(e.target.value);
            }}
            value={t2State}
            maxLength={2}
          />
        </div>

        <div style={{ width: 150, marginTop: 18 }}>
          <ToggleButton
            onLabel="Approved"
            offLabel="Rejected"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            checked={plateChecked}
            onChange={(e) => setPlateChecked(e.value)}
            disabled={t2Plate == "" && t2State == "" ? true : false}
          />
        </div>
      </div>

      {/* SSN & DL Detail/Results */}
      <div style={{ display: "flex", marginBottom: 20, marginLeft: 5 }}>
        <div style={{ width: 200, marginRight: 20 }}>
          <label htmlFor="ssn">SSN:</label>
          <InputText
            style={{ width: "100%" }}
            onChange={(e: any) => {
              setT2Ssn(e.target.value);
            }}
            value={t2Ssn}
          />
        </div>

        <div style={{ width: 200, marginRight: 20 }}>
          <label htmlFor="dl">DLN:</label>
          <InputText
            style={{ width: "100%" }}
            onChange={(e: any) => {
              setT2Dl(e.target.value);
            }}
            value={t2Dl}
          />
        </div>

        <div style={{ width: 150, marginTop: 18 }}>
          <ToggleButton
            onLabel="Approved"
            offLabel="Rejected"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            checked={piiSsnChecked}
            onChange={(e) => setPiiSsnChecked(e.value)}
            disabled={t2Ssn == "" && t2Dl == "" ? true : false}
          />
        </div>
      </div>

      <div style={{ display: "flex", marginBottom: 20, marginLeft: 5 }}>
        <div style={{ width: 100, marginTop: 18 }}>
          <Button
            label={"Send"}
            onClick={handleSubmit}
            className={"p-button-secondary no-icon-buttons"}
          />
        </div>

        <div style={{ width: 150, marginTop: 18 }}>
          <Button
            className={"p-button-secondary no-icon-buttons"}
            label={"New search"}
            onClick={() => {
              setT2PhoneNumber("");
              setVisitorsT2ExternalData(undefined);
              setT2Vin("");
              setVisitorsT2VinExternalData(undefined);
              setT2Plate("");
              setT2State("");
              setVisitorsT2PlateExternalData(undefined);
              setVisitorsT2PiiExternalData(undefined);
              setT2Ssn("");
              setT2Dl("");
              setPiiChecked(false);
              setPlateChecked(false);
              setPiiSsnChecked(false);
              setVinChecked(false);
            }}
          />
        </div>
      </div>
      <TabView>
        <TabPanel header="Customer and Vehicle">
          <div style={{ display: "flex", marginBottom: 20, marginLeft: 5 }}>
            {/* Customer Detail/Results */}
            <div style={{ marginLeft: 5, width: "30vw" }}>
              <div style={{ display: "flex", marginLeft: "5vw" }}>
                <b>Customer Detail/Results</b>
              </div>
              <div style={{ height: "29vh", overflowY: "scroll" }}>
                {t2DataLoading && (
                  <div
                    className="loader-container"
                    style={{ marginLeft: "8vw" }}
                  >
                    <div className="loader"></div>
                  </div>
                )}
                <VisitorsExternalData
                  visitorsT2ExternalData={visitorsT2ExternalData}
                />
              </div>
            </div>

            {/* VIN Detail/Results */}
            <div style={{ marginLeft: 5, width: "30vw" }}>
              <div style={{ display: "flex", marginLeft: "5vw" }}>
                <b>VIN Detail/Results</b>
              </div>
              <div style={{ height: "29vh", overflowY: "scroll" }}>
                {t2DataVinLoading && (
                  <div
                    className="loader-container"
                    style={{ marginLeft: "8vw" }}
                  >
                    <div className="loader"></div>
                  </div>
                )}
                <VehicleData vehicleData={visitorsT2VinExternalData} />
              </div>
            </div>

            {/* Plate Detail/Results */}
            <div style={{ marginLeft: 5, width: "30vw" }}>
              <div style={{ display: "flex", marginLeft: "5vw" }}>
                <b>Plate Detail/Results</b>
              </div>
              <div style={{ height: "29vh", overflowY: "scroll" }}>
                {t2DataPlateLoading && (
                  <div
                    className="loader-container"
                    style={{ marginLeft: "8vw" }}
                  >
                    <div className="loader"></div>
                  </div>
                )}
                <VehicleData vehicleData={visitorsT2PlateExternalData} />
              </div>
            </div>

            {/* SSN & DL Detail/Results */}
            <div style={{ marginLeft: 5, width: "30vw" }}>
              <div style={{ display: "flex", marginLeft: "5vw" }}>
                <b>SSN & DLN Detail/Results</b>
              </div>
              <div style={{ height: "29vh", overflowY: "scroll" }}>
                {t2DataPiiLoading && (
                  <div
                    className="loader-container"
                    style={{ marginLeft: "8vw" }}
                  >
                    <div className="loader"></div>
                  </div>
                )}
                <T2PiiData piiData={visitorsT2PiiExternalData} />
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>

      <div style={{ display: "flex", marginBottom: 20, marginLeft: 5 }}>
        <div style={{ width: 100, marginTop: 18 }}>
          <Button
            label={"Save"}
            onClick={() => {
              window.print();
            }}
            className={"p-button-secondary no-icon-buttons"}
          />
        </div>
      </div>
    </div>
  );
};

export default FraudGuard;
