import React, { useEffect, useState } from "react";
import "./iOfferPortal.css";
import { AiBuilder } from "@iofferio/aibuilder";
import { getIOfferToken } from "../../actions/iOfferActions";
import { shallowEqual, useSelector } from "react-redux";
import { IStore } from "../../../index.dts";
import { isEmpty } from "lodash";
import Spinner from "../../components/spinner/Spinner";

export function IOfferPortal() {
  const [accessToken, setAccessToken] = useState<string>("");
  const [dealerID, setDealerID] = useState<number>(0);
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  const [pending, setPending] = useState<boolean>(false);

  useEffect(() => {
    setPending(true);
    if (dealershipContext?.id) {
      getIOfferToken(dealershipContext?.id)
        .then((response) => {
          if (response?.data) {
            setAccessToken(response?.data?.AccessToken);
            setDealerID(response?.data?.DealersId);
          }
        })
        .catch((error) => {
          setDealerID(0);
          setAccessToken("");
        })
        .finally(() => {
          setPending(false);
        });
    }
  }, [dealershipContext?.id]);
  console.log("ENV:", process.env.REACT_APP_ENV_VAR);
  return (
    <div>
      <Spinner visible={pending} />
      {dealerID !== 0 && !isEmpty(accessToken) && (
        <AiBuilder
          dealerId={dealerID}
          environment={
            process.env.REACT_APP_ENV_VAR === "prod" ? "prod" : "dev"
          }
          accessToken={accessToken}
          styles={{}}
        ></AiBuilder>
      )}
    </div>
  );
}

export default IOfferPortal;
