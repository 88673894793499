import {
  ProductTypes,
  ProductTypesUtil,
} from "../../../util/enum/product_types";
import React, { useImperativeHandle, useState } from "react";

import { Column } from "primereact/column";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  DataTable,
  DataTableSelectionChangeParams,
} from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { IEngagementLeadDto } from "../../../../index.dts";
import Util from "../../../util/Util";
import {
  getEngagementLeads,
  getEngagementLeadsType,
} from "../../../actions/engLeadsAction";
import moment from "moment-timezone";

interface IProps {
  dealershipId: number;
  dealershipTimezone: string;
}

const LeadModal = React.forwardRef((props: IProps, ref: any) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [first, setFirst] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [leadData, setLeadData] = useState<IEngagementLeadDto[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<1 | 0 | -1>(0);
  const [campaignSearch, setCampaignSearch] = useState<string>("");
  const [vehicleType, setVehicleType] = useState<ProductTypes>();
  const [selectedLead, setSelectedLead] = useState();
  const [leadTypeCounts, setLeadTypeCounts] = useState<any>();
  const [leadTypePercentages, setLeadTypePercentages] = useState<any>();

  const [endDate, setEndDate] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const vehicleOrderFields = [
    "year",
    "make",
    "model",
    "series",
    "stock",
    "plate_number",
    "vin",
    "vehicle_type",
  ];

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }));

  const show = (
    campaignSearch: string,
    startDate: string,
    endDate: string,
    vehicleType?: ProductTypes
  ): void => {
    const start = moment(startDate)
      .set({ hour: 0, minute: 0, seconds: 0 })
      .format(Util.localDateTimeFormat);
    const end = moment(endDate)
      .set({ hour: 23, minute: 59, seconds: 59 })
      .format(Util.localDateTimeFormat);

    setLoading(true);
    setVisible(true);
    setEndDate(end);
    setStartDate(start);
    setCampaignSearch(campaignSearch);
    setVehicleType(vehicleType);

    getLeadsData({
      page: 0,
      limit: 100,
      fromDate: start,
      toDate: end,
      campaignSearch: campaignSearch,
      vehicleType: vehicleType,
    });

    getLeadsDataType({
      page: 0,
      limit: 100,
      fromDate: start,
      toDate: end,
      campaignSearch: campaignSearch,
      vehicleType: vehicleType,
    });
  };

  const hide = (): void => {
    setVisible(false);
    setLeadData([]);
    setSortField("");
    setSortOrder(0);
    setCampaignSearch("");
  };

  const getLeadsDataType = (args: {
    page: number;
    limit: number;
    fromDate: string;
    toDate: string;
    campaignSearch: string;
    sortField?: string;
    sortOrder?: number;
    vehicleType?: ProductTypes;
  }) =>
    new Promise((resolve, reject) => {
      let sorting: any = undefined;
      const to = moment(args.toDate).format(Util.localDateTimeFormat);
      const from = moment(args.fromDate).format(Util.localDateTimeFormat);

      if (sortField) {
        sorting = {
          field: sortField,
          direction: sortOrder === 1 ? "asc" : "desc",
        };
      }

      // setLoading(true);
      getEngagementLeadsType({
        page: args.page,
        pageLimit: args.limit,
        dealershipIds: [props.dealershipId],
        toDate: to,
        fromDate: from,
        sorting: sorting,
        filterEngagement: false,
        vehicleType: ProductTypesUtil.getName(args.vehicleType),
        campaignType: !Util.isEmpty(args.campaignSearch)
          ? args.campaignSearch
          : undefined,
      })
        .then((response) => {
          setLeadTypeCounts(response?.data?.leadTypeCounts);
          setLeadTypePercentages(response?.data?.leadTypePercentages);
          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);
          reject(error);
        })
        .finally(() => {
          // setLoading(false);
        });
    });

  const getLeadsData = (args: {
    page: number;
    limit: number;
    fromDate: string;
    toDate: string;
    campaignSearch: string;
    sortField?: string;
    sortOrder?: number;
    vehicleType?: ProductTypes;
  }) =>
    new Promise((resolve, reject) => {
      let sorting: any = undefined;
      const to = moment(args.toDate).format(Util.localDateTimeFormat);
      const from = moment(args.fromDate).format(Util.localDateTimeFormat);

      if (sortField) {
        sorting = {
          field: sortField,
          direction: sortOrder === 1 ? "asc" : "desc",
        };
      }

      setLoading(true);
      getEngagementLeads({
        page: args.page,
        pageLimit: args.limit,
        dealershipIds: [props.dealershipId],
        toDate: to,
        fromDate: from,
        sorting: sorting,
        filterEngagement: false,
        vehicleType: ProductTypesUtil.getName(args.vehicleType),
        campaignType: !Util.isEmpty(args.campaignSearch)
          ? args.campaignSearch
          : undefined,
      })
        .then((response) => {
          dataNormalization(response?.data?.content);
          setLeadData(response?.data?.content);
          setTotalItemsCount(response?.data?.totalElements);
          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);
          reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const dataNormalization = (data: IEngagementLeadDto[]): void => {
    data.forEach((item) => {
      item["name"] = `${Util.getDefaultIfNull(
        Util._.get(item, "metadata.first_name", null),
        ""
      )} ${Util.getDefaultIfNull(
        Util._.get(item, "metadata.last_name", ""),
        ""
      )}`.trim();
    });
  };

  const createdTemplate = (data: any) => {
    return (
      <div>
        {moment(data.zonedCreated).tz(props.dealershipTimezone).format()}
      </div>
    );
  };

  const onPage = (event: any) => {
    getLeadsData({
      page: event.page,
      limit: event.rows,
      fromDate: startDate,
      toDate: endDate,
      campaignSearch: campaignSearch,
      sortField: Util.getDefaultIfNull(sortField, ""),
      sortOrder: sortOrder,
      vehicleType: vehicleType,
    }).finally(() => {
      setLimit(event.rows);
      setFirst(event.first);
      setPage(event.page);
    });
  };

  const onSort = (data: any) => {
    setSortField(data.sortField);
    setSortOrder(data.sortOrder);
    getLeadsData({
      page: page,
      limit: limit,
      fromDate: startDate,
      toDate: endDate,
      campaignSearch: campaignSearch,
      sortField: data.sortField,
      sortOrder: data.sortOrder,
      vehicleType: vehicleType,
    });
  };

  const urlTemplate = (data: any) => {
    return (
      <CopyToClipboard
        text={Util._.get(data, "pageMetadata.url", "")}
        onCopy={() => Util.success("Copied to clipboard!")}
      >
        <div
          style={{
            color: "#39A3F4",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {Util._.get(data, "pageMetadata.url", "")}
        </div>
      </CopyToClipboard>
    );
  };

  const emailTemplate = (data: any) => {
    return (
      <CopyToClipboard
        text={Util.getDefaultIfNull(Util._.get(data, "metadata.email", ""), "")}
        onCopy={() => Util.success("Copied to clipboard!")}
      >
        <div style={{ wordBreak: "break-word" }}>
          {Util.getDefaultIfNull(Util._.get(data, "metadata.email", ""), "")}
        </div>
      </CopyToClipboard>
    );
  };

  const onSelectLead = (e: DataTableSelectionChangeParams): void => {
    setSelectedLead(e.value);
  };

  const onHideSelectedLead = (): void => {
    setSelectedLead(undefined);
  };

  return (
    <>
      <Dialog
        onHide={hide}
        visible={visible}
        style={{ width: "80vw" }}
        header={
          <div style={{ display: "flex", columnGap: "20px" }}>
            <p>
              Leads Details {"("}{" "}
              {Util.isEmpty(campaignSearch) ? "Total" : campaignSearch} {")"}
            </p>
            {leadTypeCounts &&
              leadTypePercentages &&
              Object.keys(leadTypeCounts)?.map(
                (leadType: string, index: number) => {
                  return (
                    <p key={index}>
                      {leadType}: {leadTypeCounts[leadType]}
                      {" ("}
                      {Number(leadTypePercentages[leadType]).toFixed(1)}
                      {"%"}
                      {")"}
                    </p>
                  );
                }
              )}
          </div>
        }
      >
        <div
          className={
            "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"
          }
        >
          <DataTable
            resizableColumns={true}
            columnResizeMode={"expand"}
            rowsPerPageOptions={Util.rowsPerPageOptions}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
            value={leadData}
            paginator={true}
            totalRecords={totalItemsCount}
            rows={limit}
            lazy={true}
            first={first}
            loading={loading}
            onPage={onPage}
            selectionMode={"single"}
            sortMode={"single"}
            onSelectionChange={onSelectLead}
            onSort={onSort}
            sortField={sortField}
            sortOrder={sortOrder}
          >
            <Column
              sortable={true}
              field={"zonedCreated"}
              sortField={"created"}
              header={"Date"}
              body={createdTemplate}
            />
            <Column sortable={true} field={"leadType"} header={"Lead Type"} />
            <Column sortable={false} field={"name"} header={"Name"} />
            <Column
              sortable={false}
              field={"metadata.phone"}
              header={"Phone"}
            />
            <Column
              sortable={false}
              field={"metadata.email"}
              header={"Email"}
              body={emailTemplate}
            />
            <Column sortable={true} field={"device"} header={"Device"} />
            <Column field={"zip"} header={"Zip"} />
            <Column field={"pageMetadata.make"} header={"Make"} />
            <Column field={"pageMetadata.model"} header={"Model"} />
            <Column field={"disposition"} header={"Disposition"} />
            <Column
              field={"pageMetadata.url"}
              header={"Url"}
              style={{ width: 200 }}
              body={urlTemplate}
            />
            <Column
              sortable={true}
              field={"campaignType"}
              header={"Campaign"}
            />
          </DataTable>
        </div>
      </Dialog>
      <Dialog
        modal={true}
        visible={!!selectedLead}
        onHide={onHideSelectedLead}
        header={"Lead Details"}
      >
        <div>
          <label>First visit:</label>
          <span className={"lead-details-value"}>
            {Util._.get(selectedLead, "zonedCreated", null)}
          </span>
        </div>

        <div>
          <label>Page Views:</label>
          <span className={"lead-details-value"}>
            {Util._.get(selectedLead, "pageViews", 0)}
          </span>
        </div>

        <div>
          <label>Engaged time:</label>
          <span className={"lead-details-value"}>
            {Util._.get(selectedLead, "totalEngagedTime", "0 minutes")}
          </span>
        </div>

        <div>
          <label>Duration time:</label>
          <span className={"lead-details-value"}>
            {Util._.get(selectedLead, "totalDurationTime", "0 minutes")}
          </span>
        </div>

        <div>
          <label>Interests with percentages:</label>
          <span className={"lead-details-value"}>
            {Util._.get(selectedLead, "stats", "")}
          </span>
        </div>
        <div>
          {(() => {
            let pii = Util._.get(selectedLead, "metadata", "")?.pii;
            let vehicles = Util._.get(selectedLead, "metadata", "")?.vehicles;
            if (pii || vehicles) {
              return (
                <>
                  <h4>Registered PII to this lead:</h4>
                </>
              );
            } else {
              return null;
            }
          })()}
          {(() => {
            let pii = Util._.get(selectedLead, "metadata", "")?.pii;
            if (pii) {
              return (
                <>
                  {pii.map((item, index) => (
                    <div key={index}>
                      {
                        // Render specific fields first: address -> city -> state -> zip code
                        ["address", "city", "state", "zipcode"].map((field) => {
                          let value = item[field];
                          let displayKey = field;

                          if (value !== undefined) {
                            if (
                              Array.isArray(value) &&
                              (field === "emails" || field === "phone")
                            ) {
                              value = value.join(", ");
                            }

                            displayKey = displayKey
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase());

                            return (
                              <p key={field}>
                                <strong>{displayKey}:</strong> {value ?? "N/A"}
                              </p>
                            );
                          }
                          return null;
                        })
                      }

                      {
                        // Render other fields in the object
                        Object.entries(item).map(([key, value]) => {
                          if (
                            ![
                              "address",
                              "city",
                              "state",
                              "zipcode",
                              "allFieldsNull",
                            ].includes(key)
                          ) {
                            if (key === "emails" && Array.isArray(value)) {
                              value = value.join(", ");
                            }
                            if (key === "phone" && Array.isArray(value)) {
                              value = value.join(", ");
                            }
                            key = key
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase());
                            return (
                              <p key={key}>
                                <>
                                  <strong>{key}:</strong> {value ?? "N/A"}
                                </>
                              </p>
                            );
                          }
                          return null;
                        })
                      }

                      <hr />
                    </div>
                  ))}
                </>
              );
            } else {
              return null;
            }
          })()}

          {(() => {
            let vehicles = Util._.get(selectedLead, "metadata", "")?.vehicles;
            if (vehicles) {
              return (
                <>
                  <h4>Registered vehicles to this lead:</h4>
                  {vehicles.map((vehicle, index) => (
                    <div key={index}>
                      <h5>Vehicle # {index + 1}</h5>

                      {
                        // Render specific fields first
                        vehicleOrderFields.map((field) => {
                          let value = vehicle[field];
                          let displayKey = field;

                          if (field === "series") {
                            displayKey = "Trim";
                          }

                          if (value !== undefined) {
                            return (
                              <p key={field}>
                                <strong>
                                  {displayKey
                                    .replace(/_/g, " ")
                                    .replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )}
                                  :
                                </strong>{" "}
                                {value ?? "N/A"}
                              </p>
                            );
                          }
                          return null;
                        })
                      }

                      {
                        // Render other fields in the object
                        Object.entries(vehicle).map(([key, value]) => {
                          if (!vehicleOrderFields.includes(key)) {
                            return (
                              <p key={key}>
                                <>
                                  <strong>
                                    {key
                                      .replace(/_/g, " ")
                                      .replace(/\b\w/g, (char) =>
                                        char.toUpperCase()
                                      )}
                                    :
                                  </strong>{" "}
                                  {value ?? "N/A"}
                                </>
                              </p>
                            );
                          }
                          return null;
                        })
                      }

                      <hr />
                    </div>
                  ))}
                </>
              );
            } else {
              return null;
            }
          })()}
        </div>
      </Dialog>
    </>
  );
});

export default LeadModal;
