import React, { useEffect, useState } from "react";
import "./support_portal.css";
import SummaryAndFAQ from "./Summary";
import ModifyOffers from "./ModifyOffers";
import { Menu } from "primereact/menu";
import { confirmAlert } from "react-confirm-alert";
import Util from "../../util/Util";
import {
  activateDealership,
  addMyTrafficUser,
  deactivateDealership,
  getInactiveDealershipUsers,
  pauseDealership,
  resendMyTrafficUsersInvitation,
  restartDealership,
  sendDealershipSupportRequest,
  sendDealershipTags,
  updateDealershipReportRecipients,
  updateDealershipRespectedIpAddresses,
  updateDealershipUaGaIds,
  updateDealershipVendor,
} from "../../actions/supportPortalActions";
import CloseButton from "../../components/close_button/CloseButton";
import { Sidebar } from "primereact/sidebar";
import { IDealership, ISelectValue, IStore } from "../../../index.dts";
import TagsInput from "react-tagsinput";
import { Button } from "primereact/button";
import { shallowEqual, useSelector } from "react-redux";
import { PERMISSIONS, SupportRequestCategory } from "../../util/Enums";
import AsyncSelect from "react-select/async";
import { getWebsiteVendors } from "../../actions/globalSettingsActions";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import Select from "react-select";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { getDealership } from "../../actions/dealershipActions";
import PersistentChatStatistics from "../../components/persistent_chat_statistics/PersistentChatStatistics";
import {
  INPUT_TAGS_TYPES,
  TAG_INPUT_PLACEHOLDERS,
} from "../../util/tags_input";
import TagsInputUtil from "../../util/tags_input/TagsInputUtil";
import Info from "../../components/info/Info";
import { useTranslation } from "react-i18next";

enum MODE {
  SUMMARY = "SUMMARY",
  CHAT_STATS = "CHAT_STATS",
  MODIFY_OFFERS = "MODIFY_OFFERS",
}

export function SupportPortal() {
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  const [mode, setMode] = useState<MODE>(MODE.SUMMARY);

  const [editRecipients, setEditRecipients] = useState<boolean>(false);
  const [dailyLeadGroupInput, setDailyLeadGroupInput] = useState<string>("");
  const [leadSummaryRecipientsInput, setLeadSummaryRecipientsInput] =
    useState<string>("");
  const [
    performanceReportsRecipientsInput,
    setPerformanceReportsRecipientsInput,
  ] = useState<string>("");

  const [editIpAddress, setEditIpAddress] = useState<boolean>(false);
  const [editUaGaIds, setEditUaGaIds] = useState<boolean>(false);
  const [restrictedIpsInput, setRestrictedIpsInput] = useState<string>("");

  const [editVendor, setEditVendor] = useState<boolean>(false);
  const [websiteVendors, setWebsiteVendors] = useState<any[]>([]);

  const [editUsers, setEditUsers] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const [userEmailWarning, setUserEmailWarning] = useState<string>("");

  const [inviteUsers, setInviteUsers] = useState<boolean>(false);
  const [invitedUsers, setInvitedUsers] = useState<ISelectValue[]>([]);
  const [usersList, setUsersList] = useState<any[]>([]);

  const [editSupportRequest, setEditSupportRequest] = useState<boolean>(false);
  const [supportRequestCategory, setSupportRequestCategory] = useState<any>({
    label: Object.keys(SupportRequestCategory)[0],
    value: Object.values(SupportRequestCategory)[0],
  });
  const [supportRequestMessage, setSupportRequestMessage] =
    useState<string>("");

  const [tagsSidebarEnabled, setTagsSidebarEnabled] = useState<boolean>(false);
  const [resendEmails, setResendEmails] = useState<string[]>([]);
  const [resendEmailsInput, setResendEmailsInput] = useState<string>("");

  const [dealership, setDealership] = useState<IDealership>();

  useEffect(() => {
    if (dealershipContext.id) {
      Util.globalSpinner().show();
      getDealership(dealershipContext.id)
        .then((response) => {
          setDealership(response.data);
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => Util.globalSpinner().hide());
    }
  }, [dealershipContext.id]);

  const onPauseDealership = (): void => {
    if (dealership) {
      confirmAlert({
        title: `Pause Dealership!`,
        message: `Choosing this option will immediately PAUSE all Offers for "${dealership.name}". The Dealership and its data will be retained. Any applicable billing WILL CONTINUE.`,
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              Util.globalSpinner().show();
              pauseDealership(dealership.id)
                .then(() => {
                  dealership.paused = true;

                  forceUpdate();

                  Util.success(
                    `Dealership ${dealership.name} paused successfully.`
                  );
                })
                .catch((error) => {
                  Util.showError(error);
                })
                .finally(() => {
                  Util.globalSpinner().hide();
                });
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    } else {
      Util.warning("Invalid dealership context");
    }
  };

  const onRestartDealership = (): void => {
    if (dealership) {
      confirmAlert({
        title: `Restart Dealership!`,
        message: `Choosing this option will immediately START active Offers for "${dealership.name}".`,
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              Util.globalSpinner().show();
              restartDealership(dealership.id)
                .then(() => {
                  dealership.paused = false;

                  forceUpdate();
                  Util.success(
                    `Dealership ${dealership.name} restarted successfully.`
                  );
                })
                .catch((error) => {
                  Util.showError(error);
                })
                .finally(() => {
                  Util.globalSpinner().hide();
                });
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    } else {
      Util.warning("Invalid dealership context");
    }
  };

  const resendWeeklyReport = (): void => {
    if (dealership) {
      Util.resendWeeklyReport({
        id: dealership.id,
        name: dealership.name,
      });
    }
  };

  const activate = (): void => {
    if (dealership) {
      confirmAlert({
        title: `Activate Dealership!`,
        message: "Are you sure you want to do this?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              Util.globalSpinner().show();
              activateDealership(dealership.id)
                .then(() => {
                  dealership.deleted = false;
                  Util.success(
                    `Dealership ${dealership.name} activated successfully.`
                  );

                  forceUpdate();
                })
                .catch((error) => {
                  Util.showError(error);
                })
                .finally(() => {
                  Util.globalSpinner().hide();
                });
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    } else {
      Util.warning("Invalid dealership context");
    }
  };

  const deactivate = (): void => {
    if (dealership) {
      confirmAlert({
        title: `Deactivate Dealership!`,
        message: `Choosing this option will PERMANENTLY cancel and DELETE "${dealership.name}" and all of their data from the platform. If the dealership wishes to return - applicable Setup and 1st Month fees will apply. Any billing for this dealership will end immediately as well.`,
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              Util.globalSpinner().show();
              deactivateDealership(dealership.id)
                .then(() => {
                  dealership.deleted = true;
                  Util.success(
                    `Dealership ${dealership.name} deactivated successfully.`
                  );

                  forceUpdate();
                })
                .catch((error) => {
                  Util.showError(error);
                })
                .finally(() => {
                  Util.globalSpinner().hide();
                });
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    }
  };

  const onHideIpAddressSidebar = (): void => {
    setEditIpAddress(false);
    setRestrictedIpsInput("");
  };

  const onHideUaGaIds = (): void => {
    setEditUaGaIds(false);
  };

  const onSaveRestrictedIpAddress = (): void => {
    if (dealership) {
      Util.globalSpinner().show();

      updateDealershipRespectedIpAddresses(
        dealership.id,
        Util._.get(dealership, "restrictedIpsSet", [])
      )
        .then((response) => {
          onHideIpAddressSidebar();
          Util.success("Restricted Ip Address updated successfully.");
        })
        .catch((error) => Util.showError(error))
        .finally(() => Util.globalSpinner().hide());
    }
  };

  const onSaveUaGaIds = (): void => {
    if (dealership) {
      Util.globalSpinner().show();

      updateDealershipUaGaIds(
        dealership.id,
        Util._.get(dealership, "googleAnalyticsTrackingId")
      )
        .then((response) => {
          onHideUaGaIds();
          Util.success("UA/GA IDs updated successfully.");
        })
        .catch((error) => Util.showError(error))
        .finally(() => Util.globalSpinner().hide());
    }
  };

  const onHideRecipientsSidebar = (): void => {
    setEditRecipients(false);
    setDailyLeadGroupInput("");
    setLeadSummaryRecipientsInput("");
    setPerformanceReportsRecipientsInput("");
  };

  const onSaveReportRecipients = (): void => {
    if (dealership) {
      Util.globalSpinner().show();

      Util.addLastTag(
        Util._.get(dealership, "dailyLeadGroupSet", []),
        dailyLeadGroupInput,
        Util.emailRegex
      );
      Util.addLastTag(
        Util._.get(dealership, "leadSummaryRecipientsSet", []),
        leadSummaryRecipientsInput,
        Util.emailRegex
      );
      Util.addLastTag(
        Util._.get(dealership, "performanceReportsRecipientsSet", []),
        performanceReportsRecipientsInput,
        Util.emailRegex
      );

      updateDealershipReportRecipients(dealership.id, {
        dailyLeadGroupSet: Util._.get(dealership, "dailyLeadGroupSet", []),
        leadSummaryRecipientsSet: Util._.get(
          dealership,
          "leadSummaryRecipientsSet",
          []
        ),
        performanceReportsRecipientsSet: Util._.get(
          dealership,
          "performanceReportsRecipientsSet"
        ),
      })
        .then(() => {
          onHideRecipientsSidebar();
          Util.success("Weekly Report Recipients updated successfully.");
        })
        .catch((error) => Util.showError(error))
        .finally(() => Util.globalSpinner().hide());
    }
  };

  const onHideEditVendorSlide = (): void => {
    setEditVendor(false);
    setWebsiteVendors([]);
  };

  const promiseVendors = (inputValue: string): any =>
    new Promise((resolve) => {
      if (websiteVendors.length === 0) {
        getWebsiteVendors().then((response) => {
          resolve(
            response.data.map((item: any) => {
              return {
                value: item.id,
                label: item.value,
              };
            })
          );
          setWebsiteVendors(response.data);
        });
      } else {
        resolve(
          websiteVendors
            .filter((item: any) =>
              item.value.toLowerCase().includes(inputValue.toLowerCase())
            )
            .map((item: any) => {
              return {
                value: item.id,
                label: item.value,
              };
            })
        );
      }
    });

  const onWebsiteVendorChange = (value: number): void => {
    const index = websiteVendors.findIndex((item: any) => item.id === value);
    if (index !== -1) {
      setDealershipContextValue("websiteVendor", websiteVendors[index]);
    } else {
      Util.warning(Util.defaultErrorMessage);
    }
  };

  const onWebsiteVendorSave = (): void => {
    if (dealership) {
      Util.globalSpinner().show();
      updateDealershipVendor(dealership.id, dealership.websiteVendor)
        .then((response) => {
          onHideEditVendorSlide();
          Util.success("Website Vendor updated successfully.");
        })
        .catch((error) => Util.showError(error))
        .finally(() => Util.globalSpinner().hide());
    } else {
      Util.warning(Util.defaultErrorMessage);
    }
  };

  const onHideEditUsersSlide = (): void => {
    setEditUsers(false);
    setUserEmail("");
    setUserEmailWarning("");
  };

  const onSaveUserEmail = (): void => {
    if (dealership) {
      setUserEmailWarning("");
      if (Util.validateEmail(userEmail)) {
        Util.globalSpinner().show();
        addMyTrafficUser(dealership.id, userEmail.trim())
          .then((response) => {
            onHideEditUsersSlide();
            Util.success(`${userEmail} added successfully.`);
          })
          .catch((error) => Util.showError(error))
          .finally(() => Util.globalSpinner().hide());
      } else {
        setUserEmailWarning("Invalid email address.");
      }
    } else {
      Util.warning(Util.defaultErrorMessage);
    }
  };

  const promiseUsers = (inputValue: string): any =>
    new Promise((resolve) => {
      if (dealership) {
        if (usersList.length === 0) {
          getInactiveDealershipUsers(dealership.id).then((response) => {
            resolve(
              response.data.data.map((item: any) => {
                return {
                  value: item.id,
                  label: item.email,
                };
              })
            );
            setUsersList(response.data.data);
          });
        } else {
          resolve(
            usersList
              .filter((item: any) =>
                item.email.toLowerCase().includes(inputValue.toLowerCase())
              )
              .map((item: any) => {
                return {
                  value: item.id,
                  label: item.email,
                };
              })
          );
        }
      } else {
        resolve([]);
      }
    });

  const onHideInviteUsersSlide = (): void => {
    setInviteUsers(false);
    setUsersList([]);
    setInvitedUsers([]);
  };

  const onReInviteUsers = (): void => {
    if (dealership) {
      if (invitedUsers.length > 0) {
        Util.globalSpinner().show();
        resendMyTrafficUsersInvitation(invitedUsers.map((user) => user.value))
          .then((response) => {
            onHideInviteUsersSlide();
            Util.success("Users invited successfully.");
          })
          .catch((error) => Util.showError(error))
          .finally(() => Util.globalSpinner().hide());
      }
    } else {
      Util.warning(Util.defaultErrorMessage);
    }
  };

  const onHideSupportRequestSidebar = (): void => {
    setEditSupportRequest(false);
    setSupportRequestMessage("");
    setSupportRequestCategory({
      label: Object.keys(SupportRequestCategory)[0],
      value: Object.values(SupportRequestCategory)[0],
    });
  };

  const onSupportRequestSave = (): void => {
    if (dealership) {
      Util.globalSpinner().show();
      sendDealershipSupportRequest(
        dealership.id,
        supportRequestCategory.value,
        supportRequestMessage
      )
        .then((response) => {
          onHideSupportRequestSidebar();
          Util.success("Support Request sent successfully.");
        })
        .catch((error) => Util.showError(error))
        .finally(() => Util.globalSpinner().hide());
    } else {
      Util.warning(Util.defaultErrorMessage);
    }
  };

  const onHideTagsSidebar = (): void => {
    setTagsSidebarEnabled(false);
    setResendEmails([]);
    setResendEmailsInput("");
  };

  const onResendTags = (): void => {
    if (dealership) {
      Util.addLastTag(resendEmails, resendEmailsInput, Util.emailRegex);

      if (resendEmails.length > 0) {
        Util.globalSpinner().show();
        sendDealershipTags(dealership.id, resendEmails)
          .then((response) => {
            onHideTagsSidebar();
            Util.success("Tags sent successfully.");
          })
          .catch((error) => Util.showError(error))
          .finally(() => Util.globalSpinner().hide());
      } else {
        Util.warning("Please enter at least one email address.");
      }
    } else {
      Util.warning(Util.defaultErrorMessage);
    }
  };

  const setDealershipContextValue = (field: string, value: any): void => {
    Util._.set(dealership, field, value);
    forceUpdate();
  };

  const getDeactivateOrDeactivateButton = (): any => {
    if (dealership && !dealership.deleted) {
      return {
        label: (
          <div>
            <label>Cancel/Deactivate Dealership</label>
            <Info
              style={{ float: "right", marginLeft: 2 }}
              message={t("info.cancelDealership", {
                dealershipName: dealership.name,
              })}
            />
          </div>
        ),
        command: deactivate,
        disabled: !Util.hasAnyAuthority(
          PERMISSIONS.DEALERSHIP_STATUS_MANAGEMENT
        ),
      };
    }
    return {
      label: "Activate Dealership",
      command: activate,
      disabled: !Util.hasAnyAuthority(PERMISSIONS.DEALERSHIP_STATUS_MANAGEMENT),
    };
  };

  const getPauseOrRestartDealershipButton = () => {
    if (dealership && dealership.paused) {
      return {
        label: (
          <div>
            <label>Restart Dealership</label>
            <Info
              style={{ float: "right", marginLeft: 2 }}
              message={t("info.restartDealership", {
                dealershipName: dealership.name,
              })}
            />
          </div>
        ),
        command: onRestartDealership,
        disabled: !Util.hasAnyAuthority(PERMISSIONS.DEALERSHIP_PAUSE_OFFERS),
      };
    }
    return {
      label: (
        <div>
          <label>Pause Dealership</label>
          <Info
            style={{ float: "right", marginLeft: 2 }}
            message={t("info.pauseDealership", {
              dealershipName: dealership?.name,
            })}
          />
        </div>
      ),
      command: onPauseDealership,
      disabled: !Util.hasAnyAuthority(PERMISSIONS.DEALERSHIP_PAUSE_OFFERS),
    };
  };

  const renderByMode = (mode: MODE): any => {
    switch (mode) {
      case MODE.SUMMARY:
        return <SummaryAndFAQ dealership={dealership} />;
      case MODE.CHAT_STATS: {
        const dealershipId = Util._.get(dealership, "id", null);
        const dealershipName = Util._.get(dealership, "name", null);

        return (
          <PersistentChatStatistics
            dealershipId={dealershipId}
            dealershipName={dealershipName}
            persistentChatEnabled={dealershipContext.persistentChatEnabled}
          />
        );
      }
      case MODE.MODIFY_OFFERS:
        return <ModifyOffers dealership={dealership} />;
    }
  };

  return (
    <div id={"support-portal-view"}>
      <div className={"p-grid"}>
        <div className={"p-col-12 p-lg-3"}>
          <div className="card card-w-title datatable-demo">
            <Menu
              style={{ width: "100%" }}
              model={[
                {
                  label: "Dealership manage",
                  items: [
                    {
                      label: "Summary & FAQ's",
                      command: () => setMode(MODE.SUMMARY),
                    },
                    getDeactivateOrDeactivateButton(),
                    getPauseOrRestartDealershipButton(),
                    {
                      label: (
                        <div>
                          <label>Modify Offers</label>
                          <Info
                            style={{ float: "right", marginLeft: 2 }}
                            message={t("info.modifyOffers")}
                          />
                        </div>
                      ),
                      command: () => setMode(MODE.MODIFY_OFFERS),
                      disabled: !Util.hasAnyAuthority(
                        PERMISSIONS.OFFERS_ACCESS,
                        PERMISSIONS.DEALERSHIP_OFFER_TERMS_UPDATE,
                        PERMISSIONS.DEALERSHIP_OFFER_LIMITATION,
                        PERMISSIONS.DEALERSHIP_OFFER_EXPIRATION_UPDATE,
                        PERMISSIONS.DEALERSHIP_OFFER_ASSET_UPDATE,
                        PERMISSIONS.DEALERSHIP_OFFER_STATUS_UPDATE
                      ),
                    },
                    {
                      label: "Copy Verify Information Link to clipboard",
                      command: () => {
                        navigator.clipboard.writeText(
                          Util.getVerifyUrl(dealership) || ""
                        );
                        Util.success("Copied to clipboard!");
                      },
                    },
                    {
                      label: (
                        <div>
                          <label>UA and GA ID's</label>
                          <Info
                            style={{ float: "right", marginLeft: 2 }}
                            message={t("info.uaGaSupportPortal")}
                          />
                        </div>
                      ),
                      command: () => {
                        if (dealership) {
                          setEditUaGaIds(true);
                        }
                      },
                      disabled: !Util.hasAnyAuthority(
                        PERMISSIONS.DEALERSHIP_MANAGE_GA
                      ),
                    },
                  ],
                },
                {
                  label: "Ips, Emails, Admin",
                  items: [
                    {
                      label: (
                        <div>
                          <label>Add/Remove IP address</label>
                          <Info
                            style={{ float: "right", marginLeft: 2 }}
                            message={t("info.blockedIpAddresses")}
                          />
                        </div>
                      ),
                      command: () => {
                        if (dealership) {
                          setEditIpAddress(true);
                        }
                      },
                      disabled: !Util.hasAnyAuthority(
                        PERMISSIONS.DEALERSHIP_OFFER_RESTRICT_IP_ADDRESS
                      ),
                    },
                    {
                      label: (
                        <div>
                          <label>Add/Remove Weekly Report Recipients</label>
                          <Info
                            style={{ float: "right", marginLeft: 2 }}
                            message={t("info.weeklyReportRecipients")}
                          />
                        </div>
                      ),
                      command: () => {
                        if (dealership) {
                          setEditRecipients(true);
                        }
                      },
                      disabled: !Util.hasAnyAuthority(
                        PERMISSIONS.DEALERSHIP_WEEKLY_REPORTS
                      ),
                    },
                  ],
                },
                {
                  label: "Resend Tags",
                  items: [
                    {
                      label: "Change Website Vendor",
                      command: () => setEditVendor(true),
                      disabled: !Util.hasAnyAuthority(
                        PERMISSIONS.DEALERSHIP_VENDOR_UPDATE
                      ),
                    },
                    {
                      label: (
                        <div>
                          <label>Send Tags</label>
                          <Info
                            style={{ float: "right", marginLeft: 2 }}
                            message={t("info.sendTags")}
                          />
                        </div>
                      ),
                      command: () => setTagsSidebarEnabled(true),
                      disabled: !Util.hasAnyAuthority(
                        PERMISSIONS.DEALERSHIP_SEND_TAGS
                      ),
                    },
                  ],
                },
                {
                  label: "myTraffic",
                  items: [
                    {
                      label: "Add User",
                      command: () => setEditUsers(true),
                      disabled: !Util.hasAnyAuthority(PERMISSIONS.RTS_ADD_USER),
                    },
                    {
                      label: "Resend Invite",
                      command: () => setInviteUsers(true),
                      disabled: !Util.hasAnyAuthority(
                        PERMISSIONS.RTS_RESEND_INVITATION
                      ),
                    },
                    {
                      label: "Chat Stats",
                      command: () => setMode(MODE.CHAT_STATS),
                      disabled:
                        !Util.hasAnyAuthority(PERMISSIONS.RTS_STATISTICS) ||
                        !dealershipContext.persistentChatEnabled,
                    },
                  ],
                },
                {
                  label: "Request",
                  items: [
                    {
                      label: (
                        <div>
                          <label>Support Request</label>
                          <Info
                            style={{ float: "right", marginLeft: 2 }}
                            message={t("info.supportRequest")}
                          />
                        </div>
                      ),
                      command: () => setEditSupportRequest(true),
                      disabled: !Util.hasAnyAuthority(
                        PERMISSIONS.DEALERSHIP_SUPPORT_REQUEST
                      ),
                    },
                    {
                      label: "Resend Weekly Report",
                      command: resendWeeklyReport,
                      disabled: !Util.hasAnyAuthority(
                        PERMISSIONS.DEALERSHIP_RESEND_WEEKLY_REPORT
                      ),
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
        <div className={"p-col-12 p-lg-9"}>{renderByMode(mode)}</div>
      </div>
      <Sidebar
        style={{ width: 350 }}
        position={"right"}
        visible={editIpAddress}
        onHide={onHideIpAddressSidebar}
        showCloseIcon={false}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <CloseButton onHide={onHideIpAddressSidebar} />
          <div
            style={{
              marginTop: 25,
              marginLeft: -15,
              marginRight: -15,
              borderBottom: "1px solid #afa6a6",
            }}
          >
            <label style={{ fontSize: 20, marginLeft: 20 }}>
              Add/Remove IP Address
            </label>
          </div>
          <div style={{ padding: 5, height: "100%", width: "100%" }}>
            <div className={"sidebar-item"}>
              <TagsInput
                onlyUnique={true}
                validationRegex={Util.ipAddressRegex}
                inputValue={restrictedIpsInput}
                value={Util._.get(dealership, "restrictedIpsSet", []) || []}
                onValidationReject={(tags) =>
                  TagsInputUtil.onValidationReject(
                    tags,
                    INPUT_TAGS_TYPES.IP_ADDRESS
                  )
                }
                inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                onChangeInput={(value: string) =>
                  setRestrictedIpsInput(value?.trim())
                }
                onChange={(value: string[]) =>
                  setDealershipContextValue(
                    "restrictedIpsSet",
                    TagsInputUtil.trimValues(value)
                  )
                }
              />
            </div>
            <div className={"sidebar-item"}>
              <Button
                label={"Save"}
                style={{ float: "right" }}
                onClick={onSaveRestrictedIpAddress}
              />
            </div>
          </div>
        </div>
      </Sidebar>
      <Sidebar
        style={{ width: 350 }}
        position={"right"}
        visible={editUaGaIds}
        onHide={onHideUaGaIds}
        showCloseIcon={false}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <CloseButton onHide={onHideUaGaIds} />
          <div
            style={{
              marginTop: 25,
              marginLeft: -15,
              marginRight: -15,
              borderBottom: "1px solid #afa6a6",
            }}
          >
            <label style={{ fontSize: 20, marginLeft: 20 }}>
              Add/Remove UA/GA IDs
            </label>
          </div>
          <div style={{ padding: 5, height: "100%", width: "100%" }}>
            <div className={"sidebar-item"}>
              <InputText
                style={{ width: "100%" }}
                value={dealership?.googleAnalyticsTrackingId}
                placeholder={"UA-XXXXXX-YY, G-XXXXXX-YY"}
                onChange={(e: any) => {
                  setDealershipContextValue(
                    "googleAnalyticsTrackingId",
                    e.target.value
                  );
                }}
              />
            </div>
            <div className={"sidebar-item"}>
              <Button
                className="no-icon-buttons"
                label={"Save"}
                style={{ float: "right" }}
                onClick={onSaveUaGaIds}
              />
            </div>
          </div>
        </div>
      </Sidebar>
      <Sidebar
        style={{ width: 350 }}
        position={"right"}
        visible={editRecipients}
        onHide={onHideRecipientsSidebar}
        showCloseIcon={false}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <CloseButton onHide={onHideRecipientsSidebar} />
          <div
            style={{
              marginTop: 25,
              marginLeft: -15,
              marginRight: -15,
              borderBottom: "1px solid #afa6a6",
            }}
          >
            <label style={{ fontSize: 20, marginLeft: 20 }}>
              Add/Remove Weekly Report Recipients
            </label>
          </div>
          <div style={{ padding: 5, height: "100%", width: "100%" }}>
            <div className={"sidebar-item"}>
              <label>Performance Reports Recipients</label>
              <TagsInput
                onlyUnique={true}
                validationRegex={Util.emailRegex}
                onValidationReject={(tags) =>
                  Util.warning(`"${tags[0]}" is not a valid e-mail address.`)
                }
                inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                inputValue={performanceReportsRecipientsInput}
                value={
                  Util._.get(
                    dealership,
                    "performanceReportsRecipientsSet",
                    []
                  ) || []
                }
                onChangeInput={(value: string) =>
                  setPerformanceReportsRecipientsInput(value)
                }
                onChange={(value: string[]) =>
                  setDealershipContextValue(
                    "performanceReportsRecipientsSet",
                    TagsInputUtil.trimValues(value)
                  )
                }
              />
            </div>
            <div className={"sidebar-item"}>
              <label>Lead Summary Recipients</label>
              <TagsInput
                onlyUnique={true}
                validationRegex={Util.emailRegex}
                onValidationReject={(tags) =>
                  TagsInputUtil.onValidationReject(tags, INPUT_TAGS_TYPES.EMAIL)
                }
                inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                inputValue={leadSummaryRecipientsInput}
                value={
                  Util._.get(dealership, "leadSummaryRecipientsSet", []) || []
                }
                onChangeInput={(value: string) =>
                  setLeadSummaryRecipientsInput(value)
                }
                onChange={(value: string[]) =>
                  setDealershipContextValue(
                    "leadSummaryRecipientsSet",
                    TagsInputUtil.trimValues(value)
                  )
                }
              />
            </div>
            <div className={"sidebar-item"}>
              <label>Daily Lead Group</label>
              <TagsInput
                onlyUnique={true}
                validationRegex={Util.emailRegex}
                onValidationReject={(tags) =>
                  TagsInputUtil.onValidationReject(tags, INPUT_TAGS_TYPES.EMAIL)
                }
                inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                inputValue={dailyLeadGroupInput}
                value={Util._.get(dealership, "dailyLeadGroupSet", []) || []}
                onChangeInput={(value: string) => setDailyLeadGroupInput(value)}
                onChange={(value: string[]) =>
                  setDealershipContextValue(
                    "dailyLeadGroupSet",
                    TagsInputUtil.trimValues(value)
                  )
                }
              />
            </div>
            <div className={"sidebar-item"}>
              <Button
                label={"Save"}
                style={{ float: "right" }}
                onClick={onSaveReportRecipients}
              />
            </div>
          </div>
        </div>
      </Sidebar>
      <Sidebar
        style={{ width: 350 }}
        position={"right"}
        visible={editVendor}
        onHide={onHideEditVendorSlide}
        showCloseIcon={false}
      >
        {editVendor ? (
          <div style={{ width: "100%", height: "100%" }}>
            <CloseButton onHide={onHideEditVendorSlide} />
            <div
              style={{
                marginTop: 25,
                marginLeft: -15,
                marginRight: -15,
                borderBottom: "1px solid #afa6a6",
              }}
            >
              <label style={{ fontSize: 20, marginLeft: 20 }}>
                Change Website Vendor
              </label>
            </div>
            <div style={{ padding: 5, height: "100%", width: "100%" }}>
              <div className={"sidebar-item"}>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  defaultValue={
                    dealership?.websiteVendor
                      ? {
                          label: Util._.get(
                            dealership,
                            "websiteVendor.value",
                            ""
                          ),
                          value: Util._.get(dealership, "websiteVendor.id", 0),
                        }
                      : null
                  }
                  loadOptions={promiseVendors}
                  onChange={(event: any) => onWebsiteVendorChange(event.value)}
                  placeholder={"Select Vendor"}
                />
              </div>
              <div className={"sidebar-item"}>
                <Button
                  label={"Save"}
                  style={{ float: "right" }}
                  onClick={onWebsiteVendorSave}
                />
              </div>
            </div>
          </div>
        ) : null}
      </Sidebar>
      <Sidebar
        style={{ width: 350 }}
        position={"right"}
        visible={editUsers}
        onHide={onHideEditUsersSlide}
        showCloseIcon={false}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <CloseButton onHide={onHideEditUsersSlide} />
          <div
            style={{
              marginTop: 25,
              marginLeft: -15,
              marginRight: -15,
              borderBottom: "1px solid #afa6a6",
            }}
          >
            <label style={{ fontSize: 20, marginLeft: 20 }}>Add User</label>
          </div>
          <div style={{ padding: 5, height: "100%", width: "100%" }}>
            <div className={"sidebar-item"}>
              <InputText
                value={userEmail}
                style={{ width: "100%" }}
                placeholder={"Add email"}
                onChange={(e: any) => setUserEmail(e.target.value)}
              />
              <span className={"red"}>{userEmailWarning}</span>
            </div>
            <div className={"sidebar-item"}>
              <Button
                label={"Save"}
                style={{ float: "right" }}
                onClick={onSaveUserEmail}
              />
            </div>
          </div>
        </div>
      </Sidebar>
      <Sidebar
        style={{ width: 350 }}
        position={"right"}
        visible={inviteUsers}
        onHide={onHideInviteUsersSlide}
        showCloseIcon={false}
      >
        {inviteUsers ? (
          <div style={{ width: "100%", height: "100%" }}>
            <CloseButton onHide={onHideInviteUsersSlide} />
            <div
              style={{
                marginTop: 25,
                marginLeft: -15,
                marginRight: -15,
                borderBottom: "1px solid #afa6a6",
              }}
            >
              <label style={{ fontSize: 20, marginLeft: 20 }}>
                Resend Invite
              </label>
            </div>
            <div style={{ padding: 5, height: "100%", width: "100%" }}>
              <div className={"sidebar-item"}>
                <AsyncSelect
                  isMulti={true}
                  closeMenuOnSelect={false}
                  cacheOptions
                  defaultOptions
                  value={invitedUsers}
                  className={"multi-select"}
                  defaultValue={undefined}
                  loadOptions={promiseUsers}
                  onChange={(values: any) => setInvitedUsers(values || [])}
                />
              </div>
              <div className={"sidebar-item"}>
                <Button
                  label={"Save"}
                  style={{ float: "right" }}
                  onClick={onReInviteUsers}
                />
              </div>
            </div>
          </div>
        ) : null}
      </Sidebar>
      <Sidebar
        style={{ width: 350 }}
        position={"right"}
        visible={editSupportRequest}
        onHide={onHideSupportRequestSidebar}
        showCloseIcon={false}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <CloseButton onHide={onHideSupportRequestSidebar} />
          <div
            style={{
              marginTop: 25,
              marginLeft: -15,
              marginRight: -15,
              borderBottom: "1px solid #afa6a6",
            }}
          >
            <label style={{ fontSize: 20, marginLeft: 20 }}>
              Support Request
            </label>
          </div>
          <div style={{ padding: 5, height: "100%", width: "100%" }}>
            <div className={"sidebar-item"}>
              <div>
                <span>Category</span>
                <Select
                  value={supportRequestCategory}
                  options={Object.entries(SupportRequestCategory).map(
                    (entry) => {
                      return {
                        label: entry[0],
                        value: entry[1],
                      };
                    }
                  )}
                  onChange={setSupportRequestCategory}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <span>
                  * Provide as much detail as possible. Be as specific as
                  possible so that we may investigate, resolve, or respond to
                  your issue/support request efficiently.
                </span>
                <InputTextarea
                  value={supportRequestMessage}
                  onChange={(e: any) =>
                    setSupportRequestMessage(e.target.value)
                  }
                  style={{
                    width: "100%",
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: 100,
                    maxHeight: 500,
                  }}
                />
              </div>
            </div>
            <div className={"sidebar-item"}>
              <Button
                label={"Submit"}
                style={{ float: "right" }}
                onClick={onSupportRequestSave}
              />
            </div>
          </div>
        </div>
      </Sidebar>
      <Sidebar
        style={{ width: 350 }}
        position={"right"}
        visible={tagsSidebarEnabled}
        onHide={onHideTagsSidebar}
        showCloseIcon={false}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <CloseButton onHide={onHideTagsSidebar} />
          <div
            style={{
              marginTop: 25,
              marginLeft: -15,
              marginRight: -15,
              borderBottom: "1px solid #afa6a6",
            }}
          >
            <label style={{ fontSize: 20, marginLeft: 20 }}>Resend Tags</label>
          </div>
          <div style={{ padding: 5, height: "100%", width: "100%" }}>
            <div className={"sidebar-item"}>
              <TagsInput
                onlyUnique={true}
                validationRegex={Util.emailRegex}
                onValidationReject={(tags) =>
                  Util.warning(`"${tags[0]}" is not a valid e-mail address.`)
                }
                inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                inputValue={resendEmailsInput}
                value={resendEmails}
                onChangeInput={(value: string) =>
                  setResendEmailsInput(value?.trim())
                }
                onChange={(value: string[]) =>
                  setResendEmails(TagsInputUtil.trimValues(value))
                }
              />
            </div>
            <div className={"sidebar-item"}>
              <Button
                label={"Send"}
                style={{ float: "right" }}
                onClick={onResendTags}
              />
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default SupportPortal;
