import React, { useEffect, useRef, useState } from "react";
import { CAMPAIGN_FILTER_TYPE, VIEW_BY_SELECTOR } from "../../../util/Enums";
import { getCarsListRequest } from "../../../actions/campaignsActions";
import { shallowEqual, useSelector } from "react-redux";
import {
  BrandTypeMap,
  CarsListTable,
  IStore,
  VinTypeMap,
} from "../../../../index.dts";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Util from "../../../util/Util";
import { Checkbox } from "primereact/checkbox";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { IDS } from "../../../views/constants";
import { IVdpPerformanceFilterData } from "./VdpPerformanceView";
import { InputSwitch } from "primereact/inputswitch";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: "y" as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    title: {
      display: true,
      text: "VDP Performance View",
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

interface IProps {
  vinTypeMap: any;
  onClickApply: (data: any) => void;
  applyDropdowns: BrandTypeMap;
  filter: IVdpPerformanceFilterData;
  onlyVdps: boolean;
  setOnlyVdps: (onlyVdps: boolean) => void;
}

const HorizontalBarVDP = (props: IProps) => {
  const [vdpCheckboxes, setVdpCheckboxes] = useState<any[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [selectedModel, setSelectedModel] = useState<string>("");
  const [selectedTrim, setSelectedTrim] = useState<string>("");
  const [selectedVin, setSelectedVin] = useState<string>("");
  const forceUpdate = useForceUpdate();
  const brands = Object.keys(props?.applyDropdowns);
  const years = Object.values(props?.applyDropdowns)?.filter(
    (brand) => brand && brand.brandName == selectedBrand
  )[0]?.years;
  const models =
    years &&
    Object.values(years)?.filter((year) => year && year.year == selectedYear)[0]
      ?.models;
  const trims =
    models &&
    Object.values(models)?.filter(
      (model) => model && model.modelName == selectedModel
    )[0]?.trims;
  const vins =
    trims &&
    Object.values(trims)?.filter(
      (trim) => trim && trim.trimName == selectedTrim
    )[0]?.vins;

  useEffect(() => {
    setSelectedBrand("");
    setSelectedYear("");
    setSelectedModel("");
    setSelectedTrim("");
    setSelectedVin("");
  }, [props.filter, props.onlyVdps]);

  const labels = Object.keys(props?.vinTypeMap ?? []);
  const data = {
    labels,
    datasets: [
      {
        label: "Views",
        data: props?.vinTypeMap
          ? Object.values(props.vinTypeMap).map(
              (vinObject: any) => vinObject.total
            )
          : [],
        borderColor: "rgb(117, 116, 116)",
        backgroundColor: "rgba(117, 116, 116, 0.5)",
      },
      {
        label: "Native Leads",
        data: props?.vinTypeMap
          ? Object.values(props.vinTypeMap).map(
              (vinObject: any) => vinObject?.leadStatsDto?.nativeLeads
            )
          : [],
        borderColor: "rgb(179, 171, 171)",
        backgroundColor: "rgba(179, 171, 171, 0.5)",
      },
      {
        label: "Offer Leads",
        data: props?.vinTypeMap
          ? Object.values(props.vinTypeMap).map(
              (vinObject: any) => vinObject?.leadStatsDto?.offerLeads
            )
          : [],
        borderColor: "rgb(225, 127, 22)",
        backgroundColor: "rgba(225, 127, 22, 0.5)",
      },
      {
        label: "Total Leads",
        data: props?.vinTypeMap
          ? Object.values(props.vinTypeMap).map(
              (vinObject: any) => vinObject?.leadStatsDto?.totalLeads
            )
          : [],
        borderColor: "rgb(13, 87, 181)",
        backgroundColor: "rgba(13, 87, 181, 0.5)",
      },
    ],
  };

  const onApply = () => {
    let data = {
      brand: selectedBrand,
      year: selectedYear,
      model: selectedModel,
      trim: selectedTrim,
      vin: selectedVin,
    };
    props.onClickApply(data);
  };

  return (
    <div className="brands-grouping-div">
      <div className="horizontal-bar-dropdowns">
        <Dropdown
          disabled={props.onlyVdps == true}
          className="bar-dropdown"
          showClear={true}
          placeholder="Brand"
          options={brands.map((entry) => {
            return {
              label: entry,
              value: entry,
            };
          })}
          value={selectedBrand}
          onChange={(e: any) => {
            setSelectedBrand(e.target.value);
            setSelectedYear("");
            setSelectedModel("");
            setSelectedTrim("");
            setSelectedVin("");
          }}
        />
        <Dropdown
          className="bar-dropdown"
          disabled={selectedBrand == undefined || selectedBrand == ""}
          showClear={true}
          placeholder="Year"
          options={
            years &&
            Object.keys(years).map((entry) => {
              return {
                label: entry,
                value: entry,
              };
            })
          }
          value={selectedYear}
          onChange={(e: any) => {
            setSelectedYear(e.target.value);
            setSelectedModel("");
            setSelectedTrim("");
            setSelectedVin("");
          }}
        />
        <Dropdown
          disabled={
            selectedBrand == undefined ||
            selectedBrand == "" ||
            selectedYear == undefined ||
            selectedYear == ""
          }
          className="bar-dropdown"
          showClear={true}
          placeholder="Model"
          options={
            models &&
            Object.keys(models).map((entry) => {
              return {
                label: entry,
                value: entry,
              };
            })
          }
          value={selectedModel}
          onChange={(e: any) => {
            setSelectedModel(e.target.value);
            setSelectedTrim("");
            setSelectedVin("");
          }}
        />
        <Dropdown
          disabled={
            selectedBrand == undefined ||
            selectedBrand == "" ||
            selectedYear == undefined ||
            selectedYear == "" ||
            selectedModel == undefined ||
            selectedModel == ""
          }
          className="bar-dropdown"
          showClear={true}
          placeholder="Trim"
          options={
            trims &&
            Object.keys(trims).map((entry) => {
              return {
                label: entry,
                value: entry,
              };
            })
          }
          value={selectedTrim}
          onChange={(e: any) => {
            setSelectedTrim(e.target.value);
            setSelectedVin("");
          }}
        />
        <Dropdown
          disabled={
            selectedBrand == undefined ||
            selectedBrand == "" ||
            selectedYear == undefined ||
            selectedYear == "" ||
            selectedModel == undefined ||
            selectedModel == "" ||
            selectedTrim == undefined ||
            selectedTrim == ""
          }
          className="bar-dropdown"
          showClear={true}
          placeholder="Vin"
          options={
            vins &&
            Object.keys(vins).map((entry) => {
              return {
                label: entry,
                value: entry,
              };
            })
          }
          value={selectedVin}
          onChange={(e: any) => {
            setSelectedVin(e.target.value);
          }}
        />
        <Button
          disabled={props.onlyVdps == true}
          className="bar-dropdown"
          style={{ margin: "5px 0" }}
          id={IDS.button.apply}
          onClick={onApply}
          label="Apply"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginRight: "10px",
          }}
        >
          <label className={"input-label"}>Set VDPs</label>
          <InputSwitch
            id={IDS.dealershipsForm.switch.enableChat}
            checked={props.onlyVdps}
            onChange={() => props.setOnlyVdps(!props.onlyVdps)}
            placeholder="Set VDPs"
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
        {/* {selectedTrim !== "" && selectedTrim !== undefined && (
          <div className="vdp-checkboxes">
            {labels &&
              labels.map((label, index) => {
                return (
                  <div key={index}>
                    <Checkbox
                      checked={
                        vdpCheckboxes.findIndex((m) => m === label) !== -1
                      }
                      onChange={(e: any) => {
                        const index = vdpCheckboxes.findIndex(
                          (m, key) => m === label
                        );
                        if (index !== -1) {
                          vdpCheckboxes.splice(index, 1);
                        } else vdpCheckboxes.push(label);

                        forceUpdate();
                      }}
                    />
                  </div>
                );
              })}
          </div>
        )} */}
        <Bar options={options} data={data} />
      </div>
    </div>
  );
};

export default HorizontalBarVDP;
