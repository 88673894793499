import {
  IChannelBuckets,
  IChartDataset,
  ISmartSpendData,
} from "../../../../index.dts";

import { ColoredDataset } from "../../../util/chart/dataset/colored_dataset";
import { IChartAnnotation } from "../../../util/chart/annotation/chart_annotation";
import { ISmartSpendFilterData } from "../../smart_spend_filter/SmartSpendFilter";
import React, { useState } from "react";
import { SEMVendorService } from "../../../services/sem_vendor/sem_vendor_service";
import { SmartSpendChart } from "../SmartSpendChart";
import { SmartSpendChartService } from "../smart_spend_chart_service";
import { forEach } from "lodash";
import { ProductTypes } from "../../../util/enum/product_types";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

interface IProps {
  readonly annotations?: IChartAnnotation[];
  readonly data?: ISmartSpendData;
  readonly filter: ISmartSpendFilterData;
  readonly scale: {
    readonly id: string;
    readonly label: string;
  };
  readonly semVendors: any[];
}

export function SmartSpendSEMVendorsChart(props: IProps) {
  const [selectedAll, setSelectedAll] = useState<boolean>(true);
  const service = new SmartSpendChartService();
  const semVendorService = new SEMVendorService();

  /**
   * HOOKS
   */

  const { t } = useTranslation();

  function getDatasets(): IChartDataset[] {
    const datasets: IChartDataset[] = [];

    forEach(props.data?.semVendors, (value: any, key: string) => {
      datasets.push(
        new ColoredDataset({
          label: getVendorName(key),
          data: getFilteredData(value),
          yAxisID: props.scale.id,
        })
      );
    });

    return datasets;
  }

  function getFilteredData(source: any): number[] {
    return service.getFilteredData(
      source,
      props.filter,
      props.data?.dates?.length
    );
  }

  function getVendorName(key: string): string {
    return (
      semVendorService.getSEMVendorName({
        key: key,
        options: props.semVendors,
      }) ?? key
    );
  }

  function getSemVendorBuckets(props: IProps): IChannelBuckets | undefined {
    if (props.filter.product?.type !== ProductTypes.all) {
      return undefined;
    }

    if (
      props.filter.options?.makes &&
      Object.keys(props.filter.options.makes).length !== 0
    ) {
      return undefined;
    }

    return {
      total: props.data?.semVendorBuckets?.paid,
    };
  }

  return (
    <>
      <div
        style={{
          marginBottom: "0.5rem",
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          id="apply-btn"
          onClick={() => setSelectedAll(!selectedAll)}
          className="no-icon-buttons"
          label={selectedAll ? t("unselectAll") : t("selectAll")}
        />
      </div>
      <SmartSpendChart
        selectedAll={selectedAll}
        annotations={props.annotations}
        data={props.data}
        channelBuckets={getSemVendorBuckets(props)}
        datasets={getDatasets()}
        scale={props.scale}
      />
    </>
  );
}
