import React, { useEffect, useState } from "react";
import "./vehicle_exchange_dealership_profile.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  getProfileConfiguration,
  createProfileConfiguration,
  updateProfileConfiguration,
} from "../../actions/vexActions";
import Util from "../../util/Util";
import FileUploadCustom from "../../util/file_upload_custom/FileUploadCustom";
import { IVexProfileConfiguration } from "../../../index.dts";
import { IDS } from "../../views/constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IExistingVexProfileConfig } from "../../../index.dts";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import TagsInputUtil from "../../util/tags_input/TagsInputUtil";
import {
  INPUT_TAGS_TYPES,
  TAG_INPUT_PLACEHOLDERS,
} from "../../util/tags_input";

import { Panel } from "primereact/panel";
import Select from "react-select";
import { Button } from "primereact/button";
import "react-tagsinput/react-tagsinput.css";
import TagsInput from "react-tagsinput";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import EmailTypes from "../../util/enum/email_types";
import { EmailRadioFilter } from "../../components/radio_filter/EmailRadioFilter";
import { InputSwitch } from "primereact/inputswitch";
import { ExportToCsv } from "export-to-csv";
import Info from "../../components/info/Info";

export function VehicleExchangeDealershipProfile(props: any) {
  const { t }: any = useTranslation();
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);
  const [vexProfileConfiguration, setVexProfileConfiguration] =
    useState<IVexProfileConfiguration>({
      apptReminderSendTime: "",
      dealerNotificationTo: "",
      declinedAcquisitionCadence: "",
      acquisitionExclusionYears: "",
      acquisitionExclusionMakes: "",
      acquisitionExclusionModels: "",
      acquisitionExclusionROTypes: "",
      acquisitionExclusionOPCodes: "",
      reminderEmailBodyContent: "",
      reminderEmailOffers: "",
      customerEmailTemplateUrl: "",
      dealershipEmailTemplateUrl: "",
      acquisitionEmailTemplateUrl: "",
      dealershipExternalName: "",
      smtpConfigurationHost: "",
      smtpConfigurationEmailAddress: "",
      smtpConfigurationUsername: "",
      smtpConfigurationPassword: "",
      smtpConfigurationPort: 0,
      ignoreVehiclesUnder: 0,
      replyToEmails: "",
      dynamicEmail: "",
      emailType: true,
      usedCarManagerName: "",
      sendOfferVehiclePurchasedAfterDays: 60,
      authenticomEnabled: false,
      authenticomId: "",
      includeCsv: false,
    });

  // const [dealer] = useState(Util._.get(props, "location.state.dealership"));

  const [existingVexProfileConfig, setExistingVexProfileConfig] =
    useState<IExistingVexProfileConfig>({
      id: 0,
      dealershipId: 0,
      apptReminderSendTime: "",
      dealerNotificationTo: "",
      declinedAcquisitionCadence: "",
      acquisitionExclusionYears: "",
      acquisitionExclusionMakes: "",
      acquisitionExclusionModels: "",
      acquisitionExclusionROTypes: "",
      acquisitionExclusionOPCodes: "",
      reminderEmailBodyContent: "",
      reminderEmailOffers: "",
      customerEmailTemplateUrl: "",
      dealershipEmailTemplateUrl: "",
      acquisitionEmailTemplateUrl: "",
      dealershipExternalName: "",
      smtpConfigurationHost: "",
      smtpConfigurationEmailAddress: "",
      smtpConfigurationUsername: "",
      smtpConfigurationPassword: "",
      smtpConfigurationPort: 0,
      ignoreVehiclesUnder: 0,
      replyToEmails: "",
      emailType: true,
      dynamicEmail: "",
      usedCarManagerName: "",
      sendOfferVehiclePurchasedAfterDays: 60,
      authenticomEnabled: false,
      authenticomId: "",
      includeCsv: false,
    });

  const [emailSMTP, setEmailSMT] = useState<any>(
    existingVexProfileConfig?.emailType
      ? EmailTypes.email
      : EmailTypes.emailSMTP
  );

  const [warnings] = useState<{ [key: string]: string }>({
    apptReminderSendTime: "",
    dealerNotificationTo: "",
    declinedAcquisitionCadence: "",
    acquisitionExclusionYears: "",
    acquisitionExclusionMakes: "",
    acquisitionExclusionModels: "",
    acquisitionExclusionROTypes: "",
    acquisitionExclusionOPCodes: "",
    reminderEmailBodyContent: "",
    reminderEmailOffers: "",
    customerEmailTemplateUrl: "",
    dealershipEmailTemplateUrl: "",
    acquisitionEmailTemplateUrl: "",
    dealershipExternalName: "",
    smtpConfigurationHost: "",
    smtpConfigurationEmailAddress: "",
    smtpConfigurationUsername: "",
    smtpConfigurationPassword: "",
    smtpConfigurationPort: "",
    dynamicEmail: "",
    sendOfferVehiclePurchasedAfterDays: "",
  });

  useEffect(() => {
    return () => {
      Util.removeAlert();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props?.dealer?.id !== 0) {
      getConfig(props?.dealer?.id);
    }
    // eslint-disable-next-line
  }, [props?.dealer]);

  useEffect(() => {
    Object.entries(existingVexProfileConfig).forEach((field) => {
      setValue(field[0], field[1]);
    });
    // eslint-disable-next-line
  }, [existingVexProfileConfig]);

  const getConfig = (dealershipId: number) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      getProfileConfiguration(dealershipId)
        .then((response) => {
          setExistingVexProfileConfig({
            id: response.data.id || 0,
            dealershipId: response.data.dealershipId || 0,
            apptReminderSendTime: response.data.apptReminderSendTime || "",
            dealerNotificationTo: response.data.dealerNotificationTo || "",
            declinedAcquisitionCadence:
              response.data.declinedAcquisitionCadence || "",
            acquisitionExclusionYears:
              response.data.acquisitionExclusionYears || "",
            acquisitionExclusionMakes:
              response.data.acquisitionExclusionMakes || "",
            acquisitionExclusionModels:
              response.data.acquisitionExclusionModels || "",
            acquisitionExclusionROTypes:
              response.data.acquisitionExclusionROTypes || "",
            acquisitionExclusionOPCodes:
              response.data.acquisitionExclusionOPCodes || "",
            reminderEmailBodyContent:
              response.data.reminderEmailBodyContent || "",
            reminderEmailOffers: response.data.reminderEmailOffers || "",
            customerEmailTemplateUrl:
              response.data.customerEmailTemplateUrl || "",
            dealershipEmailTemplateUrl:
              response.data.dealershipEmailTemplateUrl || "",
            acquisitionEmailTemplateUrl:
              response.data.acquisitionEmailTemplateUrl || "",
            dealershipExternalName: response.data.dealershipExternalName || "",
            smtpConfigurationHost: response.data.smtpConfiguration?.host || "",
            smtpConfigurationPort: response.data.smtpConfiguration?.port || 0,
            smtpConfigurationEmailAddress:
              response.data.smtpConfiguration?.emailAddress || "",
            smtpConfigurationUsername:
              response.data.smtpConfiguration?.username || "",
            smtpConfigurationPassword: "NULLFROMAPI",
            ignoreVehiclesUnder: response.data.ignoreVehiclesUnder || 0,
            replyToEmails: response.data.replyToEmails || "",
            emailType: response.data.emailType || false,
            dynamicEmail: response.data.dynamicEmail || "",
            usedCarManagerName: response.data.usedCarManagerName || "",
            sendOfferVehiclePurchasedAfterDays:
              response.data.sendOfferVehiclePurchasedAfterDays || 60,
            authenticomEnabled: response.data.authenticomEnabled || false,
            authenticomId: response.data.authenticomId || "",
            includeCsv: response.data.includeCsv || false,
          });
          setEmailSMT(
            response?.data?.emailType ? EmailTypes.email : EmailTypes.emailSMTP
          );
        })
        .catch((error) => {
          setExistingVexProfileConfig({
            id: 0,
            dealershipId: 0,
            apptReminderSendTime: "",
            dealerNotificationTo: "",
            declinedAcquisitionCadence: "",
            acquisitionExclusionYears: "",
            acquisitionExclusionMakes: "",
            acquisitionExclusionModels: "",
            acquisitionExclusionROTypes: "",
            acquisitionExclusionOPCodes: "",
            reminderEmailBodyContent: "",
            reminderEmailOffers: "",
            customerEmailTemplateUrl: "",
            dealershipEmailTemplateUrl: "",
            acquisitionEmailTemplateUrl: "",
            dealershipExternalName: "",
            smtpConfigurationHost: "",
            smtpConfigurationPort: 0,
            smtpConfigurationEmailAddress: "",
            smtpConfigurationUsername: "",
            smtpConfigurationPassword: "",
            ignoreVehiclesUnder: 0,
            replyToEmails: "",
            emailType: true,
            dynamicEmail: "",
            usedCarManagerName: "",
            sendOfferVehiclePurchasedAfterDays: 60,
            authenticomEnabled: false,
            authenticomId: "",
            includeCsv: false,
          });
          setVexProfileConfiguration({
            apptReminderSendTime: "",
            dealerNotificationTo: "",
            declinedAcquisitionCadence: "",
            acquisitionExclusionYears: "",
            acquisitionExclusionMakes: "",
            acquisitionExclusionModels: "",
            acquisitionExclusionROTypes: "",
            acquisitionExclusionOPCodes: "",
            reminderEmailBodyContent: "",
            reminderEmailOffers: "",
            customerEmailTemplateUrl: "",
            dealershipEmailTemplateUrl: "",
            acquisitionEmailTemplateUrl: "",
            dealershipExternalName: "",
            smtpConfigurationHost: "",
            smtpConfigurationEmailAddress: "",
            smtpConfigurationUsername: "",
            smtpConfigurationPassword: "",
            smtpConfigurationPort: 0,
            ignoreVehiclesUnder: 0,
            replyToEmails: "",
            dynamicEmail: "",
            emailType: true,
            usedCarManagerName: "",
            sendOfferVehiclePurchasedAfterDays: 60,
            authenticomEnabled: false,
            authenticomId: "",
            includeCsv: false,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const setValue = (field: string, value: any) => {
    // @ts-ignore
    if (field == "smtpConfigurationEmailAddress") {
      if (!Util.isEmpty(value) && !Util.validateEmail(value)) {
        setWarningValue(field, t("validation.email"));
      } else {
        warnings[field] = "";
      }
      vexProfileConfiguration[field] = value;
      forceUpdate();
    } else if (field == "smtpConfigurationPort") {
      if (value === null || value === "") {
        value = 0;
      }
      vexProfileConfiguration[field] = value;
      forceUpdate();
    } else {
      if (value === null) {
        value = "";
      }
      vexProfileConfiguration[field] = value;
      forceUpdate();
    }
  };

  const setWarningValue = (
    field: string,
    value?: string,
    disableUpdate?: boolean
  ) => {
    if (value) {
      warnings[field] = value;
    }

    if (!disableUpdate) {
      forceUpdate();
    }
  };

  const validateFields = (field: string, value: string): string | undefined => {
    switch (field) {
      case "apptReminderSendTime":
        if (Util.isEmpty(value)) {
          return t(
            "vehicleExchange.vexProfileConfiguration.apptReminderSendTimeValidation"
          );
        }
        break;
      case "dealerNotificationTo":
        if (Util.isEmpty(value)) {
          return t(
            "vehicleExchange.vexProfileConfiguration.dealerNotificationToValidation"
          );
        }
        break;
      case "smtpConfigurationEmailAddress":
      case "declinedAcquisitionCadence":
      case "acquisitionExclusionYears":
      case "acquisitionExclusionMakes":
      case "acquisitionExclusionModels":
      case "acquisitionExclusionROTypes":
      case "acquisitionExclusionOPCodes":
      case "reminderEmailBodyContent":
      case "reminderEmailOffers":
      case "customerEmailTemplateUrl":
      case "dealershipEmailTemplateUrl":
      case "acquisitionEmailTemplateUrl":
      case "dealershipExternalName":
      case "smtpConfigurationHost":
      case "smtpConfigurationUsername":
      case "smtpConfigurationPassword":
      case "smtpConfigurationPort":
      case "dynamicEmail":
      case "emailType":
      case "usedCarManagerName":
    }

    return;
  };

  const declinedValues: {
    label: string;
    value: string;
  }[] = [
    {
      label: "30",
      value: "30",
    },
    {
      label: "60",
      value: "60",
    },
    {
      label: "90",
      value: "90",
    },
    {
      label: "120",
      value: "120",
    },
    {
      label: "180",
      value: "180",
    },
  ];

  const onSave = () => {
    let vexConfObj = {
      ...vexProfileConfiguration,
    };
    const validateArray = Object.entries(vexConfObj).map((entire: any) => {
      const warningString = validateFields(entire[0], entire[1]);
      setWarningValue(entire[0], warningString, true);
      return {
        field: entire[0],
        warningString: warningString,
      };
    });

    if (validateArray.some((item) => item.warningString?.trim())) {
      forceUpdate();
      Util.warning(t("vehicleExchange.vexProfileConfiguration.invalidInputs"));
      return;
    }

    if (existingVexProfileConfig.id != 0) {
      onEdit(vexProfileConfiguration);
    } else {
      onCreate(vexProfileConfiguration);
    }
  };

  const onCreate = (vexProfileConfig: IVexProfileConfiguration) => {
    Util.globalSpinner().show();
    const dto: any = { ...vexProfileConfig };
    createProfileConfiguration(props?.dealer?.id, dto)
      .then((response) => {
        history.push(Util.PATH_NAMES.DEALERSHIPS);
        Util.globalSpinner().hide();
        Util.success(
          t("vehicleExchange.vexProfileConfiguration.createdSuccessfully")
        );
      })
      .catch((error) => {
        Util.globalSpinner().hide();
        Util.error(error.response.data.message);
      });
  };

  const onEdit = (vexProfileConfig: IVexProfileConfiguration) => {
    Util.globalSpinner().show();
    const dto: any = {
      apptReminderSendTime: vexProfileConfig.apptReminderSendTime,
      dealerNotificationTo: vexProfileConfig.dealerNotificationTo,
      declinedAcquisitionCadence: vexProfileConfig.declinedAcquisitionCadence,
      acquisitionExclusionYears: vexProfileConfig.acquisitionExclusionYears,
      acquisitionExclusionMakes: vexProfileConfig.acquisitionExclusionMakes,
      acquisitionExclusionModels: vexProfileConfig.acquisitionExclusionModels,
      acquisitionExclusionROTypes: vexProfileConfig.acquisitionExclusionROTypes,
      acquisitionExclusionOPCodes: vexProfileConfig.acquisitionExclusionOPCodes,
      reminderEmailBodyContent: vexProfileConfig.reminderEmailBodyContent,
      reminderEmailOffers: vexProfileConfig.reminderEmailOffers,
      customerEmailTemplateUrl: vexProfileConfig.customerEmailTemplateUrl,
      dealershipEmailTemplateUrl: vexProfileConfig.dealershipEmailTemplateUrl,
      acquisitionEmailTemplateUrl: vexProfileConfig.acquisitionEmailTemplateUrl,
      dealershipExternalName: vexProfileConfig.dealershipExternalName,
      smtpConfigurationHost: vexProfileConfig.smtpConfigurationHost,
      smtpConfigurationEmailAddress:
        vexProfileConfig.smtpConfigurationEmailAddress,
      smtpConfigurationUsername: vexProfileConfig.smtpConfigurationUsername,
      smtpConfigurationPassword:
        vexProfileConfig.smtpConfigurationPassword == "NULLFROMAPI"
          ? ""
          : vexProfileConfig.smtpConfigurationPassword,
      smtpConfigurationPort: vexProfileConfig.smtpConfigurationPort,
      ignoreVehiclesUnder: vexProfileConfig.ignoreVehiclesUnder,
      replyToEmails: vexProfileConfig.replyToEmails,
      dynamicEmail: vexProfileConfig.dynamicEmail,
      emailType: vexProfileConfig.emailType,
      usedCarManagerName: vexProfileConfig.usedCarManagerName,
      sendOfferVehiclePurchasedAfterDays:
        vexProfileConfig.sendOfferVehiclePurchasedAfterDays,
      authenticomEnabled: vexProfileConfig.authenticomEnabled,
      authenticomId: vexProfileConfig.authenticomId,
      includeCsv: vexProfileConfig.includeCsv,
    };

    updateProfileConfiguration(props?.dealer?.id, dto)
      .then((response) => {
        history.push(Util.PATH_NAMES.DEALERSHIPS);
        Util.globalSpinner().hide();
        Util.success(
          t("vehicleExchange.vexProfileConfiguration.updatedSuccessfully")
        );
      })
      .catch((error) => {
        Util.globalSpinner().hide();
        Util.error(error.response.data.message);
      });
  };

  const getDeclinedCadenceValue = () => {
    if (vexProfileConfiguration.declinedAcquisitionCadence) {
      return {
        label: vexProfileConfiguration.declinedAcquisitionCadence,
        value: vexProfileConfiguration.declinedAcquisitionCadence,
      };
    }
    return;
  };

  const onHideExportModal = (): void => {
    setUploadModalVisible(false);
  };

  const handleUploadSubmit = (e) => {
    e.preventDefault();
    setUploadModalVisible(true);
  };

  const onUpload = (e: { xhr: XMLHttpRequest; files: any }): void => {
    Util.success("File uploaded successfully.");
  };

  const launchIOffer = () => {
    history.push(Util.PATH_NAMES.IOFFER_PORTAL);
  };

  /**
   * Called when "Enable Authenticom option is switched"
   */
  const onUseAuthenticomSwitch = (el: any): void => {
    const value = el.target.value;

    if (value) {
      setValue("authenticomEnabled", true);
    } else {
      setValue("authenticomEnabled", false);
    }

    forceUpdate();
  };

  /**
   * Called when "Send CSV option is switched"
   */
  const onSendCSVSwitch = (el: any): void => {
    const value = el.target.value;

    if (value) {
      setValue("includeCsv", true);
    } else {
      setValue("includeCsv", false);
    }

    forceUpdate();
  };

  const generateTemplate = (): void => {
    const csvExporter = new ExportToCsv({
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: false,
      useKeysAsHeaders: true,
      filename: "template",
    });

    csvExporter.generateCsv([
      {
        '"vin"': "1C4AJWAGXJL855739",
        '"vehicle"': "2018 JEEP WRANGLER JK",
        '"customerName"': "JOHN",
        '"customerEmail"': "johndoe@mail.com",
        '"customerCellphone"': "(786) 964-8506",
        '"dateSold"': "04-03-2024",
        '"salePrice"': "20000",
      },
    ]);
  };

  return (
    <div id={"assets-view"}>
      <div className={"p-grid"}>
        <div className="p-col-12">
          <Panel
            id={
              IDS.vehicleExchangeDealershipProfile.panel
                .vehicleExchangeDealerhsipProfile
            }
            key={"My Acquisitions - Service Setup"}
            style={{ marginTop: 10 }}
            header={
              <div style={{ height: 30, width: "100%", display: "flex" }}>
                <div
                  style={{
                    height: 30,
                    width: "45%",
                    padding: 8,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {t("vehicleExchange.vehicleExchangeProfile") +
                    " | " +
                    props?.dealer?.name}
                </div>
                <div style={{ height: 30, width: "55%" }}>
                  <Button
                    style={{ float: "right" }}
                    icon={"pi pi-upload"}
                    label={"Upload Sales Feed"}
                    title={"Upload Sales Feed"}
                    onClick={handleUploadSubmit}
                  />
                </div>
              </div>
            }
          >
            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.apptReminderSendTime")}
                  </label>
                  <span className={"red"}>*</span>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <TagsInput
                  onlyUnique={true}
                  validationRegex={Util.hour24Regex}
                  onValidationReject={(tags) =>
                    TagsInputUtil.onValidationReject(
                      tags,
                      INPUT_TAGS_TYPES.HOUR_24_FORMAT
                    )
                  }
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(
                    vexProfileConfiguration.apptReminderSendTime
                  )}
                  onChange={(e: any) =>
                    setValue(
                      "apptReminderSendTime",
                      TagsInputUtil.setUniqueValue(e)
                    )
                  }
                  aria-describedby="send-time-help"
                />
                <small id="apptReminder-help">
                  Enter only the hour in 24H format. E.g: For 5:00 pm type 17
                </small>
                <label className={"warning-label"}>{warnings.sendTime}</label>
              </div>

              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.dealerNotificationsTo")}
                  </label>
                  <span className={"red"}>*</span>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <TagsInput
                  onlyUnique={true}
                  validationRegex={Util.emailRegex}
                  onValidationReject={(tags) =>
                    TagsInputUtil.onValidationReject(
                      tags,
                      INPUT_TAGS_TYPES.EMAIL
                    )
                  }
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(
                    vexProfileConfiguration.dealerNotificationTo
                  )}
                  onChange={(value: string[]) =>
                    setValue("dealerNotificationTo", TagsInputUtil.join(value))
                  }
                />
                <label className={"warning-label"}>
                  {warnings.dealerNotificationsTo}
                </label>
              </div>
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.declinedAcquisitionCadence")}
                  </label>
                  <span className={"red"}>*</span>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <Select
                  id={
                    IDS.vehicleExchangeDealershipProfile.inputs
                      .declinedAcquisitionCadence
                  }
                  // filter={true}
                  options={declinedValues}
                  value={getDeclinedCadenceValue()}
                  // style={{ width: "100%", boxShadow: "none" }}
                  onChange={(item: any) => {
                    setValue("declinedAcquisitionCadence", item.value);
                    forceUpdate();
                  }}
                />
                <label className={"warning-label"}>
                  {warnings.declinedAcquisitionCadence}
                </label>
              </div>

              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.dealershipExternalName")}
                  </label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <InputText
                  id={IDS.inputs.name}
                  style={{ width: "100%" }}
                  value={vexProfileConfiguration.dealershipExternalName}
                  name={"name"}
                  placeholder={"Dealerships External Name"}
                  onChange={(e: any) =>
                    setValue("dealershipExternalName", e.target.value)
                  }
                />
                <small id="apptReminder-help">
                  If this value is empty, it will take the Dealership Name by
                  default.
                </small>
              </div>
            </div>

            <div className="p-grid">
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div
                  className={"label-container"}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: 10,
                    alignItems: "start",
                  }}
                >
                  Send CSV{" "}
                  <Info
                    style={{ float: "right", marginLeft: 2, cursor: "pointer" }}
                    message={t("sendCSVAAService")}
                  />
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <InputSwitch
                  id={IDS.vehicleExchangeDealershipProfile.inputs.includeCSV}
                  checked={vexProfileConfiguration.includeCsv}
                  onChange={onSendCSVSwitch}
                />
              </div>

              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.useAuthenticom")}
                  </label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <InputSwitch
                  id={
                    IDS.vehicleExchangeDealershipProfile.inputs
                      .enableAuthenticom
                  }
                  checked={vexProfileConfiguration.authenticomEnabled}
                  onChange={onUseAuthenticomSwitch}
                />
              </div>
            </div>
            {vexProfileConfiguration.authenticomEnabled && (
              <div className="p-grid">
                <div className={"p-col-3 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>&nbsp;</label>
                  </div>
                </div>
                <div className={"p-col-3 p-lg-3"}>&nbsp;</div>

                <div className={"p-col-3 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      {t("vehicleExchange.authenticomId")}
                    </label>
                  </div>
                </div>
                <div className={"p-col-3 p-lg-3"}>
                  <InputText
                    id={
                      IDS.vehicleExchangeDealershipProfile.inputs.authenticomId
                    }
                    style={{ width: "100%" }}
                    value={vexProfileConfiguration.authenticomId || ""}
                    onChange={(e: any) =>
                      setValue("authenticomId", e.target.value)
                    }
                  />
                </div>
              </div>
            )}

            <div className="p-grid">
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.ignoreVehiclesUnder")}
                  </label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"} style={{ display: "flex" }}>
                <div style={{ marginLeft: -18, padding: 5 }}>$</div>
                <InputNumber
                  id={
                    IDS.vehicleExchangeDealershipProfile.inputs
                      .ignoreVehiclesUnder
                  }
                  format={true}
                  min={0}
                  max={2147483648}
                  style={{ width: "100%", height: 35 }}
                  value={vexProfileConfiguration.ignoreVehiclesUnder}
                  placeholder={"Ignore Vehicles Under"}
                  onChange={(e: any) =>
                    setValue("ignoreVehiclesUnder", e.value)
                  }
                />
              </div>

              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.replyToEmails")}
                  </label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <TagsInput
                  onlyUnique={true}
                  validationRegex={Util.emailRegex}
                  onValidationReject={(tags) =>
                    TagsInputUtil.onValidationReject(
                      tags,
                      INPUT_TAGS_TYPES.EMAIL
                    )
                  }
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(
                    vexProfileConfiguration.replyToEmails
                  )}
                  onChange={(value: string[]) =>
                    setValue("replyToEmails", TagsInputUtil.join(value))
                  }
                />
              </div>
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.usedCarManagerName")}
                  </label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <InputText
                  style={{ width: "100%" }}
                  value={vexProfileConfiguration.usedCarManagerName || ""}
                  placeholder={t("vehicleExchange.usedCarManagerName")}
                  name="name"
                  onChange={(e: any) =>
                    setValue("usedCarManagerName", e.target.value)
                  }
                />
              </div>

              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.sendOfferVehiclePurchasedAfterDays")}
                  </label>
                  <span className={"red"}>*</span>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <InputNumber
                  id={
                    IDS.vehicleExchangeDealershipProfile.inputs
                      .sendOfferVehiclePurchasedAfterDays
                  }
                  format={false}
                  min={0}
                  max={2147483648}
                  style={{ width: "100%" }}
                  value={
                    vexProfileConfiguration.sendOfferVehiclePurchasedAfterDays ||
                    0
                  }
                  placeholder={"Send Offer After Days"}
                  onChange={(e: any) =>
                    setValue("sendOfferVehiclePurchasedAfterDays", e.value)
                  }
                />
                <small>
                  The offer will be send to the customer if the vehicle was
                  purchased after the number of days set.
                </small>
              </div>
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-6 p-lg-6 p-label-col"}>
                <h3>Acquisition Exclusions</h3>
              </div>
              <EmailRadioFilter
                name={"product-type-radio"}
                onChange={(value: number) => {
                  setEmailSMT(value);
                  setVexProfileConfiguration((prev) => {
                    return {
                      ...prev,
                      emailType: value == EmailTypes.email ? true : false,
                    };
                  });
                }}
                options={[
                  {
                    label: "Email",
                    value: EmailTypes.email,
                  },
                  {
                    label: "Email SMTP Configuration",
                    value: EmailTypes.emailSMTP,
                  },
                ]}
                selected={emailSMTP}
              />
              <div className={"p-col-6 p-lg-6 p-label-col"}></div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.acquisitionExclusionYears")}
                  </label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <TagsInput
                  onlyUnique={true}
                  validationRegex={Util.yearRegex}
                  onValidationReject={(tags) =>
                    TagsInputUtil.onValidationReject(
                      tags,
                      INPUT_TAGS_TYPES.YEAR
                    )
                  }
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(
                    vexProfileConfiguration.acquisitionExclusionYears
                  )}
                  onChange={(value: string[]) =>
                    setValue(
                      "acquisitionExclusionYears",
                      TagsInputUtil.join(value)
                    )
                  }
                />
                <label className={"warning-label"}>
                  {warnings.acquisitionExclusionYears}
                </label>
              </div>
              {emailSMTP == EmailTypes.email && (
                <>
                  <div className="p-col-6 p-lg-6">
                    <div className="p-inputgroup">
                      <InputText
                        placeholder="Email"
                        value={vexProfileConfiguration.dynamicEmail}
                        onChange={(e: any) => {
                          setValue("dynamicEmail", e.target.value);
                        }}
                      />
                      <span className="p-inputgroup-addon">
                        @bestpriceformycar.ai
                      </span>
                    </div>
                    <label className={"warning-label"}>
                      {warnings.dynamicEmail}
                    </label>
                  </div>
                </>
              )}
              {emailSMTP == EmailTypes.emailSMTP && (
                <>
                  <div className={"p-col-3 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        {t("vehicleExchange.smtpConfigurationHost")}
                      </label>
                      <span className={"red"}>*</span>
                    </div>
                  </div>
                  <div className={"p-col-3 p-lg-3"}>
                    <InputText
                      id={
                        IDS.vehicleExchangeDealershipProfile.inputs
                          .smtpConfigurationHost
                      }
                      style={{ width: "100%" }}
                      value={vexProfileConfiguration.smtpConfigurationHost}
                      name={"name"}
                      placeholder={"Host"}
                      onChange={(e: any) =>
                        setValue("smtpConfigurationHost", e.target.value)
                      }
                    />
                    <label className={"warning-label"}>
                      {warnings.smtpConfigurationHost}
                    </label>
                  </div>
                </>
              )}
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.acquisitionExclusionMakes")}
                  </label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <TagsInput
                  onlyUnique={true}
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(
                    vexProfileConfiguration.acquisitionExclusionMakes
                  )}
                  onChange={(value: string[]) =>
                    setValue(
                      "acquisitionExclusionMakes",
                      TagsInputUtil.join(value)
                    )
                  }
                />
                <label className={"warning-label"}>
                  {warnings.acquisitionExclusionMakes}
                </label>
              </div>

              {emailSMTP == EmailTypes.emailSMTP && (
                <>
                  <div className={"p-col-3 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        {t("vehicleExchange.smtpConfigurationPort")}
                      </label>
                      <span className={"red"}>*</span>
                    </div>
                  </div>
                  <div className={"p-col-3 p-lg-3"}>
                    <InputNumber
                      id={
                        IDS.vehicleExchangeDealershipProfile.inputs
                          .smtpConfigurationPort
                      }
                      format={false}
                      min={-2147483648}
                      max={2147483648}
                      style={{ width: "100%" }}
                      value={vexProfileConfiguration.smtpConfigurationPort}
                      placeholder={"Port"}
                      onChange={(e: any) =>
                        setValue("smtpConfigurationPort", e.value)
                      }
                    />
                    <label className={"warning-label"}>
                      {warnings.smtpConfigurationPort}
                    </label>
                  </div>
                </>
              )}
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.acquisitionExclusionModels")}
                  </label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <TagsInput
                  onlyUnique={true}
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(
                    vexProfileConfiguration.acquisitionExclusionModels
                  )}
                  onChange={(value: string[]) =>
                    setValue(
                      "acquisitionExclusionModels",
                      TagsInputUtil.join(value)
                    )
                  }
                />
                <label className={"warning-label"}>
                  {warnings.acquisitionExclusionModels}
                </label>
              </div>
              {emailSMTP == EmailTypes.emailSMTP && (
                <>
                  <div className={"p-col-3 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        {t("vehicleExchange.smtpConfigurationEmailAddress")}
                      </label>
                      <span className={"red"}>*</span>
                    </div>
                  </div>
                  <div className={"p-col-3 p-lg-3"}>
                    <InputText
                      id={
                        IDS.vehicleExchangeDealershipProfile.inputs
                          .smtpConfigurationEmailAddress
                      }
                      style={{ width: "100%" }}
                      value={
                        vexProfileConfiguration.smtpConfigurationEmailAddress
                      }
                      name={"name"}
                      placeholder={"Email Address"}
                      onChange={(e: any) =>
                        setValue(
                          "smtpConfigurationEmailAddress",
                          e.target.value
                        )
                      }
                    />
                    <label className={"warning-label"}>
                      {warnings.smtpConfigurationEmailAddress}
                    </label>
                  </div>
                </>
              )}
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.acquisitionExclusionROType")}
                  </label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <TagsInput
                  onlyUnique={true}
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(
                    vexProfileConfiguration.acquisitionExclusionROTypes
                  )}
                  onChange={(value: string[]) =>
                    setValue(
                      "acquisitionExclusionROTypes",
                      TagsInputUtil.join(value)
                    )
                  }
                />
                <label className={"warning-label"}>
                  {warnings.acquisitionExclusionROType}
                </label>
              </div>
              {emailSMTP == EmailTypes.emailSMTP && (
                <>
                  <div className={"p-col-3 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        {t("vehicleExchange.smtpConfigurationUsername")}
                      </label>
                      <span className={"red"}>*</span>
                    </div>
                  </div>
                  <div className={"p-col-3 p-lg-3"}>
                    <InputText
                      id={
                        IDS.vehicleExchangeDealershipProfile.inputs
                          .smtpConfigurationUsername
                      }
                      style={{ width: "100%" }}
                      value={vexProfileConfiguration.smtpConfigurationUsername}
                      name={"name"}
                      placeholder={"Username"}
                      onChange={(e: any) =>
                        setValue("smtpConfigurationUsername", e.target.value)
                      }
                    />
                    <label className={"warning-label"}>
                      {warnings.smtpConfigurationUsername}
                    </label>
                  </div>
                </>
              )}
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-3 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.acquisitionExclusionOPCodes")}
                  </label>
                </div>
              </div>
              <div className={"p-col-3 p-lg-3"}>
                <TagsInput
                  onlyUnique={true}
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(
                    vexProfileConfiguration.acquisitionExclusionOPCodes
                  )}
                  onChange={(value: string[]) =>
                    setValue(
                      "acquisitionExclusionOPCodes",
                      TagsInputUtil.join(value)
                    )
                  }
                />

                <label className={"warning-label"}>
                  {warnings.acquisitionExclusionOPCodes}
                </label>
              </div>
              {emailSMTP == EmailTypes.emailSMTP && (
                <>
                  <div className={"p-col-3 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        {t("vehicleExchange.smtpConfigurationPassword")}
                      </label>
                      {existingVexProfileConfig.id == 0 && (
                        <span className={"red"}>*</span>
                      )}
                    </div>
                  </div>
                  <div className={"p-col-3 p-lg-3"}>
                    <Password
                      disabled={false}
                      style={{ width: "100%" }}
                      feedback={false}
                      placeholder={"Password"}
                      value={vexProfileConfiguration.smtpConfigurationPassword}
                      onChange={(e: any) =>
                        setValue("smtpConfigurationPassword", e.target.value)
                      }
                    />
                    <label className={"warning-label"}>
                      {warnings.smtpConfigurationPassword}
                    </label>
                  </div>
                </>
              )}
            </div>

            <div className={"p-grid"}>
              <>
                <div className={"p-col-3 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>{"iOffer Settings"}</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-3 p-lg-3"}>
                  <Button
                    label={"Launch iOffer"}
                    onClick={launchIOffer}
                    className={"p-button-secondary no-icon-buttons"}
                  />
                  <label className={"warning-label"}>
                    {warnings.smtpConfigurationEmailAddress}
                  </label>
                </div>
              </>
            </div>

            <h3>Reminder Email Content</h3>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.customerEmailTemplateUrl")}
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <TagsInput
                  onlyUnique={true}
                  validationRegex={Util.urlRegex}
                  onValidationReject={(tags) =>
                    TagsInputUtil.onValidationReject(tags, INPUT_TAGS_TYPES.URL)
                  }
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(
                    vexProfileConfiguration.customerEmailTemplateUrl
                  )}
                  onChange={(e: any) =>
                    setValue(
                      "customerEmailTemplateUrl",
                      TagsInputUtil.setUniqueValue(e)
                    )
                  }
                  aria-describedby="send-time-help"
                />
                <small id="customerEmailTemplateUrl-help">
                  Unlayer URL format is
                  'https://api.unlayer.com/v2/templates/TEMPLATE_ID/render' when
                  TEMPLATE_ID is the ID of the Unlayer's template.
                  <br />
                  If this value is empty, it will set the default Unlayer
                  template.
                </small>
                <label className={"warning-label"}>
                  {warnings.customerEmailTemplateUrl}
                </label>
              </div>
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.dealershipEmailTemplateUrl")}
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <TagsInput
                  onlyUnique={true}
                  validationRegex={Util.urlRegex}
                  onValidationReject={(tags) =>
                    TagsInputUtil.onValidationReject(tags, INPUT_TAGS_TYPES.URL)
                  }
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(
                    vexProfileConfiguration.dealershipEmailTemplateUrl
                  )}
                  onChange={(e: any) =>
                    setValue(
                      "dealershipEmailTemplateUrl",
                      TagsInputUtil.setUniqueValue(e)
                    )
                  }
                  aria-describedby="send-time-help"
                />
                <small id="dealershipEmailTemplateUrl-help">
                  Unlayer URL format is
                  'https://api.unlayer.com/v2/templates/TEMPLATE_ID/render' when
                  TEMPLATE_ID is the ID of the Unlayer's template.
                  <br />
                  If this value is empty, it will set the default Unlayer
                  template.
                </small>
                <label className={"warning-label"}>
                  {warnings.dealershipEmailTemplateUrl}
                </label>
              </div>
            </div>

            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.acquisitionEmailTemplateUrl")}
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <TagsInput
                  onlyUnique={true}
                  validationRegex={Util.urlRegex}
                  onValidationReject={(tags) =>
                    TagsInputUtil.onValidationReject(tags, INPUT_TAGS_TYPES.URL)
                  }
                  inputProps={{ placeholder: TAG_INPUT_PLACEHOLDERS.DEFAULT }}
                  value={Util.getStringArray(
                    vexProfileConfiguration.acquisitionEmailTemplateUrl
                  )}
                  onChange={(e: any) =>
                    setValue(
                      "acquisitionEmailTemplateUrl",
                      TagsInputUtil.setUniqueValue(e)
                    )
                  }
                  aria-describedby="send-time-help"
                />
                <small id="acquisitionEmailTemplateUrl-help">
                  Unlayer URL format is
                  'https://api.unlayer.com/v2/templates/TEMPLATE_ID/render' when
                  TEMPLATE_ID is the ID of the Unlayer's template.
                  <br />
                  If this value is empty, it will set the default Unlayer
                  template.
                </small>
                <label className={"warning-label"}>
                  {warnings.acquisitionEmailTemplateUrl}
                </label>
              </div>
            </div>
            <div className="p-grid">
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>
                    {t("vehicleExchange.emailBodyContent")}
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <InputTextarea
                  id={
                    IDS.vehicleExchangeDealershipProfile.inputs.emailBodyContent
                  }
                  value={vexProfileConfiguration.reminderEmailBodyContent}
                  style={{
                    width: "100%",
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: 35,
                  }}
                  onChange={(e: any) =>
                    setValue("reminderEmailBodyContent", e.target.value)
                  }
                />
              </div>
            </div>
            <Dialog
              modal={true}
              visible={uploadModalVisible}
              onHide={onHideExportModal}
              header={"Upload sales feed for: " + props?.dealer?.name}
              style={{ width: "60vw", height: "70vh" }}
            >
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-12 p-label-col"}>
                  <div style={{ padding: 5, marginBottom: "20px" }}>
                    <p>
                      To import a list of Sold Vehicles, create a
                      spreadsheet(.csv) with the following columns:
                    </p>
                    <p>
                      vin, vehicle, customerName, customerEmail,
                      customerCellphone, dateSold, salePrice
                    </p>

                    <img
                      style={{ width: "50%" }}
                      alt={"..."}
                      src={"assets/images/sales_feed_template.png"}
                    />

                    <div style={{ marginTop: 5, marginBottom: 10 }}>
                      <label
                        style={{ cursor: "pointer", color: "#39a3f4" }}
                        onClick={generateTemplate}
                      >
                        <i
                          style={{ marginRight: 5 }}
                          className={"pi pi-download"}
                        />
                        <span> Download Template</span>
                      </label>
                    </div>
                  </div>
                  <FileUploadCustom
                    name="file"
                    url={`${process.env.REACT_APP_BASE_URL}/dealerships/${props?.dealer?.id}/upload-sales-feed`}
                    multiple={false}
                    accept=".csv"
                    maxFileSize={10000000}
                    withCredentials={true}
                  />
                </div>
              </div>
            </Dialog>
          </Panel>
          <div style={{ marginTop: 10 }}>
            <Button
              id={IDS.button.save}
              onClick={() => onSave()}
              label={"Save"}
              icon={"pi pi-save"}
            />
            <Button
              id={IDS.button.back}
              style={{ marginRight: 5 }}
              icon={"pi pi-arrow-left"}
              className={"p-button-warning"}
              label={"Back"}
              onClick={() => history.push(Util.PATH_NAMES.DEALERSHIPS)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleExchangeDealershipProfile;
