export enum SubscriptionTypes {
    subscribe,
    unsubscribe,
}

export default SubscriptionTypes;

export class SubscriptionTypesUtil {
  public static getName(value?: SubscriptionTypes): string {
    let name;

    switch (value) {
      case SubscriptionTypes.subscribe:
        name = "subscribe";
        break;
      case SubscriptionTypes.unsubscribe:
        name = "unsubscribe";
        break;
    }

    return name;
  }
}