import React, { useImperativeHandle, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { IChatGreeting, IDealershipVisitor, IStore } from "../../../index.dts";
import { Dialog } from "primereact/dialog";
import { getChatGreetings } from "../../actions/chatGreetingActions";
import Util from "../../util/Util";
import "./chat_greetings_modal.css";
import { DataView } from "primereact/dataview";

interface IProps {
  onSelect(visitor?: IDealershipVisitor, chatGreeting?: IChatGreeting): void;
}

const ChatGreetingsModal = React.forwardRef((props: IProps, ref: any) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [values, setValues] = useState<IChatGreeting[]>([]);
  const [pending, setPending] = useState<boolean>(false);
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  const [first, setFirst] = useState(0);
  const [limit, setLimit] = useState(12);
  const [visitor, setVisitor] = useState<IDealershipVisitor>();
  const [totalElements, setTotalElements] = useState(0);

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }));

  const show = (visitor: IDealershipVisitor) => {
    setVisitor(visitor);
    getData(0, limit).then((response: any) => {
      if (response.data.totalElements > 1) {
        setVisible(true);
      } else if (response.data.totalElements === 1) {
        onSelect(visitor, response.data.content[0]);
      } else {
        onSelect(visitor);
      }
    });
  };

  const hide = () => {
    setFirst(0);
    setValues([]);
    setVisible(false);
    setVisitor(undefined);
  };

  const itemTemplate = (product: any, layout: string) => {
    if (!product) {
      return <div />;
    }

    return renderGridItem(product);
  };

  const renderGridItem = (data: IChatGreeting) => {
    return (
      <div className={"p-col-12 p-xl-3 p-lg-4 p-md-6"}>
        <div
          onClick={() => onSelect(visitor, data)}
          className={"rts-template-item"}
        >
          <div
            id={"rts-template-chat-permission-container"}
            style={{ backgroundColor: `#${data.backgroundColor}` }}
          >
            <img
              id={"rts-template-chat-permission-close-svg"}
              src={"assets/icons/close.svg"}
              alt={""}
            />
            <div id={"rts-template-chat-permission-header"}>{data.name}</div>
            <div id={"rts-template-chat-permission-question-container"}>
              <div id={"rts-template-chat-permission-question-header"}>
                {data.message}
              </div>
              <div
                id={"rts-template-chat-permission-question-button"}
                style={{ backgroundColor: `#${data.buttonBackgroundColor}` }}
              >
                <div id={"rts-template-chat-permission-question-yes"}>
                  <span>
                    {data.buttonLabel}{" "}
                    <img
                      id={"rts-template-chat-permission-question-arrow"}
                      src={"assets/icons/arrow.svg"}
                      alt={""}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getData = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      setPending(true);
      getChatGreetings(dealershipContext.id, {
        filter: [],
        sorting: [],
        paging: {
          page: page,
          pageLimit: limit,
        },
      })
        .then((response) => {
          setValues(response.data.content);
          setTotalElements(response.data.totalElements);

          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => setPending(false));
    });

  const onSelect = (
    visitor?: IDealershipVisitor,
    chatGreeting?: IChatGreeting
  ) => {
    hide();

    props.onSelect(visitor, chatGreeting);
  };

  const onPage = (originalEvent: any) => {
    getData(originalEvent.page, originalEvent.rows).finally(() => {
      setLimit(originalEvent.rows);
      setFirst(originalEvent.first);
    });
  };

  return (
    <Dialog
      id={"chat_greetings_modal"}
      visible={visible}
      onHide={hide}
      style={{ width: "70vw" }}
      header={`Chat Greetings`}
    >
      <DataView
        lazy={true}
        paginator={true}
        loading={pending}
        rowsPerPageOptions={[12, 20, 50, 100]}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
        value={values}
        totalRecords={totalElements}
        rows={limit}
        first={first}
        onPage={onPage}
        layout={"grid"}
        itemTemplate={itemTemplate}
      />
    </Dialog>
  );
});

export default ChatGreetingsModal;
