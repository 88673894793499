import Axios from "../config/Axios";

export const getWishListConfiguration = (dealershipId: number) =>
  Axios.instance.get(`dealerships/${dealershipId}/wish-list-configuration`);
export const createWishListConfiguration = (
  dealershipId: number,
  formData: FormData
) =>
  Axios.instance.post(
    `dealerships/${dealershipId}/wish-list-configuration`,
    formData
  );
export const updateWishListConfiguration = (
  dealershipId: number,
  formData: FormData
) =>
  Axios.instance.put(
    `dealerships/${dealershipId}/wish-list-configuration`,
    formData
  );

export const getMyAcquisitionsSalesPerformanceReport = (
  dealershipId: number,
  dateRange: string
) =>
  Axios.instance.get(`my-acquisitions/sales-performance-report`, {
    params: { dealershipId: dealershipId, dateRange: dateRange },
  });

export const exportMyAcquisitionsSalesPerformanceReport = (
  dealershipId: number,
  dateRange: string
) =>
  Axios.instance.get(`my-acquisitions/export-sales-performance-report`, {
    params: { dealershipId: dealershipId, dateRange: dateRange },
  });

export const getMyAcquisitionsPerformanceReport = (
  dealershipId: number,
  dateRange: string
) =>
  Axios.instance.get(`my-acquisitions/performance-report`, {
    params: { dealershipId: dealershipId, dateRange: dateRange },
  });
