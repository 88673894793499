import moment from "moment";
import React, { useImperativeHandle, useState } from "react";
import Util from "../../../util/Util";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import {
  DataTable,
  DataTableSelectionChangeParams,
} from "primereact/datatable";
import { findMyTrafficLeads } from "../../../actions/engLeadsAction";
import { IEngagementLeadDto, VinVdpStatsDto } from "../../../../index.dts";
import CopyToClipboard from "react-copy-to-clipboard";

interface IProps {
  dealershipId: number;
  dealershipTimezone: string;
}
const VdpLeadModal = React.forwardRef((props: IProps, ref: any) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [first, setFirst] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [leadData, setLeadData] = useState<IEngagementLeadDto[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<1 | 0 | -1>(0);
  const [campaignSearch, setCampaignSearch] = useState<string>("");
  const [vinVdpStatsDto, setVinVdpStatsDto] = useState<any>({});
  const [selectedLead, setSelectedLead] = useState();

  const [endDate, setEndDate] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }));

  const show = (
    rowData: any,
    campaignSearch: string,
    startDate: string,
    endDate: string
  ): void => {
    const start = moment(startDate)
      .set({ hour: 0, minute: 0, seconds: 0 })
      .format(Util.localDateTimeFormat);
    const end = moment(endDate)
      .set({ hour: 23, minute: 59, seconds: 59 })
      .format(Util.localDateTimeFormat);

    setLoading(true);
    setVisible(true);
    setEndDate(end);
    setStartDate(start);
    setVinVdpStatsDto(rowData);
    setCampaignSearch(campaignSearch);

    getLeadsData(0, limit, start, end, rowData);
  };

  const getLeadsData = (
    page: number,
    limit: number,
    startDate: string,
    endDate: string,
    vinVdpStatsDto: VinVdpStatsDto
  ) =>
    new Promise((resolve, reject) => {
      setLoading(true);

      findMyTrafficLeads({
        page: page,
        pageLimit: limit,
        dealershipId: props.dealershipId,
        vinVdpStatsDtos: [vinVdpStatsDto],
        endDate: endDate,
        startDate: startDate,
      })
        .then((response) => {
          dataNormalization(response.data.content);

          setLeadData(response.data.content);
          setTotalItemsCount(response.data.totalElements);

          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);
          reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const dataNormalization = (data: IEngagementLeadDto[]): void => {
    data.forEach((item) => {
      item["name"] = `${Util.getDefaultIfNull(
        Util._.get(item, "metadata.first_name", null),
        ""
      )} ${Util.getDefaultIfNull(
        Util._.get(item, "metadata.last_name", null),
        ""
      )}`.trim();
    });
  };

  const hide = (): void => {
    setVisible(false);
    setLeadData([]);
    setSortField("");
    setSortOrder(0);
    setCampaignSearch("");
  };

  const onPage = (event: any) => {
    getLeadsData(
      event.page,
      event.rows,
      startDate,
      endDate,
      vinVdpStatsDto
    ).finally(() => {
      setLimit(event.rows);
      setFirst(event.first);
      setPage(event.page);
    });
  };

  const createdTemplate = (data: any) => {
    return <div>{moment(data.created).format(Util.usDateFormat)}</div>;
  };

  const emailTemplate = (data: any) => {
    return (
      <CopyToClipboard
        text={Util.getDefaultIfNull(Util._.get(data, "metadata.email", ""), "")}
        onCopy={() => Util.success("Copied to clipboard!")}
      >
        <div style={{ wordBreak: "break-word" }}>
          {Util.getDefaultIfNull(Util._.get(data, "metadata.email", ""), "")}
        </div>
      </CopyToClipboard>
    );
  };

  const urlTemplate = (data: any) => {
    return (
      <CopyToClipboard
        text={Util._.get(data, "pageMetadata.url", "")}
        onCopy={() => Util.success("Copied to clipboard!")}
      >
        <div
          style={{
            color: "#39A3F4",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {Util._.get(data, "pageMetadata.url", "")}
        </div>
      </CopyToClipboard>
    );
  };

  const onSelectLead = (e: DataTableSelectionChangeParams): void => {
    setSelectedLead(e.value);
  };

  const onSort = (data: any) => {
    getLeadsData(page, limit, startDate, endDate, vinVdpStatsDto).finally(
      () => {
        setSortField(data.sortField);
        setSortOrder(data.sortOrder);
      }
    );
  };

  return (
    <>
      <Dialog
        onHide={hide}
        visible={visible}
        style={{ width: "80vw" }}
        header={`Leads Details ( ${
          Util.isEmpty(campaignSearch) ? "Total" : campaignSearch
        } )`}
      >
        <div
          className={
            "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"
          }
        >
          <DataTable
            resizableColumns={true}
            columnResizeMode={"expand"}
            rowsPerPageOptions={Util.rowsPerPageOptions}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
            value={leadData}
            paginator={true}
            totalRecords={totalItemsCount}
            rows={limit}
            lazy={true}
            first={first}
            loading={loading}
            onPage={onPage}
            selectionMode={"single"}
            sortMode={"single"}
            onSelectionChange={onSelectLead}
            onSort={onSort}
            sortField={sortField}
            sortOrder={sortOrder}
          >
            <Column
              sortable={true}
              field={"zonedCreated"}
              sortField={"created"}
              header={"Date"}
              body={createdTemplate}
            />
            <Column sortable={true} field={"leadType"} header={"Lead Type"} />
            <Column sortable={false} field={"name"} header={"Name"} />
            <Column
              sortable={false}
              field={"metadata.phone"}
              header={"Phone"}
            />
            <Column
              sortable={false}
              field={"metadata.email"}
              header={"Email"}
              body={emailTemplate}
            />
            <Column sortable={true} field={"device"} header={"Device"} />
            <Column field={"zip"} header={"Zip"} />
            <Column field={"pageMetadata.make"} header={"Make"} />
            <Column field={"pageMetadata.model"} header={"Model"} />
            <Column
              field={"pageMetadata.url"}
              header={"Url"}
              style={{ width: 200 }}
              body={urlTemplate}
            />
          </DataTable>
        </div>
      </Dialog>
    </>
  );
});

export default VdpLeadModal;
