import React, { useCallback, useEffect, useRef, useState } from "react";
import "../my_traffic.css";
import { useHistory } from "react-router-dom";
import { MODE } from "../../../util/Enums";
import { InputText } from "primereact/inputtext";
import { ICreateCampaignDto, ISelectValue } from "../../../../index.dts";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import moment from "moment-timezone";
import Util from "../../../util/Util";
import { InputSwitch } from "primereact/inputswitch";
import AsyncSelect from "react-select/async";
import { getRtsGroupsBySearchCriteria } from "../../../actions/rtsGroupActions";
import {
  createGroupCampaign,
  getCampaignById,
  getCampaignUsers,
  updateCampaign,
} from "../../../actions/campaignActions";
import { Button } from "primereact/button";
import deepClone from "deep-clone";
import { confirmAlert } from "react-confirm-alert";
import { useForceUpdate } from "../../../hooks/useForceUpdate";

interface IProps {
  history: any;
  location: {
    state?: {
      id?: number;
      mode?: MODE;
      activeIndex?: number;
      [key: string]: any;
    };
  };
}

const WARNINGS = {
  name: "",
  segment: "",
  message: "",
  delaySend: "",
  engagementScore: "",
  zipCodes: "",
  startDate: "",
  endDate: "",
  rtsGroupId: "",
  userIds: "",
};

export function CampaignForm(props: IProps) {
  const history = useHistory();
  const unblockHandle = useRef<any>();
  const forceUpdate = useForceUpdate();

  const [mode] = useState<MODE>(props.location.state?.mode || MODE.NEW);
  const [campaignDto, setCampaignDto] = useState<ICreateCampaignDto>({
    id: props.location.state?.id || 0,
    name: "",
    enabled: true,
    startDate: moment().format(Util.localDateTimeFormat),
    endDate: "",
    delaySend: 20,
    engagementScore: 50,
    message: "",
    segment: "",
    userIds: [],
    rtsGroupId: 0,
    zipCodes: "",
  });

  const [tempCampaignDto, setTempCampaignDto] = useState(
    deepClone(campaignDto)
  );

  const [group, setGroup] = useState<ISelectValue>();
  const [users, setUsers] = useState<ISelectValue[]>([]);

  useEffect(() => {
    if (mode === MODE.EDIT && campaignDto.id) {
      Util.globalSpinner().show();
      getCampaignById(campaignDto.id)
        .then((response) => {
          const dto = {
            id: response.data.id,
            name: response.data.name,
            segment: response.data.segment,
            message: response.data.message,
            startDate: response.data.startDate,
            endDate: response.data.endDate,
            engagementScore: response.data.engagementScore,
            delaySend: response.data.delaySend,
            zipCodes: response.data.zipCodes || "",
            enabled: response.data.enabled,
            rtsGroupId: response.data.rtsGroup.id,
            userIds: response.data.users.map((item: any) => item.id),
          };
          setCampaignDto(dto);
          setTempCampaignDto(deepClone(dto));
          setUsers(
            response.data.users.map((item: any) => {
              return {
                label: item.email,
                value: item.id,
              };
            })
          );
          setGroup({
            label: response.data.rtsGroup.name,
            value: response.data.rtsGroup.id,
          });
        })
        .catch((error) => Util.showError(error))
        .finally(() => Util.globalSpinner().hide());
    }
    return () => {
      resetWarnings();

      history.block(() => {
        return true;
      });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    unblockHandle.current = history.block((targetLocation: any) => {
      return onLeave(targetLocation);
    });
    return () => {
      unblockHandle.current.current && unblockHandle.current.current();
    };
    // eslint-disable-next-line
  }, [history.location, campaignDto, tempCampaignDto]);

  const setValue = useCallback(
    (field, value) => {
      setCampaignDto({ ...campaignDto, [field]: value });
    },
    [campaignDto]
  );

  const onLeave = (targetLocation: any): boolean => {
    if (
      targetLocation.pathname === Util.PATH_NAMES.MY_TRAFFIC &&
      !targetLocation.state
    ) {
      targetLocation.state = {
        activeIndex: props.location.state?.activeIndex || 0,
      };
    }

    if (!Util._.isEqual(campaignDto, tempCampaignDto)) {
      confirmAlert({
        title: "There are unsaved changes!",
        message: "Discard local changes, or save to continue!",
        buttons: [
          {
            label: "Save",
            className: "confirm-save-btn",
            onClick: () => {
              onSave(targetLocation);
            },
          },
          {
            label: "Discard",
            onClick: () => {
              setTempCampaignDto(campaignDto);
              setTimeout(() => {
                history.push(targetLocation);
              }, 0);
            },
            className: "confirm-discard-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
      return false;
    }
    return true;
  };

  const onStartDateChange = (e: any): void => {
    if (e.value) {
      setValue("startDate", moment(e.value).format(Util.localDateTimeFormat));
    } else {
      setValue("startDate", "");

      (document.getElementById("startDateInput") as HTMLInputElement).value =
        "";
    }
  };

  const onEndDateChange = (e: any): void => {
    if (e.value) {
      setValue("endDate", moment(e.value).format(Util.localDateTimeFormat));
    } else {
      setValue("endDate", "");

      (document.getElementById("endDateInput") as HTMLInputElement).value = "";
    }
  };

  const promiseGroups = (inputValue: string) =>
    new Promise((resolve) => {
      getRtsGroupsBySearchCriteria({
        pageNumber: 0,
        pageLimit: 40,
        sorting: [],
        name: inputValue,
      }).then((response) => {
        resolve(
          response.data.content.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          })
        );
      });
    });

  const promiseUsers = (inputValue: string) =>
    new Promise((resolve) => {
      if (campaignDto.rtsGroupId) {
        getCampaignUsers({
          userEmail: inputValue,
          rtsGroupId: campaignDto.rtsGroupId,
        }).then((response) => {
          resolve(
            response.data.map((item: any) => {
              return {
                label: item.email,
                value: item.id,
              };
            })
          );
        });
      } else {
        resolve([]);
      }
    });

  const onGroupChange = (data: any): void => {
    setGroup(data);
    setUsers([]);
    campaignDto.userIds = [];
    setValue("rtsGroupId", data.value);
  };

  const onUsersChange = (data: any): void => {
    if (Array.isArray(data)) {
      setValue(
        "userIds",
        data.map((item: any) => item.value)
      );
      setUsers(data);
    } else {
      setUsers([]);
      setValue("userIds", []);
    }
  };

  const onBack = (): void => {
    history.push(Util.PATH_NAMES.MY_TRAFFIC, {
      activeIndex: props.location.state?.activeIndex || 0,
    });
  };

  const resetWarnings = () => {
    WARNINGS.name = "";
    WARNINGS.segment = "";
    WARNINGS.message = "";
    WARNINGS.delaySend = "";
    WARNINGS.engagementScore = "";
    WARNINGS.zipCodes = "";
    WARNINGS.startDate = "";
    WARNINGS.endDate = "";
    WARNINGS.rtsGroupId = "";
    WARNINGS.userIds = "";
  };

  const validate = (dto: ICreateCampaignDto): boolean => {
    resetWarnings();

    if (Util.isEmpty(dto.name)) {
      WARNINGS.name = "Invalid Name.";
    }
    if (Util.isEmpty(dto.segment)) {
      WARNINGS.segment = "Invalid Segment.";
    }
    if (Util.isEmpty(dto.message)) {
      WARNINGS.message = "Invalid Notification Prompt.";
    }
    if (dto.rtsGroupId <= 0) {
      WARNINGS.rtsGroupId = "Invalid Group.";
    }
    if (dto.userIds.length === 0) {
      WARNINGS.userIds = "Invalid Users.";
    }
    if (Util.isEmpty(dto.startDate)) {
      WARNINGS.startDate = "Invalid Start Date";
    }
    if (Util.isEmpty(dto.endDate)) {
      WARNINGS.endDate = "Invalid End Date";
    }

    return !(
      WARNINGS.name.trim() ||
      WARNINGS.segment.trim() ||
      WARNINGS.message.trim() ||
      WARNINGS.rtsGroupId.trim() ||
      WARNINGS.userIds.trim() ||
      WARNINGS.startDate.trim() ||
      WARNINGS.endDate.trim()
    );
  };

  const onSave = (targetLocation?: any): void => {
    if (validate(campaignDto)) {
      campaignDto.limitByZipCodes = !Util.isEmpty(campaignDto.zipCodes);

      Util.globalSpinner().show();
      if (mode === MODE.NEW) {
        createGroupCampaign(campaignDto)
          .then(() => {
            setTempCampaignDto(campaignDto);
            Util.success("Campaign created successfully.");

            setTimeout(() => {
              if (targetLocation) {
                history.push(targetLocation);
              } else {
                onBack();
              }
            });
          })
          .catch((error) => Util.showError(error))
          .finally(() => Util.globalSpinner().hide());
      } else {
        if (campaignDto.id) {
          updateCampaign(campaignDto.id, campaignDto)
            .then(() => {
              setTempCampaignDto(campaignDto);
              Util.success("Campaign updated successfully.");

              setTimeout(() => {
                if (targetLocation) {
                  history.push(targetLocation);
                } else {
                  onBack();
                }
              });
            })
            .catch((error) => Util.showError(error))
            .finally(() => Util.globalSpinner().hide());
        }
      }
    } else {
      forceUpdate();
    }
  };

  return (
    <div id={"campaign-form"} style={{ height: "100vh" }}>
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div className="card card-w-title datatable-demo">
            <div style={{ marginTop: 20 }} className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Name</label>
                  <span className={"red"}>*</span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <InputText
                  style={{ width: "100%" }}
                  value={campaignDto.name}
                  placeholder={"Name"}
                  maxLength={50}
                  onChange={(e: any) => {
                    setValue("name", e.target.value);
                  }}
                />
                <label className={"warning-label"}>{WARNINGS.name}</label>
              </div>
            </div>
            <div style={{ marginTop: 20 }} className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Segment</label>
                  <span className={"red"}>*</span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <InputText
                  style={{ width: "100%" }}
                  value={campaignDto.segment}
                  placeholder={"Segment"}
                  onChange={(e: any) => {
                    setValue("segment", e.target.value);
                  }}
                  maxLength={256}
                />
                <label className={"warning-label"}>{WARNINGS.segment}</label>
              </div>
            </div>
            <div style={{ marginTop: 20 }} className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Notification Prompt</label>
                  <span className={"red"}>*</span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <InputText
                  style={{ width: "100%" }}
                  value={campaignDto.message}
                  placeholder={"Notification Prompt"}
                  onChange={(e: any) => {
                    setValue("message", e.target.value);
                  }}
                  maxLength={256}
                />
                <label className={"warning-label"}>{WARNINGS.message}</label>
              </div>
            </div>
            <div style={{ marginTop: 20 }} className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Group</label>
                  <span className={"red"}>*</span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  value={group}
                  placeholder={"Group"}
                  loadOptions={(inputValue): any => promiseGroups(inputValue)}
                  onChange={onGroupChange}
                />
                <label className={"warning-label"}>{WARNINGS.rtsGroupId}</label>
              </div>
            </div>
            <div style={{ marginTop: 20 }} className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Users</label>
                  <span className={"red"}>*</span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <AsyncSelect
                  key={campaignDto.rtsGroupId}
                  isDisabled={!campaignDto.rtsGroupId}
                  cacheOptions
                  defaultOptions
                  value={users}
                  placeholder={"Users"}
                  isMulti={true}
                  className={"multi-select"}
                  loadOptions={(inputValue): any => promiseUsers(inputValue)}
                  onChange={onUsersChange}
                />
                <label className={"warning-label"}>{WARNINGS.userIds}</label>
              </div>
            </div>
            <div style={{ marginTop: 20 }} className={"p-grid"}>
              <div className={"p-col-12 p-lg-3 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Enable Notifications</label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-9"}>
                <InputSwitch
                  checked={campaignDto.enabled}
                  onChange={() => setValue("enabled", !campaignDto.enabled)}
                />
              </div>
            </div>
            <div style={{ marginTop: 20 }} className={"p-grid"}>
              <div className={"p-col-12 p-lg-4 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Engagement Score</label>
                </div>
                <div>
                  <InputNumber
                    min={0}
                    max={101}
                    showButtons={true}
                    style={{ width: "100%" }}
                    value={campaignDto.engagementScore}
                    placeholder={"Engagement Score"}
                    onChange={(e: any) => {
                      if (e.value) {
                        if (e.value > 100) {
                          setValue("engagementScore", 100);
                        } else {
                          setValue("engagementScore", e.value);
                        }
                      } else {
                        setValue("engagementScore", 0);
                      }
                    }}
                  />
                  <label className={"warning-label"}>
                    {WARNINGS.engagementScore}
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-4 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Delay Send</label>
                </div>
                <div>
                  <InputNumber
                    min={0}
                    max={101}
                    showButtons={true}
                    style={{ width: "100%" }}
                    value={campaignDto.delaySend}
                    placeholder={"Delay Send"}
                    onChange={(e: any) => {
                      if (e.value) {
                        if (e.value > 100) {
                          setValue("delaySend", 100);
                        } else {
                          setValue("delaySend", e.value);
                        }
                      } else {
                        setValue("delaySend", 0);
                      }
                    }}
                  />
                  <label className={"warning-label"}>
                    {WARNINGS.delaySend}
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-4 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Zips</label>
                </div>
                <div>
                  <InputText
                    style={{ width: "100%" }}
                    value={campaignDto.zipCodes}
                    placeholder={"Zips"}
                    onChange={(e: any) => {
                      setValue("zipCodes", e.target.value);
                    }}
                  />
                  <label className={"warning-label"}>{WARNINGS.zipCodes}</label>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 20 }} className={"p-grid"}>
              <div className={"p-col-12 p-lg-6 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>Start Date</label>
                  <span className={"red"}>*</span>
                </div>
                <div>
                  <Calendar
                    value={moment(
                      campaignDto.startDate,
                      Util.localDateTimeFormat
                    ).toDate()}
                    minDate={moment().toDate()}
                    maxDate={moment(
                      campaignDto.endDate,
                      Util.localDateTimeFormat
                    ).toDate()}
                    style={{ width: "100%", top: "400px !important" }}
                    inputId={"startDateInput"}
                    inputStyle={{ width: "100%" }}
                    dateFormat={Util.usCalendarDateFormat}
                    onChange={onStartDateChange}
                  />
                  <label className={"warning-label"}>
                    {WARNINGS.startDate}
                  </label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-6 p-label-col"}>
                <div className={"label-container"}>
                  <label className={"input-label"}>End Date</label>
                  <span className={"red"}>*</span>
                </div>
                <div>
                  <Calendar
                    value={moment(
                      campaignDto.endDate,
                      Util.localDateTimeFormat
                    ).toDate()}
                    minDate={moment(
                      campaignDto.startDate,
                      Util.localDateTimeFormat
                    ).toDate()}
                    style={{ width: "100%", top: "400px !important" }}
                    inputId={"endDateInput"}
                    inputStyle={{ width: "100%" }}
                    dateFormat={Util.usCalendarDateFormat}
                    onChange={onEndDateChange}
                  />
                  <label className={"warning-label"}>{WARNINGS.endDate}</label>
                </div>
              </div>
              <div className={"p-col-12 p-lg-12 p-label-col"}>
                <Button
                  onClick={() => onSave()}
                  label={"Save"}
                  icon={"pi pi-save"}
                />
                <Button
                  onClick={onBack}
                  className={"p-button p-button-warning"}
                  label={"Back"}
                  icon={"pi pi-arrow-left"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignForm;
