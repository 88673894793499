import React, { useState } from "react";
import { IChartDataset } from "../../../../index.dts";
import { IChartAnnotation } from "../../../util/chart/annotation/chart_annotation";
import Util from "../../../util/Util";
import { useTranslation } from "react-i18next";
import { Line } from "react-chartjs-2";
import { CategoryScale, Chart, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
Chart.register(CategoryScale);
Chart.register(...registerables);
Chart.register(annotationPlugin);

require("chartjs-plugin-annotation");

interface IProps {
  readonly dates?: string[];
  readonly annotations?: IChartAnnotation[];
  readonly datasets: IChartDataset[];
  readonly scale: {
    id: string;
    label: string;
  };
}

export function MakeModelChart(props: IProps) {
  const { t } = useTranslation();

  const [total, setTotal] = useState<number>(0);

  const generateLabels = (chart: any) => {
    let total = 0;

    const datasets: any[] = Util._.get(chart, "data.datasets", []);

    const array = datasets.map(function (dataset: any, i: number) {
      let sum = Util._.sum(dataset.data);

      if (chart.isDatasetVisible(i)) {
        if ([t("total")].findIndex((item) => item === dataset.label) === -1) {
          total += sum;
        }
      }

      return {
        text: `${dataset.label} (${sum})`,
        fillStyle: dataset.backgroundColor,
        hidden: !chart.isDatasetVisible(i),
        lineCap: dataset.borderCapStyle,
        lineDash: dataset.borderDash,
        lineDashOffset: dataset.borderDashOffset,
        lineJoin: dataset.borderJoinStyle,
        lineWidth: dataset.borderWidth,
        strokeStyle: dataset.borderColor,
        pointStyle: dataset.pointStyle,

        // Below is extra data used for toggling the datasets
        datasetIndex: i,
      };
    });

    setTotal(total);

    return array;
  };

  return (
    <div className={"smart-spend-chart"}>
      <label style={{ float: "right" }}>Total: {total}</label>
      <Line
        data={{
          labels: props.dates,
          datasets: props.datasets,
        }}
        height={80}
        options={{
          // events: ["click", "mousemove", "mouseout"],
          plugins: {
            annotation: {
              annotations: props.annotations ?? [],
            },
            legend: {
              position: "top",
              labels: {
                generateLabels,
              },
            },
            tooltip: {
              mode: "index",
              intersect: false,
            },
          },
          responsive: true,
          scales: {
            days: {
              // id: "days",
              title: {
                display: true,
                // id: "days",
              },
              position: "bottom",
            },
            [props.scale.id]: {
              // id: props.scale.id,
              position: "right",
              title: {
                display: true,
                text: props.scale.label,
              },
              suggestedMin: 0,
              ticks: {},
              type: "linear",
            },
          },
        }}
        // type={"line"}
      />
    </div>
  );
}
