import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import {IStore} from "../../index.dts";

export function UnauthorizedViewsWrapper(props: any) {
    const pending = useSelector((store: IStore) => store.auth.pending, shallowEqual);

    return (
        <div id={"main-view"}>
            {pending ? (<div style={{height: "100%", backgroundColor: "white"}}/>) : props.children}
        </div>
    );
}

export default UnauthorizedViewsWrapper
