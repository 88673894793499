import React from "react";
import { IChartAnnotation } from "../../../util/chart/annotation/chart_annotation";
import { IChartDataset, IMakeModelData } from "../../../../index.dts";
import { ISmartSpendFilterData } from "../../smart_spend_filter/SmartSpendFilter";
import { MakeModelChart } from "./MakeModelChart";
import { useTranslation } from "react-i18next";
import { MakeModelDataService } from "../service/MakeModelDataService";
import { entries, forEach, isEmpty } from "lodash";
import { ModelDataSet } from "../../../util/chart/dataset/model_dataset";
import { ChartConfig } from "../../../util/chart/chart_config";
import Util from "../../../util/Util";

interface IProps {
  readonly annotations?: IChartAnnotation[];
  readonly data?: IMakeModelData;
  readonly filter: ISmartSpendFilterData;
  readonly scale: {
    readonly id: string;
    readonly label: string;
  };
  dataType: DataType;
}

export type DataType = "pageViews" | "leads";

const MakeModelsChartWrapper = (props: IProps) => {
  const { t } = useTranslation();
  const service = new MakeModelDataService();

  const getLabel = (type: DataType, make?: string, model?: string): string => {
    if (!isEmpty(make) && !isEmpty(model)) {
      return `${type} ${make} (${model})`;
    }

    if (!isEmpty(make)) {
      return `${type} ${make}`;
    }

    return type;
  };

  const getColor = (label: string): string => {
    switch (label) {
      case "Total":
        return ChartConfig.total.color;
      case "SRP":
        return ChartConfig.srp.color;
      case "VDP":
        return ChartConfig.vdp.color;
    }

    return Util.stringToColor(label);
  };

  const getTotalDataSet = (
    key: DataType,
    make?: string,
    model?: string
  ): IChartDataset => {
    const label = getLabel(t("total"), make, model);
    const color = getColor(label);

    return new ModelDataSet({
      label: label,
      color: color,
      data: getFilteredData(props.data?.[key]?.total, make, model),
      yAxisID: props.scale.id,
    });
  };

  const getSRPDataSet = (key: DataType, make?: string, model?: string) => {
    const label = getLabel(t("srp"), make, model);
    const color = getColor(label);

    return new ModelDataSet({
      label: label,
      color: color,
      data: getFilteredData(props.data?.[key]?.srp, make, model),
      yAxisID: props.scale.id,
    });
  };

  const getVDPDataSet = (key: DataType, make?: string, model?: string) => {
    const label = getLabel(t("vdp"), make, model);
    const color = getColor(label);

    return new ModelDataSet({
      label: label,
      color: color,
      data: getFilteredData(props.data?.[key]?.vdp, make, model),
      yAxisID: props.scale.id,
    });
  };

  const getDataSets = (): IChartDataset[] => {
    const datasets: IChartDataset[] = [];

    const makes = entries(props.filter.options?.makes);

    if (makes.length > 0) {
      makes.forEach((entry) => {
        datasets.push(getTotalDataSet(props.dataType, entry[0]));
        datasets.push(getSRPDataSet(props.dataType, entry[0]));
        datasets.push(getVDPDataSet(props.dataType, entry[0]));

        forEach(entry[1], (model) => {
          let modelName = Object.keys(model)[0];
          datasets.push(getTotalDataSet(props.dataType, entry[0], modelName));
          datasets.push(getSRPDataSet(props.dataType, entry[0], modelName));
          datasets.push(getVDPDataSet(props.dataType, entry[0], modelName));
        });
      });
    } else {
      datasets.push(getTotalDataSet(props.dataType));
      datasets.push(getSRPDataSet(props.dataType));
      datasets.push(getVDPDataSet(props.dataType));
    }
    return datasets;
  };

  function getFilteredData(
    source: any,
    make?: string,
    model?: string
  ): number[] {
    return service.getFilteredData(
      source,
      props.filter,
      make,
      model,
      props.data?.dates?.length
    );
  }

  const renderer = (): React.ReactElement => {
    return (
      <MakeModelChart
        annotations={props.annotations}
        dates={props.data?.dates}
        datasets={getDataSets()}
        scale={props.scale}
      />
    );
  };

  return renderer();
};

export default MakeModelsChartWrapper;
