export const DEFAULT_PULL_UP_SOURCE = `<style type="text/css">
    @-webkit-keyframes pi-color-change {
        0% { color: red; }
        50% { color: inherit; }
        100% { color: red; }
    }
    @-moz-keyframes pi-color-change {
        0% { color: red; }
        50% { color: inherit; }
        100% { color: red; }
    }
    @-ms-keyframes pi-color-change {
        0% { color: red; }
        50% { color: inherit; }
        100% { color: red; }
    }
    @-o-keyframes pi-color-change {
        0% { color: red; }
        50% { color: inherit; }
        100% { color: red; }
    }
    @keyframes pi-color-change {
        0% { color: red; }
        50% { color: inherit; }
        100% { color: red; }
    }
</style>

<div class="pi-pullup-template" style="text-align: center;">
    <div class="pi-pullup-line1">
        <span style="
            color: red;
            font-size: 20px;
            font-weight: bold;
            -webkit-animation: pi-color-change 1s infinite;
            -moz-animation: pi-color-change 1s infinite;
            -o-animation: pi-color-change 1s infinite;
            -ms-animation: pi-color-change 1s infinite;
            animation: pi-color-change 1s infinite;
            cursor: pointer;
            ">
            {{line1}}
        </span>
    </div>
    <div class="pi-pullup-line2">
        <span style="
            color: red;
            font-size: 18px;
            font-weight: normal;
            -webkit-animation: pi-color-change 1s infinite;
            -moz-animation: pi-color-change 1s infinite;
            -o-animation: pi-color-change 1s infinite;
            -ms-animation: pi-color-change 1s infinite;
            animation: pi-color-change 1s infinite;
            cursor: pointer;
            ">
            {{line2}}
        </span>
    </div>
</div>
`