import Axios from "../config/Axios";
import {SettingsName} from "../util/Enums";
import {IBlockedIpAddressDto, IPageable, ISearchCriteria} from "../../index.dts";

export const getSettingsList = () => Axios.instance.get("admin/settings/list");
export const saveSettings = (dto: any) => Axios.instance.post("/admin/settings", dto);
export const deleteAllSettingsByName = (settingsName: SettingsName) => Axios.instance.delete(`admin/settings/${settingsName}`);
export const deleteVendorsByIds = (ids: number[]) => Axios.all(ids.map(id => Axios.instance.delete(`admin/settings/${SettingsName.WEBSITE_VENDOR}/${id}`)));
export const getSettingsByName = (settingsName: string) => Axios.instance.get(`admin/settings/${settingsName}`);
export const getWebsiteVendors = () => Axios.instance.get(`admin/settings/websiteVendors`);
export const getSummaryData = (dealershipId: number) => Axios.instance.get(`support/leadSummary?dealershipId=${dealershipId}`);


export const getBlockedIpAddresses = (dto: ISearchCriteria): Promise<IPageable> => Axios.instance.post("blocked-ip/list", dto);
export const updateBlockedIpAddress = (id: number, dto: IBlockedIpAddressDto) => Axios.instance.put(`blocked-ip/${id}`, dto);
export const addBlockedIpAddress = (dto: IBlockedIpAddressDto) => Axios.instance.post("blocked-ip", dto);
export const deleteBlockedIpAddresses = (ids: number[]) => Axios.all(ids.map(id => Axios.instance.delete(`blocked-ip/${id}`)));