export const IDS = {
  dashboard: {
    wrapper: "dashboard-view",
    chart: {
      mainChart: "main-chart",
      pullsChart: "pulls-chart",
      leadsChart: "leads-chart",
      conversionChart: "conversion-chart",
    },
  },
  dealerships: {
    wrapper: "dealerships-view",
    button: {
      assignUsers: "assign-users-btn",
      vexProfileConfiguration: "vex-profile-config-btn",
    },
  },
  mag: {
    panel: {
      magPanel: "mag-panel",
      form: {
        firstName: "mag-customer-first-name",
        lastName: "mag-customer-last-name",
        phoneNumber: "mag-customer-phone-number",
        emailAddress: "mag-customer-email-address",
        year: "mag-customer-year",
        make: "mag-customer-make",
        model: "mag-customer-model",
        trim: "mag-customer-trim",
        vin: "mag-customer-vin",
        stockNumber: "mag-customer-stock-number",
      },
    },
  },
  wishList: {
    panel: {
      wishList: "wish-list-panel",
    },
    inputs: {
      dealerNotificationType: "dealer-notification-type-inp",
      acquisitionEmailCadence: "acquisition-email-cadence-inp",
      ignoreVehiclesUnder: "ignore-vehicles-under-inp",
      smtpConfigurationHost: "smtp-configuration-host-inp",
      smtpConfigurationEmailAddress: "smtp-configuration-email-inp",
      smtpConfigurationUsername: "smtp-configuration-username-inp",
      smtpConfigurationPassword: "smtp-configuration-password-inp",
      smtpConfigurationPort: "smtp-configuration-port-inp",
      deanonymizedIncentive: "deanonymized-incentive-inp",
      daysToFirstEmailForCustomers: "days-to-first-email-for-customers-inp",
      emailBodyContent: "email-body-content-txt-area",
      emailOffers: "email-body-offers-txt-area",
    },
  },
  vehicleExchangeDealershipProfile: {
    panel: {
      vehicleExchangeDealerhsipProfile:
        "vehicle-exchange-dealership-profile-panel",
    },
    inputs: {
      apptReminderSendTime: "reminder-send-time-inp",
      dealerNotificationsTo: "dealer-notifications-to-inp",
      declinedAcquisitionCadence: "declined-acquisition-cadence-select",
      acquisitionExclusionYears: "acquisition-exclusion-years-inp",
      acquisitionExclusionMakes: "acquisition-exclusion-makes-inp",
      acquisitionExclusionModels: "acquisition-exclusion-models-inp",
      acquisitionExclusionROType: "acquisition-exclusion-ro-type-inp",
      acquisitionExclusionOPCodes: "acquisition-exclusion-op-codes-inp",
      emailBodyContent: "email-body-content-text-area",
      emailOffers: "email-offers-text-area",
      customerEmailTemplateUrl: "customer-email-template-url-inp",
      dealershipEmailTemplateUrl: "dealership-email-template-url-inp",
      acquisitionEmailTemplateUrl: "acquisition-email-template-url-inp",
      smtpConfigurationHost: "smtp-configuration-host-inp",
      smtpConfigurationEmailAddress: "smtp-configuration-email-inp",
      smtpConfigurationUsername: "smtp-configuration-username-inp",
      smtpConfigurationPassword: "smtp-configuration-password-inp",
      smtpConfigurationPort: "smtp-configuration-port-inp",
      sendOfferVehiclePurchasedAfterDays: "send-offer-after-days-inp",
      enableAuthenticom: "enable-authenticom-swt",
      includeCSV: "include-csv-swt",
      authenticomId: "authenticom-id-inp",
      ignoreVehiclesUnder: "ignore-vehicles-under-inp",
    },
  },
  acquisitions: {
    inputs: {
      fieldsFilter: "fields-filter-select",
    },
    button: {
      wishList: "wish-list-config-btn",
    },
  },
  dealershipsForm: {
    wrapper: "dealerships-form",
    inputs: {
      siteURL: "site-url-inp",
      offerZIndex: "offer-zIndex-inp",
      dealershipAddress: "dealership-address-inp",
      salesPhoneNumber: "sales-phone-inp",
      servicePhoneNumber: "service-phone-inp",
      partsPhoneNumber: "parts-phone-inp",
      supportEmailAddress: "support-email-inp",
      copyrightNotice: "copyright-notice-inp",
      verifyInformationLink: "information-link-inp",
      homepageURL: "homepage-url-inp",
      newVehiclesURL: "new-vehicle-url-inp",
      newVehiclesVdpURL: "new-vehicle-vdp-url-inp",
      usedVehiclesURL: "used-vehicle-url-inp",
      usedVehiclesVdpURL: "used-vehicle-vdp-url-inp",
      certifiedVehiclesURL: "certified-vehicle-url-inp",
      certifiedVehiclesVdpURL: "certified-vehicle-vdp-url-inp",
      specialsURL: "special-url-inp",
      financeURL: "finance-url-inp",
      serviceURL: "service-url-inp",
      contactURL: "contact-url-inp",
      videosURL: "videos-url-inp",
      directionsURL: "direction-url-inp",
      aboutURL: "about-url-inp",
      privacyURL: "privacy-url-inp",
      partnerDestinationURL: "partner-destination-url-inp",
      mainCrmEmailAddress: "main-crm-email-inp",
      usedCrmEmailAddress: "used-crm-email-inp",
      serviceCrmEmailAddress: "service-crm-email-inp",
      administratorFirstName: "admin-first-name-inp",
      administratorLastName: "admin-last-name-inp",
      administratorEmailAddress: "admin-email-inp",
      administratorPhoneNumber: "admin-phone.inp",
      behaviourScript: "behaviour-script-inp",
      conversionScript: "conversion-script-inp",
      generalManagerFirstName: "manager-first-name-inp",
      generalManagerLastName: "manager-last-name-inp",
      generalManagerEmailAddress: "manager-email-inp",
      performanceReportsRecipients: "performance-report-recipients-inp",
      leadSummaryRecipients: "lead-summary-recipients-inp",
      dailyLeadGroup: "daily-lead-group-inp",
      restrictedIps: "restricted-ip-address-inp",
      facebookOfferImage: "facebook-offer-image-inp",
      dealershipLogoImage: "dealership-logo-image-inp",
      helixID: "helix-id-inp",
      gaPropertyId: "property-id-inp",
      gaPassword: "ga-password-inp",
      googleAnalyticsID: "google-analytics-id-inp",
      uniteDigitalDealerCode: "unite-digital-dealer-code",
      persistentChatMessage: "persistent-chat-message-inp",
      persistentChatEngScore: "persistent-chat-eng-score-inp",
      persistentChatCrmEmail: "persistent-chat-crm-email-inp",
      persistentChatThreshold: "persistent-chat-threshold-inp",
      persistentChatAvatar: "persistent-chat-avatar-inp",
      providerToken: "provider-token",
      providerUserId: "provider-user-id",
      smartPixlId: "provider-user-id",
      engagementScoreThreshold: "engagement-score-treshold",
      EmlDealerId: "enhance-my-leads-dealership-id",
      dealerSocketId: "dealer-socket-id",
      fortellisId: "fortellis-id",
      iOfferId: "iOffer-id",
      clickInsId: "click-ins-id",
      iOfferUsername: "iOffer-username",
      bannerTitle: "banner-title",
      bannerSubTitle: "banner-sub-title",
    },
    switch: {
      pauseDealership: "pause-dealership-swi",
      multiphaseEnabled: "enable-multipart-swi",
      singleCtaEnabled: "enable-single-cta-swi",
      enableDealership: "enable-dealership-swi",
      useMyTraffic: "use-my-traffic-swi",
      useMyCampaigns: "use-my-campaigns-swi",
      useGAdata: "use-GA-GA4-data",
      useUniteDigitaldata: "use-unite-digital-data",
      useVrt: "use-vrt-swi",
      useVex: "use-vex-swi",
      useFraudGuard: "use-fraud-guard-swi",
      useBlockOffers: "block-offers-swi",
      useSmartpixl: "use-smartpixl-swi",
      useDealerSocket: "use-dealersocket-swi",
      useFortellis: "use-fortellis-swi",
      useCRM: "use-crm-swi",
      useIOffer: "use-iOffer-swi",
      useWebsiteBanner: "use-website-banner-swi",
      useEml: "use-eml-swi",
      useAutoAcquire: "use-auto-acquire-swi",
      enableChat: "enable-chat-swi",
      enableAudioVideoCalls: "enable-audio-video-calls-swi",
      contactFirstNameRequired: "contact-first-name-required-swi",
      contactLastNameRequired: "contact-last-name-required-swi",
      contactEmailRequired: "contact-email-required-swi",
      contactPhoneNameRequired: "contact-phone-required-swi",
      contactCrmEmailRequired: "contact-crm-email-required-swi",
      persistentChatEnabled: "persistent-chat-enabled-swi",
      sendToCrm: "send-to-crm",
      useProMax: "use-promax-swi",
    },
    buttons: {
      mainCrmEmailAddress: "main-crm-email-btn",
      usedCrmEmailAddress: "used-crm-email-btn",
      serviceCrmEmailAddress: "service-crm-email-btn",
    },
    panel: {
      crm: "dealership-crm-panel",
      main: "dealership-main-panel",
      dealershipURL: "dealership-url-panel",
      dealershipAdministrator: "dealership-administrator-panel",
      dealershipGeneralManager: "dealership-general-manager-panel",
      dealershipLogoImage: "dealership-logo-image-panel",
      dealershipIOffer: "dealership-iOffer-panel",
      dealershipReportEmailNotification:
        "dealership-report-email-notification-panel",
      dealershipOfferGenericSettings: "dealership-offer-generic-settings-panel",
      dealershipMyTraffic: "dealership-my-traffic-panel",
      dealershipMyCampaigns: "dealership-my-campaigns-panel",
      dealershipVrtIntegration: "dealership-vrt-panel",
      dealershipVexIntegration: "dealership-vex-panel",
      dealershipFraudGuardIntegration: "dealership-fraud-guard-panel",
      dealershipBlockOffersIntegration: "dealership-block-offers-panel",
      dealershipSmartpixl: "dealership-smartpixl-panel",
      dealershipEmlIntegration: "dealership-enhance-my-leads-panel",
      dealershipAutoAcquireIntegration: "dealership-auto-acquire-panel",
      dealershipContact: "dealership-contact-panel",
      thirdPartyProviders: "dealership-third-party-providers-panel",
      persistentChat: "dealership-persistent-chat-panel",
      providerConfiguration: "provider-configuration",
    },
  },
  offers: {
    wrapper: "offers-view",
  },
  demographicsPanel: {
    wrapper: "demographics-panel",
    inputs: {
      numberOfChildren: "number-of-children-inp",
    },
    panel: {
      basic: "offer-basic-panel",
    },
    switch: {
      hasChildren: "children-swi",
      useDemographics: "use-demographics-swi",
      businessOwner: "business-owner-swi",
      yearsInResidence: "years-in-residence-swi",
      income: "income-swi",
    },
    slider: {
      homeValueFrom: "home-value-from-slider",
      homeValueTo: "home-value-to-slider",
      yearsInResidenceFrom: "years-in-residence-from-slider",
      yearsInResidenceTo: "years-in-residence-to-slider",
      ageFrom: "age-from-slider",
      ageTo: "age-to-slider",
      incomeFrom: "income-from-slider",
      incomeTo: "income-to-slider",
    },
  },
  offersForm: {
    wrapper: "offers-form-view",
    inputs: {
      minVdpDuration: "offer-min-vdp-duration-inp",
      minSessionDuration: "offer-min-session-duration-inp",
      leadSummaryRecipients: "offer-lead-summary-recipients-inp",
      maxSessionLength: "offer-max-session-length-inp",
      uniquePageViews: "offer-unique-page-views-inp",
      maxOffersPerSession: "offer-max-offers-per-session-inp",
      leadLimit: "offer-lead-limit-inp",
      includeUrls: "offer-include-urls-inp",
      excludeUrls: "offer-exclude-urls-inp",
      yearsToLimit: "offer-years-to-limit-inp",
      makesToLimit: "offer-makes-to-limit-inp",
      typesToLimit: "offer-types-to-limit-inp",
      makesToExclude: "offer-makes-to-exclude-inp",
      modelsToLimit: "offer-models-to-limit-inp",
      modelsToExclude: "offer-models-to-exclude-inp",
      includeZips: "offer-include-zips-inp",
      excludeZips: "offer-exclude-zips-inp",
      excludeZipsMobile: "offer-exclude-zips-mobile-inp",
      crmDestinationEmail: "offer-crm-destination-email-inp",
      goalAttainmentNotification: "offer-goal-attainment-notification-inp",
      termsAndConditions: "offer-terms-and-conditions-inp",
      customerMessage: "offer-customer-message-inp",
      avgScore: "offer-avg-score-inp",
      staticEngagementThreshold: "offer-static-engagement-threshold-inp",
      pullUpOfferSource: "pull-up-source-inp",
      neutralButtonSource: "neutral-btn-input",
      sideBannerSource: "side-banner-source-input",
      triggeredButtonSource: "triggered-btn-input",
      includeZipRange: "include-zip-range-inp",
      excludeZipRange: "exclude-zip-range-inp",
      excludeZipMobileRange: "exclude-zip-mobile-range-inp",
    },
    switch: {
      pullUpOffer: "pull-up-offer-swi",
      sideBanner: "side-banner-swi",
      blendedOffer: "blended-offer-swi",
      multiphaseOffer: "multiphase-offer-swi",
      singleCTA: "single-cta-offer-swi",
      popUpOffer: "pop-up-offer-swi",
      showOnLeave: "shown-on-leave-swi",
      useSubSource: "use-sub-source-swi",
      sendReceiptMessage: "send-receipt-message-swi",
      useEngagementScore: "use-engagement-score-swi",
      active: "active-swi",
      showOnEveryVisit: "show-on-every-visit-swi",
    },
    radio: {
      useStaticEngagementScore: "offer-use-static-eng-score-ra",
      useDynamicEngagementScore: "offer-use-dynamic-eng-score-ra",
    },
    panel: {
      basic: "offer-basic-panel",
      preview: "offer-preview-panel",
      crmSource: "offer-crm-source-panel",
      restrictions: "offer-restrictions-panel",
      restrictionsBasedOnUrl: "offer-restrictions-based-on-url-panel",
      restrictionsBasedOnZip: "offer-restrictions-based-on-zip-panel",
      useEngagementScore: "offer-use-engagement-score-panel",
      pullUp: "offer-pull-up-panel",
      singleCTA: "offer-single-cta-panel",
      other: "offer-other-panel",
    },
  },
  button: {
    new: "new-btn",
    edit: "edit-btn",
    clear: "clear-btn",
    duplicate: "duplicate-btn",
    delete: "delete-btn",
    save: "save-btn",
    back: "back-btn",
    apply: "apply-btn",
    export: "export-btn",
    resendWR: "resend-wr-btn",
    closeRO: "close-ro-btn",
  },
  inputs: {
    id: "id-inp",
    name: "name-inp",
    autoVin: "auto-vin-inp",
    occupation: "occupation-inp",
  },
  switch: {
    expirable: "expirable-swi",
  },
  dropdown: {
    offer: "offer-dropdown",
    period: "period-dropdown",
    device: "device-dropdown",
    dealership: "dealership-dropdown",
    visitorType: "visitor-type-dropdown",
    timezone: "timezone-dropdown",
    brands: "brands-dropdown",
    crmSource: "crm-source-dropdown",
    chatBot: "chatbot-dropdown",
    enterpriseGroups: "enterprise-groups-dropdown",
    daysOfWeek: "days-of-week-dropdown",
    usersDropdown: "users-dropdown",
    templateDropdown: "template-dropdown",
    assetDropdown: "asset-dropdown",
    sctaAssetDropdown: "scta-asset-dropdown",
    multiphaseAssetDropdown: "multiphase-asset-dropdown",
    offerType: "offer-type-dropdown",
    provider: "provider",
    providerConfigEnabled: "provider-config-enabled",
    maritalStatus: "marital-status",
    gender: "gender-dropdown",
    language: "language-dropdown",
    homeRent: "home-rent-dropdown",
    year: "year-dropdown",
    make: "make-dropdown",
    model: "model-drowdown",
    education: "education-dropdown",
    viewBy: "view-by",
  },
  calendar: {
    startTime: "start-time-ca",
    endTime: "end-time-ca",
  },
  performanceReport: {
    services: "services-performance-report-datatable",
    sales: "services-performance-report-datatable",
  },
};
