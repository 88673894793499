import React, { useEffect, useState } from "react";
import {
  IDateArgs,
  IMakeModelData,
  ITrafficMakeModel,
  ITrafficMarker,
} from "../../../index.dts";
import {
  defaultSmartSpendFilter,
  ISmartSpendFilterData,
  SmartSpendFilter,
} from "../smart_spend_filter/SmartSpendFilter";

import { IChartAnnotation } from "../../util/chart/annotation/chart_annotation";
import { Panel } from "primereact/panel";
import Spinner from "../spinner/Spinner";
import { TrafficMarker } from "../../util/chart/annotation/traffic_marker";
import Util from "../../util/Util";
import {
  listTrafficMakes,
  listTrafficMarkers,
} from "../../actions/trafficMarkerActions";
import moment from "moment";
import { SmartSpendFilterOptions } from "../smart_spend_filter/smart_spend_filter_options";
import { Fieldset } from "primereact/fieldset";
import { useTranslation } from "react-i18next";
import MakeModelsChartWrapper from "./charts/MakeModelsChartWrapper";
import { SmartSpendChartService } from "../smart_spend_chart/smart_spend_chart_service";
import { useAppContext } from "../../services/contexts/app-context";

export type ISmartSpendDataAction = (
  args: IDateArgs
) => Promise<IMakeModelData>;

interface IProps {
  readonly dealershipId: number;
  readonly dealershipRefId: string;
  readonly dataAction: ISmartSpendDataAction;
}

export function MakeModelDataView(props: IProps) {
  const service = new SmartSpendChartService();

  const { t }: any = useTranslation();
  const ctx = useAppContext();

  const [markers, setMarkers] = useState<ITrafficMarker[]>();
  const [data, setData] = useState<IMakeModelData>();
  const [filter, setFilter] = useState<ISmartSpendFilterData>(
    ctx.filter ? ctx.filter : defaultSmartSpendFilter
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [needsRefresh, setNeedsRefresh] = useState<boolean>(false);
  const [makeModels, setMakeModels] = useState<ITrafficMakeModel>({});
  const [displayAnnotationTitle, setDisplayAnnotationTitle] =
    useState<boolean>(false);

  /**
   * EFFECTS
   */

  /**
   * On dealership change
   */
  useEffect(() => {
    load();
    // eslint-disable-next-line
  }, [props.dealershipId]);

  /**
   * On filter change
   */
  useEffect(() => {
    if (needsRefresh) {
      load();
    }
    // eslint-disable-next-line
  }, [filter]);

  /**
   * CALLBACKS
   */

  function onFilterChange(update: ISmartSpendFilterData): void {
    ctx.setFilter(update);
    setFilter(update);
    checkIfNeedsRefresh(update);
  }

  /**
   * FUNCTIONS
   */

  function checkIfNeedsRefresh(update: ISmartSpendFilterData): void {
    const hasChangeMarkerId = update.marker?.id !== filter.marker?.id;
    const hasChangeMarkerDate = update.marker?.date !== filter.marker?.date;
    const hasChangeDateStart = update.period?.start !== filter.period?.start;
    const hasChangeDateEnd = update.period?.end !== filter.period?.end;
    const hasChange =
      hasChangeMarkerId ||
      hasChangeMarkerDate ||
      hasChangeDateStart ||
      hasChangeDateEnd;
    setNeedsRefresh(hasChange);
  }

  function getAnnotations(): IChartAnnotation[] {
    const annotations = markers?.map(
      (marker) =>
        new TrafficMarker({
          id: marker.id,
          label: marker.title,
          scaleId: "days",
          value: marker.axisValue,
          endValue: marker.axisValue,
          display: displayAnnotationTitle,
          setDisplay: setDisplayAnnotationTitle,
        })
    );

    return annotations ?? [];
  }

  function getSmartSpendData(): Promise<IMakeModelData> {
    return props.dataAction({
      start: filter.period?.start,
      end: filter.period?.end,
    });
  }

  function getTrafficMarkers(): Promise<ITrafficMarker[]> {
    return listTrafficMarkers({
      dealershipId: props.dealershipId,
      startDate: moment(filter.period?.start).format(Util.localDateFormat),
      endDate: moment(filter.period?.end).format(Util.localDateFormat),
    });
  }

  function getTrafficMakes(
    dealershipRefId: string
  ): Promise<ITrafficMakeModel> {
    return listTrafficMakes(dealershipRefId);
  }

  function load(): Promise<void> {
    return new Promise((resolve) => {
      setIsLoading(true);
      loadData().finally(() => {
        setIsLoading(false);
        resolve();
      });
    });
  }

  function loadData(): Promise<void> {
    return new Promise((resolve) => {
      Promise.all([
        getSmartSpendData(),
        getTrafficMarkers(),
        getTrafficMakes(props.dealershipRefId),
      ])
        .then((response) => {
          setData(response[0]);
          setMarkers(response[1]);

          setMakeModels(response[2]);
        })
        .catch((error) => Util.showError(error))
        .finally(() => resolve());
    });
  }

  /**
   * VIEW
   */

  function getPageViews() {
    return (
      <Fieldset
        style={{ marginBottom: 20 }}
        legend={t("pageViews")}
        toggleable={false}
      >
        <MakeModelsChartWrapper
          annotations={getAnnotations()}
          filter={filter}
          data={data}
          scale={{
            id: "pageViews",
            label: t("pageViews"),
          }}
          dataType={"pageViews"}
        />
      </Fieldset>
    );
  }

  /**
   * VIEW
   */

  function getLeads() {
    return (
      <Fieldset
        style={{ marginBottom: 20 }}
        legend={t("leads")}
        toggleable={false}
      >
        <MakeModelsChartWrapper
          annotations={getAnnotations()}
          filter={filter}
          data={data}
          scale={{
            id: "leads",
            label: t("leads"),
          }}
          dataType={"leads"}
        />
      </Fieldset>
    );
  }

  function getContent() {
    return (
      <div>
        {getPageViews()}
        {getLeads()}
      </div>
    );
  }

  function getHeader() {
    return (
      <SmartSpendFilter
        maxMakes={3}
        maxModels={3}
        initial={filter}
        onChange={onFilterChange}
        makesModels={service.getMakeModels(makeModels)}
        dealershipId={props.dealershipId}
        options={[
          SmartSpendFilterOptions.dateRange,
          SmartSpendFilterOptions.marker,
          SmartSpendFilterOptions.makes,
          SmartSpendFilterOptions.models,
          SmartSpendFilterOptions.productType,
        ]}
      />
    );
  }

  function getSpinner() {
    return isLoading ? (
      <Spinner
        visible={isLoading}
        width={20}
        height={20}
        style={{
          position: "absolute",
          width: 40,
          height: 40,
          top: "unset",
        }}
      />
    ) : null;
  }

  return (
    <div className={"smart-spend-data-view-container"}>
      <Panel className={"smart-spend-data-view-panel"} header={getHeader()}>
        {getSpinner()}
        {getContent()}
      </Panel>
    </div>
  );
}
