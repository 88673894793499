import Axios from "../config/Axios";
import {
    ICampaignSearchRequestDto,
    ICreateCampaignDto,
    IGroupCampaign,
    IKaninCampaignReportRequestDto,
    IPageable
} from "../../index.dts";

export const getCampaignList = (dto: ICampaignSearchRequestDto): Promise<IPageable<IGroupCampaign>> => Axios.instance.post(`rts/campaigns/list`, dto);
export const getCampaignBaseList = (dto: ICampaignSearchRequestDto): Promise<IPageable<IGroupCampaign>> => Axios.instance.post(`rts/campaigns/base-list`, dto);
export const getCampaignUsers = (dto: { userEmail?: string; rtsGroupId?: number }) => Axios.instance.post("rts/campaigns/users", dto);
export const createGroupCampaign = (dto: ICreateCampaignDto) => Axios.instance.post("rts/campaigns", dto);
export const getCampaignById = (id: number) => Axios.instance.get(`rts/campaigns/${id}`);
export const updateCampaign = (id: number, dto: ICreateCampaignDto) => Axios.instance.put(`rts/campaigns/${id}`, dto);
export const deleteCampaigns = (ids: number[]) => Axios.all(ids.map(id => Axios.instance.delete(`rts/campaigns/${id}`)));
export const enabledCampaignNotifications = (id: number, enabled: boolean) => Axios.instance.put(`rts/campaigns/${id}`, {enabled});
export const getCampaignReport = (dto: IKaninCampaignReportRequestDto) => Axios.instance.post("kanin/campaigns", dto);
export const getCampaignStatistics = (id: number) => Axios.instance.get(`rts/campaigns/${id}/statistics`);
