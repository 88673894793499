import "./choose-triggers.css";
import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import {
  DemographicsFilter,
  ICampaignInfo,
  IChatGreeting,
  IChatGreetingDto,
  IMyTrafficNotificationDto,
  IMyTrafficNotificationFull,
  IOffer,
  IOfferDto,
  IStore,
} from "../../../../index.dts";
import Util from "../../../util/Util";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { getSemVendorList } from "../../../actions/semVendorActions";
import { Panel } from "primereact/panel";
import EditIcon from "@mui/icons-material/Edit";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputSwitch } from "primereact/inputswitch";
import {
  createMyCampaign,
  updateMyCampaign,
} from "../../../actions/campaignsActions";
import { useHistory } from "react-router-dom";
import { get, isEqual, startCase } from "lodash";
import moment from "moment";
import { CAMPAIGN_EDIT_TYPES, MODE } from "../../../util/Enums";
import { Calendar } from "primereact/calendar";
import { IDS } from "../../constants";

type Props = {
  setCampaignInfo: React.Dispatch<React.SetStateAction<ICampaignInfo>>;
  setStep: React.Dispatch<
    React.SetStateAction<{
      step: number | string;
      payload?: any;
    }>
  >;
  campaignInfo: ICampaignInfo;
  matchingVehicles: any;
  mode: MODE.EDIT | MODE.NEW | MODE.DUPLICATE;
  initialData: ICampaignInfo;
  demographicsData: DemographicsFilter;
  setInitialData: React.Dispatch<React.SetStateAction<ICampaignInfo>>;
  calendarDates: Date[] | Date;
};

const ChooseTriggers = (props: Props) => {
  const history = useHistory();
  const [sources, setSources] = useState<{
    data: any;
    totalElements: number;
  }>();
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  const [allSourcesCheckboxChecked, setAllSourcesCheckboxChecked] =
    useState(false);

  const [dates2, setDates2] = useState<Date | Date[] | undefined>(
    props.calendarDates
  );

  useEffect(() => {
    getSemVendorList({
      pageNumber: 0,
      pageLimit: undefined,
      dealershipIds: [dealershipContext?.id],
      sorting: [],
    })
      .then((vendorsRes: any) => {
        setSources({
          data: vendorsRes.data.content,
          totalElements: vendorsRes?.data?.totalElements,
        });
      })
      .catch((error) => Util.showError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!campaignInfo?.sources || campaignInfo?.sources?.length === 0) {
      setAllSourcesCheckboxChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sources]);

  const {
    setCampaignInfo,
    setStep,
    campaignInfo,
    matchingVehicles,
    mode,
    initialData,
    setInitialData,
  } = props;

  useEffect(() => {
    setCampaignInfo((prev) => {
      return {
        ...prev,
      };
    });
  }, [campaignInfo?.offer?.asset]);

  useEffect(() => {
    setCampaignInfo((prev) => {
      return matchingVehicles?.length == 0
        ? {
            ...prev,
            active: false,
          }
        : {
            ...prev,
          };
    });
  }, []);

  useEffect(() => {
    if (campaignInfo?.expirable && dates2) {
      setCampaignInfo((prev) => {
        return {
          ...prev,
          startDate: dates2 && dates2[0],
          endDate: dates2 && dates2[1],
        };
      });
    } else {
      setCampaignInfo((prev: any) => {
        return {
          ...prev,
          startDate: dates2,
        };
      });
    }
  }, [dates2]);

  useEffect(() => {
    setDates2(props.calendarDates);
  }, [campaignInfo?.expirable]);

  const transformOfferToDto = (offer: IOffer): IOfferDto => {
    return {
      name: get(offer, "name"),
      active: get(offer, "active"),
      templateId: get(offer, "template.id"),
      assetId: get(offer, "asset.id"),
      offerType: get(offer, "offerType"),
      visibilityType: get(offer, "visibilityType"),
      deviceType: get(offer, "deviceType"),
      excludeZipsMobile: get(offer, "excludeZipsMobile"),
      crmDestinationEmail: get(offer, "crmDestinationEmail"),
      leadSummaryRecipients: get(offer, "leadSummaryRecipients"),
      minSessionDuration: get(offer, "minSessionDuration"),
      maxSessionLength: get(offer, "maxSessionLength"),
      minVdpDuration: get(offer, "minVdpDuration"),
      uniquePageViews: get(offer, "uniquePageViews"),
      maxOffersPerSession: get(offer, "maxOffersPerSession"),
      leadLimit: get(offer, "leadLimit"),
      goalAttachmentNotification: get(offer, "goalAttachmentNotification"),
      makesToLimit: get(offer, "makesToLimit"),
      typesToLimit: get(offer, "typesToLimit"),
      makesToExclude: get(offer, "makesToExclude"),
      yearsToLimit: get(offer, "yearsToLimit"),
      showOnEveryVisit: get(offer, "showOnEveryVisit"),
      expirable: get(offer, "expirable"),
      includeUrls: get(offer, "includeUrls"),
      excludeUrls: get(offer, "excludeUrls"),
      includeZips: get(offer, "includeZips"),
      excludeZips: get(offer, "excludeZips"),
      termsAndConditions: get(offer, "termsAndConditions"),
      sendReceiptMessage: get(offer, "sendReceiptMessage"),
      showOnLeave: get(offer, "showOnLeave"),
      pullUpOffer: get(offer, "pullUpOffer"),
      popUpOffer: get(offer, "popUpOffer"),
      customerMessage: get(offer, "customerMessage"),
      modelsToExclude: get(offer, "modelsToExclude"),
      modelsToLimit: get(offer, "modelsToLimit"),
      engagementThreshold: get(offer, "engagementThreshold"),
      useEngagementScore: get(offer, "useEngagementScore"),
      staticEngagementThreshold: get(offer, "staticEngagementThreshold", false),
      startDate: offer.startDate
        ? moment(offer.startDate).format(Util.localDateFormat)
        : undefined,
      endDate: offer.endDate
        ? moment(offer.endDate).format(Util.localDateFormat)
        : undefined,
      pullUpOfferSource: get(offer, "pullUpOfferSource"),
      useSubSource: get(offer, "useSubSource"),
      crmSourceId: get(offer, "crmSource.id"),
      vinsToExclude: get(offer, "vinsToExclude"),
      vinsToLimit: get(offer, "vinsToLimit"),
      ignoreMakesToLimit: get(offer, "ignoreMakesToLimit"),
      ignoreModelsToLimit: get(offer, "ignoreModelsToLimit"),
      ignoreYearsToLimit: get(offer, "ignoreYearsToLimit"),
      ignoreVinsToLimit: get(offer, "ignoreVinsToLimit"),
    };
  };

  const transformNotificationsToDto = (
    notifications: IMyTrafficNotificationFull[]
  ): IMyTrafficNotificationDto[] => {
    const formattedDtos: IMyTrafficNotificationDto[] = notifications.map(
      (notification) => {
        return {
          ...((mode === MODE.EDIT || mode === MODE.DUPLICATE) && {
            id: notification?.id || null,
          }),
          name: notification?.name,
          dealershipId: dealershipContext.id,
          segment: notification?.segment,
          message: notification?.message,
          engagementScore: notification?.engagementScore,
          delaySend: notification?.delaySend,
          limitByZipCodes: notification?.limitByZipCodes,
          enabled: notification?.enabled,
          zipCodes: notification?.zipCodes,
          useChatBot: notification?.useChatBot,
          useSharkTank: notification?.useSharkTank,
          chatbotId: notification?.chatbotId,
          chatGreetingId: notification?.chatGreetingId,
          users: notification?.users?.map((user, index) => {
            return {
              userId: user?.userId,
              order: notification?.users?.length - index,
            };
          }),
          chatBotWorkflows: notification?.chatBotWorkflows?.map((item) => {
            return {
              chatBotId: item.chatBot.id,
              chatGreetingId: item.chatGreeting.id,
              daysOfWeek: item.daysOfWeek,
              endTime: item.endTime,
              startTime: item.startTime,
            };
          }),
        };
      }
    );
    return formattedDtos;
  };

  const transformChatGreetingToDto = (
    greeting: IChatGreeting
  ): IChatGreetingDto => {
    return {
      name: greeting?.name,
      message: greeting?.message,
      buttonLabel: greeting?.buttonLabel,
      backgroundColor: greeting?.backgroundColor,
      buttonBackgroundColor: greeting?.buttonBackgroundColor,
      dealershipId: dealershipContext?.id,
    };
  };

  const onCreate = () => {
    if (mode === MODE.NEW || mode === MODE.DUPLICATE) {
      Util.globalSpinner().show();
      createMyCampaign({
        dealershipId: dealershipContext?.id,
        active: campaignInfo?.active,
        knownVisitor: campaignInfo?.knownVisitor,
        name: campaignInfo?.name,
        vehicleFilters: campaignInfo?.filters,
        expirable: get(campaignInfo, "expirable"),
        startDate: campaignInfo?.startDate
          ? moment(campaignInfo?.startDate).format(Util.localDateFormat)
          : (undefined as any),
        endDate: campaignInfo?.endDate
          ? moment(campaignInfo?.endDate).format(Util.localDateFormat)
          : (undefined as any),
        ...(campaignInfo?.offer && {
          offer: transformOfferToDto(campaignInfo?.offer),
        }),
        automaticallyDisabled: campaignInfo?.automaticallyDisabled,
        demographicsFilter: props?.demographicsData,
        ...(campaignInfo?.greeting && {
          chatGreeting: transformChatGreetingToDto(campaignInfo?.greeting),
        }),
        ...(campaignInfo?.notifications &&
          campaignInfo?.notifications?.length > 0 && {
            rtsNotifications: transformNotificationsToDto(
              campaignInfo?.notifications
            ),
          }),
        ...(campaignInfo?.sources &&
          campaignInfo?.sources?.length > 0 && {
            semVendorIds: campaignInfo?.sources,
          }),
      })
        .then(() => {
          setInitialData(campaignInfo);
          setTimeout(() => {
            history?.push("/my-campaigns", { newCampaign: true });
          }, 1000);
        })
        .catch((error: any) => {
          Util.showError(error);
        })
        .finally(() => {
          Util.globalSpinner().hide();
        });
    } else {
      Util.globalSpinner().show();

      updateMyCampaign(campaignInfo?.id as number, {
        active: campaignInfo?.active,
        knownVisitor: campaignInfo?.knownVisitor,
        name: campaignInfo?.name,
        vehicleFilters: campaignInfo?.filters,
        expirable: get(campaignInfo, "expirable"),
        startDate: campaignInfo?.startDate
          ? moment(campaignInfo?.startDate).format(Util.localDateFormat)
          : (undefined as any),
        endDate: campaignInfo?.endDate
          ? moment(campaignInfo?.endDate).format(Util.localDateFormat)
          : (undefined as any),
        demographicsFilter: props?.demographicsData,
        ...(campaignInfo?.notifications &&
          campaignInfo?.notifications?.length > 0 && {
            rtsNotifications: transformNotificationsToDto(
              campaignInfo?.notifications
            ),
          }),
        automaticallyDisabled: campaignInfo?.automaticallyDisabled,
        ...(campaignInfo?.sources &&
          campaignInfo?.sources?.length > 0 && {
            semVendorIds: campaignInfo?.sources,
          }),
      })
        .then(() => {
          setInitialData(campaignInfo);
          setTimeout(() => {
            history?.push("/my-campaigns");
          }, 1000);
        })
        .catch((error: any) => {
          Util.showError(error);
        })
        .finally(() => {
          Util.globalSpinner().hide();
        });
    }
  };

  const onBack = () => {
    setStep((prev: any) => {
      return {
        ...prev,
        step: (prev?.step as number) - 1,
      };
    });
  };

  const previewBody = (props: IChatGreeting): React.ReactNode => {
    return (
      <div className={"rts-template-item"}>
        <div
          id={"rts-template-chat-permission-container"}
          style={{ backgroundColor: `#${props.backgroundColor}` }}
        >
          <img
            id={"rts-template-chat-permission-close-svg"}
            src={"assets/icons/close.svg"}
            alt={""}
          />
          <div id={"rts-template-chat-permission-header"}>{props.name}</div>
          <div id={"rts-template-chat-permission-question-container"}>
            <div id={"rts-template-chat-permission-question-header"}>
              {props.message}
            </div>
            <div
              id={"rts-template-chat-permission-question-button"}
              style={{ backgroundColor: `#${props.buttonBackgroundColor}` }}
            >
              <div id={"rts-template-chat-permission-question-yes"}>
                <span>
                  {props.buttonLabel}{" "}
                  <img
                    id={"rts-template-chat-permission-question-arrow"}
                    src={"assets/icons/arrow.svg"}
                    alt={""}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getOfferPreview = (offer: any): React.ReactElement | null => {
    return (
      <img
        alt=""
        className="offer-img"
        src={Util.getAssetUrl(
          offer?.asset?.objectId
            ? offer?.asset?.objectId
            : offer?.template?.assetObjectId
        )}
      />
    );
  };

  return (
    <Panel
      style={{ width: "100%", marginBottom: 10 }}
      header={"Campaign Summary"}
    >
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div className="choose-triggers-main-container">
            <label
              className="choose-triggers-label"
              style={{ margin: "10px 0" }}
            >
              Summary
            </label>
            <Panel>
              <div className="choose-triggers-section">
                <div className="p-lg-4 p-xl-4 p-sm-4">
                  <div>
                    <div className="choose-triggers-mini-container-behaviors">
                      {campaignInfo?.offer &&
                        getOfferPreview(campaignInfo?.offer)}
                      {campaignInfo?.greeting &&
                        previewBody(campaignInfo?.greeting)}
                    </div>
                  </div>
                </div>
                {campaignInfo?.offer && (
                  <div
                    style={{ margin: "20px 0" }}
                    className="p-lg-4 p-xl-4 p-sm-4 p-col-12 choose-triggers-behaviors"
                  >
                    <label className="choose-triggers-label">
                      Offer{" "}
                      <EditIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setStep({
                            step: CAMPAIGN_EDIT_TYPES.OFFER_EDIT,
                            payload: campaignInfo?.offer,
                          });
                        }}
                        sx={{ marginLeft: "5px" }}
                      />
                    </label>
                    <div>
                      {(
                        Object.keys(campaignInfo?.offer) as Array<keyof IOffer>
                      )?.map((key) => {
                        if (
                          typeof campaignInfo?.offer?.[key] !== "object" &&
                          // eslint-disable-next-line valid-typeof
                          typeof campaignInfo?.offer?.[key] !== null &&
                          typeof campaignInfo?.offer?.[key] !== undefined &&
                          key !== "pullUpOffer" &&
                          key !== "pullUpOfferSource"
                        )
                          return (
                            <div key={key} style={{ padding: "3px 0 3px 5px" }}>
                              <span
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >{`${key
                                .replace(
                                  /([A-Z])/g,
                                  (match: any) => ` ${match}`
                                )
                                .replace(/^./, (match: any) =>
                                  match.toUpperCase()
                                )
                                .trim()}: ${campaignInfo?.offer?.[key]}`}</span>
                            </div>
                          );
                        return null;
                      })}
                    </div>
                  </div>
                )}
                {campaignInfo?.greeting && (
                  <div
                    style={{ margin: "20px 0" }}
                    className="p-lg-4 p-xl-4 p-sm-4 choose-triggers-behaviors"
                  >
                    <label className="choose-triggers-label">
                      Greeting{" "}
                      <EditIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setStep({
                            step: CAMPAIGN_EDIT_TYPES.GREETING_EDIT,
                            payload: campaignInfo?.greeting,
                          });
                        }}
                        sx={{ marginLeft: "5px" }}
                      />
                    </label>
                    <div>
                      {(
                        Object.keys(campaignInfo?.greeting) as Array<
                          keyof IChatGreeting
                        >
                      )?.map((key: any) => {
                        return (
                          // @ts-ignore
                          <div key={key} style={{ padding: "3px 0 3px 5px" }}>
                            <span
                              style={{ fontWeight: "bold", fontSize: "16px" }}
                            >{`${key
                              .replace(/([A-Z])/g, (match: any) => ` ${match}`)
                              .replace(/^./, (match: any) =>
                                match.toUpperCase()
                              )
                              //@ts-ignore
                              .trim()}: ${
                              campaignInfo?.greeting?.[key]
                            }`}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {campaignInfo?.notifications &&
                  campaignInfo?.notifications?.length > 0 && (
                    <div
                      style={{ margin: "20px 0" }}
                      className="p-lg-3 p-xl-3 choose-triggers-behaviors"
                    >
                      <label className="choose-triggers-label">
                        Notifications
                      </label>
                      <div style={{ overflow: "hidden" }}>
                        {campaignInfo?.notifications?.map((notif: any) => {
                          return (
                            // @ts-ignore
                            <div
                              key={notif?.name}
                              style={{ padding: "3px 0 3px 5px" }}
                            >
                              <span
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                {notif?.name}{" "}
                                <EditIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setStep({
                                      step: CAMPAIGN_EDIT_TYPES.NOTIFICATION_EDIT,
                                      payload: {
                                        notifId: notif?.id,
                                      },
                                    });
                                  }}
                                  sx={{ marginLeft: "5px" }}
                                />
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
              </div>
            </Panel>
            <label
              className="choose-triggers-label"
              style={{ margin: "10px 0" }}
            >
              Campaign Filters
            </label>
            <Panel>
              <div
                className={
                  "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"
                }
              >
                <DataTable
                  tableStyle={{ overflow: "visible" }}
                  resizableColumns={true}
                  columnResizeMode={"expand"}
                  value={campaignInfo?.filters || []}
                >
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field="year"
                    header="Year"
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field="make"
                    header="Make"
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field="model"
                    header="Model"
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field="trim"
                    header="Trim"
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field="body"
                    header="Body"
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field="vin"
                    header="VIN"
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field="stockNumber"
                    header="Stock Number"
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field="price"
                    header="Price"
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field="vehicleType"
                    header="Type"
                    body={(rowData: any) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>{rowData?.vehicleType}</span>
                      </div>
                    )}
                  />
                  <Column
                    style={{ background: "white", cursor: "default" }}
                    field="daysInStock"
                    header="DIS"
                    body={(rowData: any) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {startCase(rowData?.daysInStockType)?.toUpperCase()}:{" "}
                          {rowData?.daysInStock}
                        </span>
                      </div>
                    )}
                  />
                </DataTable>
              </div>
              {matchingVehicles?.length == 0 && (
                <p
                  style={{
                    color: "red",
                    fontWeight: "bolder",
                  }}
                >
                  There are no vehicles, matching your filtration criteria. The
                  campaign will be set to “Inactive“ until vehicles, matching
                  the criteria become available.
                </p>
              )}
            </Panel>
            <label
              className="choose-triggers-label"
              style={{ margin: "10px 0" }}
            >
              Additional Settings
            </label>
            <Panel>
              <div className="p-grid" style={{ padding: "0 30px" }}>
                <div className="p-xs-12 p-sm-6">
                  <label className="choose-triggers-label">Sources</label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      maxHeight: "500px",
                      overflowY: "auto",
                    }}
                  >
                    <div className="choose-triggers-mini-container-sources">
                      <Checkbox
                        checked={allSourcesCheckboxChecked}
                        onChange={(e) => {
                          if (e?.checked) {
                            if (!allSourcesCheckboxChecked) {
                              setCampaignInfo((prev: any) => {
                                return {
                                  ...prev,
                                  sources: [],
                                };
                              });
                              setAllSourcesCheckboxChecked(true);
                            }
                          } else {
                            const sourcesIds = sources?.data?.map(
                              (source) => source?.id
                            );
                            setCampaignInfo((prev: any) => {
                              return {
                                ...prev,
                                sources: sourcesIds,
                              };
                            });
                            setAllSourcesCheckboxChecked(false);
                          }
                        }}
                      />
                      <span>All sources</span>
                    </div>
                    {sources?.data?.map((source: any) => {
                      return (
                        <div
                          className="choose-triggers-mini-container-sources"
                          key={source?.id}
                        >
                          <Checkbox
                            checked={
                              campaignInfo?.sources?.some(
                                (m: any) =>
                                  m?.id === source?.id || m === source?.id
                              ) && !allSourcesCheckboxChecked
                            }
                            onChange={(e) => {
                              if (e?.checked) {
                                setCampaignInfo((prev: any) => {
                                  return {
                                    ...prev,
                                    sources:
                                      prev?.sources?.length > 0
                                        ? allSourcesCheckboxChecked
                                          ? [source?.id]
                                          : [...prev?.sources, source?.id]
                                        : [source?.id],
                                  };
                                });
                                setAllSourcesCheckboxChecked(false);
                              } else {
                                const filteredArray =
                                  campaignInfo?.sources?.filter(
                                    (selectedSource: any) =>
                                      selectedSource !== source?.id
                                  );
                                setCampaignInfo((prev) => {
                                  return {
                                    ...prev,
                                    sources: filteredArray,
                                  };
                                });
                              }
                            }}
                          />
                          <span>{source?.name}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="p-xs-12 p-sm-6">
                  <label className="choose-triggers-label">Options</label>
                  <div>
                    <div className="choose-triggers-mini-container-sources">
                      <InputSwitch
                        style={{ marginTop: "3px" }}
                        onChange={() => {
                          setCampaignInfo((prev) => {
                            return {
                              ...prev,
                              knownVisitor: !prev?.knownVisitor,
                            };
                          });
                        }}
                        checked={campaignInfo?.knownVisitor}
                      />
                      <span>Exclude Known Visitors</span>
                    </div>
                    <div className="choose-triggers-mini-container-sources">
                      <InputSwitch
                        style={{ marginTop: "3px" }}
                        onChange={() => {
                          setCampaignInfo((prev) => {
                            return {
                              ...prev,
                              active: !prev?.active,
                            };
                          });
                        }}
                        checked={campaignInfo?.active}
                      />
                      <span>Active</span>
                    </div>
                    <div className="choose-triggers-mini-container-sources">
                      <label style={{ marginTop: 5 }}>Expirable</label>
                      <div style={{ marginTop: 7, marginLeft: 5 }}>
                        <InputSwitch
                          checked={campaignInfo?.expirable}
                          onChange={(e: any) =>
                            setCampaignInfo((prev) => {
                              return {
                                ...prev,
                                expirable: !prev?.expirable,
                              };
                            })
                          }
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <label
                          style={{ marginTop: 5 }}
                          className={"input-switch-label"}
                        >
                          {campaignInfo?.expirable
                            ? "Start-End Date"
                            : "Start Date"}
                        </label>
                        <Calendar
                          id={IDS.calendar.startTime}
                          value={dates2}
                          onChange={(e: any) => {
                            setDates2(e.value);
                          }}
                          selectionMode={
                            campaignInfo.expirable ? "range" : undefined
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              onClick={onBack}
              className={"p-button-warning"}
              icon={"pi pi-arrow-left"}
              label={"Back"}
            />
            <Button
              style={{ marginRight: 5 }}
              label={`${
                mode === MODE.NEW
                  ? "Create"
                  : mode === MODE.DUPLICATE
                  ? "Duplicate"
                  : "Update and Exit"
              }`}
              icon={"pi pi-save"}
              onClick={() => onCreate()}
              disabled={
                mode === MODE.NEW ? false : isEqual(campaignInfo, initialData)
              }
            />
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default ChooseTriggers;
