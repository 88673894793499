import React, {forwardRef} from 'react';
import Util from "./util/Util";
import {shallowEqual, useSelector} from "react-redux";
import {IStore} from "../index.dts";

const AppBreadcrumb = forwardRef((props: any, ref: any) => {
    const location = props.location;
    const paths = location.pathname.split('/');

    const dealership = useSelector((store: IStore) => store.auth.dealershipContext, shallowEqual);

    const onClick = (path: string) => {
        if (!path.trim())
            props.history.push(Util.PATH_NAMES.DASHBOARD);

        props.history.push(`/${path}`);
    };

    const getTimezoneLabel = (timezone: string): string => {
        const timezones = Util.getTimezones();

        const index = timezones.findIndex(item => item.value === timezone);

        if (index !== -1) {
            return timezones[index].label
        }

        return ""
    }

    return (
        <div className="layout-breadcrumb">
            <ul>
                <li>
                    <button style={{fontSize: 14}} onClick={() => props.history.push(Util.PATH_NAMES.DASHBOARD)}
                            className="p-link"><i
                        className="pi pi-fw pi-home"/>
                    </button>
                </li>
                {
                    props.location.pathname === '/' ?
                        null : paths.map((path: string, index: number) => {
                            if (index === paths.length - 1) {
                                return (
                                    <li key={index}>
                                        <span>
                                          {path.replace("-", " ")}
                                         </span>
                                    </li>
                                )
                            }
                            return (
                                <li key={index}>
                                <span
                                    onClick={() => onClick(path)}
                                    style={{cursor: "pointer", color: "#39a3f4"}}
                                >{path.replace("-", " ")}
                                </span> |
                                </li>
                            )
                        })
                }
            </ul>
            <span style={{float: "right", marginTop: 12, color: "gray"}}>{getTimezoneLabel(dealership.timezone)}</span>
        </div>
    )
});


export default AppBreadcrumb
