import {BaseChartDataset} from "./base_chart_dataset";
import {ChartConfig} from "../chart_config";
import Util from "../../Util";

export class ModelDataSet extends BaseChartDataset {
  constructor(args: {
    label: string,
    data?: number[],
    yAxisID: string,
    color?: string;
  }) {
    super({
      backgroundColor: args.color || Util.stringToColor(args.label),
      borderColor: args.color || Util.stringToColor(args.label),
      borderWidth: ChartConfig.border.width,
      color: args.color || Util.stringToColor(args.label),
      data: args.data ?? [],
      label: args.label,
      yAxisID: args.yAxisID,
    });
  }
}