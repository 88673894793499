import { ExternalServiceCallSearchRequestDto, AutoAcquireGeneralReportSearchRequestDto } from "../../index.dts";
import Axios from "../config/Axios";

export const exportLeads = (startDate: string, endDate: string, type: string) =>
  Axios.instance.get(
    `reports/performance?startDate=${startDate}&endDate=${endDate}&sales=${type}`
  );

export const getPerformanceReport = (
  reportObj: ExternalServiceCallSearchRequestDto
) => Axios.instance.post("reports/external_service_calls", reportObj);

export const getAutoAcquireReport = (
  reportObj: AutoAcquireGeneralReportSearchRequestDto
) => Axios.instance.post("reports/export-general-my-acquisitions-report", reportObj);
