import React from "react";
import {Redirect, Route, RouteComponentProps, useHistory} from 'react-router-dom';
import store from "./store";
import Util from "../util/Util";

interface Props {
    path: string;
    exact: boolean;
    Component: React.FC<RouteComponentProps> | any;
}

export const RouteAccess = ({Component, path, exact}: Props): JSX.Element => {
    const history = useHistory();

    const permissions = store.getState().auth.user.role.permissions;
    const path2 = Util.getRouteByPermission(permissions, path, history);

    const hasAccess: boolean = path === path2;
    return (
        <Route
            path={path}
            exact={exact}
            render={(props: RouteComponentProps) =>
                hasAccess ? (
                    <Component {...props}/>
                ) : (
                    <Redirect to={{
                        pathname: path2,
                        state: {path: path}
                    }}/>
                )
            }
        />
    )
};
