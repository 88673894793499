import {
  AxiosResponse,
  IEngagementLeadDto,
  IEngagementLeadRequestDto,
  ILeadDto,
  ILeadTypeDto,
  IMyTrafficLeadsRequestDto,
  IPageable,
  IRtsVdpStatsLeadsRequestDto,
} from "../../index.dts";
import Axios from "../config/Axios";

export const getMyTrafficLeads = (
  dto: IMyTrafficLeadsRequestDto
): Promise<AxiosResponse<ILeadDto>> =>
  Axios.instance.post(`leads/new-version/mytraffic/list`, dto);
export const getEngagementLeads = (
  dto: IEngagementLeadRequestDto
): Promise<IPageable<IEngagementLeadDto>> =>
  Axios.instance.post(`leads/engagement/search`, dto);
export const getEngagementLeadsType = (
  dto: IEngagementLeadRequestDto
): Promise<AxiosResponse<ILeadTypeDto>> =>
  Axios.instance.post(`leads/engagement/search/leadType`, dto);
export const saveEngagementLeadHistory = (dto: any) =>
  Axios.instance.post("leads/history", dto);
export const findMyTrafficLeads = (
  dto: IRtsVdpStatsLeadsRequestDto
): Promise<IPageable> => Axios.instance.post(`leads/vdpStatistics/list`, dto);
