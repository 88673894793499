import React, { useEffect, useRef, useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import "./color_picker_component.css";

const ColorPickerComponent = (props: any) => {
  const pickerRef = useRef<any>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const setParentColor = (e: any) => {
    props.setColor(e);
  };

  return (
    <div>
      <div
        ref={pickerRef}
        className={`color-picker-container ${
          isExpanded ? "expanded" : "collapsed"
        }`}
        onClick={() => setIsExpanded(true)}
      >
        <HexColorPicker color={props?.color} onChange={setParentColor} />
        <HexColorInput color={props?.color} onChange={setParentColor} />
      </div>
      <p>Selected Color: {props?.color}</p>
    </div>
  );
};

export default ColorPickerComponent;
