import {shallowEqual, useSelector} from "react-redux";

import {IStore} from "../../index.dts";
import PageTypes, {PageTypesUtil} from "./enum/page_types";
import Util from "./Util";

export default class Converters {
  protected static get pageTypeConfig(): { [key: string]: string[] } {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSelector((store: IStore) => store.myTraffic.swimLanesConfig, shallowEqual);
  }

  static pageClassToPageType(pageClass: string): string {
    for (const [key, value] of Object.entries(this.pageTypeConfig)) {
      const valuesArray: string[] = Util.getDefaultIfNull(value, []);
      const index = valuesArray.findIndex(item => item.toLowerCase() === pageClass.toLowerCase());

      if (index !== -1) {
        return key;
      }
    }

    return PageTypesUtil.getName(PageTypes.other)
  }
}