import React, { useEffect, useRef, useState } from "react";
import Util from "../../util/Util";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-html";
import { IDS } from "../constants";
import { isEqual } from "lodash";
import { CAMPAIGN_EDIT_TYPES, MODE } from "../../util/Enums";
import ChooseCar from "./components/ChooseCar";
import SelectActions from "./components/SelectActions";
import CampaignSummary from "./components/CampaignSummary";
import CampaignOfferEdit from "./components/CampaignOfferEdit";
import CampaignGreetingEdit from "./components/CampaignGreetingEdit";
import CampaignNotificationsEdit from "./components/CampaignNotificationsEdit";
import { useSelector, shallowEqual } from "react-redux";
import { ICampaignInfo, IStore } from "../../../index.dts";
import CampaignActionsEdit from "./components/CampaignActionsEdit";
import {
  getFilteredCamapignFiltersList,
  getMyCampaign,
} from "../../actions/campaignsActions";
import moment from "moment";
import DemographicsPanel from "./components/DemographicsPanel";

export function MyCampaignsForm(props: any) {
  const { location } = props;
  const history = useHistory();
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  const unblockHandle = useRef<any>();
  const [campaignInfo, setCampaignInfo] = useState<ICampaignInfo>({
    name: "",
    filters: [],
    greeting: undefined,
    notifications: undefined,
    offer: undefined,
    knownVisitor: false,
    sources: [],
    active: true,
    expirable: false,
    startDate: moment(new Date()).format(Util.localDateFormat),
    endDate: null,
  });
  const [initialData, setInitialData] = useState<any>();
  const [demographicsData, setDemographicsData] = useState<any>();
  const [step, setStep] = useState<{ step: number | string; payload?: any }>({
    step: 0,
  });
  const [matchingVehicles, setMatchingVehicles] = useState<any>([]);
  const [noVehicleMessage, setNoVehicleMessage] = useState<string>("");

  useEffect(() => {
    unblockHandle.current = history.block((targetLocation: any) => {
      return onLeave(targetLocation);
    });
    return () => {
      unblockHandle.current.current && unblockHandle.current.current();
    };
    // eslint-disable-next-line
  }, [history.location, campaignInfo, initialData]);

  const getVehiclesList = (newFilters: any) =>
    getFilteredCamapignFiltersList({
      dealershipId: dealershipContext?.id,
      filters: newFilters,
    })
      .then((response: any) => {
        // setLoading(false);
        if (
          response?.data?.makes?.length == 0 &&
          response?.data?.models?.length == 0 &&
          response?.data?.types?.length == 0 &&
          response?.data?.vins?.length == 0 &&
          response?.data?.years?.length == 0
        ) {
          setNoVehicleMessage(
            "There are no vehicles, matching your filtration criteria. The campaign will be set to “Inactive“ until vehicles, matching the criteria become available."
          );
        } else {
          setNoVehicleMessage("");
        }
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => {
        // setLoading(false);
      });

  const getCalendarDates = (): Date[] | Date => {
    if (campaignInfo?.expirable) {
      const dates: Date[] = [];
      if (campaignInfo?.startDate) {
        dates.push(moment(campaignInfo?.startDate).toDate());

        if (campaignInfo?.endDate) {
          dates.push(moment(campaignInfo?.endDate).toDate());
        }
      }
      return dates;
    }
    return moment(campaignInfo?.startDate).toDate();
  };

  useEffect(() => {
    const mode = location?.state?.mode;
    if (mode === MODE.EDIT && location?.state?.campaignId) {
      Util.globalSpinner().show();
      getMyCampaign(location?.state?.campaignId)
        .then((response: any) => {
          getVehiclesList(response?.data?.vehicleFilters);
          const reformattedContent: ICampaignInfo = {
            active: response?.data?.active,
            filters: response?.data?.vehicleFilters,
            knownVisitor: response?.data?.knownVisitor,
            name: response?.data?.name,
            sources: response?.data?.semVendors?.map(
              (semVendor: any) => semVendor?.id
            ),
            greeting: response?.data?.chatGreeting,
            id: response?.data?.id,
            notifications: response?.data?.rtsNotifications,
            offer: response?.data?.offer,
            created: response?.data?.created,
            startDate: response?.data?.startDate,
            endDate: response?.data?.endDate,
            expirable: response?.data?.expirable,
            demographicsFilter: response?.data?.demographicsFilter,
            automaticallyDisabled: response?.data?.automaticallyDisabled,
          };
          setCampaignInfo(reformattedContent);
          setInitialData(reformattedContent);
        })
        .catch((error: any) => {
          Util.showError(error);
        })
        .finally(() => {
          Util.globalSpinner().hide();
        });
    } else if (mode === MODE.DUPLICATE && location?.state?.campaignId) {
      Util.globalSpinner().show();
      getMyCampaign(location?.state?.campaignId)
      .then((response: any) => {
        const vehicleFiltersWithoutSpecificFields = Array.isArray(response?.data?.vehicleFilters)
          ? response?.data?.vehicleFilters.map((filter: any) => {
          const { id, created, updated, createdBy, updatedBy, ...rest } = filter || {};
          return rest;
        })
        : [];
        
        // Check if demographicsFilter exist and are an object before mapping
        const { id, created, updated, createdBy, updatedBy, ...demographicsFilterWithoutSpecificFields } = response?.data?.demographicsFilter || {};
        const reformattedContent: ICampaignInfo = {
          active: response?.data?.active,
          filters: vehicleFiltersWithoutSpecificFields,
          knownVisitor: response?.data?.knownVisitor,
          name: response?.data?.name + " - duplicate",
          sources: response?.data?.semVendors?.map(
            (semVendor: any) => semVendor?.id
          ),
          greeting: response?.data?.chatGreeting,
          //id: response?.data?.id,
          notifications: response?.data?.rtsNotifications,
          offer: response?.data?.offer,
          //created: response?.data?.created,
          startDate: response?.data?.startDate,
          endDate: response?.data?.endDate,
          expirable: response?.data?.expirable,
          demographicsFilter: demographicsFilterWithoutSpecificFields,
          automaticallyDisabled: response?.data?.automaticallyDisabled,
        };
      
        setCampaignInfo(reformattedContent);
        setInitialData(reformattedContent);
      })
      .catch((error: any) => {
        Util.showError(error);
      })
      .finally(() => {
        Util.globalSpinner().hide();
      });
    }
    return () => {
      history.block(() => {
        return true;
      });
    };
    // eslint-disable-next-line
    }, []);

  const onLeave = (targetLocation: any): boolean => {
    if (!isEqual(campaignInfo, initialData)) {
      confirmAlert({
        title: "There are unsaved changes",
        message: "If you leave this page your progress will be lost",
        buttons: [
          {
            label: "Continue",
            className: "confirm-save-btn",
            onClick: () => {},
          },
          {
            label: "Leave",
            onClick: () => {
              setInitialData(campaignInfo);
              setTimeout(() => {
                history.push(targetLocation);
              }, 0);
            },
            className: "confirm-discard-btn",
          },
        ],
      });
      return false;
    }
    return true;
  };

  const renderCurrentStep = () => {
    switch (step?.step) {
      case 0:
        return (
          <ChooseCar
            setStep={setStep}
            noVehicleMessage={noVehicleMessage}
            getVehiclesList={getVehiclesList}
            campaignInfo={campaignInfo}
            setCampaignInfo={setCampaignInfo}
            setMatchingVehicles={setMatchingVehicles}
            mode={location?.state?.mode}
            initialData={initialData}
            setInitialData={setInitialData}
          />
        );
      case 1:
        return (
          <SelectActions
            setCampaignInfo={setCampaignInfo}
            campaignInfo={campaignInfo}
            setStep={setStep}
            mode={location?.state?.mode}
            initialData={initialData}
            setInitialData={setInitialData}
          />
        );
      case 2:
        return (
          <CampaignActionsEdit
            campaignInfo={campaignInfo}
            setStep={setStep}
            mode={location?.state?.mode}
            initialData={initialData}
            setInitialData={setInitialData}
          />
        );
      case 3:
        return dealershipContext.m1Enabled ? (
          <DemographicsPanel
            mode={location?.state?.mode}
            campaignInfo={campaignInfo}
            setStep={setStep}
            initialData={initialData}
            setInitialData={setInitialData}
            demographicsData={demographicsData}
            setDemographicsData={setDemographicsData}
          />
        ) : (
          <CampaignSummary
            setCampaignInfo={setCampaignInfo}
            setStep={setStep}
            campaignInfo={campaignInfo}
            matchingVehicles={matchingVehicles}
            mode={location?.state?.mode}
            initialData={initialData}
            demographicsData={demographicsData}
            setInitialData={setInitialData}
            calendarDates={getCalendarDates()}
          />
        );
      case 4:
        return (
          dealershipContext.m1Enabled && (
            <CampaignSummary
              setCampaignInfo={setCampaignInfo}
              setStep={setStep}
              campaignInfo={campaignInfo}
              matchingVehicles={matchingVehicles}
              mode={location?.state?.mode}
              initialData={initialData}
              demographicsData={demographicsData}
              setInitialData={setInitialData}
              calendarDates={getCalendarDates()}
            />
          )
        );
      case CAMPAIGN_EDIT_TYPES.OFFER_EDIT:
        if (campaignInfo?.offer?.id) {
          return (
            <CampaignOfferEdit
              campaignMode={location?.state?.mode}
              campaignOfferInfo={campaignInfo}
              setStep={setStep}
              setCampaignInfo={setCampaignInfo}
              previousPage={step?.payload}
            />
          );
        } else {
          setStep((prev) => {
            return {
              ...prev,
              step: (prev?.step as number) + 1,
            };
          });
        }
        break;
      case CAMPAIGN_EDIT_TYPES.GREETING_EDIT:
        if (campaignInfo?.greeting?.id) {
          return (
            <CampaignGreetingEdit
              campaignInfo={campaignInfo}
              setStep={setStep}
              setCampaignInfo={setCampaignInfo}
              mode={location?.state?.mode}
              previousPage={step?.payload}
            />
          );
        } else {
          setStep((prev) => {
            return {
              ...prev,
              step: (prev?.step as number) + 1,
            };
          });
        }
        break;
      case CAMPAIGN_EDIT_TYPES.NOTIFICATION_EDIT:
        if (
          campaignInfo?.notifications &&
          campaignInfo?.notifications?.length > 0
        ) {
          return (
            <CampaignNotificationsEdit
              campaignMode={location?.state?.mode}
              selectedNotification={campaignInfo?.notifications?.find(
                (notification: any) =>
                  notification?.id === step?.payload?.notifId
              )}
              setStep={setStep}
              campaignInfo={campaignInfo}
              setCampaignInfo={setCampaignInfo}
              location={{
                state: {
                  dealershipId: dealershipContext.id,
                  dealershipExtRefId: dealershipContext.externalRefId,
                  mode: MODE.EDIT,
                  notificationId: campaignInfo?.notifications?.find(
                    (notification: any) =>
                      notification?.id === step?.payload?.notifId
                  )?.id,
                },
              }}
              previousPage={step?.payload?.previousPage}
            />
          );
        } else {
          setStep((prev) => {
            return {
              ...prev,
              step: (prev?.step as number) + 1,
            };
          });
        }
        break;

      default:
        return null;
    }
  };

  return (
    <div id={IDS.offersForm.wrapper}>
      <div className="card card-w-title datatable-demo">
        {renderCurrentStep()}
      </div>
    </div>
  );
}

export default MyCampaignsForm;
