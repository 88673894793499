import React, { useEffect, useRef, useState } from "react";
import "./event_alert.css";
import { Panel } from "primereact/panel";
import {
  IAlertType,
  MODE,
  TrafficAlertCampaignType,
} from "../../../../util/Enums";
import { InputText } from "primereact/inputtext";
import Util from "../../../../util/Util";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import {
  IStore,
  ITrafficAlert,
  ITrafficAlertConfig,
  ITrafficAlertDto,
} from "../../../../../index.dts";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import {
  createTrafficAlert,
  getTrafficAlert,
  updateTrafficAlert,
} from "../../../../actions/trafficAlertActions";
import { useForceUpdate } from "../../../../hooks/useForceUpdate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { confirmAlert } from "react-confirm-alert";
// import {
//   SortableContainer,
//   SortableElement,
//   SortableHandle,
// } from "react-sortable-hoc";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { getNotificationUsers } from "../../../../actions/MyTrafficNotificationActions";
import { shallowEqual, useSelector } from "react-redux";

const hash = require("object-hash");

interface IProps {
  location: {
    state: {
      id?: number;
      mode: MODE;
      dealershipId: number;
    };
  };
}

enum ConfigurationFields {
  VARIANCE_DAYS = "varianceDays",
  VARIANCE_PERCENTAGE = "variancePercentage",
  MOVING_AVERAGE_DAYS = "movingAverageDays",
}

const warnings: { [key: string]: string } = {};

const SortableSelect = AsyncSelect;

const EventAlertsEditForm = (props: IProps) => {
  const history = useHistory();
  const unblockHandle = useRef<any>();
  const forceUpdate = useForceUpdate();

  const [users, setUsers] = useState<any[]>([]);
  const [mode] = useState(Util._.get(props, "location.state.mode", MODE.NEW));
  const user = useSelector((store: IStore) => store.auth.user, shallowEqual);

  const [name, setName] = useState<string>("");
  const [ownerId, setOwnerId] = useState<number>(user.id);
  const [type, setType] = useState<IAlertType>(IAlertType.VISITS);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [config, setConfig] = useState<{ [key: string]: ITrafficAlertConfig }>({
    [TrafficAlertCampaignType.TOTAL]: {
      variancePercentage: 1,
      varianceDays: 1,
      movingAverageDays: 1,
    },
  });

  const [tempState, setTempState] = useState<ITrafficAlertDto>({
    name: "",
    alertType: IAlertType.VISITS,
    startDate: null,
    endDate: null,
    configs: {
      [TrafficAlertCampaignType.TOTAL]: {
        variancePercentage: 1,
        varianceDays: 1,
        movingAverageDays: 1,
      },
    },
    dealershipId: props.location.state.dealershipId,
  });

  useEffect(() => {
    if (mode === MODE.EDIT) {
      const alertId = Util._.get(props, "location.state.id", null);

      getAlert(alertId);
    }
    return () => {
      history.block(() => {
        return true;
      });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    unblockHandle.current = history.block((targetLocation: any) => {
      return onLeave(targetLocation);
    });
    return () => {
      unblockHandle.current.current && unblockHandle.current.current();
    };
    // eslint-disable-next-line
  }, [
    history.location,
    tempState,
    name,
    hash(config),
    startDate,
    endDate,
    type,
  ]);

  const onLeave = (targetLocation: any): boolean => {
    const dto: ITrafficAlertDto = {
      dealershipId: props.location.state.dealershipId,
      startDate: startDate,
      endDate: endDate,
      name: name,
      alertType: type,
      configs: config,
    };

    if (!Util._.isEqual(dto, tempState)) {
      confirmAlert({
        title: "There are unsaved changes!",
        message: "Discard local changes, or save to continue!",
        buttons: [
          {
            label: "Save",
            className: "confirm-save-btn",
            onClick: () => {
              onSave(targetLocation);
            },
          },
          {
            label: "Discard",
            onClick: () => {
              setTempState(dto);
              setTimeout(() => {
                history.push(targetLocation);
              }, 0);
            },
            className: "confirm-discard-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
      return false;
    }
    return true;
  };

  const getAlert = (alertId: number): void => {
    Util.globalSpinner().show();
    getTrafficAlert(alertId)
      .then((response) => {
        const data: ITrafficAlert = Util.getDefaultIfNull(
          Util._.get(response, "data", null),
          {}
        );

        const users = Util.getDefaultIfNull(data.users, []);

        setName(data.name);
        setType(data.alertType);
        setStartDate(data.startDate);
        setEndDate(data.endDate);
        setConfig(data.configs);
        setOwnerId(data.owner.id);
        setUsers(
          users.map((item: any) => {
            return {
              value: item.id,
              label: item.email,
            };
          })
        );

        setTemporaryState(data);
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => Util.globalSpinner().hide());
  };

  const setTemporaryState = (data: ITrafficAlert): void => {
    const tempData = JSON.parse(JSON.stringify(data));

    setTempState({
      name: tempData.name,
      alertType: tempData.alertType,
      startDate: tempData.startDate,
      endDate: tempData.endDate,
      configs: tempData.configs,
      dealershipId: tempData.dealershipId,
    });
  };

  const onCreate = (targetLocation?: any): void => {
    const dto: ITrafficAlertDto = {
      dealershipId: props.location.state.dealershipId,
      startDate: startDate,
      endDate: endDate,
      name: name,
      alertType: type,
      configs: config,
      userIds: users.map((item) => item.value),
    };

    if (!validateDto(dto)) {
      forceUpdate();
      return;
    }

    Util.globalSpinner().show();
    createTrafficAlert(dto)
      .then((response) => {
        setTemporaryState(response.data);
        Util.success("Traffic Alert created successfully");

        setTimeout(() => {
          if (targetLocation) {
            history.push(targetLocation);
          } else {
            onBack();
          }
        });
      })
      .catch((error) => Util.showError(error))
      .finally(() => Util.globalSpinner().hide());
  };

  const onUpdate = (targetLocation?: any): void => {
    const dto: ITrafficAlertDto = {
      dealershipId: props.location.state.dealershipId,
      startDate: startDate,
      endDate: endDate,
      name: name,
      alertType: type,
      configs: config,
      userIds: users.map((item) => item.value),
    };

    if (!validateDto(dto)) {
      forceUpdate();
      return;
    }

    const alertId = Util._.get(props, "location.state.id", null);

    Util.globalSpinner().show();
    updateTrafficAlert(alertId, dto)
      .then((response) => {
        setTemporaryState(response.data);
        Util.success("Traffic Alert updated successfully");

        setTimeout(() => {
          if (targetLocation) {
            history.push(targetLocation);
          } else {
            onBack();
          }
        });
      })
      .catch((error) => Util.showError(error))
      .finally(() => Util.globalSpinner().hide());
  };

  const validateDto = (dto: ITrafficAlertDto): boolean => {
    Util.emptyObject(warnings);

    if (Util.isEmpty(name)) {
      warnings["name"] = "Traffic Alert Name is empty.";
    } else if (name.length > 255) {
      warnings["name"] = "Traffic Alert Name is too long.";
    }

    if (Util.isEmpty(type)) {
      warnings["type"] = "Traffic Alert Type is empty.";
    }

    if (Util.isEmpty(startDate)) {
      warnings["startDate"] = "Traffic Alert Start Date is empty.";
    } else if (
      !Util.isEmpty(endDate) &&
      moment(startDate).isSameOrAfter(moment(endDate))
    ) {
      warnings[
        "endDate"
      ] = `Traffic Alert End Date {${endDate}} should be after Start Date {${startDate}}.`;
    }

    if (Object.keys(config).length === 0) {
      warnings["config"] =
        "Traffic Alert Campaign Type is not set. Please choose Campaign Type.";
    }

    for (const [key, value] of Object.entries(config)) {
      const varianceDays = Util.getDefaultIfNull(value.varianceDays, 0);
      const movingAverageDays = Util.getDefaultIfNull(
        value.movingAverageDays,
        0
      );
      const variancePercentage = Util.getDefaultIfNull(
        value.variancePercentage,
        0
      );

      if (variancePercentage < 1 || variancePercentage > 1000) {
        warnings[
          `${key}_variancePercentage`
        ] = `Traffic Alert percentage is out of range ${variancePercentage} [1 - 1000]. Please set corresponding value.`;
      }

      if (varianceDays < 1 || varianceDays > 90) {
        warnings[
          `${key}_varianceDays`
        ] = `Traffic Alert variance days is out of range ${varianceDays} [1-90]. Please set corresponding value.`;
      }

      if (movingAverageDays < 1 || movingAverageDays > 90) {
        warnings[
          `${key}_movingAverageDays`
        ] = `Traffic Alert moving average days is out of range ${movingAverageDays} [1-90]. Please set corresponding value.`;
      }

      if (movingAverageDays < varianceDays) {
        warnings[
          `${key}_movingAverageDays`
        ] = `Traffic Alert moving average {${movingAverageDays}} days should be greater than variance days {${varianceDays}}. Please set corresponding value.`;
      }
    }

    return Object.keys(warnings).length === 0;
  };

  const onSave = (targetLocation?: any): void => {
    if (mode === MODE.NEW) {
      onCreate(targetLocation);
    } else {
      onUpdate(targetLocation);
    }
  };

  const onBack = () => {
    history.push(Util.PATH_NAMES.MY_TRAFFIC, { activeIndex: 3 });
  };

  const getConfigButtons = (): React.ReactNode => {
    const buttons: React.ReactNode[] = [];

    if (!config[TrafficAlertCampaignType.TOTAL]) {
      buttons.push(
        <Button
          key={0}
          onClick={onPlusTotalClick}
          label={"Total"}
          icon={"pi pi-plus"}
        />
      );
    }

    if (!config[TrafficAlertCampaignType.ORGANIC]) {
      buttons.push(
        <Button
          key={1}
          onClick={onPlusOrganicClick}
          label={"Organic"}
          icon={"pi pi-plus"}
          className={"p-button-warning"}
        />
      );
    }

    if (!config[TrafficAlertCampaignType.PAID]) {
      buttons.push(
        <Button
          key={2}
          onClick={onPlusPaidClick}
          label={"Paid"}
          icon={"pi pi-plus"}
          className={"p-button-danger"}
        />
      );
    }

    return buttons;
  };

  const onPlusTotalClick = (): void => {
    config[TrafficAlertCampaignType.TOTAL] = {
      variancePercentage: 1,
      varianceDays: 1,
      movingAverageDays: 1,
    };
    forceUpdate();
  };

  const onPlusOrganicClick = (): void => {
    config[TrafficAlertCampaignType.ORGANIC] = {
      variancePercentage: 1,
      varianceDays: 1,
      movingAverageDays: 1,
    };
    forceUpdate();
  };

  const onPlusPaidClick = (): void => {
    config[TrafficAlertCampaignType.PAID] = {
      variancePercentage: 1,
      varianceDays: 1,
      movingAverageDays: 1,
    };
    forceUpdate();
  };

  const onRemove = (key: string): void => {
    delete config[key];

    forceUpdate();
  };

  const removeTemplate = (data: any) => {
    return (
      <Button
        style={{ width: 35, minWidth: 35 }}
        icon={"pi pi-minus"}
        className={"p-button-danger"}
        onClick={() => onRemove(data.key)}
      />
    );
  };

  const body = (
    props: any,
    field: ConfigurationFields,
    min?: number,
    max?: number,
    title?: string
  ): any => {
    return (
      <div>
        <InputNumber
          showButtons={true}
          style={{ width: "100%" }}
          inputStyle={{ width: "calc(100% - 25px)" }}
          value={
            props[field] !== null && !isNaN(props[field] as number)
              ? (props[field] as number)
              : undefined
          }
          mode={"decimal"}
          tooltip={title}
          onChange={(event) => onChange(event, props, field, min, max)}
        />
        <label className={"warning-label"}>
          {warnings[`${props.key}_${field}`]}
        </label>
      </div>
    );
  };
  const varianceDays = (props: any): React.ReactElement => {
    return body(props, ConfigurationFields.VARIANCE_DAYS);
  };

  const variancePercentage = (props: any): React.ReactElement => {
    return body(props, ConfigurationFields.VARIANCE_PERCENTAGE);
  };

  const movingAvgDays = (props: any): React.ReactElement => {
    return body(props, ConfigurationFields.MOVING_AVERAGE_DAYS);
  };

  const onChange = (
    event: any,
    props: any,
    field: ConfigurationFields,
    min?: number,
    max?: number
  ): void => {
    if (event.value === null) {
      config[props.key][field] = event.originalEvent.key === "-" ? -0 : null;
      forceUpdate();
      return;
    }

    if (min && event.value < min) {
      config[props.key][field] = min;
      forceUpdate();
      return;
    }

    if (max && event.value > max) {
      config[props.key][field] = max;
      forceUpdate();
      return;
    }

    config[props.key][field] = event.value;
    forceUpdate();
  };

  const campaignTypeBody = (props: any): React.ReactNode => {
    switch (props.key) {
      case TrafficAlertCampaignType.TOTAL:
        return (
          <div>
            <label className={"campaign-type-label"}>{props.key}</label>
          </div>
        );
      case TrafficAlertCampaignType.ORGANIC:
        return (
          <div>
            <label
              style={{ backgroundColor: "#EF9B0A" }}
              className={"campaign-type-label"}
            >
              {props.key}
            </label>
          </div>
        );
      case TrafficAlertCampaignType.PAID:
        return (
          <div>
            <label
              style={{ backgroundColor: "#ED3C39" }}
              className={"campaign-type-label"}
            >
              {props.key}
            </label>
          </div>
        );
      default:
        return null;
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const newValue = arrayMove(users, oldIndex, newIndex);
    setUsers(newValue);
  };

  const SortableMultiValue = (props: any) => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { ...props.innerProps, onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
  };

  const SortableMultiValueLabel = (props: any) => (
    <components.MultiValueLabel {...props} />
  );

  const promiseUsers = (inputValue: string) =>
    new Promise((resolve) => {
      getNotificationUsers({
        dealershipId: props.location.state.dealershipId,
        userEmail: inputValue,
      }).then((response) => {
        resolve(
          response.data
            .filter((item: any) => item.id !== ownerId)
            .map((item: any) => {
              return {
                value: item.id,
                label: item.email,
                pushSubscribed: item.pushSubscribed,
              };
            })
        );
      });
    });

  return (
    <Panel
      id={"event-alert-form"}
      header={
        props.location.state.mode === MODE.NEW
          ? "Create Event Alert"
          : "Edit Event Alert"
      }
    >
      <div className={"p-grid"}>
        <div className={"p-col-12 p-xl-10 p-lg-12"}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-4 p-md-6"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Name</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <InputText
                    defaultValue={name}
                    maxLength={254}
                    placeholder={"Name"}
                    onChange={(e) => {
                      const value = Util._.get(e, "target.value", "");

                      setName(value);
                    }}
                  />
                </div>
              </div>
              <label className={"warning-label"}>{warnings.name}</label>
            </div>

            <div className={"p-col-12 p-lg-4 p-md-6"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>Start Date</label>
                    <span className={"red"}>*</span>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <Calendar
                    readOnlyInput={true}
                    placeholder={"Start Date"}
                    value={
                      !Util.isEmpty(startDate)
                        ? moment(startDate).toDate()
                        : undefined
                    }
                    onChange={(e): any => {
                      setStartDate(
                        moment(Util._.get(e, "value", null)).format(
                          Util.localDateFormat
                        )
                      );
                    }}
                  />
                </div>
              </div>
              <label className={"warning-label"}>{warnings.startDate}</label>
            </div>

            <div className={"p-col-12 p-lg-4 p-md-6"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>End Date</label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-9"}>
                  <Calendar
                    readOnlyInput={true}
                    placeholder={"End Date"}
                    value={
                      !Util.isEmpty(endDate)
                        ? moment(endDate).toDate()
                        : undefined
                    }
                    onChange={(e): any => {
                      setEndDate(
                        moment(Util._.get(e, "value", null)).format(
                          Util.localDateFormat
                        )
                      );
                    }}
                  />
                </div>
              </div>
              <label className={"warning-label"}>{warnings.endDate}</label>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginBottom: 10 }}>
        <label className={"input-label"}>Users</label>
        <SortableSelect
          id={"test"}
          //axis={"xy"}
          //distance={4}
          value={users}
          //filter={true}
          isMulti={true}
          cacheOptions={true}
          //useDragHandle={true}
          defaultOptions={true}
          className={"multi-select"}
          closeMenuOnSelect={false}
          // getHelperDimensions={({ node }) => node.getBoundingClientRect()}
          onChange={(values: any) =>
            setUsers(Util.getDefaultIfNull(values, []))
          }
          // onSortEnd={onSortEnd}
          components={{
            MultiValue: SortableMultiValue,
            MultiValueLabel: SortableMultiValueLabel,
          }}
          key={props.location.state.dealershipId}
          loadOptions={(inputValue: string): any => promiseUsers(inputValue)}
          styles={{
            option: (styles, { data }) => {
              const pushSubscribed = Util._.get(data, "pushSubscribed", false);
              return {
                ...styles,
                color: pushSubscribed ? "green" : "#000",
              };
            },
            container: (styles) => {
              return {
                ...styles,
                width: "max-content",
                minWidth: 300,
                maxWidth: "100%",
              };
            },
            multiValue: (styles, { data }) => {
              const pushSubscribed = Util._.get(data, "pushSubscribed", false);
              return {
                ...styles,
                color: pushSubscribed ? "green" : "#000",
              };
            },
            multiValueLabel: (styles, { data }) => {
              const pushSubscribed = Util._.get(data, "pushSubscribed", false);
              return {
                ...styles,
                color: pushSubscribed ? "green" : "#000",
              };
            },
            multiValueRemove: (styles, { data }) => {
              const pushSubscribed = Util._.get(data, "pushSubscribed", false);
              return {
                ...styles,
                color: pushSubscribed ? "green" : "#000",
              };
            },
          }}
        />
      </div>

      <DataTable
        header={
          <div style={{ height: 30, width: "100%" }}>
            <div style={{ float: "left" }}>
              <label>Configuration</label>
              <span className={"red"}>*</span>
              <label className={"warning-label"} style={{ marginLeft: 5 }}>
                {warnings.config}
              </label>
            </div>
            <div style={{ float: "right" }}>{getConfigButtons()}</div>
          </div>
        }
        value={Object.entries(config).map((entry) => {
          const [key, value] = entry;
          return {
            key: key,
            ...value,
          };
        })}
      >
        <Column field="key" header="Campaign Type" body={campaignTypeBody} />
        <Column
          field="varianceDays"
          header="Variance Days"
          body={varianceDays}
        />
        <Column
          field="variancePercentage"
          header="Variance %"
          body={variancePercentage}
        />
        <Column
          field="movingAverageDays"
          header="Moving Average Days"
          body={movingAvgDays}
        />
        <Column
          sortable={false}
          body={removeTemplate}
          style={{ width: "4.2em" }}
        />
      </DataTable>

      <div style={{ marginTop: 10 }}>
        <Button
          className="no-icon-buttons"
          onClick={() => onSave()}
          label={"Save"}
        />
        <Button
          onClick={onBack}
          className={"p-button p-button-warning no-icon-buttons"}
          label={"Back"}
        />
      </div>
    </Panel>
  );
};
export default EventAlertsEditForm;

const arrayMove = (array: any, from: number, to: number) => {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
};
