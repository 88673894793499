import React, { useState, useEffect } from "react";
import "./mag.css";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";

import { IMagExternalSalesRepresentative, IStore } from "../../../index.dts";
import { sendMagADF } from "../../actions/magActions";
import { IDS } from "../../views/constants";
import Util from "../../util/Util";
import { useForceUpdate } from "../../hooks/useForceUpdate";

export function Mag() {
  const { t }: any = useTranslation();
  const forceUpdate = useForceUpdate();
  const history = useHistory();

  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  const userContext = useSelector(
    (store: IStore) => store.auth.user,
    shallowEqual
  );

  const [magExternalSalesRepresentative, setMagExternalSalesRepresentative] =
    useState<IMagExternalSalesRepresentative>({
      dealershipId: dealershipContext.id,
      userId: userContext.id,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      emailAddress: "",
      year: "",
      make: "",
      model: "",
      trim: "",
      vin: "",
      stockNumber: "",
    });

  const [warnings] = useState<{ [key: string]: string }>({
    phoneNumber: "",
    emailAddress: "",
    year: "",
  });

  useEffect(() => {
    if (dealershipContext.id !== 0) {
      magExternalSalesRepresentative["dealershipId"] = dealershipContext.id;
    }
    // eslint-disable-next-line
  }, [dealershipContext]);

  useEffect(() => {
    if (userContext.id !== 0) {
      magExternalSalesRepresentative["userId"] = userContext.id;
    }
    // eslint-disable-next-line
  }, [userContext]);

  const setWarningValue = (
    field: string,
    value?: string,
    disableUpdate?: boolean
  ) => {
    if (value) {
      warnings[field] = value;
    }

    if (!disableUpdate) {
      forceUpdate();
    }
  };

  const setValue = (field: string, value: any) => {
    // @ts-ignore
    if (field == "phoneNumber") {
      if (!Util.isEmpty(value) && !Util.validatePhoneNumber(value)) {
        setWarningValue(field, t("mag.validation.phoneNumber"));
      } else {
        warnings[field] = "";
      }
      magExternalSalesRepresentative[field] = value;
      forceUpdate();
    } else if (field == "emailAddress") {
      if (!Util.isEmpty(value) && !Util.validateEmail(value)) {
        setWarningValue(field, t("validation.email"));
      } else {
        warnings[field] = "";
      }
      magExternalSalesRepresentative[field] = value;
      forceUpdate();
    } else if (field == "year") {
      if (!Util.isEmpty(value) && !Util.isValidInteger(value)) {
        setWarningValue(field, t("mag.validation.year"));
      } else {
        warnings[field] = "";
      }
      magExternalSalesRepresentative[field] = value;
      forceUpdate();
    } else {
      if (value === null) {
        value = "";
      }
      magExternalSalesRepresentative[field] = value;
      forceUpdate();
    }
  };

  function areAllFieldsEmptyOrNullExceptNumbers(data) {
    return Object.values(data).every(
      (value) => value === "" || value === null || typeof value === "number"
    );
  }

  const onCreate = () => {
    Util.globalSpinner().show();
    const dto: any = { ...magExternalSalesRepresentative };
    const validateArray = Object.entries(warnings).map((entire) => {
      return {
        field: entire[0],
        warningString: entire[1],
      };
    });

    for (const item of validateArray) {
      if (item.warningString?.trim() !== "") {
        forceUpdate();
        Util.warning(item.warningString?.trim());
        Util.globalSpinner().hide();
        return;
      }
    }

    if (areAllFieldsEmptyOrNullExceptNumbers(dto)) {
      forceUpdate();
      Util.warning(t("mag.validation.invalidForm"));
      Util.globalSpinner().hide();
      return;
    }
    sendMagADF(dto)
      .then((response) => {
        Util.globalSpinner().hide();
        Util.success(t("mag.sentSuccessfully"));
        history.push(Util.PATH_NAMES.DASHBOARD);
      })
      .catch((error) => {
        Util.globalSpinner().hide();
        Util.error(error.response.data.message);
      });
  };

  return (
    <div id={"assets-view"}>
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div className="card card-w-title">
            <Panel
              id={IDS.mag.panel.magPanel}
              key={"MAG"}
              style={{ marginTop: 10 }}
              header={
                t("mag.magExternalSalesRepresentative") +
                " | " +
                dealershipContext.name
              }
            >
              <div className={"p-grid"}>
                <div className={"p-col-3 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      {t("mag.form.firstName")}
                    </label>
                  </div>
                </div>
                <div className={"p-col-9 p-lg-9"}>
                  <InputText
                    id={IDS.mag.panel.form.firstName}
                    style={{ width: "100%" }}
                    value={magExternalSalesRepresentative.firstName}
                    name={"firstName"}
                    placeholder={"Customer First Name"}
                    onChange={(e: any) => setValue("firstName", e.target.value)}
                  />
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-3 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      {t("mag.form.lastName")}
                    </label>
                  </div>
                </div>
                <div className={"p-col-9 p-lg-9"}>
                  <InputText
                    id={IDS.mag.panel.form.lastName}
                    style={{ width: "100%" }}
                    value={magExternalSalesRepresentative.lastName}
                    name={"lastName"}
                    placeholder={"Customer Last Name"}
                    onChange={(e: any) => setValue("lastName", e.target.value)}
                  />
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-3 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      {t("mag.form.phoneNumber")}
                    </label>
                  </div>
                </div>
                <div className={"p-col-9 p-lg-9"}>
                  <InputText
                    id={IDS.mag.panel.form.phoneNumber}
                    style={{ width: "100%" }}
                    value={magExternalSalesRepresentative.phoneNumber}
                    name={"phoneNumber"}
                    placeholder={"Customer Phone Number"}
                    onChange={(e: any) =>
                      setValue("phoneNumber", e.target.value)
                    }
                  />
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-3 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      {t("mag.form.emailAddress")}
                    </label>
                  </div>
                </div>
                <div className={"p-col-9 p-lg-9"}>
                  <InputText
                    id={IDS.mag.panel.form.emailAddress}
                    style={{ width: "100%" }}
                    value={magExternalSalesRepresentative.emailAddress}
                    name={"emailAddress"}
                    placeholder={"Customer Email Address"}
                    onChange={(e: any) =>
                      setValue("emailAddress", e.target.value)
                    }
                  />
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-3 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      {t("mag.form.year")}
                    </label>
                  </div>
                </div>
                <div className={"p-col-9 p-lg-9"}>
                  <InputText
                    id={IDS.mag.panel.form.year}
                    style={{ width: "100%" }}
                    value={magExternalSalesRepresentative.year}
                    name={"year"}
                    placeholder={"Vehicle Year"}
                    onChange={(e: any) => setValue("year", e.target.value)}
                  />
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-3 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      {t("mag.form.make")}
                    </label>
                  </div>
                </div>
                <div className={"p-col-9 p-lg-9"}>
                  <InputText
                    id={IDS.mag.panel.form.make}
                    style={{ width: "100%" }}
                    value={magExternalSalesRepresentative.make}
                    name={"make"}
                    placeholder={"Vehicle Make"}
                    onChange={(e: any) => setValue("make", e.target.value)}
                  />
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-3 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      {t("mag.form.model")}
                    </label>
                  </div>
                </div>
                <div className={"p-col-9 p-lg-9"}>
                  <InputText
                    id={IDS.mag.panel.form.model}
                    style={{ width: "100%" }}
                    value={magExternalSalesRepresentative.model}
                    name={"model"}
                    placeholder={"Vehicle Model"}
                    onChange={(e: any) => setValue("model", e.target.value)}
                  />
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-3 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      {t("mag.form.trim")}
                    </label>
                  </div>
                </div>
                <div className={"p-col-9 p-lg-9"}>
                  <InputText
                    id={IDS.mag.panel.form.trim}
                    style={{ width: "100%" }}
                    value={magExternalSalesRepresentative.trim}
                    name={"trim"}
                    placeholder={"Vehicle Trim/Series"}
                    onChange={(e: any) => setValue("trim", e.target.value)}
                  />
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-3 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>{t("mag.form.vin")}</label>
                  </div>
                </div>
                <div className={"p-col-9 p-lg-9"}>
                  <InputText
                    id={IDS.mag.panel.form.vin}
                    style={{ width: "100%" }}
                    value={magExternalSalesRepresentative.vin}
                    name={"vin"}
                    placeholder={"Vehicle VIN Number"}
                    onChange={(e: any) => setValue("vin", e.target.value)}
                  />
                </div>
              </div>

              <div className={"p-grid"}>
                <div className={"p-col-3 p-lg-3 p-label-col"}>
                  <div className={"label-container"}>
                    <label className={"input-label"}>
                      {t("mag.form.stockNumber")}
                    </label>
                  </div>
                </div>
                <div className={"p-col-9 p-lg-9"}>
                  <InputText
                    id={IDS.mag.panel.form.stockNumber}
                    style={{ width: "100%" }}
                    value={magExternalSalesRepresentative.stockNumber}
                    name={"stockNumber"}
                    placeholder={"Vehicle Stock Number"}
                    onChange={(e: any) =>
                      setValue("stockNumber", e.target.value)
                    }
                  />
                </div>
              </div>
            </Panel>
            <div style={{ marginTop: 10 }}>
              <Button
                id={IDS.button.save}
                onClick={() => onCreate()}
                label={"Save"}
                icon={"pi pi-save"}
              />
              <Button
                id={IDS.button.back}
                style={{ marginRight: 5 }}
                icon={"pi pi-arrow-left"}
                className={"p-button-warning"}
                label={"Back"}
                onClick={() => history.push(Util.PATH_NAMES.DASHBOARD)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mag;
