import { RadioFilter } from "../radio_filter/RadioFilter";
import React from "react";
import { useTranslation } from "react-i18next";
import SubscriptionTypes from "../../util/enum/subscription_types";

interface IProps {
  onChange: (update: number) => void;
  type?: SubscriptionTypes;
}

export function SubscriptionTypeRadio(props: IProps) {
  function onChange(value: number): void {
    props.onChange(value);
  }

  return (
    <RadioFilter
      name={"subscription-type-radio"}
      onChange={onChange}
      options={[
        {
          label: "Subscribe",
          value: SubscriptionTypes.subscribe,
        },
        {
          label: "Unsubscribe",
          value: SubscriptionTypes.unsubscribe,
        },
      ]}
      selected={props.type}
    />
  );
}
