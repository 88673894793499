import React, { useEffect, useState } from "react";
import "./chat_greetings.css";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import { IChatGreeting, IChatGreetingDto, IStore } from "../../../../index.dts";
import Util from "../../../util/Util";
import { SketchPicker } from "react-color";
import { InputTextarea } from "primereact/inputtextarea";
import {
  createChatGreeting,
  deleteChatGreetings,
  getChatGreetings,
  updateChatGreeting,
} from "../../../actions/chatGreetingActions";
import { shallowEqual, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { InputText } from "primereact/inputtext";
import { PERMISSIONS, SEARCH_OPERATIONS } from "../../../util/Enums";
import { Checkbox } from "primereact/checkbox";
import { IDS } from "../../../views/constants";
import { useTranslation } from "react-i18next";

interface IProps {}

const styles: any = {
  picker: {
    width: "calc(100% - 20px)",
    padding: "10px 10px 0px",
    boxSizing: "initial",
    background: "rgb(255, 255, 255)",
    borderRadius: 4,
    boxShadow:
      "rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px",
  },
};

const ChatGreetings = (props: IProps) => {
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();

  const [pending, setPending] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [first, setFirst] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [sortMap] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(new Map());

  const [chatGreeting, setChatGreeting] = useState<IChatGreetingDto>();
  const [chatGreetings, setChatGreetings] = useState<IChatGreetingDto[]>([]);
  const [selectedChatGreetings, setSelectedChatGreetings] = useState<
    IChatGreetingDto[]
  >([]);
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  const [nameFilterValue, setNameFilterValue] = useState<string>("");
  const [messageFilterValue, setMessageFilterValue] = useState<string>("");
  const [buttonFilterValue, setButtonFilterValue] = useState<string>("");

  const onClear = () => {
    sortMap.clear();
    setNameFilterValue("");
    setMessageFilterValue("");
    setButtonFilterValue("");
  };

  useEffect(() => {
    if (dealershipContext.id) {
      getData(0, limit).finally(() => {
        setPage(0);
        setSelectedChatGreetings([]);
      });
    }
    // eslint-disable-next-line
  }, [
    dealershipContext,
    messageFilterValue,
    nameFilterValue,
    buttonFilterValue,
  ]);

  const getData = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      setPending(true);

      const filter: any[] = [];

      if (nameFilterValue) {
        filter.push({
          field: "name",
          value: nameFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (messageFilterValue) {
        filter.push({
          field: "message",
          value: messageFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (buttonFilterValue) {
        filter.push({
          field: "buttonLabel",
          value: buttonFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      getChatGreetings(dealershipContext.id, {
        filter: filter,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
        paging: {
          page: page,
          pageLimit: limit,
        },
      })
        .then((response) => {
          setChatGreetings(response.data.content);
          setTotalElements(response.data.totalElements);

          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);

          reject(error);
        })
        .finally(() => setPending(false));
    });

  const onAddChatGreeting = (): void => {
    if (chatGreeting) {
      return;
    }

    setChatGreeting({
      id: 0,
      name: "I’m at the Dealership",
      message: "Can I offer you A great discount?",
      backgroundColor: "42cb83",
      buttonBackgroundColor: "42cb83",
      buttonLabel: "Yes Please",
    });

    setTimeout(() => {
      Util.$("#chat-greetings .p-row-editor-init-icon")[0]?.click();
      Util.$("#chat-greetings .input-editor")[0]?.focus();
    });
  };

  const onDelete = () => {
    const ids = selectedChatGreetings
      .filter((chatGreetings) => chatGreetings.id !== 0)
      .map((chatGreeting) => chatGreeting.id);

    if (ids.length > 0) {
      confirmAlert({
        title: `You are about to delete ${ids.length} chat greeting${
          ids.length > 1 ? "s" : ""
        }!`,
        message: "Are you sure you want to do this?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              Util.globalSpinner().show();
              deleteChatGreetings(ids)
                .then(() => {
                  Util.success("Chat Greetings deleted successfully.");
                })
                .catch((error) => {
                  Util.showError(error);
                })
                .finally(() => {
                  Util.globalSpinner().hide();
                  getData(0, limit).finally(() => {
                    setPage(0);
                    setSelectedChatGreetings([]);
                  });
                });
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    } else {
      Util.warning("Please choose at least one chat greeting.");
    }
  };

  const onRowEditSave = (props: any): void => {
    props.originalEvent.preventDefault();

    if (props.data.id === 0) {
      Util.globalSpinner().show();
      createChatGreeting(dealershipContext.id, {
        name: props.newData.name,
        message: props.newData.message,
        buttonLabel: props.newData.buttonLabel,
        backgroundColor: props.newData.backgroundColor,
        buttonBackgroundColor: props.newData.buttonBackgroundColor,
      })
        .then((response) => {
          Util.success("Chat Greeting created successfully");

          chatGreetings.unshift(response.data);

          setTotalElements(totalElements + 1);
          forceUpdate();
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => {
          setChatGreeting(undefined);
          Util.globalSpinner().hide();
        });
    } else {
      Util.globalSpinner().show();
      updateChatGreeting(props.data.id, {
        name: props.newData.name,
        message: props.newData.message,
        buttonLabel: props.newData.buttonLabel,
        backgroundColor: props.newData.backgroundColor,
        buttonBackgroundColor: props.newData.buttonBackgroundColor,
      })
        .then((response) => {
          Util.success("Chat Greeting updated successfully");

          getData(page, limit);
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => Util.globalSpinner().hide());
    }
  };

  const onRowEditCancel = (props: { data: IChatGreetingDto }): void => {
    setChatGreeting(undefined);
  };

  const onChange = (data: any, field: string, value: any): void => {
    data[field] = value;
    forceUpdate();
  };

  const chatGreetingEditor = (
    props: any,
    field: string,
    maxLength: number
  ): JSX.Element => {
    return (
      <InputTextarea
        className={"input-editor"}
        style={{
          minWidth: "100%",
          maxWidth: "100%",
          minHeight: 40,
          maxHeight: 200,
        }}
        value={props.value}
        maxLength={maxLength}
        onChange={(e: any) => {
          props.editorCallback(e.target.value);
        }}
      />
    );
  };

  const chatGreetingColorEditor = (props: any, field: string): JSX.Element => {
    return (
      <SketchPicker
        disableAlpha={true}
        color={props.rowData[field]}
        styles={styles}
        onChange={(e) => {
          props.rowData[field] = e.hex.substring(1);
          forceUpdate();
        }}
      />
    );
  };

  const calculateChatGreetingFontSize = (text: string, maxLength: number, maxFontSize: number, minFontSize: number, ratioMultiplier: number): number => {
    const textLength = text.length;
  
    if (textLength <= maxLength) {
      return maxFontSize;
    }
  
    const excessLengthRatio = (textLength - maxLength) / (100 - (maxLength * ratioMultiplier));
    const fontSizeRange = maxFontSize - minFontSize;
    const fontSizeReduction = Math.round(fontSizeRange * excessLengthRatio);
  
    return Math.max(minFontSize, maxFontSize - fontSizeReduction);
  };

  const previewBody = (props: IChatGreeting): React.ReactNode => {
    return (
      <div className={"rts-template-item"}>
        <div
          id={"rts-template-chat-permission-container"}
          style={{ backgroundColor: `#${props.backgroundColor}` }}
        >
          <img
            id={"rts-template-chat-permission-close-svg"}
            src={"assets/icons/close.svg"}
            alt={""}
          />
          <div id={"rts-template-chat-permission-header"} style={{fontSize: calculateChatGreetingFontSize(props.name, 8, 14, 4, 4) + 'px'}}>{props.name}</div>
          <div id={"rts-template-chat-permission-question-container"}>
            <div id={"rts-template-chat-permission-question-header"} style={{fontSize: calculateChatGreetingFontSize(props.message, 10, 10, 4, 1) + 'px'}}>
              {props.message}
            </div>
            <div
              id={"rts-template-chat-permission-question-button"}
              style={{ backgroundColor: `#${props.buttonBackgroundColor}` }}
            >
              <div id={"rts-template-chat-permission-question-yes"}>
                <span style={{marginBottom: 20}}>
                  <div style={{fontSize: calculateChatGreetingFontSize(props.buttonLabel, 8, 8, 6, 10) + 'px'}}>
                    {props.buttonLabel}{" "}
                    <img
                      id={"rts-template-chat-permission-question-arrow"}
                      src={"assets/icons/arrow.svg"}
                      alt={""}
                    />
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const backgroundColorBody = (props: IChatGreeting): React.ReactNode => {
    return (
      <input
        style={{ width: "100%" }}
        type={"color"}
        value={`#${props.backgroundColor}`}
        disabled={true}
      />
    );
  };

  const buttonColorBody = (props: IChatGreeting): React.ReactNode => {
    return (
      <input
        style={{ width: "100%" }}
        type={"color"}
        value={`#${props.buttonBackgroundColor}`}
        disabled={true}
      />
    );
  };

  const onPage = (event: any) => {
    getData(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
      setSelectedChatGreetings([]);
    });
  };

  const onSort = (data: any) => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getData(page, limit);
    }
  };

  const nameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={nameFilterValue}
      onChange={(e: any) => {
        setNameFilterValue(e.target.value || "");
      }}
    />
  );

  const messageFilter = (
    <InputText
      style={{ width: "100%" }}
      value={messageFilterValue}
      onChange={(e: any) => {
        setMessageFilterValue(e.target.value || "");
      }}
    />
  );

  const buttonFilter = (
    <InputText
      style={{ width: "100%" }}
      value={buttonFilterValue}
      onChange={(e: any) => {
        setButtonFilterValue(e.target.value || "");
      }}
    />
  );

  const getGreetings = (): IChatGreetingDto[] => {
    const array = chatGreeting ? [chatGreeting] : [];

    return array.concat(chatGreetings);
  };

  const checkboxTemplate = (data: IChatGreetingDto) => {
    const index = selectedChatGreetings.findIndex((m) => m.id === data.id);
    return (
      <Checkbox
        style={{ width: "3em" }}
        checked={index !== -1}
        disabled={data.id === 0}
        onChange={(e) => onSelectRow(e.checked, data)}
      />
    );
  };

  const onSelectRow = (
    checked: boolean,
    chatGreetingDto: IChatGreetingDto
  ): void => {
    const index = selectedChatGreetings.findIndex(
      (c) => c.id === chatGreetingDto.id
    );

    if (index !== -1) {
      selectedChatGreetings.splice(index, 1);
    } else {
      selectedChatGreetings.push(chatGreetingDto);
    }

    forceUpdate();
  };

  return (
    <div>
      <div
        className={
          limit > 10
            ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting"
            : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
        }
      >
        <DataTable
          id={"chat-greetings"}
          filterDisplay="row"
          editMode={"row"}
          autoLayout={true}
          lazy={true}
          first={first}
          rows={limit}
          scrollable={true}
          paginator={true}
          resizableColumns={true}
          columnResizeMode={"expand"}
          totalRecords={totalElements}
          rowsPerPageOptions={Util.rowsPerPageOptions}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
          loading={pending}
          onPage={onPage}
          onSort={onSort}
          value={getGreetings()}
          onRowEditComplete={onRowEditSave}
          onRowEditCancel={onRowEditCancel}
          selection={selectedChatGreetings}
          onSelectionChange={(e) => setSelectedChatGreetings(e.value)}
          sortMode={"multiple"}
          multiSortMeta={Array.from(sortMap.values())}
          header={
            Util.hasAnyAuthority(PERMISSIONS.RTS_CHAT_MANAGEMENT) ? (
              <div style={{ display: "flex" }}>
                <div className={"p-grid-header-components"}>
                  <Button
                    id={IDS.button.clear}
                    style={{ position: "absolute", left: "10px" }}
                    icon={"pi pi-filter"}
                    label={t("clear")}
                    onClick={onClear}
                  />
                  <Button
                    disabled={!!chatGreeting}
                    style={{ marginRight: 5 }}
                    onClick={onAddChatGreeting}
                    icon="pi pi-plus"
                    label={"Add"}
                  />
                  <Button
                    disabled={selectedChatGreetings.length === 0}
                    onClick={onDelete}
                    icon="pi pi-trash"
                    className="p-button-danger"
                    label={"Delete"}
                  />
                </div>
              </div>
            ) : null
          }
        >
          <Column style={{ width: "3em" }} body={checkboxTemplate} />
          <Column
            filter={true}
            sortable={!chatGreeting}
            field={"name"}
            header={"Title"}
            editor={(props) => chatGreetingEditor(props, "name", 40)}
            filterElement={nameFilter}
            style={{ width: 100 }}
          />
          <Column
            filter={true}
            sortable={!chatGreeting}
            field={"message"}
            header={"Message"}
            editor={(props) => chatGreetingEditor(props, "message", 70)}
            filterElement={messageFilter}
            style={{ width: 100 }}
          />
          <Column
            filter={true}
            sortable={!chatGreeting}
            field={"buttonLabel"}
            header={"Button Label"}
            editor={(props) => chatGreetingEditor(props, "buttonLabel", 18)}
            filterElement={buttonFilter}
            style={{ width: 100 }}
          />
          <Column
            filter={false}
            sortable={!chatGreeting}
            field={"backgroundColor"}
            header={"Background Color"}
            body={backgroundColorBody}
            editor={(props) =>
              chatGreetingColorEditor(props, "backgroundColor")
            }
            style={{ width: 180 }}
          />
          <Column
            filter={false}
            sortable={!chatGreeting}
            field={"buttonBackgroundColor"}
            header={"Button Color"}
            body={buttonColorBody}
            editor={(props) =>
              chatGreetingColorEditor(props, "buttonBackgroundColor")
            }
            style={{ width: 180 }}
          />
          <Column
            field={"preview"}
            header={"Preview"}
            body={previewBody}
            style={{ width: 225 }}
          />
          {Util.hasAnyAuthority(PERMISSIONS.RTS_CHAT_MANAGEMENT) ? (
            <Column
              rowEditor
              headerStyle={{ width: "3rem" }}
              bodyStyle={{ textAlign: "center" }}
            />
          ) : null}
        </DataTable>
      </div>
    </div>
  );
};

export default ChatGreetings;
