import React from "react";
import {map} from "lodash";
import "./tags.css";

interface IProps {
  values: string[];
}

const Tags = (props: IProps) => {

  const render = (): React.ReactElement => {
    return (
      <div>
        {map(props.values, (item, index) => (
          <div key={index} className={"tag-item"}>{item}</div>
        ))}
      </div>
    )
  }

  return render();
}

export default Tags;