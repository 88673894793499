import React, { useEffect, useState } from "react";
import {
  createEnterpriseGroup,
  deleteEnterpriseGroups,
  getEnterpriseGroup,
  getEnterpriseGroupsList,
  updateEnterpriseGroup,
} from "../../actions/enterpriseGroupActions";
import Util from "../../util/Util";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import { Column } from "primereact/column";
import { IEnterpriseListGroup, IStore } from "../../../index.dts";
import { MODE, PERMISSIONS } from "../../util/Enums";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import moment from "moment-timezone";
import { shallowEqual, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { useHistory } from "react-router-dom";
import { IDS } from "../constants";
import { useTranslation } from "react-i18next";
import { DateRangePickerWidget } from "../../components/data_range_picker/DateRangePickerWidget";
import { DateRangePeriods } from "../../components/data_range_picker/date_range_periods";

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

export function EnterpriseGroups() {
  const history = useHistory();
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);

  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [page, setPage] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [sortMap] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(new Map());
  const [enterpriseGroupList, setEnterpriseGroupList] = useState<
    IEnterpriseListGroup[]
  >([]);

  const [selectedEnterpriseGroups, setSelectedEnterpriseGroups] = useState<
    IEnterpriseListGroup[]
  >([]);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);

  const [nameFilterValue, setNameFilterValue] = useState<string>("");
  const [ownerFilterValue, setOwnerFilterValue] = useState<string>("");
  const [createdPeriod, setCreatedPeriod] = useState<{
    start?: Date;
    end?: Date;
  }>({
    start: undefined,
    end: undefined,
  });

  const user = useSelector((store: IStore) => store.auth.user, shallowEqual);
  const globalSpinner = useSelector(
    (store: IStore) => store.myTraffic.globalSpinner,
    shallowEqual
  );

  const onClear = () => {
    sortMap.clear();
    setNameFilterValue("");
    setOwnerFilterValue("");
    setCreatedPeriod({
      start: undefined,
      end: undefined,
    });
  };

  useEffect(() => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getData(0, limit).finally(() => {
        setPage(0);
        setFirst(0);
        searchTimeoutInterval = 500;
        setSelectedEnterpriseGroups([]);
      });
    }, searchTimeoutInterval);
    // eslint-disable-next-line
  }, [nameFilterValue, ownerFilterValue, createdPeriod]);

  const getData = (page: number, limit: number) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      getEnterpriseGroupsList({
        name: nameFilterValue,
        userEmail: ownerFilterValue,
        pageLimit: limit,
        pageNumber: page,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
        toDate: createdPeriod.end
          ? moment(createdPeriod.end).format(Util.localDateTimeFormat)
          : undefined,
        fromDate: createdPeriod.start
          ? moment(createdPeriod.start).format(Util.localDateTimeFormat)
          : undefined,
      })
        .then((response) => {
          setTotalItemsCount(response.data.totalElements);
          setEnterpriseGroupList(response.data.content);

          resolve(response);
        })
        .catch((error) => {
          Util.showError(error);

          reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const onPage = (event: any) => {
    getData(event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
      setSelectedEnterpriseGroups([]);
    });
  };

  const onSort = (data: any) => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getData(page, limit);
    }
  };

  const onNew = () => {
    history.push(Util.PATH_NAMES.ENTERPRISE_GROUP_CREATE, {
      mode: MODE.NEW,
    });
  };

  const onEdit = () => {
    history.push(Util.PATH_NAMES.ENTERPRISE_GROUP_EDIT, {
      mode: MODE.EDIT,
      id: selectedEnterpriseGroups?.[0]?.id,
    });
  };

  const onCopy = (): void => {
    if (selectedEnterpriseGroups.length === 1) {
      Util.globalSpinner().show();
      getEnterpriseGroup(selectedEnterpriseGroups[0].id)
        .then((response) => {
          const name = `Copy of ${Util._.get(
            response,
            "data.name",
            ""
          )}_(${Util.uniqId()})`;
          const publicGroup = Util._.get(response, "data.publicGroup", false);
          const description = Util._.get(response, "data.description", "");
          const userIds = response.data.users.map((item) => item.id);
          const dealershipIds = response.data.dealerships.map(
            (item) => item.id
          );

          createEnterpriseGroup({
            name: name,
            publicGroup: publicGroup,
            description: description,
            userIds: userIds,
            dealershipIds: dealershipIds,
          })
            .then(() => {
              Util.success("Enterprise group copied successfully.");
            })
            .catch((error) => {
              Util.showError(error);
            })
            .finally(() => {
              Util.globalSpinner().hide();
              getData(page, limit).finally(() => {
                setSelectedEnterpriseGroups([]);
              });
            });
        })
        .catch((error) => {
          Util.showError(error);
          Util.globalSpinner().hide();
        });
    } else {
      Util.warning("Please select only one enterprise group.");
    }
  };

  const onDelete = () => {
    const groupsAllowedToDelete = selectedEnterpriseGroups.filter((group) =>
      allowedToModify(group.owner.id)
    );

    if (groupsAllowedToDelete.length > 0) {
      confirmAlert({
        title: `You are about to delete ${
          groupsAllowedToDelete.length
        } enterprise group${groupsAllowedToDelete.length > 1 ? "s" : ""}!`,
        message: "Are you sure you want to do this?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              globalSpinner.show();
              deleteEnterpriseGroups(
                groupsAllowedToDelete.map((group) => group.id)
              )
                .then(() =>
                  Util.success("Enterprise group/s deleted successfully.")
                )
                .catch((error) => Util.showError(error))
                .finally(() => {
                  globalSpinner.hide();
                  getData(0, limit).finally(() => {
                    setPage(0);
                    setFirst(0);
                    setSelectedEnterpriseGroups([]);
                  });
                });
            },
            className: "confirm-save-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
    } else {
      Util.warning("Please choose at least one enterprise group.");
    }
  };

  const allowedToModify = (ownerId: number, mode?: MODE): boolean => {
    if (mode === MODE.NEW) {
      return true;
    }
    return (
      ownerId === user.id ||
      Util.hasAnyAuthority(PERMISSIONS.RTS_ENTERPRISE_GROUPS_MANAGE_ALL)
    );
  };

  const publicTemplate = (data: any) => {
    return (
      <InputSwitch
        disabled={!allowedToModify(data.owner.id)}
        onChange={() => onPublicChange(data)}
        checked={data.publicGroup}
      />
    );
  };

  const onPublicChange = (data: any) => {
    data.publicGroup = !data.publicGroup;
    updateEnterpriseGroup(data.id, { publicGroup: data.publicGroup })
      .then(() => {
        Util.success("Enterprise group updated successfully.");
      })
      .catch((error) => {
        Util.showError(error);
        data.publicGroup = !data.publicGroup;
        forceUpdate();
      });
    forceUpdate();
  };

  const createdTemplate = (data: any) => {
    return (
      <div>
        {moment(Util.utcToLocal(data.created)).format(Util.usDateTimeFormat)}
      </div>
    );
  };

  const nameFilter = (
    <InputText
      style={{ width: "100%" }}
      value={nameFilterValue}
      onChange={(e: any) => {
        setNameFilterValue(e.target.value || "");
      }}
    />
  );

  const ownerFilter = (
    <InputText
      style={{ width: "100%" }}
      value={ownerFilterValue}
      onChange={(e: any) => {
        setOwnerFilterValue(e.target.value || "");
      }}
    />
  );

  function onCreatedPeriodChange(start?: Date, end?: Date): void {
    setCreatedPeriod({
      start: start ? moment(start).set({ hour: 0, minute: 0, second: 0 }).toDate() : undefined,
      end: start ? moment(end).set({ hour: 23, minute: 59, second: 59 }).toDate() : undefined,
    });
  }

  const createdFilter = (
    <DateRangePickerWidget
      start={createdPeriod.start}
      end={createdPeriod.end}
      ranges={DateRangePeriods.options} // Replace 'Options' with the range definitions
      onChange={onCreatedPeriodChange}
    />
  );

  const disableDeleteButton = (): boolean => {
    if (selectedEnterpriseGroups.length === 0) {
      return true;
    }
    return (
      selectedEnterpriseGroups.length === 1 &&
      !allowedToModify(selectedEnterpriseGroups[0].owner.id)
    );
  };

  return (
    <div id={"enterprise-group-view"}>
      <div className={"p-grid"}>
        <div className="p-col-12">
          <div className="card card-w-title datatable-demo">
            <div
              className={
                limit > 10
                  ? "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting"
                  : "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows chat-greeting-ten"
              }
            >
              <DataTable
                tableStyle={{ overflow: "visible" }}
                resizableColumns={true}
                columnResizeMode={"expand"}
                rowsPerPageOptions={Util.rowsPerPageOptions}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                lazy={true}
                rows={limit}
                scrollable
                first={first}
                value={enterpriseGroupList}
                paginator={true}
                loading={loading}
                sortMode={"multiple"}
                filterDisplay="row"
                onPage={onPage}
                onSort={onSort}
                multiSortMeta={Array.from(sortMap.values())}
                totalRecords={totalItemsCount}
                selection={selectedEnterpriseGroups}
                onSelectionChange={(e) => setSelectedEnterpriseGroups(e.value)}
                header={
                  Util.hasAnyAuthority(
                    PERMISSIONS.RTS_ENTERPRISE_GROUPS_MANAGE_ALL
                  ) ||
                  Util.hasAnyAuthority(
                    PERMISSIONS.RTS_ENTERPRISE_GROUPS_MANAGE_OWN
                  ) ? (
                    <div className={"p-grid-header-components"}>
                      <Button
                        id={IDS.button.clear}
                        style={{ position: "absolute", left: "10px" }}
                        icon={"pi pi-filter"}
                        label={t("clear")}
                        onClick={onClear}
                      />
                      <Button
                        style={{ marginRight: 5 }}
                        onClick={onNew}
                        icon="pi pi-plus"
                        label={"New"}
                      />
                      <Button
                        disabled={selectedEnterpriseGroups.length !== 1}
                        style={{ marginRight: 5 }}
                        onClick={onEdit}
                        icon="pi pi-pencil"
                        label={"Edit"}
                      />
                      <Button
                        disabled={selectedEnterpriseGroups.length !== 1}
                        style={{ marginRight: 5 }}
                        onClick={onCopy}
                        icon="pi pi-copy"
                        className="p-button-warning"
                        label={"Copy"}
                      />
                      <Button
                        disabled={disableDeleteButton()}
                        onClick={onDelete}
                        icon="pi pi-trash"
                        className="p-button-danger"
                        label={"Delete"}
                      />
                    </div>
                  ) : null
                }
              >
                <Column
                  selectionMode="multiple"
                  style={{ width: "3em", flex: "0.1" }}
                />
                <Column
                  field="name"
                  header="Name"
                  filter={true}
                  filterElement={nameFilter}
                  sortable={true}
                />
                <Column
                  field="dealershipsCount"
                  header="Dealerships"
                  filter={false}
                  sortable={false}
                />
                <Column
                  field="usersCount"
                  header="Users"
                  filter={false}
                  sortable={false}
                />
                <Column
                  field="publicGroup"
                  header="Public"
                  filter={false}
                  sortable={true}
                  body={publicTemplate}
                />
                <Column
                  field="owner.email"
                  header="Owner"
                  filter={true}
                  filterElement={ownerFilter}
                  sortable={false}
                />
                <Column
                  field="created"
                  header="Created"
                  filter={true}
                  filterElement={createdFilter}
                  sortable={true}
                  body={createdTemplate}
                  style={{ overflow: "visible" }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnterpriseGroups;
