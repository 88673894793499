import React, { useEffect, useState } from "react";
import Select from "react-select";
import { ISelectOption } from "../../../../index.dts";
import { getEnterpriseUsers } from "../../../actions/enterpriseGroupActions";
import Util from "../../../util/Util";

interface IProps {
  users: ISelectOption[];

  onChange(users: ISelectOption[]): void;
}

const EnterpriseUsers = (props: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [usersList, setUsersList] = useState<ISelectOption[]>([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = (): void => {
    setLoading(true);
    getEnterpriseUsers()
      .then((response) => {
        const users = response.data.map((item) => {
          return {
            value: item.id,
            label: item.email,
          };
        });

        setUsersList(users);
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onUsersChange = (value: any): void => {
    if (!Array.isArray(value)) {
      props.onChange([]);
      return;
    }

    props.onChange(value);
  };

  const render = (): React.ReactElement => {
    return (
      <Select
        isMulti={true}
        value={props.users}
        isLoading={loading}
        options={usersList}
        //style={{ width: "100%" }}
        placeholder="Select Users"
        onChange={onUsersChange}
        className={"multi-select enterprise-select-users"}
        closeMenuOnSelect={false}
      />
    );
  };

  return render();
};

export default EnterpriseUsers;
