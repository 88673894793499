import "./date_range_picker_widget.css";

import { find, isUndefined } from "lodash";
import { useRef, useState } from "react";

import { Button } from "primereact/button";
import { DateRangePicker } from "react-date-range";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Period } from "./date_range_periods";
import React from "react";
import Util from "../../util/Util";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useForceUpdate } from "../../hooks/useForceUpdate";

interface IProps {
  start?: Date;
  end?: Date;
  ranges: Period[];
  onChange: (start?: Date, end?: Date) => void;
}

export function DateRangePickerWidget(props: IProps) {
  const forceUpdate = useForceUpdate();
  const op: any = useRef(null);
  const [start, setStart] = useState(props.start);
  const [end, setEnd] = useState(props.end);
  const { t } = useTranslation();
  function findPeriod(): Period | undefined {
    return find(
      props.ranges,
      (p) => p.start === props.start && p.end === props.end
    );
  }

  function formatDate(input?: Date): string {
    return moment(input).format(Util.usDateFormat);
  }

  function getLabel(): string {
    let label;
    const current = findPeriod();
    
    if (isUndefined(current)) {
      if (props.start == undefined && props.end == undefined) {
        label = "";
      } else {
        label = `${formatDate(props.start)} - ${formatDate(props.end)}`;
      }
    } else {
      label = t(current.label);
    }

    return label;
  }

  function onApply(): void {
    op.current.hide();
    props.onChange(start, end);
  }

  function onCancel(): void {
    setStart(props.start);
    setEnd(props.end);
    op.current.hide();
  }

  function onClear(): void {
    setStart(undefined);
    setEnd(undefined);
    props.onChange(undefined, undefined);
    op.current.hide();
  }

  function onChange(range: any): void {
    setStart(range.selection.startDate);
    setEnd(range.selection.endDate);
  }

  return (
    <div className={"date-range-picker-container"}>
      <InputText
        className={"date-range-picker-input"}
        readOnly={true}
        onClick={(e) => op.current.toggle(e)}
        value={getLabel()}
      />
      <OverlayPanel
        ref={op}
        // className={"date-range-picker-overlay"}
        className="date-range-widget"
        style={{
          position: "absolute",
          display: "block",
          opacity: 1.016,
          zIndex: 1006,
        }}
        dismissable={true}
      >
        <DateRangePicker
          direction={"horizontal"}
          inputRanges={[]}
          minDate={new Date(2018)}
          maxDate={new Date()}
          months={1}
          moveRangeOnFirstSelection={true}
          onChange={onChange}
          ranges={[
            {
              startDate: start,
              endDate: end,
              key: "selection",
            },
          ]}
          //showSelectionPreview={true}
          showPreview
          staticRanges={props.ranges.map((option) => ({
            label: t(option.label),
            hasCustomRendering: false,
            range: () => {
              if (option.label === "last30days") {
                if (
                  !(
                    new Date().toLocaleDateString() ===
                    option.end.toLocaleDateString()
                  )
                ) {
                  forceUpdate();
                  window.location.reload();
                }
              }
              return { startDate: option.start, endDate: option.end };
            },
            isSelected: () => start === option.start && end === option.end,
          }))}
        />
        <div className={"date-range-picker-button-bar"}>
        <Button
            className={"p-button-secondary date-range-picker-buttons"}
            icon={"pi pi-calendar-times"}
            label={t("clear")}
            onClick={onClear}
          />
          <Button
            className={"p-button-secondary date-range-picker-buttons"}
            icon={"pi pi-times"}
            label={t("cancel")}
            onClick={onCancel}
          />
          <Button
            className={"date-range-picker-buttons"}
            icon={"pi pi-check"}
            label={t("apply")}
            onClick={onApply}
          />
        </div>
      </OverlayPanel>
    </div>
  );
}
