import { createStore, applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";
import { createLogger } from "redux-logger";
import combineReducer from "../reducers/combineReducer";

const middleWares: any = [
  thunk,
  createLogger({
    diff: true,
    duration: true,
  }),
];

const store = () => {
  return compose(applyMiddleware(...middleWares))(createStore)(combineReducer);
};

export default store();
