import React from "react";
import {Dropdown} from "primereact/dropdown";
import {LEAD_TYPE} from "../../util/Enums";

interface IPops {
    value: any

    onChange(value: any): void;
}

const EngLeadTypeDropdown = (props: IPops) => {

    return <Dropdown
        value={props.value}
        style={{width: "100%"}}
        options={[
            {
                label: "All",
                value: null
            },
            {
                label: "Engagement Offer",
                value: LEAD_TYPE.ENGAGEMENT_OFFER.toLowerCase().replaceAll("_", "-")
            },
            {
                label: "Engagement Chat",
                value: LEAD_TYPE.ENGAGEMENT_CHAT.toLowerCase().replaceAll("_", "-")
            },
            {
                label: "Engagement Ai Chat",
                value: LEAD_TYPE.ENGAGEMENT_AI_CHAT.toLowerCase().replaceAll("_", "-")
            },
            {
                label: "Engagement Ai Chat Delegated",
                value: "engagement-ai-delegated-chat"
            }
        ]}
        onChange={props.onChange}
    />
};

export default EngLeadTypeDropdown;