import React, { useEffect, useState } from "react";
import "./reports.css";
import AsyncSelect from "react-select/async";
import {
  getCampaignById,
  getCampaignList,
  getCampaignReport,
  getCampaignStatistics,
} from "../../../actions/campaignActions";
import Util from "../../../util/Util";
import { IDealershipDto } from "../../../../index.dts";
import { Panel } from "primereact/panel";
import { Chart } from "primereact/chart";
import moment from "moment";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import Axios from "../../../config/Axios";
import { get, concat } from "lodash";

const stc = require("string-to-color");
require("chartjs-plugin-trendline");

const LEADS_STYLE = buildStyles({
  pathColor: `#0062ff`,
  textColor: "black",
  textSize: "25px",
});

const VISITS_STYLE = buildStyles({
  pathColor: `#3dd598`,
  textColor: "black",
  textSize: "25px",
});

interface IProps {
  activeIndex: number;
}

interface IStats {
  totalLeads: number;
  totalVisitors: number;
  orgLeads: number;
  orgVisitors: number;
  paidLeads: number;
  paidVisitors: number;
  visitorsDatasets: any[];
  visitorsDatasetsDrillDown: any[];
  leadsDatasetsDrillDown: any[];
  leadDatasets: any[];
  avgScoreDataset: any[];
  notificationDataset: any[];
  engagementLeadsDatasets: any[];
  engagementVisitorsDatasets: any[];
  hasTotalSegment: boolean;
  hasTotalDealership: boolean;
}

const getOptions = (): any => {
  return {
    responsive: true,
    maintainAspectRatio: false,
  };
};

interface ICampaign {
  label: string;
  value: number;
  startDate: string;
  endDate: string;
  externalRefId: string;
  dealerships: IDealershipDto[];
  selectedDealerships: string[];
  segments: { id: string; name: string }[];
  selectedSegments: string[];
}

const CONSTANTS = {
  DEFAULT_TOTAL_KEY: "total",
  DEFAULT_DATA: {
    orgLeads: 0,
    orgVisitors: 0,
    paidLeads: 0,
    paidVisitors: 0,
    totalLeads: 0,
    totalVisitors: 0,
    visitorsDatasets: [],
    visitorsDatasetsDrillDown: [],
    leadsDatasetsDrillDown: [],
    leadDatasets: [],
    avgScoreDataset: [],
    engagementLeadsDatasets: [],
    engagementVisitorsDatasets: [],
    hasTotalSegment: false,
    hasTotalDealership: false,
    notificationDataset: [],
  },
};

export function CampaignReport(props: IProps) {
  const forceUpdate = useForceUpdate();
  const [data, setData] = useState<{ notifications: any; campaigns: any }>();
  const [aggregatedData, setAggregatedData] = useState<IStats>(
    CONSTANTS.DEFAULT_DATA
  );
  const [campaign, setCampaign] = useState<ICampaign>();

  const [visitorsDrillDown, setVisitorsDrillDown] = useState();
  const [leadsDrillDown, setLeadsDrillDown] = useState();

  const [pendingCampaigns, setPendingCampaigns] = useState<boolean>(false);

  useEffect(() => {
    if (campaign) {
      getReportsData(campaign);
    }
    // eslint-disable-next-line
  }, [campaign]);

  useEffect(() => {
    if (data && campaign) {
      generateStats(campaign, Util._.get(data, "stats"));
    }
    // eslint-disable-next-line
  }, [
    campaign,
    data,
    Util._.get(campaign, "selectedDealerships"),
    Util._.get(campaign, "selectedSegments"),
  ]);

  const getCampaign = (campaignId: number) => {
    Util.globalSpinner().show();
    getCampaignById(campaignId)
      .then((response) => {
        setCampaign({
          value: Util._.get(response, "data.id"),
          label: Util._.get(response, "data.name"),
          segments: Util.parseDelimitedStringToArray(
            Util._.get(response, "data.segment", ""),
            ","
          ).map((item: string) => {
            return {
              id: item,
              name: item,
            };
          }),
          selectedSegments: [CONSTANTS.DEFAULT_TOTAL_KEY],
          startDate: Util._.get(response, "data.startDate"),
          endDate: Util._.get(response, "data.endDate"),
          externalRefId: Util._.get(response, "data.externalRefId"),
          dealerships: Util._.get(response, "data.rtsGroup.dealerships", []),
          selectedDealerships: [CONSTANTS.DEFAULT_TOTAL_KEY],
        });
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => Util.globalSpinner().hide());
  };

  const promiseCampaigns = (inputValue: string) =>
    new Promise((resolve) => {
      setPendingCampaigns(true);
      getCampaignList({
        pageLimit: 100,
        pageNumber: 0,
        sorting: [],
        name: inputValue,
      })
        .then((response) => {
          resolve(
            response.data.content.map((item) => {
              return {
                value: item.id,
                label: item.name,
                endDate: item.endDate,
                startDate: item.startDate,
                rtsGroupId: item.rtsGroupId,
                externalRefId: item.externalRefId,
              };
            })
          );

          if (!campaign && response.data.content.length > 0) {
            const item = response.data.content[0];
            getCampaign(item.id);
          }
        })
        .finally(() => setPendingCampaigns(false));
    });

  const onCampaignChange = (campaign: any) => {
    getCampaign(campaign.value);
  };

  const getReportsData = (campaign: ICampaign) => {
    Util.globalSpinner().show();
    Axios.all([
      getCampaignStatistics(campaign.value),
      getCampaignReport({
        campaignId: campaign.externalRefId,
        startDate: moment(campaign.startDate).format(Util.localDateFormat),
        endDate: moment(campaign.endDate).format(Util.localDateFormat),
      }),
    ])
      .then((responses) => {
        setData(normalizeData(responses));
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => Util.globalSpinner().hide());
  };

  const getDataArray = (items: any[], dates: string[]): number[] => {
    const dataArray = new Array(dates.length).fill(0);

    items.forEach((value) => {
      const createdDate = Util._.get(value, "createdDate", null);

      const index = dates.findIndex((date) => date === createdDate);

      if (index !== -1) {
        dataArray[index] = Util._.get(value, "count", 0);
      }
    });

    return dataArray;
  };

  const normalizeData = (data: { [key: string]: any }): any => {
    const key = "notifications";

    const kaninData = Util._.get(data, "[1].data", {});
    const stats = Util._.get(kaninData, "stats", {});
    const dates: string[] = Util._.get(kaninData, "dates", []);

    const total: any[] = Util._.get(data, "[0].data.total", []);
    const segments: { [key: string]: any[] } = Util._.get(
      data,
      "[0].data.segment",
      {}
    );
    const dealerships: { [key: string]: any[] } = Util._.get(
      data,
      "[0].data.dealership",
      {}
    );
    const dealershipSegments: { [key: string]: any[] } = Util._.get(
      data,
      "[0].data.dealership_segment",
      {}
    );

    Object.entries(dealerships).forEach(([dealershipExtRefId, items]) => {
      const dataArray = getDataArray(items, dates);

      const dealership = Util._.get(stats, dealershipExtRefId, null);

      if (dealership) {
        dealership[key] = dataArray;
      }
    });

    Object.entries(dealershipSegments).forEach(
      ([dealershipExtRefId, dealershipItems]) => {
        Object.entries(dealershipItems).forEach(([segment, items]) => {
          const dataArray = getDataArray(items, dates);

          const dealershipSegment = Util._.get(
            stats,
            `${dealershipExtRefId}-${segment}`,
            null
          );

          if (dealershipSegment) {
            dealershipSegment[key] = dataArray;
          }
        });
      }
    );

    Object.entries(segments).forEach(([segment, items]) => {
      const dataArray = getDataArray(items, dates);

      const totalSegment = Util._.get(stats, `total-${segment}`, null);

      if (totalSegment) {
        totalSegment[key] = dataArray;
      }
    });

    const totalStats = Util._.get(stats, "total", null);

    if (totalStats) {
      totalStats[key] = getDataArray(total, dates);
    }

    return kaninData;
  };

  const generateStats = (campaign: ICampaign, stats: any) => {
    let data: IStats = Util.deepClone(CONSTANTS.DEFAULT_DATA);

    for (const dealershipRef of campaign.selectedDealerships) {
      if (
        dealershipRef === CONSTANTS.DEFAULT_TOTAL_KEY &&
        !data.hasTotalDealership
      ) {
        data.hasTotalDealership = true;
      }
      getSummaryByDealershipRef(campaign, stats, dealershipRef, data);
    }

    setAggregatedData(data);
    setLeadsDrillDown(undefined);
    setVisitorsDrillDown(undefined);
  };

  const getKey = (
    totalDealership: boolean,
    totalSegment: boolean,
    dealershipRef: string,
    segment: string
  ): string => {
    if (totalDealership && totalSegment) {
      return CONSTANTS.DEFAULT_TOTAL_KEY;
    } else if (!totalDealership && totalSegment) {
      return dealershipRef;
    } else if (!totalDealership && !totalSegment) {
      return `${dealershipRef}-${segment}`;
    } else if (totalDealership && !totalSegment) {
      return `${CONSTANTS.DEFAULT_TOTAL_KEY}-${segment}`;
    }
    return "";
  };

  const getSummaryByDealershipRef = (
    campaign: ICampaign,
    stats: any,
    dealershipRef: string,
    data: IStats
  ): void => {
    for (const segment of campaign.selectedSegments) {
      if (segment === CONSTANTS.DEFAULT_TOTAL_KEY && !data.hasTotalSegment) {
        data.hasTotalSegment = true;
      }

      const totalSegment: boolean = segment === CONSTANTS.DEFAULT_TOTAL_KEY;
      const totalDealership: boolean =
        dealershipRef === CONSTANTS.DEFAULT_TOTAL_KEY;

      const key = getKey(totalDealership, totalSegment, dealershipRef, segment);

      const summaryData = Util._.get(stats, key, null);

      const orgLeads = Util._.get(summaryData, "leads.org", []);
      const paidLeads = Util._.get(summaryData, "leads.paid", []);
      const totalLeads = Util.sumArrays(orgLeads, paidLeads);

      const orgVisitors = Util._.get(summaryData, "visitors.org", []);
      const paidVisitors = Util._.get(summaryData, "visitors.paid", []);
      const totalVisitors = Util.sumArrays(orgVisitors, paidVisitors);
      const avgScores = Util._.get(summaryData, "avg_scores", []);
      const converted = Util._.get(summaryData, "converted", []);
      const engagements = Util._.get(summaryData, "engagements", []);
      const notifications = Util._.get(summaryData, "notifications", []);

      if (key === CONSTANTS.DEFAULT_TOTAL_KEY) {
        data["orgLeads"] = Util.getDefaultIfNull(Util._.sum(orgLeads), 0);
        data["paidLeads"] = Util.getDefaultIfNull(Util._.sum(paidLeads), 0);
        data["totalLeads"] = Util.getDefaultIfNull(Util._.sum(totalLeads), 0);
        data["orgVisitors"] = Util.getDefaultIfNull(Util._.sum(orgVisitors), 0);
        data["paidVisitors"] = Util.getDefaultIfNull(
          Util._.sum(paidVisitors),
          0
        );
        data["totalVisitors"] = Util.getDefaultIfNull(
          Util._.sum(totalVisitors),
          0
        );
      } else if (key !== CONSTANTS.DEFAULT_TOTAL_KEY) {
        if (
          (data.hasTotalDealership && !data.hasTotalSegment) ||
          (!data.hasTotalDealership && data.hasTotalSegment) ||
          (!data.hasTotalDealership && !data.hasTotalSegment)
        ) {
          data["orgLeads"] =
            Util.getDefaultIfNull(Util._.sum(orgLeads), 0) + data["orgLeads"];
          data["paidLeads"] =
            Util.getDefaultIfNull(Util._.sum(paidLeads), 0) + data["paidLeads"];
          data["totalLeads"] =
            Util.getDefaultIfNull(Util._.sum(totalLeads), 0) +
            data["totalLeads"];
          data["orgVisitors"] =
            Util.getDefaultIfNull(Util._.sum(orgVisitors), 0) +
            data["orgVisitors"];
          data["paidVisitors"] =
            Util.getDefaultIfNull(Util._.sum(paidVisitors), 0) +
            data["paidVisitors"];
          data["totalVisitors"] =
            Util.getDefaultIfNull(Util._.sum(totalVisitors), 0) +
            data["totalVisitors"];
        }
      }

      const label = getLabel(dealershipRef, segment, campaign);

      data["visitorsDatasets"].push({
        label: label,
        data: totalVisitors,
        fill: false,
        borderColor: Util.stringToColor(label),
        backgroundColor: Util.stringToColor(label),
        trendlineLinear:
          key === CONSTANTS.DEFAULT_TOTAL_KEY
            ? {
                style: stc("All red"),
                lineStyle: "line",
                width: 2,
              }
            : undefined,
      });

      data["visitorsDatasetsDrillDown"].push({
        label: "Paid",
        id: label,
        data: paidVisitors,
        fill: false,
        borderColor: Util.stringToColor("Paid"),
        backgroundColor: Util.stringToColor("Paid"),
      });

      data["visitorsDatasetsDrillDown"].push({
        label: "Org",
        id: label,
        data: orgVisitors,
        fill: false,
        borderColor: Util.stringToColor("Org"),
        backgroundColor: Util.stringToColor("Org"),
      });

      data["leadsDatasetsDrillDown"].push({
        label: "Org",
        id: label,
        data: orgLeads,
        fill: false,
        borderColor: Util.stringToColor("Org"),
        backgroundColor: Util.stringToColor("Org"),
      });

      data["leadsDatasetsDrillDown"].push({
        label: "Paid",
        id: label,
        data: paidLeads,
        fill: false,
        borderColor: Util.stringToColor("Paid"),
        backgroundColor: Util.stringToColor("Paid"),
      });

      data["leadDatasets"].push({
        label: label,
        data: totalLeads,
        fill: false,
        borderColor: Util.stringToColor(label),
        backgroundColor: Util.stringToColor(label),
        trendlineLinear:
          key === CONSTANTS.DEFAULT_TOTAL_KEY
            ? {
                style: stc("All red"),
                lineStyle: "line",
                width: 2,
              }
            : undefined,
      });

      data["avgScoreDataset"].push({
        label: label,
        data: avgScores,
        fill: false,
        borderColor: Util.stringToColor(label),
        backgroundColor: Util.stringToColor(label),
        trendlineLinear:
          key === CONSTANTS.DEFAULT_TOTAL_KEY
            ? {
                style: stc("All red"),
                lineStyle: "line",
                width: 2,
              }
            : undefined,
      });

      data["engagementLeadsDatasets"].push({
        label: label,
        data: converted,
        fill: false,
        borderColor: Util.stringToColor(label),
        backgroundColor: Util.stringToColor(label),
        trendlineLinear:
          key === CONSTANTS.DEFAULT_TOTAL_KEY
            ? {
                style: stc("All red"),
                lineStyle: "line",
                width: 2,
              }
            : undefined,
      });

      data["engagementVisitorsDatasets"].push({
        label: label,
        data: engagements,
        fill: false,
        borderColor: Util.stringToColor(label),
        backgroundColor: Util.stringToColor(label),
        trendlineLinear:
          key === CONSTANTS.DEFAULT_TOTAL_KEY
            ? {
                style: stc("All red"),
                lineStyle: "line",
                width: 2,
              }
            : undefined,
      });

      data["notificationDataset"].push({
        label: label,
        data: notifications,
        fill: false,
        borderColor: Util.stringToColor(label),
        backgroundColor: Util.stringToColor(label),
        trendlineLinear:
          key === CONSTANTS.DEFAULT_TOTAL_KEY
            ? {
                style: stc("All red"),
                lineStyle: "line",
                width: 2,
              }
            : undefined,
      });
    }
  };

  const getLabel = (
    dealershipRef: string,
    segment: string,
    campaign: ICampaign
  ): string => {
    if (
      dealershipRef === CONSTANTS.DEFAULT_TOTAL_KEY &&
      segment === CONSTANTS.DEFAULT_TOTAL_KEY
    ) {
      return "Total";
    } else if (
      dealershipRef !== CONSTANTS.DEFAULT_TOTAL_KEY &&
      segment === CONSTANTS.DEFAULT_TOTAL_KEY
    ) {
      return `${getDealershipNameByExtRefId(dealershipRef, campaign)}`;
    } else if (
      dealershipRef === CONSTANTS.DEFAULT_TOTAL_KEY &&
      segment !== CONSTANTS.DEFAULT_TOTAL_KEY
    ) {
      return `Total-${segment}`;
    }

    return `${getDealershipNameByExtRefId(dealershipRef, campaign)}-${segment}`;
  };

  const getDealershipNameByExtRefId = (
    extRefId: string,
    campaign: ICampaign
  ): string => {
    const index = campaign.dealerships.findIndex(
      (item) => item.externalRefId === extRefId
    );

    if (index !== -1) {
      return Util._.get(campaign, `dealerships[${index}].name`, "");
    }
    return extRefId;
  };

  const getCampaignVisitorsDataset = (aggregatedData: IStats): any => {
    if (visitorsDrillDown) {
      return aggregatedData.visitorsDatasetsDrillDown.filter(
        (item) => item.id === visitorsDrillDown
      );
    }
    return aggregatedData.visitorsDatasets;
  };

  const getCampaignLeadsDataset = (aggregatedData: IStats): any => {
    if (leadsDrillDown) {
      return aggregatedData.leadsDatasetsDrillDown.filter(
        (item) => item.id === leadsDrillDown
      );
    }
    return aggregatedData.leadDatasets;
  };

  const onRefresh = (): void => {
    if (campaign) {
      getReportsData(campaign);
    } else {
      Util.warning("Select campaign first");
    }
  };

  const onSelectDealership = (values: any[]) => {
    if (!campaign) {
      return;
    }

    campaign.selectedDealerships = values || [];
    forceUpdate();
  };

  const onSelectSegment = (values: any[]) => {
    if (!campaign) {
      return;
    }

    campaign.selectedSegments = values || [];
    forceUpdate();
  };

  return (
    <div id={"reports-view"}>
      <div className={"p-grid"}>
        <div className={"p-col-12 p-lg-4"}>
          <label>Campaign</label>
          <div style={{ display: "flex" }}>
            <AsyncSelect
              isLoading={pendingCampaigns}
              cacheOptions
              defaultOptions
              className={"multi-select"}
              value={campaign}
              placeholder={"Select Group Campaign"}
              loadOptions={(inputValue): any => promiseCampaigns(inputValue)}
              onChange={onCampaignChange}
            />
            <Button
              icon={"pi pi-refresh"}
              onClick={onRefresh}
              style={{ minWidth: 38, width: 38, marginLeft: 2 }}
            />
          </div>
        </div>
        <div className={"p-col-12 p-lg-4"}>
          <label>Dealerships</label>
          <MultiSelect
            filter={true}
            optionValue={"externalRefId"}
            optionLabel={"name"}
            disabled={!campaign}
            style={{ width: "100%", height: 38 }}
            placeholder={"Select Dealership(s)"}
            value={Util._.get(campaign, "selectedDealerships", [])}
            options={concat(
              [
                {
                  name: "Total",
                  externalRefId: CONSTANTS.DEFAULT_TOTAL_KEY,
                },
              ],
              get(campaign, "dealerships", [])
            )}
            onChange={(e) => onSelectDealership(e.value)}
          />
        </div>
        <div className={"p-col-12 p-lg-4"}>
          <label>Segments</label>
          <MultiSelect
            filter={true}
            optionValue={"id"}
            optionLabel={"name"}
            disabled={!campaign}
            placeholder={"Select Segment(s)"}
            style={{ width: "100%", height: 38 }}
            value={Util._.get(campaign, "selectedSegments", [])}
            options={Util._.concat(
              [
                {
                  name: "Total",
                  id: CONSTANTS.DEFAULT_TOTAL_KEY,
                },
              ],
              Util._.get(campaign, "segments", [])
            )}
            onChange={(e) => onSelectSegment(e.value)}
          />
        </div>
      </div>
      <div className={"p-grid"}>
        <div className={"p-col-12 p-lg-12"}>
          <Panel
            className={"report-panel"}
            header={`Summary ${
              campaign
                ? moment(campaign.startDate).format(Util.usDateFormat) +
                  " - " +
                  moment(campaign.endDate).format(Util.usDateFormat)
                : ""
            }`}
          >
            <div
              style={{ textAlign: "center" }}
              id={"period"}
              className={"p-col-12 p-lg-12"}
            >
              <div style={{ margin: "auto" }} className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4"}>
                  <div className={"chart-container"}>
                    <CircularProgressbar
                      value={aggregatedData.totalLeads}
                      maxValue={aggregatedData.totalLeads}
                      text={aggregatedData.totalLeads.toString()}
                      styles={LEADS_STYLE}
                      strokeWidth={4}
                    />
                    <label className={"text"}>
                      <span className={"lead"}>Leads</span> Total
                    </label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4"}>
                  <div className={"chart-container"}>
                    <CircularProgressbar
                      value={aggregatedData.orgLeads}
                      maxValue={aggregatedData.totalLeads}
                      text={aggregatedData.orgLeads.toString()}
                      styles={LEADS_STYLE}
                      strokeWidth={4}
                    />
                    <label className={"text"}>
                      <span className={"lead"}>Leads</span> Organic/Direct
                    </label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4"}>
                  <div className={"chart-container"}>
                    <CircularProgressbar
                      value={aggregatedData.paidLeads}
                      maxValue={aggregatedData.totalLeads}
                      text={aggregatedData.paidLeads.toString()}
                      styles={LEADS_STYLE}
                      strokeWidth={4}
                    />
                    <label className={"text"}>
                      <span className={"lead"}>Leads</span> Paid
                    </label>
                  </div>
                </div>
              </div>

              <div style={{ margin: "auto" }} className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4"}>
                  <div className={"chart-container"}>
                    <CircularProgressbar
                      value={aggregatedData.totalVisitors}
                      maxValue={aggregatedData.totalVisitors}
                      text={aggregatedData.totalVisitors.toString()}
                      styles={VISITS_STYLE}
                      strokeWidth={4}
                    />
                    <label className={"text"}>
                      <span className={"visit"}>Visits</span> Total
                    </label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4"}>
                  <div className={"chart-container"}>
                    <CircularProgressbar
                      value={aggregatedData.orgVisitors}
                      maxValue={aggregatedData.totalVisitors}
                      text={aggregatedData.orgVisitors.toString()}
                      styles={VISITS_STYLE}
                      strokeWidth={4}
                    />
                    <label className={"text"}>
                      <span className={"visit"}>Visits</span> Organic/Direct
                    </label>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-4 p-md-4 p-sm-4"}>
                  <div className={"chart-container"}>
                    <CircularProgressbar
                      value={aggregatedData.paidVisitors}
                      maxValue={aggregatedData.totalVisitors}
                      text={aggregatedData.paidVisitors.toString()}
                      styles={VISITS_STYLE}
                      strokeWidth={4}
                    />
                    <label className={"text"}>
                      <span className={"visit"}>Visits</span> Paid
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel
            className={"report-panel"}
            header={
              <div style={{ width: "100%", display: "flex" }}>
                <label>Campaign Visitors</label>
                <div style={{ marginLeft: "auto", width: 250 }}>
                  <Dropdown
                    showClear={true}
                    value={visitorsDrillDown}
                    optionValue={"id"}
                    optionLabel={"name"}
                    style={{ width: "100%" }}
                    options={aggregatedData.visitorsDatasetsDrillDown
                      .filter((item) => item.label === "Paid")
                      .map((item) => {
                        return {
                          id: item.id,
                          name: item.id,
                        };
                      })}
                    onChange={(e) => setVisitorsDrillDown(e.value)}
                    placeholder={"Select drill down report"}
                  />
                </div>
              </div>
            }
          >
            <Chart
              type="line"
              height={"500"}
              data={{
                datasets: getCampaignVisitorsDataset(aggregatedData),
                labels: Util._.get(data, "dates"),
              }}
              options={getOptions()}
            />
          </Panel>
          <Panel
            className={"report-panel"}
            header={
              <div style={{ width: "100%", display: "flex" }}>
                <label>Campaign Leads</label>
                <div style={{ marginLeft: "auto", width: 250 }}>
                  <Dropdown
                    showClear={true}
                    value={leadsDrillDown}
                    optionValue={"id"}
                    optionLabel={"name"}
                    style={{ width: "100%" }}
                    options={aggregatedData.leadsDatasetsDrillDown
                      .filter((item) => item.label === "Paid")
                      .map((item) => {
                        return {
                          id: item.id,
                          name: item.id,
                        };
                      })}
                    onChange={(e) => setLeadsDrillDown(e.value)}
                    placeholder={"Select drill down report"}
                  />
                </div>
              </div>
            }
          >
            <Chart
              type="line"
              height={"500"}
              data={{
                datasets: getCampaignLeadsDataset(aggregatedData),
                labels: Util._.get(data, "dates"),
              }}
              options={getOptions()}
            />
          </Panel>
          <Panel className={"report-panel"} header={"Average Engagement Score"}>
            <Chart
              type="line"
              height={"500"}
              data={{
                datasets: aggregatedData.avgScoreDataset,
                labels: Util._.get(data, "dates"),
              }}
              options={getOptions()}
            />
          </Panel>
          <Panel className={"report-panel"} header={"Engagement Leads"}>
            <Chart
              type="line"
              height={"500"}
              data={{
                datasets: aggregatedData.engagementLeadsDatasets,
                labels: Util._.get(data, "dates"),
              }}
              options={getOptions()}
            />
          </Panel>
          <Panel className={"report-panel"} header={"Engagement Visitors"}>
            <Chart
              type="line"
              height={"500"}
              data={{
                datasets: aggregatedData.engagementVisitorsDatasets,
                labels: Util._.get(data, "dates"),
              }}
              options={getOptions()}
            />
          </Panel>
          <Panel className={"report-panel"} header={"Notifications"}>
            <Chart
              type="line"
              height={"500"}
              data={{
                datasets: aggregatedData.notificationDataset,
                labels: Util._.get(data, "dates"),
              }}
              options={getOptions()}
            />
          </Panel>
        </div>
      </div>
    </div>
  );
}

export default CampaignReport;
