import React, {ChangeEvent, useEffect, useState} from 'react';
import $ from 'jquery'

import './selectable_image.css';
import Util from "../../util/Util";

const INPUT_ID: string = 'selectable_image_picker';
const NO_IMAGE_URL: string = 'assets/images/no_image_placeholder.png';

interface IProps {
  id?: string;
  url?: string;
  maxSize?: number;
  inputId?: string;
  imgClassName?: string;
  onChange: (file: File) => void;
}

const SelectableImage = (props: IProps) => {
  const [inputId] = useState<string>(props.inputId || INPUT_ID);
  const [currentUrl, setCurrentUrl] = useState<string>(props.url || NO_IMAGE_URL);

  useEffect(() => {
    if (props.url && props.url !== currentUrl) {
      setCurrentUrl(props.url);
    }
    // eslint-disable-next-line
  }, [props.url])

  const onClick = (): void => {
    const selector = `#${inputId}`;
    $(selector).click();
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const files: FileList | null = event.target.files;
    const file: File | null = files?.length ? files[0] : null;


    if (!file) {
      return;
    }

    if (file.size > Util.getDefaultIfNull(props.maxSize, 50000)) {
      Util.warning("File is too large!");
      event.target.value = ""
      return;
    }


    const url = URL.createObjectURL(file);

    setCurrentUrl(url);
    props.onChange(file);
  }

  return (
    <div id={props.id} className='selectable_image_container'>
      <i className="pi pi-pencil edit_image_icon"/>
      <img
        alt={"..."}
        className={`selectable_image ${props.imgClassName}`}
        onClick={onClick}
        src={currentUrl}
      />
      <input
        accept=".gif, .jpg, .png, .svg, .jpeg"
        className="selectable_image_picker"
        id={inputId}
        onChange={onChange}
        type="file"
      />
    </div>
  );
}

export default SelectableImage;