import { INotificationService } from "../services/notification/INotificationService";

const actions = {
  SET_GROWL: "SET_GROWL",
  SET_GLOBAL_SPINNER_REF: "SET_GLOBAL_SPINNER_REF",

  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",

  AUTH_PENDING: "AUTH_PENDING",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_ERROR: "AUTH_ERROR",

  USER_UPDATE: "USER_UPDATE",

  SET_DEALERSHIP_CONTEXT: "SET_DEALERSHIP_CONTEXT",
  UPDATE_DROPDOWN_KEY: "UPDATE_DROPDOWN_KEY",
  SET_SWIM_LANES_CONFIG: "SET_SWIM_LANES_CONFIG",
  SET_DEVICE: "SET_DEVICE",
  UPDATE_USER_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",
  UPDATE_MY_TRAFFIC_TAB_INDEX: "UPDATE_MY_TRAFFIC_TAB_INDEX",
  UPDATE_MY_TRAFFIC_STATS_TAB_INDEX: "UPDATE_MY_TRAFFIC_STATS_TAB_INDEX",
  UPDATE_VISITORS_AND_LEADS_CONFIG: "UPDATE_VISITORS_AND_LEADS_CONFIG",
  SET_PROGRESS_BAR: "SET_PROGRESS_BAR",
  REMOVE_PROGRESS_BAR: "REMOVE_PROGRESS_BAR",
  SET_NOTIFICATION_SERVICE: "SET_NOTIFICATION_SERVICE",
  UPDATE_PERFORMANCE_REPORT_TAB_INDEX: "UPDATE_PERFORMANCE_REPORT_TAB_INDEX",
};

export const setNotificationService = (payload: INotificationService) => ({
  type: actions.SET_NOTIFICATION_SERVICE,
  payload: payload,
});

export default actions;
