enum PageTypes {
  all,
  vdp,
  srp,
  other
}

export default PageTypes;

export class PageTypesUtil {
  public static getName(value?: PageTypes): string {
    let name;

    switch (value) {
      case PageTypes.vdp:
        name = "vdp";
        break;
      case PageTypes.srp:
        name = "srp";
        break;
      case PageTypes.all:
        name = "total";
        break;
      case PageTypes.other:
        name = "other";
        break;
      default:
        name = "unknown"
        break;
    }

    return name;
  }
}