import React, {useEffect, useState} from "react";
import "./user_activate.css"
import {useHistory} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {Password} from 'primereact/password';
import {Button} from "primereact/button";
import {authSuccess, getUserInfo, login, userActivate, validateKey} from "../../../actions/authActions";
import Util from "../../../util/Util";
import {useDispatch} from "react-redux";
import Spinner from "../../../components/spinner/Spinner";

const queryString = require('query-string');

enum KEY_STATUS {
    VALID = "VALID",
    INVALID = "INVALID",
    EXPIRED = "EXPIRED"
}

export function UserActivate() {
    const history = useHistory();
    const dispatch = useDispatch();

    const [key] = useState(queryString.parse(history.location.search).key);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [pending, setPending] = useState<boolean>(true);
    const [keyStatus, setKeyStatus] = useState<KEY_STATUS>(KEY_STATUS.VALID);

    useEffect(() => {
        validateInviteKey(key);
        // eslint-disable-next-line
    }, []);

    const validateInviteKey = (key: string) => {
        setPending(true);
        validateKey(key)
            .then(response => {
                const firstName = Util._.get(response, "data.firstName", "");
                const lastName = Util._.get(response, "data.lastName", "");
                const status = Util._.get(response, "data.status", KEY_STATUS.INVALID);

                switch (status) {
                    case "INVALID":
                        history.push(Util.PATH_NAMES.LOGIN);
                        break;
                    default:
                        setFirstName(firstName);
                        setLastName(lastName);
                        setKeyStatus(status);
                        break;
                }
            })
            .catch(error => {
                Util.showError(error);
            })
            .finally(() => setPending(false))
    }

    const onSubmit = () => {
        if (key && firstName.trim() && lastName.trim() && Util.validatePassword(password) && password === confirmPassword) {
            Util.globalSpinner().show();
            userActivate(key, {firstName: firstName.trim(), lastName: lastName.trim(), password: window.btoa(password)})
                .then(activateResponse => {
                    login(activateResponse.data.email, password)
                        .then(response => {
                            getUserInfo(response.data.access_token)
                                .then(res => {
                                    dispatch(authSuccess({auth: response.data, user: res.data}));
                                    history.push(Util.PATH_NAMES.DASHBOARD);
                                })
                                .catch(error => {
                                    Util.showError(error);
                                })
                                .finally(() => {
                                    Util.globalSpinner().hide();
                                });
                            Util.success("User activated successfully.");
                        });
                })
                .catch(error => {
                    Util.globalSpinner().hide();
                    if (error?.response?.data?.message?.toLowerCase().includes("invalid token")) {
                        Util.error("Activation link has expired or is invalid.");
                    } else {
                        Util.showError(error);
                    }
                })
        } else {
            if (!key) {
                Util.warning("Invalid token");
            } else if (!firstName.trim()) {
                Util.warning("Invalid First Name");
            } else if (!lastName.trim()) {
                Util.warning("Invalid Last Name");
            } else if (!Util.validatePassword(password)) {
                Util.warning("Passwords must:\n" +
                    "Be a minimum of eight (8) characters in length\n" +
                    "Be a maximum of sixty four (64) characters in length\n" +
                    "Contain at least one (1) character from each four (4) of the following categories:\n" +
                    "Uppercase letter (A-Z)\n" +
                    "Lowercase letter (a-z)\n" +
                    "Digit (0-9)\n" +
                    "Special character (~`!@#$%^&*()+=_-{}[]\\|:;”’?/<>,.)");
            } else {
                Util.warning("Passwords does not match");
            }
        }
    };


    const returnNode = (): any => {
        if (pending) {
            return <Spinner visible={true}/>
        }

        if (keyStatus === KEY_STATUS.EXPIRED) {
            return (
                <div style={{maxWidth: 900, marginLeft: "auto", marginRight: "auto"}}>
                    <div style={{padding: 10}} className={"p-grid"}>
                        <div className="p-col-12">
                            <div style={{backgroundColor: "#3D5899"}} className="card card-w-title datatable-demo">
                                <div style={{color: "white"}}>
                                    <img style={{width: "300px"}} src={"assets/images/logo.png"}
                                         alt={"Pureinfluencer"}/>
                                    <p style={{fontSize: 48}}>The invite link is no longer Active</p>
                                    <p style={{fontSize: 24}}>
                                        <span>Please check with PureInfluencer Support (</span>
                                        <span style={{color: "blue"}}>support@pureinfluencer.com</span>
                                        <span>) if there's a new link available</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (keyStatus === KEY_STATUS.INVALID) {
            return null;
        }

        return (
            <div id={"user-activate-view"}>
                <div style={{padding: 20}} className={"p-grid"}>
                    <div className="p-col-12">
                        <div className="card card-w-title datatable-demo">
                            <div
                                className={"p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"}>
                                <h1>Activate</h1>
                                <div className={"p-grid"}>
                                    <div className={"p-col-12"}>
                                        <label>First Name</label>
                                    </div>
                                    <div className={"p-col-12"}>
                                        <InputText
                                            value={firstName}
                                            maxLength={64}
                                            style={{width: "100%"}}
                                            onChange={(e: any) => setFirstName(e.target.value)}
                                        />
                                    </div>

                                    <div className={"p-col-12"}>
                                        <label>Last Name</label>
                                    </div>
                                    <div className={"p-col-12"}>
                                        <InputText
                                            value={lastName}
                                            maxLength={64}
                                            style={{width: "100%"}}
                                            onChange={(e: any) => setLastName(e.target.value)}
                                        />
                                    </div>

                                    <div className={"p-col-12"}>
                                        <label>Password</label>
                                    </div>
                                    <div className={"p-col-12"}>
                                        <Password
                                            value={password}
                                            style={{width: "100%"}}
                                            onChange={(e: any) => setPassword(e.target.value)}
                                        />
                                    </div>

                                    <div className={"p-col-12"}>
                                        <label>Confirm Password</label>
                                    </div>
                                    <div className={"p-col-12"}>
                                        <Password
                                            feedback={false}
                                            value={confirmPassword}
                                            style={{width: "100%"}}
                                            onChange={(e: any) => setConfirmPassword(e.target.value)}
                                        />
                                    </div>

                                    <div className={"p-col-12"}>
                                        <Button onClick={() => history.push(Util.PATH_NAMES.LOGIN)}
                                                className={"p-button-secondary"}
                                                style={{float: "left"}} label={"Back to login"}/>
                                        <Button onClick={onSubmit} style={{float: "right"}} label={"Sign in"}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return returnNode();
}

export default UserActivate
