import React, {useImperativeHandle, useState} from "react";
import {Dialog} from "primereact/dialog";
import {getVisitorDetails} from "../actions/kaninActions";
import Util from "../util/Util";
import {IVisitorDetails} from "../../index.dts";
import {Box, Tab,} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import Calculation from "./Calculation";
import Pages from "./Pages";
import Spinner from "../components/spinner/Spinner";

interface IProps {
  dealershipRefId: string;
}

const VisitorDetails = React.forwardRef((props: IProps, ref: any) => {

    const [value, setValue] = useState<string>('1');
    const [pending, setPending] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [visitorRefId, setVisitorRefId] = useState<string>();
    const [visitorDetails, setVisitorDetails] = useState<IVisitorDetails>();


    useImperativeHandle(ref, () => ({
      show: onShow,
      hide: onHide
    }));

    const onShow = (visitorRefId: string): void => {
      getData(visitorRefId);
      setVisible(true);
      setVisitorRefId(visitorRefId);
    }

    const onHide = (): void => {
      setValue("1");
      setVisible(false);
      setPending(false);
      setVisitorRefId(undefined);
      setVisitorDetails(undefined);
    }

    const getData = (visitorRefId: string): void => {
      setPending(true);
      getVisitorDetails(props.dealershipRefId, visitorRefId, true)
        .then(response => {
          setVisitorDetails(response.data?.details);
        })
        .catch(error => {
          Util.showError(error);
        })
        .finally(() => {
          setPending(false);
        })
    }

    const handleChange = (event: any, newValue: string) => {
      setValue(newValue);
    };

    const render = (): React.ReactElement => {
      return (
        <Dialog
          visible={visible}
          onHide={onHide}
          style={{maxWidth: "80%"}}
          header={`Visitor Details: (${visitorRefId})`}
        >
          <Spinner visible={pending}/>
          <TabContext value={value}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Calculation" value="1"/>
                <Tab label="Pages" value="2"/>
              </TabList>
            </Box>
            <TabPanel style={{padding: 0}} value="1">
              <Calculation
                visitorRefId={visitorRefId}
                visitorDetails={visitorDetails}/>
            </TabPanel>
            <TabPanel style={{padding: 0}} value="2">
              <Pages pages={visitorDetails?.pages}/>
            </TabPanel>
          </TabContext>
        </Dialog>
      )
    }

    return render();
  }
);

export default VisitorDetails;