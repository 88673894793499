import { Panel } from "primereact/panel";
import React, { useEffect, useState } from "react";
import { IDS } from "../../constants";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import {
  EDUCATION,
  GENDER,
  YES_NO,
  HOME_OWNER_RENTER,
  LANGUAGE,
  MARITAL_STATUS,
  MODE,
} from "../../../util/Enums";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import { useTranslation } from "react-i18next";
import { InputSwitch } from "primereact/inputswitch";
import { InputNumber } from "primereact/inputnumber";
import { Slider } from "primereact/slider";
import {
  DemographicsFilter,
  ICampaignInfo,
  IMyTrafficNotificationDto,
  IMyTrafficNotificationFull,
  IStore,
  SmartPixlUniqueValuesWrapperDto,
} from "../../../../index.dts";
import { Calendar } from "primereact/calendar";
import Util from "../../../util/Util";
import moment from "moment";
import { Button } from "primereact/button";
import { get, isEqual } from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import {
  getValuesForDropdowns,
  updateMyCampaign,
} from "../../../actions/campaignsActions";
import { useHistory } from "react-router-dom";

type Props = {
  campaignInfo: ICampaignInfo;
  mode: MODE.EDIT | MODE.NEW | MODE.DUPLICATE;
  initialData: ICampaignInfo;
  demographicsData: DemographicsFilter;
  setDemographicsData: React.Dispatch<
    React.SetStateAction<DemographicsFilter | null>
  >;
  setInitialData: React.Dispatch<React.SetStateAction<ICampaignInfo>>;
  setStep: React.Dispatch<
    React.SetStateAction<{
      step: number | string;
      payload?: any;
    }>
  >;
};

const DemographicsPanel = (props: Props) => {
  const {
    mode,
    campaignInfo,
    initialData,
    setInitialData,
    setStep,
    setDemographicsData,
  } = props;
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  const history = useHistory();
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();
  const [dropdowns, setDropdowns] = useState<SmartPixlUniqueValuesWrapperDto>();
  const [useDemographics, setUseDemographics] = useState<boolean>(
    initialData?.demographicsFilter ? true : false
  );
  const [yearsInResidence, setYearsInResidence] = useState<boolean>(
    initialData?.demographicsFilter?.yearsInResidenceFrom != null ||
      initialData?.demographicsFilter?.yearsInResidenceTo != null
      ? true
      : false
  );
  const [income, setIncome] = useState<boolean>(
    initialData?.demographicsFilter?.incomeFrom != null ||
      initialData?.demographicsFilter?.incomeTo != null
      ? true
      : false
  );

  const [demographics, setDemographics] = useState<DemographicsFilter>({
    isMarried: false,
    gender: "",
    hasChildren: false,
    numberOfChildren: 0,
    homeOwner: false,
    yearsInResidenceFrom: 0,
    yearsInResidenceTo: 0,
    year: 0,
    make: "",
    model: "",
    firstSeenFrom: moment()
      .subtract(30, "days")
      .format(Util.localDateTimeFormat),
    firstSeenTo: moment().format(Util.localDateTimeFormat),
    lastSeenFrom: moment()
      .subtract(30, "days")
      .format(Util.localDateTimeFormat),
    lastSeenTo: moment().format(Util.localDateTimeFormat),
    incomeFrom: 0,
    incomeTo: 0,
    education: "",
    occupation: "",
    businessOwner: false,
    language: "",
  });

  useEffect(() => {
    initialData?.demographicsFilter &&
      setDemographics(initialData?.demographicsFilter);
    getValuesForDropdowns().then((response) => {
      setDropdowns(response.data);
    });
  }, []);

  const setValue = (field: string, value: any) => {
    const type = typeof value;

    switch (type) {
      case "string":
        // @ts-ignore
        demographics[field] = value;
        break;
      default:
        // @ts-ignore
        demographics[field] = value;
    }
    forceUpdate();
  };

  const getYears = () => {
    const year = new Date().getFullYear();
    const years = Array.from(new Array(54), (val, index) => year - index);
    return years;
  };

  const getMakes = () => {
    return []; //props?.campaignInfo?.filters;
    //comes from BE
  };

  const getModels = () => {
    return [];
    //comes from BE
  };

  const getFirstSeenDateArray = (): Date[] => {
    const array: any = [moment(demographics.firstSeenFrom).toDate()];

    if (demographics.firstSeenTo) {
      array.push(moment(demographics.firstSeenTo).toDate());
    }

    return array;
  };

  const getLastSeenDateArray = (): Date[] => {
    const array: any = [moment(demographics.lastSeenFrom).toDate()];

    if (demographics.lastSeenTo) {
      array.push(moment(demographics.lastSeenTo).toDate());
    }

    return array;
  };

  const transformNotificationsToDto = (
    notifications: IMyTrafficNotificationFull[]
  ): IMyTrafficNotificationDto[] => {
    const formattedDtos: IMyTrafficNotificationDto[] = notifications.map(
      (notification) => {
        return {
          ...((mode === MODE.EDIT || mode === MODE.DUPLICATE) && {
            id: notification?.id || null,
          }),
          name: notification?.name,
          dealershipId: dealershipContext.id,
          segment: notification?.segment,
          message: notification?.message,
          engagementScore: notification?.engagementScore,
          delaySend: notification?.delaySend,
          limitByZipCodes: notification?.limitByZipCodes,
          enabled: notification?.enabled,
          zipCodes: notification?.zipCodes,
          useChatBot: notification?.useChatBot,
          useSharkTank: notification?.useSharkTank,
          chatbotId: notification?.chatbotId,
          chatGreetingId: notification?.chatGreetingId,
          users: notification?.users?.map((user, index) => {
            return {
              userId: user?.userId,
              order: notification?.users?.length - index,
            };
          }),
          chatBotWorkflows: notification?.chatBotWorkflows?.map((item) => {
            return {
              chatBotId: item.chatBot.id,
              chatGreetingId: item.chatGreeting.id,
              daysOfWeek: item.daysOfWeek,
              endTime: item.endTime,
              startTime: item.startTime,
            };
          }),
        };
      }
    );
    return formattedDtos;
  };

  const onBack = () => {
    setStep((prev: any) => {
      return {
        ...prev,
        step: (prev?.step as number) - 1,
      };
    });
  };

  return (
    <Panel
      style={{ width: "100%", marginBottom: 10 }}
      header={"Select a trigger"}
    >
      <div id={IDS.demographicsPanel.wrapper}>
        <div className="card card-w-title datatable-demo">
          <Panel
            id={IDS.demographicsPanel.panel.basic}
            style={{ width: "100%", marginBottom: 10 }}
            header={
              <div style={{ display: "flex", marginTop: 5, marginBottom: 5 }}>
                Demographics
                <div style={{ display: "flex", height: 18, marginRight: 5 }}>
                  <div style={{ marginTop: 2, marginLeft: 15, marginRight: 5 }}>
                    <InputSwitch
                      id={IDS.demographicsPanel.switch.useDemographics}
                      checked={useDemographics}
                      onChange={(e: any) => setUseDemographics(e.target.value)}
                    />
                  </div>
                  <label>Use Demographics</label>
                </div>
              </div>
            }
          >
            {useDemographics && (
              <div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Marital Status</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <Dropdown
                      id={IDS.dropdown.maritalStatus}
                      style={{ width: "100%" }}
                      placeholder={"Marital Status"}
                      value={
                        demographics.isMarried === true
                          ? MARITAL_STATUS.Married
                          : demographics.isMarried === false
                          ? MARITAL_STATUS.Single
                          : MARITAL_STATUS.Irrelevant
                      }
                      options={Object.entries(MARITAL_STATUS).map((entry) => {
                        return {
                          label: entry[0],
                          value: entry[1],
                        };
                      })}
                      onChange={(e) => {
                        setValue(
                          "isMarried",
                          e.value === MARITAL_STATUS.Married
                            ? true
                            : e.value === MARITAL_STATUS.Single
                            ? false
                            : null
                        );
                        // forceUpdate();
                      }}
                    />
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Gender</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <Dropdown
                      id={IDS.dropdown.gender}
                      style={{ width: "100%" }}
                      placeholder={
                        demographics.gender === null ? "Irrelevant" : "Gender"
                      }
                      value={demographics.gender}
                      options={dropdowns?.genders.map((entry) => {
                        if (entry === null) {
                          return {
                            label: "Irrelevant",
                            value: entry,
                          };
                        } else
                          return {
                            label: entry,
                            value: entry,
                          };
                      })}
                      onChange={(e) => {
                        setValue("gender", e.value);
                        // forceUpdate();
                      }}
                    />
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Language</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <Dropdown
                      id={IDS.dropdown.language}
                      style={{ width: "100%" }}
                      placeholder={
                        demographics.language === null
                          ? "Irrelevant"
                          : "Language"
                      }
                      value={demographics.language}
                      options={dropdowns?.languages.map((entry) => {
                        if (entry === null) {
                          return {
                            label: "Irrelevant",
                            value: entry,
                          };
                        } else
                          return {
                            label: entry,
                            value: entry,
                          };
                      })}
                      onChange={(e) => {
                        setValue("language", e.value);
                        // forceUpdate();
                      }}
                    />
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Children</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <Dropdown
                      id={IDS.demographicsPanel.switch.hasChildren}
                      style={{ width: "100%" }}
                      placeholder={"Has Children"}
                      value={
                        demographics.hasChildren === true
                          ? YES_NO.Yes
                          : demographics.hasChildren === false
                          ? YES_NO.No
                          : YES_NO.Irrelevant
                      }
                      options={Object.entries(YES_NO).map((entry) => {
                        return {
                          label: entry[0],
                          value: entry[1],
                        };
                      })}
                      onChange={(e) => {
                        setValue(
                          "hasChildren",
                          e.value === YES_NO.Yes
                            ? true
                            : e.value === YES_NO.No
                            ? false
                            : null
                        );
                        if (
                          e.value === YES_NO.No ||
                          e.value === YES_NO.Irrelevant
                        )
                          setValue("numberOfChildren", null);
                        else if (e.value === YES_NO.Yes)
                          setValue("numberOfChildren", 0);
                        // forceUpdate();
                      }}
                    />
                  </div>
                </div>
                {demographics.hasChildren && (
                  <div className={"p-grid"}>
                    <div className={"p-col-12 p-lg-3 p-label-col"}>
                      <div className={"label-container"}>
                        <label className={"input-label"}>
                          Number of Children
                        </label>
                      </div>
                    </div>
                    <div className={"p-col-12 p-lg-9"}>
                      <InputNumber
                        id={IDS.demographicsPanel.inputs.numberOfChildren}
                        min={0}
                        showButtons={true}
                        style={{ width: "100%" }}
                        value={demographics.numberOfChildren}
                        placeholder={"Number of Children"}
                        onChange={(e: any) =>
                          setValue("numberOfChildren", e.value)
                        }
                      />
                    </div>
                  </div>
                )}
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Home or Rent</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <Dropdown
                      id={IDS.dropdown.homeRent}
                      style={{ width: "100%" }}
                      placeholder={"Home or Rent"}
                      value={
                        demographics.homeOwner === true
                          ? HOME_OWNER_RENTER["Home Owner"]
                          : demographics.homeOwner === false
                          ? HOME_OWNER_RENTER.Renter
                          : HOME_OWNER_RENTER.Irrelevant
                      }
                      options={Object.entries(HOME_OWNER_RENTER).map(
                        (entry) => {
                          return {
                            label: entry[0],
                            value: entry[1],
                          };
                        }
                      )}
                      onChange={(e) => {
                        setValue(
                          "homeOwner",
                          e.value === HOME_OWNER_RENTER["Home Owner"]
                            ? true
                            : e.value === HOME_OWNER_RENTER.Renter
                            ? false
                            : null
                        );
                        // forceUpdate();
                      }}
                    />
                  </div>
                </div>
                <div
                  className={"p-grid"}
                  style={{ marginTop: "0.5rem", paddingRight: "0.5rem" }}
                >
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>
                        Years in residence
                      </label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <InputSwitch
                      id={IDS.demographicsPanel.switch.yearsInResidence}
                      checked={yearsInResidence}
                      onChange={(e: any) => {
                        setYearsInResidence(e.target.value);
                        if (e?.target?.value === false) {
                          setValue("yearsInResidenceFrom", null);
                          setValue("yearsInResidenceTo", null);
                        } else {
                          setValue("yearsInResidenceFrom", 0);
                          setValue("yearsInResidenceTo", 0);
                        }
                      }}
                    />
                  </div>
                </div>
                {yearsInResidence && (
                  <>
                    <div className={"p-grid"}>
                      <div className={"p-col-12 p-lg-3 p-label-col"}>
                        <div className={"label-container"}>
                          <label className={"input-label"}>
                            Years in residence from:{" "}
                          </label>
                        </div>
                      </div>
                      <div className={"p-col-12 p-lg-9"}>
                        <InputNumber
                          id={IDS.demographicsPanel.slider.yearsInResidenceFrom}
                          style={{ width: "100%" }}
                          value={demographics.yearsInResidenceFrom}
                          onChange={(e) => {
                            setValue("yearsInResidenceFrom", e.value);
                            // forceUpdate();
                          }}
                          placeholder={"Years in residence from"}
                        />
                      </div>
                    </div>
                    <div className={"p-grid"}>
                      <div className={"p-col-12 p-lg-3 p-label-col"}>
                        <div className={"label-container"}>
                          <label className={"input-label"}>
                            Years in residence to:
                          </label>
                        </div>
                      </div>
                      <div className={"p-col-12 p-lg-9"}>
                        <InputNumber
                          id={IDS.demographicsPanel.slider.yearsInResidenceTo}
                          style={{ width: "100%" }}
                          value={demographics.yearsInResidenceTo}
                          onChange={(e) => {
                            setValue("yearsInResidenceTo", e.value);
                            // forceUpdate();
                          }}
                          placeholder={"Years in residence to"}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Year</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <Dropdown
                      id={IDS.dropdown.year}
                      style={{ width: "100%" }}
                      placeholder={
                        demographics.year === null ? "Irrelevant" : "Year"
                      }
                      value={demographics.year}
                      options={dropdowns?.years.map((entry) => {
                        if (entry === null) {
                          return {
                            label: "Irrelevant",
                            value: entry,
                          };
                        } else
                          return {
                            label: entry,
                            value: entry,
                          };
                      })}
                      onChange={(e) => {
                        setValue("year", e.value);
                        // forceUpdate();
                      }}
                    />
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Make</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <Dropdown
                      id={IDS.dropdown.make}
                      style={{ width: "100%" }}
                      placeholder={
                        demographics.make === null ? "Irrelevant" : "Make"
                      }
                      value={demographics.make}
                      options={dropdowns?.makes.map((entry) => {
                        if (entry === null) {
                          return {
                            label: "Irrelevant",
                            value: entry,
                          };
                        } else
                          return {
                            label: entry,
                            value: entry,
                          };
                      })}
                      onChange={(e) => {
                        setValue("make", e.value);
                        // forceUpdate();
                      }}
                    />
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Model</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <Dropdown
                      id={IDS.dropdown.model}
                      style={{ width: "100%" }}
                      placeholder={
                        demographics.model === null ? "Irrelevant" : "Model"
                      }
                      value={demographics.model}
                      options={dropdowns?.models.map((entry) => {
                        if (entry === null) {
                          return {
                            label: "Irrelevant",
                            value: entry,
                          };
                        } else
                          return {
                            label: entry,
                            value: entry,
                          };
                      })}
                      onChange={(e) => {
                        setValue("model", e.value);
                        // forceUpdate();
                      }}
                    />
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>First Seen</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <Calendar
                      style={{ width: "100%" }}
                      showButtonBar
                      inputId={"dateInput"}
                      inputStyle={{ width: "100%" }}
                      readOnlyInput={true}
                      value={getFirstSeenDateArray()}
                      selectionMode={"range"}
                      yearNavigator={true}
                      monthNavigator={true}
                      maxDate={moment(
                        Util.moment(
                          moment().format(Util.localDateFormat),
                          Util.localDateFormat
                        )
                      ).toDate()}
                      yearRange={`2010:${moment().format("YYYY")}`}
                      onClearButtonClick={(e): any => {
                        setValue("firstSeenFrom", null);
                        setValue("firstSeenTo", null);
                      }}
                      onChange={(e): any => {
                        const values = Util._.get(e, "value", []);
                        values &&
                          values[0] &&
                          setValue(
                            "firstSeenFrom",
                            moment(values[0]).format(Util.localDateTimeFormat)
                          );

                        if (values && values[1]) {
                          setValue(
                            "firstSeenTo",
                            moment(values[1]).format(Util.localDateTimeFormat)
                          );
                        } else {
                          setValue("firstSeenTo", undefined);
                        }
                      }}
                      todayButtonClassName={"no-icon-buttons"}
                      clearButtonClassName={"no-icon-buttons"}
                    />
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Last Seen</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <Calendar
                      style={{ width: "100%" }}
                      showButtonBar
                      inputId={"dateInput"}
                      inputStyle={{ width: "100%" }}
                      readOnlyInput={true}
                      value={getLastSeenDateArray()}
                      selectionMode={"range"}
                      yearNavigator={true}
                      monthNavigator={true}
                      maxDate={moment(
                        Util.moment(
                          moment().format(Util.localDateFormat),
                          Util.localDateFormat
                        )
                      ).toDate()}
                      yearRange={`2010:${moment().format("YYYY")}`}
                      onClearButtonClick={(e): any => {
                        setValue("lastSeenFrom", null);
                        setValue("lastSeenTo", null);
                      }}
                      onChange={(e): any => {
                        const values = Util._.get(e, "value", []);
                        values &&
                          values[0] &&
                          setValue(
                            "lastSeenFrom",
                            moment(values[0]).format(Util.localDateTimeFormat)
                          );

                        if (values && values[1]) {
                          setValue(
                            "lastSeenTo",
                            moment(values[1]).format(Util.localDateTimeFormat)
                          );
                        } else {
                          setValue("lastSeenTo", undefined);
                        }
                      }}
                      todayButtonClassName={"no-icon-buttons"}
                      clearButtonClassName={"no-icon-buttons"}
                    />
                  </div>
                </div>
                <div
                  className={"p-grid"}
                  style={{ marginTop: "0.5rem", paddingRight: "0.5rem" }}
                >
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Income</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <InputSwitch
                      id={IDS.demographicsPanel.switch.income}
                      checked={income}
                      onChange={(e: any) => {
                        setIncome(e.target.value);
                        if (e?.target?.value === false) {
                          setValue("incomeFrom", null);
                          setValue("incomeTo", null);
                        } else {
                          setValue("incomeFrom", 0);
                          setValue("incomeTo", 0);
                        }
                      }}
                    />
                  </div>
                </div>
                {income && (
                  <>
                    <div className={"p-grid"}>
                      <div className={"p-col-12 p-lg-3 p-label-col"}>
                        <div className={"label-container"}>
                          <label className={"input-label"}>Income From:</label>
                        </div>
                      </div>
                      <div className={"p-col-12 p-lg-9"}>
                        <InputNumber
                          id={IDS.demographicsPanel.slider.incomeFrom}
                          style={{ width: "100%" }}
                          value={demographics.incomeFrom}
                          onChange={(e) => {
                            setValue("incomeFrom", e.value);
                            // forceUpdate();
                          }}
                          placeholder={"Income From"}
                        />
                      </div>
                    </div>
                    <div className={"p-grid"}>
                      <div className={"p-col-12 p-lg-3 p-label-col"}>
                        <div className={"label-container"}>
                          <label className={"input-label"}>Income To:</label>
                        </div>
                      </div>
                      <div className={"p-col-12 p-lg-9"}>
                        <InputNumber
                          id={IDS.demographicsPanel.slider.incomeTo}
                          style={{ width: "100%" }}
                          value={demographics.incomeTo}
                          onChange={(e) => {
                            setValue("incomeTo", e.value);
                            // forceUpdate();
                          }}
                          placeholder={"Income To"}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Education</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <Dropdown
                      id={IDS.dropdown.education}
                      style={{ width: "100%" }}
                      placeholder={
                        demographics.education === null
                          ? "Irrelevant"
                          : "Education"
                      }
                      value={demographics.education}
                      options={dropdowns?.education.map((entry) => {
                        if (entry === null) {
                          return {
                            label: "Irrelevant",
                            value: entry,
                          };
                        } else
                          return {
                            label: entry,
                            value: entry,
                          };
                      })}
                      onChange={(e) => {
                        setValue("education", e.value);
                        // forceUpdate();
                      }}
                    />
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Occupation</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <Dropdown
                      id={IDS.inputs.occupation}
                      style={{ width: "100%" }}
                      value={demographics.occupation}
                      placeholder={
                        demographics.occupation === null
                          ? "Irrelevant"
                          : "Occupation"
                      }
                      options={dropdowns?.occupation.map((entry) => {
                        if (entry === null) {
                          return {
                            label: "Irrelevant",
                            value: entry,
                          };
                        } else
                          return {
                            label: entry,
                            value: entry,
                          };
                      })}
                      onChange={(e) => {
                        setValue("occupation", e.value);
                        // forceUpdate();
                      }}
                    />
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-3 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Business Owner</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-9"}>
                    <Dropdown
                      id={IDS.demographicsPanel.switch.businessOwner}
                      style={{ width: "100%" }}
                      placeholder={"Business Owner"}
                      value={
                        demographics.businessOwner === true
                          ? YES_NO.Yes
                          : demographics.businessOwner === false
                          ? YES_NO.No
                          : YES_NO.Irrelevant
                      }
                      options={Object.entries(YES_NO).map((entry) => {
                        return {
                          label: entry[0],
                          value: entry[1],
                        };
                      })}
                      onChange={(e) => {
                        setValue(
                          "businessOwner",
                          e.value === YES_NO.Yes
                            ? true
                            : e.value === YES_NO.No
                            ? false
                            : null
                        );
                        // forceUpdate();
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </Panel>
          <div style={{ marginTop: "10px" }}>
            <Button
              onClick={onBack}
              className={"p-button-warning"}
              icon={"pi pi-arrow-left"}
              label={"Back"}
            />
            <Button
              style={{ marginRight: 5 }}
              label={"Next"}
              icon={"pi pi-save"}
              onClick={() => {
                setDemographicsData(useDemographics ? demographics : null);
                setStep((prev: any) => {
                  return {
                    ...prev,
                    step: (prev?.step as number) + 1,
                  };
                });
              }}
            />
            {(mode === MODE.EDIT) && (
              <Button
                style={{ marginRight: 15 }}
                label={"Update and Exit"}
                icon={"pi pi-save"}
                disabled={isEqual(campaignInfo, initialData)}
                onClick={() => {
                  Util.globalSpinner().show();
                  updateMyCampaign(campaignInfo?.id as number, {
                    active: campaignInfo?.active,
                    knownVisitor: campaignInfo?.knownVisitor,
                    name: campaignInfo?.name,
                    vehicleFilters: campaignInfo?.filters,
                    expirable: get(campaignInfo, "expirable"),
                    startDate: campaignInfo?.startDate
                      ? moment(campaignInfo?.startDate).format(
                          Util.localDateFormat
                        )
                      : (undefined as any),
                    endDate: campaignInfo?.endDate
                      ? moment(campaignInfo?.endDate).format(
                          Util.localDateFormat
                        )
                      : (undefined as any),
                    automaticallyDisabled: campaignInfo?.automaticallyDisabled,
                    ...(campaignInfo?.notifications &&
                      campaignInfo?.notifications?.length > 0 && {
                        rtsNotifications: transformNotificationsToDto(
                          campaignInfo?.notifications
                        ),
                      }),
                    ...(campaignInfo?.sources &&
                      campaignInfo?.sources?.length > 0 && {
                        semVendorIds: campaignInfo?.sources,
                      }),
                    demographicsFilter: useDemographics ? demographics : null,
                  })
                    .then(() => {
                      setInitialData(campaignInfo);
                      setTimeout(() => {
                        history?.push("/my-campaigns");
                      }, 1000);
                    })
                    .catch((error: any) => {
                      Util.showError(error);
                    })
                    .finally(() => {
                      Util.globalSpinner().hide();
                    });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Panel>
  );
};
export default DemographicsPanel;
