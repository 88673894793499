export enum SEARCH_OPERATIONS {
  LESS_THAN = "<",
  GREATER_THAN = ">",
  EQUAL = "=",
  LIKE = "like",
  SORT_ASC = "asc",
  SORT_DESC = "dec",
  IN = "in",
  NOT_IN = "not_in",
}

export enum PERMISSIONS {
  DEALERSHIP_ACCESS = "DEALERSHIP_ACCESS",
  DEALERSHIP_MANAGER = "DEALERSHIP_MANAGER",
  DEALERSHIP_MANAGEMENT = "DEALERSHIP_MANAGEMENT",
  ROLES_MANAGEMENT = "ROLES_MANAGEMENT",
  USERS_MANAGEMENT = "USERS_MANAGEMENT",
  SETTINGS_MANAGEMENT = "SETTINGS_MANAGEMENT",
  VIEW_EVENT_LOGS = "VIEW_EVENT_LOGS",
  RTS_ACCESS = "RTS_ACCESS",
  ALL_DEALERSHIP = "ALL_DEALERSHIP",
  USERS_VIEW = "USERS_VIEW",
  DEALERSHIP_STATUS_MANAGEMENT = "DEALERSHIP_STATUS_MANAGEMENT",
  DEALERSHIP_VIEW_REPORTS = "DEALERSHIP_VIEW_REPORTS",
  DEALERSHIP_LAUNCH_REQUEST = "DEALERSHIP_LAUNCH_REQUEST",
  DEALERSHIP_WEEKLY_REPORTS = "DEALERSHIP_WEEKLY_REPORTS",
  DEALERSHIP_UPDATE_VENDOR = "DEALERSHIP_UPDATE_VENDOR",
  DEALERSHIP_SEND_TAGS = "DEALERSHIP_SEND_TAGS",
  DEALERSHIP_SUPPORT_REQUEST = "DEALERSHIP_SUPPORT_REQUEST",
  OFFERS_ACCESS = "OFFERS_ACCESS",
  OFFERS_MANAGEMENT = "OFFERS_MANAGEMENT",
  OFFERS_CREATE = "OFFERS_CREATE",
  MY_CAMPAIGNS_ACCESS = "MY_CAMPAIGNS_ACCESS",
  MY_CAMPAIGNS_MANAGEMENT = "MY_CAMPAIGNS_MANAGEMENT",
  DEALERSHIP_OFFER_RESTRICT_IP_ADDRESS = "DEALERSHIP_OFFER_RESTRICT_IP_ADDRESS",
  TEMPLATES_ACCESS = "TEMPLATES_ACCESS",
  TEMPLATES_MANAGEMENT = "TEMPLATES_MANAGEMENT",
  TEMPLATES_CREATE = "TEMPLATES_CREATE",
  ASSETS_ACCESS = "ASSETS_ACCESS",
  ASSETS_MANAGEMENT = "ASSETS_MANAGEMENT",
  ASSETS_CREATE = "ASSETS_CREATE",
  RTS_STATISTICS = "RTS_STATISTICS",
  RTS_USER_STATISTICS = "RTS_USER_STATISTICS",
  RTS_LEADS_MANAGEMENT = "RTS_LEADS_MANAGEMENT",
  RTS_NOTIFICATIONS = "RTS_NOTIFICATIONS",
  RTS_CHAT_MANAGEMENT = "RTS_CHAT_MANAGEMENT",
  RTS_SCORING_MODEL_MANAGEMENT = "RTS_SCORING_MODEL_MANAGEMENT",
  RTS_ENTERPRISE_GROUPS_MANAGE_ALL = "RTS_ENTERPRISE_GROUPS_MANAGE_ALL",
  RTS_ENTERPRISE_GROUPS_MANAGE_OWN = "RTS_ENTERPRISE_GROUPS_MANAGE_OWN",
  RTS_GROUPS_MANAGEMENT = "RTS_GROUPS_MANAGEMENT",
  RTS_GROUP_CAMPAIGNS_MANAGEMENT = "RTS_GROUP_CAMPAIGNS_MANAGEMENT",
  RTS_PERSISTENT_CHAT = "RTS_PERSISTENT_CHAT",
  DEALERSHIP_PAUSE_OFFERS = "DEALERSHIP_PAUSE_OFFERS",
  DEALERSHIP_VENDOR_UPDATE = "DEALERSHIP_VENDOR_UPDATE",
  DEALERSHIP_RESEND_WEEKLY_REPORT = "DEALERSHIP_RESEND_WEEKLY_REPORT",
  RTS_ADD_USER = "RTS_ADD_USER",
  RTS_RESEND_INVITATION = "RTS_RESEND_INVITATION",
  DEALERSHIP_OFFER_TERMS_UPDATE = "DEALERSHIP_OFFER_TERMS_UPDATE",
  DEALERSHIP_OFFER_LIMITATION = "DEALERSHIP_OFFER_LIMITATION",
  DEALERSHIP_OFFER_EXPIRATION_UPDATE = "DEALERSHIP_OFFER_EXPIRATION_UPDATE",
  DEALERSHIP_OFFER_STATUS_UPDATE = "DEALERSHIP_OFFER_STATUS_UPDATE",
  DEALERSHIP_OFFER_ASSET_UPDATE = "DEALERSHIP_OFFER_ASSET_UPDATE",
  VIEW_REPORTS = "VIEW_REPORTS",
  SUPPORT_PORTAL_ACCESS = "SUPPORT_PORTAL_ACCESS",
  DEALERSHIP_MANAGE_GA = "DEALERSHIP_MANAGE_GA",
  MAG_EXTERNAL_REP = "MAG_EXT_REP",
  IOFFER_PORTAL = "IOFFER_PORTAL",
  RO_ADMINISTRATOR = "RO_ADMINISTRATOR",
  RO_PARTS_TECH = "RO_PARTS_TECH",
  RO_ADVISOR = "RO_ADVISOR",
}

export enum EVENT_CATEGORIES {
  ALL = "ALL",
  DEALERSHIP = "DEALERSHIP",
  OFFER = "OFFER",
  TEMPLATE = "TEMPLATE",
  ASSET = "ASSET",
  USER = "USER",
  ROLE = "ROLE",
  SETTINGS = "SETTINGS",
  RTS_NOTIFICATION = "RTS_NOTIFICATION",
  MY_CAMPAIGNS = "MY_CAMPAIGNS",
}

export enum EVENT_ACTIONS {
  ALL = "ALL",
  CREATE = "CREATE",
  MODIFY = "MODIFY",
  DELETE = "DELETE",
  ACTIVATE = "ACTIVATE",
}

export enum MODE {
  NEW = "NEW",
  EDIT = "EDIT",
  DUPLICATE = "DUPLICATE",
}

export enum OFFER_TYPES {
  "All" = "GENERAL",
  "New Vehicle" = "NEW",
  "Used Vehicle" = "USED",
  "Service Special" = "SERVICE",
}

export enum VISIBILITY_TYPES {
  "All Visitors" = "ALL",
  "New Visitors" = "NEW",
  "Returning Visitors" = "RETURNING",
}

export enum DEVICE_TYPES {
  "All Devices" = "ALL",
  "Desktop Devices" = "DESKTOP",
  "Mobile Devices" = "MOBILE",
}

export enum MARITAL_STATUS {
  // "Inferred Single" = "INFERRED",
  "Single" = "SINGLE",
  "Married" = "MARRIED",
  "Irrelevant" = "IRRELEVANT",
}

export enum YES_NO {
  "Yes" = "YES",
  "No" = "NO",
  "Irrelevant" = "IRRELEVANT",
}

export enum GENDER {
  "Male" = "M",
  "Female" = "F",
}

export enum LANGUAGE {
  "Bilingual - Native Language Primary" = "BILINGUAL",
  "Unassimilated - Native Language Only" = "UNASSIMILATED",
}

export enum HOME_OWNER_RENTER {
  "Home Owner" = "HOME_OWNER",
  "Renter" = "RENTER",
  "Irrelevant" = "IRRELEVANT",
}

export enum EDUCATION {
  "Completed College" = "COMPLETED_COLLEGE",
  "High School Graduate (Includes Equivalency)" = "HIGH_SCHOOL_GRADUATE",
  "Associate Degree" = "ASSOCIATE_DEGREE",
  "Graduate Or Professional Degree" = "GRADUATE_PROFESSIONAL_DEGREE",
  "Attended Vocational/Technical" = "ATTENDED_VOCATIONAL",
}

export enum LAYOUT_MODES {
  SLIM = "slim",
  STATIC = "static",
  OVERLAY = "overlay",
  HORIZONTAL = "horizontal",
}

export enum ENGAGEMENT_STATUS {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  DELIVERED = "DELIVERED",
  ENDED = "ENDED",
  NO_RESPONSE = "NO_RESPONSE",
  CHATBOT_ACCEPTED = "CHATBOT_ACCEPTED",
}

export enum TOP_BAR_ITEMS {
  PROFILE = "Profile",
  LOGOUT = "logout",
}

export enum COOKIES {
  AUTH = "AUTH",
  UUID = "UUID",
  REFRESH_TOKEN = "REFRESH_TOKEN",
  DEALERSHIP_CONTEXT = "DEALERSHIP_CONTEXT",
  USER_IS_NOTIFIED_THAT_HE_IS_NOT_ASSOCIATED_WITH_ANY_DEALERSHIPS = "USER_IS_NOTIFIED_THAT_HE_IS_NOT_ASSOCIATED_WITH_ANY_DEALERSHIPS",
}

export enum ENGAGEMENT_INTERACTIONS {
  OFFER_ACCEPTED = "OfferAccepted",
  OFFER_IGNORED = "OfferIgnored",
  CHAT_IGNORED = "ChatIgnored",
  CHAT_ACCEPTED = "ChatAccepted",
  CHAT_LEAD = "ChatLead",
  POPUP_ACCEPTED = "PopupAccepted",
  POPUP_IGNORED = "PopupIgnored",
  AI_CHAT_ACCEPTED = "AIChatAccepted",
  AI_CHAT_IGNORED = "AIChatIgnored",
}

export enum SettingsName {
  LEAD_FILTERING = "LEAD_FILTERING",
  ADMINISTRATOR_EMAIL = "ADMINISTRATOR_EMAIL",
  WEBSITE_VENDOR = "WEBSITE_VENDOR",
  SUPPORT_PORTAL_FAQ = "SUPPORT_PORTAL_FAQ",
  CRM_SUBDOMAIN = "CRM_SUBDOMAIN",
}

export enum ConfigurationKeys {
  currentSession = "Current Session",
  previousVisit = "Previous Visit",
  srpScroll = "SRP Scroll",
  srpTotals = "SRP Totals",
  totalTimeAway = "Total Time Away",
  vdpScroll = "VDP Scroll",
  vdpTotals = "VDP Totals",
  vdpViewCount = "VDP View Count",
  visitCount = "Visit Count",
}

export enum ConfigurationFields {
  PROPERTY = "property",
  MULTIPLAYER = "multiplier",
  LIMIT = "limit",
  THRESHOLD = "threshold",
}

export enum SupportRequestCategory {
  Dealership = "DEALERSHIP",
  Offer = "OFFER",
  Tags = "TAGS",
  Admin = "ADMIN",
}

export enum NotificationType {
  BEBACK = "BEBACK",
  CHATBOT = "CHATBOT",
  CAMPAIGN = "CAMPAIGN",
  NOTIFICATION = "NOTIFICATION",
  ENGAGEMENT_OFFER_LEAD = "ENGAGEMENT_OFFER_LEAD",
  CAMPAIGN_CHAT_DISABLED = "CAMPAIGN_CHAT_DISABLED",
  NOTIFICATION_CHAT_DISABLED = "NOTIFICATION_CHAT_DISABLED",
  TRAFFIC_MARKER_REMINDER = "TRAFFIC_MARKER_REMINDER",
  TRAFFIC_ALERT = "TRAFFIC_ALERT",
  PERSISTENT_CHAT = "PERSISTENT_CHAT",
  USER_ROLE_UPDATE = "USER_ROLE_UPDATE",
}

export enum NotificationStatus {
  SENT = "SENT",
  RECEIVED = "RECEIVED",
  IGNORED = "IGNORED",
  REVIEWED = "REVIEWED",
  SENT_OFFER = "SENT_OFFER",
  OPENED_CHAT = "OPENED_CHAT",
  NON_RESPONDED = "NON_RESPONDED",
}

export enum LEAD_CREATIVE_TYPE {
  ASSET = "ASSET",
  DYNAMIC = "DYNAMIC",
  ENGAGEMENT_CHAT = "ENGAGEMENT_CHAT",
}

export enum LEAD_TYPE {
  POP_UP = "POP_UP",
  PULL_UP = "PULL_UP",
  NATIVE = "NATIVE",
  ENGAGEMENT_CHAT = "ENGAGEMENT_CHAT",
  ENGAGEMENT_OFFER = "ENGAGEMENT_OFFER",
  ENGAGEMENT_AI_CHAT = "ENGAGEMENT_AI_CHAT",
  ENGAGEMENT_AI_CHAT_DELEGATED = "ENGAGEMENT_AI_CHAT_DELEGATED",
}

export enum CHAT_ACTIONS {
  JOINED_CHAT = "joined_chat",
  CLOSED_CHAT = "closed_chat",
}

export enum LEAD_ACTIONS {
  REVIEW = "review",
  CHAT = "chat",
}

export enum IAlertType {
  LEADS = "LEADS",
  VISITS = "VISITS",
}

export enum TrafficAlertCampaignType {
  TOTAL = "TOTAL",
  ORGANIC = "ORGANIC",
  PAID = "PAID",
}

export enum MyTrafficTabKeys {
  VISUALIZER = "visualizer",
  SEM_VENDORS = "semVendors",
  VDP_PERFORMANCE_VIEW = "vdpPerformanceView",
  CHAT_GREETING = "chatGreeting",
  CHAT_HISTORY = "chatHistory",
  STATS = "stats",
  ENG_LEADS = "engLeads",
  NOTIFICATIONS = "notifications",
  GROUPS = "groups",
  CAMPAIGNS = "campaigns",
  CAMPAIGN_REPORTS = "campaignReports",
  CONFIGURATION = "configuration",
  ACQUISITIONS = "acquisitions",
  FRAUD_GUARD = "fraudGuard",
}

export enum DealerNotificationType {
  NOTIFICATION = "NOTIFICATION",
  ACQUISITION = "ACQUISITION",
  BOTH = "BOTH",
}

export enum AcquisitionEmailCadence {
  VAL_30 = "30",
  VAL_60 = "60",
  VAL_90 = "90",
  VAL_120 = "120",
  VAL_180 = "180",
}

export enum StatsTabKeys {
  VISITS = "visits",
  PAGE_VIEWS = "pageViews",
  LEADS = "leads",
  MAKES_AND_MODELS = "makesAndModels",
  PEAK_HOURS = "peakHours",
  EVENT_MARKERS = "eventMarkers",
  ALERTS = "alerts",
  PERSISTENT_CHAT = "persistentChat",
}

export enum NOTIFICATION_METADATA_KEYS {
  alertId = "alertId",
  markerId = "markerId",
  chatHistoryId = "chatHistoryId",
}

export enum NOTIFICATION_ACTION_TEXT {
  JOIN_CHAT = "Join Chat",
  SELECT_VISITOR = "Engage Visitor",
  NAVIGATE_TO_ENG_LEADS = "View Eng Leads",
  NAVIGATE_TO_VISITS_AND_LEADS = "View Stats",
  NAVIGATE_TO_ALERTS = "View Alerts",
  NO_ACTION = "",
}

export enum CHAT_HISTORY_TYPE {
  my_traffic = "my_traffic",
  persistent = "persistent",
  all = "",
}

export enum FILE_EXPORT_STATUS {
  NOT_READY = "NOT-READY",
}

export enum STORAGE_EVENTS {
  loginEvent = "login-event",
  logoutEvent = "logout-event",
}

export enum IVisitorDetailsKeys {
  pages = "pages",
  daysAwayScore = "days_away_score",
  sessionCountScore = "session_count_score",
  sessionTimeScore = "session_time_score",
  srpScrollScore = "srp_scroll_score",
  srpTimeScore = "srp_time_score",
  timeSincePrevSessionScore = "time_since_prev_session_score",
  totalScore = "total_score",
  vdpScrollScore = "vdp_scroll_score",
  vdpTimeScore = "vdp_time_score",
  vdpViewsScore = "vdp_views_score",
}

export enum CAMPAIGN_EDIT_TYPES {
  OFFER_EDIT = "OFFER_EDIT",
  NOTIFICATION_EDIT = "NOTIFICATION_EDIT",
  GREETING_EDIT = "GREETING_EDIT",
}

export enum CAMPAIGN_TYPES {
  OFFER = "OFFER",
  GREETING = "GREETING",
  OFFER_AND_NOTIFICATION = "OFFER & NOTIFICATION",
  GREETING_AND_NOTIFICATION = "GREETING & NOTIFICATION",
}

export enum VIEW_BY_SELECTOR {
  NONE = "NONE",
  VISITS = "VISITS",
  LEADS = "LEADS",
  ENGAGEMENT_SCORE = "ENGAGEMENT SCORE",
  ZIP_CODE = "ZIP CODE",
  DAYS_IN_STOCK = "DAYS IN STOCK",
}

export enum CAMPAIGN_FILTER_TYPE {
  NEW = "NEW",
  USED = "USED",
  CERTIFIED = "CERTIFIED",
  ALL = "ALL",
}

export enum CAMPAIGNS_DIS_TYPE {
  EQUALS = "equals",
  LESS_THAN = "lessThan",
  GREATER_THAN = "greaterThan",
}

export enum PROVIDER {
  VAUTO = "VAUTO",
  HOMENET = "HOMENET",
}

export enum PerformanceReportTabKeys {
  MY_ACQUISITIONS_SERVICE = "service",
  MY_ACQUISITIONS_SALE = "sale",
  MY_ACQUISITIONS_PERFORMANCE = "performance",
}
