import React, { useEffect, useState } from "react";

import "./chats_view.css";
import { IActiveChat, IVisitorDetailsCallback } from ".";
import VisitorDrawer, {
  IVisitorDetails,
} from "../../components/visitor_details/VisitorDrawer";
import _, { orderBy } from "lodash";
import {
  getVisitorDetails,
  listChats,
  startChat,
} from "../../actions/chatActions";
import { shallowEqual, useSelector } from "react-redux";

import { ActiveChatsGrid } from "./active_grid/ActiveChatsGrid";
import { CHAT_HISTORY_TYPE } from "../../util/Enums";
import ChatModes from "../../constants/chat_modes";
import ChatWidget from "../../services/chat_widget/ChatWidget";
import ChatsViewService from "./chats_view_service";
import CloseButton from "../../components/close_button/CloseButton";
import { IStore } from "../../../index.dts";
import PersistentChatStatistics from "../../components/persistent_chat_statistics/PersistentChatStatistics";
import { ProgressSpinner } from "primereact/progressspinner";
import { Sidebar } from "primereact/sidebar";
import Util from "../../util/Util";
import { useTranslation } from "react-i18next";
import { TabPanel, TabView } from "primereact/tabview";
import ChatHistory, {
  CHAT_HISTORY_ACTIONS,
} from "../../components/my_traffic/chat_history/ChatHistory";

let pollHandle: NodeJS.Timeout;

export default function ChatsView(props: any) {
  const [chats, setChats] = useState<IActiveChat[]>([]);
  const chatWidget: ChatWidget = useSelector(
    (store: IStore) => store.myTraffic.chatWidget,
    shallowEqual
  );
  const visitorChatMap = useSelector(
    (store: IStore) => store.myTraffic.visitorChatMap,
    shallowEqual
  );
  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  const [details, setDetails] = useState<IVisitorDetails>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSidebarOpened, setIsSidebarOpened] = useState<boolean>(false);
  const service: ChatsViewService = new ChatsViewService();
  const { t }: any = useTranslation();

  /**
   * Load initial data.
   */
  useEffect(() => {
    loadChats().catch(() => Util.error(Util.defaultErrorMessage));
  }, []);

  /**
   * Start the data polling
   */
  useEffect(() => {
    pollHandle = setInterval(() => {
      loadChats().catch((error) =>
        console.error("Failed polling active chats:", error)
      );
    }, service.dataPollTimeout);

    return () => {
      if (!_.isUndefined(pollHandle)) {
        clearInterval(pollHandle);
      }
    };
    // eslint-disable-next-line
  }, []);

  function loadChats(): Promise<void> {
    return new Promise((resolve, reject) => {
      listChats()
        .then((response) => {
          setChats(orderBy(response.data, "created", "desc"));
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        })
        .finally(() => setIsLoading(false));
    });
  }

  function onHideSidebar(): void {
    setIsSidebarOpened(false);
    setDetails(undefined);
  }

  function onStartChat(chat: IActiveChat): void {
    Util.globalSpinner().show();
    startChat(chat.id)
      .then((response) => {
        const chatHistoryId = Util._.get(response, "data.id", null);
        const channelId = Util._.get(response, "data.channelId", null);
        const visitorId = Util._.get(response, "data.visitorId", null);
        const visitorExtRefId = Util._.get(
          response,
          "data.visitorExtRefId",
          null
        );
        const dealershipExtRefId = Util._.get(
          response,
          "data.dealershipExtRefId",
          null
        );

        chatWidget.start({
          channelId: channelId,
          isCallsEnabled: chat.dealership.chatMode === ChatModes.Calls,
          label: chat.dealership.name,
        });

        visitorChatMap.set(visitorId.toString(), {
          chatHistoryId: chatHistoryId,
          type: CHAT_HISTORY_TYPE.persistent,
          visitorExtRefId: visitorExtRefId,
          dealershipExtRefId: dealershipExtRefId,
        });
      })
      .catch((error) => {
        console.error(error);
        Util.showError(error);
      })
      .finally(() => {
        loadChats();
        Util.globalSpinner().hide();
      });
  }

  function onVisitorDetails(args: IVisitorDetailsCallback): void {
    setIsSidebarOpened(true);
    getVisitorDetails(args.dealershipRefId, args.visitorRefId)
      .then((result) => {
        setDetails({
          dealership: {
            id: args.dealershipId,
            refId: args.dealershipRefId,
          },
          visitor: result,
        });
      })
      .catch((error) => {
        console.error(error);
        setIsSidebarOpened(false);
        Util.error(t("visitorDetails.error"));
      });
  }

  const content = (): React.ReactElement => {
    if (!dealershipContext.rtsEnabled) {
      return <div style={{ color: "red" }}>{t("myTrafficIsDisabled")}</div>;
    }

    return (
      <TabView>
        <TabPanel header={"Chats"}>
          <div className={"p-grid"}>
            {dealershipContext.persistentChatEnabled ? (
              <div className={"p-col-12"}>
                <PersistentChatStatistics
                  dealershipId={dealershipContext.id}
                  dealershipName={dealershipContext.name}
                  persistentChatEnabled={
                    dealershipContext.persistentChatEnabled
                  }
                />
              </div>
            ) : null}
          </div>
          <div className={"card card-w-title"}>
            <div
              className={
                "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"
              }
            >
              <ActiveChatsGrid
                data={chats}
                isLoading={isLoading}
                onStartChat={onStartChat}
                onVisitorDetails={onVisitorDetails}
              />
            </div>
          </div>
          <Sidebar
            modal={true}
            position={"right"}
            onHide={onHideSidebar}
            showCloseIcon={false}
            style={{ width: 400 }}
            visible={isSidebarOpened}
          >
            {_.isUndefined(details) ? (
              <ProgressSpinner
                animationDuration={"1s"}
                className={"visitor-details-spinner"}
              />
            ) : (
              <>
                <div>
                  <h1>{t("chatsView.visitorDetails.header")}</h1>
                  <CloseButton onHide={onHideSidebar} />
                </div>
                <hr />
                <VisitorDrawer
                  dealership={details.dealership}
                  visitor={details.visitor}
                />
              </>
            )}
          </Sidebar>
        </TabPanel>

        <TabPanel header={"Chat History"}>
          <ChatHistory
            actions={[CHAT_HISTORY_ACTIONS.VIEW_HISTORY]}
            actionStyle={{ width: 105 }}
            chatMode={dealershipContext.chatMode}
            dealershipId={dealershipContext.id}
            dealershipRefId={dealershipContext.externalRefId}
            chatType={CHAT_HISTORY_TYPE.persistent}
          />
        </TabPanel>
      </TabView>
    );
  };

  return <div id="chats-view">{content()}</div>;
}
