import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  forwardIOfferData,
  iOfferLeadSubmit,
} from "../../../actions/iOfferActions";
import Spinner from "../../../components/spinner/Spinner";
import { IOfferDataResponseDto } from "../../../../index.dts";
import { AiCertificate } from "@iofferio/certificate";
import { isUndefined } from "lodash";
import Util from "../../../util/Util";
import { Button } from "@mui/material";
import moment from "moment";
import QRCode from "react-qr-code";

// enum AcquisitionSourceLabel {
//   MY_ACQUISITIONS_SERVICE = "MY_ACQUISITIONS_SERVICE",
//   MY_ACQUISITIONS_SALE = "MY_ACQUISITIONS_SALE",
// }

enum AcquisitionSourceLabel {
  MY_ACQUISITIONS_SERVICE = "MY_ACQUISITIONS_SERVICE",
  MY_ACQUISITIONS_SALE = "MY_ACQUISITIONS_SALE",
}
interface RouteParams {
  iOfferDataId: string;
  acquisitionSource: AcquisitionSourceLabel;
}
declare const window;
export default function IOfferFormNew() {
  const openInNewTab = (url) => {
    window.open(url, "_self", "noreferrer");
  };
  const params: RouteParams = useParams();
  const [pending, setPending] = useState<boolean>(false);
  const [iFrameData, setIFrameData] = useState<IOfferDataResponseDto>();
  const [data, setData] = useState<any>();
  const [event, setEvent] = useState<any>();
  const paramsRef = useRef(params);

  const getCalendarValue = (value?: string): Date | undefined => {
    if (!value) {
      return;
    }

    return moment(value).toDate();
  };

  useEffect(() => {
    setPending(true);
    if (paramsRef?.current)
      forwardIOfferData({
        iOfferExtId: paramsRef?.current?.iOfferDataId,
        acquisitionSource: paramsRef?.current?.acquisitionSource,
      })
        .then((response) => {
          setIFrameData(response?.data);
        })
        .finally(() => setPending(false));
  }, [paramsRef]);

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener("message", onMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", onMessage, false);
    }
    function onMessage(event) {
      setEvent(event);
      if (
        event.origin !==
        `https://${
          process.env.REACT_APP_ENV_VAR === "prod" ? "prod" : "dev"
        }-banner.ioffer.io`
      )
        return;
      let data = event.data;
      if (!isUndefined(data?.values)) {
        const updatedValues = {
          ...event?.data?.values,
          expDate: moment(event?.data?.values?.expDate).format(
            Util.localDateTimeFormat
          ),
          completeDate: moment(event?.data?.values?.completeDate).format(
            Util.localDateTimeFormat
          ),
        };

        iOfferLeadSubmit({
          ...updatedValues,
          initialOfferExtId: params?.iOfferDataId,
        }).then((response) => {
          // console.log("RESP: ", response);
        });
      }
      setData(data);
    }
  }, []);

  function escapeHtmlEntities(str) {
    return str
      .replace(/&/g, "%26")
      .replace(/</g, "%3C")
      .replace(/>/g, "%3E")
      .replace(/%/g, "%25")
      .replace(/\+/g, "%2B")
      .replace(/\$/g, "%24")
      .replace(/'/g, "%60")
      .replace(/{/g, "%7B")
      .replace(/}/g, "%7D")
      .replace(/\^/g, "%5E")
      .replace(/=/g, "%3D")
      .replace(/#/g, "%23");
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formatDateTime = (dateTime: any) => {
    const [datePart, timePart] = dateTime.split(" ");
    const [month, day, year] = datePart.split("/");
    const [hours, minutes, seconds] = timePart.split(":");

    const date = new Date(year, month - 1, day, hours, minutes, seconds);

    const options: any = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "America/Chicago", // CST timezone
    };

    const formattedTime = date.toLocaleTimeString("en-US", options);
    return `${datePart} ${formattedTime} CST`;
  };

  const QrCodeGenerator = ({ url }) => {
    return (
      <div style={{ padding: "padding: 16px 16px 16px 0" }}>
        <QRCode size={180} level="M" value={url} />
      </div>
    );
  };
  console.log("Util: ", Util.isDesktop());
  return (
    <div style={{ width: "100%", height: "105vh" }}>
      <Spinner visible={pending} />
      {iFrameData && !iFrameData?.certificateShown && (
        <>
          {iFrameData?.dealershipLogoObjectId ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className="uploadPictureContainerIOffer"
                style={{
                  marginTop: "15px",
                }}
              >
                <img
                  alt={"..."}
                  src={Util.getAssetUrl(iFrameData.dealershipLogoObjectId)}
                  style={{
                    maxWidth: "300px",
                    maxHeight: "300px",
                    width: "auto",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          ) : null}
          {(isUndefined(data?.values) ||
            isUndefined(data?.showCertificate) ||
            data?.showCertificate == false) && (
            <>
              <iframe
                // src="https://dev-banner.ioffer.io/?guid=90938b81-2144-ef11-862c-b923b9f6e3cf&dealerId=10058&firstName=Hitesh&lastName=patel&email=hiteh@hi.com&phone=1234567890&zipCode=90100&mileage=15000"
                src={`https://${
                  process.env.REACT_APP_ENV_VAR === "prod" ? "prod" : "dev"
                }-banner.ioffer.io/?guid=${iFrameData?.guid}&dealerId=${
                  iFrameData?.iofferDealerId
                }&firstName=${iFrameData?.firstName}&lastName=${
                  iFrameData?.lastName
                }&email=${escapeHtmlEntities(iFrameData?.email)}&phone=${
                  iFrameData?.phone
                }&zipCode=${iFrameData?.zipCode}&mileage=`}
                width={"100%"}
                height={"100%"}
              ></iframe>
            </>
          )}
          {data?.values && data?.showCertificate && (
            <>
              <div
                style={{
                  margin: "10px auto",
                  maxWidth: 900,
                  border: "2px solid green",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="next-steps-div">
                  <u>Your</u> Next Steps
                </div>
                <div className="vehicle-info-and-offer">
                  <div className="vehicle-info-div">
                    <div className="vehicle-description-div">
                      {/* <p style={{ fontWeight: "bold" }}>Audi A5</p> */}
                      <p>{data?.values?.vehicleDescription}</p>
                      <p>
                        <b>VIN:</b> {data?.values?.vin}
                      </p>
                    </div>
                    <div className="vehicle-condition-div">
                      <p style={{ fontWeight: "bold" }}>Condition</p>
                      <p>
                        Exterior: {data?.values?.conditions?.ConditionsExterior}
                      </p>
                      <p>
                        Interior: {data?.values?.conditions?.ConditionsInterior}
                      </p>
                      <p>
                        Mechanical:{" "}
                        {data?.values?.conditions?.ConditionsMechanical}
                      </p>
                      <p>
                        Drivable:{" "}
                        {data?.values?.conditions?.ConditionsIsDriveable}
                      </p>
                    </div>
                  </div>
                  <div className="vertical-line"></div>
                  <div className="vehicle-offer-div">
                    <p>Instant Cash Offer</p>
                    <p style={{ fontSize: "2.3rem", fontWeight: "bolder" }}>
                      {formatter.format(data?.values?.myOfferPrice)}
                    </p>
                    <p>Reference ID:</p>
                    <p>
                      Expires{" "}
                      {moment(data?.values?.expDate).format(Util.usDateFormat)}
                    </p>
                  </div>
                </div>
                <div className="offer-steps-div">
                  <div className="steps-div">
                    <div
                      className="napoleon-buttons"
                      style={Util.isDesktop() ? { rowGap: "30px" } : {}}
                    >
                      <div className="green-rect-completed"></div>
                      <div className="vertical-button-line"></div>
                      <div className="green-rect"></div>
                      <div className="vertical-button-line"></div>
                      <div className="green-rect"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="steps-div-middle">
                    <div className="middle-div-text">
                      <p>
                        <b>Initial Cash Offer Complete</b>
                      </p>
                      <p>
                        {formatDateTime(
                          moment(data?.values?.completeDate).format(
                            Util.usDateTimeFormat
                          )
                        )}
                      </p>
                    </div>
                    {Util.isDesktop() ? (
                      <div className="middle-div-text">
                        <p>Complete Remote Inspection (Scan from Smartphone)</p>
                        <QrCodeGenerator url={iFrameData?.clickInsUrl} />
                      </div>
                    ) : (
                      <div className="middle-div-text">
                        <p>Complete Remote Inspection</p>
                        <Button
                          className="start-inspection-btn"
                          style={{
                            fontSize: "1.2rem",
                            color: "white",
                            background: "#7fbd40",
                            fontFamily: "Arial, sans-serif",
                            textTransform: "none",
                          }}
                          onClick={() => openInNewTab(iFrameData?.clickInsUrl)}
                        >
                          Start Inspection
                        </Button>
                      </div>
                    )}

                    <div className="middle-div-text">
                      <p>Arrange final steps with</p>
                      <p>{iFrameData?.dealershipName}</p>
                      <p>and Get Paid</p>
                    </div>
                  </div>
                  <div className="steps-div">
                    {/* <img
                      style={{
                        width: 60,
                        height: 60,
                        position: "absolute",
                        bottom: 10,
                        right: 20,
                      }}
                      src={"assets/images/AutoAcquireAI.png"}
                      alt={"Auto Acquire AI"}
                    /> */}
                  </div>
                </div>
                <div className="dealership-info-div">
                  <p>{iFrameData?.dealershipName}</p>
                  <p>{iFrameData?.dealershipWebsite}</p>
                  <p>{iFrameData?.dealershipPhone}</p>
                </div>
              </div>
            </>
          )}
          <div></div>
        </>
      )}
      {iFrameData && iFrameData?.certificateShown == true && (
        <>
          {iFrameData?.dealershipLogoObjectId ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className="uploadPictureContainerIOffer"
                style={{
                  marginTop: "15px",
                }}
              >
                <img
                  alt={"..."}
                  src={Util.getAssetUrl(iFrameData.dealershipLogoObjectId)}
                  style={{
                    maxWidth: "300px",
                    maxHeight: "300px",
                    width: "auto",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          ) : null}
          <div
            style={{
              margin: "10px auto",
              maxWidth: 900,
              border: "2px solid green",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="next-steps-div">
              <u>Your</u> Next Steps
            </div>
            <div className="vehicle-info-and-offer">
              <div className="vehicle-info-div">
                <div className="vehicle-description-div">
                  {/* <p style={{ fontWeight: "bold" }}>Audi A5</p> */}
                  <p>{iFrameData?.vehicleDescription}</p>
                  <p>
                    <b>VIN:</b> {iFrameData?.vin}
                  </p>
                </div>
                <div className="vehicle-condition-div">
                  <p style={{ fontWeight: "bold" }}>Condition</p>
                  <p>Exterior: {iFrameData?.conditions?.ConditionsExterior}</p>
                  <p>Interior: {iFrameData?.conditions?.ConditionsInterior}</p>
                  <p>
                    Mechanical: {iFrameData?.conditions?.ConditionsMechanical}
                  </p>
                  <p>
                    Drivable: {iFrameData?.conditions?.ConditionsIsDriveable}
                  </p>
                </div>
              </div>
              <div className="vertical-line"></div>
              <div className="vehicle-offer-div">
                <p>Instant Cash Offer</p>
                <p style={{ fontSize: "2.3rem", fontWeight: "bolder" }}>
                  {formatter.format(iFrameData?.myOfferPrice)}
                </p>
                <p>Reference ID:</p>
                <p>
                  Expires{" "}
                  {moment(iFrameData?.expDate).format(Util.usDateFormat)}
                </p>
              </div>
            </div>
            <div className="offer-steps-div">
              <div className="steps-div">
                <div
                  className="napoleon-buttons"
                  style={Util.isDesktop() ? { rowGap: "30px" } : {}}
                >
                  <div className="green-rect-completed"></div>
                  <div className="vertical-button-line"></div>
                  <div
                    className={
                      iFrameData?.inspectionFinished
                        ? "green-rect-completed"
                        : "green-rect"
                    }
                  ></div>
                  <div className="vertical-button-line"></div>
                  <div className="green-rect"></div>
                  <div></div>
                </div>
              </div>
              <div className="steps-div-middle">
                <div
                  className="middle-div-text middle-div-text-completed"
                  style={Util.isDesktop() ? { height: "135px" } : {}}
                >
                  <p>
                    <b>Initial Cash Offer Complete</b>
                  </p>
                  <p>
                    {formatDateTime(
                      moment(iFrameData?.completeDate).format(
                        Util.usDateTimeFormat
                      )
                    )}
                  </p>
                </div>
                <div
                  className="middle-div-text middle-div-text-completed"
                  style={
                    Util.isDesktop()
                      ? { height: "135px", paddingLeft: 0 }
                      : { paddingLeft: 0 }
                  }
                >
                  {Util.isDesktop() ? (
                    <div className="middle-div-text">
                      {iFrameData?.inspectionFinished ? (
                        <p>Remote Inspection Completed</p>
                      ) : (
                        <p>Complete Remote Inspection (Scan from Smartphone)</p>
                      )}
                      {iFrameData?.inspectionFinished ? null : (
                        <QrCodeGenerator url={iFrameData?.clickInsUrl} />
                      )}
                    </div>
                  ) : (
                    <div className="middle-div-text">
                      {iFrameData?.inspectionFinished ? (
                        <p>Remote Inspection Completed</p>
                      ) : (
                        <p>Complete Remote Inspection</p>
                      )}
                      {iFrameData?.inspectionFinished ? null : (
                        <Button
                          className="start-inspection-btn"
                          style={{
                            fontSize: "1.2rem",
                            color: "white",
                            background: "#7fbd40",
                            fontFamily: "Arial, sans-serif",
                            textTransform: "none",
                          }}
                          onClick={() => openInNewTab(iFrameData?.clickInsUrl)}
                        >
                          Start Inspection
                        </Button>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className="middle-div-text middle-div-text-completed"
                  style={Util.isDesktop() ? { height: "135px" } : {}}
                >
                  <p>Arrange final steps with</p>
                  <p>{iFrameData?.dealershipName}</p>
                  <p>and Get Paid</p>
                </div>
              </div>
              <div className="steps-div">
                {/* <img
                  style={{
                    width: 60,
                    height: 60,
                    position: "absolute",
                    bottom: 10,
                    right: 20,
                  }}
                  src={"assets/images/AutoAcquireAI.png"}
                  alt={"Auto Acquire AI"}
                /> */}
              </div>
            </div>
            <div className="dealership-info-div">
              <p>{iFrameData?.dealershipName}</p>
              <p>{iFrameData?.dealershipWebsite}</p>
              <p>{iFrameData?.dealershipPhone}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
