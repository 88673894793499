import React, { useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import Util from "../../util/Util";
import { PERMISSIONS } from "../../util/Enums";
import VehicleExchangeDealershipProfile from "./VehicleExchangeDealershipProfile";
import AutoAcquire from "../wish_list/AutoAcquire";

interface IProps {}

const AAServiceAndSales = (props: IProps) => {
  const [dealer] = useState(Util._.get(props, "location.state.dealership"));

  const renderer = (): React.ReactElement => {
    return (
      <div id={"dealerships-and-users"}>
        {Util.hasAnyAuthority(PERMISSIONS.DEALERSHIP_MANAGEMENT) ? (
          <div style={{ padding: 0 }} className="card">
            <TabView renderActiveOnly={true} className="tabview-custom">
              <TabPanel header={"myAcquisitions Service"}>
                <VehicleExchangeDealershipProfile dealer={dealer} />
              </TabPanel>
              <TabPanel header={"myAcquisitions Sale"}>
                <AutoAcquire dealer={dealer} />
              </TabPanel>
            </TabView>
          </div>
        ) : null}
      </div>
    );
  };

  return renderer();
};

export default AAServiceAndSales;
