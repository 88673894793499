import React, { useEffect, useRef, useState } from "react";
import "./templates.css";
import {
  IAsset,
  ISelectValue,
  IStore,
  ITemplate,
  ITemplateDto,
  SingeCtaButton,
} from "../../../index.dts";
import { getAsset, getAssetList } from "../../actions/assetActions";
import Util from "../../util/Util";
import { shallowEqual, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import parse from "html-react-parser";
import {
  createTemplate,
  getOfferMultiphaseTemplate,
  getOfferTemplate,
  getTemplate,
  updateTemplate,
} from "../../actions/templateActions";
import { useHistory } from "react-router-dom";
import deepClone from "deep-clone";
import { MODE } from "../../util/Enums";
import { confirmAlert } from "react-confirm-alert";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import AsyncSelect from "react-select/async";
import { groupBadgeStyles, groupStyles } from "../../util/styles";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-html";
import { Panel } from "primereact/panel";
import { InputSwitch } from "primereact/inputswitch";
import { IDS } from "../constants";
import { useTranslation } from "react-i18next";
import ColorPickerComponent from "../../components/general_components/ColorPickerComponent";
import { get, isNull } from "lodash";
import { fontFamilies } from "../../util/fonts";
import { ascEvents } from "../../util/asc_events";

export const TEMPLATE_BODY_CONSTANTS = {
  OFFER_NAME_PLACEHOLDER: "{{offerName}}",
  IMAGE_SOURCE_PLACEHOLDER: "{{pi_pop_up_bg}}",
  IMAGE_SCTA: "{{scta_logo}}",
  MAIN_COLOR: "{{main_color}}",
  BORDER_RADIUS_MULTI: "{{border_radius_multi}}",
  BACKGROUND_COLOR: "{{background_color}}",
  SCTA_BACKGROUND_COLOR: "{{scta_background_color}}",
  FIRST_NAME_NAME: "first_name",
  LAST_NAME_NAME: "last_name",
  EMAIL_NAME: "email",
  PHONE_NAME: "phone",
  SUBMIT_BUTTON_CLASS:
    "pi_pop_up_submit_button pi_pop_up_submit_button-generated-by-offer",
  DIV_CONTAINER_CLASS:
    "pi_pop_up_main_container pi_pop_up_main_container-generated-by-offer",
};

// noinspection HtmlRequiredAltAttribute
const REGEXPS = {
  IMAGE: `<img[^>]+src="${TEMPLATE_BODY_CONSTANTS.IMAGE_SOURCE_PLACEHOLDER}"`,
  EMAIL_NAME: `<input[^>]+name="${TEMPLATE_BODY_CONSTANTS.EMAIL_NAME}"`,
  FIRST_NAME: `<input[^>]+name="${TEMPLATE_BODY_CONSTANTS.FIRST_NAME_NAME}"`,
  LAST_NAME: `<input[^>]+name="${TEMPLATE_BODY_CONSTANTS.LAST_NAME_NAME}"`,
  PHONE_NAME: `<input[^>]+name="${TEMPLATE_BODY_CONSTANTS.PHONE_NAME}"`,
  SUBMIT_BUTTON_CLASS: `<button[^>]+class="${TEMPLATE_BODY_CONSTANTS.SUBMIT_BUTTON_CLASS}"`,
  DIV_CONTAINER: `<div[^>]+aria-label="${TEMPLATE_BODY_CONSTANTS.OFFER_NAME_PLACEHOLDER}"`,
};

const WARNINGS: {
  TEMPLATE_NAME: string;
  TEMPLATE_BODY: string[];
  TEMPLATE_STYLE: string;
  TEMPLATE_DEALERSHIP: string;
  SCTA_ASSET: string;
  SCTA_BUTTONS: string;
  SCTA_BTN_TEXT: string[];
  SCTA_LINK_TEXT: string[];
} = {
  TEMPLATE_NAME: "",
  TEMPLATE_BODY: [],
  TEMPLATE_STYLE: "",
  TEMPLATE_DEALERSHIP: "",
  SCTA_ASSET: "",
  SCTA_BUTTONS: "",
  SCTA_BTN_TEXT: [""],
  SCTA_LINK_TEXT: [""],
};
enum MultiphaseTheme {
  MULTIPHASE_ROUNDED_EDGE = "MULTIPHASE_ROUNDED_EDGE",
  MULTIPHASE_SHARP_EDGE = "MULTIPHASE_SHARP_EDGE",
}

export function TemplatesForm(props: any) {
  const { t }: any = useTranslation();
  const history: any = useHistory();
  const unblockHandle = useRef<any>();
  const forceUpdate = useForceUpdate();

  const [mode, setMode] = useState<MODE>(MODE.NEW);
  const [assetList, setAssetList] = useState<IAsset[]>([]);

  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );
  const [themeValue, setThemeValue] = useState<MultiphaseTheme>(
    MultiphaseTheme.MULTIPHASE_ROUNDED_EDGE
  );
  const [mainColor, setMainColor] = useState<string>("#2f99c0");
  const [backgroundColor, setBackgroundColor] = useState<string>("#fff");
  const [multiText, setMultiText] = useState<string>(
    "I agree to receive promotional text messages on my phone!"
  );
  const [offerBody, setOfferBody] = useState<string>("");
  const [offerStyle, setOfferStyle] = useState<string>("");
  const [sctaBackgroundColor, setSctaBackgroundColor] =
    useState<string>("#fff");
  const [sctaButtons, setSctaButtons] = useState<SingeCtaButton[]>([
    {
      color: "#8ed973",
      text: "Calculate Payment",
      href: "#",
      fontColor: "white",
      fontFamily: "Microsoft Sans Serif",
      singleCtaAscEvent: "payment_calculator",
    },
    {
      color: "#215f9a",
      text: "Reveal Vehicle Savings",
      href: "#",
      fontColor: "white",
      fontFamily: "Microsoft Sans Serif",
      singleCtaAscEvent: "reveal_savings",
    },
    {
      color: "#a02b93",
      text: "Value my Trade-In",
      href: "#",
      fontColor: "white",
      fontFamily: "Microsoft Sans Serif",
      singleCtaAscEvent: "asc_retail_process",
    },
  ]);

  const [template, setTemplate] = useState<ITemplateDto>({
    name: "",
    body: "",
    style: "",
    assetId: null,
    assetObjectId: "",
    active: true,
    dealershipId: dealershipContext.id,
    dealershipName: dealershipContext.name,
    multiphaseEnabled: false,
    multiphaseTheme: themeValue,
    multiphaseText: multiText,
    multiphaseMainColor: mainColor,
    multiphaseBackgroundColor: backgroundColor,
    singleCtaBackgroundColor: sctaBackgroundColor,
    singleCtaEnabled: false,
    singleCtaAsset: null,
    buttons: [],
  });
  const [tempTemplate, setTempTemplate] = useState<ITemplateDto>(
    deepClone(template)
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    const newSctaTemplate = setSctaButtonsInTemplate(offerBody);
    setOfferBody(newSctaTemplate);
  }, [sctaButtons]);

  const setValue = (field: string, value: any) => {
    const type = typeof value;

    switch (type) {
      case "string":
        // @ts-ignore
        template[field] = value.trim();
        break;
      default:
        // @ts-ignore
        template[field] = value;
    }
    forceUpdate();
  };

  useEffect(() => {
    if (template.dealershipId !== dealershipContext.id && mode === MODE.NEW) {
      setValue("asset", undefined);
      setValue("dealershipId", dealershipContext.id);
      setValue("dealershipName", dealershipContext.name);
    }
    // eslint-disable-next-line
  }, [dealershipContext]);

  useEffect(() => {
    onMount(props.location.state);

    return () => {
      resetWarnings();
      history.block(() => {
        return true;
      });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    unblockHandle.current = history.block((targetLocation: any) => {
      if (isSaving) {
        return true; // Allow navigation if saving
      }
      return onLeave(targetLocation);
    });
    return () => {
      unblockHandle.current.current && unblockHandle.current.current();
    };
    // eslint-disable-next-line
  }, [history.location, template, tempTemplate, isSaving]);

  useEffect(() => {
    if (template.dealershipId) {
      Util.globalSpinner().show();
      getAssetList(template.dealershipId)
        .then((response) => {
          setAssetList(
            response.data.data.map((item: IAsset) => {
              return {
                id: item.id,
                objectId: item.objectId,
                key: `(${item.id}) ${item.objectId}`,
              };
            })
          );
        })
        .catch((error) => {
          Util.showError(error);
        })
        .finally(() => {
          Util.globalSpinner().hide();
        });
    }
    // eslint-disable-next-line
  }, [template.dealershipId]);

  const onMount = (state: { mode: MODE; template?: ITemplate } | null) => {
    const mode = Util._.get(state, "mode", MODE.NEW);
    const templateId = Util._.get(state, "template.id", 0);

    setMode(mode);
    getData(mode, templateId);
  };

  const resetWarnings = (): void => {
    WARNINGS.TEMPLATE_NAME = "";
    WARNINGS.TEMPLATE_BODY = [];
    WARNINGS.TEMPLATE_STYLE = "";
    WARNINGS.TEMPLATE_DEALERSHIP = "";
    WARNINGS.SCTA_ASSET = "";
    WARNINGS.SCTA_BUTTONS = "";
    WARNINGS.SCTA_BTN_TEXT = [""];
    WARNINGS.SCTA_LINK_TEXT = [""];
  };

  const sctaButtonsTemplate = `<div class="scta-buttons-div" style="display: flex;justify-content: space-between;padding: 0;margin: 0;width:100%;column-gap:5px;">
        ${
          sctaButtons && sctaButtons?.length > 0
            ? sctaButtons
                ?.map((button) => {
                  return `<a href="${button?.href}" 
                  style="width:100%;"
                  target="_blank" >
                    <button class="scta-button" style="padding: 10px;
                      font-size: 16px;
                      font-weight: bold;
                      text-align: center;
                      border: none;
                      cursor: pointer;
                      color: ${button?.fontColor};
                      margin: 0;
                      background-color: ${button?.color};
                      width:100%;
                      font-family: ${button?.fontFamily};
                  ">${button?.text}</button>
                </a>`;
                })
                .join("")
            : ``
        }
        </div>`;

  const onLeave = (targetLocation: any): boolean => {
    if (!Util._.isEqual(template, tempTemplate)) {
      confirmAlert({
        title: "There are unsaved changes!",
        message: "Discard local changes, or save to continue!",
        buttons: [
          {
            label: "Save",
            className: "confirm-save-btn",
            onClick: () => {
              onSave(targetLocation);
            },
          },
          {
            label: "Discard",
            onClick: () => {
              setTempTemplate(template);
              setTimeout(() => {
                history.push(targetLocation);
              }, 0);
            },
            className: "confirm-discard-btn",
          },
          {
            label: "Cancel",
            className: "confirm-cancel-btn",
            onClick: () => {},
          },
        ],
      });
      return false;
    }
    return true;
  };

  const setSctaButtonsInTemplate = (bodyString) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = bodyString;
    const sctaDiv = tempDiv.querySelector("#scta-div");
    if (sctaDiv) {
      sctaDiv.innerHTML = sctaButtonsTemplate;
    }

    return tempDiv.innerHTML;
  };

  const getData = (mode: MODE, templateId?: number) =>
    new Promise((resolve, reject) => {
      Util.globalSpinner().show();
      if (mode === MODE.NEW) {
        getOfferTemplate()
          .then((response) => {
            setValue("body", Util._.get(response.data, "body", ""));
            setValue("style", Util._.get(response.data, "style", ""));
            setOfferBody(response?.data?.body);
            setOfferStyle(response?.data?.style);
            setThemeValue(MultiphaseTheme.MULTIPHASE_ROUNDED_EDGE);
            setMainColor("#2fc072");
            setSctaBackgroundColor("#fff");
            setBackgroundColor("#fff");

            resolve(response);
          })
          .catch((error) => {
            Util.showError(error);

            reject(error);
          })
          .finally(() => {
            Util.globalSpinner().hide();
          });
      } else if (templateId) {
        getTemplate(templateId)
          .then((response) => {
            if (response.data.assetId) {
              getAsset(response.data.assetId)
                .then((assetResponse) => {
                  response.data.asset = assetResponse.data;
                })
                .catch((error) => {
                  Util.showError(error);
                })
                .finally(() => {
                  const newSctaTemplate = setSctaButtonsInTemplate(
                    response?.data?.body
                  );
                  setTemplate(response.data);
                  setOfferBody(
                    response?.data?.singleCtaEnabled
                      ? newSctaTemplate
                      : response?.data?.body
                  );
                  setOfferStyle(response?.data?.style);
                  setThemeValue(
                    response?.data?.multiphaseTheme ??
                      MultiphaseTheme.MULTIPHASE_ROUNDED_EDGE
                  );
                  setMainColor(
                    response?.data?.multiphaseMainColor ?? "#2fc072"
                  );
                  setSctaBackgroundColor(
                    response?.data?.singleCtaBackgroundColor ?? "#fff"
                  );
                  setBackgroundColor(
                    response?.data?.multiphaseBackgroundColor ?? "#fff"
                  );
                  setSctaButtons(response?.data?.buttons ?? []);
                  setMultiText(
                    response?.data?.multiphaseText ??
                      "I agree to receive promotional text messages on my phone!"
                  );
                  setTempTemplate(deepClone(response.data));
                  Util.globalSpinner().hide();
                });
            } else {
              const newSctaTemplate = setSctaButtonsInTemplate(
                response?.data?.body
              );
              setTemplate(response.data);
              setOfferBody(
                response?.data?.singleCtaEnabled
                  ? newSctaTemplate
                  : response?.data?.body
              );
              setOfferStyle(response?.data?.style);
              setThemeValue(
                response?.data?.multiphaseTheme ??
                  MultiphaseTheme.MULTIPHASE_ROUNDED_EDGE
              );
              setMainColor(response?.data?.multiphaseMainColor ?? "#2fc072");
              setSctaBackgroundColor(
                response?.data?.singleCtaBackgroundColor ?? "#fff"
              );
              setBackgroundColor(
                response?.data?.multiphaseBackgroundColor ?? "#fff"
              );
              setSctaButtons(response?.data?.buttons ?? []);
              setMultiText(
                response?.data?.multiphaseText ??
                  "I agree to receive promotional text messages on my phone!"
              );
              setTempTemplate(deepClone(response.data));
              Util.globalSpinner().hide();
            }
          })
          .catch((error) => {
            Util.showError(error);
            Util.globalSpinner().hide();
          });
      }
    });

  const validate = (): boolean => {
    resetWarnings();

    if (template?.singleCtaEnabled == true) {
      if (isNull(template?.singleCtaAsset)) {
        WARNINGS.SCTA_ASSET =
          "Single CTA Asset cannot be empty, if Single CTA is enabled";
      }

      if (sctaButtons?.length == 0) {
        WARNINGS.SCTA_BUTTONS =
          "You need to add at least one button, if Single CTA is enabled";
      }

      if (sctaButtons?.length > 0) {
        sctaButtons?.map((button, index) => {
          const updatedButtonsText = [...WARNINGS.SCTA_BTN_TEXT];
          const updatedButtonsLink = [...WARNINGS.SCTA_LINK_TEXT];
          //for button text
          if (Util.isEmpty(button?.text)) {
            updatedButtonsText.splice(index, 0, "Button text cannot be empty");
          } else {
            updatedButtonsText.splice(index, 0, "");
          }
          //for button link
          if (Util.isEmpty(button?.href)) {
            updatedButtonsLink.splice(index, 0, "Button link cannot be empty");
          } else {
            updatedButtonsLink.splice(index, 0, "");
          }
          WARNINGS.SCTA_BTN_TEXT = updatedButtonsText;
          WARNINGS.SCTA_LINK_TEXT = updatedButtonsLink;
        });
      }
    }

    if (Util.isEmpty(template.name)) {
      WARNINGS.TEMPLATE_NAME = "Template name cannot be empty";
    }

    if (Util.isEmpty(template.body)) {
      WARNINGS.TEMPLATE_BODY.push("Template body cannot be empty");
    }

    if (Util.isEmpty(template.style)) {
      WARNINGS.TEMPLATE_STYLE = "Template style cannot be empty";
    }

    if (!template.dealershipId) {
      WARNINGS.TEMPLATE_DEALERSHIP = "Invalid or empty template dealership";
    }

    if (!new RegExp(REGEXPS.IMAGE, "g").test(template.body)) {
      WARNINGS.TEMPLATE_BODY.push(
        `Template body does not contain "img" with src placeholder "${TEMPLATE_BODY_CONSTANTS.IMAGE_SOURCE_PLACEHOLDER}"`
      );
    }

    if (!new RegExp(REGEXPS.FIRST_NAME, "g").test(template.body)) {
      WARNINGS.TEMPLATE_BODY.push(
        `Template body does not contain "input" with name "${TEMPLATE_BODY_CONSTANTS.FIRST_NAME_NAME}"`
      );
    }

    if (!new RegExp(REGEXPS.LAST_NAME, "g").test(template.body)) {
      WARNINGS.TEMPLATE_BODY.push(
        `Template body does not contain "input" with name "${TEMPLATE_BODY_CONSTANTS.LAST_NAME_NAME}"`
      );
    }

    if (!new RegExp(REGEXPS.EMAIL_NAME, "g").test(template.body)) {
      WARNINGS.TEMPLATE_BODY.push(
        `Template body does not contain "input" with name "${TEMPLATE_BODY_CONSTANTS.EMAIL_NAME}"`
      );
    }

    if (!new RegExp(REGEXPS.PHONE_NAME, "g").test(template.body)) {
      WARNINGS.TEMPLATE_BODY.push(
        `Template body does not contain "input" with name "${TEMPLATE_BODY_CONSTANTS.PHONE_NAME}"`
      );
    }

    if (!new RegExp(REGEXPS.SUBMIT_BUTTON_CLASS, "g").test(template.body)) {
      WARNINGS.TEMPLATE_BODY.push(
        `Template body does not contain "button" with class "${TEMPLATE_BODY_CONSTANTS.SUBMIT_BUTTON_CLASS}"`
      );
    }

    return (
      !WARNINGS.TEMPLATE_NAME.trim() &&
      !WARNINGS.SCTA_ASSET.trim() &&
      !WARNINGS.SCTA_BUTTONS.trim() &&
      WARNINGS.SCTA_BTN_TEXT.every((element) => element === "") === true &&
      WARNINGS.SCTA_LINK_TEXT.every((element) => element === "") === true &&
      !WARNINGS.TEMPLATE_STYLE.trim() &&
      !WARNINGS.TEMPLATE_DEALERSHIP.trim() &&
      WARNINGS.TEMPLATE_BODY.length === 0
    );
  };

  const onSave = (targetLocation?: any) =>
    new Promise((resolve, reject) => {
      setIsSaving(true);
      if (validate()) {
        Util.globalSpinner().show();
        if (mode === MODE.NEW) {
          createTemplate({
            active: true,
            name: template.name.trim(),
            body: offerBody.trim(),
            style: offerStyle.trim(),
            assetId: template.assetId,
            assetObjectId: template.assetObjectId,
            dealershipId: template.dealershipId,
            dealershipName: tempTemplate.dealershipName,
            multiphaseEnabled: template.multiphaseEnabled,
            multiphaseTheme: themeValue,
            multiphaseText: multiText,
            multiphaseMainColor: mainColor,
            multiphaseBackgroundColor: backgroundColor,
            singleCtaBackgroundColor: sctaBackgroundColor,
            singleCtaEnabled: template.singleCtaEnabled,
            singleCtaAsset: template.singleCtaAsset,
            buttons: sctaButtons,
          })
            .then((response) => {
              resolve(response);
              setTempTemplate(deepClone(template));
              setIsSaving(false);

              setTimeout(() => {
                Util.success("Template created successfully.");

                history.push(targetLocation || Util.PATH_NAMES.TEMPLATES);
              }, 0);
            })
            .catch((error) => {
              reject(error);
              Util.showError(error);
              setIsSaving(false);
            })
            .finally(() => {
              Util.globalSpinner().hide();
            });
        } else {
          updateTemplate(template.id as number, {
            name: template.name.trim(),
            body: offerBody.trim(),
            style: offerStyle.trim(),
            assetId: template.assetId,
            assetObjectId: template.assetObjectId,
            active: template.active,
            dealershipId: template.dealershipId,
            dealershipName: tempTemplate.dealershipName,
            multiphaseEnabled: template.multiphaseEnabled,
            multiphaseTheme: themeValue,
            multiphaseText: multiText,
            multiphaseMainColor: mainColor,
            multiphaseBackgroundColor: backgroundColor,
            singleCtaBackgroundColor: sctaBackgroundColor,
            singleCtaEnabled: template.singleCtaEnabled,
            singleCtaAsset: template.singleCtaAsset,
            buttons: sctaButtons,
          })
            .then((response) => {
              resolve(response);
              setTempTemplate(deepClone(template));
              setIsSaving(false);

              setTimeout(() => {
                Util.success("Template updated successfully.");
                history.push(targetLocation || Util.PATH_NAMES.TEMPLATES);
              }, 0);
            })
            .catch((error) => {
              reject(error);
              Util.showError(error);
              setIsSaving(false);
            })
            .finally(() => {
              Util.globalSpinner().hide();
            });
        }
      } else {
        reject();
        forceUpdate();
      }
    });

  const onBack = () => {
    history.push(Util.PATH_NAMES.TEMPLATES);
  };

  const assetDropdownTemplate = (asset: IAsset) => {
    return (
      <div>
        <img
          style={{ width: 50, height: 50 }}
          alt={asset.objectId}
          src={Util.getAssetUrl(asset.objectId)}
        />
        <span style={{ marginLeft: 5 }}>{asset.objectId}</span>
      </div>
    );
  };

  const onAssetChange = (value: number) => {
    if (value) {
      const index = assetList.findIndex((asset) => asset.id === value);
      setValue("assetId", assetList[index].id);
      setValue("assetObjectId", assetList[index].objectId);
    } else {
      setValue("assetId", null);
      setValue("assetObjectId", null);
    }
  };

  const onChangeSctaAsset = (props: any) => {
    if (props.value) {
      const index = assetList.findIndex(
        (singleCtaAsset) => singleCtaAsset.id === props.value
      );
      if (index > -1) {
        let sctaAsset: any = assetList[index];
        delete sctaAsset?.key;
        setValue("singleCtaAsset", sctaAsset);
      }
    } else {
      setValue("singleCtaAsset", null);
    }
  };

  const onDealershipChange = (item: ISelectValue) => {
    if (mode === MODE.NEW) {
      setValue("asset", undefined);
      setValue("dealershipId", item.value);
      setValue("dealershipName", item.label);
    }
  };

  const showPreview = (): boolean => {
    return !!template.body.trim();
  };

  const getImageSourcePlaceholder = (): string => {
    if (template && template.assetObjectId) {
      return Util.getAssetUrl(template.assetObjectId);
    }
    return "";
  };

  const getSCTAImageSourcePlaceholder = (): string => {
    if (
      template &&
      template.singleCtaAsset &&
      template.singleCtaAsset.objectId
    ) {
      return Util.getAssetUrl(template.singleCtaAsset.objectId);
    }
    return "";
  };

  const formatGroupLabel = (data: any) => {
    return (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.total}</span>
      </div>
    );
  };

  const getPreview = (): React.ReactElement | null => {
    if (!showPreview()) {
      return null;
    }

    return (
      <div id={"template-preview"}>
        {parse(
          `<div><style>${template.style}</style>${template.body
            .replace(
              TEMPLATE_BODY_CONSTANTS.IMAGE_SOURCE_PLACEHOLDER,
              `${getImageSourcePlaceholder()}`
            )
            .replace(
              TEMPLATE_BODY_CONSTANTS.IMAGE_SCTA,
              `${getSCTAImageSourcePlaceholder()}`
            )
            .replaceAll(TEMPLATE_BODY_CONSTANTS.MAIN_COLOR, `${mainColor}`)
            .replaceAll(
              TEMPLATE_BODY_CONSTANTS.BORDER_RADIUS_MULTI,
              String(themeValue) === String("MULTIPHASE_SHARP_EDGE")
                ? "0"
                : "16px"
            )
            .replaceAll(
              /SUBMIT/g,
              String(themeValue) === String("MULTIPHASE_SHARP_EDGE")
                ? "Submit"
                : "SUBMIT"
            )
            .replaceAll(
              TEMPLATE_BODY_CONSTANTS.BACKGROUND_COLOR,
              `${backgroundColor}`
            )}</div>`
        )}
      </div>
    );
  };

  const sctaFirstScreen = `
  <div role="dialog" aria-label="{{offerName}}" style="
    border: 1px solid black;
    padding: 10px;
    background: ${sctaBackgroundColor};
    width: 100% !important;
    max-width: 500px !important;
    display: block !important;
    position: unset !important;
  " >
    <div style="
      overflow: hidden;
      display: inline-block;
      position: relative;
      min-height: 345px;
      width: 100%;
      max-width: 500px;" 
    >
      <img editable="" data="" style="
        position: relative;
        left: 0;
        width: 100%;
        z-index: 100;
        max-width: 500px;
        max-height: 350px;" src="${Util.getAssetUrl(
          get(template as any, "singleCtaAsset.objectId", null)
        )}" alt="{{offerName}}. Enter your information below!">
    </div>
    <div class="scta-div" style="
      display: flex;
      align-items: end;
      min-height: 86px;
      background: ${sctaBackgroundColor}">
        <div class="scta-buttons-div" style="display: flex;justify-content: space-between;padding: 0;margin: 0;width:100%;column-gap:5px;">
        ${
          sctaButtons && sctaButtons?.length > 0
            ? sctaButtons
                ?.map((button) => {
                  return `<a href="${button?.href}" 
                  style="width:100%;"
                  target="_blank" >
                    <button class="scta-button" style="padding: 10px;
                      font-size: 16px;
                      font-weight: bold;
                      text-align: center;
                      border: none;
                      cursor: pointer;
                      color: ${button?.fontColor};
                      margin: 0;
                      background-color: ${button?.color};
                      width:100%;
                      font-family: ${button?.fontFamily};
                  ">${button?.text}</button>
                </a>`;
                })
                .join("")
            : ``
        }
        </div>
    </div>
</div>
  `;

  const multiPhaseOfferBanner = `<div
    className="pureinfluencer-popup-bottom"
    id="form-body-2"
    style="background-color: ${backgroundColor};padding: 25px;border: 1px solid black;margin-top: 15px;"
  >
    <div className="pureinfluencer-popup-nav" style="display: flex;margin-bottom: 32px;border-bottom: 1px solid #e8e8e8">
      <div
        className="pureinfluencer-popup-nav-item active"
        style="color: ${mainColor};border-bottom:2px solid ${mainColor};display: flex;justify-content: center;align-items: center;
        width: 33.333%;
        font-size: 20px;
        padding: 12px 0;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.5);
        position: relative;
        font-weight: 500;"
      >
        <span>Search Vehicle</span>
      </div>
      <div className="pureinfluencer-popup-nav-item" style="display: flex;
        justify-content: center;
        align-items: center;
        width: 33.333%;
        font-size: 20px;
        padding: 12px 0;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.5);
        position: relative;">
        <span>Vin</span>
      </div>
      <div className="pureinfluencer-popup-nav-item" style="display: flex;
        justify-content: center;
        align-items: center;
        width: 33.333%;
        font-size: 20px;
        padding: 12px 0;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.5);
        position: relative;">
        <span>Plate</span>
      </div>
    </div>
    <form action="#">
      <div className="pureinfluencer-popup-search" style="position: relative;margin-bottom: 32px;">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;"
        >
          <g opacity="0.5" clip-path="url(#clip0_2_122)">
            <path
              d="M15.8048 14.862L11.8254 10.8827C12.9098 9.5564 13.443 7.86407 13.3147 6.15571C13.1863 4.44736 12.4062 2.85369 11.1358 1.70435C9.8654 0.555014 8.20183 -0.0620646 6.48919 -0.0192434C4.77656 0.0235778 3.1459 0.723023 1.93451 1.93442C0.723114 3.14581 0.0236694 4.77647 -0.0191518 6.4891C-0.061973 8.20173 0.555106 9.86531 1.70445 11.1357C2.85379 12.4061 4.44745 13.1862 6.15581 13.3146C7.86416 13.4429 9.55649 12.9098 10.8828 11.8253L14.8621 15.8047C14.9878 15.9261 15.1562 15.9933 15.331 15.9918C15.5058 15.9903 15.673 15.9202 15.7966 15.7966C15.9202 15.6729 15.9904 15.5057 15.9919 15.3309C15.9934 15.1561 15.9262 14.9877 15.8048 14.862ZM6.66676 12C5.61193 12 4.58079 11.6872 3.70372 11.1012C2.82666 10.5151 2.14307 9.68219 1.73941 8.70765C1.33574 7.73311 1.23012 6.66076 1.43591 5.62619C1.6417 4.59163 2.14965 3.64132 2.89553 2.89544C3.64141 2.14956 4.59172 1.64161 5.62628 1.43582C6.66085 1.23003 7.7332 1.33565 8.70774 1.73932C9.68228 2.14298 10.5152 2.82657 11.1013 3.70363C11.6873 4.58069 12.0001 5.61184 12.0001 6.66667C11.9985 8.08067 11.4361 9.43631 10.4362 10.4362C9.4364 11.436 8.08076 11.9984 6.66676 12Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_2_122">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <input
          type="text"
          placeholder="Search year, Make, Model, Trim"
          name="Search"
          disabled
          style="width: 100%;
            padding-left: 52px;
            min-height: 45px;"
        />
      </div>
      <button
        className="pureinfluencer-popup-btn"
        style="background-color: ${mainColor};display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        height: 52px;
        width: 100%;
        border: none;
        outline-offset: 0;
        outline: none;
        color: #ffffff;
        cursor: pointer;
        transition: all ease 0.2s;"
        disabled
      >
        Submit
      </button>
    </form>
  </div>`;

  const sctaAssetDropdownTemplate = (singleCtaAsset: IAsset) => {
    return (
      <div>
        <img
          style={{ width: 50, height: 50 }}
          alt={singleCtaAsset.objectId}
          src={Util.getAssetUrl(singleCtaAsset.objectId)}
        />
        <span style={{ marginLeft: 5 }}>{singleCtaAsset.objectId}</span>
      </div>
    );
  };

  const onAddSctaButtons = () => {
    let tempButtons: SingeCtaButton[] = [];
    let tempBtn = {
      color: "red",
      text: "Button label",
      href: "#",
      fontColor: "white",
      fontFamily: "Times",
      singleCtaAscEvent: "",
    };
    tempButtons.push(...sctaButtons, tempBtn);
    setSctaButtons(tempButtons);
  };

  const onRemoveSctaButtons = (index) => {
    setSctaButtons((previousArr) => previousArr.filter((_, i) => i !== index));
  };

  const selectedDropdownTemplate = (option: any, props: DropdownProps) => {
    if (option) {
      return (
        <div className="font-item font-item-value">
          <div>{option.name}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const optionTemplate = (option: any) => {
    return (
      <div className="font-item">
        <div>{option.name}</div>
      </div>
    );
  };

  return (
    <div id={"templates-form-view"}>
      <div className="card card-w-title datatable-demo">
        <Panel
          header={"Preview"}
          style={{
            width: "100%",
            marginBottom: 10,
            backgroundColor: "rgba(0,0,0,0.3)",
          }}
        >
          {getPreview()}
        </Panel>

        <Panel header={"Settings"} style={{ width: "100%", marginBottom: 10 }}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Template Name</label>
                <span className={"red"}>*</span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <InputText
                name={"name"}
                style={{ width: "100%" }}
                defaultValue={template.name}
                placeholder={"Template Name"}
                onChange={(e: any) => setValue("name", e.target.value)}
              />
              <label className={"warning-text"}>{WARNINGS.TEMPLATE_NAME}</label>
            </div>
          </div>

          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Dealership</label>
                <span className={"red"}>*</span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <AsyncSelect
                menuPosition={"fixed"}
                cacheOptions
                defaultOptions
                value={{
                  value: template.dealershipId,
                  label: template.dealershipName,
                }}
                isDisabled={mode === MODE.EDIT}
                loadOptions={Util.promiseMyDealerships}
                placeholder={"Select Dealership"}
                formatGroupLabel={formatGroupLabel}
                onChange={(data: any) => onDealershipChange(data)}
              />
              <label className={"warning-text"}>
                {WARNINGS.TEMPLATE_DEALERSHIP}
              </label>
            </div>
          </div>

          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-3 p-label-col"}>
              <div className={"label-container"}>
                <label className={"input-label"}>Asset</label>
              </div>
            </div>
            <div className={"p-col-12 p-lg-9"}>
              <Dropdown
                showClear={true}
                disabled={!template.dealershipId}
                style={{ width: "100%", marginBottom: 10 }}
                value={template.assetId}
                optionValue={"id"}
                optionLabel={"key"}
                options={assetList}
                onChange={(e) => onAssetChange(e.value)}
                itemTemplate={assetDropdownTemplate}
              />
            </div>
          </div>
        </Panel>
        <Panel
          header={
            <div>
              <label className={"input-label"}>Template Body</label>
              <span className={"red"}>*</span>
            </div>
          }
          style={{ width: "100%", marginBottom: 10 }}
        >
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-12"}>
              <AceEditor
                mode="html"
                onChange={(code) => {
                  setValue("body", code);
                  setOfferBody(code);
                  forceUpdate();
                }}
                style={{ width: "100%" }}
                value={offerBody}
                name="TEMPLATE_BODY"
                editorProps={{ $blockScrolling: true }}
              />

              {WARNINGS.TEMPLATE_BODY.map((item) => (
                <p className={"warning-text"}>{item}</p>
              ))}
            </div>
          </div>
        </Panel>

        <Panel
          header={
            <div>
              <label className={"input-label"}>Template Style</label>
              <span className={"red"}>*</span>
            </div>
          }
          style={{ width: "100%", marginBottom: 10 }}
        >
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-12"}>
              <AceEditor
                mode="css"
                onChange={(code) => {
                  setValue("style", code);
                  setOfferStyle(code);
                  forceUpdate();
                }}
                style={{ width: "100%", height: 430, zIndex: 0 }}
                value={offerStyle}
                name="TEMPLATE_STYLE"
                editorProps={{ $blockScrolling: true }}
              />
              <label className={"warning-text"}>
                {WARNINGS.TEMPLATE_STYLE}
              </label>
            </div>
          </div>
        </Panel>

        <Panel
          header={
            <div style={{ display: "flex" }}>
              <label className={"input-label"}>Multipart</label>
              <div style={{ marginTop: 2, marginLeft: 5 }}>
                <InputSwitch
                  id={IDS.dealershipsForm.switch.multiphaseEnabled}
                  checked={template.multiphaseEnabled}
                  onChange={(e: any) => {
                    setValue("multiphaseEnabled", e?.target?.value);
                    if (e?.target?.value !== undefined)
                      getOfferMultiphaseTemplate(
                        e?.target?.value,
                        template?.singleCtaEnabled
                      ).then((response) => {
                        const newSctaTemplate = setSctaButtonsInTemplate(
                          response?.data?.body ?? template.body
                        );
                        setOfferBody(
                          response?.data?.singleCtaEnabled
                            ? newSctaTemplate
                            : response?.data?.body ?? template.body
                        );
                        setOfferStyle(response?.data?.style ?? template.style);
                        setValue(
                          "body",
                          response?.data?.singleCtaEnabled
                            ? newSctaTemplate
                            : Util._.get(response.data, "body", "")
                        );
                        setValue(
                          "style",
                          Util._.get(response.data, "style", "")
                        );
                      });
                  }}
                />
              </div>
            </div>
          }
          style={{ width: "100%", marginBottom: 10 }}
        >
          {template.multiphaseEnabled && (
            <div className={"p-grid"}>
              <div
                className={"p-col-12 p-lg-4"}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 10,
                  padding: "1rem",
                }}
              >
                <div className={"p-grid"} style={{ alignItems: "baseline" }}>
                  <div className={"label-container"}>
                    <label
                      className={"input-label"}
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Theme
                    </label>
                  </div>
                  <div
                    className={"p-col-12 p-lg-9"}
                    style={{ marginLeft: "auto" }}
                  >
                    <Dropdown
                      value={themeValue}
                      options={[
                        {
                          label: t("multiphase_rounded_edge"),
                          value: MultiphaseTheme.MULTIPHASE_ROUNDED_EDGE,
                        },
                        {
                          label: t("multiphase_sharp_edge"),
                          value: MultiphaseTheme.MULTIPHASE_SHARP_EDGE,
                        },
                      ]}
                      style={{ width: "100%", boxShadow: "none" }}
                      onChange={(e: any) => {
                        setThemeValue(e?.target?.value);
                        if (
                          e?.target?.value ===
                          MultiphaseTheme.MULTIPHASE_ROUNDED_EDGE
                        ) {
                          setMainColor("#2fc072");
                          setBackgroundColor("#fff");
                        } else if (
                          e?.target?.value ===
                          MultiphaseTheme.MULTIPHASE_SHARP_EDGE
                        ) {
                          setMainColor("#2f99c0");
                          setBackgroundColor("#fff");
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"label-container"}>
                    <label
                      className={"input-label"}
                      style={{ fontSize: "20px", fontWeight: "bold" }}
                    >
                      Customize:
                    </label>
                  </div>
                  <div
                    className={"p-col-12 p-lg-12"}
                    style={{ border: "1px solid #cccccc" }}
                  >
                    <div className={"p-grid"}>
                      <div className={"p-col-12 p-lg-12 p-label-col"}>
                        <div className={"label-container"}>
                          <label className={"input-label"}>
                            Text messages acknowledgment
                          </label>
                        </div>
                      </div>
                      <div className={"p-col-12 p-lg-12"}>
                        <InputText
                          id={IDS.dealershipsForm.inputs.bannerTitle}
                          style={{ width: "100%" }}
                          value={multiText}
                          maxLength={256}
                          onChange={(e: any) => setMultiText(e?.target?.value)}
                        />
                      </div>
                    </div>
                    <div className={"p-grid"}>
                      <div className={"p-col-12 p-lg-4 p-label-col"}>
                        <div className={"label-container"}>
                          <label className={"input-label"}>Main Color</label>
                        </div>
                      </div>
                      <div className={"p-col-12 p-lg-8"}>
                        <ColorPickerComponent
                          color={mainColor}
                          setColor={setMainColor}
                        />
                      </div>
                    </div>
                    <div className={"p-grid"}>
                      <div className={"p-col-12 p-lg-4 p-label-col"}>
                        <div className={"label-container"}>
                          <label className={"input-label"}>
                            Background Color
                          </label>
                        </div>
                      </div>
                      <div className={"p-col-12 p-lg-8"}>
                        <ColorPickerComponent
                          color={backgroundColor}
                          setColor={setBackgroundColor}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"p-col-12 p-lg-8"}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: multiPhaseOfferBanner,
                  }}
                />
              </div>
            </div>
          )}
        </Panel>
        <Panel
          header={
            <div style={{ display: "flex" }}>
              <label className={"input-label"}>Single CTA</label>
              <div style={{ marginTop: 2, marginLeft: 5 }}>
                <InputSwitch
                  id={IDS.dealershipsForm.switch.singleCtaEnabled}
                  checked={template.singleCtaEnabled}
                  onChange={(e: any) => {
                    setValue("singleCtaEnabled", e?.target?.value);
                    if (e?.target?.value !== undefined)
                      getOfferMultiphaseTemplate(
                        template?.multiphaseEnabled,
                        e?.target?.value
                      ).then((response) => {
                        const newSctaTemplate = setSctaButtonsInTemplate(
                          response?.data?.body
                        );
                        setOfferBody(
                          response?.data?.singleCtaEnabled
                            ? newSctaTemplate
                            : response?.data?.body ?? template.body
                        );
                        setOfferStyle(response?.data?.style ?? template.style);
                        setValue(
                          "body",
                          response?.data?.singleCtaEnabled
                            ? newSctaTemplate
                            : Util._.get(response.data, "body", "")
                        );
                        setValue(
                          "style",
                          Util._.get(response.data, "style", "")
                        );
                      });
                  }}
                />
              </div>
            </div>
          }
          style={{ width: "100%", marginBottom: 10 }}
        >
          {template.singleCtaEnabled && (
            <div
              className={"p-grid"}
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 10,
                  padding: "1rem",
                  width: "50%",
                }}
              >
                <div className={"p-grid"} style={{ alignItems: "baseline" }}>
                  <div
                    className={"label-container"}
                    style={{ paddingLeft: "0.5rem" }}
                  >
                    <label
                      className={"input-label"}
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Default Asset
                    </label>
                    <span className={"red"}>*</span>
                  </div>
                  <div
                    className={"p-col-12 p-lg-12"}
                    style={{ marginLeft: "auto" }}
                  >
                    <Dropdown
                      id={IDS.dropdown.sctaAssetDropdown}
                      style={{ width: "100%", maxWidth: "300px" }}
                      disabled={!template.dealershipId}
                      placeholder={"Select Asset"}
                      optionLabel={"key"}
                      optionValue={"id"}
                      options={assetList}
                      showClear={true}
                      value={template?.singleCtaAsset?.id}
                      onChange={onChangeSctaAsset}
                      itemTemplate={sctaAssetDropdownTemplate}
                    />
                    <label className={"warning-text"}>
                      {WARNINGS.SCTA_ASSET}
                    </label>
                  </div>
                </div>
                <div
                  style={{
                    alignItems: "baseline",
                    display: "flex",
                    border: "2px solid #fdebeb",
                    borderRadius: "5px",
                  }}
                >
                  <div className={"p-col-12 p-lg-4 p-label-col"}>
                    <div className={"label-container"}>
                      <label className={"input-label"}>Background Color</label>
                    </div>
                  </div>
                  <div className={"p-col-12 p-lg-8"}>
                    <ColorPickerComponent
                      color={sctaBackgroundColor}
                      setColor={setSctaBackgroundColor}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                  }}
                >
                  <Button
                    style={{
                      marginRight: 5,
                      height: "2rem",
                      marginLeft: "auto",
                    }}
                    onClick={onAddSctaButtons}
                    tooltip="Add Button"
                    icon="pi pi-plus"
                    disabled={sctaButtons.length > 5}
                  />
                  <label className={"warning-text"}>
                    {WARNINGS.SCTA_BUTTONS}
                  </label>
                  {sctaButtons &&
                    sctaButtons?.length > 0 &&
                    sctaButtons?.map(
                      (button: SingeCtaButton, index: number) => {
                        return (
                          <div
                            style={{
                              border: "2px solid #fdebeb",
                              borderRadius: "5px",
                              display: "flex",
                              flexDirection: "column",
                              flexWrap: "wrap",
                            }}
                            key={index}
                          >
                            <div
                              style={{
                                alignItems: "baseline",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              <div
                                className={
                                  "single-cta-buttons p-col-12 p-lg-4 p-label-col"
                                }
                              >
                                <div className={"label-container"}>
                                  <label className={"input-label"}>
                                    Button Background Color
                                  </label>
                                </div>
                              </div>
                              <div className={"p-col-12 p-lg-7"}>
                                <ColorPickerComponent
                                  color={button?.color}
                                  setColor={(colorE) => {
                                    let newBtn = {
                                      ...button,
                                      color: colorE,
                                    };
                                    const tempBtns = [...sctaButtons];
                                    tempBtns[index] = newBtn;
                                    setSctaButtons(tempBtns);
                                  }}
                                />
                              </div>
                              <div className={"p-col-12 p-lg-1"}>
                                <i
                                  title="Remove range"
                                  className="pi pi-times"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => onRemoveSctaButtons(index)}
                                ></i>
                              </div>
                            </div>
                            <div
                              style={{
                                alignItems: "baseline",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              <div
                                className={
                                  "single-cta-buttons p-col-12 p-lg-4 p-label-col"
                                }
                              >
                                <div className={"label-container"}>
                                  <label className={"input-label"}>
                                    Button Text
                                  </label>
                                  <span className={"red"}>*</span>
                                </div>
                              </div>
                              <div className={"p-col-12 p-lg-8"}>
                                <InputText
                                  name={"button.text"}
                                  style={{ width: "100%" }}
                                  value={button.text}
                                  placeholder={"Button Text"}
                                  onChange={(e) => {
                                    let newBtn = {
                                      ...button,
                                      text: e?.target?.value,
                                    };
                                    const tempBtns = [...sctaButtons];
                                    tempBtns[index] = newBtn;
                                    setSctaButtons(tempBtns);
                                  }}
                                />
                                <label className={"warning-text"}>
                                  {WARNINGS.SCTA_BTN_TEXT[index]}
                                </label>
                              </div>
                            </div>
                            <div
                              style={{
                                alignItems: "baseline",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              <div
                                className={
                                  "single-cta-buttons p-col-12 p-lg-4 p-label-col"
                                }
                              >
                                <div className={"label-container"}>
                                  <label className={"input-label"}>
                                    Link Text
                                  </label>
                                  <span className={"red"}>*</span>
                                </div>
                              </div>
                              <div className={"p-col-12 p-lg-8"}>
                                <InputText
                                  name={"button.href"}
                                  style={{ width: "100%" }}
                                  value={button.href}
                                  placeholder={"Link Text"}
                                  onChange={(e) => {
                                    let newBtn = {
                                      ...button,
                                      href: e?.target?.value,
                                    };
                                    const tempBtns = [...sctaButtons];
                                    tempBtns[index] = newBtn;
                                    setSctaButtons(tempBtns);
                                  }}
                                />
                                <label className={"warning-text"}>
                                  {WARNINGS.SCTA_LINK_TEXT[index]}
                                </label>
                              </div>
                            </div>
                            <div
                              style={{
                                alignItems: "baseline",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              <div
                                className={
                                  "single-cta-buttons p-col-12 p-lg-4 p-label-col"
                                }
                              >
                                <div className={"label-container"}>
                                  <label className={"input-label"}>
                                    ASC Event
                                  </label>
                                </div>
                              </div>
                              <div className={"p-col-12 p-lg-8"}>
                                <Dropdown
                                  style={{
                                    width: "100%",
                                    marginBottom: "10px",
                                  }}
                                  value={{
                                    name: button.singleCtaAscEvent || "",
                                  }}
                                  options={ascEvents}
                                  onChange={(e) => {
                                    let newBtn = {
                                      ...button,
                                      singleCtaAscEvent: e?.value?.name || "",
                                    };
                                    const tempBtns = [...sctaButtons];
                                    tempBtns[index] = newBtn;
                                    setSctaButtons(tempBtns);
                                  }}
                                  optionLabel="name"
                                  filter
                                  showClear
                                  filterBy="name"
                                  placeholder="Select an ASC event"
                                  valueTemplate={selectedDropdownTemplate}
                                  itemTemplate={optionTemplate}
                                />
                                <InputText
                                  name={"button.singleCtaAscEvent"}
                                  style={{ width: "100%" }}
                                  value={button.singleCtaAscEvent}
                                  placeholder={"ASC Event"}
                                  onChange={(e) => {
                                    let newBtn = {
                                      ...button,
                                      singleCtaAscEvent: e?.target?.value,
                                    };
                                    const tempBtns = [...sctaButtons];
                                    tempBtns[index] = newBtn;
                                    setSctaButtons(tempBtns);
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                alignItems: "baseline",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              <div
                                className={
                                  "single-cta-buttons p-col-12 p-lg-4 p-label-col"
                                }
                              >
                                <div className={"label-container"}>
                                  <label className={"input-label"}>
                                    Font Family
                                  </label>
                                </div>
                              </div>
                              <div className={"p-col-12 p-lg-8"}>
                                <Dropdown
                                  style={{ width: "100%" }}
                                  value={{
                                    name: button.fontFamily,
                                  }}
                                  options={fontFamilies}
                                  onChange={(e) => {
                                    let newBtn = {
                                      ...button,
                                      fontFamily: e?.value?.name,
                                    };
                                    const tempBtns = [...sctaButtons];
                                    tempBtns[index] = newBtn;
                                    setSctaButtons(tempBtns);
                                  }}
                                  optionLabel="name"
                                  filter
                                  showClear
                                  filterBy="name"
                                  placeholder="Select a Font Family"
                                  valueTemplate={selectedDropdownTemplate}
                                  itemTemplate={optionTemplate}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                alignItems: "baseline",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              <div
                                className={
                                  "single-cta-buttons p-col-12 p-lg-4 p-label-col"
                                }
                              >
                                <div className={"label-container"}>
                                  <label className={"input-label"}>
                                    Font Color
                                  </label>
                                </div>
                              </div>
                              <div className={"p-col-12 p-lg-8"}>
                                <ColorPickerComponent
                                  color={button?.fontColor}
                                  setColor={(fontColorE) => {
                                    let newBtn = {
                                      ...button,
                                      fontColor: fontColorE,
                                    };
                                    const tempBtns = [...sctaButtons];
                                    tempBtns[index] = newBtn;
                                    setSctaButtons(tempBtns);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
              <div style={{ padding: "1rem" }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sctaFirstScreen,
                  }}
                />
              </div>
            </div>
          )}
        </Panel>

        <div>
          <Button
            onClick={() => onSave()}
            style={{ marginRight: 5 }}
            label={"Save"}
            icon={"pi pi-save"}
          />
          <Button
            onClick={onBack}
            className={"p-button-warning"}
            label={"Back"}
            icon={"pi pi-arrow-left"}
          />
        </div>
      </div>
    </div>
  );
}

export default TemplatesForm;
